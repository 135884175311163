import { LOGOUT_REQUEST } from './types.js';
import { logoutError } from './actions.js';
import { put, take, call } from 'redux-saga/effects';
import { redirect } from '../CustomRedirect/actions';
import { resetApplicationStore } from '../../common/redux/purgeStore';
import { getServerResponseStatus } from '../ErrorPopUp/actions';
import { endLoading } from '../../common/redux/loadManager/actions';

let currentLoadId = 0;

/** If detect type: LOGOUT_REQUEST call logoutUser() */
export default function* logoutSaga(api) {
  while (true) {
    const logoutAction = yield take(LOGOUT_REQUEST);

    const accessToken = logoutAction.payload.accessToken;
    yield call(logoutUser, api, accessToken);
  }
}

/** Logout user */
function* logoutUser(api, accessToken) {
  try {
    yield call(api.logout, accessToken);
    // Reset store data
    yield put(resetApplicationStore());
    yield put(redirect('/login'));
  } catch (error) {
    yield put(logoutError(error));
    /** Listen for error state to show when server is disconected */
    yield put(getServerResponseStatus(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}
