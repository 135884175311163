import React from 'react';
import { translate } from '../../localization/TranslationUtil';
import Graphic from '../Graphic/Graphic';
import IconButton from '../IconButton/IconButton';
import LabelValue from '../LabelValue/LabelValue';
import './CalmRobotPopUp.scss'; 


/** CalmRobotPopUp component */
function CalmRobotPopUp({ closePopupClickFn }) {
  return (
    <div className="calmRobotPopup"> 
      <div className='calmRobotPopup--container'>
      <IconButton cssClassName="calmRobotPopup--btnClosePopup" onClickFn={closePopupClickFn} />
      <div className="calmRobotPopup--title">{translate('ROBOT_CALM_TITLE')}</div> 
      <div className="calmRobotPopup--robotNameContainer"> 
        <div className="calmRobotPopup--nameHeader">{translate('ROBOT_NAME')}</div> 
        <div className="calmRobotPopup--nameValue">{translate('ROBOT_NAME_VALUE_CALM')}</div>
      </div>
      <div className="calmRobotPopup--robotDescriptionContainer">
        {/* <div className="calmRobotPopup--descriptionHeader">{translate('ROBOT_FUNCTIONALITY')}</div>  */}
        <div className="calmRobotPopup--descriptionValue">{translate('ROBOT_CALM_DESCRIPTION')}</div>
      </div>
      </div>
      <Graphic cssClassName="calmRobotPopup--logo"
      />
      
    </div>
  );
}
export default CalmRobotPopUp; 
