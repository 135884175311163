import React from "react";
import { translate } from "../../localization/TranslationUtil";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../../common/components/ConfirmationDialog/ConfirmationDialog";
import {
  closeConfirmSaveChangesPopup,
  leaveWithoutSavingChanges,
  saveChangesAndLeave,
} from "../../common/components/SaveChangesManager/actions";
// Styles
import "./SaveChangesConfirmationDialog.scss";

/** SaveChangesConfirmationDialog component */
function SaveChangesConfirmationDialog({}) {
  const dispatch = useDispatch();
  return (
    <ConfirmationDialog
      onConfirmCallbackFn={() => {
        dispatch(saveChangesAndLeave());
      }}
      onCancelCallbackFn={() => {
        dispatch(leaveWithoutSavingChanges());
      }}
      onCloseCallbackFn={() => {
        dispatch(closeConfirmSaveChangesPopup());
      }}
      dialogTitle={translate("ADMIN_CONFIRM_SAVE_CHANGES_DIALOG_TITLE")}
      dialogText={translate("ADMIN_CONFIRM_SAVE_CHANGES_DIALOG_TEXT")}
      cancelButtonLabelText={translate(
        "ADMIN_CONFIRM_SAVE_CHANGES_NO_BUTTON_TEXT",
      )}
      confirmationButtonLabelText={translate(
        "ADMIN_CONFIRM_SAVE_CHANGES_YES_BUTTON_TEXT",
      )}
      cssClassname="saveChangesConfirmationDialogContainer"
    />
  );
}
export default SaveChangesConfirmationDialog;
