import { SET_NEW_PASSWORD_REQUEST } from "./types.js";
import { setNewPasswordError, setNewPasswordSuccess } from "./actions.js";
import { put, take, call } from "redux-saga/effects";
import { redirect } from "../../CustomRedirect/actions";
import { getServerResponseStatus } from "../../ErrorPopUp/actions";
import {
  startLoading,
  endLoading,
} from "../../../common/redux/loadManager/actions";
import { getLoadId } from "../../../common/redux/loadManager/methods";

let currentLoadId = 0;

export default function* setNewPasswordSaga(api) {
  while (true) {
    const setNewPasswordForUserRequest = yield take(SET_NEW_PASSWORD_REQUEST);
    if (setNewPasswordForUserRequest.payload) {
      /** Run loader */
      currentLoadId = getLoadId();
      yield put(startLoading(currentLoadId));
      const { email, token, newPassword } =
        setNewPasswordForUserRequest.payload;
      yield call(setNewPassword, api, email, token, newPassword);
    }
  }
}

/** Create request for resending verification e-mail to the user
 * @param {object} api - API providing access to the Lely backend
 * @param {string} email - The e-mail address of the user that wants to change their password
 * @param {string} token - A string of characters identifying and authorizing the current set password operation (whose password the backend is supposed to change)
 * @param {string} newPassword - The new password to be set
 */
function* setNewPassword(api, email, token, newPassword) {
  var response;
  try {
    response = yield call(api.setNewPassword, email, token, newPassword);
    yield put(setNewPasswordSuccess());
    yield put(getServerResponseStatus(""));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    yield put(setNewPasswordError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
    /** Listen for error state to show when server is disconected */
    yield put(getServerResponseStatus(error));
  }
}
