import _ from "lodash";

export const getEmptyStoryBlock = (languageKeys, newStoryBlockId) => {
    let newStoryBlockObject = {
        textBlockIndex: newStoryBlockId, title: {}, description: {}, imageData: ''
    };
    languageKeys.forEach(currentLanguageKey => {
        newStoryBlockObject.description[currentLanguageKey] = '';
        newStoryBlockObject.title[currentLanguageKey] = '';
    });
    return newStoryBlockObject;
};

export const convertBEStoryBlocksToFE = (storySlidesFromBE, supportedLanguageCodesArray) => {
    let slides = [];

    storySlidesFromBE && Object.keys(storySlidesFromBE).forEach((currentLanguageKey, currentLanguageIndex) => {
        let currentLanguageSlides = storySlidesFromBE[currentLanguageKey];
        currentLanguageSlides.forEach((currentSlide, currentSlideIndex) => {
            let isSlideStillNotAdded = (currentSlideIndex >= slides.length || slides.length === 0);
            if (isSlideStillNotAdded) {
                slides.push(_.cloneDeep(currentSlide));
                slides[currentSlideIndex].description = {};
                slides[currentSlideIndex].title = {};
            }
            slides[currentSlideIndex].description[currentLanguageKey] = currentSlide.description;
            slides[currentSlideIndex].title[currentLanguageKey] = currentSlide.title;
        });
    });
    slides.forEach(currentSlide => {
        supportedLanguageCodesArray.map(currentLanguageCode => {
            if (!currentSlide.description.hasOwnProperty(currentLanguageCode)) {
                currentSlide.description[currentLanguageCode] = '';
            }
            if (!currentSlide.title.hasOwnProperty(currentLanguageCode)) {
                currentSlide.title[currentLanguageCode] = '';
            }
        });
    });
    return slides;
};

export const getLanguageCodesOfLanguagesPlusSimpleMode = (languagesArray) => {
    let languageCodesPlusSimpleMode = [];
    languagesArray.forEach(currentLanguageCode => {
        languageCodesPlusSimpleMode.push(currentLanguageCode);
        languageCodesPlusSimpleMode.push(currentLanguageCode + 's');
    });

    return languageCodesPlusSimpleMode;
}

export const convertFEStoryBlocksToBE = (textBlocksFromFE, supportedLanguageCodesArray) => {
    let languageCodesPlusSimpleMode = getLanguageCodesOfLanguagesPlusSimpleMode(supportedLanguageCodesArray);
    let storySlidesObject = {};
    languageCodesPlusSimpleMode.forEach(currentLanguageCode => {
        storySlidesObject[currentLanguageCode] = [];
    });
    textBlocksFromFE.forEach(currentTextBlock => {
        languageCodesPlusSimpleMode.forEach(currentLanguageCode => {
            let newTextBlockObject = _.cloneDeep(currentTextBlock);
            newTextBlockObject.title = currentTextBlock.title[currentLanguageCode] ? currentTextBlock.title[currentLanguageCode] : "";
            newTextBlockObject.description = currentTextBlock.description[currentLanguageCode] ? currentTextBlock.description[currentLanguageCode] : "";
            newTextBlockObject.imageData = currentTextBlock.imageData ? currentTextBlock.imageData : "";

            storySlidesObject[currentLanguageCode].push(newTextBlockObject);
        });
    });
    return storySlidesObject;
};