import React from "react";
import "./FifthSlideLely.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const FifthSlideLely = ({}) => {
  return (
    <div className="lelyStoryFifthSlide">
      <TextCaption additionalCssClassname="lelyStoryFifthSlide--textCaption">
        <div className="lelyStoryFifthSlide--title">
          {translate("LELY_FIFTH_SLIDE_TITLE")}
        </div>
        <div className="lelyStoryFifthSlide--text">
          {translate("LELY_FIFTH_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="lelyStoryFifthSlide--imgCaption">
        <div className="lelyStoryFifthSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default FifthSlideLely;
