import {
  UPDATE_CURRENT_APPLICATION_VERSION,
  UPDATE_TIMESTAMP_OF_LATEST_BUILD,
} from '../common/components/CacheBust/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  applicationVersionNumber: '',
  latestBuildTimestamp: 0,
};

/** Application Version Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function applicationVersionReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_APPLICATION_VERSION:
      return {
        ...state,
        applicationVersionNumber: action.payload,
      };

    case UPDATE_TIMESTAMP_OF_LATEST_BUILD:
      return {
        ...state,
        latestBuildTimestamp: action.payload,
      };

    default:
      return state;
  }
}

export default applicationVersionReducer;
