import {
  UPDATE_ACCESS_TOKEN_AND_REFRESH_TOKEN_WITH_NEW_ONES,
  START_WAITING_FOR_ACCESS_TOKEN_EXPIRATION,
  STOP_WAITING_FOR_ACCESS_TOKEN_EXPIRATION
} from './types';

/** Action which initiates the start waiting for access token expiration
   * @param {string} newAccessToken - Short-lived access token
   * @param {string} newRefreshToken - Longer-lived access token
   * @return {any} action object
   */
export function startWaitingForAccessTokenExpiration(newAccessToken, newRefreshToken) {
  return {
    type: START_WAITING_FOR_ACCESS_TOKEN_EXPIRATION,
    payload: {
        newAccessToken: newAccessToken,
        newRefreshToken: newRefreshToken,
    },
  };
}

/** Action which is usually called after
* logout in order to cancel the wait
* for access token to expire
* @return {any} action object
*/
export function stopWaitingForAccessTokenExpiration() {
  return {
    type: STOP_WAITING_FOR_ACCESS_TOKEN_EXPIRATION,
    payload: {},
  };
}

/** Action which notifies for the successful receival of new access/refresh tokens.
   * @param {string} newAccessToken - Brand new short-lived access token
   * @param {string} newRefreshToken - Brand new longer-lived access token
   * @return {any} refresh access token success action
   */
export function accessTokenRefreshSuccess(newAccessToken, newRefreshToken) {
  return {
    type: UPDATE_ACCESS_TOKEN_AND_REFRESH_TOKEN_WITH_NEW_ONES,
    payload: {
      newAccessToken: newAccessToken,
      newRefreshToken: newRefreshToken,
    },
  };
}