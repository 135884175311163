import {
  REQUEST_FARMER_PAGE_INFORMATION,
  SAVE_CHANGES_TO_FARMER_PAGE,
} from './types.js';
import {
  getFarmerInformationSuccess,
  getFarmerInformationError,
  saveChangesToFarmerPageSuccess,
  saveChangesToFarmerPageSuccessError,
} from './actions.js';
import { put, take, call } from 'redux-saga/effects';
import {
  startLoading,
  endLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import { saveChangesToSelectedFarmLanguages } from '../../../common/components/LanguageSelection/actions.js';

let currentLoadId = 0;

export function* initalizeFarmerPageSaga(api) {
  while (true) {
    const farmerInformationRequestAction = yield take(
      REQUEST_FARMER_PAGE_INFORMATION,
    );
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { accessToken } = farmerInformationRequestAction.payload;
    yield call(getFarmerDetails, api, accessToken);
  }
}

/** Create request to get farmer page latest state of data from the backend
 * @param {string} accessToken - User access token
 */
function* getFarmerDetails(api, accessToken) {
  try {
    const farmerDetails = yield call(api.getFarmerDetails, accessToken);
    yield put(getFarmerInformationSuccess(farmerDetails.data));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(getFarmerInformationError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}

export function* farmerSaveChangesSaga(api) {
  while (true) {
    const saveChangesFarmerRequestAction = yield take(
      SAVE_CHANGES_TO_FARMER_PAGE,
    );
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { accessToken, newFarmerDetails, newLanguageDetails } =
      saveChangesFarmerRequestAction.payload;
    yield call(saveChangesToFarmerPage, api, accessToken, newFarmerDetails, newLanguageDetails);
  }
}

/** Create request to get farmer page latest state of data from the backend
 * @param {string} accessToken - User access token
 * @param {Object} newFarmerDetails - The latest state of the farm details page (with the latest changes)
 * @param {Object} newLanguageDetails - The latest state of the selected farm languages that need to be persisted to the backend as soon as the first request is over
 */
function* saveChangesToFarmerPage(api, accessToken, newFarmerDetails, newLanguageDetails) {
  try {
    const updateFarmerDataResponse = yield call(
      api.setFarmerDetails,
      accessToken,
      newFarmerDetails,
    );
    yield put(saveChangesToFarmerPageSuccess(updateFarmerDataResponse.data));
    yield put(saveChangesToSelectedFarmLanguages(accessToken, newLanguageDetails));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(saveChangesToFarmerPageSuccessError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}
