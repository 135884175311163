import React from "react";
import "./StartingSlideDiscovery.scss";
import {
  translate,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import StoryNavigationHint from "../../common/components/StoryNavigationHint/StoryNavigationHint";

const StartingSlideDiscovery = ({ name, otherDevices }) => {
  const filteredDevices = otherDevices.filter((currentDevice) =>
    currentDevice.type.includes("discovery"),
  );
  const robotCssClassFirstSlideName =
    "discoveryStoryStartingSlide--" +
    "" +
    "" +
    "robot" +
    "" +
    "" +
    filteredDevices.length;
  console.log("robotCssClassName", robotCssClassFirstSlideName);
  return (
    <div className="discoveryStoryStartingSlide">
      <TextCaption additionalCssClassname="discoveryStoryStartingSlide--textCaption">
        <div className="discoveryStoryStartingSlide--title">
          {filteredDevices.length
            ? translate("DISCOVERY_STORY_SUBTITLE")
            : translate("DISCOVERY_COLLECTOR_STORY_SUBTITLE")}
        </div>

        <div className="discoveryStoryStartingSlide--name">
          {filteredDevices.length
            ? translateWithValuesAndHtml("DISCOVERY_STORY_TITLE")
            : translateWithValuesAndHtml("DISCOVERY_COLLECTOR_STORY_TITLE")}
        </div>
      </TextCaption>
      <div className="discoveryStory--bottomColorOverlay"></div>
      <div className={robotCssClassFirstSlideName}></div>
      <StoryNavigationHint />
    </div>
  );
};

export default StartingSlideDiscovery;
