import { HANDLE_ACTOR_SELECTED } from "../components/Actor/types.js";

/** Store states
 * @constant {Object} */
export const initialState = {
  selectedActorDetails: {},
};

/** Cow Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function actorReducer(state = initialState, action) {
  switch (action.type) {
    case HANDLE_ACTOR_SELECTED:
      return {
        ...state,
        selectedActorDetails: action.payload,
      };

    default:
      return state;
  }
}

export default actorReducer;
