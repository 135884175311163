import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  translate,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import "./CookieStatementPopup.scss";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";
import { acceptAllCookies } from "./actions";
import { showPopup } from "../../common/components/PopupContainer/actions";
import CallToActionButton from "../CallToActionButton/CallToActionButton";

const CookieStatementPopup = ({ closePopupClickFn, isAdultSelected }) => {
  const dispatch = useDispatch();
  const popupComponentRef = useRef(null);
  const fadeOutIntervalRef = useRef(null);
  const POPUP_FADE_OUT_ANIMATION_DURATION_IN_MILLISECONDS = 500;
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const doShowChildVersion = typeof farmDetails.isAdultSelected === "undefined" || farmDetails.isAdultSelected == false;

  useEffect(() => {
    return () => {
      if (fadeOutIntervalRef.current) {
        clearInterval(fadeOutIntervalRef.current);
      }
    };
  });

  const acceptCookiesClickFn = () => {
    fadeOutPopup();
    fadeOutIntervalRef.current = setTimeout(() => {
      dispatch(acceptAllCookies());
      dispatch(showPopup({}));
    }, POPUP_FADE_OUT_ANIMATION_DURATION_IN_MILLISECONDS);
  };

  const fadeOutPopup = () => {
    popupComponentRef.current.classList.add("is-hiding");
  };
  const getSelectedAgeIsChild = farmDetails.isAdultSelected == true || farmDetails.isAdultSelected == undefined ? false : true;
  
  return (


    <div
      className={
        getSelectedAgeIsChild 
          ? "cookieStatementPopup is-children"
          : "cookieStatementPopup is-adult"
      }
      ref={popupComponentRef}
    >
      <div className="cookieStatementPopup--headers">
        <div className="cookieStatementPopup--title">
          {translate("COOKIE_STATEMENT_POPUP_TITLE_TEXT")}
        </div>
      </div>
      {getSelectedAgeIsChild && doShowChildVersion ? (
        <div className="cookieStatementPopup--body">
          <div
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml(
                "COOKIE_STATEMENT_POPUP_BODY_TEXT_INTRO_PARAGRAPH",
              ),
            }}
          />
        </div>
      ) : (
        <div className="cookieStatementPopup--body">
          <p  dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml(
                "COOKIE_STATEMENT_POPUP_BODY_TEXT_INTRO_PARAGRAPH",
              ),
            }}></p>
          <h3>
            {translate(
              "COOKIE_STATEMENT_POPUP_BODY_TEXT_WHAT_ARE_COOKIES_HEADING",
            )}
          </h3>
          <p>
            {translate(
              "COOKIE_STATEMENT_POPUP_BODY_TEXT_WHAT_ARE_COOKIES_PARAGRAPH",
            )}
          </p>
          <h3>
            {translate(
              "COOKIE_STATEMENT_POPUP_BODY_TEXT_WHY_LELY_PLACES_COOKIES_HEADING",
            )}
          </h3>
          <p>
            {translate(
              "COOKIE_STATEMENT_POPUP_BODY_TEXT_WHY_LELY_PLACES_COOKIES_PARAGRAPH",
            )}
          </p>
          <h3>
            {translate(
              "COOKIE_STATEMENT_POPUP_BODY_TEXT_HOW_LELY_HANDLES_PRIVACY_HEADING",
            )}
          </h3>
          <p>
            {translate(
              "COOKIE_STATEMENT_POPUP_BODY_TEXT_HOW_LELY_HANDLES_PRIVACY_PARAGRAPH",
            )}
          </p>
          <h3>
            {translate(
              "COOKIE_STATEMENT_POPUP_BODY_TEXT_WHAT_KINDS_OF_COOKIES_LELY_PUTS_HEADING",
            )}
          </h3>
          <p>
            {translate(
              "COOKIE_STATEMENT_POPUP_BODY_TEXT_WHAT_KINDS_OF_COOKIES_LELY_PUTS_INTRO_TEXT",
            )}
          </p>
          <ul>
            <li>
              {translate(
                "COOKIE_STATEMENT_POPUP_BODY_TEXT_COOKIES_WITH_VERY_LITTLE_IMPACT_ON_PRIVACY",
              )}
              <ul>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_COOKIES_WITH_VERY_LITTLE_IMPACT_ON_PRIVACY_TYPE_1",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_COOKIES_WITH_VERY_LITTLE_IMPACT_ON_PRIVACY_TYPE_2",
                  )}
                </li>
              </ul>
            </li>
            <li>
              {translate(
                "COOKIE_STATEMENT_POPUP_BODY_TEXT_COOKIES_WITH_LITTLE_INFLUENCE_ON_PRIVACY",
              )}
              <ul>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_COOKIES_WITH_LITTLE_INFLUENCE_ON_PRIVACY_TYPE_1",
                  )}
                </li>
              </ul>
            </li>
          </ul>
          <p>
            {translate(
              "COOKIE_STATEMENT_POPUP_BODY_TEXT_WHAT_KINDS_OF_COOKIES_LELY_PUTS_END_TEXT",
            )}
          </p>
          <h3>
            {translate("COOKIE_STATEMENT_POPUP_BODY_TEXT_GOOD_TO_KNOW_HEADING")}
          </h3>
          <p>
            {translate(
              "COOKIE_STATEMENT_POPUP_BODY_TEXT_GOOD_TO_KNOW_PARAGRAPH",
            )}
          </p>
          <h3>
            {translate(
              "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_HEADING",
            )}
          </h3>
          <ul>
            <li>
              {translate(
                "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_BULLET_1",
              )}
            </li>
            <li>
              {translate(
                "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_EDGE_HEADING",
              )}
              <ul>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_EDGE_BULLET_1",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_EDGE_BULLET_2",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_EDGE_BULLET_3",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_EDGE_BULLET_4",
                  )}
                </li>
              </ul>
            </li>
            <li>
              {translate(
                "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_IE_HEADING",
              )}
              <ul>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_IE_BULLET_1",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_IE_BULLET_2",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_IE_BULLET_3",
                  )}
                </li>
              </ul>
            </li>
            <li>
              {translate(
                "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_CHROME_HEADING",
              )}
              <ul>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_CHROME_BULLET_1",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_CHROME_BULLET_2",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_CHROME_BULLET_3",
                  )}
                </li>
              </ul>
            </li>
            <li>
              {translate(
                "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_FIREFOX_HEADING",
              )}
              <ul>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_FIREFOX_BULLET_1",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_FIREFOX_BULLET_2",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_FIREFOX_BULLET_3",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_FIREFOX_BULLET_4",
                  )}
                </li>
              </ul>
            </li>
            <li>
              {translate(
                "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_SAFARI_HEADING",
              )}
              <ul>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_SAFARI_BULLET_1",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_SAFARI_BULLET_2",
                  )}
                </li>
                <li>
                  {translate(
                    "COOKIE_STATEMENT_POPUP_BODY_TEXT_DELETE_COOKIES_IN_SAFARI_BULLET_3",
                  )}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      )}
      {/* <div className= {doShowChildVersion && farmDetails.isAgeSelected != undefined
          ? "cookieStatementPopup--footer is-children"
          : "cookieStatementPopup--footer is-adult"}> */}
          
         <CallToActionButton
          label={translate("COOKIES_POPUP_ACCEPT_COOKIES_BUTTON_LABEL")}
          isAdultSelected={!getSelectedAgeIsChild}
          cssClassName="cookieStatementPopup--footer--btnAcceptCookies"
          onClickFn={() => {
            acceptCookiesClickFn()
          }}
        
        >
        </CallToActionButton>
        {/* <div
          className={
            "cookieStatementPopup--footer--btnAcceptCookies " +
            IS_CLICKABLE_CSS_CLASSNAME
          }
          onClick={acceptCookiesClickFn}
        >
          <span className="cookieStatementPopup--footer--btnAcceptCookies--label">
            {translate("COOKIES_POPUP_ACCEPT_COOKIES_BUTTON_LABEL")}
          </span>
        </div> */}
      {/* </div> */}
    </div>
  
  );
};

export default CookieStatementPopup;
