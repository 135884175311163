import React from "react";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import IconButton from "../IconButton/IconButton";
import { translate } from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import "./LunaRobotPopup.scss";

function LunaRobotPopup({ callToActionClickFn, closePopupClickFn, isAdultSelected }) {
  return (
    <div className="lunaPopup">
      <div className="lunaPopup--container">
        <IconButton
          cssClassName="lunaPopup--btnClosePopup"
          onClickFn={closePopupClickFn}
        />
        <div className="lunaPopup--title">{translate("LUNA_ROBOT_TITLE")}</div>
        <div className="lunaPopup--description">
        {translate("LUNA_ROBOT_DESCRIPTION")}
        </div> 
        <CallToActionButton
          isAdultSelected = {isAdultSelected}
          cssClassName="lunaPopup--btnCallToAction"
          label={translate("LUNA_CALL_TO_ACTION_BUTTON")}
          onClickFn={() => {
          callToActionClickFn();
          closePopupClickFn();
          }}
        />
      </div>
     <Graphic cssClassName="lunaPopup--luna" />
      
    </div>
  );
}

export default LunaRobotPopup;
