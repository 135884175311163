import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "../IconButton/IconButton";
import { translate } from "../../localization/TranslationUtil";
import "./CookiesPopup.scss";
import {
  ACTOR_TYPE_COOKIE_STATEMENT,
  IS_CLICKABLE_CSS_CLASSNAME,
} from "../../common/constants";
import { showPopup } from "../../common/components/PopupContainer/actions";
import { acceptAllCookies } from "./actions";
import initializeWithLanguage from "../../localization/TranslationUtil";
import CallToActionButton from "../CallToActionButton/CallToActionButton";


const CookiesPopup = ({ closePopupClickFn, selectedLanguage }) => {
  const dispatch = useDispatch();
  const popupComponentRef = useRef(null);
  const fadeOutIntervalRef = useRef(null);
  const POPUP_FADE_OUT_ANIMATION_DURATION_IN_MILLISECONDS = 500;
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const doShowChildVersion =
    farmDetails && farmDetails.isAdultSelected === false;
console.log("selectedLanguage",selectedLanguage)
  useEffect(() => {
    return () => {
      if (fadeOutIntervalRef.current) {
        clearInterval(fadeOutIntervalRef.current);
      }
    };
  });

  const showReadMorePopup = () => {
    fadeOutPopup();
    fadeOutIntervalRef.current = setTimeout(() => {
      dispatch(showPopup({ type: ACTOR_TYPE_COOKIE_STATEMENT }));
    }, POPUP_FADE_OUT_ANIMATION_DURATION_IN_MILLISECONDS);
  };

  const fadeOutPopup = () => {
    popupComponentRef.current.classList.add("is-hiding");
  };

  const acceptCookiesClickFn = () => {
    fadeOutPopup();
    fadeOutIntervalRef.current = setTimeout(() => {
      dispatch(acceptAllCookies());
      dispatch(showPopup({}));
    }, POPUP_FADE_OUT_ANIMATION_DURATION_IN_MILLISECONDS);
  };

  return (
    <div className="cookiesPopup--overlay">
      <div
        className={
          doShowChildVersion
            ? "cookiesPopup is-children"
            : "cookiesPopup is-adult"
        }
        ref={popupComponentRef}
      >

        <div className="cookiesPopup--image" />
        <div className="cookiesPopup--headers">
          <div className="cookiesPopup--title">
            {translate("COOKIES_POPUP_TITLE_TEXT")}
          </div>
        </div>
        <div className="cookiesPopup--body">
          <p className="cookiesPopup-body-paragraph">
            <span className="cookiesPopup--body--textBeginning">
              {translate("COOKIES_POPUP_DESCRIPTION_TEXT_BEGINNING") + " "}
            </span>
            <span
              className="cookiesPopup--body--btnReadMore"
              onClick={showReadMorePopup}
            >
              {translate("COOKIES_POPUP_READ_MORE_BUTTON_LABEL")}
            </span>
            <span className="cookiesPopup--body--textBeginning">
              {" " + translate("COOKIES_POPUP_DESCRIPTION_TEXT_END")}
            </span>
          </p>
        </div>
        <div className="cookiesPopup--footer">
          <CallToActionButton
            label={translate("COOKIES_POPUP_ACCEPT_COOKIES_BUTTON_LABEL")}
            isAdultSelected={!doShowChildVersion}
            cssClassName="cookieStatementPopup--footer--btnAcceptCookies"
            onClickFn={() => {
              acceptCookiesClickFn()
            }}
          >
          </CallToActionButton>
        </div>
      </div>
    </div>
  );
};

export default CookiesPopup;
