import axios from 'axios';
import {
  SUSTAINABILITY_SERVICE_URL,
  GET_FARM_FEATURES_TEXT_BLOCKS_SERVICE_URL,
} from './config';

export const api = {
  getSustainabilityDetails: async function (accessToken) {
    /*
    return {
      data: {
        productionType: 'cheese',
        farmName: 'Mock farm name title',
        description: 'Mock farm text description',
        imageData: {},
      },
    }; // <-- those are the text blocks that the backend has returned (mock data)
    */

    // uncomment code below when backend is ready
    return axios.get(SUSTAINABILITY_SERVICE_URL, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  setSustainabilityDetails: async function (
    accessToken,
    sustainabilityDetails,
  ) {
    /*
    return {
      data: {
        status: 200,
      },
    }; // <-- mock backend response
    */
    // uncomment code below when backend is ready
    return axios.patch(SUSTAINABILITY_SERVICE_URL, sustainabilityDetails, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
};
