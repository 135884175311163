import _ from "lodash";
import React, { useCallback } from "react";
import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
import "./BackgroundLayer.scss";
import { getBackgroundLayerSvgFilePath } from "../../common/svgUtil";

function BackgroundLayer({
  customCssClassName,
  forceSvgInlining,
  backgroundClassNameVarietiesArray,
  slideWidthInPx,
  slidesCount,
  xPosition,
  farmTheme,
  children,
}) {
  let generatedSlides = [];
  const onCompletedCallback = useCallback((e) => {}, []);
  const onErrorCallback = useCallback((e) => {
    console.log("@onError e:", e);
  }, []);

  for (
    var currentSlideIndex = 0;
    currentSlideIndex <= slidesCount;
    currentSlideIndex++
  ) {
    let currentSlideCssClassname =
      backgroundClassNameVarietiesArray[
        currentSlideIndex % backgroundClassNameVarietiesArray.length
      ];
    let backgroundLayerSvgPath = forceSvgInlining
      ? getBackgroundLayerSvgFilePath(farmTheme, currentSlideCssClassname)
      : "";
    if (forceSvgInlining) {
      generatedSlides.push(
        <div
          style={{
            position: "absolute",
            left: `${currentSlideIndex * slideWidthInPx}px`,
          }}
          className={"background-layer--slide"}
        >
          <DynamicInlineSVG
            svgFullFilePath={backgroundLayerSvgPath}
            themeName={farmTheme}
            onCompleted={onCompletedCallback}
            onError={onErrorCallback}
          />
        </div>,
      );
    } else {
      generatedSlides.push(
        <div
          style={{
            position: "absolute",
            left: `${currentSlideIndex * slideWidthInPx}px`,
          }}
          className={"background-layer--slide " + currentSlideCssClassname}
        />,
      );
    }
  }

  return (
    <div
      className={customCssClassName + " background-layer"}
      style={{
        width: `${slidesCount * slideWidthInPx}px`,
        transform: `translateX(${xPosition})`,
      }}
    >
      {children || generatedSlides}
    </div>
  );
}

export default BackgroundLayer;
