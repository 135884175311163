import {
  WEATHER_TYPE_SUNNY,
  WEATHER_DEFAULT_TEMPERATURE_IN_DEGREES_CELSIUS,
} from "../../common/constants";
import _ from "lodash";

export const getWeatherStringFromMilkingData = (milkingData) => {};

export const initializeCowDailyMilkingsWithWeatherData = (cowDailyData) => {
  cowDailyData &&
    cowDailyData.milkings.forEach(function (currentMilkingData) {
      populateMilkingWithWeatherData(
        currentMilkingData,
        cowDailyData &&
          cowDailyData.weatherData &&
          cowDailyData.weatherData.hours,
      );
    });
};

export const getWeatherDataByFarmDetails = (hudDetails) => {
  if (
    !hudDetails ||
    !hudDetails.dateTime ||
    !hudDetails.farmLocationWeatherData
  ) {
    return null;
  }
  const currentFarmHourString = hudDetails.dateTime.substr(11, 2);
  let currentHourWeatherData = {};
  hudDetails.farmLocationWeatherData.hours.forEach((currentHourInfo) => {
    let currentHourString = currentHourInfo.datetime.substr(0, 2);
    if (currentHourString === currentFarmHourString) {
      currentHourWeatherData = currentHourInfo;
    }
  });
  return currentHourWeatherData;
};

export const getWeatherDataForTime = (timeString, hoursDataObject) => {
  let weatherDataDuringTime = !_.isEmpty(hoursDataObject)
    ? getWeatherDataByTimeString(timeString, hoursDataObject)
    : {};
  let weatherDataObject = {};
  weatherDataObject.weatherString =
    weatherDataDuringTime && weatherDataDuringTime.conditions
      ? weatherDataDuringTime.conditions.toLowerCase()
      : WEATHER_TYPE_SUNNY;
  weatherDataObject.temperature = weatherDataDuringTime
    ? weatherDataDuringTime.temp
    : WEATHER_DEFAULT_TEMPERATURE_IN_DEGREES_CELSIUS;
  weatherDataObject.cloudCover = weatherDataDuringTime
    ? weatherDataDuringTime.cloudCover
    : 0;
  weatherDataObject.precipitation = weatherDataDuringTime
    ? weatherDataDuringTime.precip
    : 0;
  weatherDataObject.snow = weatherDataDuringTime
    ? weatherDataDuringTime.snow
    : 0;
  weatherDataObject.visibility = weatherDataDuringTime
    ? weatherDataDuringTime.visibility
    : 0;
  weatherDataObject.windSpeed = weatherDataDuringTime
    ? weatherDataDuringTime.windSpeed
    : undefined;

  return weatherDataObject;
};

export const populateMilkingWithWeatherData = (
  currentMilkingData,
  hoursDataObject,
) => {
  let milkingTimeString = currentMilkingData.created.toString();
  let milkingWeatherDataObject = getWeatherDataForTime(
    milkingTimeString,
    hoursDataObject,
  );
  currentMilkingData.weatherData = milkingWeatherDataObject;
};

export const getWeatherDataByTimeString = (timeString, hoursDataObject) => {
  let hoursString = getHoursPartFromBackendDate(timeString);
  let weatherDataForTheRespectiveHour = hoursDataObject.filter(
    (currentHourWeatherData) =>
      currentHourWeatherData.datetime.indexOf(hoursString) !== -1,
  );
  return weatherDataForTheRespectiveHour && weatherDataForTheRespectiveHour[0];
};

export const getWeatherDataByDateObject = (dateObject, hoursDataObject) => {
  let hoursString =
    dateObject.getHours() < 10
      ? "0" + dateObject.getHours()
      : dateObject.getHours();
  let weatherDataForTheRespectiveHour = hoursDataObject.filter(
    (currentHourWeatherData) =>
      currentHourWeatherData.datetime.indexOf(hoursString) !== -1,
  );
  return weatherDataForTheRespectiveHour && weatherDataForTheRespectiveHour[0];
};

export const getHoursPartFromBackendDate = (backendDateString) => {
  return (
    backendDateString &&
    backendDateString.length > 0 &&
    backendDateString.substring(11, 13)
  );
};
