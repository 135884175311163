import React from "react";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import IconButton from "../IconButton/IconButton";
import { translate } from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import "./VectorPopup.scss";

function VectorPopup({ closePopupClickFn, callToActionClickFn,isAdultSelected }) {
  return (
    <div className="vectorPopup">
      <div className="vectorPopup--container">
        <IconButton
          cssClassName="vectorPopup--btnClosePopup"
          onClickFn={closePopupClickFn}
        /> 
      <div className="vectorPopup--title">{translate("VECTOR_TITLE")}</div>
        <div className="vectorPopup--description">
        {translate("VECTOR_DESCRIPTION_TEXT")}
      </div>
      <CallToActionButton
        isAdultSelected={isAdultSelected}
        cssClassName="vectorPopup-callToActionButton"
        label={translate("VECTOR_CALL_TO_ACTION_BUTTON")}
        onClickFn={() => {
          callToActionClickFn();
          closePopupClickFn();
        }}
      />
      </div>
      
      {/* <CallToActionButton
        cssClassName="vectorPopup-callToActionButton"
        label={translate("MILK_FACTORY_POPUP_SEE_HISTORY_BUTTON_LABEL")}
        onClickFn={() => {
          callToActionClickFn();
          closePopupClickFn();
        }}
      /> */}
      <Graphic cssClassName="vectorPopup--vector" /> 
    </div>
  );
}

export default VectorPopup;
