import axios from "axios";
import { VIRTUAL_FARM_SERVICE_URL } from "./config";

export const api = {
  getVirtualFarmDetails: async function (farmId, batchId) {
    let virtualFarmServiceUrl = VIRTUAL_FARM_SERVICE_URL;
    virtualFarmServiceUrl += farmId ? "/" + farmId : "";
    virtualFarmServiceUrl += batchId ? "/" + batchId : "";
    return axios.get(virtualFarmServiceUrl, {});
  },
};
