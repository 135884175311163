import React from "react";
import "./VirtualFarmFooter.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const VirtualFarmFooter = ({}) => {
  return (
    <div className="virtualFarmFooter">
      <div className="virtualFarmFooter--grassLeft"></div>
      <div className="virtualFarmFooter--grassCenter"></div>
      <div className="virtualFarmFooter--grassRight"></div>
    </div>
  );
};

export default VirtualFarmFooter;
