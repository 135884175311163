import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
import { getMilkFactorySvgFilePath } from "../../common/svgUtil";
import Graphic from "../Graphic/Graphic";
import React, { useCallback } from "react";
import "./MilkFactory.scss";
import { measureResponsive } from "../../components/VirtualFarm/util";
import {
  ADULT_THEME_CODE,
  IS_CLICKABLE_CSS_CLASSNAME,
} from "../../common/constants";

/** MilkFactory component */
function MilkFactory({
  milkFactoryData,
  milkFactoryCssClassname,
  milkFactoryXCoordinate,
  milkFactoryYCoordinate,
  milkFactoryScaleX,
  milkFactoryClickHandlerFn,
  additionalCssClassnames,
  farmTheme,
  doPositionFixed,
  isClickable,
  isInteractable,
  isAdultSelected,
}) {
  let transformValue =
    "translateX(" +
    milkFactoryXCoordinate +
    ") translateY(" +
    milkFactoryYCoordinate +
    ") scaleX(" +
    milkFactoryScaleX +
    ")";
  let milkFactoryPositionStyles = doPositionFixed
    ? {}
    : {
        transform: transformValue,
      };
  let milkFactorySubmoduleCssClassname =
    milkFactoryCssClassname + " " + additionalCssClassnames;
  milkFactorySubmoduleCssClassname += isInteractable
    ? " virtualFarm--interactable virtualFarm--highlighted"
    : "";
  milkFactorySubmoduleCssClassname = isClickable
    ? milkFactorySubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : milkFactorySubmoduleCssClassname;
  farmTheme = isAdultSelected ? ADULT_THEME_CODE : farmTheme;
  let milkFactorySvgPath = getMilkFactorySvgFilePath(farmTheme);
  const onCompletedCallback = useCallback((e) => {}, []);
  const onErrorCallback = useCallback((e) => {
    console.log("@onError e:", e);
  }, []);
  return (
    <div
      style={{
        left: measureResponsive(63.071),
        width: measureResponsive(15.9209),
        top: measureResponsive(20.8492),
        height: measureResponsive(17.5455),
      }}
      className={milkFactorySubmoduleCssClassname + " milkFactory--animated"}
      onClick={() => {
        milkFactoryClickHandlerFn(milkFactoryData);
      }}
    >
      <h2
        style={{
          transform: "scaleX(" + milkFactoryScaleX + ")",
        }}
        className={milkFactoryCssClassname + "--title"}
      >
        {milkFactoryData.name}
      </h2>
      {isInteractable ? (
        <DynamicInlineSVG
          svgFullFilePath={milkFactorySvgPath}
          themeName={farmTheme}
          onCompleted={onCompletedCallback}
          onError={onErrorCallback}
        />
      ) : null}
      {isInteractable ? <Graphic cssClassName="is-actor-pointed" /> : null}
    </div>
  );
}
export default MilkFactory;
