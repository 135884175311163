import { GET_ASTRONAUT_DETAILS_REQUEST } from "./types.js";
import {
  getAstronautDetailsError,
  getAstronautDetailsSuccess,
} from "./actions.js";
import { put, take, call } from "redux-saga/effects";
import { getServerResponseStatus } from "../ErrorPopUp/actions";
import {
  startLoading,
  endLoading,
} from "../../common/redux/loadManager/actions";
import { getLoadId } from "../../common/redux/loadManager/methods";

let currentLoadId = 0;

export default function* astronautDetailsSaga(api) {
  while (true) {
    const astronautDetailsRequestAction = yield take(
      GET_ASTRONAUT_DETAILS_REQUEST,
    );
    /** Update state when server is online */
    yield put(getServerResponseStatus(""));
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { farmId, cowId } = astronautDetailsRequestAction.payload;
    yield call(getAstronautDetails, api, farmId, cowId);
  }
}

/** Create request to get virtual farm details
 * @param {string} accessToken - User access token
 * @param {any} farmId - Farm id
 */
function* getAstronautDetails(api, farmId, cowId) {
  try {
    const astronautDetails = yield call(api.getAstronautDetails, farmId, cowId);
    yield put(getAstronautDetailsSuccess(astronautDetails.data));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    console.log("@error:", error);
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(getAstronautDetailsError(error));
    /** Listen for error state to show when server is disconected */
    yield put(getServerResponseStatus(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}
