import {
  REQUEST_LANGUAGES_INFORMATION_SUCCESS,
  REQUEST_LANGUAGES_INFORMATION_ERROR,
  SAVE_CHANGES_TO_LANGUAGES_SUCCESS,
  SAVE_CHANGES_TO_LANGUAGES_ERROR
} from '../common/components/LanguageSelection/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  farmLanguagesDetails: {},
  errorMessage: '',
  isLatestSaveSuccessful: false,
  saveChangesErrorMessage: '',
};

/** Farm languages reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function farmLanguagesReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_LANGUAGES_INFORMATION_SUCCESS:
      return {
        ...state,
        farmLanguagesDetails: action.payload,
        errorMessage: '',
      };
    case REQUEST_LANGUAGES_INFORMATION_ERROR:
      return {
        ...state,
        farmLanguagesDetails: {},
        errorMessage: action.payload,
      };
    case SAVE_CHANGES_TO_LANGUAGES_SUCCESS:
      return {
        ...state,
        isLatestSaveSuccessful: true,
        saveChangesErrorMessage: '',
      };
    case SAVE_CHANGES_TO_LANGUAGES_ERROR:
      return {
        ...state,
        isLatestSaveSuccessful: false,
        saveChangesErrorMessage: action.payload,
      };

    default:
      return state;
  }
}

export default farmLanguagesReducer;
