
export const getRandomPositionForACloud = function (cloudMinXCoordinate, cloudMaxXCoordinate, cloudMinYCoordinate, cloudMaxYCoordinate) {
    let randomXCoordinate = getRandomNumberBetweenMinAndMax(cloudMinXCoordinate, cloudMaxXCoordinate);
    let randomYCoordinate = getRandomNumberBetweenMinAndMax(cloudMinYCoordinate, cloudMaxYCoordinate);
    return {
        left: `${randomXCoordinate}px`,
        top: `${randomYCoordinate}px`
    };
};

export const getRandomNumberBetweenMinAndMax = function (min, max) { // including min and max
    return Math.floor(Math.random() * (max - min + 1) + min);
};