import {
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  Radio,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import './CheckboxGroupSelection.scss';
import { lelyRed } from '../../styles/variables.scss';

const RedCheckbox = withStyles({
  root: {
    '&$checked': {
      color: lelyRed,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

/** CheckboxGroupSelection component
 * A set of radio buttons
 * only one of which can be selected
 * @param {Object} props - Object with all props
 * @param {Number} props.defaultSelectedLabel - Label of the radio button that should be selected by default (default is empty string if not specified)
 * @param {Array} props.optionsArray - Array of radio button labels. For each of those labels a Radio button component will be created
 * @param {String} props.componentLabel - Label that should be displayed on top of the radio button group
 * @param {Function} props.onSelectionChange - Optional function that will be called when a radio button from the group is selected
 * @param {Boolean} [props.isDisabled] - Disable all radio buttons
 */
export default function CheckboxGroupSelection({
  defaultSelectedLabel,
  optionsArray,
  componentLabel,
  onSelectionChange,
  isDisabled,
  selectedOptions,
}) {
  const [selectedLabel, setSelectedLabel] = useState(defaultSelectedLabel);
  console.log('selectedOptions',selectedOptions)
  function handleRadioChange(event) {
    if (event.target.value) {
      const newlySelectedRadioButtonLabel = event.target.value;
      setSelectedLabel(newlySelectedRadioButtonLabel);
      if (onSelectionChange) {
        onSelectionChange(newlySelectedRadioButtonLabel, event.target.checked);
      }
    }
  }

  return (
    <FormControl component="fieldset" className="checkbox-group-selection">
      <FormLabel
        component="legend"
        className="checkbox-group-selection--label"
        focused={false}
      >
        {componentLabel}
      </FormLabel>
      <div className="checkbox-group-selection--container">
        {optionsArray &&
          optionsArray.map((currentOption, index) => {
            let currentLabel = currentOption.label;
            console.log('currentOption',currentOption)
            let currentOptionClassName =
              'checkbox-group-selection--form-control ' +
              'checkbox-group-selection--form-control-' +
              currentOption.classNameAppendix;
            return (
              <div
                className="checkbox-group-selection--checkbox-item"
                key={index}
              >
                <FormControlLabel
                  className={currentOptionClassName}
                  key={currentLabel}
                  value={currentOption.value || currentLabel}
                  control={<RedCheckbox />}
                  label={currentLabel}
                  disabled={isDisabled}
                  onClick={handleRadioChange}
                  checked={
                    (selectedOptions &&
                      selectedOptions.filter(currentSelectedOption => currentSelectedOption === currentOption.value).length > 0) ||
                    false
                  }
                />
              </div>
            );
          })}
      </div>
    </FormControl>
  );
}
