import React from "react";
import LoginForm from "../../../components/UserManagement/LoginForm/LoginForm";
import "./LoginPage.scss";
import UserManagementContainer from "../../../components/UserManagement/UserManagementContainer/UserManagementContainer";

function LoginPage() {
  return (
    <UserManagementContainer>
      <LoginForm></LoginForm>
    </UserManagementContainer>
  );
}

export default LoginPage;
