import React from "react";
import {
  translate,
  translateWithValues,
} from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import IconButton from "../IconButton/IconButton";
import "./SolarPanelPopup.scss";

/** SustainabilityPlatePopup component */
function SolarPanelPopup({
  selectedSolarPanelsDetails,
  closePopupClickFn,
  solarPanelData,
}) {
  const numberOfSolarPanels = solarPanelData && solarPanelData.numberOfPanels;
  const percentageOfSolarPanels =
    solarPanelData && solarPanelData.solarEnergyPercentage;
  const farmName = solarPanelData.farmName;
  //{translateWithValues(')}
  console.log("solarPanelData", solarPanelData);
  return (
    <div className="solarPanel">
      <div className="solarPanelTextInfo">
      <IconButton
          cssClassName="solarPanel--btnClosePopup"
          onClickFn={closePopupClickFn}
        />
        <div className="solarPanel--title">
          {translate("SOLAR_PANEL_TITLE_POPUP")}
        </div>
        <div className="solarPanel--description">
          {" "}
          {translateWithValues("SOLARPANEL_DESCRIPTION", {
            farmName: farmName,
            numberOfPanels: numberOfSolarPanels,
            percentageOfPanels: percentageOfSolarPanels,
          })}
        </div>
        
      </div>

      <Graphic cssClassName="solarPanel--logo" />
    </div>
  );
}
export default SolarPanelPopup;
