import {
  ANIMAL_TYPE_ALPACA,
  ANIMAL_TYPE_CAT,
  ANIMAL_TYPE_CHICKEN,
  ANIMAL_TYPE_DONKEY,
  ANIMAL_TYPE_GOAT,
  ANIMAL_TYPE_HORSE,
  ANIMAL_TYPE_LARGE_DOG,
  ANIMAL_TYPE_LLAMA,
  ANIMAL_TYPE_MEDIUM_DOG,
  ANIMAL_TYPE_PONY,
  ANIMAL_TYPE_SHEEP,
  ANIMAL_TYPE_SMALL_DOG,
} from "../../common/constants";
import { translate } from "../../localization/TranslationUtil";

export const getEmptyAnimal = (languageKeys, newAnimalId) => {
  let newAnimalObject = {
    textBlockIndex: newAnimalId,
    animalType: "",
    name: "",
    imageData: "",
  };
  newAnimalObject.story = {};
  languageKeys.forEach((currentLanguageKey) => {
    newAnimalObject.story[currentLanguageKey] = "";
  });
  return newAnimalObject;
};

export const doesAnimalsListHasNDogsAlready = (
  animalsArray,
  maxNumberOfDogs,
) => {
  let numberOfDogsInAnimalsArray = getNumberOfDogsInAnimalsArray(animalsArray);
  return numberOfDogsInAnimalsArray === maxNumberOfDogs;
};

export const isCurrentAnimalDog = (animalBlockData) => {
  return (
    animalBlockData.animalType === ANIMAL_TYPE_LARGE_DOG ||
    animalBlockData.animalType === ANIMAL_TYPE_SMALL_DOG ||
    animalBlockData.animalType === ANIMAL_TYPE_MEDIUM_DOG
  );
};

export const getNumberOfDogsInAnimalsArray = (animalsArray) => {
  let numberOfDogs = 0;
  animalsArray.forEach((currentAnimalBlock) => {
    if (isCurrentAnimalDog(currentAnimalBlock)) {
      numberOfDogs++;
    }
  });
  return numberOfDogs;
};

export const doesAnimalsListHaveCatAlready = (animalsArray) => {
  let catsInAnimalsArray = animalsArray.filter((currentAnimalData) => {
    return currentAnimalData.animalType === ANIMAL_TYPE_CAT;
  });
  return catsInAnimalsArray.length > 0;
};
export const doesAnimalsListHaveHorseAlready = (animalsArray) => {
  let horseInAnimalsArray = animalsArray.filter((currentAnimalData) => {
    return currentAnimalData.animalType === ANIMAL_TYPE_HORSE;
  });
  return horseInAnimalsArray.length > 0;
};

export const getAnimalOptionsArray = (
  areCatsEnabled,
  areDogsEnabled,
  areHorsesEnabled,
) => {
  const DOG_OPTIONS = [
    {
      label: translate("ABOUT_YOU_PAGE_DO_YOU_HAVE_DOG_VALUE_2_LABEL"),
      classNameAppendix: "dogSmall",
      value: ANIMAL_TYPE_SMALL_DOG,
    },
    {
      label: translate("ABOUT_YOU_PAGE_DO_YOU_HAVE_DOG_VALUE_3_LABEL"),
      classNameAppendix: "dogMedium",
      value: ANIMAL_TYPE_MEDIUM_DOG,
    },
    {
      label: translate("ABOUT_YOU_PAGE_DO_YOU_HAVE_DOG_VALUE_4_LABEL"),
      classNameAppendix: "dogLarge",
      value: ANIMAL_TYPE_LARGE_DOG,
    },
  ];
  const CAT_OPTIONS = [
    {
      label: translate(
        "ABOUT_YOU_PAGE_DO_YOU_HAVE_OTHER_ANIMALS_VALUE_2_LABEL",
      ),
      classNameAppendix: "otherAnimalsCat",
      value: ANIMAL_TYPE_CAT,
    },
  ];
  const HORSE_OPTIONS = [
    {
      label: translate(
        "ABOUT_YOU_PAGE_DO_YOU_HAVE_OTHER_ANIMALS_VALUE_4_LABEL",
      ),
      classNameAppendix: "otherAnimalsHorse",
      value: ANIMAL_TYPE_HORSE,
    },
  ];
  const OTHER_ANIMALS_OPTIONS = [
    {
      label: translate(
        "ABOUT_YOU_PAGE_DO_YOU_HAVE_OTHER_ANIMALS_VALUE_3_LABEL",
      ),
      classNameAppendix: "otherAnimalsPony",
      value: ANIMAL_TYPE_PONY,
    },
    {
      label: translate(
        "ABOUT_YOU_PAGE_DO_YOU_HAVE_OTHER_ANIMALS_VALUE_5_LABEL",
      ),
      classNameAppendix: "otherAnimalsLlama",
      value: ANIMAL_TYPE_LLAMA,
    },
    {
      label: translate(
        "ABOUT_YOU_PAGE_DO_YOU_HAVE_OTHER_ANIMALS_VALUE_6_LABEL",
      ),
      classNameAppendix: "otherAnimalsAlpaca",
      value: ANIMAL_TYPE_ALPACA,
    },
    {
      label: translate(
        "ABOUT_YOU_PAGE_DO_YOU_HAVE_OTHER_ANIMALS_VALUE_7_LABEL",
      ),
      classNameAppendix: "otherAnimalsDonkey",
      value: ANIMAL_TYPE_DONKEY,
    },
    {
      label: translate(
        "ABOUT_YOU_PAGE_DO_YOU_HAVE_OTHER_ANIMALS_VALUE_8_LABEL",
      ),
      classNameAppendix: "otherAnimalsSheep",
      value: ANIMAL_TYPE_SHEEP,
    },
    {
      label: translate(
        "ABOUT_YOU_PAGE_DO_YOU_HAVE_OTHER_ANIMALS_VALUE_9_LABEL",
      ),
      classNameAppendix: "otherAnimalsGoat",
      value: ANIMAL_TYPE_GOAT,
    },
    {
      label: translate(
        "ABOUT_YOU_PAGE_DO_YOU_HAVE_OTHER_ANIMALS_VALUE_10_LABEL",
      ),
      classNameAppendix: "otherAnimalsChicken",
      value: ANIMAL_TYPE_CHICKEN,
    },
  ];
  let animalOptionsArray = [];
  if (areDogsEnabled) {
    animalOptionsArray = animalOptionsArray.concat(DOG_OPTIONS);
  }
  if (areHorsesEnabled) {
    animalOptionsArray = animalOptionsArray.concat(HORSE_OPTIONS);
  }
  if (areCatsEnabled) {
    animalOptionsArray = animalOptionsArray.concat(CAT_OPTIONS);
  }
  animalOptionsArray = animalOptionsArray.concat(OTHER_ANIMALS_OPTIONS);

  return animalOptionsArray;
};
