//#region imports
import Graphic from "../Graphic/Graphic";
import React, { useCallback, useEffect, useRef } from "react";
import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
//import DynamicInlineSVG from '../../common/customHooks/DynamicInlineSVG';
import { getCowSvgFilePath } from "../../common/svgUtil";
import "./Cow.scss";
import { measureResponsive } from "../../components/VirtualFarm/util";
import { gsap } from "gsap";
import { useDispatch, useSelector } from "react-redux";
import { updateActorPosition } from "../../components/Actor/actions";
import { areThereOtherActorsReallyCloseBy } from "./CollisionDetectionUtil";
import { showExcrementForCow } from "./actions";
import {
  EXCREMENT_PREFIX,
  IS_CLICKABLE_CSS_CLASSNAME,
} from "../../common/constants";
//import SVG from 'react-inlinesvg';
//#endregion

/** SpaceAwareCow component */
//#region component props declaration
function SpaceAwareCow({
  cowData,
  cowCssClassname,
  cowXCoordinate,
  cowYCoordinate,
  cowScaleX,
  cowClickHandlerFn,
  additionalCssClassnames,
  farmTheme,
  doPositionFixed,
  isInteractable,
  isAnimated,
  isClickable,
}) {
  //#endregion
  //#region flip variables declarations
  let cowRef = useRef(null);
  let timelineRef = useRef(null);
  let numberOfFlipsExecutedSoFar = useRef(0);
  //#endregion

  //#region space aware cow movement logic
  const COW_MOVE_AWAY_DISTANCE = 6;
  const dispatch = useDispatch();
  const { actorPositions } = useSelector((state) => state.actorPositions);

  useEffect(() => {
    if (isAnimated) {
      timelineRef.current = gsap.timeline({});
    }
    dispatch(
      updateActorPosition(cowData.externalId, {
        x: +cowXCoordinate,
        y: +cowYCoordinate,
      }),
    );
  }, []);

  useEffect(() => {
    if (areThereOtherActorsReallyCloseBy(cowData.externalId, actorPositions)) {
      moveCow();
    }
  }, [actorPositions]);
  //#endregion

  function moveCow() {
    executeGsapAnimation();
  }

  function executeGsapAnimation() {
    dispatch(showExcrementForCow(cowData.externalId));
    timelineRef.current.fromTo(
      cowRef.current,
      {
        left: measureResponsive(cowXCoordinate),
      },
      {
        left: measureResponsive(cowXCoordinate - COW_MOVE_AWAY_DISTANCE),
        duration: 2,
        repeat: 0,
      },
    );
    timelineRef.current.fromTo(
      cowRef.current,
      {
        left: measureResponsive(cowXCoordinate - COW_MOVE_AWAY_DISTANCE),
      },
      {
        left: measureResponsive(cowXCoordinate),
        duration: 3,
        repeat: 0,
      },
      "+=15",
    );
    numberOfFlipsExecutedSoFar.current = numberOfFlipsExecutedSoFar.current++;
  }
  const getCowBreed = () => {
    return cowData.cowSubBreed ? cowData.cowSubBreed : 'cowHolsteinFriesian--one'; 
  } 
  const getCowSubBreed = () => {
    return cowData.subBreedCssClassName ? cowData.subBreedCssClassName : 'cowHolsteinFriesian--one'; 
  }
  let cowSubmoduleCssClassname =
    cowCssClassname + "--" + cowData &&
    getCowBreed().toLowerCase() + " " + cowData &&
    getCowSubBreed(); // important in order to visualize the right cow type/the right cow SVG image
  cowSubmoduleCssClassname += isInteractable
    ? " virtualFarm--interactable virtualFarm--highlighted"
    : "";
  cowSubmoduleCssClassname = isClickable
    ? cowSubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : cowSubmoduleCssClassname;

  let cowSvgPath = getCowSvgFilePath(farmTheme, cowData.subBreedCssClassName);
  const onCompletedCallback = useCallback((e) => {}, []);
  const onErrorCallback = useCallback((e) => {}, []);
  const cowName =
    (cowData && cowData.poll && cowData.poll.chosenName) || cowData.name;
  return (
    <div
      style={{
        left: measureResponsive(cowXCoordinate),
        top: measureResponsive(cowYCoordinate),
        width: measureResponsive(3.463675),
        height: measureResponsive(3.925498),
      }}
      title={cowName}
      ref={cowRef}
      className={
        cowCssClassname +
        " " +
        cowSubmoduleCssClassname +
        " " +
        additionalCssClassnames
      }
      onClick={() => {
        cowClickHandlerFn(cowData);
      }}
    >
      <h2
        style={{
          transform: "scaleX(" + cowScaleX + ")",
        }}
        className={cowCssClassname + "--title"}
      >
        {cowData.name}
      </h2>
      {isInteractable ? (
        <DynamicInlineSVG
          svgFullFilePath={cowSvgPath}
          themeName={farmTheme}
          onCompleted={onCompletedCallback}
          onError={onErrorCallback}
        />
      ) : null}
      {isInteractable ? <Graphic cssClassName="is-actor-pointed" /> : null}
    </div>
  );
}
export default SpaceAwareCow;
