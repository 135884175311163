import { getRandomNumberBetweenMAndN } from "../../components/VirtualFarm/util";
import React, { useEffect, useRef } from "react";
import "./BirthdayPartyHat.scss";


function BirthdayPartyHat({ totalNumberOfAvailablePartyHatVarieties }) {
    const BASE_CLASSNAME = "birthdayPartyHat";
    const birthdayPartyHatContainerRef = useRef(BASE_CLASSNAME);

    useEffect(() => {
        let randomPartyHatId = getRandomNumberBetweenMAndN(1, totalNumberOfAvailablePartyHatVarieties);
        birthdayPartyHatContainerRef.current.className = BASE_CLASSNAME + " " + BASE_CLASSNAME + "-" + randomPartyHatId;
    }, []);

    return <div
        className={BASE_CLASSNAME}
        ref={birthdayPartyHatContainerRef}
    >
    </div>;
}

export default BirthdayPartyHat;
