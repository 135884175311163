import React from "react";
import "./MijnMelkBottle.scss";
import "./MijnMelkBottleVariety.scss";
import { getMijnMelkBottleCssClassByFarmId } from "./milkBottleImageUtil";
import { getMijnMelkPopupSvgFilePath } from "../../common/svgUtil";

function MijnMelkBottle({ farmId, isShiftedLeft, isFullLeft, batchProductType }) {
  
  let containerElementCssClassName = "mijnMelkBottle--container";

  const onboardingBottleCssClassNameIsFullLeft = batchProductType === "Yogurt"
  ? " yoghurt-is-full-left"
  : " is-full-left"

  const onboardingBottleCssClassNameIsShiftedRight = batchProductType === "Yogurt"
  ? " yoghurt-is-shifted-right"
  : " is-shifted-right"
  
  containerElementCssClassName = isFullLeft
    ? containerElementCssClassName + onboardingBottleCssClassNameIsFullLeft
    : containerElementCssClassName;
  if (!isFullLeft) {
    containerElementCssClassName = isShiftedLeft
      ? containerElementCssClassName + " is-shifted-left"
      : containerElementCssClassName + onboardingBottleCssClassNameIsShiftedRight;
  }

  const mijnMelkBottleCssClassname = getMijnMelkPopupSvgFilePath(farmId, batchProductType);

  return (
    <div className={containerElementCssClassName}>
      {batchProductType?.includes("Yogurt")  || batchProductType?.includes("Yoghurt") 
      ?  <div className={"youghurtBottle " + mijnMelkBottleCssClassname} />
      : <div className={"mijnMelkBottle " + mijnMelkBottleCssClassname} />
      }
      <div className="mijnMelkBottle--grass" />
    </div>
  );
}
export default MijnMelkBottle;
