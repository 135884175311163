import React from "react";
import "./UserManagementContainer.scss";
import { translate } from "../../../localization/TranslationUtil";

/** UserManagementContainer component */
function UserManagementContainer({ children }) {
  return (
    <div className="user-management-container">
      <div className="user-management-container--lely-logo"></div>
      <h3 className="user-management-container--welcome-text">
        {translate("USER_MANAGEMENT_WELCOME_TEXT")}
      </h3>
      <div className="user-management-container--current-section-container">
        {children}
      </div>
    </div>
  );
}

export default UserManagementContainer;
