import Graphic from "../Graphic/Graphic";
import React, { useCallback } from "react";
import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
import { getFarmerSvgFilePath } from "../../common/svgUtil";
import { translateDynamic } from "../../localization/TranslationUtil";
import "./Farmer.scss";
import { measureResponsive } from "../../components/VirtualFarm/util";
import {
  ADULT_THEME_CODE,
  IS_CLICKABLE_CSS_CLASSNAME,
} from "../../common/constants";

/** Farmer component */
const Farmer = React.memo(
  ({
    farmerData,
    farmerFarmName,
    farmerCssClassname,
    farmerXCoordinate,
    farmerYCoordinate,
    farmerScaleX,
    farmerClickHandlerFn,
    farmTheme,
    additionalCssClassnames,
    isInteractable,
    isClickable,
    farmerStyle,
    isAdultSelected,
  }) => { 
    let farmerSubmoduleCssClassname =
      farmerCssClassname + "--" + getGenderCss(farmerStyle);
    farmerSubmoduleCssClassname += isInteractable
      ? " virtualFarm--interactable virtualFarm--highlighted"
      : "";
    farmerData.farmName = farmerFarmName;
    let transformValue =
      "translateX(" +
      farmerXCoordinate +
      ") translateY(" +
      farmerYCoordinate +
      ") scaleX(" +
      farmerScaleX +
      ")";
    function getGenderCss(farmerStyle) {
      return farmerStyle ? farmerStyle : "male";
    }
    let farmerSvgPath = getFarmerSvgFilePath(
      farmTheme,
      getGenderCss(farmerStyle),
    ).toLowerCase();

    function getGenderCss(farmerStyle) {
      return farmerStyle ? farmerStyle : "male";
    }

    farmerSubmoduleCssClassname = isClickable
      ? farmerSubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
      : farmerSubmoduleCssClassname;
    return (
      <div
        style={{
          left: measureResponsive(57.5008),
          width: measureResponsive(2.77094),
          top: measureResponsive(11.392735),
          height: measureResponsive(4.61823),
        }}
        className={
          farmerCssClassname +
          " " +
          farmerSubmoduleCssClassname +
          " " +
          additionalCssClassnames
        }
        onClick={() => {
          farmerClickHandlerFn(farmerData);
        }}
      >
        <h2
          style={{
            transform: "scaleX(" + farmerScaleX + ")",
          }}
          className={farmerCssClassname + "--title"}
        >
          {translateDynamic(farmerData.name)}
        </h2>
        {isInteractable ? (
          <DynamicInlineSVG
            svgFullFilePath={farmerSvgPath}
            themeName={isAdultSelected ? ADULT_THEME_CODE : farmTheme}
            onCompleted={useCallback((e) => {}, [])}
            onError={useCallback((e) => {
              console.log("@onError e:", e);
            }, [])}
          />
        ) : null}
        {isInteractable ? <Graphic cssClassName="is-actor-pointed" /> : null}
      </div>
    );
  },
);
export default Farmer;
