import _ from "lodash";
import React, { useEffect } from "react";
import "./CowDailyIntroPage.scss";
import TextCaption from "../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";
import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import { COW_CSS_CLASSNAME } from "../../common/constants";
import Cow from "../../components/Cow/Cow";
import moment from "moment";
import { getFarmHouseStyleCssClassname } from "../../common/farmUtil";
import StoryNavigationHint from "../../common/components/StoryNavigationHint/StoryNavigationHint";

function CowDailyIntroPage() {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const { cowDailyDetails } = useSelector((state) => state.cowDailyDetails);
  const farmStyle = farmDetails && farmDetails.style.toLowerCase();

  useEffect(() => {}, [
    !_.isEmpty(selectedCowDetails) && !_.isEmpty(cowDailyDetails),
  ]);

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div className="cowDailyIntroPage">
        <TextCaption additionalCssClassname="textCaption--cowDailyIntro">
          <span className="cow-daily-intro--calendar"></span>
          <p
            className="cow-daily-intro--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml("COW_DAILY_INTRO_TEXT"),
            }}
          ></p>
          <span className="cow-daily-intro--name">
            {cowDailyDetails &&
              moment(new Date(cowDailyDetails.lastUpdated))
                .format("D MMM YYYY")
                .toUpperCase()}
          </span>
        </TextCaption>
        <span className="cow-daily-intro--calendarAdultVersion">
          <div className="cow-daily-intro--monthForAdultTheme">
            {cowDailyDetails &&
              moment(new Date(cowDailyDetails.lastUpdated)).format("MMMM")}
          </div>
          <div className="cow-daily-intro--dayForAdultTheme">
            {cowDailyDetails &&
              moment(new Date(cowDailyDetails.lastUpdated)).format("DD")}
          </div>
        </span>
        <TextCaption additionalCssClassname="textCaption--cowDailyIntroAdultVersion">
          <p
            className="cow-daily-intro--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml("COW_DAILY_INTRO_TEXT"),
            }}
          ></p>
        </TextCaption>
        <StoryNavigationHint />
        <div className={getFarmHouseStyleCssClassname(farmStyle)}></div>
        <div className="cow-daily-intro--tree"></div>
        <div className="cow-daily-intro--cow"></div>
        <Cow
          cowData={selectedCowDetails}
          cowCssClassname={COW_CSS_CLASSNAME}
          cowXCoordinate={40 + "vw"}
          cowYCoordinate={0}
          cowScaleX={1}
          cowClickHandlerFn={(clickedCowData) => {}}
          additionalCssClassnames="cow--bigger cow--daily-intro"
          doPositionFixed={true}
        />
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowDailyIntroPage;
