import _ from "lodash";
import React, { useEffect } from "react";
import "./CowBirthdayCakeSlide.scss";
import TextCaption from "../../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";
import BirthdayCake from "../../../components/BirthdayCake/BirthdayCake";

function CowBirthdayCakeSlide() {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );

  const firstBirthdayDate = new Date(selectedCowDetails.birthTimestamp);
  firstBirthdayDate.setFullYear(firstBirthdayDate.getFullYear() + 1);

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div className="cow--birthdayCakeSlide">
        <div className="cow-birthdayCakeSlide--temperature"></div>
        <TextCaption additionalCssClassname="textCaption--birthdayCakeSlide--degrees">
          {selectedCowDetails.temperatureAtFirstBirthday}°c{" "}
        </TextCaption>
        <span className="cow--birthdayCakeSlide--box"></span>
        <span className="cow--birthdayCakeSlide--bigBox"></span>
        <span className="cow--birthdayCakeSlide--bigPartyFlag"></span>
        <span className="cow--birthdayCakeSlide--arrow"></span> 
        <div className="cow--birthdayCakeSlide--treeAdultVersion"></div>
        <BirthdayCake />
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowBirthdayCakeSlide;
