import React from "react";
import "./UdderXYZInfo.scss";
import TextCaption from "../../components/TextCaption/TextCaption";
import { translate } from "../../localization/TranslationUtil";

const UdderXYZInfo = ({ x, y, z }) => {
  return (
    <div className="udderXYZInfo">
      <div className="udderXYZInfo--x">
        <span className="udderXYZInfo--caption">{`${translate(
          "UDDER_XYZ_INFO_X_CAPTION",
        )}:`}</span>
        <span className="udderXYZInfo--value">{x}</span>
      </div>
      <div className="udderXYZInfo--y">
        <span className="udderXYZInfo--caption">{`${translate(
          "UDDER_XYZ_INFO_Y_CAPTION",
        )}:`}</span>
        <span className="udderXYZInfo--value">{y}</span>
      </div>
      <div className="udderXYZInfo--z">
        <span className="udderXYZInfo--caption">{`${translate(
          "UDDER_XYZ_INFO_Z_CAPTION",
        )}:`}</span>
        <span className="udderXYZInfo--value">{z}</span>
      </div>
    </div>
  );
};

export default UdderXYZInfo;
