import _ from "lodash";
import React, { useEffect } from "react";
import "./CowCalmRobotSecondSlide.scss";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../localization/TranslationUtil";
import { ACTOR_TYPE_CALM_ROBOT } from "../../../common/constants";
import IconButton from "../../../components/IconButton/IconButton";
import { showPopup } from "../../../common/components/PopupContainer/actions";

const CowCalmRobotSecondSlide = React.memo(() => {
  const dispatch = useDispatch();
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div className="cow-calm--robot--secondContainer">
        <div className="cow-calm--robot--secondSlide">
          <div
            className="cow-calm--robot--milkRobot"
            onClick={() => {
              dispatch(showPopup({ type: ACTOR_TYPE_CALM_ROBOT }));
            }}
          ></div>
          <IconButton
            cssClassName="cowCalmRobot--button"
            onClickFn={() => {
              dispatch(showPopup({ type: ACTOR_TYPE_CALM_ROBOT }));
            }}
          />
          <div className="cow-calm--robot--tree"></div>
          <div className="cow-calm--robot--greyPart"></div>
          <div className="cow-calm--robot--arrowText">
            <span className="cow-calm--robot--arrow"></span>
            <span className="cow-calm--robot--textDrinking">
              {translate("CALM_ROBOT_INFO")}
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
});

export default CowCalmRobotSecondSlide;
