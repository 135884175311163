import React, { useEffect, useRef, useState } from "react";
import "./JunoFeature.scss";
import { measureResponsive } from "../VirtualFarm/util";
import _ from "lodash";
import useWindowDimensions from "../../hooks/useViewportDimensions";
import { gsap, Linear } from "gsap";
import {
  ADULT_THEME_CODE,
  IS_CLICKABLE_CSS_CLASSNAME,
} from "../../common/constants";
import {
  printConsoleHeading,
  printConsoleSubheading,
} from "../../common/consoleUtil";

/** JunoFeature component */
function JunoFeature({
  junoFeatureData,
  junoFeatureCssClassname,
  vectorFeatureScaleX,
  junoFeatureClickHandlerFn,
  additionalCssClassnames,
  doPositionFixed,
  isClickable,
  isAnimationEnabled,
  isAdultSelected,
}) {
  let timelineRef = useRef(null);
  let vectorRobotRef = useRef(null);
  const VECTOR_INITIAL_POSITION_IN_ANIMATION_LABEL = "start";
  const VECTOR_FINAL_POSITION_IN_ANIMATION_LABEL = "end";

  let { width: VIEWPORT_WIDTH_IN_PX, height: VIEWPORT_HEIGHT_IN_PX } =
    useWindowDimensions();

  const TOTAL_ANIMATION_DURATION_IN_SECONDS_PER_DIRECTION = 14;
  const vectorMovementStartPoint = {
    x: measureResponsive(18.701),
    y: measureResponsive(43.873),
  };
  const vectorMovementEndPoint = {
    x: measureResponsive(29.018),
    y: measureResponsive(63.273),
  };

  const [currentVectorCoordinates, setCurrentVectorCoordinates] = useState({
    x: _.cloneDeep(vectorMovementStartPoint.x),
    y: _.cloneDeep(vectorMovementStartPoint.y),
  });
  const vectorPositionRef = useRef(_.cloneDeep(vectorMovementStartPoint.x));
  const VECTOR_FRONT_CSS_CLASSNAME = "junoFeature--front";
  const VECTOR_BACK_CSS_CLASSNAME = "junoFeature--back";
  const vectorCssClassnameRef = useRef(VECTOR_FRONT_CSS_CLASSNAME);
  const isAnimationEnabledRef = useRef(true);

  useEffect(() => {
    timelineRef.current = gsap.timeline({});
    moveVector();
  }, []);

  useEffect(() => {
    resumeMovingVector();
  }, [VIEWPORT_HEIGHT_IN_PX]);

  useEffect(() => {
    isAnimationEnabledRef.current = isAnimationEnabled;
  }, [isAnimationEnabled]);

  function resumeMovingVector() {
    animateVectorWithGSAP();
  }

  function getVectorCssClassname(classNameAppendix) {
    let vectorCssClassname = junoFeatureCssClassname + " " + classNameAppendix;
    vectorCssClassname = additionalCssClassnames
      ? vectorCssClassname + " " + additionalCssClassnames
      : vectorCssClassname;
    return vectorCssClassname;
  }

  function animateVectorWithGSAP() {
    timelineRef.current.kill();
    timelineRef.current = gsap.timeline({
      repeat: -1,
    });
    let gsapGlobalConfig = {
      duration: 10,
      delay: 0,
      ease: Linear.easeNone,
    };
    timelineRef.current.addLabel(VECTOR_INITIAL_POSITION_IN_ANIMATION_LABEL);
    timelineRef.current.fromTo(
      vectorRobotRef.current,
      {
        left: vectorMovementStartPoint.y,
        top: vectorMovementStartPoint.x,
        ...gsapGlobalConfig,
      },
      {
        left: vectorMovementEndPoint.y,
        top: vectorMovementEndPoint.x,
        ...gsapGlobalConfig,
        onComplete: function () {
          if (vectorRobotRef.current) {
            vectorRobotRef.current.className = getVectorCssClassname(
              VECTOR_FRONT_CSS_CLASSNAME,
            );
          }
        },
      },
    );
    timelineRef.current.fromTo(
      vectorRobotRef.current,
      {
        left: vectorMovementEndPoint.y,
        top: vectorMovementEndPoint.x,
        className: getVectorCssClassname(VECTOR_FRONT_CSS_CLASSNAME),
        ...gsapGlobalConfig,
      },
      {
        left: vectorMovementStartPoint.y,
        top: vectorMovementStartPoint.x,
        className: getVectorCssClassname(VECTOR_BACK_CSS_CLASSNAME),
        ...gsapGlobalConfig,
        onComplete: () => {
          if (vectorRobotRef.current) {
            vectorRobotRef.current.className = getVectorCssClassname(
              VECTOR_BACK_CSS_CLASSNAME,
            );
          }

          //animateVectorWithGSAP();
        },
      },
    );
  }

  function moveVector() {
    if (!isAnimationEnabledRef.current) {
      // skip animating the Vector robot if user is currently in the virtual farm tour
      setCurrentVectorCoordinates({
        x: _.cloneDeep(vectorMovementStartPoint.x),
        y: _.cloneDeep(vectorMovementStartPoint.y),
      });
      return;
    }
    animateVectorWithGSAP();
  }
  let vectorRobotCssClassname =
    junoFeatureCssClassname +
    " " +
    vectorCssClassnameRef.current +
    " " +
    additionalCssClassnames;
  vectorRobotCssClassname = isClickable
    ? vectorRobotCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : vectorRobotCssClassname;

  return (
    <div
      style={{
        width: measureResponsive(4.2008),
        height: measureResponsive(4.71282),
        left: vectorMovementStartPoint.y,
        top: vectorMovementStartPoint.x,
      }}
      ref={vectorRobotRef}
      className={vectorRobotCssClassname}
      onClick={() => {
        junoFeatureClickHandlerFn(junoFeatureData);
      }}
    >
      <h2
        style={{
          transform: "scaleX(" + vectorFeatureScaleX + ")",
        }}
        className={junoFeatureCssClassname + "--title"}
      >
        {junoFeatureData.name}
      </h2>
    </div>
  );
}
export default JunoFeature;
