import React from "react";
import "./FourthSlideOrbiter.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const FourthSlideOrbiter = ({}) => {
  return (
    <div className="orbiterStoryFourthSlide">
      <div className="orbiterStoryFourthSlide--floor"></div>
      <TextCaption additionalCssClassname="orbiterStoryFourthSlide--textCaption">
        <div className="orbiterStoryFourthSlide--text">
          {translate("ORBITER_STORY_FOURTH_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="orbiterStoryFourthSlide--robot"></div>
      <div className="orbiterStoryFourthSlide--pipe"></div>
      <div className="orbiterStoryFourthSlide--secondPipe"></div>
      <div className="orbiterStoryFourthSlide--temperature"></div>
      <div className="orbiterStoryFourthSlide--temperatureText">55°c</div>
      <div className="orbiterStoryFourthSlide--temperatureAfter"></div>
      <div className="orbiterStoryFourthSlide--temperatureAfterText">72°c</div> 
      <div className="orbiterStoryFourthSlide--temperatureContainerAdult">
        <div className="orbiterStoryFourthSlide--temperatureContainerAdultText">72°c</div> 
      </div> 
      <div className="orbiterStoryFourthSlide--temperatureContainerAdultBefore"></div>
      <div className="orbiterStoryFourthSlide--milkywayPipe"></div>
      <div className="orbiterStoryFourthSlide--arrow"></div>
    </div>
  );
};

export default FourthSlideOrbiter;
