import React from "react";
import { translate } from "../../localization/TranslationUtil";
import { getDisableCacheAppendix } from "../../common/urlUtil";
import { ReactComponent as Cash } from "../../resources/assets/themes/lely/16x9/ui/icons/cash-icon.svg";
import { ReactComponent as Card } from "../../resources/assets/themes/lely/16x9/ui/icons/card-icon.svg";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import IconButton from "../IconButton/IconButton";
import Shop from "../Shop/Shop";
import "./ShopPopup.scss";

/** ShopPopup component */
function ShopPopup({ selectedShopDetails, closePopupClickFn, isAdultSelected }) {
  const shopPopupTitle = translate("SHOP_TITLE");
  const shopPopupHoursLabel = translate("SHOP_OPENING_HOURS_LABEL");
  const shopPopupAddress = translate("SHOP_ADDRESS_LABEL");
  const shopPopupPaymentOptions = translate("SHOP_WE_ACCEPT_LABEL");
  const shopIsClosed = translate("CLOSED");
  const websiteUrl = selectedShopDetails && selectedShopDetails.websiteUrl;
  const weekOrder = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const hasRouteUrl =
    selectedShopDetails.address && selectedShopDetails.address.googleMapsUrl;
  const mapIcon = {
    Cash: <Cash />,
    ByCard: <Card />,
  };
  const regexWebsiteUrl = new RegExp(/(http(s)?:\/\/)/);
  const isValidWebsiteUrl = (websiteUrl) => {
    const result = websiteUrl && websiteUrl.match(regexWebsiteUrl);
    return result ? websiteUrl : "https://" + `${websiteUrl}`;
  };
  const mapPaymentMethod = {
    Cash: "Cash",
    ByCard: "By Card",
  };

  return (
    <div className="shopPopup">
      <div className="shopPopup--container">
      <IconButton
        cssClassName="shopPopup--btnClosePopup"
        onClickFn={closePopupClickFn}
      />
     
      <div className="shopPopup--title">{shopPopupTitle}</div>
      <div className="shopPopup--description">
        {/* LEFT COLUMN */}
        <div>
          <div className="shopPopup--label">{shopPopupHoursLabel}</div>
          {weekOrder.map((day, index) => {
            return (
              <div className="shopPopup--text flex-row" key={index}>
                <div className="shopPopup--day">
                  {translate(day.toUpperCase())}
                </div>
                <div className="shopPopup--hours">
                  {selectedShopDetails &&
                    selectedShopDetails.workingTimes &&
                    selectedShopDetails.workingTimes.find(
                      (dayObject) =>
                        dayObject.day === day.toLowerCase() &&
                        !dayObject.isClosed,
                    ) //&&
                    ? // selectedShopDetails.workingTimes.find(
                    //   (dayObject) => dayObject.day === day.toLowerCase(),
                    // ).from
                    `${selectedShopDetails.workingTimes.find(
                      (dayObject) => dayObject.day === day.toLowerCase(),
                    ).hourFrom
                    } - ${selectedShopDetails.workingTimes.find(
                      (dayObject) => dayObject.day === day.toLowerCase(),
                    ).hourTo
                    }`
                    : shopIsClosed}
                </div>
              </div>
            );
          })}
        </div>

        {/* RIGHT COLUMN */}
        <div>
          <div className="shopPopup--label">{shopPopupAddress}</div>
          {selectedShopDetails.address &&
            selectedShopDetails.address.streetAndNumber && (
              <div className="shopPopup--text">
                {selectedShopDetails.address.streetAndNumber}
              </div>
            )}
          {selectedShopDetails.address &&
            selectedShopDetails.address.postalAndCity && (
              <div className="shopPopup--text">
                {selectedShopDetails.address.postalAndCity}
              </div>
            )}
          <div className="shopPopup--label">{shopPopupPaymentOptions}</div>
          <div className="flex-row">
            {selectedShopDetails.paymentMethods &&
              selectedShopDetails.paymentMethods.map((payment, index) => {
                return (
                  <div className="shopPopup--text flex-row" key={index}>
                    {mapIcon[payment]} {mapPaymentMethod[payment]}
                  </div>
                );
              })}
          </div>
        </div>
      </div> 
      <div className="shopPopup--action-buttons">
        <CallToActionButton
          cssClassName={`shopPopup--btnCallToAction${hasRouteUrl ? "" : " shopPopup--btnCallToAction-disabled"
            }`}
          isAdultSelected={isAdultSelected}
          label={translate("ROUTE")}
          onClickFn={() =>
            hasRouteUrl &&
            window.open(selectedShopDetails.address.googleMapsUrl, "_blank")
          }
        />
        <CallToActionButton
          cssClassName={`shopPopup--btnCallToActionSecondary${selectedShopDetails.websiteUrl
            ? ""
            : " shopPopup--btnCallToAction-disabled"
            }`}
          label={translate("WEBSHOP")}
          isAdultSelected={isAdultSelected}
          onClickFn={() =>
            selectedShopDetails.websiteUrl &&
            window.open(isValidWebsiteUrl(websiteUrl), "_blank")
          }
        />
      </div>

      </div>
      <div className="shopPopup--image-wrapper">
        {selectedShopDetails.imageData ? (
          <img
            src={getDisableCacheAppendix(selectedShopDetails.imageData)}
            className="shopPopup--image"
          />
        ) : (
          <div className="shopPopup--noImage">
            <Shop />
          </div>
        )}
        <div className="shopPopup--frame" />
      </div>

      {/* ACTION BUTTONS */}
     
      
    </div>
  );
}
export default ShopPopup;
