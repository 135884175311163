import {
  REQUEST_LANGUAGES_INFORMATION, REQUEST_LANGUAGES_INFORMATION_ERROR, REQUEST_LANGUAGES_INFORMATION_SUCCESS, SAVE_CHANGES_TO_LANGUAGES, SAVE_CHANGES_TO_LANGUAGES_ERROR, SAVE_CHANGES_TO_LANGUAGES_SUCCESS
} from './types';


/** Create action which will request information for all languages selected for current farm
 * @param {string} accessToken - User access token
 * @return {Object} Returns action object
 */
export function requestSelectedFarmLanguagesInformation(accessToken) {
  return {
    type: REQUEST_LANGUAGES_INFORMATION,
    payload: {
      accessToken: accessToken,
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of selected farm languagse in the Admin app
 * @param {Object} languageDetails - an object containing the data to be used in order to initialize the selected languages information in the Admin app
 * @return {Object} Return type and languagesDetails
 */
export function getSelectedFarmLanguagesInformationSuccess(languagesDetails) {
  return {
    type: REQUEST_LANGUAGES_INFORMATION_SUCCESS,
    payload: languagesDetails,
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getSelectedFarmLanguagesInformationError(errorMessage) {
  return {
    type: REQUEST_LANGUAGES_INFORMATION_ERROR,
    payload: errorMessage,
  };
}

/** Create action which to be used whenever the user wants to persist the selected farm languages selection to the backend
 * @param {string} accessToken - User access token
 * @param {Object} newLanguageDetails - The latest state of the selected farm languages
 * @return {Object} Returns action object
 */
export function saveChangesToSelectedFarmLanguages(
  accessToken,
  newLanguageDetails,
) {
  return {
    type: SAVE_CHANGES_TO_LANGUAGES,
    payload: {
      accessToken: accessToken,
      newLanguageDetails,
    },
  };
}

/**
 * @return {Object} Returns action object
 */
export function saveChangesToSelectedFarmLanguagesSuccess() {
  return {
    type: SAVE_CHANGES_TO_LANGUAGES_SUCCESS,
    payload: {},
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function saveChangesToSelectedFarmLanguagesError(errorMessage) {
  return {
    type: SAVE_CHANGES_TO_LANGUAGES_ERROR,
    payload: errorMessage,
  };
}