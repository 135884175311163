import React from "react";
import { useSelector } from "react-redux";
import "./Weather.scss";
import "./Rainfall.scss";
import Snowfall from "../../common/components/SnowFall/Snowfall";
import {
  WEATHER_TYPE_DRIZZLE,
  WEATHER_TYPE_HEAVY_RAIN,
  WEATHER_TYPE_RAIN,
  WEATHER_TYPE_RAIN_OVERCAST,
  WEATHER_TYPE_SNOW,
  WEATHER_TYPE_SNOWSTORM,
  WEATHER_TYPE_THUNDERSTORM,
  WEATHER_TYPE_RAIN_PARTIALLY_CLOUDY,
  WEATHER_TYPE_SOME_SNOW,
} from "../../common/constants";
import Thunderstorm from "./Thunderstorm";

/** Weather component */
function Weather({
  viewportWidthInPx,
  viewportHeightInPx,
  cssClassName,
  isAdultThemeApplied,
}) {
  let { currentWeatherString, cloudCover, precipitation, snow, visibility } =
    useSelector((state) => state.storyMoment);
  let isLowSnow = snow > 0 && visibility >= 1;
  let isMediumSnow = snow > 0 && visibility >= 0.5 && visibility < 1;
  let isSnowStorm = snow > 0 && visibility < 0.5;
  let snowFlakeCount = isSnowStorm ? 2000 : 0;
  snowFlakeCount = isLowSnow ? 100 : snowFlakeCount;
  snowFlakeCount = isMediumSnow ? 700 : snowFlakeCount;
  let weatherContainerCssClassname = "weather--container";
  weatherContainerCssClassname = isLowSnow
    ? "weather--container snow--low"
    : weatherContainerCssClassname;
  weatherContainerCssClassname = isMediumSnow
    ? "weather--container snow--medium"
    : weatherContainerCssClassname;
  weatherContainerCssClassname = isSnowStorm
    ? "weather--container snow--high"
    : weatherContainerCssClassname;

  return (
    <div className={weatherContainerCssClassname}>
      {snow > 0 ? (
        <Snowfall
          snowflakeCount={snowFlakeCount}
          strokeColor={isAdultThemeApplied ? "black" : "white"}
          color={"white"}
          radius={[0.5, 2.5]}
          style={{
            width: viewportWidthInPx,
            height: viewportHeightInPx,
          }}
        />
      ) : null}

      {snow === 0 && precipitation > 0 && precipitation < 2.5 ? (
        <div className="rainfall drizzle" />
      ) : null}
      {snow === 0 && precipitation > 2.5 && precipitation < 10 ? (
        <div className="rainfall averagerain" />
      ) : null}
      {snow === 0 && precipitation >= 10 && precipitation < 50 ? (
        <div className="rainfall heavyrain" />
      ) : null}

      {snow === 0 && precipitation > 50 ? (
        <div className="rainfall thunderstorm" />
      ) : null}
      {snow === 0 && precipitation > 50 ? (
        <Thunderstorm
          viewportWidthInPx={viewportWidthInPx}
          viewportHeightInPx={viewportHeightInPx}
        />
      ) : null}
    </div>
  );
}
export default Weather;
