import { HubConnectionBuilder, HubConnectionState } from "@aspnet/signalr";
import { LIVE_DATA_CHANNEL_NAME } from "../../api/lelyBackend/config";

export const liveData = (farmId, signalrUrl, notifyFn) => {
  // Set the initial SignalR Hub Connection.
  let hubConnect;
  // Create request to get canbus components data
  const createHubConnection = async () => {
    // Build new Hub Connection, url is currently hard coded.
    hubConnect = new HubConnectionBuilder().withUrl(signalrUrl).build();
    try {
      await hubConnect
        .start()
        .then(() => {
          hubConnect.invoke("StartListen", farmId);
        })
        .catch((e) => {
          console.error("Disconnected");
        });
      console.log("Connection successful!");
      hubConnect.on("ReceiveMessage", (message) => {
        notifyFn(message);
      });
    } catch (err) {
      console.log("In the catch", err);
      console.assert(hubConnect.state === HubConnectionState.Disconnected);
    }
  };
  createHubConnection();
  return hubConnect;
};

export const stopSignalRConnection = function (liveDataInstance) {
  liveDataInstance.stop().then(() => {
    console.log("signalR stopped");
  });
};
