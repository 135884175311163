import moment from "moment";
import React from "react";
import {
  translate,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import TextCaption from "../TextCaption/TextCaption";
import "./MijnMelkAdultOnboardingSlide2.scss";

function MijnMelkAdultOnboardingSlide2({
  farmName,
  milkBatchDate,
  milkHour1,
  milkHour2,
  milkGoingToBottleHour,
  batchId,
  batchProductType,
}) {
  const MIJN_MELK_ADULT_ONBOARDING_SLIDE2_TEXT1 = batchProductType === "Yogurt"
  ? "MIJN_MELK_ADULT_ONBOARDING_SLIDE2_TEXT1_YOGHURT"
  : "MIJN_MELK_ADULT_ONBOARDING_SLIDE2_TEXT1"
  const MIJN_MELK_ADULT_ONBOARDING_SLIDE2_TEXT3 = batchProductType === "Yogurt"
  ? "MIJN_MELK_ADULT_ONBOARDING_SLIDE2_TEXT3_YOGHURT"
  : "MIJN_MELK_ADULT_ONBOARDING_SLIDE2_TEXT3"
  return (
    <div> 
      <div className="mijnMelkAdultOnboarding-slide1--fromCowToBus"></div>
      <div className="mijnMelkAdultOnboarding-slide1--leftCow"></div>
      <div className="mijnMelkAdultOnboarding-slide1--grain"></div> 
      <div className="mijnMelkAdultOnboarding-slide1--lyingCow"></div> 
      <div className="mijnMelkAdultOnboarding-slide1--dataPassportLabel"></div>
      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide2-text1">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              MIJN_MELK_ADULT_ONBOARDING_SLIDE2_TEXT1,
              {},
            ),
          }}
        ></span>
      </TextCaption>

      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide2-text2">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE2_TEXT2",
              {},
            ),
          }}
        ></span>
      </TextCaption>

      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide2-text3">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              MIJN_MELK_ADULT_ONBOARDING_SLIDE2_TEXT3,
              {},
            ),
          }}
        ></span>
      </TextCaption>

      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide2-text4">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE2_TEXT4",
              {},
            ),
          }}
        ></span>
      </TextCaption>
    </div>
  );
}

export default MijnMelkAdultOnboardingSlide2;
