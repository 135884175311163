import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import userSessionReducer from "./userSessionReducer";
import redirectReducer from "./redirectReducer";
import preloaderReducer from "./preloaderReducer";
import errorPopupReducer from "./errorPopupReducer";
import networkErrorCodeReducer from "./networkErrorCodeReducer";
import loadManagerReducer from "./loadManagerReducer";
import applicationVersionReducer from "./applicationVersionReducer";
import virtualFarmReducer from "./virtualFarmReducer";
import cowReducer from "./cowReducer";
import actorReducer from "./actorReducer";
import tellMeMoreReducer from "./tellMeMoreReducer";
import farmerPageReducer from "./farmerPageReducer";
import aboutTheFarmReducer from "./aboutTheFarmReducer";
import animalsPageReducer from "./animalsReducer";
import cowsPageReducer from "./cowsPageReducer";
import farmFeaturesReducer from "./farmFeaturesReducer";
import cowDailyDetailsReducer from "./cowDailyDetailsReducer";
import virtualFarmTourReducer from "./virtualFarmTourReducer";
import farmLanguagesReducer from "./farmLanguagesReducer";
import storyMomentReducer from "./storyMomentReducer";
import popupReducer from "./popupReducer";
import namePollReducer from "./namePollReducer";
import slideTitleReducer from "./slideTitleReducer";
import excrementReducer from "./excrementReducer";
import confettiReducer from "./confettiReducer";
import actorPositionReducer from "./actorPositionReducer";
import liveDataReducer from "./liveDataReducer";
import astronautReducer from "./astronautReducer";
import udderReducer from "./udderReducer";
import cookiesReducer from "./cookiesReducer";
import languageReducer from "./languageReducer";
import sustainabilityReducer from "./sustainabilityPageReducer";
import globalSaveChangesReducer from "./globalSaveChangesReducer";
import userManagementReducer from "./userManagementReducer";

/** Combine all reducers
 * @returns {Object} store
 */
const appReducer = combineReducers({
  userSession: userSessionReducer,
  redirect: redirectReducer,
  preloader: preloaderReducer,
  responseStatus: errorPopupReducer,
  networkErrorCode: networkErrorCodeReducer,
  loadManager: loadManagerReducer,
  applicationVersion: applicationVersionReducer,
  virtualFarmDetails: virtualFarmReducer,
  selectedCowDetails: cowReducer,
  selectedActorDetails: actorReducer,
  tellMeMoreDetails: tellMeMoreReducer,
  farmerPageDetails: farmerPageReducer,
  aboutTheFarmDetails: aboutTheFarmReducer,
  cowsPageDetails: cowsPageReducer,
  animalsDetails: animalsPageReducer,
  farmFeaturesPageDetails: farmFeaturesReducer,
  cowDailyDetails: cowDailyDetailsReducer,
  virtualFarmTour: virtualFarmTourReducer,
  selectedFarmLanguages: farmLanguagesReducer,
  storyMoment: storyMomentReducer,
  popupDetails: popupReducer,
  namePollDetails: namePollReducer,
  slideTitleDetails: slideTitleReducer,
  confettiDetails: confettiReducer,
  actorPositions: actorPositionReducer,
  excrementDetails: excrementReducer,
  liveDataDetails: liveDataReducer,
  astronautDetails: astronautReducer,
  udderDetails: udderReducer,
  cookieDetails: cookiesReducer,
  selectedLanguageDetails: languageReducer,
  sustainabilityDetails: sustainabilityReducer,
  saveChangesDetails: globalSaveChangesReducer,
  userManagementDetails: userManagementReducer,
});

const rootReducer = (state, action) => {
  // Reset all items in the storage
  if (action.type === "RESET_STORAGE") {
    // Remove all data from store except application version
    const { applicationVersion } = state;
    state = { applicationVersion };
  }
  // Reset all items in storage except userSession
  if (action.type === "RESET_STORAGE_WITHOUT_USER_SESSION") {
    const { userSession, applicationVersion } = state;
    // Keep userSession and applicationVersion in store
    state = { userSession, applicationVersion };
  }
  return appReducer(state, action);
};

export default rootReducer;
