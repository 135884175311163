import React, { useRef, useEffect } from "react";
import "./AstronautTeetAnimationSlide.scss";
import {
  translate,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import MatrixBackground from "./MatrixBackground";
import Udder from "./Udder";
import AstronautCowDropdownMenu from "../../common/components/AstronautCowDropdownMenu/AstronautCowDropdownMenu";
import { useDispatch, useSelector } from "react-redux";
import { getUdderDetails } from "../../common/components/AstronautCowDropdownMenu/actions";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import MatrixColumn from "./MatrixColumn";

const AstronautTeetAnimationSlide = ({}) => {
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const dispatch = useDispatch(); //
  const search = useLocation().search;
  const farmId = new URLSearchParams(search).get("f");

  const batchCowsDropdownOptions =
    farmDetails &&
    farmDetails.milkBatch &&
    farmDetails.milkBatch.cows &&
    farmDetails.milkBatch.cows.map((currentCow) => {
      return {
        name: currentCow.name,
        code: currentCow.externalId.trim(),
      };
    });
  const [selectedCowForUdderGraph, setSelectedCowForUdderGraph] = useState(
    batchCowsDropdownOptions && batchCowsDropdownOptions[0].code,
  );

  return (
    <div className="astronautTeetAnimation">
      <div className="astronautTeetAnimation--bigMatrixCow"></div>
      <div className="astronautTeetAnimation--arrow"></div>
      <MatrixColumn
        cssClassName="testMatrixColumn10"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
      <MatrixColumn
        cssClassName="testMatrixColumn11"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
      <MatrixColumn
        cssClassName="testMatrixColumn12"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
      <TextCaption additionalCssClassname="astronautTeetAnimation--nameTextCaption">
        <AstronautCowDropdownMenu
          additionalClassName="astronautCowDropdownMenu"
          labelText={
            batchCowsDropdownOptions && batchCowsDropdownOptions[0].name
          }
          options={batchCowsDropdownOptions}
          initialValue={selectedCowForUdderGraph}
          handleChangeFn={(cowExternalId) => {
            console.log(
              "@handleChangeFn farmId:",
              farmId,
              "cowExternalId:",
              cowExternalId,
            );
            setSelectedCowForUdderGraph(cowExternalId);
            dispatch(getUdderDetails(farmId, cowExternalId));
          }}
        />
        <div className="astronautTeetAnimation--connectionArrow"></div>
      </TextCaption>
      <Udder />
    </div>
  );
};

export default AstronautTeetAnimationSlide;
