import axios from 'axios';
import { TELL_ME_MORE_SERVICE_URL } from './config';

export const api = {
  getFarmerStoryTextBlocks: async function (accessToken) {
    /*
    return {
      data: [
        {
          title: 'Mock text block title',
          description: 'Mock text block description',
          imageData:
            'https://static7.depositphotos.com/1086305/731/i/600/depositphotos_7315837-stock-photo-storm-is-coming-on-american.jpg',
        },
        {
          title: 'Mock text block fsalkdjflksafa',
          description: 'Mock text block safdafa',
          imageData: {},
        },
      ],
    }; // <-- those are the text blocks that the backend has returned (mock data)
    */

    // uncomment code below when backend is ready

    return axios.get(TELL_ME_MORE_SERVICE_URL, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  setFarmerStoryTextBlocks: async function (accessToken, textBlocksData) {
    // uncomment code below when backend is ready
    return axios.patch(TELL_ME_MORE_SERVICE_URL, textBlocksData, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
};
