import React from "react";
import "./EndingPoint.scss";
import { translate, translateWithValues } from "../../localization/TranslationUtil";
import {
  IS_CLICKABLE_CSS_CLASSNAME,
  SIDE_MENU_OPTION_TO_THE_FARM,
} from "../../common/constants";
import PoleComponent from "../../common/components/PoleComponentEndingSlides/PoleComponent";

const EndingPoint = ({ onNavigateToInitialSlideFn, webSiteURL, farmName }) => { 

  const goToTheFarmerWebsite = translateWithValues("GO_TO_THE_FARMER_WEBSITE", {website : farmName}); 
  const regexWebsiteUrl = new RegExp(/(http(s)?:\/\/)/);
  const isValidWebsiteUrl = (webSiteURL) => {
    const result = webSiteURL && webSiteURL.match(regexWebsiteUrl); 
    console.log('resultfromHere', result)
    return result ? webSiteURL : "https://" + `${webSiteURL}`;
  };

  return (
    <div className="farmerStoryEndingPoint">
      {/* <div className="farmerStoryEndingPoint--background"></div>
      
      </div>
      <div className="farmerStoryEndingPoint--backButton--text">
        {exploreTheFarm}
      </div>
       */} 
      <PoleComponent onNavigateToInitialSlideFn={onNavigateToInitialSlideFn} /> 
      {webSiteURL ? 
      <div className="farmerStory--signToTheWebsite">
      <span
          className={"farmerStory--text " + IS_CLICKABLE_CSS_CLASSNAME}
          onClick={() => window.open(isValidWebsiteUrl(webSiteURL))}
        >
          {goToTheFarmerWebsite}
        </span>
      </div> : ''}
    </div>
  );
};

export default EndingPoint;
