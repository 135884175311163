import React from 'react';
import { translate, translateWithValues } from '../../localization/TranslationUtil';
import Graphic from '../Graphic/Graphic'; 
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '../IconButton/IconButton'; 
import CallToActionButton from '../CallToActionButton/CallToActionButton';
import LabelValue from '../LabelValue/LabelValue';
import './IceCreamFactoryPopup.scss';  

/** IceCreamFactoryPopup component */
function IceCreamFactoryPopup({ closePopupClickFn, isAdultSelected }) { 
  const { farmDetails } = useSelector(
    (state) => state.virtualFarmDetails,
  );
  return (
    <div className="iceCreamFactoryPopup"> 
      <div className='iceCreamFactoryPopup--container'>
      <IconButton
      cssClassName="iceCreamFactoryPopup--btnClosePopup"
      onClickFn={closePopupClickFn}
    /> 
     <div className="iceCreamFactoryPopup--title">
      {translate('COW_ICECREAM_FACTORY_TITLE')}
      </div>
      <div className="iceCreamFactoryPopup--description">
      {translate('COW_ICECREAM_ROBOT_DESCRIPTION')}
      </div>
    </div>
    {/* <CallToActionButton
      cssClassName="iceCreamFactoryPopup--btnCallToAction"
      isAdultSelected={isAdultSelected}
      label={translate('COW_AVAILABLE_SOON_ACTION_BUTTON')}
      onClickFn={() => {
      }}
    /> */}
    <Graphic cssClassName="iceCreamFactoryPopup--iceCream" />
    
  </div>
  );
}
export default IceCreamFactoryPopup; 
