import { SET_GOOGLE_ANALYTIC_TRACKING } from './types';

/** Create action which will track user activity
 * @param {string} categoryName - Category name of the selected event / Required
 * @param {string} eventName - Name of the Event / Required
 * @param {string} [eventLabel] - Optional event description
 */
export function setGoogleAnalyticTracking(categoryName, eventName, eventLabel) {
  return {
    type: SET_GOOGLE_ANALYTIC_TRACKING,
    payload: {
      categoryName,
      eventName,
      eventLabel,
    },
  };
}
