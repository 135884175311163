import _ from "lodash";

export const convertLanguageSelectionToBE = (selectedLanguagesCodes, defaultLanguageOptions) => {
    let languagesInBEFormat = [];
    languagesInBEFormat = defaultLanguageOptions.filter(currentLanguageObject => ((selectedLanguagesCodes.indexOf(currentLanguageObject.code) !== -1) || (selectedLanguagesCodes.indexOf(currentLanguageObject.code.substring(0, 2)) !== -1)));
    return {
        languages: languagesInBEFormat
    };
};

export const getLanguagesPlusSimpleOptions = (languageCodes) => {
    let optionsWithSimple = [];
    languageCodes.forEach(currentLanguageCode => {
        optionsWithSimple.push(currentLanguageCode);
        optionsWithSimple.push(currentLanguageCode + 's');
    });

    return optionsWithSimple;
};