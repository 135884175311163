import moment from "moment";
import React from "react";
import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import "./MilkBatchWeatherText.scss";

function MilkBatchWeatherText({ weatherData, currentTimeInFarm }) {
  if (!weatherData || !currentTimeInFarm) {
    return null;
  }
  const monthName = moment(currentTimeInFarm).format("MMMM");
  const localizedMonthName = translate("MONTH_" + monthName.toUpperCase());
  const dateText = moment(currentTimeInFarm).format("DD");
  const weatherStringWithoutSpaces =
    weatherData &&
    weatherData.conditions &&
    weatherData.conditions.replace(/\s/g, "");
  let isLowSnow = weatherData.snow > 0 && weatherData.visibility >= 1;
  let isMediumSnow =
    weatherData.snow > 0 &&
    weatherData.visibility >= 0.5 &&
    weatherData.visibility < 1;
  let isSnowStorm = weatherData.snow > 0 && weatherData.visibility < 0.5;
  let isLowRain =
    weatherData.snow === 0 &&
    weatherData.precip > 0 &&
    weatherData.precip < 2.5;
  let isMediumRain =
    weatherData.snow === 0 &&
    weatherData.precip > 2.5 &&
    weatherData.precip < 10;
  let isHeavyRain =
    weatherData.snow === 0 &&
    weatherData.precip >= 10 &&
    weatherData.precip < 50;
  let isThunderStorm = weatherData.snow === 0 && weatherData.precip > 50;
  let isMist = weatherData.snow === 0 && weatherData.visibility <= 1;

  const CONDITIONS_TO_TRANSLATION_KEY_MAPPING = {
    Partiallycloudy: "lightCloudy",
    Clear: "sunny",
    Overcast: "cloudy",
  };
  let weatherTextDescription =
    CONDITIONS_TO_TRANSLATION_KEY_MAPPING[weatherStringWithoutSpaces]; // if possible do direct matching between conditions string and CSS classname
  if (!weatherTextDescription) {
    if (isLowSnow) {
      weatherTextDescription = "lightSnow";
    } else if (isMediumSnow) {
      weatherTextDescription = "snow";
    } else if (isSnowStorm) {
      weatherTextDescription = "heavySnow";
    } else if (isLowRain) {
      weatherTextDescription = "lightRain";
    } else if (isMediumRain) {
      weatherTextDescription = "rain";
    } else if (isHeavyRain) {
      weatherTextDescription = "heavyRain";
    } else if (isThunderStorm) {
      weatherTextDescription = "thunderstorm";
    } else if (isMist) {
      weatherTextDescription = "mist";
    } else {
      weatherTextDescription = "sunny";
    }
  }
  let localizedWeatherDescription = translate(
    "WEATHER_LABEL_" + weatherTextDescription.toUpperCase(),
  );

  return (
    <p
      className="milkBatchWeatherText"
      dangerouslySetInnerHTML={{
        __html: translateWithValuesAndHtml("WEATHER_LABEL_TEXT", {
          dateText: dateText + " " + localizedMonthName,
          weatherText: localizedWeatherDescription,
        }),
      }}
    />
  );
}


export default MilkBatchWeatherText;
