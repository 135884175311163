import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App/App.jsx";
import "./index.css";
// #THEMES DEMO: import './themes/milka.scss';
import "react-grid-layout/css/styles.css";
import { Route, Switch, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./configureStore";
import Preloader from "./components/Preloader/Preloader.jsx";

const initialState = {};
/** Contains history */
const hist = createBrowserHistory();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <PersistGate
      loading={
        <Preloader isPreloaderActive={true} preloaderText={"Loading..."} />
      }
      persistor={persistor}
    >
      <Router history={hist}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>,
);
