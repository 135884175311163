import _ from "lodash";
import React, { useEffect } from "react";
import "./CowOutroPage.scss";
import { useSelector } from "react-redux";
import PoleComponent from "../../../common/components/PoleComponentEndingSlides/PoleComponent";

const CowOutroPage = React.memo(
  ({ onNavigateToInitialSlideFn, goToSlideFn }) => {
    const { selectedCowDetails } = useSelector(
      (state) => state.selectedCowDetails,
    );

    const { selectedLanguage } = useSelector(
      (state) => state.selectedLanguageDetails,
    );

    useEffect(() => {}, [!_.isEmpty(selectedCowDetails)]);

    if (!_.isEmpty(selectedCowDetails)) {
      const isThereFirstCalfSlides =
        selectedCowDetails && selectedCowDetails.firstCalf !== null;
      const isTheCowMoreThanOneYearsOld =
        selectedCowDetails && selectedCowDetails.ageInYears > 0;
      return (
        <div className="firstContainer">
          <PoleComponent
            onNavigateToInitialSlideFn={onNavigateToInitialSlideFn}
          />
          {!isThereFirstCalfSlides && isTheCowMoreThanOneYearsOld ? (
            <div className="cowOutroPage--bigTree"></div>
          ) : null}
        </div>
      );
    } else {
      return <p>Empty cow details.</p>;
    }
  },
);

export default CowOutroPage;
