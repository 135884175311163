import React, { useState } from 'react';
import SpacingWrapper from '../../common/components/SpacingWrapper/SpacingWrapper.jsx';
// Components && Styles
import Search from '../../common/components/Search/Search';
import AccordionPanel from '../../common/components/Accordion/Accordion';
import { Container } from '@material-ui/core';
import { HELP_INFORMATION_COMPONENTS } from '../../common/data/help_information_components';


export default function HelpPage() {
  /** Contains all help information and it is updated on search  */
  const [helpInformation, setHelpInformation] = useState(
    HELP_INFORMATION_COMPONENTS,
  );

  /** Search help information
   * @param {string} item - Typed character
   */
  function search(item) {
    // Get the date from help mock date
    let searchedItem = HELP_INFORMATION_COMPONENTS.filter(
      (help) =>
        help.title.toLowerCase().match(item.toLowerCase()) ||
        help.text.toLowerCase().match(item.toLowerCase()),
    );

    /** Update help state with matched information */
    setHelpInformation(searchedItem);
  }
  return (
    <SpacingWrapper className="help-screen">
      <Container
        component="main"
        className="help-table"
        data-testid="container-help"
      >
        <Search search={search} placeholder="Search" />
        <div style={{ width: '100%', paddingTop: 20 }}>
          {helpInformation.map((item, index) => {
            return (
              <AccordionPanel
                key={item.title + index}
                title={item.title}
                text={item.text}
                panelCount={index}
              />
            );
          })}
        </div>
      </Container>
    </SpacingWrapper>
  );
}
