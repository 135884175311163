import {
  HANDLE_ACTOR_SELECTED,
  HANDLE_ACTOR_POSITIONED,
  RESET_ACTOR_POSITIONS
} from './types';

/** Create action to store the data for clicked cow
 * @param {Object} selectedActorData - The object containing the data for the currently selected actor
 * @return {Object} Returns action object
 */
export function selectActor(selectedActorData) {
  return {
    type: HANDLE_ACTOR_SELECTED,
    payload: selectedActorData
  };
}

/** Create action to store the data for clicked cow
 * @param {String} actorId - Id of the actor whose position is updated
 * @param {Object} actorPosition - Object of format { x: <>, y: <> } that contains the current coordinates of the actor
 * @return {Object} Returns action object
 */
export function updateActorPosition(actorId, actorPosition) {
  return {
    type: HANDLE_ACTOR_POSITIONED,
    payload: {
      actorId: actorId,
      actorPosition: actorPosition
    }
  };
}


/** Create action to reset actor positions
 * @return {Object} Returns action object
 */
 export function resetActorPositions() {
  return {
    type: RESET_ACTOR_POSITIONS,
    payload: {}
  };
}