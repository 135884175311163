import React from "react";
import "./LastSlideOrbiterSlide.scss";

import PoleComponent from "../../common/components/PoleComponentEndingSlides/PoleComponent";

const LastSlideOrbiterSlide = ({ onNavigateToInitialSlideFn }) => {
  return (
    <div className="orbiterStoryLastSlide">
      <PoleComponent onNavigateToInitialSlideFn={onNavigateToInitialSlideFn} />

      <div className="orbiterStoryLastSlide--bus"></div>
    </div>
  );
};

export default LastSlideOrbiterSlide;
