const STAGE_API_URL = 'https://lelysense-stage.westeurope.cloudapp.azure.com/api';
const PRD_API_URL = 'https://lelysense.com/api';
const LOCALHOST_API_URL = 'https://localhost:44334/api';
const isDeployedToPRD = (apiUrl) => apiUrl == PRD_API_URL;
const isDeployedToStage = (apiUrl) => apiUrl == STAGE_API_URL;
const isDeploymentOperation = (apiUrl) => (isDeployedToPRD(apiUrl) || isDeployedToStage(apiUrl));

const PRD_VERSION_FEATURE_FLAG_NAME = 'latest_application_version_prd';
const STAGE_VERSION_FEATURE_FLAG_NAME = 'latest_application_version_stg';
const getVersionFeatureFlagName = (apiUrl) => {
  let versionFeatureFlagName = "";
  if (isDeployedToPRD(apiUrl)) {
      versionFeatureFlagName = PRD_VERSION_FEATURE_FLAG_NAME;
  } else if (isDeployedToStage(apiUrl)) {
      versionFeatureFlagName = STAGE_VERSION_FEATURE_FLAG_NAME;
  }
  return versionFeatureFlagName;    
};

module.exports = {
  STAGE_API_URL,
  PRD_API_URL,
  LOCALHOST_API_URL,
  isDeployedToPRD,
  isDeployedToStage,
  isDeploymentOperation,
  PRD_VERSION_FEATURE_FLAG_NAME,
  STAGE_VERSION_FEATURE_FLAG_NAME,
  getVersionFeatureFlagName
};