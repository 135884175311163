import React, { useRef, useEffect } from "react";
import "./AstronautCommonData.scss";
import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";
import MatrixColumn from "./MatrixColumn";

const AstronautCommonData = ({}) => {
  const { astronautDetails } = useSelector((state) => state.astronautDetails);
  return (
    <div className="astronautCommonData">
      <div className="astronautCommonData--arrow"></div>
      <TextCaption additionalCssClassname="astronautCommonData--middleTextCaption">
        <div className="astronautCommonData--text">
          {translateWithValues("ASTRONAUT_LIVE_DATA_KG_MILKED", {
            kgMilkMilkedLast24Hours:
              astronautDetails?.kgMilkMilkedLast24Hours?.toFixed(),
          })}
        </div>
      </TextCaption>
      <TextCaption additionalCssClassname="astronautCommonData--hourTextCaption">
        <div className="astronautCommonData--hourText">
          {translateWithValues("ASTRONAUT_LIVE_DATA_COW_MILKED", {
            cowsMilkedLast24Hours: astronautDetails?.cowsMilkedLast24Hours,
          })}
        </div>
      </TextCaption>
      <TextCaption additionalCssClassname="astronautCommonData--milkContentTextBox"> 
      { astronautDetails?.avgMilkFatPercentageLast24Hours || astronautDetails?.avgMilkProteinPercentageLast24Hours || astronautDetails?.avgMilkLactosePercentageLast24Hours ?
        <div className="astronautCommonData--milkContentText">
          {translate("ASTRONAUT_LIVE_DATA_MILK_CONTENT")}
        </div> : ''
      }
        <div className="astronautCommonData--milkContent">
          {astronautDetails?.avgMilkFatPercentageLast24Hours ?  
          <li className="astronautCommonData--fatPercentage">
            {" "}
            {translateWithValues("ASTRONAUT_LIVE_DATA_FAT_PERCENTAGE", {
              fat: astronautDetails?.avgMilkFatPercentageLast24Hours?.toFixed(
                2,
              ),
            })}
          </li> : ''} 
          {astronautDetails?.avgMilkProteinPercentageLast24Hours ?
            <li className="astronautCommonData--proteinPercentage">
            {" "}
            {translateWithValues("ASTRONAUT_LIVE_DATA_PROTEIN_PERCENTAGE", {
              protein:
                astronautDetails?.avgMilkProteinPercentageLast24Hours?.toFixed(
                  2,
                ),
            })}
          </li> : ''} 
          {astronautDetails?.avgMilkLactosePercentageLast24Hours ? 
            <li className="astronautCommonData--lactosePercentage">
            {translateWithValues("ASTRONAUT_LIVE_DATA_LACTOSE_PERCENTAGE", {
              lactose:
                astronautDetails?.avgMilkLactosePercentageLast24Hours?.toFixed(
                  2,
                ),
            })}
          </li> : ''}
        </div>
      </TextCaption>
      <div className="astronautCommonData--smallMilkBottle"></div>
      <div className="astronautCommonData--matrixMilkBottle"></div>
      <div className="astronautCommonData--matrixCows"></div>
      <div className="astronautCommonData--milkBottles"></div>
      <div className="astronautCommonData--connectionArrows"></div>
      <MatrixColumn
        cssClassName="testMatrixColumn7"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
      <MatrixColumn
        cssClassName="testMatrixColumn8"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
      <MatrixColumn
        cssClassName="testMatrixColumn9"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
    </div>
  );
};

export default AstronautCommonData;
