import React from "react";
import { translate } from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import IconButton from "../IconButton/IconButton";
import "./CowRuminationPopup.scss";

function CowRuminationPopup({ closePopupClickFn }) {
  return (
    <div className="cow--ruminationPopup">
      <div className="cow--ruminationPopup--title">
        {translate("COW_RUMINATION_TITLE")}
      </div>
      <div className="cow--ruminationPopup--description">
        {translate("COW_RUMINATION_DESCRIPTION_TEXT")}
      </div>
      <Graphic cssClassName="cow--ruminationPopup--rumination" />
      <IconButton
        cssClassName="cow--ruminationPopup--btnClosePopup"
        onClickFn={closePopupClickFn}
      />
    </div>
  );
}

export default CowRuminationPopup;
