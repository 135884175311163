import React, { useState } from "react";
// Styles
import "./StoryNavigationHint.scss";
import { translate } from "../../../localization/TranslationUtil";

/** Show proper navigation hint in stories/onboardings/etc. depending on the device
 * in which the application is opened (mobile/desktop)
 */
export default function StoryNavigationHint({ explicitCssClassname }) {
  const isOpenedOnMobileDevice = window.matchMedia(
    "(any-pointer:coarse)",
  ).matches;
  let cssClassname =
    "storyNavigationHint storyNavigationHint--" +
    (isOpenedOnMobileDevice ? "mobile" : "desktop");
  let containerCssClassname = explicitCssClassname
    ? "storyNavigationHint--container" + " " + explicitCssClassname
    : "storyNavigationHint--container";
  let gestureLabelText = isOpenedOnMobileDevice
    ? translate("GESTURE_LABEL_SWIPE")
    : translate("GESTURE_LABEL_SCROLL");
  return (
    <div className={containerCssClassname}>
      <div className={cssClassname}></div>
      <p className="storyNavigationHint--gestureLabel">{gestureLabelText}</p>
    </div>
  );
}
