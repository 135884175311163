import {
  COWS_PAGE_INFORMATION_ERROR,
  COWS_PAGE_INFORMATION_SUCCESS,
  SAVE_CHANGES_TO_COWS_PAGE_SUCCESS,
  SAVE_CHANGES_TO_COWS_PAGE_ERROR,
} from '../containers/Admin/CowsPage/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  cowsPageDetails: {},
  errorMessage: '',
  isLatestSaveSuccessful: false,
  saveChangesErrorMessage: '',
};

/** Tell me more page reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function cowsReducer(state = initialState, action) {
  switch (action.type) {
    case COWS_PAGE_INFORMATION_SUCCESS:
      return {
        ...state,
        cowsPageDetails: action.payload,
        errorMessage: '',
      };
    case COWS_PAGE_INFORMATION_ERROR:
      return {
        ...state,
        cowsPageDetails: {},
        errorMessage: action.payload,
      };
    case SAVE_CHANGES_TO_COWS_PAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLatestSaveSuccessful: true,
        saveChangesErrorMessage: '',
      };
    case SAVE_CHANGES_TO_COWS_PAGE_ERROR:
      return {
        ...state,
        isLatestSaveSuccessful: false,
        saveChangesErrorMessage: action.payload,
      };

    default:
      return state;
  }
}

export default cowsReducer;
