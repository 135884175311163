import { translate } from "../../localization/TranslationUtil";
import React from "react";
import "./UdderRGBInfo.scss";

const UdderRGBInfo = ({ red, green, blue }) => {
  return (
    <div className="udderRGBInfo">
      <div className="udderRGBInfo--red">
        <span className="udderRGBInfo--caption">{`${translate(
          "UDDER_RGB_INFO_RED_CAPTION",
        )}:`}</span>
        <span className="udderRGBInfo--value">{red}</span>
      </div>
      <div className="udderRGBInfo--green">
        <span className="udderRGBInfo--caption">{`${translate(
          "UDDER_RGB_INFO_GREEN_CAPTION",
        )}:`}</span>
        <span className="udderRGBInfo--value">{green}</span>
      </div>
      <div className="udderRGBInfo--blue">
        <span className="udderRGBInfo--caption">{`${translate(
          "UDDER_RGB_INFO_BLUE_CAPTION",
        )}:`}</span>
        <span className="udderRGBInfo--value">{blue}</span>
      </div>
    </div>
  );
};

export default UdderRGBInfo;
