import React from 'react';
import './CallToActionButton.scss';

/** CallToActionButton component */
function CallToActionButton({cssClassName, label, onClickFn, buttonTooltipText,isAdultSelected}) {
  let callToActionButtonCssClassName = "btnCallToAction " + cssClassName; 
  let callToActionButtonCssClassNameContainer = callToActionButtonCssClassName + '--container'; 
  let callToActionButtonCssClassNameArrows = callToActionButtonCssClassName + '--arrows'; 
  return ( 
    <> 
    {isAdultSelected ? 
      (<div className={callToActionButtonCssClassNameContainer}>
      <button className={callToActionButtonCssClassName}
        onClick={onClickFn}
        title={buttonTooltipText}
        >{label}</button>
      <div className={callToActionButtonCssClassNameArrows}></div>
        </div>) : 
      (<button className={callToActionButtonCssClassName}
        onClick={onClickFn}
        title={buttonTooltipText}
        >{label}</button>)}
    </>
  );
}
export default CallToActionButton;