import { GET_SERVER_RESPONSE_STATUS } from '../components/ErrorPopUp/types';

/** Store states
 * @constant {Object}
 */
export const initialState = {
  responseStatus: '',
};

/** Server Response Status Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function errorPopupReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SERVER_RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload.status,
      };
    default:
      return state;
  }
}

export default errorPopupReducer;
