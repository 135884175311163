import { SET_GOOGLE_ANALYTIC_TRACKING } from './types.js';
import { googleAnalyticsEventTracker } from '../../../api/googleAnalytics/googleAnalyticsEventTracker';
import { take, call } from 'redux-saga/effects';

export default function* googleAnalyticTrackingSaga() {
  while (true) {
    const googleAction = yield take(SET_GOOGLE_ANALYTIC_TRACKING);
    const { categoryName, eventName, eventLabel } = googleAction.payload;
    yield call(
      updateGoogleAnalyticUserTracking,
      categoryName,
      eventName,
      eventLabel,
    );
  }

  /** Call google analytic event tracking
   * @param {string} categoryName - Category name of the selected event / Required
   * @param {string} eventName - Name of the Event / Required
   * @param {string} [eventLabel] - Optional event description
   */
  function* updateGoogleAnalyticUserTracking(
    categoryName,
    eventName,
    eventLabel,
  ) {
    googleAnalyticsEventTracker(categoryName, eventName, eventLabel);
  }
}
