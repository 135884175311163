import _ from "lodash";
import React, { useEffect } from "react";
import "./CowCalmRobotSlide.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  translate,
  translateWithValuesAndHtml,
} from "../../../localization/TranslationUtil";
import TextCaption from "../../../components/TextCaption/TextCaption";
import { ACTOR_TYPE_CALM_ROBOT } from "../../../common/constants";
import IconButton from "../../../components/IconButton/IconButton";
import { showPopup } from "../../../common/components/PopupContainer/actions";

const CowCalmRobotSlide = React.memo(() => {
  const dispatch = useDispatch();
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  const calfClassName = "calf " + "calf--" + selectedCowDetails.cowSubBreed;

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div className="cow-calm--robot--container">
        <div className="cow-calm--robot--slide">
          <TextCaption additionalCssClassname="textCaption--cow-calm--robot">
            <span
              className="textCaption--cow-calm--robot--text"
              dangerouslySetInnerHTML={{
                __html: translateWithValuesAndHtml("CALM_ROBOT_SLIDE_TEXT", {cowName : selectedCowDetails.name }),
              }}
            ></span>
          </TextCaption>
          <div className={calfClassName}></div>
        </div>
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
});

export default CowCalmRobotSlide;
