import React, { useRef } from "react";
import {translate,translateNumber,translateWithValues,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import "./ThirdSlideAnimalWelfare.scss";

import { translateDynamic } from "../../localization/TranslationUtil";
import { getDisableCacheAppendix } from "../../common/urlUtil";

const ThirdSlideAnimalWelfare = ({ farmDetails }) => {
  const herdBreedAndAgeStory = translateDynamic(
    farmDetails?.animalWelfare?.herdBreedAndAgeStory,
  );
  const image = farmDetails?.animalWelfare?.herdBreedAndAgeImageData;

  return (
    <div className="animalWelfareStoryThirdSlide">
      <TextCaption additionalCssClassname="animalWelfareStoryThirdSlide--textCaption">
        <div className="animalWelfareStoryThirdSlide--title">
          {translate("ANIMAL_WELFARE_THIRD_SLIDE_HERD")}
        </div>
        <div className="animalWelfareStoryThirdSlide--text">
          {herdBreedAndAgeStory}
        </div>
      </TextCaption>
      <div className={`animalWelfareStoryThirdSlide--imgCaption`}>
        <img src={getDisableCacheAppendix(image)} className="animalWelfareStoryThirdSlide--imgContainer"></img>
        <div className="animalWelfareStoryThirdSlide--imgText">
        </div>
      </div>
      <div className="animalWelfareStoryThirdSlide-treeAndSmallBush"></div>
    </div>
  );
};

export default ThirdSlideAnimalWelfare;
