import _ from 'lodash';
import React, { useEffect } from 'react';
import './AdminWelcomePage.scss';
import { useSelector } from 'react-redux';
import { translate } from '../../../localization/TranslationUtil';

function AdminWelcomePage() {
  return (
    <div className="WelcomePage">
      <h3 className="textCaption--heading textCaption--welcome--heading">{translate('ADMIN_WELCOME_HEADING')}</h3>
      <p className="textCaption--label textCaption--welcome--label">{translate('ADMIN_WELCOME_TEXT')}</p>
    </div>
  );
}

export default AdminWelcomePage;
