import {
  REQUEST_TELL_ME_MORE_PAGE_INFORMATION,
  TELL_ME_MORE_PAGE_INFORMATION_SUCCESS,
  TELL_ME_MORE_PAGE_INFORMATION_ERROR,
  SAVE_CHANGES_TO_TELL_ME_MORE_PAGE,
  SAVE_CHANGES_TO_TELL_ME_MORE_PAGE_SUCCESS,
  SAVE_CHANGES_TO_TELL_ME_MORE_PAGE_ERROR
} from './types';


/** Create action which will receive the text blocks for the selected farmer
 * @param {string} accessToken - User access token
 * @return {Object} Returns action object
 */
export function requestTellMeMorePageInformation(accessToken) {
  return {
    type: REQUEST_TELL_ME_MORE_PAGE_INFORMATION,
    payload: {
      accessToken: accessToken
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of the Tell Me More page in the Admin app
 * @param {Object} textBlocksDetails - an object containing the data to be used in order to initialize the Tell Me More page in the Admin app
 * @return {Object} Return type and farmDetails
 */
export function getTellMeMoreInformationSuccess(textBlocksDetails) {
  return {
    type: TELL_ME_MORE_PAGE_INFORMATION_SUCCESS,
    payload: textBlocksDetails,
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getTellMeMoreInformationError(errorMessage) {
  return {
    type: TELL_ME_MORE_PAGE_INFORMATION_ERROR,
    payload: errorMessage,
  };
}

/** Create action which to be used whenever the user clicks the Save changes button in the Tell Me More page in the Admin app
 * @param {string} accessToken - User access token
 * @param {Object} newTextBlocksData - The latest state of the text blocks (some new text blocks might be added, the contents of existing ones might have been changed)
 * @return {Object} Returns action object
 */
 export function saveChangesToTellMeMorePage(accessToken, newTextBlocksData) {
  return {
    type: SAVE_CHANGES_TO_TELL_ME_MORE_PAGE,
    payload: {
      accessToken: accessToken,
      textBlocksData: newTextBlocksData
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of the Tell Me More page in the Admin app
 * @return {Object} Returns action object
 */
export function saveChangesToTellMeMorePageSuccess() {
  return {
    type: SAVE_CHANGES_TO_TELL_ME_MORE_PAGE_SUCCESS,
    payload: {},
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function saveChangesToTellMeMorePageSuccessError(errorMessage) {
  return {
    type: SAVE_CHANGES_TO_TELL_ME_MORE_PAGE_ERROR,
    payload: errorMessage,
  };
}