import {
  REQUEST_ABOUT_THE_FARM_PAGE_INFORMATION,
  SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE,
} from './types.js';
import {
  getAboutTheFarmInformationSuccess,
  getAboutTheFarmIformationError,
  saveChangesToAboutTheFarmPageSuccess,
  saveChangesToAboutTheFarmPageSuccessError,
} from './actions.js';
import { put, take, call } from 'redux-saga/effects';
import {
  startLoading,
  endLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import { saveChangesToSelectedFarmLanguages } from '../../../common/components/LanguageSelection/actions.js';

let currentLoadId = 0;

export function* initalizeAboutTheFarmPageSaga(api) {
  while (true) {
    const aboutTheFarmInformationRequestAction = yield take(
      REQUEST_ABOUT_THE_FARM_PAGE_INFORMATION,
    );
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { accessToken } = aboutTheFarmInformationRequestAction.payload;
    yield call(getAboutTheFarmDetails, api, accessToken);
  }
}

/** Create request to get about the farm page latest state of data from the backend
 * @param {string} accessToken - User access token
 */
function* getAboutTheFarmDetails(api, accessToken) {
  try {
    const aboutTheFarmDetails = yield call(
      api.getFarmStoryDetails,
      accessToken,
    );
    yield put(getAboutTheFarmInformationSuccess(aboutTheFarmDetails.data));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(getAboutTheFarmIformationError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}

export function* aboutTheFarmSaveChangesSaga(api) {
  while (true) {
    const saveChangesRequestAction = yield take(
      SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE,
    );
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { accessToken, newAboutTheFarmDetails, newLanguageDetails } =
      saveChangesRequestAction.payload;
    yield call(
      saveChangesAboutTheFarmPage,
      api,
      accessToken,
      newAboutTheFarmDetails,
      newLanguageDetails
    );
  }
}

/** Create request to get about the farm page latest state of data from the backend
 * @param {string} accessToken - User access token
 * @param {Object} newAboutTheFarmDetails - The latest state of the farm details data coming directly from the frontend which we want to persist on the backend
 * @param {Object} newLanguageDetails - The latest state of the selected farm languages that need to be persisted to the backend as soon as the first request is over
 */
function* saveChangesAboutTheFarmPage(
  api,
  accessToken,
  newAboutTheFarmDetails,
  newLanguageDetails
) {
  try {
    const updateAboutTheFarmDataResponse = yield call(
      api.setFarmStoryTextBlocks,
      accessToken,
      newAboutTheFarmDetails,
    );
    yield put(
      saveChangesToAboutTheFarmPageSuccess(updateAboutTheFarmDataResponse.data),
    );
    yield put(saveChangesToSelectedFarmLanguages(accessToken, newLanguageDetails));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(saveChangesToAboutTheFarmPageSuccessError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}
