import _ from "lodash";
import React, { useEffect } from "react";
import "./CowGaveBirthThirdSlide.scss";
import TextCaption from "../../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";
import { translateWithValues } from "../../../localization/TranslationUtil";

const CowGaveBirthThirdSlide = React.memo(() => {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  const calfClassName =
    "calfLeftSide " + "calf--" + selectedCowDetails.cowSubBreed;

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div className="CowGaveBirthThirdSlide">
        <TextCaption additionalCssClassname="textCaption--CowGaveBirthThirdSlide">
          <p className="cow-CowGaveBirthThirdSlide--text">
            {translateWithValues("COW_GAVE_BIRTH_THIRD_SLIDE_INFO")}
          </p>
        </TextCaption>
        <div className={calfClassName}></div>
        <span className="cow-CowGaveBirthSecondSlide--button"></span>
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
});

export default CowGaveBirthThirdSlide;
