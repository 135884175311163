import "./HudDisplayMessagesContainer.scss";
import React, { useEffect, useRef, useState } from "react";

/** HudDisplayMessagesContainer component */
function HudDisplayMessagesContainer({ messagesArray, onNewCowMilkedFn }) {
  const [currentMessages, setCurrentMessages] = useState([]);
  const messageDisplayIntervalTimerRef = useRef();
  const PERIOD_IN_SECONDS_BETWEEN_MESSAGES = 4;

  function showNextMessage() {
    messageDisplayIntervalTimerRef.current = setTimeout(() => {
      setCurrentMessages((currentStateOfMessages) => {
        if (currentStateOfMessages.length <= messagesArray.length - 2) {
          showNextMessage();
        }
        if (messagesArray[currentStateOfMessages.length].cowName) {
          onNewCowMilkedFn(
            messagesArray[currentStateOfMessages.length].cowName,
          );
        }
        return [
          messagesArray[currentStateOfMessages.length],
          ...currentStateOfMessages,
        ];
      });
    }, PERIOD_IN_SECONDS_BETWEEN_MESSAGES * 1000);
  }

  useEffect(() => {
    setCurrentMessages([]);
    if (messagesArray.length > 0) {
      showNextMessage(messagesArray[0]);
    }
    return () => {
      if (
        messageDisplayIntervalTimerRef &&
        messageDisplayIntervalTimerRef.current
      ) {
        clearTimeout(messageDisplayIntervalTimerRef.current);
      }
    };
  }, [messagesArray]);

  return (
    <div className="hudDisplay--messages--container">
      {currentMessages.map((currentMessage, currentMessageIndex) => {
        return (
          <p
            key={currentMessage.messageKey}
            className={
              "hudDisplay--messages--container--message " +
              currentMessage.messageType
            }
          >
            {currentMessage.messageText}
          </p>
        );
      })}
    </div>
  );
}
export default HudDisplayMessagesContainer;
