import { translate } from '../../localization/TranslationUtil';
import React, { useRef, useState } from 'react';
import StoryBlock from '../StoryBlock/StoryBlock';
import { getEmptyStoryBlock } from '../StoryBlock/StoryBlockObject';
import './StoryBlockList.scss';
import _ from 'lodash';
import ConfirmationDialog from '../../common/components/ConfirmationDialog/ConfirmationDialog';
import Graphic from '../Graphic/Graphic';


/** StoryBlockList component.
 * @param {Object} obj - Object which contains all props.
 * @param {Array} [obj.storyBlocksArray] - Array of story block objects (containing all of the story block-relevant data like name, photoUrl, story (for each specific language), etc.)
 * @param {Array} [obj.languageKeysArray] - Array of language keys which are going to be supported for the story of each animal in the animals list
 * @param {Number} [obj.maxNumberOfStoryBlocks] - The maximum number of story blocks that can be added through the component
 * @param {Function} [obj.handleStoryBlockListChangeFn] - Function that will be called whenever any kind of change is made to the list of animals (creation, update, deletion)
 */
function StoryBlockList({
  storyBlocksArray,
  languageKeysArray,
  maxNumberOfStoryBlocks,
  handleStoryBlockListChangeFn
}) {
  const [isConfirmDeleteStoryBlockPopupDisplayed, setIsConfirmDeleteStoryBlockPopupDisplayed] = useState(false);
  const storyBlockToDeleteIdRef = useRef(0);

  function handleAddNewStoryBlockButtonClicked() {
    let newStoryBlocksArray = _.cloneDeep(storyBlocksArray) || [];
    newStoryBlocksArray.push(getEmptyStoryBlock(languageKeysArray, newStoryBlocksArray.length + 1));
    handleStoryBlockListChangeFn(newStoryBlocksArray);
  }

  function moveUpStoryBlock(storyBlockIdToMove) {
    let newStoryBlocksArray = _.cloneDeep(storyBlocksArray);
    let storyBlock = _.cloneDeep(getStoryBlock(newStoryBlocksArray, storyBlockIdToMove));
    let storyBlockAbove = _.cloneDeep(getStoryBlockAbove(newStoryBlocksArray, storyBlockIdToMove));
    swapStoryBlocks(newStoryBlocksArray, storyBlock, storyBlockAbove);
    handleStoryBlockListChangeFn(newStoryBlocksArray);
  }

  function moveDownStoryBlock(storyBlockIdToMove) {
    let newStoryBlocksArray = _.cloneDeep(storyBlocksArray);
    let storyBlock = _.cloneDeep(getStoryBlock(newStoryBlocksArray, storyBlockIdToMove));
    let storyBlockBelow = _.cloneDeep(getStoryBlockBelow(newStoryBlocksArray, storyBlockIdToMove));
    swapStoryBlocks(newStoryBlocksArray, storyBlock, storyBlockBelow);
    handleStoryBlockListChangeFn(newStoryBlocksArray);
  }

  function swapStoryBlocks(storyBlocksArray, storyBlock1, storyBlock2) {
    storyBlocksArray[storyBlock1.storyBlockIndex] = storyBlock2.storyBlock;
    storyBlocksArray[storyBlock2.storyBlockIndex] = storyBlock1.storyBlock;
    let originalStoryBlock1TextBlockIndex = _.cloneDeep(storyBlock1.storyBlockIndex);
    let originalStoryBlock2TextBlockIndex = _.cloneDeep(storyBlock2.storyBlockIndex);
    storyBlock1.storyBlock.textBlockIndex = originalStoryBlock2TextBlockIndex + 1;
    storyBlock2.storyBlock.textBlockIndex = originalStoryBlock1TextBlockIndex + 1;
  }
  
  function sortStoryBlocksBasedOnIndex(storyBlocksArray) {
    return storyBlocksArray.sort((a, b) => a.textBlockIndex > b.textBlockIndex);
  }

  function getStoryBlock(storyBlocksArray, storyBlockId) {
    let storyBlock = {};
    storyBlocksArray.forEach((currentStoryBlock, currentStoryBlockIndex) => {
      if (currentStoryBlock.textBlockIndex == storyBlockId) {
        storyBlock = {
          storyBlock: currentStoryBlock,
          storyBlockIndex: currentStoryBlockIndex
        };
      }
    });
    return storyBlock;
  }

  function getStoryBlockAbove(storyBlocksArray, storyBlockId) {
    let sortedStoryBlocks = sortStoryBlocksBasedOnIndex(storyBlocksArray);
    let storyBlockAbove = {};
    let storyBlockAboveIndex = 0;
    sortedStoryBlocks.forEach((currentStoryBlock, currentStoryBlockIndex) => {
      if (currentStoryBlock.textBlockIndex == (+storyBlockId - 1)) {
        storyBlockAbove = currentStoryBlock;
        storyBlockAboveIndex = currentStoryBlockIndex;
      }
    });
    return {
      storyBlock: storyBlockAbove,
      storyBlockIndex: storyBlockAboveIndex
    }
  }

  function getStoryBlockBelow(storyBlocksArray, storyBlockId) {
    let sortedStoryBlocks = sortStoryBlocksBasedOnIndex(storyBlocksArray);
    let storyBlockBelow = {};
    let storyBlockBelowIndex = 0;
    sortedStoryBlocks.forEach((currentStoryBlock, currentStoryBlockIndex) => {
      if (currentStoryBlock.textBlockIndex == (+storyBlockId + 1)) {
        storyBlockBelow = currentStoryBlock;
        storyBlockBelowIndex = currentStoryBlockIndex;
      }
    });
    return {
      storyBlock: storyBlockBelow,
      storyBlockIndex: storyBlockBelowIndex
    }
  }

  function handleDeleteStoryBlockButtonClicked(storyBlockIdToDelete) {
    let newStoryBlocksArray = _.cloneDeep(storyBlocksArray);
    newStoryBlocksArray = newStoryBlocksArray.filter(currentStoryBlock => storyBlockIdToDelete !== currentStoryBlock.textBlockIndex);
    newStoryBlocksArray.forEach((currentStoryBlock, currentStoryBlockIndex) => {
      currentStoryBlock.textBlockIndex = currentStoryBlockIndex + 1;
    });
    handleStoryBlockListChangeFn(newStoryBlocksArray);
    setIsConfirmDeleteStoryBlockPopupDisplayed(false);
  }

  function showDeleteStoryBlockConfirmationPopup(storyBlockIdToDelete) {
    storyBlockToDeleteIdRef.current = storyBlockIdToDelete;
    setIsConfirmDeleteStoryBlockPopupDisplayed(true);
  }

  return (
    <div className="adminPanel--section">
      <h3 className="adminPanel--section--header">{translate('ABOUT_YOU_YOUR_STORY_HEADING_TEXT')}</h3>
      <p className="adminPanel--section--subheading">{translate('ABOUT_YOU_YOUR_STORY_BODY_TEXT')}</p>
      <Graphic cssClassName={'your-story--sample-story-slide'}></Graphic>
      <h4 className="your-story--sample-story-text">{translate('ABOUT_YOU_YOUR_STORY_STORY_BLOCK_EXAMPLE_TEXT')}</h4>
      <>
        {storyBlocksArray && storyBlocksArray.length > 0 && storyBlocksArray.map((currentStoryBlockData, currentStoryBlockIndex) => (
          <StoryBlock
            storyBlocksArray={storyBlocksArray}
            storyBlockData={currentStoryBlockData}
            languageKeysArray={languageKeysArray}
            handleStoryBlockChangeFn={(newStoryBlockData) => {
              let newStoryBlocksArray = _.cloneDeep(storyBlocksArray);
              newStoryBlocksArray.forEach((currentStoryBlock, currentStoryBlockIndex) => {
                if (currentStoryBlock.textBlockIndex === newStoryBlockData.textBlockIndex) {
                  newStoryBlocksArray[currentStoryBlockIndex] = newStoryBlockData;
                }
              });
              handleStoryBlockListChangeFn(newStoryBlocksArray);
            }}
            deleteStoryBlockClickFn={(storyBlockIdToDelete) => {
              showDeleteStoryBlockConfirmationPopup(storyBlockIdToDelete);
            }}//
            handleStoryBlockMoveUpClickFn={(storyBlockId) => {
              moveUpStoryBlock(storyBlockId);
            }}
            handleStoryBlockMoveDownClickFn={(storyBlockId) => {
              moveDownStoryBlock(storyBlockId);
            }}
            isMoveUpEnabled={+currentStoryBlockIndex > 0}
            isMoveDownEnabled={+currentStoryBlockIndex != (storyBlocksArray.length - 1)}
          />
        ))}
      </>
      <div className="add-new-story-block-container">
        <button
          className="btn-add-new-story-block"
          type="button"
          disabled={storyBlocksArray && storyBlocksArray.length >= maxNumberOfStoryBlocks}
          onClick={handleAddNewStoryBlockButtonClicked}
        >
          <i className="lely-icon-plus"></i><span className='add-new-story-block-label'>{translate('ABOUT_YOU_PAGE_ADD_ANOTHER_STORY_BLOCK_TEXT')}</span>
        </button>
      </div>
      {
        isConfirmDeleteStoryBlockPopupDisplayed ? <ConfirmationDialog
          onConfirmCallbackFn={() => {
            handleDeleteStoryBlockButtonClicked(storyBlockToDeleteIdRef.current);
          }}
          onCancelCallbackFn={() => {
            setIsConfirmDeleteStoryBlockPopupDisplayed(false);
          }}
          dialogTitle={`Delete story block ${storyBlockToDeleteIdRef.current}`}
          dialogText={`Are you sure you want to remove story block ${storyBlockToDeleteIdRef.current}?`}
          cancelButtonLabelText="No"
          confirmationButtonLabelText={`Yes, delete story block ${storyBlockToDeleteIdRef.current}`}
        />
          : null
      }
    </div>
  );
}

export default StoryBlockList;
