import Graphic from "../Graphic/Graphic";
import React, { useEffect, useRef } from "react";
import "./Excrement.scss";
import { measureResponsive } from "../../components/VirtualFarm/util";
import { gsap } from "gsap";
import { useDispatch, useSelector } from "react-redux";
import {
  areThereOtherActorsReallyCloseBy,
  checkWhetherActorWithIdOverlapsWithActorWithId,
} from "./CollisionDetectionUtil";
import { removeExcrementForCow } from "./actions";
import { printConsoleSubheading } from "../../common/consoleUtil";
import { updateActorPosition } from "../../components/Actor/actions";
import { DISCOVERY_ACTOR_ID, EXCREMENT_PREFIX } from "../../common/constants";

/** Excrement component */
//#region props declaration
const Excrement = React.memo(({ cowXCoordinate, cowYCoordinate, cowData }) => {
  //#endregion
  //#region component state
  let excrementRef = useRef(null);
  let timelineRef = useRef(null);
  const dispatch = useDispatch();
  const { actorPositions } = useSelector((state) => state.actorPositions);
  const { cowExcrements } = useSelector((state) => state.excrementDetails);
  //#endregion
  console.log("isClose", cowExcrements);
  //#region useEffect clauses
  useEffect(() => {
    timelineRef.current = gsap.timeline({
      repeat: 0,
    });
  }, []);

  useEffect(() => {
    const doesExcrementOverlapWithDiscovery =
      checkWhetherActorWithIdOverlapsWithActorWithId(
        +cowXCoordinate,
        +cowYCoordinate,
        DISCOVERY_ACTOR_ID,
        actorPositions,
      );

    if (doesExcrementOverlapWithDiscovery) {
      removeExcrement();
    }
  }, [actorPositions]);
  //#endregion

  function removeExcrement() {
    dispatch(removeExcrementForCow(cowData.externalId));
  }

  let isRainbowCow = cowData.cowSubBreed === "holstein-rainbow";
  const DEFAULT_EXCREMENT_CSS_CLASSNAME = "excrement";
  let excrementCssClassname = isRainbowCow
    ? DEFAULT_EXCREMENT_CSS_CLASSNAME + " is-rainbow"
    : DEFAULT_EXCREMENT_CSS_CLASSNAME;

  return (
    <div
      style={{
        left: measureResponsive(cowXCoordinate),
        top: measureResponsive(cowYCoordinate),
        width: measureResponsive(3.463675 / 2),
        height: measureResponsive(3.925498 / 2),
        opacity: !cowExcrements.includes(cowData.externalId) ? 0 : 1,
      }}
      ref={excrementRef}
      className="excrement-container"
    >
      <Graphic cssClassName={excrementCssClassname} />
    </div>
  );
});
export default Excrement;
