import { toVh, toVw } from "../VirtualFarm/util";
import React, { useEffect, useRef, useState } from "react";
import "./Bird.scss";
import { BirdProps } from "./types/Bird.types";
import { TIME_TO_UPDATE_BIRD_COORDINATES_TO_FINAL_POINT_OF_FLIGHT_IN_MS } from "./config";

/** Bird component */
const Bird: React.FunctionComponent<any> = ({
  birdCssClassname,
  birdData,
}: BirdProps) => {
  let birdElementRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (birdData.birdIndex && birdData.birdFlightDurationInMilliseconds) {
      document.documentElement.style.setProperty(
        "--bird" + birdData.birdIndex + "FlightDurationInMilliseconds",
        `${birdData.birdFlightDurationInMilliseconds}ms`,
      );
      document.documentElement.style.setProperty(
        "--bird" + birdData.birdIndex + "WingspanDurationInMilliseconds",
        `${birdData.birdFlightDurationInMilliseconds / 10}ms`,
      );
      if (birdElementRef.current && birdElementRef.current.style) {
        birdElementRef.current.className =
          birdCssClassname + " bird-" + birdData.birdIndex;
      }
    }
    setTimeout(() => {
      if (birdElementRef.current && birdElementRef.current.style) {
        birdElementRef.current.style.top =
          toVh(birdData.birdEndYCoordinate) + "";
        birdElementRef.current.style.left =
          toVw(birdData.birdEndXCoordinate) + "";
        birdElementRef.current.className =
          birdCssClassname + " bird-" + birdData.birdIndex + " is-flying";
      }
    }, TIME_TO_UPDATE_BIRD_COORDINATES_TO_FINAL_POINT_OF_FLIGHT_IN_MS);
  }, [birdData]);

  let isBirdComingFromLeftToRight =
    birdData.birdStartXCoordinate < birdData.birdEndXCoordinate;
  let birdFlipCssAppendix = isBirdComingFromLeftToRight
    ? " rotateY(0)"
    : " rotateY(180deg)";

  return (
    <div
      style={{
        top: `${toVh(birdData.birdStartYCoordinate)}`,
        left: `${toVw(birdData.birdStartXCoordinate)}`,
        transform: `scale(${birdData.birdScale}) ${birdFlipCssAppendix}`,
      }}
      ref={birdElementRef}
      className={birdCssClassname + " bird-" + birdData.birdIndex}
    ></div>
  );
};

export default Bird;
