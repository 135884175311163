import axios from 'axios';
import { COWS_PAGE_SERVICE_URL } from './config';

export const api = {
  getCowsDetails: async (accessToken) => {
    return axios.get(COWS_PAGE_SERVICE_URL, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  setCowsDetails: async (accessToken, newCowsPageDetails) => {
    return axios.patch(COWS_PAGE_SERVICE_URL, newCowsPageDetails, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
};
