import "./wdyr";
import React, { useEffect } from "react";
import "./App.scss";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
// Components
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import CustomRedirect from "../CustomRedirect/CustomRedirect.jsx";
import Preloader from "../Preloader/Preloader.jsx";
import ErrorPopUp from "../ErrorPopUp/ErrorPopUp.jsx";
import ReactGA from "react-ga4";
import { environment } from "../../common/environment";
import { logoutSuccess } from "../LogoutButton/actions";
// Router
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { invalidateApplicationCacheIfNeeded } from "../../common/components/CacheBust/actions";
import ActivityTracker from "../../common/components/ActivityTracker/ActivityTracker";
import AppSidebar from "../../components/AppSidebar/AppSidebar";
import { ParallaxProvider } from "react-scroll-parallax";
//#region EndUserApp pages imports
import VirtualFarmPage from "../../containers/VirtualFarmPage/VirtualFarmPage";
import SlidesContainerPage from "../../containers/SlidesContainerPage/SlidesContainerPage";
import ForbiddenPage from "../../containers/ForbiddenPage/ForbiddenPage.jsx";
import HelpPage from "../../containers/HelpPage/HelpPage.jsx";
import UnavailablePage from "../../containers/UnavailablePage/UnavailablePage";
import "../../common/styles/admin_base.scss";
//#endregion

//#region AdminApp pages imports
import AboutTheFarmPage from "../../containers/Admin/AboutTheFarmPage/AboutTheFarmPage";
import AboutYouPage from "../../containers/Admin/AboutYouPage/AboutYouPage";
import CowsPage from "../../containers/Admin/CowsPage/CowsPage";
import AnimalsPage from "../../containers/Admin/AnimalsPage/AnimalsPage";
import LoginPage from "../../containers/Admin/UserManagement/LoginPage";
import TellMoreAboutMe from "../../containers/Admin/TellMoreAboutMe/TellMoreAboutMe";
import AdminWelcomePage from "../../containers/Admin/AdminWelcomePage/AdminWelcomePage";
import FarmFeaturesPage from "../../containers/Admin/FarmFeaturesPage/FarmFeaturesPage";
import BottleStoryPage from "../../containers/BottleStoryPage/BottleStoryPage";
import { resetPopupInfo } from "../../common/components/PopupContainer/actions";
import { API_PREFIX } from "../../api/lelyBackend/config";
import SustainabilityPage from "../../containers/Admin/SustainabilityPage/SustainabiltyPage";
import {
  requestApplicationNavigation,
  resetCurrentSaveStatus,
} from "../../common/components/SaveChangesManager/actions";
import { NAVIGATION_TYPE_CLOSE_TAB } from "../../common/components/SaveChangesManager/config";
import { getLastSaveStatus } from "../../common/components/SaveChangesManager/util";
import CreateAccountPage from "../../containers/Admin/UserManagement/CreateAccountPage";
import AccountActivated from "../UserManagement/AccountActivated/AccountActivated";
import AccountActivatedPage from "../../containers/Admin/UserManagement/AccountActivatedPage";
import RecoverAccountPage from "../../containers/Admin/UserManagement/RecoverAccountPage";
import SetNewPasswordPage from "../../containers/Admin/UserManagement/SetNewPasswordPage";
//#endregion
import {
  BATCH_FARM_VISITOR,
  BATCHLESS_FARM_VISITOR,
} from "../../common/googleAnalyticTracking/types";
import { setGoogleAnalyticTracking } from "../../common/googleAnalyticTracking/actions";

function App() {
  const search = useLocation().search;
  const batchId = new URLSearchParams(search).get("b");
  const dispatch = useDispatch();

  useEffect(() => {
    batchId
      ? dispatch(
          setGoogleAnalyticTracking(
            BATCH_FARM_VISITOR,
            BATCH_FARM_VISITOR,
            BATCH_FARM_VISITOR,
          ),
        )
      : dispatch(
          setGoogleAnalyticTracking(
            BATCHLESS_FARM_VISITOR,
            BATCHLESS_FARM_VISITOR,
            BATCHLESS_FARM_VISITOR,
          ),
        );
  }, []);

  return <MainApp />;
}

/** The main function which contains route navigation logic and from where app start */
function MainApp() {
  const location = useLocation();
  const dispatch = useDispatch();
  const isApplicationUsedInDevelopmentMode =
    API_PREFIX && API_PREFIX.indexOf("prod") != -1;

  useEffect(() => {
    if (!isApplicationUsedInDevelopmentMode) {
      // dispatch(invalidateApplicationCacheIfNeeded());
      // the line above should be executed
      // when we get the feature flag values;
      // we should pass the version feature flag value
      // to the saga where comparison should take place
    }
  }, [location]);

  const loadManagerInfo = useSelector((state) => state.loadManager);

  const isInitialLoad = loadManagerInfo.initialPageLoad;
  const isFarmSeedingInProgress = loadManagerInfo.isSeedingFarmDataInProgress;
  const isPreloaderActive = loadManagerInfo.loadingOperations.length > 0;

  /** Check if we are on the first screen "for now first screen is /farms"
   * and if we are there disable back button */
  let windowLocation = window.location;
  let windowURL = windowLocation.pathname;

  //#region Google analytics code
  const GA_MEASUREMENT_ID_PRD = "G-QMXG93XPML";
  const GA_MEASUREMENT_ID_STG = "G-PV8X3D9R26";
  const googleAnalyticsMeasurementId = isApplicationUsedInDevelopmentMode
    ? GA_MEASUREMENT_ID_STG
    : GA_MEASUREMENT_ID_PRD;
  ReactGA.initialize(googleAnalyticsMeasurementId);
  //#endregion

  let history = useHistory();

  history.listen((location) => {
    //#region Google Analytics call on each URL change TODO: uncomment
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.send({ hitType: "pageview", page: location.pathname }); // Record a pageview for the given page
    //#endregion
  });

  return (
    <ActivityTracker>
      <CustomRedirect />
      <Preloader
        isPreloaderActive={isPreloaderActive || isInitialLoad}
        preloaderText={
          isFarmSeedingInProgress
            ? "Please wait while we are preparing your farm... This might take a few minutes."
            : "Loading..."
        }
      />

      <Switch>
        <Route path="/admin" component={AdminApp} />
        <Route path="/" component={EndUserApp} />
        <Route path="*">
          <Redirect to="/notFound" />
        </Route>
      </Switch>
    </ActivityTracker>
  );
}

const EndUserApp = (props) => (
  <div>
    <Switch>
      <Route exact path="/" component={SlidesContainerPage} />
      <Route exact path="/virtualfarm" component={VirtualFarmPage} />
      <Route exact path="/bottle" component={BottleStoryPage} />
      <Route exact path="/slides" component={SlidesContainerPage} />
      <Route exact path="/help" component={HelpPage} />
      <Route exact path="/forbidden" component={ForbiddenPage} />
      <Route exact path="/notFound" component={UnavailablePage} />
      <Route exact path="*" component={SlidesContainerPage} />
    </Switch>
  </div>
);

const AdminApp = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const beforeUnloadHandler = (event) => {
      if (!getLastSaveStatus()) {
        event.preventDefault();
        event.returnValue = "";
        dispatch(requestApplicationNavigation(NAVIGATION_TYPE_CLOSE_TAB));
        event.returnValue = "Are you sure you want to leave?";
      }
    };

    dispatch(resetCurrentSaveStatus());
    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, []);
  const userSessionInfo = useSelector((state) => state.userSession);
  const token = userSessionInfo.accessToken;
  let isUserLoggedIn = false;
  if (token) {
    const decoded = jwtDecode(token);
    const now = new Date().getTime() / 1000;
    isUserLoggedIn = decoded.exp > now;
    if (!isUserLoggedIn) {
      dispatch(logoutSuccess());
    }
  }
  let windowLocation = window.location;
  let windowURL = windowLocation.pathname;
  console.log("Window URL: ", windowURL);

  return (
    <div>
      {!isUserLoggedIn && windowURL !== "/admin/register" ? (
        /*
      windowURL !== "/admin/activated" &&
      windowURL !== "/admin/recover" &&
      windowURL !== "/admin/passwordreset" */ <Redirect to="/admin/login" />
      ) : null}
      {isUserLoggedIn ? <Header /> : null}
      {isUserLoggedIn && windowURL === "/admin/login" ? (
        <Redirect to="/admin/welcome" />
      ) : null}
      {!windowURL.includes("register") &&
      !windowURL.includes("activated") &&
      /*
      !windowURL.includes("recover") &&
      */
      !windowURL.includes("login") &&
      /*
      !windowURL.includes("passwordreset") &&
      */
      !windowURL.includes("notFound") ? (
        <AppSidebar />
      ) : null}

      <Route exact path="/admin" component={AdminWelcomePage} />
      <Route exact path="/admin/welcome" component={AdminWelcomePage} />
      <Route exact path="/admin/login" component={LoginPage} />
      <Route exact path="/admin/register" component={CreateAccountPage} />
      <Route exact path="/admin/activated" component={AccountActivatedPage} />
      {/* UNCOMMENT WHEN E-MAIL SENDING CAPABILITIES ARE PROVIDED
      <Route exact path="/admin/recover" component={RecoverAccountPage} />
      <Route exact path="/admin/passwordreset" component={SetNewPasswordPage} />
      */}
      <Route exact path="/admin/aboutyou" component={AboutYouPage} />
      <Route exact path="/admin/aboutthefarm" component={AboutTheFarmPage} />
      <Route exact path="/admin/cows" component={CowsPage} />
      <Route exact path="/admin/animals" component={AnimalsPage} />
      <Route exact path="/admin/farmfeatures" component={FarmFeaturesPage} />
      <Route
        exact
        path="/admin/sustainability"
        component={SustainabilityPage}
      />
    </div>
  );
};

export default App;
