import React from "react";
import _ from "lodash";
import "./PopupContainer.scss";
import FarmPopup from "../../../components/FarmPopup/FarmPopup";
import CowPopup from "../../../components/CowPopup/CowPopup";
import FarmerPopup from "../../../components/FarmerPopup/FarmerPopup";
import PetPopup from "../../../components/PetPopup/PetPopup";
import BrandPopup from "../../../components/BrandPopUp/BrandPopup";
import OrbiterPopup from "../../../components/OrbiterPopup/OrbiterPopup";
import IceCreamFactoryPopup from "../../../components/IceCreamFactoryPopup/IceCreamFactoryPopup";
import DairyPopup from "../../../components/DairyPopup/DairyPopup";
import CheeseFactoryPopup from "../../../components/CheeseFactoryPopup/CheeseFactoryPopup";
import VehiclePopup from "../../../components/VehiclePopUp/VehiclePopup";
import ShopPopup from "../../../components/ShopPopup/ShopPopup";
import BatchCowListPopup from "../../../components/BatchCowListPopup/BatchCowListPopup";
import SustainabilityPlatePopup from "../../../components/SustainabilityPlatePopup/SustainabilityPlatePopup";
import MijnMelkPopup from "../../../components/MijnMelkPopup/MijnMelkPopup";
import AstronautPopup from "../../../components/AstronautPopup/AstronautPopup";
import DiscoveryPopup from "../../../components/DiscoveryPopup/DiscoveryPopup";
import GrazewayPopup from "../../../components/GrazewayPopup/GrazewayPopup";
import VectorPopup from "../../../components/VectorPopup/VectorPopup";
import CalmRobotPopUp from "../../../components/CalmRobotPopUp/CalmRobotPopUp";
import CowRuminationPopup from "../../../components/CowRuminationPopup/CowRuminationPopup";
import LunaRobotPopup from "../../../components/LunaRobotPopup/LunaRobotPopup";
import DisclaimerPopup from "../../../components/DisclaimerPopup/DisclaimerPopup";
import WhatIsThisWebsitePopup from "../../../components/WhatIsThisWebsite/WhatIsThisWebsite";
import {
  ACTOR_TYPE_BATCH_COW_LIST,
  ACTOR_TYPE_BRAND,
  ACTOR_TYPE_CALM_ROBOT,
  ACTOR_TYPE_CHEESE_FACTORY,
  ACTOR_TYPE_COW,
  ACTOR_TYPE_DAIRY_FACTORY,
  ACTOR_TYPE_FARM,
  ACTOR_TYPE_FARMER,
  ACTOR_TYPE_ICE_CREAM_FACTORY,
  ACTOR_TYPE_MIJN_MELK,
  ACTOR_TYPE_COLLECTOR,
  ACTOR_TYPE_MILK_FACTORY,
  ACTOR_TYPE_ORBITER_FACTORY,
  ACTOR_TYPE_GRAZEWAY,
  ACTOR_TYPE_DISCOVERY,
  ACTOR_TYPE_LUNA,
  ACTOR_TYPE_ASTRONAUT,
  ACTOR_TYPE_VECTOR,
  ACTOR_TYPE_JUNO,
  ACTOR_TYPE_PET,
  ACTOR_TYPE_SOLARPANEL,
  ACTOR_TYPE_SHOP,
  ACTOR_TYPE_SUSTAINABILITY_PLATE,
  ACTOR_TYPE_TRACTOR,
  ACTOR_TYPE_RUMINATION,
  ACTOR_TYPE_COOKIES,
  ACTOR_TYPE_COOKIE_STATEMENT,
  ACTOR_TYPE_DISCLAIMER,
  ACTOR_TYPE_WHAT_IS_THIS_WEBSITE,
} from "../../constants";

import {
  STORY_VARIETY_COW_LATEST,
  STORY_VARIETY_COW_HISTORY,
  STORY_VARIETY_ASTRONAUT_HISTORY,
  STORY_VARIETY_ASTRONAUT_LIVE_DATA,
  STORY_DISCOVERY,
  STORY_ORBITER_ROBOT_YOGHURT,
  STORY_VARIETY_ORBITER_YOGHURT,
  STORY_VARIETY_ORBITER_MILK,
  STORY_VARIETY_SUSTAINABILITY_STORY
} from "../../stories";

import {
  popupCallToActionClicked,
  showPopup,
} from "../../components/PopupContainer/actions";
import { useDispatch, useSelector } from "react-redux";
import SolarPanelPopup from "../../../components/SolarPanelPopup/SolarPanelPopup";
import CookiesPopup from "../../../components/CookiesPopup/CookiesPopup";
import CookieStatementPopup from "../../../components/CookiesPopup/CookieStatementPopup";
import JunoPopup from "../../../components/JunoPopup/JunoPopup";

/** PopupContainer component.
 * @param {Object} obj - Object which contains all props.
 * @param {String} [obj.farmId] - Farm id of current farm.
 */
function PopupContainer({ farmId, isAdultSelected }) {
  const dispatch = useDispatch();
  const { popupInfo } = useSelector((state) => state.popupDetails);
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const selectedCow = useSelector(
    (state) => state.selectedCowDetails.selectedCowDetails,
  );

  const getPopupJSXByPopupItemInfo = function (popupItemInfo) {
    let popupJSXElements = [];
    let selectedItemType =
      popupItemInfo && popupItemInfo.type && popupItemInfo.type.toLowerCase();  
    switch (selectedItemType) { 
      case ACTOR_TYPE_CALM_ROBOT:
        popupJSXElements.push(
          <CalmRobotPopUp
            selectedVehicleDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_FARM:
        popupJSXElements.push(
          <FarmPopup
            isAdultSelected={isAdultSelected}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
            farmData={popupItemInfo}
            popupItemInfo={popupItemInfo}
            selectedFarmDetails={popupItemInfo}
          />,
        );
        break;
      case ACTOR_TYPE_COW:
        popupJSXElements.push(
          <CowPopup
            selectedCowDetails={popupItemInfo}
            farmTheme={farmDetails.farmTheme}
            isAdultSelected={isAdultSelected}
            seeLatestClickFn={(cowDailyDetails) => {
              dispatch(
                popupCallToActionClicked(
                  _.cloneDeep(popupItemInfo),
                  STORY_VARIETY_COW_LATEST,
                  _.cloneDeep(cowDailyDetails),
                ),
              );
            }}
            seeHistoryClickFn={(selectedCowDetails) => {
              dispatch(
                popupCallToActionClicked(
                  _.cloneDeep(popupItemInfo),
                  STORY_VARIETY_COW_HISTORY,
                  _.cloneDeep(selectedCowDetails),
                ),
              );
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_FARMER:
        popupJSXElements.push(
          <FarmerPopup
            selectedFarmerDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_PET:
        popupJSXElements.push(
          <PetPopup
            selectedPetDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_BRAND:
        popupJSXElements.push(
          <BrandPopup
            selectedBrandDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_MILK_FACTORY:
        popupJSXElements.push(
          <OrbiterPopup
            selectedOrbiterFactoryDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(
                popupCallToActionClicked(
                  _.cloneDeep(popupItemInfo),
                  STORY_VARIETY_ORBITER_MILK
                ),
              );
            }}
            secondCallToActionClickFn={() => {
              dispatch(
                popupCallToActionClicked(
                  _.cloneDeep(popupItemInfo),
                  STORY_VARIETY_ORBITER_YOGHURT
                ),
              );
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_ICE_CREAM_FACTORY:
        popupJSXElements.push(
          <IceCreamFactoryPopup
            selectedIceCreamFactoryDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      // case ACTOR_TYPE_ORBITER_FACTORY:
      //   popupJSXElements.push(
      //     <OrbiterPopup
      //       selectedOrbiterFactoryDetails={popupItemInfo}
      //       isAdultSelected={isAdultSelected}
      //       callToActionClickFn={() => {
      //         dispatch(popupCallToActionClicked(popupItemInfo));
      //       }}
            
      //       closePopupClickFn={() => {
      //         dispatch(showPopup({}));
      //       }}
      //     />,
      //   );
      //   break;
      case ACTOR_TYPE_DAIRY_FACTORY:
        popupJSXElements.push(
          <DairyPopup
            selectedDairyFactoryDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_CHEESE_FACTORY:
        popupJSXElements.push(
          <CheeseFactoryPopup
            selectedCheeseFactoryDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_TRACTOR:
        popupJSXElements.push(
          <VehiclePopup
            selectedVehicleDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_SHOP:
        popupJSXElements.push(
          <ShopPopup
            selectedShopDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_COOKIES:
        popupJSXElements.push(<CookiesPopup 
          isAdultSelected={isAdultSelected}
      />);
        break;
      case ACTOR_TYPE_COOKIE_STATEMENT:
        popupJSXElements.push(<CookieStatementPopup 
          isAdultSelected={isAdultSelected}
        />);
        break;
      case ACTOR_TYPE_DISCLAIMER:
        popupJSXElements.push(<DisclaimerPopup />);
        break;
      case ACTOR_TYPE_WHAT_IS_THIS_WEBSITE:
        popupJSXElements.push(<WhatIsThisWebsitePopup />);
        break;
      case ACTOR_TYPE_BATCH_COW_LIST:
        popupJSXElements.push(
          <BatchCowListPopup
            allCows={farmDetails.allCows}
            farmTheme={farmDetails.farmTheme}
            farmId={farmId}
            batchCows={
              farmDetails.milkBatch
                ? farmDetails.milkBatch.cows
                : farmDetails.cowsWithFullInfo
            }
            isAdultSelected={isAdultSelected}
            externalId={farmDetails?.milkBatch?.externalId}
            batchless={!farmDetails.milkBatch}
            farmName={farmDetails.farmName}
            batchProductType={farmDetails?.milkBatch?.batchProductType}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
            selectedCow={selectedCow}
            showActorStoryFn={() => {
              //dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            moveToNextSlideFn={() => {
              //moveToNextSlideFn
            }}
          />,
        );
        break;
      case ACTOR_TYPE_SUSTAINABILITY_PLATE:
        popupJSXElements.push(
          <SustainabilityPlatePopup
            isAdultSelected={isAdultSelected}
            selectedSustainabilityDetails={popupItemInfo}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            secondCallToActionClickFn={() => {
              dispatch(
                popupCallToActionClicked(
                  _.cloneDeep(popupItemInfo),
                  STORY_VARIETY_SUSTAINABILITY_STORY
                ),
              );
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_MIJN_MELK:
        popupJSXElements.push(
          <MijnMelkPopup 
            batchProductType={farmDetails?.milkBatch?.batchProductType}
            selectedMijnMelkDetails={popupItemInfo}
            farmName={farmDetails.farmName}
            farmId={farmId}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            secondCallToActionClickFn={() => {
              dispatch(showPopup({ type: ACTOR_TYPE_BATCH_COW_LIST }));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_RUMINATION:
        popupJSXElements.push(
          <CowRuminationPopup
            isAdultSelected={isAdultSelected}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_ASTRONAUT:
        popupJSXElements.push(
          <AstronautPopup
            selectedAstronautFactoryDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            batchCows={
              farmDetails.milkBatch
                ? farmDetails.milkBatch.cows
                : farmDetails.cowsWithFullInfo
            }
            milkingNotification={
              farmDetails &&
              farmDetails.realTimeData &&
              farmDetails.realTimeData.milkingNotifications
            }
            seeLatestClickFn={(cowDailyDetails) => {
              dispatch(
                popupCallToActionClicked(
                  _.cloneDeep(popupItemInfo),
                  STORY_VARIETY_ASTRONAUT_HISTORY,
                  _.cloneDeep(cowDailyDetails),
                ),
              );
            }}
            seeHistoryClickFn={(selectedCowDetails) => {
              dispatch(
                popupCallToActionClicked(
                  _.cloneDeep(popupItemInfo),
                  STORY_VARIETY_ASTRONAUT_LIVE_DATA,
                  _.cloneDeep(selectedCowDetails),
                ),
              );
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
            farmId={farmId}
          />,
        );
        break;
      case ACTOR_TYPE_VECTOR:
        popupJSXElements.push(
          <VectorPopup
            selectedOrbiterFactoryDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break; 
        case ACTOR_TYPE_JUNO:
          popupJSXElements.push(
            <JunoPopup
              selectedOrbiterFactoryDetails={popupItemInfo}
              isAdultSelected={isAdultSelected}
              callToActionClickFn={() => {
                dispatch(popupCallToActionClicked(popupItemInfo));
              }}
              closePopupClickFn={() => {
                dispatch(showPopup({}));
              }}
            />,
          );
          break; 

      case ACTOR_TYPE_GRAZEWAY:
        popupJSXElements.push(
          <GrazewayPopup
            selectedGrazewayFeatureDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_DISCOVERY:
        popupJSXElements.push(
          <DiscoveryPopup
            selectedDiscoveryFactoryDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_COLLECTOR:
        popupJSXElements.push(
          <DiscoveryPopup
            selectedDiscoveryFactoryDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_LUNA:
        popupJSXElements.push(
          <LunaRobotPopup
            selectedDiscoveryFactoryDetails={popupItemInfo}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
      case ACTOR_TYPE_SOLARPANEL:
        popupJSXElements.push(
          <SolarPanelPopup
            selectedSolarPanelsDetails={popupItemInfo}
            solarPanelData={farmDetails.solarPanel}
            isAdultSelected={isAdultSelected}
            callToActionClickFn={() => {
              dispatch(popupCallToActionClicked(popupItemInfo));
            }}
            closePopupClickFn={() => {
              dispatch(showPopup({}));
            }}
          />,
        );
        break;
    }
    return popupJSXElements;
  };

  return (
    <>
      <div
        className={
          !_.isEmpty(popupInfo)
            ? "virtualFarm--background-overlay is-virtual-farm-displayed"
            : "virtualFarm--background-overlay"
        }
      />
      <div className="popup-container">
        {!_.isEmpty(popupInfo) ? getPopupJSXByPopupItemInfo(popupInfo) : null}
      </div>
    </>
  );
}

export default PopupContainer;
