import _ from 'lodash';
import React from 'react';
import './GenericSlide.scss';
import TextCaption from '../../components/TextCaption/TextCaption';


function GenericSlide({ headingText, bodyText, backgroundImageUrl, cssClassName }) {
  let genericSlideCssClassName = cssClassName ? "genericSlidePage" + " " + cssClassName : "genericSlidePage";
  return (
    <div className={genericSlideCssClassName}>
      <h3 className="textCaption--heading textCaption--heading--genericSlide">{headingText}</h3>
      <TextCaption additionalCssClassname="textCaption--genericSlide">
        <p className="cow-introSecond--text">{bodyText}</p> 
      </TextCaption>
    </div>
  );
}

export default GenericSlide;
