import {
  COW_ENTERED,
  COW_EXIT,
  RESET_ASTRONAUT_STATE,
} from "../components/LiveDataPopup/types";

/** Store states
 * @constant {Object} */
export const initialState = {
  milkedCowDetails: {},
};

/** Live Data Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function liveDataReducer(state = initialState, action) {
  switch (action.type) {
    case COW_ENTERED:
      return {
        ...state,
        milkedCowDetails: action.payload.cowDetails,
      };

    case COW_EXIT:
      return {
        ...state,
        milkedCowDetails: {},
      };

    case RESET_ASTRONAUT_STATE:
      return {
        ...state,
        milkedCowDetails: {},
      };

    default:
      return state;
  }
}

export default liveDataReducer;
