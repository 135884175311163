import axios from "axios";
import {
  FILTER_ASTRONAUT_FARM_ID,
  GET_ASTRONAUT_DETAILS_SERVICE_URL,
} from "./config";

export const MOCK_ASTRONAUT_API_RESPONSE = {
  name: "Alex",
  model: "A4-101",
  cowsMilkedLast24Hours: 42,
  kgMilkMilkedLast24Hours: 764.0,
  kgMilkSeparatedLast24Hours: 78.0,
  concentratesGivenLast24Hours: 322.0,
  secondsMilkingLast24Hours: 14535,
  secondsMovingLast24Hours: 13702,
  secondsCleaningLast24Hours: 3408,
  milkingTechnicalInfo: {
    externalId: "NL 123456789",
    teatFrontLeftCoordinateX: 52,
    teatFrontLeftCoordinateY: 743,
    teatFrontLeftCoordinateZ: 656,
    teatFrontRightCoordinateX: 9,
    teatFrontRightCoordinateY: 728,
    teatFrontRightCoordinateZ: 670,
    teatRearLeftCoordinateX: 73,
    teatRearLeftCoordinateY: 654,
    teatRearLeftCoordinateZ: 637,
    teatRearRightCoordinateX: -17,
    teatRearRightCoordinateY: 663,
    teatRearRightCoordinateZ: 640,
    teatMilkFrontLeftRed: 5869,
    teatMilkFrontLeftGreen: 4906,
    teatMilkFrontLeftBlue: 8049,
    teatMilkFrontRightRed: 7052,
    teatMilkFrontRightGreen: 2971,
    teatMilkFrontRightBlue: 2157,
    teatMilkRearLeftRed: 7442,
    teatMilkRearLeftGreen: 2750,
    teatMilkRearLeftBlue: 2334,
    teatMilkRearRightRed: 9703,
    teatMilkRearRightGreen: 3507,
    teatMilkRearRightBlue: 6859,
    id: 0,
  },
  id: 0,
};

export const api = {
  getAstronautDetails: async function (farmId, cowId) {
    let astronautDetailsServiceUrl =
      GET_ASTRONAUT_DETAILS_SERVICE_URL +
      cowId +
      FILTER_ASTRONAUT_FARM_ID +
      farmId;
    return axios.get(astronautDetailsServiceUrl, {});
  },
};
