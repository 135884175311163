import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import BreedColorSelector from './BreedColorSelector';
import { translate } from '../../../localization/TranslationUtil';
import SelectLabels from '../DropdownMenu/DropdownMenu';

// Styles
import './CowBreedBlock.scss';

/** CowBreedBlock component.
 * @param {Object} obj - Object which contains all props.
 * @param {string} [obj.labelText] - Label for the text input
 * @param {Object} [obj.selectedBreed]
 * @param {Number} [obj.selectedBreedIndex] - The index of the selected breed from the dropdown
 * @param {Function} [obj.onHandleBreedColorsChangedFn] -
 * @param {Function} [obj.onHandleBreedSelectedChangedFn] -
 * @param {Object} [obj.cowBreedConfiguration] - Stores all cow breeds and the current cow breed selection status
 * @param {string} [obj.additionalClassName] - Additional CSS classname to be added in order to style further
 * @param {Function} {obj.onDeleteBreedButtonClickFn}
 * @param {Number} slotId
 * @param {Boolean} isDeleteBreedButtonDisabled
 *
 */
function CowBreedBlock({
  labelText,
  onHandleBreedColorsChangedFn,
  onHandleBreedSelectedChangedFn,
  cowBreedConfiguration,
  selectedBreed,
  selectedBreedIndex,
  onDeleteBreedButtonClickFn,
  additionalClassName,
  slotId,
  isDeleteBreedButtonDisabled,
}) {
  const [selectedDropdownIndex, setSelectedDropdownIndex] =
    useState(selectedBreedIndex);
  const [currentBreed, setCurrentBreed] = useState(selectedBreed);

  useEffect(() => {
    setCurrentBreed(selectedBreed);
  }, [selectedBreed]);

  function handleCowBreedColorsChanged(breedInfo) {
    onHandleBreedColorsChangedFn(breedInfo);
    setCurrentBreed(breedInfo);
  }

  function handleCowBreedSelectedChanged(breedInfo, previousBreedInfo, slotId) {
    onHandleBreedSelectedChangedFn(breedInfo, previousBreedInfo, slotId);
  }

  function handleDeleteBreedButtonClicked() {
    onDeleteBreedButtonClickFn(currentBreed);
  }

  let availableCowBreeds =
    cowBreedConfiguration.length &&
    cowBreedConfiguration.map((currentBreed, currentBreedIndex) => {
      return {
        name: currentBreed.name,
        code: currentBreed.name,
      };
    });
  return (
    <div className="cow--breed--container--block">
      <SelectLabels
        labelText={translate('SELECT_COW_BREED')}
        initialValue={currentBreed?.name}
        options={availableCowBreeds}
        additionalClassName="cow--breed--selection"
        handleChangeFn={(breedCode) => {
          let selectedBreedInfo = cowBreedConfiguration.filter(
            (breed) => breed.name === breedCode,
          )[0];
          handleCowBreedSelectedChanged(
            selectedBreedInfo,
            _.cloneDeep(currentBreed),
            slotId,
          );
          setCurrentBreed(selectedBreedInfo);
        }}
      />
      <BreedColorSelector
        cowBreedData={currentBreed}
        key={currentBreed}
        onCowBreedChangeFn={(currentBreed) => {
          handleCowBreedColorsChanged(currentBreed);
        }}
      />
      <button
        style={{ marginTop: '1vw', marginBottom: '1vw' }}
        className="btn-delete-breed-block"
        type="button"
        disabled={isDeleteBreedButtonDisabled}
        onClick={function () {
          handleDeleteBreedButtonClicked();
        }}
      >
        <i className="lely-icon-trash"></i>
      </button>{' '}
      <span style={{ paddingLeft: '0.75vw' }}>
        {translate('DELETE_COW_BREED')}
      </span>
    </div>
  );
}

export default CowBreedBlock;
