import { LOGIN_REQUEST } from "./types.js";
import { loginSuccess, loginError } from "./actions.js";
import { put, take, call } from "redux-saga/effects";
import { redirect } from "../../CustomRedirect/actions";
import { getServerResponseStatus } from "../../ErrorPopUp/actions";
import {
  startLoading,
  endLoading,
} from "../../../common/redux/loadManager/actions";
import { getLoadId } from "../../../common/redux/loadManager/methods";
import {
  startWaitingForAccessTokenExpiration,
  accessTokenRefreshSuccess,
} from "../../AccessToken/actions.js";

let currentLoadId = 0;

/** Call authorizeUser with username and password */
export default function* loginSaga(api) {
  while (true) {
    const loginRequest = yield take(LOGIN_REQUEST);
    if (loginRequest.payload) {
      /** Run loader */
      currentLoadId = getLoadId();
      yield put(startLoading(currentLoadId));
      const { username, password } = loginRequest.payload;
      yield call(authorizeUser, api, username, password);
    }
  }
}

/** Create login request
 * @param {object} api - API providing access to the Lely backend
 * @param {string} username - Username of the user
 * @param {any} password - Password of the user
 */
function* authorizeUser(api, username, password) {
  var response;
  try {
    response = yield call(api.login, username, password);
    const { accessToken, refreshToken, userRoles, userLanguage } =
      response.data;
    yield put(loginSuccess(username, accessToken, userRoles));
    yield put(accessTokenRefreshSuccess(accessToken, refreshToken));
    yield put(startWaitingForAccessTokenExpiration(accessToken, refreshToken));
    /** Update state when server is online */
    yield put(getServerResponseStatus(""));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
    yield put(redirect("/admin/aboutyou"));
  } catch (error) {
    yield put(loginError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
    /** Listen for error state to show when server is disconected */
    yield put(getServerResponseStatus(error));
  }
}
