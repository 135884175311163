import React from "react";
import "./SecondSlideAstronaut.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const SecondSlideAstronaut = ({}) => {
  return (
    <div className="astronautStorySecondSlide">
      <TextCaption additionalCssClassname="astronautStorySecondSlide--textCaption">
        <div className="astronautStorySecondSlide--title">
          {translate("ASTRONAUT_HISTORY_TITLE")}
        </div>
        <div className="astronautStorySecondSlide--text">
          {translate("ASTRONAUT_HISTORY_TEXT")}
        </div>
      </TextCaption>
      <div className="astronautStorySecondSlide--imgCaption">
        <div className="astronautStorySecondSlide--imgContainer"></div>
      </div>
      <div className="astronautStorySecondSlide--transitionTree"></div>
    </div>
  );
};

export default SecondSlideAstronaut;
