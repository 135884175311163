import React, { useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import IconButton from "../IconButton/IconButton";
import {
  translateWithValues,
  translate,
  translateDynamic,
} from "../../localization/TranslationUtil";
import CowPopup from "../../components/CowPopup/CowPopup";
import { selectActor } from "../../components/Actor/actions";
import { selectCow } from "../../components/Cow/actions";
import {
  STORY_VARIETY_COW_LATEST,
  STORY_VARIETY_COW_HISTORY,
} from "../../common/stories";
import "./BatchCowListPopup.scss";
import "../MijnMelkOnboardingAdult/MijnMelkBottleVariety.scss";
import { popupCallToActionClicked } from "../../common/components/PopupContainer/actions";
import {
  FARM_THEME_MILK_BOTTLE,
  IS_CLICKABLE_CSS_CLASSNAME,
} from "../../common/constants";
import { getMijnMelkPopupSvgFilePath } from "../../common/svgUtil";

const BatchCowListPopup = ({
  allCows,
  batchCows,
  batchless,
  farmName,
  farmId,
  closePopupClickFn,
  externalId,
  selectedCow,
  showActorStoryFn,
  moveToNextSlideFn,
  farmTheme,
  isAdultSelected,
  batchProductType,
}) => {
  const isHorizonUser = farmTheme !== FARM_THEME_MILK_BOTTLE;
  const batchCowListTranslationTitle = translateDynamic(farmName);
  const batchCowListPopupTitle = isHorizonUser
    ? translateWithValues("HORIZON_USERS_COW_LIST_POPUP_TITLE", {
        farmName: batchCowListTranslationTitle,
      })
    : batchProductType?.includes("Yogurt") ||
      batchProductType?.includes("Yoghurt")
    ? translateWithValues("BATCH_COW_LIST_POPUP_TITLE_YOGURT", {
        batchCowListTranslationTitle: batchCowListTranslationTitle,
      })
    : translateWithValues("BATCH_COW_LIST_POPUP_TITLE", {
        batchCowListTranslationTitle: batchCowListTranslationTitle,
      });
  const batchCowListPopupCssClassName= isHorizonUser ? 'horizonBatchListPopup':'';
  const batchCowListPopupDescription = translate(
    batchless
      ? "BATCHLESS_COW_LIST_POPUP_DESCRIPTION"
      : "BATCH_COW_LIST_POPUP_DESCRIPTION",
  );
  const nonBatchCowListPopupDescription = translate(
    "NON_BATCH_COW_LIST_POPUP_DESCRIPTION",
  );
  const nonBatchCows = allCows.filter(
    (ac) => batchCows.findIndex((bc) => bc.externalId === ac.externalId) === -1,
  );
  const [showCowDataPopup, setShowCowDataPopup] = useState(false);
  const dispatch = useDispatch();

  const getNameOfTheCow = (cow) => {
    return cow.name == null && cow.poll.chosenName !== null
      ? cow.poll.chosenName
      : cow.name;
  };
  const getCowTypeCssStyle = (cowType) => {
    const standardClass = "batchCowListPopup--";
    return cowType
      ? standardClass + cowType.toLowerCase()
      : standardClass + "friesian";
  };
  const mijnMelkBottleCssClassname = getMijnMelkPopupSvgFilePath(
    farmId,
    batchProductType,
  );
  const renderCowPopup = () => {
    return  (
      <div className="batchCowListPopup--overlay">
        <div className="cowPopup">
          <CowPopup
            selectedCowDetails={selectedCow}
            farmTheme={farmTheme}
            isAdultSelected={isAdultSelected}
            seeLatestClickFn={(cowDailyDetails) => {
              dispatch(
                popupCallToActionClicked(
                  _.cloneDeep(selectedCow),
                  STORY_VARIETY_COW_LATEST,
                  _.cloneDeep(cowDailyDetails),
                ),
              );
              showActorStoryFn(
                selectedCow,
                STORY_VARIETY_COW_LATEST,
                cowDailyDetails,
              );
              moveToNextSlideFn();
            }}
            seeHistoryClickFn={(selectedCowDetails) => {
              dispatch(
                popupCallToActionClicked(
                  _.cloneDeep(selectedCow),
                  STORY_VARIETY_COW_HISTORY,
                  _.cloneDeep(selectedCowDetails),
                ),
              );
            }}
            closePopupClickFn={() => {
              setShowCowDataPopup(false);
            }}
            isBatchCow={
              !!batchCows.filter((cow) => cow.id === selectedCow.id).length
            }
          />
        </div>
      </div>
    ) 
  };
  return (
    <div className={`batchCowListPopup ${batchCowListPopupCssClassName}`}>
      <div className="batchCowListPopup--headers">
        {!isHorizonUser ? (
          batchProductType === "Yogurt" || batchProductType === "Yoghurt" ? (
            <div
              className={
                "batchCowListPopup--yoghurtBottle " + mijnMelkBottleCssClassname
              }
            ></div>
          ) : (
            <div
              className={
                "batchCowListPopup--milkBottle " + mijnMelkBottleCssClassname
              }
            ></div>
          )
        ) : null}
        {!batchless ? (
          <div className="batchCowListPopup-batchNumber">
            <div className="batchCowListPopup-batchNumber--text">
              {translate("MIJN_MELK_ADULT_ONBOARDING_SLIDE1_BATCH_LABEL")}
            </div>
            <div className="batchCowListPopup-batchNumber--number">
              {externalId}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="batchCowListPopup--title">{batchCowListPopupTitle}</div>
        {/* <div className="batchCowListPopup--description">
          {batchCowListPopupDescription}
        </div> */}
        {/* <div className="batchCowListPopup--rightArrow"></div> */}
      </div>
      <div className="batchCowListPopup--cowListBody">
        {showCowDataPopup && renderCowPopup()}
        <div className="batchCowListPopup--batchCowsList">
          {batchCows.map((cow, index) => {
            return cow?.lastMilkingInfo?.milkYieldKg ||
              cow?.lastMilkingInfo?.milkProteinPercentage ||
              cow?.lastMilkingInfo?.milkLactosePercentage ||
              cow?.lastMilkingInfo?.milkFatPercentage ||
              cow?.milkWeightInKgs ||
              cow?.milkProteinPercentage ||
              cow?.milkLactosePercentage ||
              cow?.milkFatPercentage ? (
              <div className="batchCowListPopup--cowContainer" key={index}>
                <div
                  className={`batchCowListPopup--cowContainer--cowImg ${getCowTypeCssStyle(
                    cow.cowSubBreed,
                  )} ${IS_CLICKABLE_CSS_CLASSNAME}`}
                  onClick={() => {
                    setShowCowDataPopup(true);
                    dispatch(selectCow(cow));
                    dispatch(selectActor(cow));
                  }}
                />
                 
                <div className="batchCowListPopup--cowContainer--cowName">
                  <div className="batchCowListPopup--cowContainer--cowNameFirstRow">
                    {getNameOfTheCow(cow)}
                  </div>
                  {cow?.lastMilkingInfo?.milkYieldKg || cow?.milkWeightInKgs ? (
                    <div className="batchCowListPopup--cowContainer--cowMilkWeightInKgs">
                      {translateWithValues("BATCH_LIST_MILK_KG", {
                        milkKg: batchless
                          ? cow?.lastMilkingInfo?.milkYieldKg?.toFixed(0)
                          : cow?.milkWeightInKgs?.toFixed(0),
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                  {cow?.lastMilkingInfo?.milkProteinPercentage ||
                  cow?.milkProteinPercentage ? (
                    <div className="batchCowListPopup--cowContainer--cowProtein">
                      {translateWithValues("BATCH_LIST_MILK_PROTEIN", {
                        milkProtein: batchless
                          ? cow?.lastMilkingInfo?.milkProteinPercentage?.toFixed(
                              2,
                            )
                          : cow?.milkProteinPercentage?.toFixed(2),
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                  {cow?.lastMilkingInfo?.milkLactosePercentage ||
                  cow?.milkLactosePercentage ? (
                    <div className="batchCowListPopup--cowContainer--cowLactose">
                      {translateWithValues("BATCH_LIST_MILK_LACTOSE", {
                        milkLactose: batchless
                          ? cow?.lastMilkingInfo?.milkLactosePercentage?.toFixed(
                              2,
                            )
                          : cow?.milkLactosePercentage?.toFixed(2),
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                  {cow?.lastMilkingInfo?.milkFatPercentage ||
                  cow?.milkFatPercentage ? (
                    <div className="batchCowListPopup--cowContainer--cowFat">
                      {translateWithValues("BATCH_LIST_MILK_FAT", {
                        milkFat: batchless
                          ? cow?.lastMilkingInfo?.milkFatPercentage?.toFixed(2)
                          : cow?.milkFatPercentage?.toFixed(2),
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            );
          })}
        </div>
        <div className="batchCowListPopup--nonBatchCowsList">
          <div className="batchCowListPopup--nonBatchCowsListDescription">
            {nonBatchCowListPopupDescription}
          </div>
          <div className="batchCowListPopup--nonBatchCowsList">
            {nonBatchCows.map((cow, index) => {
              return (
                <div className="batchCowListPopup--cowContainer" key={index}>
                  <div
                    className={
                      "batchCowListPopup--cowContainer--cowName " +
                      IS_CLICKABLE_CSS_CLASSNAME
                    }
                    onClick={() => {
                      setShowCowDataPopup(true);
                      dispatch(selectCow(cow));
                      dispatch(selectActor(cow));
                    }}
                  >
                    {cow.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="batchCowListPopup--bottomGradient"></div>
      <IconButton
        cssClassName="batchCowListPopup--btnClosePopup"
        onClickFn={closePopupClickFn}
      />
    </div>
   );
};

export default BatchCowListPopup;
