import _ from "lodash";
import React, { useEffect, useState } from "react";
import "./SustainabiltyPage.scss";
import { useSelector, useDispatch } from "react-redux";
import { translate } from "../../../localization/TranslationUtil";
import LabelledTextInput from "../../../common/components/LabelledTextInput/LabelledTextInput";
import LabelledTextArea from "../../../common/components/LabelledTextArea/LabelledTextArea";
import ImageRadioSelection from "../../../common/components/ImageRadioSelection/ImageRadioSelection";
import LabelledImageDropZone from "../../../common/components/LabelledImageDropZone/LabelledImageDropZone";
import { Button } from "@material-ui/core";
import {
  requestSustainabilityPageInformation,
  saveChangesToSustainabilityPage,
} from "./actions";
import { getCurrentLanguage } from "../../../localization/TranslationUtil";
import LanguageSelection from "../../../common/components/LanguageSelection/LanguageSelection";
import { DEFAULT_LANGUAGE_OPTIONS } from "../../../common/constants";
import { requestSelectedFarmLanguagesInformation } from "../../../common/components/LanguageSelection/actions";
import { convertLanguageSelectionToBE } from "../../../common/components/LanguageSelection/LanguageUtil";
import MultiLanguageTextArea from "../../../common/components/MultiLanguageTextArea/MultiLanguageTextArea";
import CheckboxGroupSelection from "../../../common/components/CheckboxGroupSelection/CheckboxGroupSelection";
import useSaveManager from "../../../hooks/useSaveManager";
import SaveChangesConfirmationDialog from "../../../components/SaveChangesConfirmationDialog/SaveChangesConfirmationDialog";

function SustainabilityPage() {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => ({ ...state.userSession }));
  const { sustainabilityDetails } = useSelector((state) => ({
    ...state.sustainabilityDetails,
  }));
  //#region farm languages logic
  const { farmLanguagesDetails } = useSelector((state) => ({
    ...state.selectedFarmLanguages,
  }));
  let languageKeysForAllLanguagesAvailable = DEFAULT_LANGUAGE_OPTIONS.filter(
    (currentLanguageOption) => currentLanguageOption.code.length === 2,
  ).map((currentLanguageOption) => currentLanguageOption.code);

  function initializeLanguageSelection(languagesDataFromBE) {
    let selectedLanguageObjects =
      languagesDataFromBE && languagesDataFromBE.languages
        ? languagesDataFromBE.languages
        : DEFAULT_LANGUAGE_OPTIONS;
    let initialLanguageSelection = selectedLanguageObjects
      .filter(
        (currentLanguageOption) => currentLanguageOption.code.length === 2,
      )
      .map((currentLanguageOption) => currentLanguageOption.code);
    return initialLanguageSelection;
  }

  let initialLanguageSelection =
    initializeLanguageSelection(farmLanguagesDetails);
  const [farmLanguages, setFarmLanguages] = useState(initialLanguageSelection);

  useEffect(() => {
    let initialLanguageSelection =
      initializeLanguageSelection(farmLanguagesDetails);
    setFarmLanguages(initialLanguageSelection);
  }, [farmLanguagesDetails.languages]);

  const getInitialLanguage = () => {
    if (sustainabilityDetails.languages) {
      const supportedLanguages = sustainabilityDetails.languages.map(
        (lang) => lang.code,
      );
      const defLanguage =
        supportedLanguages.indexOf("en") > -1 ? "en" : supportedLanguages[0];
      const currentLanguage = getCurrentLanguage(
        defLanguage,
        supportedLanguages,
      );
      return currentLanguage;
    }
    return "";
  };

  const [currentLanguage, setCurrentLanguage] = useState(getInitialLanguage());
  //#endregion

  const [newSustainabilityDetails, setNewSustainabilityDetails] = useState(
    sustainabilityDetails,
  );
  console.log('sustainabilityDetails', newSustainabilityDetails)
  const { isSaveChangesConfirmationPopupDisplayed } = useSelector((state) => ({
    ...state.saveChangesDetails,
  }));
  const [isSaved, setIsSaved] = useState(true);
  const handleSaveChangesButtonClick = () => {
    let newLanguageDetails = convertLanguageSelectionToBE(
      farmLanguages,
      DEFAULT_LANGUAGE_OPTIONS,
    );
    dispatch(
      saveChangesToSustainabilityPage(
        accessToken,
        newSustainabilityDetails,
        newLanguageDetails,
      ),
    );
    setSaved(true);
  };
  const { setSaved, lastSaveTimestamp } = useSaveManager(
    handleSaveChangesButtonClick,
    isSaved,
    setIsSaved,
  );

  useEffect(() => {
    dispatch(requestSustainabilityPageInformation(accessToken));
    dispatch(requestSelectedFarmLanguagesInformation(accessToken));
  }, []);

  useEffect(() => {
    setNewSustainabilityDetails(sustainabilityDetails);
    setCurrentLanguage(getInitialLanguage());
    sustainabilityDetails &&
      sustainabilityDetails.hasSustainabilityData &&
      setHasInformationAboutSustainability(true);
  }, [sustainabilityDetails]);

  const modifySustainabilityDetails = (key, value) => {
    //setNewSustainabilityDetails({ ...newSustainabilityDetails, [key]: value }); 
    setNewSustainabilityDetails({
      ...newSustainabilityDetails,
      sustainabilityData: {
        ...newSustainabilityDetails.sustainabilityData,
        [key]: value
      }
    });
    setSaved(false);
  };
  const modifyAnimalWelfareDetails = (key, value) => {
    //setNewSustainabilityDetails({ ...newSustainabilityDetails, [key]: value }); 
    setNewSustainabilityDetails({
      ...newSustainabilityDetails,
      animalWelfare: {
        ...newSustainabilityDetails.animalWelfare,
        [key]: value
      }
    });
    setSaved(false);
  };
  const modifySustainabilityDetailsWithLanguage = (key, value) => {
    setNewSustainabilityDetails({
      ...newSustainabilityDetails,
      [key]: {
        ...newSustainabilityDetails[key],
        [currentLanguage]: value,
      },
    });
    setSaved(false);
  };
  const [
    hasInformationAboutSustainability,
    setHasInformationAboutSustainability,
  ] = useState(
    newSustainabilityDetails && newSustainabilityDetails.hasSustainabilityData,
  );

  return (
    <div className="AboutSustainabilityPage">
      <h3 className="about-sustainability--header">
        {translate("MENU_ITEM_SUSTAINABILITY")}
      </h3>
      <p className="sustainability--intro">
        {translate("SUSTAINABILITY_PAGE_INTRO_TEXT")}
      </p>
      <LanguageSelection
        handleLanguagesChangedFn={(
          newLanguageCodesSelected,
          isManualChange,
        ) => {
          setFarmLanguages(newLanguageCodesSelected);
          if (isManualChange) {
            setSaved(false);
          }
        }}
        allAvailableLanguageCodesArray={languageKeysForAllLanguagesAvailable}
        selectedLanguageCodesArray={farmLanguages}
      />
      <div className="adminPanel--sustainabilityModal">
        <h3 className="adminPanel--sustainability--header">
          {translate("SIDE_MENU_OPTION_SUSTAINABILITY")}
        </h3>
        <p className="adminPanel--sustainability--subheading">
          {translate("SUSTAINABILITY_INTRODUCTION_ADMIN_PANEL")}
        </p> 
        {newSustainabilityDetails ? <>
              <MultiLanguageTextArea
                multiLanguageData={newSustainabilityDetails?.sustainabilityData?.sustainabilityPopupStory}
                fieldLabelTranslationKey={""}
                givenLength={250}
                fieldPlaceholderTextTranslationKey={""}
                handleDataChangedFn={(newCarbonDioxideStoryMultiLanguageData) =>
                  modifySustainabilityDetails(
                    "sustainabilityPopupStory",
                    newCarbonDioxideStoryMultiLanguageData,
                  )
                }
                languageKeysArray={farmLanguages}
                additionalCssClassname="sustainabilityModal--textArea"
              />
              <LabelledImageDropZone
                labelText={translate("ABOUT_YOU_YOUR_STORY_BLOCK_PHOTO_TEXT")}
                dropzoneText={translate(
                  "ABOUT_YOU_PAGE_CHANGE_PROFILE_PICTURE_DROP_ZONE_LABEL",
                )}
                initialValue={
                  newSustainabilityDetails.sustainabilityData &&
                  newSustainabilityDetails.sustainabilityData.sustainabilityPopupImageData
                }
                key={
                  newSustainabilityDetails.sustainabilityData &&
                  newSustainabilityDetails.sustainabilityData.sustainabilityPopupImageData
                }
                onChangeFileFn={(newFileBase64) => {
                  setNewSustainabilityDetails({
                    ...newSustainabilityDetails,
                    sustainabilityData: {
                      ...newSustainabilityDetails.sustainabilityData,
                      sustainabilityPopupImageData: newFileBase64,
                    },
                  });
                  setSaved(false);
                }}
                onDeleteFileFn={() => {
                  setNewSustainabilityDetails({
                    ...newSustainabilityDetails,
                    sustainabilityData: {
                      ...newSustainabilityDetails.sustainabilityData,
                      sustainabilityPopupImageData: "",
                    },
                  });
                  setSaved(false);
                }}
                defaultPreviewCssClassname="storyImagePreview"
              />
            </> : ""}
      </div>
      <div className="adminPanel--sustainability">
        <h3 className="adminPanel--sustainability--header">
          {translate("SUSTAINABILITY_ON_THE_FARM")}
        </h3>
        <p className="adminPanel--sustainability--subheading">
          {translate("SUSTAINABILITY_SUBHEADING")}
        </p>
        <p className="adminPanel--sustainability--subheading">
          {translate("SUSTAINABILITY_SECOND_SUBHEADING")}
        </p>
        <ImageRadioSelection
          optionsArray={[
            {
              label: translate("YES"),
              value: "1",
              classNameAppendix: "inline",
            },
            {
              label: translate("NO"),
              value: "0",
              classNameAppendix: "inline",
            },
          ]}
          initialSelectedValue={
            newSustainabilityDetails?.sustainabilityData?.hasSustainabilityData ? "1" : "0"
          }
          onSelectionChangeFn={(newSelectedValue) => {
            setNewSustainabilityDetails({
              ...newSustainabilityDetails,
              sustainabilityData: {
                ...newSustainabilityDetails.sustainabilityData,
                hasSustainabilityData: newSelectedValue == 1 ? true : false,
              }
            });
            setSaved(false);
            setHasInformationAboutSustainability(newSelectedValue === "1");
          }}
        />
        {newSustainabilityDetails?.sustainabilityData?.hasSustainabilityData ? (
          <>
            <div className="sustainabilityPage--milkyield">
              {translate("SUSTAINABILITY_MILKPRODUCTION")}
            </div>
            <LabelledTextInput
              inputType={"number"}
              initialValue={
                newSustainabilityDetails?.sustainabilityData?.annualMilkProductionInLiters
              }
              labelText={translate("SUSTAINABILITY_ANNUAL_PRODUCTION")}
              placeholderText={translate(
                "SUSTAINABILITY_ANNUAL_PRODUCTION_PLACEHOLDER",
              )}
              additionalClassName="sustainabilityPage--milkyield--question"
              handleChangeFn={(newAnnualMilkProductionInLiters) => {
                let modifiedSustainabilityDetails = _.cloneDeep(
                  newSustainabilityDetails,
                );
                modifiedSustainabilityDetails.sustainabilityData.annualMilkProductionInLiters =
                  newAnnualMilkProductionInLiters;
                setNewSustainabilityDetails(modifiedSustainabilityDetails);
                setSaved(false);
              }}
              optional={true}
            />
            <div className="sustainability--carbonDioxide">
              <div className="sustainabilityPage--milkyield--question">
                {translate("SUSTAINABILITY_SUBJECTS_QUESTION")}
              </div>
              <CheckboxGroupSelection
                optionsArray={[
                  {
                    label: translate("SUSTAINABILITY_CARBON_DIOXIDE"),
                    value: true,
                  },
                ]}
                selectedOptions={[
                  newSustainabilityDetails?.sustainabilityData?.hasCarbonDioxideMeasures,
                ]}
                onSelectionChange={(newSelectedValue, isChecked) => {
                  let newCarbonDioxideMeasures = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  isChecked
                    ? (newCarbonDioxideMeasures.sustainabilityData.hasCarbonDioxideMeasures = true)
                    : (newCarbonDioxideMeasures.sustainabilityData.hasCarbonDioxideMeasures = false);
                  setNewSustainabilityDetails(newCarbonDioxideMeasures);
                  setSaved(false);
                }}
              />
            </div>
            {newSustainabilityDetails?.sustainabilityData?.hasCarbonDioxideMeasures ? <>
              <LabelledTextInput
                initialValue={
                  newSustainabilityDetails?.sustainabilityData?.carbonDioxideKilogramsPerLiterOfMilk
                }
                inputType={"number"}
                labelText={translate("SUSTAINABILITY_CARBON_DIOXIDE_QUESTION")}
                placeholderText={translate(
                  "SUSTAINABILITY_CARBON_DIOXIDE_PLACEHOLDER",
                )}
                additionalClassName="sustainability--carbonDioxideQuestion"
                handleChangeFn={(newCarbonDioxideMeasures) => {
                  let modifiedSustainabilityDetails = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  modifiedSustainabilityDetails.sustainabilityData.carbonDioxideKilogramsPerLiterOfMilk =
                    newCarbonDioxideMeasures;
                  setNewSustainabilityDetails(modifiedSustainabilityDetails);
                  setSaved(false);
                }}
                optional={true}
              />
              <MultiLanguageTextArea
                multiLanguageData={newSustainabilityDetails?.sustainabilityData?.carbonDioxideStory}
                givenLength={250}
                fieldLabelTranslationKey={"SUSTAINABILITY_FERTILIZER_USAGE"}
                fieldPlaceholderTextTranslationKey={""}
                handleDataChangedFn={(newCarbonDioxideStoryMultiLanguageData) =>
                  modifySustainabilityDetails(
                    "carbonDioxideStory",
                    newCarbonDioxideStoryMultiLanguageData,
                  )
                }
                languageKeysArray={farmLanguages}
                additionalCssClassname="sustainabilityCarbonDioxide--textArea"
              />
            </> : ""}
            <div className="sustainability--concentrates">
              <CheckboxGroupSelection
                optionsArray={[
                  {
                    label: translate("SUSTAINABILITY_CONCENTRATES_TITLE"),
                    value: true,
                  },
                ]}
                selectedOptions={[
                  newSustainabilityDetails?.sustainabilityData?.hasConcentratesData,
                ]}
                onSelectionChange={(newSelectedValue, isChecked) => {
                  let newConcentratesData = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  isChecked
                    ? (newConcentratesData.sustainabilityData.hasConcentratesData = true)
                    : (newConcentratesData.sustainabilityData.hasConcentratesData = false);
                  setNewSustainabilityDetails(newConcentratesData);
                  setSaved(false);
                }}
              />
            </div>
            {newSustainabilityDetails?.sustainabilityData?.hasConcentratesData ? <>
              <LabelledTextInput
                initialValue={
                  newSustainabilityDetails?.sustainabilityData?.gramsConcentratesYearlyBasis
                }
                inputType={"number"}
                labelText={translate("SUSTAINABILITY_CONCENTRATES_QUESTION")}
                placeholderText={translate(
                  "SUSTAINABILITY_FERTILIZER_USAGE_PLACEHOLDER",
                )}
                additionalClassName="sustainability--concentratesQuestion"
                handleChangeFn={(newGramsConcentratesYearlyBasis) => {
                  let modifiedSustainabilityDetails = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  modifiedSustainabilityDetails.gramsConcentratesYearlyBasis =
                    newGramsConcentratesYearlyBasis;
                  setNewSustainabilityDetails(modifiedSustainabilityDetails);
                  setSaved(false);
                }}
                optional={true}
              />
              <MultiLanguageTextArea
                multiLanguageData={newSustainabilityDetails?.sustainabilityData?.concentratesStory}
                fieldLabelTranslationKey={"SUSTAINABILITY_CONCENTRATES_TEXT"}
                givenLength={250}
                fieldPlaceholderTextTranslationKey={""}
                handleDataChangedFn={(newCarbonDioxideStoryMultiLanguageData) =>
                  modifySustainabilityDetails(
                    "concentratesStory",
                    newCarbonDioxideStoryMultiLanguageData,
                  )
                }
                languageKeysArray={farmLanguages}
                additionalCssClassname="sustainabilityConcentrates--textArea"
              />
            </> : ""}
            <div className="sustainability--carbonDioxide">
              <CheckboxGroupSelection
                optionsArray={[
                  {
                    label: translate("FERTILIZER_TITLE"),
                    value: true,
                  },
                ]}
                selectedOptions={[newSustainabilityDetails?.sustainabilityData?.hasFertilizerData]}
                onSelectionChange={(newSelectedValue, isChecked) => {
                  let newFertilerData = _.cloneDeep(newSustainabilityDetails);
                  isChecked
                    ? (newFertilerData.sustainabilityData.hasFertilizerData = true)
                    : (newFertilerData.sustainabilityData.hasFertilizerData = false);
                  setNewSustainabilityDetails(newFertilerData);
                  setSaved(false);
                }}
              />
            </div>
            {newSustainabilityDetails?.sustainabilityData?.hasFertilizerData ? <>
              <LabelledTextInput
                initialValue={
                  newSustainabilityDetails?.sustainabilityData?.gramsFertilizerYearlyBasis
                }
                inputType={"number"}
                labelText={translate("FERTILIZER_QUESTION")}
                placeholderText={translate(
                  "SUSTAINABILITY_FERTILIZER_USAGE_PLACEHOLDER",
                )}
                additionalClassName="sustainability--fertilizerQuestion"
                handleChangeFn={(newGramsFertilizerYearlyBasis) => {
                  let modifiedSustainabilityDetails = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  modifiedSustainabilityDetails.sustainabilityData.gramsFertilizerYearlyBasis =
                    newGramsFertilizerYearlyBasis;
                  setNewSustainabilityDetails(modifiedSustainabilityDetails);
                  setSaved(false);
                }}
                optional={true}
              />
              <MultiLanguageTextArea
                multiLanguageData={newSustainabilityDetails?.sustainabilityData?.fertilizersStory}
                fieldLabelTranslationKey={"FERTILIZER_INFO"}
                givenLength={250}
                fieldPlaceholderTextTranslationKey={""}
                handleDataChangedFn={(newCarbonDioxideStoryMultiLanguageData) =>
                  modifySustainabilityDetails(
                    "fertilizersStory",
                    newCarbonDioxideStoryMultiLanguageData,
                  )
                }
                languageKeysArray={farmLanguages}
                additionalCssClassname="sustainabilityFertilizer--textArea"
              />
            </> : ""}
            <div className="sustainability--carbonDioxide">
              <CheckboxGroupSelection
                optionsArray={[
                  {
                    label: translate("RENEWABLE_ENERGY_TITLE"),
                    value: true,
                  },
                ]}
                selectedOptions={[newSustainabilityDetails?.sustainabilityData?.hasRenewableEnergy]}
                onSelectionChange={(newSelectedValue, isChecked) => {
                  let newRenewableEnergy = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  isChecked
                    ? (newRenewableEnergy.sustainabilityData.hasRenewableEnergy = true)
                    : (newRenewableEnergy.sustainabilityData.hasRenewableEnergy = false);
                  setNewSustainabilityDetails(newRenewableEnergy);
                  setSaved(false);
                }}
              />
            </div>
            {newSustainabilityDetails?.sustainabilityData?.hasRenewableEnergy ? <>
              <LabelledTextInput
                initialValue={
                  newSustainabilityDetails?.sustainabilityData?.renewableEnergyPercentageInFarm
                }
                inputType={"number"}
                labelText={translate("RENEWABLE_ENERGY_QUESTION")}
                placeholderText={translate(
                  "SUSTAINABILITY_PERCETANGE_PLACEHOLDER",
                )}
                additionalClassName="sustainability--renewableEnergyQuestion"
                handleChangeFn={(newRenewableEnergyPercentageInFarm) => {
                  let modifiedSustainabilityDetails = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  modifiedSustainabilityDetails.sustainabilityData.renewableEnergyPercentageInFarm =
                    newRenewableEnergyPercentageInFarm;
                  setNewSustainabilityDetails(modifiedSustainabilityDetails);
                  setSaved(false);
                }}
                optional={true}
              />
              <MultiLanguageTextArea
                multiLanguageData={newSustainabilityDetails?.sustainabilityData?.renewableEnergyStory}
                fieldLabelTranslationKey={"RENEWABLE_ENERGY_INFO"}
                givenLength={250}
                fieldPlaceholderTextTranslationKey={""}
                handleDataChangedFn={(newCarbonDioxideStoryMultiLanguageData) =>
                  modifySustainabilityDetails(
                    "renewableEnergyStory",
                    newCarbonDioxideStoryMultiLanguageData,
                  )
                }
                languageKeysArray={farmLanguages}
                additionalCssClassname="sustainabilityRenewableEnergy--textArea"
              />
            </> : ""}
            <div className="sustainability--carbonDioxide">
              <CheckboxGroupSelection
                optionsArray={[
                  {
                    label: translate("ROUGHAGE_PRODUCTION_TITLE"),
                    value: true,
                  },
                ]}
                selectedOptions={[
                  newSustainabilityDetails?.sustainabilityData?.hasRoughageProduction,
                ]}
                onSelectionChange={(newSelectedValue, isChecked) => {
                  let newRoughageProduction = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  isChecked
                    ? (newRoughageProduction.sustainabilityData.hasRoughageProduction = true)
                    : (newRoughageProduction.sustainabilityData.hasRoughageProduction = false);
                  setNewSustainabilityDetails(newRoughageProduction);
                  setSaved(false);
                }}
              />
            </div>
            {newSustainabilityDetails?.sustainabilityData?.hasRoughageProduction ? <>
              <LabelledTextInput
                initialValue={
                  newSustainabilityDetails?.sustainabilityData?.roughagePercentageProducedInFarm
                }
                inputType={"number"}
                labelText={translate("ROUGHAGE_PRODUCTION_QUESTION")}
                placeholderText={translate(
                  "SUSTAINABILITY_PERCETANGE_PLACEHOLDER",
                )}
                additionalClassName="sustainability--roughageProductionQuestion"
                handleChangeFn={(newRoughagePercentageProducedInFarm) => {
                  let modifiedSustainabilityDetails = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  modifiedSustainabilityDetails.sustainabilityData.roughagePercentageProducedInFarm =
                    newRoughagePercentageProducedInFarm;
                  setNewSustainabilityDetails(modifiedSustainabilityDetails);
                  setSaved(false);
                }}
                optional={true}
              />
              <MultiLanguageTextArea
                multiLanguageData={
                  newSustainabilityDetails?.sustainabilityData?.roughageProductionStory
                }
                fieldLabelTranslationKey={"ROUGHAGE_PRODUCTION_INFO"}
                givenLength={250}
                fieldPlaceholderTextTranslationKey={""}
                handleDataChangedFn={(newCarbonDioxideStoryMultiLanguageData) =>
                  modifySustainabilityDetails(
                    "roughageProductionStory",
                    newCarbonDioxideStoryMultiLanguageData,
                  )
                }
                languageKeysArray={farmLanguages}
                additionalCssClassname="sustainabilityRoughageProduction--textArea"
              />
            </> : ""}
            {" "}
          </>
        ) : (
          ""
        )}
      </div>
      <div className="adminPanel--animalWellfare">
        <h3 className="adminPanel--sustainability--header">
          {translate("ANIMAL_WELFARE")}
        </h3>
        <p className="adminPanel--sustainability--subheading">
          {translate("ANIMAL_WELFARE_SUBHEADING")}
        </p>
        <p className="adminPanel--sustainability--subheading">
          {translate("ANIMAL_WELFARE_SUBHEADING_QUESTION")}
        </p>
        <ImageRadioSelection
          optionsArray={[
            {
              label: translate("YES"),
              value: "1",
              classNameAppendix: "inline",
            },
            {
              label: translate("NO"),
              value: "0",
              classNameAppendix: "inline",
            },
          ]}
          initialSelectedValue={
            newSustainabilityDetails?.animalWelfare?.hasAnimalWelfareData ? "1" : "0"
          }
          onSelectionChangeFn={(newSelectedValue) => {
            setNewSustainabilityDetails({
              ...newSustainabilityDetails,
              animalWelfare: {
                ...newSustainabilityDetails.animalWelfare,
                hasAnimalWelfareData: newSelectedValue == 1 ? true : false,
              }
            });
            setSaved(false);
            setHasInformationAboutSustainability(newSelectedValue === "1");
          }}
        />
        {newSustainabilityDetails?.animalWelfare?.hasAnimalWelfareData ? (
          <>
            <div className="sustainability--cowAndCalf">
              <CheckboxGroupSelection
                optionsArray={[
                  {
                    label: translate("SUSTAINABILITY_COW_CALF_TITLE"),
                    value: true,
                  },
                ]}
                selectedOptions={[
                  newSustainabilityDetails?.animalWelfare?.hasCowAndCalfData,
                ]}
                onSelectionChange={(newSelectedValue, isChecked) => {
                  let newConcentratesData = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  isChecked
                    ? (newConcentratesData.animalWelfare.hasCowAndCalfData = true)
                    : (newConcentratesData.animalWelfare.hasCowAndCalfData = false);
                  setNewSustainabilityDetails(newConcentratesData);
                  setSaved(false);
                }}
              />
            </div>
            {newSustainabilityDetails?.animalWelfare?.hasCowAndCalfData ? <>
              <MultiLanguageTextArea
                multiLanguageData={newSustainabilityDetails?.animalWelfare?.cowAndCalfStory}
                fieldLabelTranslationKey={"SUSTAINABILITY_COW_CALF_TITLE_MULTITEXT_TITLE"}
                givenLength={250}
                fieldPlaceholderTextTranslationKey={""}
                handleDataChangedFn={(newCarbonDioxideStoryMultiLanguageData) =>
                  modifyAnimalWelfareDetails(
                    "cowAndCalfStory",
                    newCarbonDioxideStoryMultiLanguageData,
                  )
                }
                languageKeysArray={farmLanguages}
                additionalCssClassname="animalWelfareCowAndCalf--textArea"
              />
              <LabelledImageDropZone
                labelText={translate("ABOUT_YOU_YOUR_STORY_BLOCK_PHOTO_TEXT")}
                dropzoneText={translate(
                  "ABOUT_YOU_PAGE_CHANGE_PROFILE_PICTURE_DROP_ZONE_LABEL",
                )}
                initialValue={
                  newSustainabilityDetails.animalWelfare &&
                  newSustainabilityDetails.animalWelfare.cowAndCalfImageData
                }
                key={
                  newSustainabilityDetails.animalWelfare &&
                  newSustainabilityDetails.animalWelfare.cowAndCalfImageData
                }
                onChangeFileFn={(newFileBase64) => {
                  setNewSustainabilityDetails({
                    ...newSustainabilityDetails,
                    animalWelfare: {
                      ...newSustainabilityDetails.animalWelfare,
                      cowAndCalfImageData: newFileBase64,
                    },
                  });
                  setSaved(false);
                }}
                onDeleteFileFn={() => {
                  setNewSustainabilityDetails({
                    ...newSustainabilityDetails,
                    animalWelfare: {
                      ...newSustainabilityDetails.animalWelfare,
                      cowAndCalfImageData: "",
                    },
                  });
                  setSaved(false);
                }}
                defaultPreviewCssClassname="storyImagePreview"
              />
            </> : ""}
            <div className="sustainability--ageBreedInfo">
              <CheckboxGroupSelection
                optionsArray={[
                  {
                    label: translate("SUSTAINABILITY_AGE_BREED_INFO_TITLE"),
                    value: true,
                  },
                ]}
                selectedOptions={[
                  newSustainabilityDetails?.animalWelfare?.hasHerdBreedAndAgeData,
                ]}
                onSelectionChange={(newSelectedValue, isChecked) => {
                  let newConcentratesData = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  isChecked
                    ? (newConcentratesData.animalWelfare.hasHerdBreedAndAgeData = true)
                    : (newConcentratesData.animalWelfare.hasHerdBreedAndAgeData = false);
                  setNewSustainabilityDetails(newConcentratesData);
                  setSaved(false);
                }}
              />
            </div>
            {newSustainabilityDetails?.animalWelfare?.hasHerdBreedAndAgeData ? <>
              <MultiLanguageTextArea
                multiLanguageData={newSustainabilityDetails?.animalWelfare?.herdBreedAndAgeStory}
                fieldLabelTranslationKey={"SUSTAINABILITY_AGE_BREED_INFO_MULTITEXT_TITLE"}
                givenLength={250}
                fieldPlaceholderTextTranslationKey={""}
                handleDataChangedFn={(newCarbonDioxideStoryMultiLanguageData) =>
                  modifyAnimalWelfareDetails(
                    "herdBreedAndAgeStory",
                    newCarbonDioxideStoryMultiLanguageData,
                  )
                }
                languageKeysArray={farmLanguages}
                additionalCssClassname="animalWelfareHerdAndBreed--textArea"
              />
              <LabelledImageDropZone
                labelText={translate("ABOUT_YOU_YOUR_STORY_BLOCK_PHOTO_TEXT")}
                dropzoneText={translate(
                  "ABOUT_YOU_PAGE_CHANGE_PROFILE_PICTURE_DROP_ZONE_LABEL",
                )}
                initialValue={
                  newSustainabilityDetails.animalWelfare &&
                  newSustainabilityDetails.animalWelfare.herdBreedAndAgeImageData
                }
                key={
                  newSustainabilityDetails.animalWelfare &&
                  newSustainabilityDetails.animalWelfare.herdBreedAndAgeImageData
                }
                onChangeFileFn={(newFileBase64) => {
                  setNewSustainabilityDetails({
                    ...newSustainabilityDetails,
                    animalWelfare: {
                      ...newSustainabilityDetails.animalWelfare,
                      herdBreedAndAgeImageData: newFileBase64,
                    },
                  });
                  setSaved(false);
                }}
                onDeleteFileFn={() => {
                  setNewSustainabilityDetails({
                    ...newSustainabilityDetails,
                    animalWelfare: {
                      ...newSustainabilityDetails.animalWelfare,
                      herdBreedAndAgeImageData: "",
                    },
                  });
                  setSaved(false);
                }}
                defaultPreviewCssClassname="storyImagePreview"
              />
            </> : ""}
            <div className="sustainability--grazing">
              <CheckboxGroupSelection
                optionsArray={[
                  {
                    label: translate("SUSTAINABILITY_GRAZING_TITLE"),
                    value: true,
                  },
                ]}
                selectedOptions={[
                  newSustainabilityDetails?.animalWelfare?.hasGrazingData,
                ]}
                onSelectionChange={(newSelectedValue, isChecked) => {
                  let newConcentratesData = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  isChecked
                    ? (newConcentratesData.animalWelfare.hasGrazingData = true)
                    : (newConcentratesData.animalWelfare.hasGrazingData = false);
                  setNewSustainabilityDetails(newConcentratesData);
                  setSaved(false);
                }}
              />
            </div>
            {newSustainabilityDetails?.animalWelfare?.hasGrazingData ? <>
              <LabelledTextInput
                initialValue={
                  newSustainabilityDetails?.animalWelfare?.grazingDays
                }
                inputType={"number"}
                labelText={translate("SUSTAINABILITY_GRAZING_SUBHEADING_TITLE")}
                placeholderText={translate(
                  "SUSTAINABILITY_GRAZING_PLACEHOLDER",
                )}
                additionalClassName="animalWelfare--grazingQuestion"
                handleChangeFn={(newGramsConcentratesYearlyBasis) => {
                  let modifiedSustainabilityDetails = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  modifiedSustainabilityDetails.animalWelfare.grazingDays =
                    newGramsConcentratesYearlyBasis;
                  setNewSustainabilityDetails(modifiedSustainabilityDetails);
                  setSaved(false);
                }}
                optional={true}
              />
              <MultiLanguageTextArea
                multiLanguageData={newSustainabilityDetails?.animalWelfare?.grazingStory}
                fieldLabelTranslationKey={"SUSTAINABILITY_GRAZING_MULTITEXT_TITLE"}
                givenLength={250}
                fieldPlaceholderTextTranslationKey={""}
                handleDataChangedFn={(newCarbonDioxideStoryMultiLanguageData) =>
                  modifyAnimalWelfareDetails(
                    "grazingStory",
                    newCarbonDioxideStoryMultiLanguageData,
                  )
                }
                languageKeysArray={farmLanguages}
                additionalCssClassname="animalWelfareGrazing--textArea"
              />
            </> : ""}
            <div className="sustainability--livingEnvironment">
              <CheckboxGroupSelection
                optionsArray={[
                  {
                    label: translate("SUSTAINABILITY_LIVING_ENVIRONMENT_TITLE"),
                    value: true,
                  },
                ]}
                selectedOptions={[
                  newSustainabilityDetails?.animalWelfare?.hasLivingEnvironmentData,
                ]}
                onSelectionChange={(newSelectedValue, isChecked) => {
                  let newConcentratesData = _.cloneDeep(
                    newSustainabilityDetails,
                  );
                  isChecked
                    ? (newConcentratesData.animalWelfare.hasLivingEnvironmentData = true)
                    : (newConcentratesData.animalWelfare.hasLivingEnvironmentData = false);
                  setNewSustainabilityDetails(newConcentratesData);
                  setSaved(false);
                }}
              />
            </div>
            {newSustainabilityDetails?.animalWelfare?.hasLivingEnvironmentData ? <>
              <MultiLanguageTextArea
                multiLanguageData={newSustainabilityDetails?.animalWelfare?.livingEnvironmentStory}
                fieldLabelTranslationKey={"SUSTAINABILITY_LIVING_ENVIRONMENT_MULTITEXT_TITLE"}
                givenLength={250}
                fieldPlaceholderTextTranslationKey={""}
                handleDataChangedFn={(newCarbonDioxideStoryMultiLanguageData) =>
                  modifyAnimalWelfareDetails(
                    "livingEnvironmentStory",
                    newCarbonDioxideStoryMultiLanguageData,
                  )
                }
                languageKeysArray={farmLanguages}
                additionalCssClassname="animalWelfareLivingEnvironment--textArea"
              />
              <LabelledImageDropZone
                labelText={translate("ABOUT_YOU_YOUR_STORY_BLOCK_PHOTO_TEXT")}
                dropzoneText={translate(
                  "ABOUT_YOU_PAGE_CHANGE_PROFILE_PICTURE_DROP_ZONE_LABEL",
                )}
                initialValue={
                  newSustainabilityDetails.animalWelfare &&
                  newSustainabilityDetails.animalWelfare.livingEnvironmentImageData
                }
                key={
                  newSustainabilityDetails.animalWelfare &&
                  newSustainabilityDetails.animalWelfare.livingEnvironmentImageData
                }
                onChangeFileFn={(newFileBase64) => {
                  setNewSustainabilityDetails({
                    ...newSustainabilityDetails,
                    animalWelfare: {
                      ...newSustainabilityDetails.animalWelfare,
                      livingEnvironmentImageData: newFileBase64,
                    },
                  });
                  setSaved(false);
                }}
                onDeleteFileFn={() => {
                  setNewSustainabilityDetails({
                    ...newSustainabilityDetails,
                    animalWelfare: {
                      ...newSustainabilityDetails.animalWelfare,
                      livingEnvironmentImageData: "",
                    },
                  });
                  setSaved(false);
                }}
                defaultPreviewCssClassname="storyImagePreview"
              />
            </> : ""}
          </>) : ''}
      </div>
      {isSaved && lastSaveTimestamp !== 0 ? (
        <Button
          className="btn-already-saved"
          disabled={false}
          onClick={() => { }}
        >
          {translate("ADMIN_PAGE_ALREADY_SAVED_CHANGES_BUTTON_LABEL")}
        </Button>
      ) : null}
      {!isSaved ? (
        <Button
          className="btn-save-changes"
          style={{ marginBottom: "5vh" }}
          disabled={false}
          onClick={handleSaveChangesButtonClick}
        >
          {translate("ABOUT_YOU_PAGE_SAVE_CHANGES_BUTTON_LABEL")}
        </Button>
      ) : null}
      {isSaveChangesConfirmationPopupDisplayed ? (
        <SaveChangesConfirmationDialog />
      ) : null}
    </div>

  );
}


export default SustainabilityPage;
