import React from "react";
import "./FourthSlideJuno.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const FourthSlideJuno = ({}) => {
  return (
    <div className="junoStoryFourthSlide">
      <TextCaption additionalCssClassname="junoStoryFourthSlide--textCaption">
        <div className="junoStoryFourthSlide--title">
          {translate("JUNO_FOURTH_SLIDE_TITLE")}
        </div>
        <div className="junoStoryFourthSlide--text">
          {translate("JUNO_FOURTH_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="junoStoryFourthSlide--imgCaption">
        <div className="junoStoryFourthSlide--imgContainer"></div>
      </div> 
      <div className="junoStoryFourthSlide--treeForAdultVersion"></div>
    </div>
  );
};

export default FourthSlideJuno;
