import React from 'react'
import SpacingWrapper from '../../common/components/SpacingWrapper/SpacingWrapper.jsx';

function ForbiddenPage() {
    return (
        <SpacingWrapper className="next-to-sidebar">
            <h1>Тhe information you are trying to access is not accessible for you</h1>
        </SpacingWrapper>
    );
}

export default ForbiddenPage;