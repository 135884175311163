import _ from "lodash";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import {
  translate,
  getCurrentLanguage,
} from "../../../localization/TranslationUtil";
import LabelledTextInput from "../../../common/components/LabelledTextInput/LabelledTextInput";
import ImageRadioSelection from "../../../common/components/ImageRadioSelection/ImageRadioSelection";
import LabelledImageDropZone from "../../../common/components/LabelledImageDropZone/LabelledImageDropZone";
import SelectLabels from "../../../common/components/DropdownMenu/DropdownMenu";
import CheckboxGroupSelection from "../../../common/components/CheckboxGroupSelection/CheckboxGroupSelection";
import { DEFAULT_THEME_OPTIONS } from "../../../themes/themes";
import { ReactComponent as Shop } from "../../../resources/assets/themes/lely/16x9/actors/shop/shop.svg";
import { ReactComponent as Info } from "../../../resources/assets/themes/lely/16x9/ui/icons/info-icon.svg";
import { ReactComponent as Close } from "../../../resources/assets/themes/lely/16x9/ui/icons/close-icon.svg";
import {
  saveChangesToAboutTheFarmPage,
  requestAboutTheFarmPageInformation,
} from "./actions";
import "./AboutTheFarmPage.scss";
import WorkingHoursEditor from "../../../components/WorkingHoursEditor/WorkingHoursEditor";
import { DEFAULT_LANGUAGE_OPTIONS } from "../../../common/constants";
import { convertLanguageSelectionToBE } from "../../../common/components/LanguageSelection/LanguageUtil";
import { requestSelectedFarmLanguagesInformation } from "../../../common/components/LanguageSelection/actions";
import LanguageSelection from "../../../common/components/LanguageSelection/LanguageSelection";
import MultiLanguageTextInput from "../../../common/components/MultiLanguageTextInput/MultiLanguageTextInput";
import MultiLanguageTextArea from "../../../common/components/MultiLanguageTextArea/MultiLanguageTextArea";
import useSaveManager from "../../../hooks/useSaveManager";
import SaveChangesConfirmationDialog from "../../../components/SaveChangesConfirmationDialog/SaveChangesConfirmationDialog";

function AboutTheFarmPage() {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => ({ ...state.userSession }));
  const aboutTheFarmDetails = useSelector((state) => ({
    ...state.aboutTheFarmDetails,
  }));
  const { isSaveChangesConfirmationPopupDisplayed } = useSelector((state) => ({
    ...state.saveChangesDetails,
  }));
  const [newFarmDetails, setNewFarmDetails] = useState(
    aboutTheFarmDetails.aboutTheFarmDetails,
  );
  const handleSaveChangesButtonClick = () => {
    let newLanguageDetails = convertLanguageSelectionToBE(
      farmLanguages,
      DEFAULT_LANGUAGE_OPTIONS,
    );
    dispatch(
      saveChangesToAboutTheFarmPage(
        accessToken,
        newFarmDetails,
        newLanguageDetails,
      ),
    );
    setSaved(true);
  };
  const [isSaved, setIsSaved] = useState(true);
  const { lastSaveTimestamp, setSaved } = useSaveManager(
    handleSaveChangesButtonClick,
    isSaved,
    setIsSaved,
  );

  const [isThereWorkingTimeError, setIsThereWorkingTimeError] = useState(false);
  const [hasShop, setHasShop] = useState(
    newFarmDetails.farm &&
      newFarmDetails.farm.shop &&
      newFarmDetails.farm.shop.flag,
  );
  const [hasSolarPanels, setHasSolarPanels] = useState(
    newFarmDetails.farm &&
      newFarmDetails.farm.solarPanel &&
      newFarmDetails.farm.solarPanel.flag,
  );
  const [showGoogleMapsPopup, setShowGoogleMapsPopup] = useState(false);
  const workingTimes = useRef(
    newFarmDetails.farm &&
      newFarmDetails.farm.shop &&
      newFarmDetails.farm.shop.workingTimes,
  );
  const shopAddress = useRef(
    newFarmDetails.farm &&
      newFarmDetails.farm.shop &&
      newFarmDetails.farm.shop.address,
  );
  //#region farm languages logic
  const { farmLanguagesDetails } = useSelector((state) => ({
    ...state.selectedFarmLanguages,
  }));
  let languageKeysForAllLanguagesAvailable = DEFAULT_LANGUAGE_OPTIONS.filter(
    (currentLanguageOption) => currentLanguageOption.code.length === 2,
  ).map((currentLanguageOption) => currentLanguageOption.code);

  function initializeLanguageSelection(languagesDataFromBE) {
    let selectedLanguageObjects =
      languagesDataFromBE && languagesDataFromBE.languages
        ? languagesDataFromBE.languages
        : DEFAULT_LANGUAGE_OPTIONS;
    let initialLanguageSelection = selectedLanguageObjects
      .filter(
        (currentLanguageOption) => currentLanguageOption.code.length === 2,
      )
      .map((currentLanguageOption) => currentLanguageOption.code);
    return initialLanguageSelection;
  }

  let initialLanguageSelection =
    initializeLanguageSelection(farmLanguagesDetails);
  const [farmLanguages, setFarmLanguages] = useState(initialLanguageSelection);

  useEffect(() => {
    let initialLanguageSelection =
      initializeLanguageSelection(farmLanguagesDetails);
    setFarmLanguages(initialLanguageSelection);
  }, [farmLanguagesDetails.languages]);

  const getInitialLanguage = () => {
    if (aboutTheFarmDetails.aboutTheFarmDetails.languages) {
      const supportedLanguages =
        aboutTheFarmDetails.aboutTheFarmDetails.languages.map(
          (lang) => lang.code,
        );
      const defLanguage =
        supportedLanguages.indexOf("en") > -1 ? "en" : supportedLanguages[0];
      const currentLanguage = getCurrentLanguage(
        defLanguage,
        supportedLanguages,
      );
      return currentLanguage;
    }
    return "";
  };

  const [currentLanguage, setCurrentLanguage] = useState(getInitialLanguage());
  //#endregion

  useEffect(() => {
    dispatch(requestAboutTheFarmPageInformation(accessToken));
    dispatch(requestSelectedFarmLanguagesInformation(accessToken));
  }, []);

  useEffect(() => {
    setNewFarmDetails(aboutTheFarmDetails.aboutTheFarmDetails);
    aboutTheFarmDetails.aboutTheFarmDetails.farm &&
      aboutTheFarmDetails.aboutTheFarmDetails.farm.shop &&
      aboutTheFarmDetails.aboutTheFarmDetails.farm.shop.flag &&
      setHasShop(true);
    aboutTheFarmDetails.aboutTheFarmDetails.farm &&
      aboutTheFarmDetails.aboutTheFarmDetails.farm.solarPanel &&
      aboutTheFarmDetails.aboutTheFarmDetails.farm.solarPanel.flag &&
      setHasSolarPanels(true);
    setCurrentLanguage(getInitialLanguage());
  }, [aboutTheFarmDetails.aboutTheFarmDetails]);

  const renderGoogleMapsLinkPopup = () => {
    return (
      <div className="about-the-farm--google-maps-popup">
        <div className="about-the-farm--google-maps-popup--header">
          <div className="about-the-farm--google-maps-popup--title">
            {translate("ABOUT_THE_FARM_SHOP_ADDRESS_GOOGLE_LINK_TITLE")}
          </div>
          <div
            className="about-the-farm--google-maps-popup--close"
            onClick={() => setShowGoogleMapsPopup(false)}
          >
            <Close />
          </div>
        </div>
        <div className="about-the-farm--google-maps-popup--content">
          {translate("ABOUT_THE_FARM_SHOP_ADDRESS_GOOGLE_LINK_CONTENT")}
        </div>
        <div className="about-the-farm--google-maps-popup--video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/QFyspWxvYoU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </div>
    );
  };  


  if (newFarmDetails) {
    return (
      <div className="AboutTheFarmPage">
        <h3 className="about-the-farm--header">
          {translate("ABOUT_THE_FARM_PAGE_HEADING")}
        </h3>
        <p className="about-the-farm--intro">
          {translate("ABOUT_THE_FARM_PAGE_INTRO_TEXT")}
        </p>

        <LanguageSelection
          handleLanguagesChangedFn={(
            newLanguageCodesSelected,
            isManualChange,
          ) => {
            setFarmLanguages(newLanguageCodesSelected);
            if (isManualChange) {
              setSaved(false);
            }
          }}
          allAvailableLanguageCodesArray={languageKeysForAllLanguagesAvailable}
          selectedLanguageCodesArray={farmLanguages}
        />

        <div className="farm-features-block">
          <SelectLabels
            labelText={translate("FARM_FEATURES_THEME_LABEL")}
            initialValue={
              !_.isEmpty(
                newFarmDetails &&
                  newFarmDetails.farm &&
                  newFarmDetails.farm.farmTheme,
              )
                ? newFarmDetails.farm.farmTheme
                : DEFAULT_THEME_OPTIONS[0].code
            }
            options={DEFAULT_THEME_OPTIONS}
            additionalClassName="farm-features--theme"
            handleChangeFn={(newFarmTheme) => {
              let newFarmDetailsCopy = _.cloneDeep(newFarmDetails);
              newFarmDetailsCopy.farm.farmTheme = newFarmTheme;
              setNewFarmDetails(newFarmDetailsCopy);
              setSaved(false);
            }}
          />
          <MultiLanguageTextInput
            multiLanguageData={
              newFarmDetails && newFarmDetails.farm && newFarmDetails.farm.name
            }
            fieldLabelTranslationKey={"ABOUT_THE_FARM_PAGE_FARM_NAME_LABEL"}
            fieldPlaceholderTextTranslationKey={""}
            handleDataChangedFn={(newFarmNameMultiLanguageData) => {
              let modifiedFarmDetailsCopy = _.cloneDeep(newFarmDetails);
              if (modifiedFarmDetailsCopy && modifiedFarmDetailsCopy.farm) {
                modifiedFarmDetailsCopy.farm.name =
                  newFarmNameMultiLanguageData;
                setNewFarmDetails(modifiedFarmDetailsCopy);
                setSaved(false);
              }
            }}
            languageKeysArray={farmLanguages}
            additionalCssClassname="multiLanguageTextInput--farmName--textInput"
          />

          <ImageRadioSelection
            componentLabel={translate("ABOUT_THE_FARM_PAGE_FARM_STYLE")}
            optionsArray={[
              {
                label: "style1",
                classNameAppendix: "farmstyle1",
                value: "style1",
              },
              {
                label: "style2",
                classNameAppendix: "farmstyle2",
                value: "style2",
              },
              {
                label: "style3",
                classNameAppendix: "farmstyle3",
                value: "style3",
              },
            ]}
            initialSelectedValue={
              newFarmDetails &&
              newFarmDetails.farm &&
              newFarmDetails.farm.style &&
              newFarmDetails.farm.style.toLowerCase()
            }
            onSelectionChangeFn={(newSelectedValue) => {
              let modifiedFarmDetailsCopy = _.cloneDeep(newFarmDetails);
              if (modifiedFarmDetailsCopy.farm.style) {
                modifiedFarmDetailsCopy.farm.style =
                  newSelectedValue.toLowerCase();
                modifiedFarmDetailsCopy.farm.style = newSelectedValue;
                setNewFarmDetails(modifiedFarmDetailsCopy);
              }
              setSaved(false);
            }}
          />
          <ImageRadioSelection
            componentLabel={translate(
              "ABOUT_THE_FARM_PAGE_MAIN_PRODUCTION_LABEL",
            )}
            optionsArray={[
              {
                label: translate(
                  "ABOUT_THE_FARM_PAGE_PRODUCTION_VALUE_1_LABEL",
                ),
                classNameAppendix: "farmProduction1",
                value: "orbiter",
              },
              {
                label: translate(
                  "ABOUT_THE_FARM_PAGE_PRODUCTION_VALUE_2_LABEL",
                ),
                classNameAppendix: "farmProduction2",
                value: "dairy",
              },
              {
                label: translate(
                  "ABOUT_THE_FARM_PAGE_PRODUCTION_VALUE_3_LABEL",
                ),
                classNameAppendix: "farmProduction3",
                value: "cheese",
              },
              {
                label: translate(
                  "ABOUT_THE_FARM_PAGE_PRODUCTION_VALUE_4_LABEL",
                ),
                classNameAppendix: "farmProduction4",
                value: "icecream",
              },
            ]}
            initialSelectedValue={
              newFarmDetails &&
              newFarmDetails.farm &&
              newFarmDetails.farm.producingDevice &&
              newFarmDetails.farm.producingDevice.name &&
              newFarmDetails.farm.producingDevice.name.toLowerCase()
            }
            onSelectionChangeFn={(newSelectedValue) => {
              let modifiedFarmDetailsCopy = _.cloneDeep(newFarmDetails);
              if (modifiedFarmDetailsCopy.farm.producingDevice) {
                modifiedFarmDetailsCopy.farm.producingDevice.type =
                  newSelectedValue.toLowerCase();
                modifiedFarmDetailsCopy.farm.producingDevice.name =
                  newSelectedValue;
                setNewFarmDetails(modifiedFarmDetailsCopy);
              }
              setSaved(false);
            }}
          />
          <MultiLanguageTextArea
            multiLanguageData={
              newFarmDetails &&
              newFarmDetails.farm &&
              newFarmDetails.farm.description
            }
            fieldLabelTranslationKey={"ABOUT_THE_FARM_PAGE_FARM_HISTORY_LABEL"}
            fieldPlaceholderTextTranslationKey={""}
            handleDataChangedFn={(newFarmDescriptionMultiLanguageData) => {
              let modifiedFarmDetailsCopy = _.cloneDeep(newFarmDetails);
              if (modifiedFarmDetailsCopy && modifiedFarmDetailsCopy.farm) {
                modifiedFarmDetailsCopy.farm.description =
                  newFarmDescriptionMultiLanguageData;
                setNewFarmDetails(modifiedFarmDetailsCopy);
                setSaved(false);
              }
            }}
            givenLength={200}
            languageKeysArray={farmLanguages}
            additionalCssClassname="multiLanguageTextArea--farmDescription--textArea"
          />

          <LabelledImageDropZone
            labelText={translate(
              "ABOUT_THE_FARM_PAGE_CHANGE_FARM_PICTURE_LABEL",
            )}
            dropzoneText={translate(
              "ABOUT_THE_FARM_PAGE_CHANGE_FARM_PICTURE_DROP_ZONE_LABEL",
            )}
            initialValue={
              newFarmDetails &&
              newFarmDetails.farm &&
              newFarmDetails.farm.imageData &&
              newFarmDetails.farm.imageData["all"]
            }
            key={
              newFarmDetails.farm &&
              newFarmDetails.farm.imageData &&
              newFarmDetails.farm.imageData["all"]
            }
            onChangeFileFn={(newFileBase64) => {
              let modifiedFarmDetailsCopy = _.cloneDeep(newFarmDetails);
              if (modifiedFarmDetailsCopy && modifiedFarmDetailsCopy.farm) {
                modifiedFarmDetailsCopy.farm.imageData["all"] = newFileBase64;
                setNewFarmDetails(modifiedFarmDetailsCopy);
              }
              setSaved(false);
            }}
            onDeleteFileFn={() => {
              let modifiedFarmDetailsCopy = _.cloneDeep(newFarmDetails);
              if (
                modifiedFarmDetailsCopy.farm &&
                modifiedFarmDetailsCopy.farm.imageData["all"]
              ) {
                modifiedFarmDetailsCopy.farm.imageData["all"] = "";
                setNewFarmDetails(modifiedFarmDetailsCopy);
              }
              setSaved(false);
            }}
            defaultPreviewCssClassname="farmImagePreview"
          />
        </div>
        <div className="solarPanel-block">
          <div>{translate("ABOUT_THE_FARM_PAGE_DO_YOU_HAVE_SOLAR_PANELS")}</div>
          <ImageRadioSelection
            optionsArray={[
              {
                label: translate("YES"),
                value: "1",
                classNameAppendix: "inline",
              },
              {
                label: translate("NO"),
                value: "0",
                classNameAppendix: "inline",
              },
            ]}
            initialSelectedValue={
              newFarmDetails.farm &&
              newFarmDetails.farm.solarPanel &&
              newFarmDetails.farm.solarPanel.flag
                ? "1"
                : "0"
            }
            onSelectionChangeFn={(newSelectedValue) => {
              setNewFarmDetails({
                ...newFarmDetails,
                farm: {
                  ...newFarmDetails.farm,
                  solarPanel:
                    newSelectedValue === "1"
                      ? { flag: true }
                      : { ...newFarmDetails.farm.solarPanel, flag: false },
                },
              });
              setSaved(false);
              setHasSolarPanels(newSelectedValue === "1");
            }}
          />
          {hasSolarPanels && (
            <>
              <LabelledTextInput
                initialValue={
                  newFarmDetails &&
                  newFarmDetails.farm &&
                  newFarmDetails.farm.solarPanel &&
                  newFarmDetails.farm.solarPanel.numberOfPanels
                }
                labelText={translate("SOLARPANEL_NUMBER_QUESTION")}
                placeholderText={translate(
                  "SOLARPANEL_NUMBER_QUESTION_PLACEHOLDER",
                )}
                additionalClassName="about-the-farm--solarPanel"
                handleChangeFn={(newSolarPanelNumber) => {
                  let modifiedFarmDetailsCopy = _.cloneDeep(newFarmDetails);
                  modifiedFarmDetailsCopy.farm.solarPanel.numberOfPanels =
                    newSolarPanelNumber;
                  setNewFarmDetails(modifiedFarmDetailsCopy);
                  setSaved(false);
                }}
                optional={true}
              />
              <LabelledTextInput
                initialValue={
                  newFarmDetails &&
                  newFarmDetails.farm &&
                  newFarmDetails.farm.solarPanel &&
                  newFarmDetails.farm.solarPanel.solarEnergyPercentage
                }
                labelText={translate("SOLARPANEL_PERCENTAGE_QUESTION")}
                placeholderText={translate(
                  "SOLARPANEL_PERCENTAGE_QUESTION_PLACEHOLDER",
                )}
                additionalClassName="about-the-farm--solarPanel"
                handleChangeFn={(newSolarPanelPercentageNumber) => {
                  let modifiedFarmDetailsCopy = _.cloneDeep(newFarmDetails);
                  modifiedFarmDetailsCopy.farm.solarPanel.solarEnergyPercentage =
                    newSolarPanelPercentageNumber;
                  setNewFarmDetails(modifiedFarmDetailsCopy);
                  setSaved(false);
                }}
                optional={true}
              />
            </>
          )}
        </div>
        {/* FARMER SHOP */}
        <div className="farm-features-block">
          <div>{translate("ABOUT_THE_FARM_PAGE_DO_YOU_HAVE_A_SHOP")}</div>
          <div className="about-the-farm--shop-image">
            <Shop />
          </div>
          <ImageRadioSelection
            optionsArray={[
              {
                label: translate("YES"),
                value: "1",
                classNameAppendix: "inline",
              },
              {
                label: translate("NO"),
                value: "0",
                classNameAppendix: "inline",
              },
            ]}
            initialSelectedValue={
              newFarmDetails.farm &&
              newFarmDetails.farm.shop &&
              newFarmDetails.farm.shop.flag
                ? "1"
                : "0"
            }
            onSelectionChangeFn={(newSelectedValue) => {
              setNewFarmDetails({
                ...newFarmDetails,
                farm: {
                  ...newFarmDetails.farm,
                  shop:
                    newSelectedValue === "1"
                      ? { type: "Shop", flag: true, paymentMethods: ["Cash"] }
                      : { ...newFarmDetails.farm.shop, flag: false },
                },
              });
              setSaved(false);
              setHasShop(newSelectedValue === "1");
            }}
          />
          {hasShop && (
            <>
              <LabelledImageDropZone
                labelText={translate(
                  "ABOUT_THE_FARM_PAGE_CHANGE_SHOP_PICTURE_LABEL",
                )}
                dropzoneText={translate(
                  "ABOUT_YOU_PAGE_CHANGE_PROFILE_PICTURE_DROP_ZONE_LABEL",
                )}
                initialValue={
                  newFarmDetails.farm &&
                  newFarmDetails.farm.shop &&
                  newFarmDetails.farm.shop.imageData
                }
                key={
                  newFarmDetails.farm &&
                  newFarmDetails.farm.shop &&
                  newFarmDetails.farm.shop.imageData
                }
                onChangeFileFn={(newFileBase64) => {
                  setNewFarmDetails({
                    ...newFarmDetails,
                    farm: {
                      ...newFarmDetails.farm,
                      shop: {
                        ...newFarmDetails.farm.shop,
                        imageData: newFileBase64,
                      },
                    },
                  });
                  setSaved(false);
                }}
                onDeleteFileFn={() => {
                  setNewFarmDetails({
                    ...newFarmDetails,
                    farm: {
                      ...newFarmDetails.farm,
                      shop: { ...newFarmDetails.farm.shop, imageData: "" },
                    },
                  });
                  setSaved(false);
                }}
                defaultPreviewCssClassname="farmShopImagePreview"
              />
              <LabelledTextInput
                initialValue={newFarmDetails.farm.shop.websiteUrl}
                labelText={translate("ABOUT_THE_FARM_SHOP_LINK_LINK")}
                handleChangeFn={(newWebsiteUrl) => {
                  setNewFarmDetails({
                    ...newFarmDetails,
                    farm: {
                      ...newFarmDetails.farm,
                      shop: {
                        ...newFarmDetails.farm.shop,
                        websiteUrl: newWebsiteUrl,
                      },
                    },
                  });
                  setSaved(false);
                }}
                additionalClassName="about-you--textField"
                optional={true}
              />
              <div className="about-the-farm--payment-methods">
                <CheckboxGroupSelection
                  componentLabel={translate(
                    "ABOUT_THE_FARM_SHOP_PAYMENT_METHODS_LABEL",
                  )}
                  optionsArray={[
                    {
                      label: translate(
                        "ABOUT_THE_FARM_SHOP_PAYMENT_METHODS_CASH",
                      ),
                      value: "Cash",
                    },
                    {
                      label: translate(
                        "ABOUT_THE_FARM_SHOP_PAYMENT_METHODS_CARD",
                      ),
                      value: "ByCard",
                    },
                  ]}
                  selectedOptions={
                    newFarmDetails &&
                    newFarmDetails.farm &&
                    newFarmDetails.farm.shop &&
                    newFarmDetails.farm.shop.paymentMethods
                  }
                  onSelectionChange={(newSelectedValue) => {
                    let paymentMethods = [
                      ...(newFarmDetails &&
                        newFarmDetails.farm &&
                        newFarmDetails.farm.shop &&
                        newFarmDetails.farm.shop.paymentMethods),
                    ];
                    paymentMethods && paymentMethods.includes(newSelectedValue)
                      ? paymentMethods &&
                        paymentMethods.splice(
                          paymentMethods &&
                            paymentMethods.indexOf(newSelectedValue),
                          1,
                        )
                      : paymentMethods.push(newSelectedValue);
                    if (paymentMethods.length < 1) {
                      paymentMethods.push("Cash");
                    }
                    setNewFarmDetails({
                      ...newFarmDetails,
                      farm: {
                        ...newFarmDetails.farm,
                        shop: {
                          ...newFarmDetails.farm.shop,
                          paymentMethods: paymentMethods,
                        },
                      },
                    });
                    setSaved(false);
                  }}
                />
              </div>
              <WorkingHoursEditor
                onWorkingHoursChangedFn={(
                  newWorkingHours,
                  isThereInvalidWorkingTimeErrorInWorkingHours,
                ) => {
                  let previousWorkingHours =
                    newFarmDetails.farm.shop.workingTimes;
                  const isSavingNeededForThisChange = !_.isEqual(
                    newWorkingHours,
                    newFarmDetails.farm.shop.workingTimes,
                  );
                  let modifiedFarmDetails = {
                    ...newFarmDetails,
                    farm: {
                      ...newFarmDetails.farm,
                      shop: {
                        ...newFarmDetails.farm.shop,
                        workingTimes: _.cloneDeep(newWorkingHours),
                      },
                    },
                  };
                  setIsThereWorkingTimeError(
                    isThereInvalidWorkingTimeErrorInWorkingHours,
                  );
                  setNewFarmDetails(modifiedFarmDetails);
                  if (isSavingNeededForThisChange) {
                    setSaved(false);
                  }
                }}
                initialWorkingTimes={newFarmDetails.farm.shop.workingTimes}
              />
              <div>{translate("ABOUT_THE_FARM_SHOP_ADDRESS_TITLE")}</div>
              <LabelledTextInput
                initialValue={
                  newFarmDetails.farm.shop.address &&
                  newFarmDetails.farm.shop.address.streetAndNumber
                }
                labelText={translate("ABOUT_THE_FARM_SHOP_ADDRESS_STREET")}
                handleChangeFn={(newStreet) => {
                  shopAddress.current = {
                    ...shopAddress.current,
                    streetAndNumber: newStreet,
                  };
                  setNewFarmDetails({
                    ...newFarmDetails,
                    farm: {
                      ...newFarmDetails.farm,
                      shop: {
                        ...newFarmDetails.farm.shop,
                        address: shopAddress.current,
                      },
                    },
                  });
                  setSaved(false);
                }}
                additionalClassName="about-you--textField"
                optional={true}
              />
              <LabelledTextInput
                initialValue={
                  newFarmDetails.farm.shop.address &&
                  newFarmDetails.farm.shop.address.postalAndCity
                }
                labelText={translate("ABOUT_THE_FARM_SHOP_ADDRESS_CITY")}
                handleChangeFn={(newCity) => {
                  shopAddress.current = {
                    ...shopAddress.current,
                    postalAndCity: newCity,
                  };
                  setNewFarmDetails({
                    ...newFarmDetails,
                    farm: {
                      ...newFarmDetails.farm,
                      shop: {
                        ...newFarmDetails.farm.shop,
                        address: shopAddress.current,
                      },
                    },
                  });
                  setSaved(false);
                }}
                additionalClassName="about-you--textField"
                optional={true}
              />
              <LabelledTextInput
                initialValue={
                  newFarmDetails.farm.shop.address &&
                  newFarmDetails.farm.shop.address.googleMapsUrl
                }
                labelText={translate("ABOUT_THE_FARM_SHOP_ADDRESS_GOOGLE_LINK")}
                handleChangeFn={(newUrl) => {
                  shopAddress.current = {
                    ...shopAddress.current,
                    googleMapsUrl: newUrl,
                  };
                  setNewFarmDetails({
                    ...newFarmDetails,
                    farm: {
                      ...newFarmDetails.farm,
                      shop: {
                        ...newFarmDetails.farm.shop,
                        address: shopAddress.current,
                      },
                    },
                  });
                  setSaved(false);
                }}
                additionalClassName="about-you--textField"
                optional={true}
                tooltip={<Info />}
                tooltipFn={() => setShowGoogleMapsPopup(true)}
              />
              {isThereWorkingTimeError ? (
                <div className="about-the-farm--error">
                  {translate("ABOUT_YOU_FARM_SHOP_VALIDATION_ERROR")}
                </div>
              ) : (
                <div className="about-the-farm--empty-line" />
              )}
            </>
          )}
        </div>
        {showGoogleMapsPopup && renderGoogleMapsLinkPopup()}
        {isSaved && lastSaveTimestamp !== 0 ? (
          <Button
            className="btn-already-saved"
            disabled={false}
            onClick={() => {}}
          >
            {translate("ADMIN_PAGE_ALREADY_SAVED_CHANGES_BUTTON_LABEL")}
          </Button>
        ) : null}
        {!isSaved ? (
          <Button
            className="btn-save-changes"
            style={{ marginBottom: "5vh" }}
            disabled={isThereWorkingTimeError}
            onClick={handleSaveChangesButtonClick}
          >
            {translate("ABOUT_THE_FARM_PAGE_SAVE_CHANGES_BUTTON_LABEL")}
          </Button>
        ) : null}
        {isSaveChangesConfirmationPopupDisplayed ? (
          <SaveChangesConfirmationDialog />
        ) : null}
      </div>
    );
  } else {
    return null;
  }
}

export default AboutTheFarmPage;
