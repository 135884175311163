import _ from "lodash";
import React from "react";
import "./CheeseFactoryScreen.scss";
import TextCaption from "../../../../components/TextCaption/TextCaption";
import {
  translate,
  translateWithValuesAndHtml,
} from "../../../../localization/TranslationUtil";
import { useSelector } from "react-redux";

const CheeseFactoryScreen = React.memo(() => {
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  return (
    <div className="CheeseFactoryScreen">
      <TextCaption additionalCssClassname="textCaption--CheeseFactoryScreen">
        <p
          className="CheeseFactoryScreen--text"
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml("CHEESE_ONBOARDING_TEXT"),
          }}
        ></p>
      </TextCaption>
      <div className="CheeseFactoryScreen--farmer"></div>
      <div className="CheeseFactoryScreen--cheese"></div>
    </div>
  );
});

export default CheeseFactoryScreen;
