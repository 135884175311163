import axios from 'axios';
import { FILTER_COW_ID, COW_DAILY_DETAILS_SERVICE_URL } from './config';

export const api = {
  getCowDailyDetails: async function (cowId, specificDate) {
    let cowDailyDetailsServiceUrl = COW_DAILY_DETAILS_SERVICE_URL;
    cowDailyDetailsServiceUrl += cowId ? FILTER_COW_ID + cowId : '';
    //cowDailyDetailsServiceUrl += batchId ? FILTER_DATE + specificDate : '';
    return axios.get(cowDailyDetailsServiceUrl, {});
  },
};
