import React from "react";
import "./PoleComponent.scss";
import { translate } from "../../../localization/TranslationUtil";
import { useLocation } from "react-router";
import {
  IS_CLICKABLE_CSS_CLASSNAME,
  SIDE_MENU_OPTION_TO_THE_FARM,
} from "../../../common/constants";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { useSelector } from "react-redux";

function PoleComponent({ onNavigateToInitialSlideFn }) {
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const farmStyle = farmDetails && farmDetails.style.toLowerCase();
  
  const getFarmHouseStyle = (farmStyle) => {
    return farmStyle != undefined
      ? "lastSlideRobotStory--house--" + farmStyle
      : "lastSlideRobotStory--house--style1";
  };
  let shareableURL = window.location.href.toString();

  shareableURL =
    shareableURL.indexOf("localhost") !== -1
      ? shareableURL.replace(
          "localhost:3000",
          "lelymilkyway-prod.westeurope.cloudapp.azure.com",
        )
      : shareableURL;
  const goToFarmText = translate("COW_OUTRO_GO_TO_FARM_BUTTON_CAPTION");
  const doYouWantToKnowMore = translate("DO_YOU_WANT_TO_KNOW_MORE");
  const toTheMijnMelkWebSiteText = translate("TO_THE_MIJN_MELK_WEBSITE_TEXT");
  const shareText = translate("SHARE");

  const search = useLocation().search;
  const farmId = new URLSearchParams(search).get("f");
  let mijnMelkPopupFarmId = "";
  switch (farmId) {
    case "A4D1442E459E":
      mijnMelkPopupFarmId = "dedobbelhoeve";
      break;
    case "8D8F970C4BB1":
      mijnMelkPopupFarmId = "oostdam";
      break;
    case "9FCA2FEE4AB3":
      mijnMelkPopupFarmId = "boerreijer";
      break;
    case "9643297842C4":
      mijnMelkPopupFarmId = "boerkees";
      break;
    default:
      mijnMelkPopupFarmId = "dedobbelhoeve";
      break;
  }

  return (
    <div className="vectorStoryLastSlide">
      <div className="lastSlideRobotStory--pole"></div>
      <div className="lastSlideRobotStory--poleSharing"></div>
      <div className={getFarmHouseStyle(farmStyle)}></div>
      <div className="lastSlideRobotStory--poleSharing--share">
        <span className="lastSlideRobotStory--poleSharing--share--text">
          {shareText}
        </span>
      </div>
      <div className="lastSlideRobotStory--poleSharing--facebook">
        <FacebookShareButton
          url={shareableURL}
          quote="link"
          className="Demo__some-network__share-button"
        >
          <FacebookIcon
            //size={"4vw"}
            round
            className="btn-social theme-btn-social"
          />
        </FacebookShareButton>
      </div>
      <div className="lastSlideRobotStory--poleSharing--linkedin">
        <LinkedinShareButton
          url={shareableURL}
          quote="link"
          className="Demo__some-network__share-button"
        >
          <LinkedinIcon
            size={"4vw"}
            round
            className="btn-social theme-btn-social"
          />
        </LinkedinShareButton>
      </div>
      <div className="lastSlideRobotStory--poleSharing--twitter">
        <TwitterShareButton
          url={shareableURL}
          quote="link"
          className="Demo__some-network__share-button"
        >
          <TwitterIcon
            size={"4vw"}
            round
            className="btn-social theme-btn-social"
          />
        </TwitterShareButton>
      </div>
      <div className="lastSlideRobotStory--house"></div>
      <div className="lastSlideRobotStory--trees"></div>
      <div className="lastSlideRobotStory--house"></div>
      <div className="lastSlideRobotStory--moreInfo">
        <span className="lastSlideRobotStory--moreInfo--text">
          {doYouWantToKnowMore}
        </span>
      </div>
      <div className="vectorStoryLastSlide--sign">
        <span
          className={"vectorStoryLastSlide--text " + IS_CLICKABLE_CSS_CLASSNAME}
          onClick={() =>
            onNavigateToInitialSlideFn(SIDE_MENU_OPTION_TO_THE_FARM)
          }
        >
          {goToFarmText}
        </span>
      </div>
      <div className="mijnMelkLastSlide--sign">
        <span
          className={
            "mijnMelkLastSlide--sign--text " + IS_CLICKABLE_CSS_CLASSNAME
          }
          onClick={() => window.open(`https://mijnmelk.nl/${mijnMelkPopupFarmId}`)}
        >
          {toTheMijnMelkWebSiteText}
        </span>
      </div>
    </div>
  );
}

export default PoleComponent;
