import { FARM_THEME_MILK_BOTTLE } from "../common/constants";

export const DEFAULT_THEME_OPTIONS = [
  {
    name: "Default",
    code: "lely",
  },
  // {
  //   name: "Thema Groen",
  //   code: "themagroen",
  // },
  // {
  //   name: "MijnMelk Generic",
  //   code: "mijnmelkgeneric",
  // },
  // {
  //   name: "MijnMelk Picnic",
  //   code: "mijnmelkpicnic",
  // },
  // {
  //   name: "Kaas farm",
  //   code: "kaasfarm",
  // },
  {
    name: "Mijn Melk default",
    code: FARM_THEME_MILK_BOTTLE,
  },

  // {
  //   name: "Adult theme",
  //   code: "adult",
  // },
];
