import React from "react";
import uuid from "react-uuid";
import "./Slide.scss";

/** Slide component */
function Slide({
  titleText,
  slideIndex,
  children,
  additionalCssClassname,
  explicitSlideWidthInPx,
}) {
  const currentSlideXPosition = `${slideIndex * explicitSlideWidthInPx}px`;
  const slideUuid = uuid();

  return (
    <div
      className={
        additionalCssClassname
          ? additionalCssClassname + " slide slide-" + slideIndex
          : "slide slide-" + slideIndex
      }
      style={{
        position: "absolute",
        left: currentSlideXPosition,
        width: explicitSlideWidthInPx + "px",
      }}
    >
      {children}
    </div>
  );
}
export default Slide;
