import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../localization/TranslationUtil";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
import Graphic from "../Graphic/Graphic";
import IconButton from "../IconButton/IconButton";
import LabelValue from "../LabelValue/LabelValue";
import { getCowDailyDetails } from "./actions";
import "./CowPopup.scss";
import moment from "moment";
import CowNameVoting from "../../components/CowNameVoting/CowNameVoting";
import {
  MOCK_NAME_POLL_OBJECT_THAT_IS_ACTIVE,
  MOCK_NAME_POLL_OBJECT_THAT_IS_OVER,
} from "../../api/lelyBackend/namePollsApi";
import { hasCowNameBeenChosen } from "../../components/CowNameVoting/CowNameUtil";
import { getCowPopupSvgFilePath } from "../../common/svgUtil";
import { resetLastVoteResult } from "../../components/CowNameVoting/actions";

/** CowPopup component */
function CowPopup({
  selectedCowDetails,
  farmTheme,
  seeHistoryClickFn,
  seeLatestClickFn,
  closePopupClickFn,
  isAdultSelected,
  isBatchCow = true,
}) {
  console.log("Selected cow details:", selectedCowDetails);
  const dispatch = useDispatch();
  //selectedCowDetails.namePoll = MOCK_NAME_POLL_OBJECT_THAT_IS_OVER;
  selectedCowDetails.namePoll = MOCK_NAME_POLL_OBJECT_THAT_IS_ACTIVE;
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  useEffect(() => {
    let selectedCowId = selectedCowDetails.externalId;
    dispatch(getCowDailyDetails(selectedCowId));
    return () => {
      dispatch(resetLastVoteResult());
    };
  }, []);
  const { cowDailyDetails, isCowDailyDetailsError, errorMessage } = useSelector(
    (state) => state.cowDailyDetails,
  );

  let cowPopupSvgPath = getCowPopupSvgFilePath(
    farmTheme,
    selectedCowDetails.cowSubBreed,
  );
  let shouldNavigateToCowDailyButtonBeDisabled =
    isCowDailyDetailsError || !cowDailyDetails.weatherData;
  const lastMilking =
    cowDailyDetails &&
    cowDailyDetails.milkings &&
    cowDailyDetails.milkings.slice(-1).pop();
  let hasCowNameBeenChosenAlready = hasCowNameBeenChosen(
    selectedCowDetails && selectedCowDetails.poll,
  );
  let cowName = hasCowNameBeenChosenAlready
    ? selectedCowDetails.poll.chosenName
    : selectedCowDetails.name;
  const cowPopupTitle = translate("COW_POPUP_TITLE_PREFIX") + cowName;
  const cowPopupCowClassName =
    "cowPopup--cow " + "cowPopup--cow--" + selectedCowDetails.cowSubBreed;
  const cowBreedValue =
    selectedCowDetails.cowType == "mixed"
      ? selectedCowDetails.cowSubBreed
      : selectedCowDetails.cowType;
  const isMoreThanOneYearOld = () => {
    if (selectedCowDetails && selectedCowDetails.ageInYears > "0") {
      return selectedCowDetails.ageInYears + " jaar";
    } else if (selectedCowDetails && selectedCowDetails.ageInYears == "0") {
      return monthDifference() + " maanden";
    }
  };
  const monthDifference = () => {
    const birthdayDate = selectedCowDetails.birthTimestamp;
    const currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");
    return moment(new Date(currentDate))
      .diff(new Date(birthdayDate), "months", true)
      .toFixed(0);
  };

  let cowPopupCssClassname = hasCowNameBeenChosenAlready
    ? "cowPopup cowPopup--hasNameBeenSelected cowNameVoting--" +
      selectedCowDetails.subBreedCssClassName
    : "cowPopup";
  cowPopupCssClassname =
    selectedCowDetails &&
    selectedCowDetails.poll &&
    selectedCowDetails.name == null &&
    !hasCowNameBeenChosenAlready
      ? cowPopupCssClassname + " cowPopup--namePolling"
      : cowPopupCssClassname;

  //#region TODO: DELETE THIS REGION WHEN DONE WITH BUG #TJOM-1113
  //selectedCowDetails.poll = MOCK_NAME_POLL_OBJECT_THAT_IS_OVER; //MOCK_NAME_POLL_OBJECT_THAT_IS_OVER; //MOCK_NAME_POLL_OBJECT_THAT_IS_ACTIVE;
  //selectedCowDetails.name = null;
  //#endregion
  return (
    <div className={cowPopupCssClassname}>
      {selectedCowDetails &&
      selectedCowDetails.poll &&
      selectedCowDetails &&
      selectedCowDetails.name == null ? (
        <CowNameVoting
          farmDetails={farmDetails}
          cowDetails={selectedCowDetails}
        />
      ) : null}
      <div className="cowPopup--container">
        <IconButton
          cssClassName="cowPopup--btnClosePopup"
          onClickFn={closePopupClickFn}
        />
        <div className="cowPopup--title">{cowPopupTitle}</div>
        {isBatchCow && (
          <LabelValue label={translate("COW_RACE")} value={cowBreedValue} />
        )}
        {isBatchCow && selectedCowDetails.weightKg !== 0 ? (
          <LabelValue
            label={translate("COW_WEIGHT")}
            value={selectedCowDetails.weightKg + " KG"}
          />
        ) : null}
        {isBatchCow ? (
          <LabelValue
            label={translate("COW_AGE")}
            value={isMoreThanOneYearOld()}
          />
        ) : selectedCowDetails.birthTimestamp ? (
          <LabelValue
            label={translate("COW_BIRTH_DATE")}
            value={moment(selectedCowDetails.birthTimestamp).format(
              "DD MM YYYY",
            )}
          />
        ) : null}
        {/* {isBatchCow && (
        <LabelValue
          label={translate("COW_MILK_PROFILE")}
          value={"Vol & Romig"}
        />
      )} */}
        {isBatchCow && lastMilking && lastMilking.created !== "null" ? (
          <LabelValue
            label={translate("COW_LATEST_MILKING")}
            value={moment(lastMilking.created)
              .utc()
              .format("DD-MM-YYYY, HH:mm")}
          />
        ) : null}

        <div className="cowPopup--buttons">
          {/* <Graphic cssClassName={cowPopupCowClassName} /> */}
          {isBatchCow && !selectedCowDetails.doHideButtons && (
            <CallToActionButton
              isAdultSelected={isAdultSelected}
              cssClassName={
                shouldNavigateToCowDailyButtonBeDisabled
                  ? "cowPopup--btnCallToAction is-disabled"
                  : "cowPopup--btnCallToAction"
              }
              label={translate("MILK_FACTORY_POPUP_SEE_LIVE_DATA_BUTTON_LABEL")}
              onClickFn={() => {
                if (!shouldNavigateToCowDailyButtonBeDisabled) {
                  seeLatestClickFn(cowDailyDetails);
                  closePopupClickFn();
                }
              }}
            />
          )}
          {isBatchCow && !selectedCowDetails.doHideButtons && (
            <CallToActionButton
              isAdultSelected={isAdultSelected}
              cssClassName={"cowPopup--secondBtnCallToAction"}
              label={translate("MILK_FACTORY_POPUP_SEE_HISTORY_BUTTON_LABEL")}
              onClickFn={() => {
                seeHistoryClickFn(selectedCowDetails);
                closePopupClickFn();
              }}
            />
          )}
        </div>
      </div>

      {/* <LabelValue
        label={translate('COW_NOW')}
        value={selectedCowDetails.nowText}
      /> */}
      {hasCowNameBeenChosenAlready ? (
        <>
          <Graphic cssClassName="cowPopup--chosen-name-party-hat" />
          <Graphic cssClassName="cowPopup--chosen-name-party-flute" />
        </>
      ) : null}
      {isAdultSelected ? (
        <div className="cowPopup-head-adults"></div>
      ) : (
        <DynamicInlineSVG
          themeName={farmTheme}
          svgFullFilePath={cowPopupSvgPath}
          className={cowPopupCowClassName}
        />
      )}
    </div>
  );
}
export default CowPopup;
