import React from "react";
import { windowWhen } from "rxjs/operator/windowWhen";
import { getRandomNumberBetweenMinAndMax, measureResponsive } from "../../components/VirtualFarm/util";
import "./DiscoveryRoute.scss";


/** DiscoveryRoute component */
function DiscoveryRoute({
  discoveryRouteSettings
 }) {
    function getMeasuredResponsiveValue(value) {
      let measureResponsiveString = measureResponsive(value);
      let starCharacterPosition = measureResponsiveString.indexOf("*") + 2;
      let closingBracketPosition = measureResponsiveString.lastIndexOf(")");
      let parsedVhValue = measureResponsiveString.substring(starCharacterPosition, closingBracketPosition);

      let isInLandscapeMode = window.innerHeight < window.innerWidth;
      let windowHeight = isInLandscapeMode ? window.innerHeight : window.innerWidth;
      return (parsedVhValue / 100) * windowHeight;
    }

    function getPolygonPointsByDiscoveryRouteSettings(discoveryRouteSettings) {
      let polygonPoints = [];
      discoveryRouteSettings.STAGES.forEach(currentStage => {
        polygonPoints.push({ x: getMeasuredResponsiveValue(currentStage.startPosition.x), y: getMeasuredResponsiveValue(currentStage.startPosition.y) });
        polygonPoints.push({ x: getMeasuredResponsiveValue(currentStage.endPosition.x), y: getMeasuredResponsiveValue(currentStage.endPosition.y) });
      });
      return polygonPoints;
    }

  let discoveryRoutePolygonPoints = getPolygonPointsByDiscoveryRouteSettings(discoveryRouteSettings);
  let polygonPointsString = discoveryRoutePolygonPoints.map(currentRoutePolygonPoint => `${currentRoutePolygonPoint.x},${currentRoutePolygonPoint.y}`).join(" ");
  return (
    <div
      className="discoveryRoute"
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <svg width="100%" height="100%">
        <polygon points={polygonPointsString}
                style={{
                  fill: 'lime',
                  stroke: 'purple',
                  strokeWidth: 1
                }}
         />
      </svg>
    </div>
  );
}
export default DiscoveryRoute;
