import React from "react";
import "./LastSlideSustainability.scss";

import PoleComponent from "../../common/components/PoleComponentEndingSlides/PoleComponent";

const LastSlideAstronautSlide = ({ onNavigateToInitialSlideFn }) => {
  return (
    <div className="sustainabilityStoryLastSlide">
      <PoleComponent onNavigateToInitialSlideFn={onNavigateToInitialSlideFn} />
    </div>
  );
};

export default LastSlideAstronautSlide;
