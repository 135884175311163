import React from "react";
import "./ThirdSlideAstronaut.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const ThirdSlideAstronaut = ({}) => {
  return (
    <div className="astronautStoryThirdSlide">
      <TextCaption additionalCssClassname="astronautStoryThirdSlide--textCaption">
        <div className="astronautStoryThirdSlide--title">
          {translate("ASTRONAUT_TITLE_THIRD_SLIDE")}
        </div>
        <div className="astronautStoryThirdSlide--text">
          {translate("ASTRONAUT_TEXT_THIRD_SLIDE")}
        </div>
      </TextCaption>
      <div className="astronautStoryThirdSlide--imgCaption">
        <div className="astronautStoryThirdSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default ThirdSlideAstronaut;
