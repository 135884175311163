import {
  HIDE_CONFIRM_SAVING_CHANGES_POPUP,
  LEAVE_WITHOUT_SAVING_CHANGES,
  REQUEST_APPLICATION_NAVIGATION,
  SAVE_CHANGES_AND_LEAVE,
  SAVE_CHANGES_POPUP_ACTION,
  SHOW_CONFIRM_SAVING_CHANGES_POPUP,
  UPDATE_GLOBAL_SAVE_STATUS,
  RESET_GLOBAL_SAVE_STATUS,
  CLOSE_SAVE_CHANGES_POPUP,
} from "./types";

/** Create action which will be fired either when:
 * A) Users have clicked an item from the Sidebar
 * B) Users have tried to refresh/close the browser tab
 * @param {string} type - The type of navigation event
 * @param {string} additionalData - Additional data passed to the event that might be needed further down the road (e.g. urlAppendix)
 * @return {Object} Returns action object
 */
export function requestApplicationNavigation(type, additionalData) {
  return {
    type: REQUEST_APPLICATION_NAVIGATION,
    payload: {
      type,
      additionalData,
    },
  };
}

/** Create action which will be fired when users click the "Yes, save and leave" button in confirm save changes popup
 * @return {Object} Returns action object
 */
export function saveChangesAndLeave() {
  return {
    type: SAVE_CHANGES_POPUP_ACTION,
    payload: {
      type: SAVE_CHANGES_AND_LEAVE,
    },
  };
}

/** Create action which will be fired when users click the "No, leave page" button in confirm save changes popup
 * @return {Object} Returns action object
 */
export function leaveWithoutSavingChanges() {
  return {
    type: SAVE_CHANGES_POPUP_ACTION,
    payload: {
      type: LEAVE_WITHOUT_SAVING_CHANGES,
    },
  };
}

/** Create action which will be fired when users click the X button in the top right corner of the confirm save changes popup
 * @return {Object} Returns action object
 */
export function closeConfirmSaveChangesPopup() {
  return {
    type: SAVE_CHANGES_POPUP_ACTION,
    payload: {
      type: CLOSE_SAVE_CHANGES_POPUP,
    },
  };
}

/** Create action which will cause the confirm save changes popup to be displayed to the user
 * @return {Object} Returns action object
 */
export function requestConfirmSavingChanges() {
  return {
    type: SHOW_CONFIRM_SAVING_CHANGES_POPUP,
    payload: {},
  };
}

/** Create action which will cause the confirm save changes popup to be hidden
 * @return {Object} Returns action object
 */
export function hideConfirmSavingChangesPopup() {
  return {
    type: HIDE_CONFIRM_SAVING_CHANGES_POPUP,
    payload: {},
  };
}

/** Create an action which will be fired when any changes are made to the
 * current admin app page data object (in order to know whether changes are made
 * and whether the confirm save changes popup needs to be displayed when
 * the users try a specific form of navigation out of the page)
 * @param {Function} currentSaveClickHandlerFn - The function that gets called (with all of its parameters in its closure) when the user clicks the Save Changes at the bottom of the page
 * @param {boolean} currentAreChangesSaved - The saveChanges useState of the current admin application page
 * @return {Object} Returns action object
 */
export function updateCurrentSaveStatus(
  currentSaveClickHandlerFn,
  currentAreChangesSaved, // areChangesSaved as applicable for the current page that the user has opened
) {
  return {
    type: UPDATE_GLOBAL_SAVE_STATUS,
    payload: {
      currentSaveClickHandlerFn,
      currentAreChangesSaved,
    },
  };
}

/** Create action which will reset the status of the save manager object
 * @return {Object} Returns action object
 */
export function resetCurrentSaveStatus() {
  return {
    type: RESET_GLOBAL_SAVE_STATUS,
    payload: {},
  };
}
