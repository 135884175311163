import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers/rootReducer.js";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/rootSaga";
import omit from "lodash/omit";
import { composeWithDevTools } from "redux-devtools-extension";
import { configureStore } from "@reduxjs/toolkit";
// Redux persist
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";

const blacklistPaths = [
  "userSession.loginError",
  "redirect",
  "loadManager",
  "farmDetails",
  "popupDetails",
  "slideTitleDetails",
  "confettiDetails",
  "actorPositions",
  "virtualFarmDetails",
];

const persistConfig = {
  key: "root",
  storage,
  blacklist: blacklistPaths,
  transforms: [
    // nested blacklist-paths require a custom transform to be applied
    createTransform((inboundState: any, key) => {
      const reducerName = String(key);
      const blacklistPaths_forKey = blacklistPaths
        .filter((path) => path.startsWith(`${reducerName}.`))
        .map((path) => path.substring(reducerName.length + 1));
      return omit(inboundState, ...blacklistPaths_forKey);
    }, null),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      thunk: false,
    }).concat(middlewares),
  devTools: {
    trace: true,
  },
});
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
