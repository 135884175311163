import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import './LabelledDatePicker.scss';
import { Grid, Typography } from '@material-ui/core';
import BaseDatePicker from './BaseDatePicker';
import { datePickerCommonValidation } from './datePickerValidation';
import { translate } from '../../../localization/TranslationUtil';

/** Date picker for Admin Application
 * @param {Date} startDate - Explicit start date
 * @param {Date} endDate - Explicit end date
 * @param {String} startDateLabelText
 * @param {String} endDateLabelText
 * @param {Function} onDateChangeFunction - Function to be called on date change
 * @param {Function} onDateValidityChangeFunction - Function to be called when date validity changes (error is thrown OR valid date is entered)
 * */
export default function LabelledDatePicker({
  startDate,
  endDate,
  startDateLabelText,
  endDateLabelText,
  onDateChangeFunction,
  onDateValidityChangeFunction,
}) {
  const datePickerStartDate = startDate ? startDate : new Date();
  const datePickerEndDate = endDate ? endDate : new Date();
  const IS_DATE_INVALID = false;
  const IS_DATE_VALID = true;

  /** Date picker object which contains start and end date  */
  const [datePickerDate, setDatePickerDate] = useState({
    start: datePickerStartDate,
    end: datePickerEndDate,
  });

  const [datePickerErrorMessage, setDatePickerErrorMessage] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    hideErrorMessage();
    validateSelectedDates();
  }, [datePickerDate]);
  useEffect(() => {
    setDatePickerDate({
      start: startDate,
      end: endDate,
    });
  }, [startDate, endDate]);
  /**
   * On changed date in date picker.
   * @param {object} date - Expect object with parameters which date to change {start: ..} or {end: ...}.
   */
  function changeDateInDatePicker(date) {
    let newDateRange = { ...datePickerDate, ...date };
    setDatePickerDate(newDateRange);
    onDateChangeFunction(newDateRange);
  }

  function showErrorMessage(errorMessage) {
    setDatePickerErrorMessage({
      show: true,
      message: errorMessage,
    });
    onDateValidityChangeFunction(IS_DATE_INVALID);
  }

  function hideErrorMessage() {
    setDatePickerErrorMessage({ show: false, message: '' });
    onDateValidityChangeFunction(IS_DATE_VALID);
  }

  function validateSelectedDates() {
    let hasValidationErrorOccurred = datePickerCommonValidation(
      datePickerDate.start,
      datePickerDate.end,
      true,
    );
    if (hasValidationErrorOccurred) {
      showErrorMessage(hasValidationErrorOccurred);
    }
  }

  function handleInvalidDateFormat(isError) {
    if (isError) {
      showErrorMessage(translate('INVALID_DATE_FORMAT_ERROR_TEXT'));
    }
  }

  return (
    <div className="data-container">
      <Grid>
        <Grid xs={10} item>
          <div>
            <div style={{ display: 'inline-flex' }}>
              <BaseDatePicker
                title={startDateLabelText || 'Date picker label'}
                setDate={(date) => changeDateInDatePicker({ start: date })}
                startDate={datePickerDate.start}
                showError={datePickerErrorMessage.show}
                enableFuture={false}
                invalidHandlerFn={handleInvalidDateFormat}
                dateFormat="dd/MM"
              />
              <BaseDatePicker
                style={{ left: '10vw !important' }}
                title={endDateLabelText || 'Date picker label'}
                setDate={(date) => changeDateInDatePicker({ end: date })}
                startDate={datePickerDate.end}
                showError={datePickerErrorMessage.show}
                enableFuture={false}
                invalidHandlerFn={handleInvalidDateFormat}
                dateFormat="dd/MM"
              />
              {datePickerErrorMessage.message.length > 0 ? (
                <Typography className="date-charts-date-picker-error-message-text">
                  {datePickerErrorMessage.message}
                </Typography>
              ) : null}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
