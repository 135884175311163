import {
  GET_COW_DAILY_DETAILS_REQUEST,
  GET_COW_DAILY_DETAILS_REQUEST_SUCCESS,
  GET_COW_DAILY_DETAILS_REQUEST_ERROR,
} from './types';

/** Create action which gets us the cow daily details
 * @param {string} cowId - Cow id
 * @param {string} specificDate - The date for which we want to get details about what the cow did
 * @return {Object} Return type, accessToken and farmId
 */
export function getCowDailyDetails(cowId, specificDate) {
  return {
    type: GET_COW_DAILY_DETAILS_REQUEST,
    payload: {
      cowId: cowId,
      specificDate: specificDate,
    },
  };
}

/** Create action which to cause the cow daily details to be stored in the Redux store
 * @param {Object} cowDailyDetails
 * @return {Object} Returns action object for storing cow daily details
 */
export function getCowDailyDetailsSuccess(cowDailyDetails) {
  return {
    type: GET_COW_DAILY_DETAILS_REQUEST_SUCCESS,
    payload: { ...cowDailyDetails },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getCowDailyDetailsError(errorMessage) {
  return {
    type: GET_COW_DAILY_DETAILS_REQUEST_ERROR,
    payload: errorMessage,
  };
}