import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import "./CowFirstYearSlide.scss";
import { useSelector } from "react-redux";
import { translate } from "../../../localization/TranslationUtil";
import TextCaption from "../../../components/TextCaption/TextCaption";

function CowFirstYearSlide() {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );

  useEffect(() => {}, [!_.isEmpty(selectedCowDetails)]);

  if (!_.isEmpty(selectedCowDetails)) {
    return <div className="cow-first-year--slide"></div>;
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowFirstYearSlide;
