import {
  GET_ASTRONAUT_DETAILS_REQUEST,
  GET_ASTRONAUT_DETAILS_REQUEST_ERROR,
  GET_ASTRONAUT_DETAILS_REQUEST_SUCCESS,
  RESET_ASTRONAUT_DETAILS,
} from "./types";

/** Create action for initiating the request of data for astronaut live data story
 * @param {string} farmId - Farm id
 * @param {string} cowId - Cow id
 * @return {Object} Action object
 */
export function getAstronautDetails(farmId, cowId) {
  return {
    type: GET_ASTRONAUT_DETAILS_REQUEST,
    payload: {
      farmId: farmId,
      cowId: cowId,
    },
  };
}

/** Create action which to cause the astronaut details to be stored in the Redux store
 * @param {Object} astronautDetails
 * @return {Object} Return type and astronautDetails
 */
export function getAstronautDetailsSuccess(astronautDetails) {
  return {
    type: GET_ASTRONAUT_DETAILS_REQUEST_SUCCESS,
    payload: { ...astronautDetails },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getAstronautDetailsError(errorMessage) {
  return {
    type: GET_ASTRONAUT_DETAILS_REQUEST_ERROR,
    payload: errorMessage,
  };
}

/** Create action which will clear all astronaut details from the reducer
 * @return {Object} Action object
 */
export function resetAstronautDetails() {
  return {
    type: RESET_ASTRONAUT_DETAILS,
    payload: {},
  };
}
