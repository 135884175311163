import _ from "lodash";
import React, { useEffect } from "react";
import "./CowGaveBirthSecondSlide.scss";
import TextCaption from "../../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";
import {
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../../localization/TranslationUtil";
import moment from "moment-timezone";

const CowGaveBirthSecondSlide = React.memo(() => {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  const calfClassName =
    "calfLeftSide " + "calf--" + selectedCowDetails.cowSubBreed;
  const calfName =
    selectedCowDetails &&
    selectedCowDetails.firstCalf &&
    selectedCowDetails.firstCalf.name !== null
      ? selectedCowDetails.firstCalf.name
      : "";

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div className="CowGaveBirthSecondSlide">
        {selectedCowDetails.firstCalf &&
        selectedCowDetails.firstCalf.birthTimestamp ? (
          <TextCaption additionalCssClassname="textCaption--CowGaveBirthSecondSlide">
            <p
              className="cow-CowGaveBirthSecondSlide--text"
              dangerouslySetInnerHTML={{
                __html: translateWithValuesAndHtml(
                  "COW_GAVE_BIRTH_SECOND_SLIDE_INFO",
                  { cowName: calfName },
                ),
              }}
            ></p>
          </TextCaption>
        ) : null}
        <div className="cow-CowGaveBirthSecondSlide--temperature"></div>
        <TextCaption additionalCssClassname="textCaption--CowGaveBirthSecondSlide--degrees">
          {selectedCowDetails.temperatureAtBirth}°c{" "}
        </TextCaption>
        <div className={calfClassName}></div>
        // <span className="cow-CowGaveBirthSecondSlide--button"></span>
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
});

export default CowGaveBirthSecondSlide;
