import React from "react";
import "./SecondSlideJuno.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const SecondSlideJuno = ({}) => {
  return (
    <div className="junoStorySecondSlide">
      <TextCaption additionalCssClassname="junoStorySecondSlide--textCaption">
        <div className="junoStorySecondSlide--title">
          {translate("JUNO_HISTORY_TITLE")}
        </div>
        <div className="junoStorySecondSlide--text">
          {translate("JUNO_HISTORY_TEXT")}
        </div>
      </TextCaption>
      <div className="junoStorySecondSlide--imgCaption">
        <div className="junoStorySecondSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default SecondSlideJuno;
