import React from "react";
import "./AstronautTimeCleanedAndMoved.scss";
import MatrixBackground from "./MatrixBackground";
import { useSelector } from "react-redux";
import {
  translate,
  translateWithValues,
} from "../../localization/TranslationUtil";
import moment from "moment";

const AstronautTimeCleanedAndMoved = ({}) => {
  const { astronautDetails } = useSelector((state) => state.astronautDetails);
  const movingTime = moment
    .utc(1000 * astronautDetails.secondsMovingLast24Hours)
    .format("H");
  const hoursMilkingLast24Hours = moment
    .utc(1000 * astronautDetails.secondsCleaningLast24Hours)
    .format("H");
  const minutesCleaning = (
    astronautDetails.secondsCleaningLast24Hours / 60
  ).toFixed(0);
  const minutesMoving = (
    astronautDetails.secondsMovingLast24Hours / 60
  ).toFixed(0);

  const timeCleaned = (time) => {
    return time == 0
      ? translateWithValues("ASTRONAUT_TIME_CLEANED_MINUTES", {
          timeCleaned: minutesCleaning,
        })
      : translateWithValues("ASTRONAUT_TIME_CLEANED", {
          timeCleaned: hoursMilkingLast24Hours,
        });
  };

  const timeMoved = (time) => {
    return time == 0
      ? translateWithValues("ASTRONAUT_TIME_MOVING_MINUTES", {
          timeCleaned: minutesMoving,
        })
      : translateWithValues("ASTRONAUT_TIME_MOVING", {
          hoursMoved: movingTime,
          minutesMoved: movingTime,
        });
  };
  return (
    <div className="astronautTimeCleanedAndMoved">
      <div className="astronautTimeCleanedAndMoved--textCaption">
        <div className="astronautTimeCleanedAndMoved--text">
          {timeCleaned(hoursMilkingLast24Hours)}
        </div>
      </div>
      <div className="astronautTimeCleanedAndMoved--whiteTextCaption">
        <div className="astronautTimeCleanedAndMoved--whiteTextCaptionText">
          {timeMoved(movingTime)}
        </div>
      </div>
      <div className="astronautTimeCleanedAndMoved--arrowConnectingTextBoxes"></div>
      <div className="astronautTimeCleanedAndMoved--transitionTree"></div>
    </div>
  );
};

export default AstronautTimeCleanedAndMoved;
