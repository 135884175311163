import _ from "lodash";
import React, { useEffect } from "react";
import "./CowParentsPage.scss";
import TextCaption from "../../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";
import {
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../../localization/TranslationUtil";
import FamilyTree from "../../../components/FamilyTree/FamilyTree";
import { populateCowRelativesWithSubbreeds } from "../../../components/FamilyTree/CowInfoUtil";

const regex = new RegExp(/(\d+)?(\s+-\s+)?([A-z ]+)?(\d+)?/);

const CowParentsPage = React.memo(() => {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  const getNumberOfCow = (nameFromServer) => {
    const result = regex.exec(nameFromServer);
    return result[4] ? result[4] : "1";
  };

  const getCowFamilyName = (nameFromServer) => {
    const result = regex.exec(nameFromServer);
    if (result[3]) {
      return result[3];
    } else {
      return result[1] ? result[1] : "";
    }
  };

  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const cowSubbreed = selectedCowDetails.cowSubBreed;
  populateCowRelativesWithSubbreeds(
    selectedCowDetails,
    farmDetails,
    cowSubbreed,
    selectedCowDetails.cowType,
  );
  
  useEffect(() => {}, [!_.isEmpty(selectedCowDetails)]);

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div className="CowParentsPage">
        <TextCaption additionalCssClassname="textCaption--cowParentPage">
          <p
            className="CowParentsPage--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml("COW_INTRO_SECOND_PAGE", {
                cowName: getNumberOfCow(selectedCowDetails.name),
              }),
            }}
          ></p>
          <span className="cow-full--name">
            {translateWithValues("COW_INTRO_NAME_END", {
              cowName: getCowFamilyName(selectedCowDetails.name).trim(),
            })}
          </span>
          <div className="cowParentPage">
            <FamilyTree
              expandedCowDetails={selectedCowDetails}
              cowDetails={selectedCowDetails}
              subBreed={cowSubbreed}
            />
          </div>
          {/* {translateWithValues("COW_INTRO_SECOND_PAGE", {
              // cowName: selectedCowDetails.name.split(' ')[1],
              cowName: getNumberOfCow(selectedCowDetails.name),
            })}
            <strong className="CowParentsPage--text--name">
              {translateWithValues("COW_INTRO_NAME_END", {
                cowName: getCowFamilyName(selectedCowDetails.name).trim(),
              })} 
            </strong> */}
        </TextCaption>
        <div className="CowParentsPage-leftTree"></div>
      </div>
    );
  } else {
    return <p></p>;
  }
});

export default CowParentsPage;
