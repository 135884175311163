import React, { useState } from "react";
import "./LoginForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "./actions.js";
import {
  Button,
  TextField,
  Container,
  Paper,
  Typography,
  Link,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { translate } from "../../../localization/TranslationUtil";
import LabelledTextInput from "../../../common/components/LabelledTextInput/LabelledTextInput";
import { redirect } from "../../CustomRedirect/actions";

/** Login component */
function LoginForm() {
  const dispatch = useDispatch();
  /** Username state */
  const [username, setUsername] = useState("");
  /** Password state */
  const [password, setPassword] = useState("");
  /** Parameter responsible for showing the error layout */
  const { loginError } = useSelector((state) => state.userSession);

  /** Method for updating the username state
   * @param {Object} event - Object which contains input field value
   */
  const onChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  /** Method for updating the password state
   * @param {Object} event - Object which contains input field value
   */
  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };

  /** Create a login request
   * @param {Object} event - Object which contain form event
   */
  const onSubmitCredentials = (event) => {
    event && event.preventDefault();
    dispatch(loginUser(username, password));
  };

  /** If Username and Password fields are not enable login button */
  let isLoginButtonEnabled = username.length > 0 && password.length > 0;

  /** Error message component */
  let errorMessage = "";
  if (loginError) {
    let isAccountLocked =
      loginError.message && loginError.message.indexOf("403") !== -1;
    if (isAccountLocked) {
      errorMessage = translate("USER_MANAGEMENT_USER_ACCOUNT_LOCKED_MSG");
    } else {
      errorMessage = translate("USER_MANAGEMENT_LOGIN_MSG_ERROR");
    }
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      className="login-form-container"
      onKeyUp={(event) => {
        if (event.key === "Enter") {
          if (isLoginButtonEnabled) {
            onSubmitCredentials();
          }
        }
      }}
    >
      <LabelledTextInput
        initialValue={username}
        labelText={translate("USER_MANAGEMENT_LOGIN_EMAIL_TEXT")}
        handleChangeFn={(usernameValue) => {
          setUsername(usernameValue);
        }}
        additionalClassName="login--textField"
        dataTestID="txt-username"
      />
      <LabelledTextInput
        initialValue={password}
        labelText={translate("USER_MANAGEMENT_LOGIN_PASSWORD_TEXT")}
        handleChangeFn={(passwordValue) => {
          setPassword(passwordValue);
        }}
        additionalClassName="login--textField"
        inputType="password"
        dataTestID="txt-password"
      />
      <p className="login-container--error-msg">{errorMessage}</p>
      <Button
        disabled={!isLoginButtonEnabled}
        data-testid="btn-login"
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className="btn-login"
        onClick={onSubmitCredentials}
      >
        {translate("USER_MANAGEMENT_LOGIN_LOGIN_BUTTON_TEXT")}
      </Button>
      <Link
        /*
        onClick={() => {
          dispatch(redirect("/admin/recover"));
        }}
        */
        href="mailto:info@mijnmelk.nl?subject=Forgotten Password"
      >
        {translate("USER_MANAGEMENT_LOGIN_FORGOTTEN_PASSWORD_BUTTON_TEXT")}
      </Link>
    </Container>
  );
}

export default LoginForm;
