import _ from "lodash";
import React, { useEffect, useState } from "react";
import "./AboutYouPage.scss";
import { useSelector, useDispatch } from "react-redux";
import { translate } from "../../../localization/TranslationUtil";
import LabelledTextInput from "../../../common/components/LabelledTextInput/LabelledTextInput";
import ImageRadioSelection from "../../../common/components/ImageRadioSelection/ImageRadioSelection";
import LabelledImageDropZone from "../../../common/components/LabelledImageDropZone/LabelledImageDropZone";
import { Button } from "@material-ui/core";
import {
  saveChangesToFarmerPage,
  requestFarmerPageInformation,
} from "./actions";
import { getCurrentLanguage } from "../../../localization/TranslationUtil";
import LanguageSelection from "../../../common/components/LanguageSelection/LanguageSelection";
import StoryBlockList from "../../../components/StoryBlockList/StoryBlockList";
import {
  convertBEStoryBlocksToFE,
  convertFEStoryBlocksToBE,
} from "../../../components/StoryBlock/StoryBlockObject";
import { DEFAULT_LANGUAGE_OPTIONS } from "../../../common/constants";
import { requestSelectedFarmLanguagesInformation } from "../../../common/components/LanguageSelection/actions";
import { convertLanguageSelectionToBE } from "../../../common/components/LanguageSelection/LanguageUtil";
import MultiLanguageTextArea from "../../../common/components/MultiLanguageTextArea/MultiLanguageTextArea";
import useSaveManager from "../../../hooks/useSaveManager";
import SaveChangesConfirmationDialog from "../../../components/SaveChangesConfirmationDialog/SaveChangesConfirmationDialog";

function AboutYouPage() {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => ({ ...state.userSession }));
  const { farmerPageDetails } = useSelector((state) => ({
    ...state.farmerPageDetails,
  }));

  //#region farm languages logic
  const { farmLanguagesDetails } = useSelector((state) => ({
    ...state.selectedFarmLanguages,
  }));
  let languageKeysForAllLanguagesAvailable = DEFAULT_LANGUAGE_OPTIONS.filter(
    (currentLanguageOption) => currentLanguageOption.code.length === 2,
  ).map((currentLanguageOption) => currentLanguageOption.code);

  function initializeLanguageSelection(languagesDataFromBE) {
    let selectedLanguageObjects =
      languagesDataFromBE && languagesDataFromBE.languages
        ? languagesDataFromBE.languages
        : DEFAULT_LANGUAGE_OPTIONS;
    let initialLanguageSelection = selectedLanguageObjects
      .filter(
        (currentLanguageOption) => currentLanguageOption.code.length === 2,
      )
      .map((currentLanguageOption) => currentLanguageOption.code);
    return initialLanguageSelection;
  }

  let initialLanguageSelection =
    initializeLanguageSelection(farmLanguagesDetails);
  const [farmLanguages, setFarmLanguages] = useState(initialLanguageSelection);

  useEffect(() => {
    let initialLanguageSelection =
      initializeLanguageSelection(farmLanguagesDetails);
    setFarmLanguages(initialLanguageSelection);
  }, [farmLanguagesDetails.languages]);

  const getInitialLanguage = () => {
    if (farmerPageDetails.languages) {
      const supportedLanguages = farmerPageDetails.languages.map(
        (lang) => lang.code,
      );
      const defLanguage =
        supportedLanguages.indexOf("en") > -1 ? "en" : supportedLanguages[0];
      const currentLanguage = getCurrentLanguage(
        defLanguage,
        supportedLanguages,
      );
      return currentLanguage;
    }
    return "";
  };

  const [currentLanguage, setCurrentLanguage] = useState(getInitialLanguage());
  //#endregion

  const [newFarmerDetails, setNewFarmerDetails] = useState(farmerPageDetails);
  const { isSaveChangesConfirmationPopupDisplayed } = useSelector((state) => ({
    ...state.saveChangesDetails,
  }));

  const handleSaveChangesButtonClick = () => {
    let textBlocksInBackendFormat = convertFEStoryBlocksToBE(
      textBlocks,
      languageKeysForAllLanguagesAvailable,
    );
    newFarmerDetails.storySlides = textBlocksInBackendFormat;
    let newLanguageDetails = convertLanguageSelectionToBE(
      farmLanguages,
      DEFAULT_LANGUAGE_OPTIONS,
    );
    dispatch(
      saveChangesToFarmerPage(
        accessToken,
        newFarmerDetails,
        newLanguageDetails,
      ),
    );
    setSaved(true);
  };

  const [isSaved, setIsSaved] = useState(true);
  const { setSaved, lastSaveTimestamp } = useSaveManager(
    handleSaveChangesButtonClick,
    isSaved,
    setIsSaved,
  );

  const [textBlocks, setTextBlocks] = useState({});

  useEffect(() => {
    dispatch(requestFarmerPageInformation(accessToken));
    dispatch(requestSelectedFarmLanguagesInformation(accessToken));
  }, []);

  useEffect(() => {
    setNewFarmerDetails(farmerPageDetails);
    let slides = convertBEStoryBlocksToFE(
      farmerPageDetails.storySlides,
      languageKeysForAllLanguagesAvailable,
    );
    setTextBlocks(slides);
    setCurrentLanguage(getInitialLanguage());
  }, [farmerPageDetails]);

  const modifyFarmerDetails = (key, value) => {
    setNewFarmerDetails({ ...newFarmerDetails, [key]: value });
    setSaved(false);
  };
  const modifyFarmerDetailsWithLanguage = (key, value) => {
    setNewFarmerDetails({
      ...newFarmerDetails,
      [key]: {
        ...newFarmerDetails[key],
        [currentLanguage]: value,
      },
    });
    setSaved(false);
  };
  return (
    <div className="AboutYouPage">
      <h3 className="about-you--header">
        {translate("ABOUT_YOU_PAGE_HEADING")}
      </h3>
      <p className="about-you--intro">
        {translate("ABOUT_YOU_PAGE_INTRO_TEXT")}
      </p>
      <LanguageSelection
        handleLanguagesChangedFn={(
          newLanguageCodesSelected,
          isManualChange,
        ) => {
          setFarmLanguages(newLanguageCodesSelected);
          if (isManualChange) {
            setSaved(false);
          }
        }}
        allAvailableLanguageCodesArray={languageKeysForAllLanguagesAvailable}
        selectedLanguageCodesArray={farmLanguages}
      />
      <div className="adminPanel--section">
        <h3 className="adminPanel--section--header">
          {translate("ABOUT_YOU_YOUR_PROFILE_HEADING")}
        </h3>
        <p className="adminPanel--section--subheading">
          {translate("ABOUT_YOU_YOUR_PROFILE_SUBHEADING")}
        </p>
        <LabelledTextInput
          initialValue={
            newFarmerDetails.farmerName &&
            newFarmerDetails.farmerName[currentLanguage]
          }
          labelText={translate("ABOUT_YOU_PAGE_FIRST_NAME_INPUT_LABEL")}
          handleChangeFn={(newFarmerName) =>
            modifyFarmerDetailsWithLanguage("farmerName", newFarmerName)
          }
          additionalClassName="about-you--textField"
        />
        <ImageRadioSelection
          componentLabel={translate("ABOUT_YOU_PAGE_STYLE_LABEL")}
          optionsArray={[
            {
              label: translate("ABOUT_YOU_PAGE_STYLE_VALUE_1_LABEL"),
              classNameAppendix: "style1",
              value: "Male",
            },
            {
              label: translate("ABOUT_YOU_PAGE_STYLE_VALUE_2_LABEL"),
              classNameAppendix: "style2",
              value: "Female",
            },
          ]}
          initialSelectedValue={newFarmerDetails.style}
          onSelectionChangeFn={(newSelectedValue) => {
            let modifiedFarmerDetailsCopy = _.cloneDeep(newFarmerDetails);
            modifiedFarmerDetailsCopy.style = newSelectedValue;
            setNewFarmerDetails(modifiedFarmerDetailsCopy);
            setSaved(false);
          }}
        />
        <LabelledTextInput
          initialValue={
            newFarmerDetails.favoriteCowName &&
            newFarmerDetails.favoriteCowName[currentLanguage]
          }
          labelText={translate("ABOUT_YOU_PAGE_FAVOURITE_COW_INPUT_LABEL")}
          handleChangeFn={(newFavoriteCowName) =>
            modifyFarmerDetailsWithLanguage(
              "favoriteCowName",
              newFavoriteCowName,
            )
          }
          additionalClassName="about-you--textField"
        />
        <MultiLanguageTextArea
          multiLanguageData={newFarmerDetails.personalQuote}
          fieldLabelTranslationKey={"ABOUT_YOU_PAGE_PERSONAL_QUOTE_INPUT_LABEL"}
          fieldPlaceholderTextTranslationKey={""}
          givenLength={200}
          handleDataChangedFn={(newPersonalQuoteMultiLanguageData) =>
            modifyFarmerDetails(
              "personalQuote",
              newPersonalQuoteMultiLanguageData,
            )
          }
          languageKeysArray={farmLanguages}
          additionalCssClassname="multiLanguageTextArea--personalQuote--textArea"
        />
        <MultiLanguageTextArea
          multiLanguageData={newFarmerDetails.visionQuote}
          fieldLabelTranslationKey={"ABOUT_YOU_PAGE_VISION_LABEL"}
          fieldPlaceholderTextTranslationKey={""}
          givenLength={200}
          handleDataChangedFn={(newVisionQuoteMultiLanguageData) =>
            modifyFarmerDetails("visionQuote", newVisionQuoteMultiLanguageData)
          }
          languageKeysArray={farmLanguages}
          additionalCssClassname="multiLanguageTextArea--visionQuote--textArea"
        />
        <LabelledTextInput
          initialValue={newFarmerDetails.webSiteURL}
          labelText={translate("ABOUT_YOU_PAGE_WEBSITE_LINK_LABEL")}
          handleChangeFn={(newWebSiteURL) =>
            modifyFarmerDetails("webSiteURL", newWebSiteURL)
          }
          additionalClassName="about-you--textField"
        />
        <LabelledImageDropZone
          initialValue={
            newFarmerDetails &&
            newFarmerDetails.imageData &&
            newFarmerDetails.imageData.all
          }
          key={
            newFarmerDetails &&
            newFarmerDetails.imageData &&
            newFarmerDetails.imageData.all
          }
          onChangeFileFn={(newFileBase64) => {
            let modifiedFarmDetailsCopy = _.cloneDeep(newFarmerDetails);
            if (modifiedFarmDetailsCopy) {
              modifiedFarmDetailsCopy.imageData.all = newFileBase64;
              setNewFarmerDetails(modifiedFarmDetailsCopy);
            }
            setSaved(false);
          }}
          onDeleteFileFn={() => {
            let modifiedFarmDetailsCopy = _.cloneDeep(newFarmerDetails);
            if (
              modifiedFarmDetailsCopy &&
              modifiedFarmDetailsCopy.imageData &&
              modifiedFarmDetailsCopy.imageData.all
            ) {
              modifiedFarmDetailsCopy.imageData.all = "";
              setNewFarmerDetails(modifiedFarmDetailsCopy);
            }
            setSaved(false);
          }}
          defaultPreviewCssClassname={`farmerImagePreview-${newFarmerDetails.style}`}
        />
      </div>
      {textBlocks ? (
        <StoryBlockList
          storyBlocksArray={textBlocks}
          handleStoryBlockListChangeFn={(newStoryBlocksArray) => {
            setTextBlocks(newStoryBlocksArray);
            setSaved(false);
          }}
          languageKeysArray={farmLanguages}
          maxNumberOfStoryBlocks={5}
        />
      ) : null}
      {isSaved && lastSaveTimestamp !== 0 ? (
        <Button
          className="btn-already-saved"
          disabled={false}
          onClick={() => {}}
        >
          {translate("ADMIN_PAGE_ALREADY_SAVED_CHANGES_BUTTON_LABEL")}
        </Button>
      ) : null}
      {!isSaved ? (
        <Button
          className="btn-save-changes"
          style={{ marginBottom: "5vh" }}
          disabled={false}
          onClick={handleSaveChangesButtonClick}
        >
          {translate("ABOUT_YOU_PAGE_SAVE_CHANGES_BUTTON_LABEL")}
        </Button>
      ) : null}
      {isSaveChangesConfirmationPopupDisplayed ? (
        <SaveChangesConfirmationDialog />
      ) : null}
    </div>
  );
}

export default AboutYouPage;
