import {
  REQUEST_SUSTAINABILITY_PAGE_INFORMATION,
  SAVE_CHANGES_TO_SUSTAINABILITY_PAGE,
  SAVE_CHANGES_TO_SUSTAINABILITY_PAGE_ERROR,
  SAVE_CHANGES_TO_SUSTAINABILITY_PAGE_SUCCESS,
  SUSTAINABILITY_PAGE_INFORMATION_ERROR,
  SUSTAINABILITY_PAGE_INFORMATION_SUCCESS,
} from "./types";

/** Create action which will request sustainability info for the selected farm in admin app
 * @param {string} accessToken - User access token
 * @return {Object} Returns action object
 */
export function requestSustainabilityPageInformation(accessToken) {
  return {
    type: REQUEST_SUSTAINABILITY_PAGE_INFORMATION,
    payload: {
      accessToken: accessToken,
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the sustainability info in the Admin app
 * @return {Object} Return type and farmerDetails
 */
export function getSustainabilityPageSuccess(farmSustainabilityDetails) {
  return {
    type: SUSTAINABILITY_PAGE_INFORMATION_SUCCESS,
    payload: farmSustainabilityDetails,
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getSustainabilityPageError(errorMessage) {
  return {
    type: SUSTAINABILITY_PAGE_INFORMATION_ERROR,
    payload: errorMessage,
  };
}

/** Create action which to be used whenever the user clicks the Save changes button in the Sustainability page in the Admin app
 * @param {string} accessToken - User access token
 * @param {Object} newSustainabilityDetails - The latest state of farmer details page (with all the latest changes that the user has made)
 * @param {Object} newLanguageDetails - The latest state of the language selection control which needs to be persisted to the languages services as soon as the other request has been carried (both requests can't happen at the same time as there is concurrency issue with the database access)
 * @return {Object} Returns action object
 */
export function saveChangesToSustainabilityPage(
  accessToken,
  newSustainabilityDetails,
  newLanguageDetails,
) {
  return {
    type: SAVE_CHANGES_TO_SUSTAINABILITY_PAGE,
    payload: {
      accessToken: accessToken,
      newSustainabilityDetails,
      newLanguageDetails: newLanguageDetails,
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of the Farmer page in the Admin app
 * @return {Object} Returns action object
 */
export function saveChangesToSustainabilityPageSuccess(newSustainabilityData) {
  return {
    type: SAVE_CHANGES_TO_SUSTAINABILITY_PAGE_SUCCESS,
    payload: newSustainabilityData,
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function saveChangesToSustainabilityPageError(errorMessage) {
  return {
    type: SAVE_CHANGES_TO_SUSTAINABILITY_PAGE_ERROR,
    payload: errorMessage,
  };
}
