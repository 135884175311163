import React from "react";
import {
  translate,
  translateDynamic,
} from "../../localization/TranslationUtil";
import { getDisableCacheAppendix } from "../../common/urlUtil";
import Tractor from "../Tractor/Tractor";
import IconButton from "../IconButton/IconButton";
import "./VehiclePopup.scss";

/** VehiclePopup component */
function VehiclePopup({ selectedVehicleDetails, closePopupClickFn }) {
  const vehiclePopupTitle = translate("TRACTOR_TITLE");

  return (
    <div className="vehiclePopup"> 
      <div className="vehiclePopup--container">
      <IconButton
        cssClassName="vehiclePopup--btnClosePopup"
        onClickFn={closePopupClickFn}
      />
        <div className="vehiclePopup--title">{vehiclePopupTitle}</div>
      <div className="vehiclePopup--description">
        {translateDynamic(selectedVehicleDetails.description)}
      </div>
      
      </div>
      
      <div className="vehiclePopup--image-wrapper">
        <div className="vehiclePopup--frame"></div>
        {selectedVehicleDetails.imageData ? (
          <img
            src={getDisableCacheAppendix(selectedVehicleDetails.imageData)}
            className="vehiclePopup--image"
          />
        ) : (
          <div className="vehiclePopup--noImage">
            {/* <Tractor
              lineColor={selectedVehicleDetails.lineColor}
              mainColor={selectedVehicleDetails.mainColor}
            /> */}
          </div>
        )}
      </div>
      
    </div>
  );
}
export default VehiclePopup;
