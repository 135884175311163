import React, { useEffect } from 'react';
import _, { valuesIn } from 'lodash';
import './CowDailyGrazingPage.scss';
import { useSelector } from 'react-redux';
import {
  translate,
  translateWithValues,
} from '../../localization/TranslationUtil';
import TextCaption from '../../components/TextCaption/TextCaption';
import moment from 'moment';
import Cow from '../../components/Cow/Cow';
import { COW_CSS_CLASSNAME } from '../../common/constants';
import { getGrazingDates } from '../../common/grazingDateUtil';

function CowDailyGrazingPage({
  showActorStoryFn,
  additionalCssClassname,
  cowDailyDetails,
  onNavigateToInitialSlideFn,
  cowGrazingData,
}) {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  useEffect(() => {
  }, [!_.isEmpty(selectedCowDetails)]);
  const comparedDate = moment(
    new Date(cowDailyDetails && cowDailyDetails.lastUpdated),
  ).format('D MMM YYYY');

  const isBetweenDate = moment(comparedDate).isBetween(
    cowGrazingData.dateFrom,
    cowGrazingData.dateTo,
  );
  const calculatedGrazingDays = getGrazingDates(
    cowGrazingData && cowGrazingData.dateTo,
    cowGrazingData && cowGrazingData.dateFrom,
  );

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div
        className={
          additionalCssClassname
            ? 'cow-daily--outro--slide ' + additionalCssClassname
            : 'cow-daily--outro--slide'
        }
      >
        <TextCaption additionalCssClassname="textCaption--cowDailyGrazing">
          <h3 className="textCaption--heading textCaption--heading--cowDailyGrazing"></h3>
          {isBetweenDate ? (
            <p className="cowDailyGrazingText">
              {translateWithValues('COW_GRAZING_DAYS', { 
                cowName: selectedCowDetails.name,
                cowGrazingDays: calculatedGrazingDays,
              })}
            </p>
          ) : (
            <p className="cowDailyGrazingText">
              {translateWithValues('COW_GRAZING_DAYS_IN_THE_BARN', { 
                cowName: selectedCowDetails.name,
                cowGrazingDays: calculatedGrazingDays,
              })}
            </p>
          )}
        </TextCaption>
        <Cow
          cowData={selectedCowDetails}
          cowCssClassname={COW_CSS_CLASSNAME}
          cowXCoordinate={40 + 'vw'}
          cowYCoordinate={0}
          cowScaleX={1}
          cowClickHandlerFn={(clickedCowData) => {}}
          additionalCssClassnames="cow--bigger cow--daily-intro"
          doPositionFixed={true}
        />
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowDailyGrazingPage;
