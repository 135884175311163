import { REQUEST_TELL_ME_MORE_PAGE_INFORMATION, SAVE_CHANGES_TO_TELL_ME_MORE_PAGE } from './types.js';
import { getTellMeMoreInformationSuccess, getTellMeMoreInformationError, saveChangesToTellMeMorePageSuccess, saveChangesToTellMeMorePageSuccessError } from './actions.js';
import { put, take, call } from 'redux-saga/effects';
import {
  startLoading,
  endLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';

let currentLoadId = 0;

export function* initalizeTellMeMorePageSaga(api) {
  while (true) {
    const tellMeMoreInformationRequestAction = yield take(REQUEST_TELL_ME_MORE_PAGE_INFORMATION);
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { accessToken } = tellMeMoreInformationRequestAction.payload;
    yield call(getTellMeMoreDetails, api, accessToken);
  }
}

/** Create request to get tell me more page latest state of data from the backend
 * @param {string} accessToken - User access token
 */
function* getTellMeMoreDetails(api, accessToken) {
  try {
    const tellMeMoreDetails = yield call(api.getFarmerStoryTextBlocks, accessToken);
    yield put(
      getTellMeMoreInformationSuccess(tellMeMoreDetails.data),
    );
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(getTellMeMoreInformationError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}


export function* tellMeMoreSaveChangesSaga(api) {
  while (true) {
    const saveChangesRequestAction = yield take(SAVE_CHANGES_TO_TELL_ME_MORE_PAGE);
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { accessToken, textBlocksData } = saveChangesRequestAction.payload;
    yield call(saveChangesToTellMeMorePage, api, accessToken, textBlocksData);
  }
}

/** Create request to get tell me more page latest state of data from the backend
 * @param {string} accessToken - User access token
 * @param {Object} textBlocksData - The latest state of the text blocks data coming directly from the frontend which we want to persist on the backend
 */
function* saveChangesToTellMeMorePage(api, accessToken, textBlocksData) {
  try {
    const updateTellMeMoreDataResponse = yield call(api.setFarmerStoryTextBlocks, accessToken, textBlocksData);
    yield put(
      saveChangesToTellMeMorePageSuccess(updateTellMeMoreDataResponse.data),
    );
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(saveChangesToTellMeMorePageSuccessError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}
