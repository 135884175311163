import React from "react";
import "./FourthSlideOrbiterYoghurt.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const FourthSlideOrbiterYoghurt = ({}) => {
  return (
    <div className="orbiterStoryFourthSlideYoghurt">
      <div className="orbiterStoryFourthSlideYoghurt--floor"></div>
      <TextCaption additionalCssClassname="orbiterStoryFourthSlideYoghurt--textCaption">
        <div className="orbiterStoryFourthSlideYoghurt--text">
          {translate("ORBITER_FOURTH_SLIDE_YOGHURT_TEXT")}
        </div>
      </TextCaption>
      <div className="orbiterStoryFourthSlideYoghurt--robot"></div>
      <div className="orbiterStoryFourthSlideYoghurt--pipe"></div>
      <div className="orbiterStoryFourthSlideYoghurt--secondPipe"></div>
      <div className="orbiterStoryFourthSlideYoghurt--temperature"></div>
      <div className="orbiterStoryFourthSlideYoghurt--temperatureText">86°c</div>
      <div className="orbiterStoryFourthSlideYoghurt--temperatureAfter"></div>
      <div className="orbiterStoryFourthSlideYoghurt--temperatureAfterText">72°c</div> 
      <div className="orbiterStoryFourthSlideYoghurt--temperatureContainerAdult">
        <div className="orbiterStoryFourthSlideYoghurt--temperatureContainerAdultText">86°c</div> 
      </div> 
      <div className="orbiterStoryFourthSlideYoghurt--temperatureContainerAdultBefore"></div>
      <div className="orbiterStoryFourthSlideYoghurt--milkywayPipe"></div>
      <div className="orbiterStoryFourthSlideYoghurt--arrow"></div>
    </div>
  );
};

export default FourthSlideOrbiterYoghurt;
