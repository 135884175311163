import axios from 'axios';
import moment from 'moment';
import { FILTER_POLL_ID, FILTER_SELECTED_NAME, GET_NAME_POLLS_SERVICE_URL } from './config';

export const MOCK_NAME_POLL_OBJECT_THAT_IS_ACTIVE = {
  startDate: moment(new Date()).subtract(1, 'days').toDate(),
  endDate: moment(new Date()).add(6, 'days').toDate(),
  chosenName: '',
  pollId: 'sample_poll_id',
  id: 'sample_poll_id',
  pollOptions:
  {
    'aukje': 20,
    'gerda': 60,
    'bleske': 20
  }
};

export const MOCK_NAME_POLL_OBJECT_THAT_IS_OVER = {
  startDate: moment(new Date()).subtract(8, 'days').toDate(),
  endDate: moment(new Date()).subtract(1, 'days').toDate(),
  chosenName: 'chosenName',
  pollId: 'sample_poll_id_2',
  id: 'sample_poll_id_2',
  pollOptions:
  {
    'jerry': 40,
    'tom': 30,
    'whatever': 30
  }
};

export const api = {
  voteForCowName: async function (pollId, cowNameOption) {
    /*
    return {
      data: {
        status: 'ok'
      }
    };
    */
    let voteForCowNameServiceUrl = GET_NAME_POLLS_SERVICE_URL + FILTER_POLL_ID + pollId + FILTER_SELECTED_NAME + cowNameOption;
    return axios.patch(voteForCowNameServiceUrl,
      {}
    );
  },
};
