import React from "react";
import "./SecondSlideOrbiter.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const SecondSlideOrbiter = ({}) => {
  return (
    <div className="orbiterStorySecondSlide">
      <div className="orbiterStorySecondSlide--background">
        <TextCaption additionalCssClassname="orbiterStorySecondSlide--textCaption">
          <div className="orbiterStorySecondSlide--text">
            {translate("ORBITER_STORY_SECOND_SLIDE_TEXT")}
          </div>
        </TextCaption>
        <div className="orbiterStorySecondSlide--robot"></div>
        <div className="orbiterStorySecondSlide--pipe"></div>
        <div className="orbiterStorySecondSlide--tree"></div>
      </div>
    </div>
  );
};

export default SecondSlideOrbiter;
