import React, { useEffect } from "react";
import _ from "lodash";
import "./CowDailyGrazeWayPage.scss";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "../../components/IconButton/IconButton";
import { ACTOR_TYPE_GRAZEWAY } from "../../common/constants";
import { showPopup } from "../../common/components/PopupContainer/actions";

function CowDailyGrazeWayPage({ additionalCssClassname, farmFeaturesDetails }) {
  const dispatch = useDispatch();
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  useEffect(() => {}, [!_.isEmpty(selectedCowDetails)]);
  const isGrazeWaySelected = farmFeaturesDetails.find(({ type }) =>
    type === "grazeway" ? type : "",
  );

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div
        className={
          additionalCssClassname
            ? "cow-daily--outro--slide " + additionalCssClassname
            : "cow-daily--outro--slide"
        }
      >
        {isGrazeWaySelected ? (
          <div
            className="cow-daily--robot--grazeway"
            onClick={() => {
              dispatch(
                showPopup({ type: ACTOR_TYPE_GRAZEWAY, type: "grazeway" }),
              );
            }}
          ></div>
        ) : null}
        {isGrazeWaySelected ? (
          <IconButton
            cssClassName="cowDailyGrazeway--button"
            onClickFn={() => {
              dispatch(
                showPopup({ type: ACTOR_TYPE_GRAZEWAY, type: "grazeway" }),
              );
            }}
          />
        ) : null}
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowDailyGrazeWayPage;
