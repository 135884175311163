import {
  GET_ASTRONAUT_DETAILS_REQUEST_ERROR,
  GET_ASTRONAUT_DETAILS_REQUEST_SUCCESS,
  RESET_ASTRONAUT_DETAILS,
} from "../components/AstronautPopup/types";

/** Store states
 * @constant {Object} */
export const initialState = {
  astronautDetails: {},
  isAstronautDetailsError: false,
  errorMessage: "",
};

/** Astronaut Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function astronautReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ASTRONAUT_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        astronautDetails: action.payload,
        isAstronautDetailsError: false,
        errorMessage: "",
      };

    case GET_ASTRONAUT_DETAILS_REQUEST_ERROR:
      return {
        ...state,
        astronautDetails: {},
        isAstronautDetailsError: true,
        errorMessage: action.payload,
      };

    case RESET_ASTRONAUT_DETAILS:
      return {
        ...state,
        astronautDetails: {},
        isAstronautDetailsError: false,
        errorMessage: "",
      };

    default:
      return state;
  }
}

export default astronautReducer;
