import _ from "lodash";
import React, { useEffect, useState } from "react";
import "./CowDailyBlankPage.scss";
import { useSelector } from "react-redux";
//
function CowDailyBlankPage({ additionalCssClassname, farmFeaturesDetails }) {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  useEffect(() => {}, [!_.isEmpty(selectedCowDetails)]);

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div
        className={
          additionalCssClassname
            ? "cow-daily--eating--slide " + additionalCssClassname
            : "cow-daily--eating--slide"
        }
      ></div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowDailyBlankPage;
