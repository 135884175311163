import React from "react";
import "./FifthSlideOrbiter.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const FifthSlideOrbiter = ({}) => {
  return (
    <div className="orbiterStoryFifthSlide">
      <div className="orbiterStoryFifthSlide--floor"></div>
      <TextCaption additionalCssClassname="orbiterStoryFifthSlide--textCaption">
        <div className="orbiterStoryFifthSlide--text">
          {translate("ORBITER_STORY_FIFTH_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="orbiterStoryFifthSlide--robot"></div>
      <div className="orbiterStoryFifthSlide--table"></div>
      <div className="orbiterStoryFifthSlide--tableRightPart"></div>
      <div className="orbiterStoryFifthSlide--middlePipe"></div>
      <div className="orbiterStoryFifthSlide--milkBottles"></div> 
      <div className="orbiterStoryFifthSlide--temperature"></div>
      <div className="orbiterStoryFifthSlide--pipe"></div>
      <div className="orbiterStoryFifthSlide--arrow"></div> 
      <div className="orbiterStoryFifthSlide--arrowText"></div> 
      <div className="orbiterStoryFifthSlide--arrowTextBottom">{translate('ORBITER_STORY_FIFTH_SLIDE_BOTTOM_TEXT')}</div>
    </div>
  );
};

export default FifthSlideOrbiter;
