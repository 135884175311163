import _ from "lodash";
import React, { useEffect, useState } from "react";
import "./CowDailyMilkingPage.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import IconButton from "../../components/IconButton/IconButton";
import moment from "moment";
import { showPopup } from "../../common/components/PopupContainer/actions";
import { ACTOR_TYPE_ASTRONAUT } from "../../common/constants";

function CowDailyMilkingPage({ additionalCssClassname, milkingData }) {
  const dispatch = useDispatch();
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div
        className={
          additionalCssClassname
            ? "cow-daily--milking--slide " + additionalCssClassname
            : "cow-daily--milking--slide"
        }
      >
        <TextCaption additionalCssClassname="textCaption--cowDailyMilking">
          <h3 className="textCaption--heading textCaption--heading--milking">
            {moment(milkingData && milkingData.created)
              .utc()
              .format("DD MMMM YYYY, HH:mm")}
          </h3>
          <p className="cowMilkingText">
            {translateWithValues("COW_DAILY_MILKING_TEXT", {
              cowName : selectedCowDetails?.name 
            })}
          </p>
        </TextCaption>
        <div
          className="cow-daily--robot--astronaut"
          onClick={() => {
            dispatch(
              showPopup({ type: ACTOR_TYPE_ASTRONAUT, name: "astronaut" }),
            );
          }}
        ></div>
        <IconButton
          cssClassName="cowDailyAstronaut--button"
          onClickFn={() => {
            dispatch(
              showPopup({ type: ACTOR_TYPE_ASTRONAUT, name: "astronaut" }),
            );
          }}
        />
        <div className="cow-daily--astronaut--arrowText">
          <span className="cow-daily--astronaut--arrowDown"></span>
          <span
            className="cow-daily--astronaut--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml(
                "COW_DAILY_THIS_IS_ASTRONAUT_TEXT",
              ),
            }}
          ></span>
        </div>
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowDailyMilkingPage;
