import React from "react";
import "./FourthSlideAstronaut.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const FourthSlideAstronaut = ({}) => {
  return (
    <div className="astronautStoryFourthSlide">
      <TextCaption additionalCssClassname="astronautStoryFourthSlide--textCaption">
        <div className="astronautStoryFourthSlide--title">
          {translate("ASTRONAUT_TITLE_FOURTH_SLIDE")}
        </div>
        <div className="astronautStoryFourthSlide--text">
          {translate("ASTRONAUT_TEXT_FOURTH_SLIDE")}
        </div>
      </TextCaption>
      <div className="astronautStoryFourthSlide--imgCaption">
        <div className="astronautStoryFourthSlide--imgContainer"></div>
      </div> 
      <div className="astronautStoryFourthSlide--transitionTree"></div>
    </div>
  );
};

export default FourthSlideAstronaut;
