import { sanitize } from "dompurify";
import React, { useState, useEffect } from "react";

// Styles
import "./LabelledTextArea.scss";

/** LabelledTextArea component.
 * @param {Object} obj - Object which contains all props.
 * @param {string} [obj.tooltip] - Text for tooltip
 * @param {string} [obj.placeholder] - Search placeholder
 * @param {string} [obj.dataTestID] - Data test ID
 * @param {string} [obj.labelText] - Label for the text input
 * @param {string} [obj.initialValue] - Initial value for the text area
 * @param {Function} [obj.handleChangeFn] - Function that will be called whenever the text area value has changed
 * @param {string} [obj.additionalClassName] - Adds additional css class name
 * @param {Number} [obj.givenLength] - Sets the length of the text
 * @param {String} [obj.placeholderText] - The placeholder text value for the text area component inside
 * @param {Boolean} [obj.isDisabled] - Sets if the text area is disabled or not(editable)
 */
function LabelledTextArea({
  tooltip,
  placeholder,
  dataTestID,
  labelText,
  initialValue,
  handleChangeFn,
  additionalClassName,
  givenLength,
  placeholderText,
  isDisabled,
}) {
  initialValue = sanitize(initialValue);
  givenLength = givenLength ? givenLength : 250;
  const [value, setValue] = useState(initialValue);
  const [borderStyle, setBorderStyle] = useState("1px solid #cfcbc3");

  useEffect(() => {
    if (value && value.length > 0) {
      setBorderStyle("1px solid #cfcbc3");
    } else {
      setBorderStyle("1px solid #FF0000");
    }
  }, [value]);

  useEffect(() => {
    setValue(initialValue || "");
  }, [initialValue]);

  /**
   * @param {Object} event - Contains current value of input filed.
   */
  function handleChange(event) {
    let sanitizedValue = sanitize(event.target.value);
    setValue(sanitizedValue);
    handleChangeFn(sanitizedValue);
  }

  return (
    <div className="labelled-text-area">
      <label className="labelled-text-area--label">{labelText}</label>
      <textarea
        value={value}
        maxLength={givenLength}
        onChange={handleChange}
        style={{ border: borderStyle }}
        data-testid={dataTestID}
        disabled={isDisabled}
        placeholder={placeholderText || ""}
        className={`labelled-text-area--textArea${
          additionalClassName ? " " + additionalClassName : ""
        }`}
      />
      <span className="labelled-text-area--charactersLeft">
        {givenLength - (value ? value.length : 0)}
      </span>
    </div>
  );
}

export default LabelledTextArea;
