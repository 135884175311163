import React from 'react';
import CallToActionButton from '../CallToActionButton/CallToActionButton';
import IconButton from '../IconButton/IconButton';
import { translate } from '../../localization/TranslationUtil';
import Graphic from '../Graphic/Graphic';
import './DairyPopup.scss'

function DairyPopup({closePopupClickFn, isAdultSelected}) {
    return (
      <div className="dairyPopup">
        <div className='dairyPopup--container'>
        <IconButton
          cssClassName="dairyPopup--btnClosePopup"
          onClickFn={closePopupClickFn}
        />
         <div className="dairyPopup--title">
          {translate('DAIRY_ROBOT_TITLE')}
        </div>
        <div className="dairyPopup--description">
          {translate('DAIRY_ROBOT_DESCRIPTION')}
        </div>
        </div>
       
        {/* <CallToActionButton
          cssClassName="dairyPopup--btnCallToAction"
          isAdultSelected = {isAdultSelected}
          label={translate('VECTOR_BUTTON_TEXT')}
          onClickFn={() => {
          }}
        /> */}
        <Graphic cssClassName="dairyPopup--dairy" />
        
      </div>
    );
  }
  
  export default DairyPopup;