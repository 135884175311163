import React from "react";
import { translate } from "../../localization/TranslationUtil";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import Graphic from "../Graphic/Graphic";
import IconButton from "../IconButton/IconButton";
import LabelValue from "../LabelValue/LabelValue";
import "./BrandPopup.scss";

/** BrandPopup component */
function BrandPopup({
  selectedBrandDetails,
  callToActionClickFn,
  closePopupClickFn,
  isAdultSelected,
}) {
  const brandPopupTitle =
    translate("FARMER_POPUP_TITLE_PREFIX") + selectedBrandDetails.name;
  return (
    <div className="brandPopup">
      <div className="brandPopup--title">{brandPopupTitle}</div>
      <div className="brandPopup--description">
        {translate("BRAND_LELY_DESCRIPTION")}
      </div>
      <CallToActionButton
        cssClassName=" brandPopup--btnCallToAction"
        isAdultSelected={isAdultSelected}
        label={translate("MILK_FACTORY_POPUP_SEE_HISTORY_BUTTON_LABEL")}
        onClickFn={() => {
          callToActionClickFn();
          closePopupClickFn();
        }}
      />
      <Graphic cssClassName="brandPopup--logo"></Graphic>
      <IconButton
        cssClassName="brandPopup--btnClosePopup"
        onClickFn={closePopupClickFn}
      />
    </div>
  );
}
export default BrandPopup;
