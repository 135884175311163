import _ from 'lodash';
import { HANDLE_ACTOR_POSITIONED, RESET_ACTOR_POSITIONS } from '../components/Actor/types.js';

/** Store states
 * @constant {Object} */
export const initialState = {
  actorPositions: {}
};

/** Actor Position Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function actorPositionReducer(state = initialState, action) {
  switch (action.type) {
    case HANDLE_ACTOR_POSITIONED:
      let modifiedState = _.cloneDeep(state);
      let actorId = action.payload.actorId;
      let actorPosition = action.payload.actorPosition;
      modifiedState.actorPositions[actorId] = actorPosition;
      return modifiedState;
    case RESET_ACTOR_POSITIONS:
      return {
        ...state,
        actorPositions: {}
      };
    default:
      return state;
  }
}

export default actorPositionReducer;
