import {
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_REQUEST_ERROR,
  ACTIVATE_ACCOUNT_REQUEST_SUCCESS,
} from "./types";

/** Action which is going to lead to the making of a request to the backend
 * for the activation of the user by a means of a specific activationToken
 * @param {string} activationToken - Special token which the backend recognizes and leads to the activation of the new user
 * @param {string} customerId - Additional parameter necessary in order to be able to activate new user accounts
 * @param {string} userId - One more additional parameter necessary in order to be able to activate new user accounts
 * @param {string} licenseKey - The license key using which successful user registration has been established
 */
export function activateUser(activationToken, customerId, userId, licenseKey) {
  return {
    type: ACTIVATE_ACCOUNT_REQUEST,
    payload: {
      activationToken: activationToken,
      customerId,
      userId,
      licenseKey,
    },
  };
}

/** On successfully being able to activate the new user on the backend
 */
export function activateUserSuccess() {
  return {
    type: ACTIVATE_ACCOUNT_REQUEST_SUCCESS,
    payload: {},
  };
}

/** On the case of an error happened while attempting to activate the new user
 * using the supplied activationToken
 * @param {string} errorMessage - Error message
 */
export function activateUserError(errorMessage) {
  return {
    type: ACTIVATE_ACCOUNT_REQUEST_ERROR,
    payload: errorMessage,
  };
}
