import React from "react";
import {
  translate,
  translateDynamic,
} from "../../localization/TranslationUtil";
import "./SamsungPopup.scss";

/** SamsungPopup component */
function SamsungPopup({}) {
  let samsungPopupTitle = translate("SAMSUNG_PHONE_MESSAGE");
  return (
    <div className="samsungPopup">
      <p className="samsungPopup--title">{samsungPopupTitle}</p>
    </div>
  );
}
export default SamsungPopup;
