import { ACTIVATE_ACCOUNT_REQUEST } from "./types.js";
import { activateUserSuccess, activateUserError } from "./actions.js";
import { put, take, call } from "redux-saga/effects";
import { redirect } from "../../CustomRedirect/actions.js";
import { getServerResponseStatus } from "../../ErrorPopUp/actions";
import {
  startLoading,
  endLoading,
  startFarmDataSeeding,
  endFarmDataSeeding,
} from "../../../common/redux/loadManager/actions";
import { getLoadId } from "../../../common/redux/loadManager/methods";

let currentLoadId = 0;

export default function* activateUserSaga(api) {
  while (true) {
    const activateUserRequest = yield take(ACTIVATE_ACCOUNT_REQUEST);
    if (activateUserRequest.payload) {
      /** Run loader */
      currentLoadId = getLoadId();
      yield put(startLoading(currentLoadId));
      const { activationToken, customerId, userId, licenseKey } =
        activateUserRequest.payload;
      yield call(
        activateUser,
        api,
        activationToken,
        customerId,
        userId,
        licenseKey,
      );
    }
  }
}

/** Create activate user request
 * @param {object} api - API providing access to the Lely backend
 * @param {string} activationToken - A set of characters that if valid will activate the new user account
 * @param {string} customerId - Additional parameter necessary in order to be able to activate new user accounts
 * @param {string} userId - One more additional parameter necessary in order to be able to activate new user accounts
 * @param {string} licenseKey - The license key using which successful user registration has been established
 */
function* activateUser(api, activationToken, customerId, userId, licenseKey) {
  var response;
  try {
    yield put(startFarmDataSeeding());
    response = yield call(
      api.activateAccount,
      activationToken,
      customerId,
      userId,
    );
    //response = yield call(api.seedFarmData, licenseKey); // no longer necessary as seeding is done on the backend without the frontend having to make a request to initiate this operation
    yield put(endFarmDataSeeding());
    yield put(activateUserSuccess());
    yield put(getServerResponseStatus(""));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    yield put(activateUserError(error));
    yield put(endFarmDataSeeding());
    /** Stop loader */
    yield put(endLoading(currentLoadId));
    /** Listen for error state to show when server is disconected */
    yield put(getServerResponseStatus(error));
  }
}
