import React from "react";
import "./SecondSlideVector.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const SecondSlideVector = ({}) => {
  return (
    <div className="vectorStorySecondSlide">
      <TextCaption additionalCssClassname="vectorStorySecondSlide--textCaption">
        <div className="vectorStorySecondSlide--title">
          {translate("VECTOR_HISTORY_TITLE")}
        </div>
        <div className="vectorStorySecondSlide--text">
          {translate("VECTOR_HISTORY_TEXT")}
        </div>
      </TextCaption>
      <div className="vectorStorySecondSlide--imgCaption">
        <div className="vectorStorySecondSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default SecondSlideVector;
