import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
import { getCheeseFactorySvgFilePath } from "../../common/svgUtil";
import Graphic from "../Graphic/Graphic";
import React, { useCallback } from "react";
import "./CheeseFactory.scss";
import { measureResponsive } from "../../components/VirtualFarm/util";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";

/** CheeseFactory component */
function CheeseFactory({
  cheeseFactoryData,
  cheeseFactoryCssClassname,
  cheeseFactoryXCoordinate,
  cheeseFactoryYCoordinate,
  cheeseFactoryScaleX,
  cheeseFactoryClickHandlerFn,
  additionalCssClassnames,
  farmTheme,
  doPositionFixed,
  isInteractable,
  isClickable,
  isAdultSelected,
}) {
  let transformValue =
    "translateX(" +
    cheeseFactoryXCoordinate +
    ") translateY(" +
    cheeseFactoryYCoordinate +
    ") scaleX(" +
    cheeseFactoryScaleX +
    ")";
  let cheeseFactoryPositionStyles = doPositionFixed
    ? {}
    : {
        transform: transformValue,
      };
  let cheeseFactorySubmoduleCssClassname =
    cheeseFactoryCssClassname + " " + additionalCssClassnames;
  cheeseFactorySubmoduleCssClassname += isInteractable
    ? " virtualFarm--interactable virtualFarm--highlighted"
    : "";
  cheeseFactorySubmoduleCssClassname = isClickable
    ? cheeseFactorySubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : cheeseFactorySubmoduleCssClassname;
  let cheeseFactorySvgPath = getCheeseFactorySvgFilePath(farmTheme);
  const onCompletedCallback = useCallback((e) => {}, []);
  const onErrorCallback = useCallback((e) => {
    console.log("@onError e:", e);
  }, []);
  return (
    <div
      style={{
        left: measureResponsive(64.193),
        width: measureResponsive(11.5455),
        top: measureResponsive(20.78205),
        height: measureResponsive(10.16011),
      }}
      className={cheeseFactorySubmoduleCssClassname}
      onClick={() => {
        cheeseFactoryClickHandlerFn(cheeseFactoryData);
      }}
    >
      <h2
        style={{
          transform: "scaleX(" + cheeseFactoryScaleX + ")",
        }}
        className={cheeseFactoryCssClassname + "--title"}
      >
        {cheeseFactoryData.name}
      </h2>
      {isInteractable ? (
        <DynamicInlineSVG
          svgFullFilePath={cheeseFactorySvgPath}
          themeName={farmTheme}
          onCompleted={onCompletedCallback}
          onError={onErrorCallback}
        />
      ) : null}
      {isInteractable ? <Graphic cssClassName="is-actor-pointed" /> : null}
    </div>
  );
}
export default CheeseFactory;
