import React from 'react';
import './Graphic.scss';

/** Graphic component */
function Graphic({cssClassName}) {
  let graphicCssClassName = "graphic " + cssClassName;
  return (
    <div className={graphicCssClassName}>
    </div>
  );
}
export default Graphic;
