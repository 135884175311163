var latestSwipeDeltaX;
export const setLatestSwipeDeltaX = function (newLatestSwipeDeltaX) {
  latestSwipeDeltaX = newLatestSwipeDeltaX;
};

export const getLatestSwipeDeltaX = function () {
  return latestSwipeDeltaX;
};

var latestFlowTranslateX;
export const setLatestFlowTranslateX = function (newLatestFlowTranslateX) {
  latestFlowTranslateX = newLatestFlowTranslateX;
};

export const getLatestFlowTranslateX = function () {
  return latestFlowTranslateX;
};

var latestSwipeInProgress;
export const setIsSwipeInProgress = function (newSwipeInProgressValue) {
  latestSwipeInProgress = newSwipeInProgressValue;
};

export const getIsSwipeInProgress = function () {
  return latestSwipeInProgress;
};

var areSlidesContainerTransitionsDisabled;
export const setAreSlidesContainerTransitionsDisabled = function (
  newAreSlidesContainerTransitionsDisabledValue,
) {
  areSlidesContainerTransitionsDisabled =
    newAreSlidesContainerTransitionsDisabledValue;
};

export const getAreSlidesContainerTransitionsDisabled = function () {
  return areSlidesContainerTransitionsDisabled;
};

var slideIndexToTransitionTo;
export const setSlideIndexToTransitionTo = function (
  newSlideIndexToTransitionTo,
) {
  slideIndexToTransitionTo = newSlideIndexToTransitionTo;
};

export const getSlideIndexToTransitionTo = function () {
  return slideIndexToTransitionTo;
};

var currentlyActiveSlideIndex;
export const setCurrentlyActiveSlideIndex = function (
  newCurrentlyActiveSlideIndex,
) {
  currentlyActiveSlideIndex = newCurrentlyActiveSlideIndex;
};

export const getCurrentlyActiveSlideIndex = function () {
  return currentlyActiveSlideIndex;
};

var isOnboardingOver = false;
export const setIsOnboardingOver = function (newIsOnboardingOver) {
  console.log("@setIsOnboardingOver newIsOnboardingOver:", newIsOnboardingOver);
  isOnboardingOver = newIsOnboardingOver;
};

export const getIsOnboardingOver = function () {
  return isOnboardingOver;
};
