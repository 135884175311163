import React from "react";
import { useSelector } from "react-redux";
import "./Sky.scss";
import moment from "moment";
import { getSkyOpacityNumberByCurrentMoment } from "./SkyOpacityUtil";

/** Sky component */
function Sky({
  viewportWidthInPx,
  viewportHeightInPx,
  cssClassName,
  isAdultThemeApplied,
}) {
  let { currentMoment, sunrise, sunset } = useSelector(
    (state) => state.storyMoment,
  );
  currentMoment = moment(currentMoment);
  let sunriseHour =
    sunrise && sunrise.split(":")[0] != "null" ? sunrise.split(":")[0] : "06";
  let sunriseMinutes =
    sunrise && sunrise.split(":")[1] != "null" ? sunrise.split(":")[1] : "00";
  let sunsetHour =
    sunset && sunset.split(":")[0] != "null" ? sunset.split(":")[0] : "22";
  let sunsetMinutes =
    sunset && sunset.split(":")[1] != "null" ? sunset.split(":")[1] : "00";

  let skyOpacity = getSkyOpacityNumberByCurrentMoment(
    currentMoment,
    sunriseHour,
    sunriseMinutes,
    sunsetHour,
    sunsetMinutes,
  );

  let skyContainerCssClassname = "sky--container";
  skyContainerCssClassname = isAdultThemeApplied
    ? skyContainerCssClassname + " sky--container--adult"
    : skyContainerCssClassname;

  return (
    <div
      className={skyContainerCssClassname}
      style={{
        opacity: skyOpacity,
      }}
    ></div>
  );
}
export default Sky;
