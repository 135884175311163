import React from "react";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";
import Tractor from "../Tractor/Tractor";
import TractorLeft from "../TractorLeft/TractorLeft";
import "./Vehicle.scss";

/** Vehicle component */
function Vehicle({
  vehicleData,
  farmTheme,
  vehicleCssClassname,
  vehicleClickHandlerFn,
  hasShop,
  isClickable,
  isAdultSelected,
}) {
  return (
    <div
      className={
        isClickable
          ? vehicleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
          : vehicleCssClassname
      }
      onClick={() => {
        vehicleClickHandlerFn(vehicleData);
      }}
    >
      {hasShop ? (
        <TractorLeft
          farmTheme={farmTheme}
          lineColor={vehicleData.lineColor}
          mainColor={vehicleData.mainColor}
          isAdultSelected={isAdultSelected}
        />
      ) : (
        <Tractor
          farmTheme={farmTheme}
          lineColor={vehicleData.lineColor}
          mainColor={vehicleData.mainColor}
          isAdultSelected={isAdultSelected}
        />
      )}
    </div>
  );
}
export default Vehicle;
