import { translate } from "../../../localization/TranslationUtil";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import "./LanguageSelection.scss";
import StoryLanguageSelector from "./StoryLanguageSelector";

/** LanguageSelection component.
 * @param {Object} obj - Object which contains all props.
 * @param {Array} obj.selectedLanguageCodesArray - An array of language codes to be selected by default
 * @param {Array} obj.allAvailableLanguageCodesArray - An array of all available language codes
 * @param {Function} obj.handleLanguagesChangedFn - Function that will be called when the language selection has been changed
 */
function LanguageSelection({
  selectedLanguageCodesArray,
  allAvailableLanguageCodesArray,
  handleLanguagesChangedFn,
}) {
  const [selectedLanguageKeysArray, setSelectedLanguageKeysArray] = useState(
    selectedLanguageCodesArray,
  );
  useEffect(() => {
    setSelectedLanguageKeysArray(selectedLanguageCodesArray);
  }, [selectedLanguageCodesArray]);
  return (
    <div className="languageSelection--container adminPanel--section">
      <h3 className="languageSelection--header adminPanel--section--header">
        {translate("LANGUAGE_SELECTION_HEADING")}
      </h3>
      <p className="languageSelection--intro adminPanel--section--subheading">
        {translate("LANGUAGE_SELECTION_INTRO_TEXT")}
      </p>
      <StoryLanguageSelector
        selectedLanguageKeys={selectedLanguageKeysArray}
        handleLanguagesChangedFn={(newSelectedLanguagesKeys) => {
          setSelectedLanguageKeysArray(newSelectedLanguagesKeys);
          const isManualChange = true;
          handleLanguagesChangedFn(newSelectedLanguagesKeys, isManualChange);
        }}
        allAvailableLanguageCodes={allAvailableLanguageCodesArray}
      />
    </div>
  );
}

export default LanguageSelection;
