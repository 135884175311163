import {
  ABOUT_THE_FARM_PAGE_INFORMATION_ERROR,
  ABOUT_THE_FARM_PAGE_INFORMATION_SUCCESS,
  SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE_SUCCESS,
  SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE_ERROR,
} from '../containers/Admin/AboutTheFarmPage/types.js';

/** Store states
 * @constant {Object} */
export const initialState = {
  aboutTheFarmDetails: {},
  errorMessage: '',
  isLatestSaveSuccessful: false,
  saveChangesErrorMessage: '',
};

/** Tell me more page reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function aboutTheFarmReducer(state = initialState, action) {
  switch (action.type) {
    case ABOUT_THE_FARM_PAGE_INFORMATION_SUCCESS:
      return {
        ...state,
        aboutTheFarmDetails: action.payload,
        errorMessage: '',
      };
    case ABOUT_THE_FARM_PAGE_INFORMATION_ERROR:
      return {
        ...state,
        aboutTheFarmDetails: [],
        errorMessage: action.payload,
      };
    case SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE_SUCCESS:
      return {
        ...state,
        isLatestSaveSuccessful: true,
        saveChangesErrorMessage: '',
      };
    case SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE_ERROR:
      return {
        ...state,
        isLatestSaveSuccessful: false,
        saveChangesErrorMessage: action.payload,
      };

    default:
      return state;
  }
}

export default aboutTheFarmReducer;
