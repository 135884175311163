import React from "react";
import "./SecondSlideMijnMelk.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const SecondSlideMijnMelk = ({}) => {
  return (
    <div className="mijnMelkSecondSlide">
      <div className="mijnMelkSecondSlide--floor"></div>
      <TextCaption additionalCssClassname="mijnMelkSecondSlide--textCaption">
        <div className="mijnMelkSecondSlide--title">
          {translate("MIJN_MELK_SECOND_SLIDE_TITLE")}
        </div>
        <div className="mijnMelkSecondSlide--text">
          {translate("MIJN_MELK_SECOND_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="mijnMelkSecondSlide--imgCaption">
        <div className="mijnMelkSecondSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default SecondSlideMijnMelk;
