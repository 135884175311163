import React from "react";
import "./AccountActivatedPage.scss";
import UserManagementContainer from "../../../components/UserManagement/UserManagementContainer/UserManagementContainer";
import AccountActivated from "../../../components/UserManagement/AccountActivated/AccountActivated";

function AccountActivatedPage() {
  return (
    <UserManagementContainer>
      <AccountActivated></AccountActivated>
    </UserManagementContainer>
  );
}

export default AccountActivatedPage;
