import _ from "lodash";
import React, { useEffect, useState } from "react";
import { translate } from "../../localization/TranslationUtil";
import TimePickerInput from "../../components/TimePickerInput/TimePickerInput";
import CheckboxGroupSelection from "../../common/components/CheckboxGroupSelection/CheckboxGroupSelection";

function WorkingHoursEditor({ initialWorkingTimes, onWorkingHoursChangedFn }) {
  const [workingTimes, setWorkingTimes] = useState(initialWorkingTimes || []);

  // resolves problem with hitting Refresh immediately after saving
  useEffect(() => {
    if (!_.isEqual(initialWorkingTimes, workingTimes)) {
      setWorkingTimes(initialWorkingTimes || []);
    }
  }, [initialWorkingTimes]);

  useEffect(() => {
    let daysWithInvalidWorkingTime = workingTimes.filter((dayObject) => {
      if (dayObject.isClosed) {
        return false;
      }
      return (
        (dayObject && !dayObject.hourFrom) ||
        (dayObject && !dayObject.hourTo) ||
        dayObject.hourFrom >= dayObject.hourTo
      );
    });
    const isThereInvalidWorkingTimeErrorInWorkingHours =
      daysWithInvalidWorkingTime.length > 0;
    typeof onWorkingHoursChangedFn === "function" &&
      onWorkingHoursChangedFn(
        workingTimes,
        isThereInvalidWorkingTimeErrorInWorkingHours,
      );
  }, [workingTimes]);

  const handleChangeTime = (field, time, day) => {
    let modifiedWorkingTimes = _.cloneDeep(workingTimes);
    let selectedDayFromWorkingTimes = modifiedWorkingTimes.find(
      (o) => o.day === day,
    );
    selectedDayFromWorkingTimes[field] = time;
    setWorkingTimes(modifiedWorkingTimes);
  };

  const renderWorkingTime = (day) => {
    let selectedDayFromWorkingTimes = workingTimes.find((o) => o.day === day);
    return selectedDayFromWorkingTimes &&
      !selectedDayFromWorkingTimes.isClosed ? (
      <div className="about-the-farm--working-time--inputs-container">
        <TimePickerInput
          labelText={translate("FROM")}
          initialValue={selectedDayFromWorkingTimes.hourFrom}
          handleChangeFn={(newWorkingTime) =>
            handleChangeTime("hourFrom", newWorkingTime, day)
          }
          invalid={
            selectedDayFromWorkingTimes.hourTo &&
            selectedDayFromWorkingTimes.hourFrom >
              selectedDayFromWorkingTimes.hourTo
          }
        />
        <TimePickerInput
          labelText={translate("TO")}
          initialValue={selectedDayFromWorkingTimes.hourTo}
          handleChangeFn={(newWorkingTime) =>
            handleChangeTime("hourTo", newWorkingTime, day)
          }
          invalid={
            selectedDayFromWorkingTimes.hourFrom &&
            selectedDayFromWorkingTimes.hourTo <
              selectedDayFromWorkingTimes.hourFrom
          }
        />
      </div>
    ) : (
      <div>{translate("CLOSED")}</div>
    );
  };

  return (
    <div className="about-the-farm--working-time">
      <CheckboxGroupSelection
        componentLabel={translate("ABOUT_THE_FARM_SHOP_WORKING_TIME_LABEL")}
        optionsArray={[
          {
            label: (
              <div className="about-the-farm--working-time--option">
                <div>{translate("MONDAY")}</div>
                {renderWorkingTime("monday")}
              </div>
            ),
            value: "monday",
          },
          {
            label: (
              <div className="about-the-farm--working-time--option">
                <div>{translate("TUESDAY")}</div>
                {renderWorkingTime("tuesday")}
              </div>
            ),
            value: "tuesday",
          },
          {
            label: (
              <div className="about-the-farm--working-time--option">
                <div>{translate("WEDNESDAY")}</div>
                {renderWorkingTime("wednesday")}
              </div>
            ),
            value: "wednesday",
          },
          {
            label: (
              <div className="about-the-farm--working-time--option">
                <div>{translate("THURSDAY")}</div>
                {renderWorkingTime("thursday")}
              </div>
            ),
            value: "thursday",
          },
          {
            label: (
              <div className="about-the-farm--working-time--option">
                <div>{translate("FRIDAY")}</div>
                {renderWorkingTime("friday")}
              </div>
            ),
            value: "friday",
          },
          {
            label: (
              <div className="about-the-farm--working-time--option">
                <div>{translate("SATURDAY")}</div>
                {renderWorkingTime("saturday")}
              </div>
            ),
            value: "saturday",
          },
          {
            label: (
              <div className="about-the-farm--working-time--option">
                <div>{translate("SUNDAY")}</div>
                {renderWorkingTime("sunday")}
              </div>
            ),
            value: "sunday",
          },
        ]}
        selectedOptions={
          workingTimes &&
          workingTimes.filter((day) => day.isClosed === false).map((o) => o.day)
        }
        onSelectionChange={(newSelectedValue, isChecked) => {
          let modifiedWorkingTimes = _.cloneDeep(workingTimes);
          let selectedDayInWorkingTimes = modifiedWorkingTimes.find(
            (o) => o.day === newSelectedValue,
          );

          if (isChecked) {
            if (selectedDayInWorkingTimes) {
              selectedDayInWorkingTimes.isClosed = false;
            } else {
              modifiedWorkingTimes.push({
                day: newSelectedValue,
                hourFrom: "00:00",
                hourTo: "00:00",
                isClosed: false,
              });
            }
          } else {
            if (selectedDayInWorkingTimes) {
              selectedDayInWorkingTimes.isClosed = true;
            }
          }
          setWorkingTimes(modifiedWorkingTimes);
        }}
      />
    </div>
  );
}

export default WorkingHoursEditor;
