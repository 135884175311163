import React from "react";
import { translate } from "../../localization/TranslationUtil";
import "./UnavailablePage.scss";

function UnavailablePage({}) {
  return (
    <div className="UnavailablePage">
      <div additionalcssclassname="UnavailablePage--textCaption">
        <p className="UnavailavlePage--fourOfour--message">404</p>
        <p className="UnavailavlePage--message">
          {translate("UNAVAILABLE_PAGE_MESSAGE")}
        </p>
        <p className="UnavailavlePage--second--message">
          {translate("UNAVAILABLE_PAGE_SECOND_MESSAGE")}
        </p>
      </div>
      <div className="unavailablePage--buttons">
        <a
          className="unavailablePage--homepage--btnCallToAction"
          href="https://www.mijnmelk.nl/"
        >
          {translate("UNAVAILABLE_PAGE_BACK_HOMEPAGE_BUTTON_LABEL")}
        </a>
        <a
          href="mailto:info@mijnboerderij.mijnmelk.nl?subject=404 Page not found"
          className="unavailablePage--contactUs--btnCallToAction"
        >
          {translate("UNAVAILABLE_PAGE_CONTACT_US_BUTTON_LABEL")}
        </a>
      </div>
    </div>
  );
}

export default UnavailablePage;
