import _ from "lodash";
import React, { useEffect } from "react";
import "./CowBirthdayPage.scss";
import TextCaption from "../../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";
import {
  translate,
  translateDynamic,
  translateWithValuesAndHtml,
} from "../../../localization/TranslationUtil";
import moment from "moment-timezone";

const CowBirthdayPage = React.memo(() => {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const shouldAddCalmSlide =
    farmDetails.otherDevices.filter((currentDevice) =>
      currentDevice.type.includes("calm"),
    ).length > 0;

  useEffect(() => {}, [!_.isEmpty(selectedCowDetails)]);

  const farmTextboxName = () =>
    translateDynamic(farmDetails.farmName) == null
      ? "farm."
      : translateDynamic(farmDetails.farmName) + ".";
  const birthdayDate = new Date(selectedCowDetails.birthTimestamp);
  birthdayDate.setFullYear(birthdayDate.getFullYear());
  const getTranslatedDay = (weather) => {
    if (weather == "normal") {
      return translate("NORMAL");
    } else if (weather == "cold") {
      return translate("COLD");
    } else if (weather == "warm") {
      return translate("HOT");
    } else {
      return translate("NORMAL");
    }
  };
  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div className="cowBirthdayPage">
        <div className="cow-cowBirthday--calendar">
          <span className="cow-cowBirthday--calendarMonth">
            {moment(birthdayDate).format("MMMM")}
          </span>
          <span className="cow-cowBirthday--calendarDay">
            {moment(birthdayDate).format("DD")}
          </span>
        </div>
        <TextCaption additionalCssClassname="textCaption--cowBirthdayPage--year">
          {moment(birthdayDate).format("YYYY")}
        </TextCaption>
        <div className="cow-cowBirthdayPage--temperature"></div>
        <TextCaption additionalCssClassname="cow-cowBirthdayPage--temperature--degrees">
          {selectedCowDetails.temperatureAtBirth}°c{" "}
        </TextCaption>
        <TextCaption additionalCssClassname="textCaption--cowBirthdayPage">
          <span
            className="cow-cowBirthdayPage--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml("COW_BIRTHDAY_INFO", {
                weatherDay: getTranslatedDay(
                  selectedCowDetails.temperatureAtBirthDefinition,
                ),
                cowName : selectedCowDetails.name
              }),
            }}
          ></span>
          <span className="cow-cowBirthdayPage--farmName">
            {farmTextboxName()}
          </span>
        </TextCaption>
        <div className="cow-cowBirthdayPage--leftTreeAdultVersion"></div>
        {!shouldAddCalmSlide ? (
          <div className="cow-cowBirthdayPage--tree"></div>
        ) : null}
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
});

export default CowBirthdayPage;
