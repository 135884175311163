import React from "react";
import "./SixthSlideOrbiter.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const SixthSlideOrbiter = ({}) => {
  return (
    <div className="orbiterStorySixthSlide">
      <div className="orbiterStorySixthSlide--floor"></div>
      <TextCaption additionalCssClassname="orbiterStorySixthSlide--textCaption">
        <div className="orbiterStorySixthSlide--text">
          {translate("ORBITER_STORY_SIXTH_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="orbiterStorySixthSlide--robot"></div>
      <div className="orbiterStorySixthSlide--table"></div>
      <div className="orbiterStorySixthSlide--tree"></div>
      <div className="orbiterStorySixthSlide--pipe"></div>
      <div className="orbiterStorySixthSlide--arrow"></div>
    </div>
  );
};

export default SixthSlideOrbiter;
