import React from "react";
import "./SecondSlideDiscovery.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const SecondSlideDiscovery = ({ otherDevices }) => {
  const filteredDevices = otherDevices.filter((currentDevice) =>
    currentDevice.type.includes("discovery"),
  );
  const robotCssImgContainerClassName =
    "discoveryStorySecondSlide--" +
    "" +
    "" +
    "imgContainer" +
    "" +
    "" +
    filteredDevices.length;
  console.log("filteredDevices", robotCssImgContainerClassName);
  return (
    <div className="discoveryStorySecondSlide">
      <TextCaption additionalCssClassname="lelyStorySecondSlide--textCaption">
        <div className="discoveryStorySecondSlide--title">
          {filteredDevices.length
            ? translate("DISCOVERY_STORY_WHAT_IS_TITLE")
            : translate("DISCOVERY_COLLECTOR_WHAT_IS_TITLE")}
        </div>
        <div className="discoveryStorySecondSlide--text">
          {filteredDevices.length
            ? translate("DISCOVERY_STORY_WHAT_IS_TEXT")
            : translate("DISCOVERY_COLLECTOR_WHAT_IS_TEXT")}
        </div>
      </TextCaption>
      <div className="discoveryStory--bottomColorOverlay"></div>
      <div className="discoveryStorySecondSlide--imgCaption">
        <div className={robotCssImgContainerClassName}></div>
      </div>
    </div>
  );
};

export default SecondSlideDiscovery;
