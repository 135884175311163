import React from "react";
import { useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import copy from "copy-to-clipboard";
// Styles
import "./ShareButtonDialog.scss";

/** ConfirmationDialog component.
 * @param {Object} obj - Object which contains all props.
 * @param {string} [obj.dialogTitle] - The title of the ConfirmationDialog
 * @param {string} [obj.dialogText] - The text of the ConfirmationDialog
 * @param {string} [obj.confirmationButtonLabelText] - The label text of the confirmation button in the ConfirmationDialog
 * @param {string} [obj.cancelButtonLabelText] - The label text of the cancel button in the ConfirmationDialog
 * @param {string} [obj.popupCssClassname] - Additional CSS class to apply to the ConfirmationDialog
 * @param {Function} [obj.onConfirmCallbackFn] - A callback function to be called upon clicking the Confirm button of the dialog
 * @param {Function} [obj.onCancelCallbackFn] - A callback function to be called upon cancelling or closing the confirmation dialog
 * @param {Function} [obj.onCloseCallbackFn] - Explicit callback function for the scenario in which the user clicks the X button at the top right corner of the dialog. If not specified explicitly, onCancelCallbackFn will be executed instead.
 * @param {string} [obj.cssClassname] - Specific CSS class to use on ConfirmationDialog parent element
 * @param {string} [obj.farmId] - The ID of the farm for this specific farmer user
 */
function ShareButtonDialog({
  dialogText,
  onCancelCallbackFn,
  onCloseCallbackFn,
  cssClassname,
  farmId,
}) {
  const confirmationDialogContainerCssClassname =
    cssClassname || "confirmationDialogContainer";

  const farmerSite = "https://mijnboerderij.mijnmelk.nl/?f=" + farmId;

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    copy(farmerSite.toString());
    setCopied(true);
  };

  const downloadQRcode = () => {
    const farmUrlQRCodeImage = document.getElementById("qrCodeImage");
    const pngUrl = farmUrlQRCodeImage
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = farmId + "-QRCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className={confirmationDialogContainerCssClassname}>
      <div className="shareButtonDialog">
        <div className="shareButtonDialog--header">
          <i className="shareButton--dialog"></i>
          <div className="shareButtonDialog--text">Share</div>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="close"
            onClick={onCloseCallbackFn || onCancelCallbackFn}
          >
            <i class="lely-icon-close"></i>
          </button>
        </div>
        <div className="confirmationDialog--body">
          <p>{dialogText}</p>
        </div>

        <div className="websiteURL--text">Website URL</div>
        <div className="website--text--box">{farmerSite}</div>

        <button
          className="copyToClipboard--button"
          type="button"
          onClick={copyToClipboard}
        ></button>
        {copied ? (
          <div className="copiedMessage--body">
            <div className="copiedMessage--mark"></div>
            <div className="copiedMessage--text">Copied successfully</div>
          </div>
        ) : null}

        <div className="websiteQRcode--text">Website QR-code</div>
        <div className="QRcode--box">
          <QRCodeCanvas
            value={farmerSite}
            id="qrCodeImage"
            width={160}
            height={160}
            size={160}
          />
        </div>

        <div className="sharePopup--footer--buttons">
          <button
            className="previewWebsite--button"
            type="button"
            onClick={() => window.open(farmerSite)}
          >
            <div className="previewWebsite--image"></div>
            <div className="previewWebsite--button--text">Preview website</div>
          </button>
          <button
            className="downloadQRcode--button"
            type="button"
            onClick={downloadQRcode}
          >
            <div className="downloadQRcode--button--text">Download QR-code</div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ShareButtonDialog;
