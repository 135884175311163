import "./HudDisplay.scss";
import React, { useRef } from "react";
import moment from "moment";
import MilkBatchWeatherIcon from "../../../components/MijnMelkOnboardingAdult/MilkBatchWeatherIcon";
import { getCowNameByAllCowsList } from "../../../components/Cow/CowNamesUtil";
import HudDisplayMessagesContainer from "./HudDisplayMessagesContainer";
import {
  translate,
  translateWithValues,
} from "../../../localization/TranslationUtil";
import {
  HUD_MESSAGE_TYPE_NORMAL_LOG,
  HUD_MESSAGE_TYPE_SPECIAL_LOG,
} from "../../constants";
import uuid from "react-uuid";
import { getWeatherDataByFarmDetails } from "../../../components/Weather/WeatherUtil";
import Graphic from "../../../components/Graphic/Graphic";

/** HudDisplay component */
function HudDisplay({
  hudDetails,
  milkingsLast24Hours,
  allCowsList,
  farmLocation,
}) {
  const latestCowMilkedSpanRef = useRef(null);
  if (!hudDetails.farmLocationWeatherData) {
    return null;
  }
  const currentFarmTime = hudDetails.dateTime;
  const userFriendlyFarmTime =
    hudDetails.dateTime.substr(8, 2) +
    "-" +
    hudDetails.dateTime.substr(5, 2) +
    "-" +
    hudDetails.dateTime.substr(0, 4) +
    " " +
    hudDetails.dateTime.substr(0,0);

  const currentHourWeatherDetails = getWeatherDataByFarmDetails(hudDetails);
  console.log(
    "@HudDisplay currentHourWeatherDetails:",
    currentHourWeatherDetails,
  );

  const getHudDisplayMessagesArrayByMilkingNotifications = (
    milkingNotifications,
  ) => {
    if (!milkingNotifications || milkingNotifications.length === 0) {
      return [];
    }
    let hudDisplayMessagesArray = [
      {
        messageText: translateWithValues("HUD_MESSAGE_INCOMING_DATA", {
          dateTime: milkingNotifications[0].milkingStart.substr(11, 8),
        }),
        messageType: HUD_MESSAGE_TYPE_SPECIAL_LOG,
        messageKey: uuid(),
      },
    ];

    milkingNotifications.forEach((currentMilkingNotificationData) => {
      const currentCowMilkedName = getCowNameByAllCowsList(
        allCowsList,
        currentMilkingNotificationData.cowId,
      );
      console.log(
        "currentMilkingNotificationData.milkingStart:",
        currentMilkingNotificationData.milkingStart,
      );
      hudDisplayMessagesArray.push({
        messageText: translateWithValues("HUD_MESSAGE_COW_MILKED", {
          dateTime: currentMilkingNotificationData.milkingStart.substr(11, 8),
          cowName: currentCowMilkedName,
        }),
        messageType: HUD_MESSAGE_TYPE_NORMAL_LOG,
        messageKey: uuid(),
        cowName: currentCowMilkedName,
      });
      hudDisplayMessagesArray.push({
        messageText: translateWithValues("HUD_MESSAGE_COW_MILK_YIELD", {
          dateTime: currentMilkingNotificationData.milkingEnd.substr(11, 8),
          milkYield: currentMilkingNotificationData.milkYield,
          milkDestination: currentMilkingNotificationData.destinationTank,
        }),
        messageType: HUD_MESSAGE_TYPE_NORMAL_LOG,
        messageKey: uuid(),
      });
    });
    return hudDisplayMessagesArray;
  };

  const currentHudDisplayMessagesArray =
    getHudDisplayMessagesArrayByMilkingNotifications(
      hudDetails.milkingNotifications,
    );

  return (
    <div className="hudDisplay--box">
      <div className="hudDisplay--box--topArea">
        <div className="hudDisplay--box--weatherIconContainer">
          <MilkBatchWeatherIcon
            weatherData={currentHourWeatherDetails}
            currentTimeInFarm={currentFarmTime}
          />
        </div>
        <div className="hudDisplay--box--weatherParametersContainer">
          <h3 className="hudDisplay--box--farmDate">{userFriendlyFarmTime}</h3>
          <div className="hudDisplay--box--farmLocation">
            <span className="hudDisplay--box--icon hudDisplay--box--farmLocation--icon" />
            {farmLocation}
          </div>
          <div className="hudDisplay--box--secondRowTempHumidity">
            {currentHourWeatherDetails.temp ? (
              <div className="hudDisplay--box--farmTemperature">
                <span className="hudDisplay--box--icon hudDisplay--box--farmTemperature--icon" />
                {currentHourWeatherDetails.temp}°c
              </div>
            ) : null}

            {typeof currentHourWeatherDetails.precip !== "undefined" ? (
              <div className="hudDisplay--box--farmHumidity">
                <span className="hudDisplay--box--icon hudDisplay--box--farmHumidity--icon" />
                {currentHourWeatherDetails.precip}mm
              </div>
            ) : null}
          </div>
          <div className="hudDisplay--box--numberOfCowsMilked">
            <span className="hudDisplay--box--icon hudDisplay--box--numberOfCowsMilked--icon" />
            <span ref={latestCowMilkedSpanRef} />
          </div>
        </div>
      </div>
      <Graphic cssClassName="liveDataPopup--liveIcon" />
      
      <div className="hudDisplay--box--separator"></div>
      <div className="hudDisplay--box--logsContainer">
        <HudDisplayMessagesContainer
          messagesArray={currentHudDisplayMessagesArray}
          onNewCowMilkedFn={(newMilkedCowName) => {
            if (latestCowMilkedSpanRef.current) {
              latestCowMilkedSpanRef.current.textContent = translateWithValues(
                "HUD_COW_LAST_MILKED_LABEL",
                {
                  cowName: newMilkedCowName,
                },
              );
            }
          }}
        />
      </div>
    </div>
  );
}
export default HudDisplay;
