import React from "react";
import BackgroundLayer from "../../BackgroundLayer/BackgroundLayer";
import { getBackgroundLayerTranslateXInPx } from "../parallax_util";
import "./ParallaxCowLatest.scss";
import WindmillGenerator from "../../Windmills/WindmillGenerator";

function ParallaxCowLatest({
  numberOfSlides,
  translateX,
  startX,
  endX,
  slideWidthInPx,
}) {
  const BACKGROUND_LAYER_MOUNTAIN_MOVEMENT_AMOUNT = 100;
  const BACKGROUND_LAYER_HILLS_1_MOVEMENT_AMOUNT = 300;
  const BACKGROUND_LAYER_HILLS_2_MOVEMENT_AMOUNT = 300;
  const BACKGROUND_LAYER_FORREST_1_MOVEMENT_AMOUNT = 500;
  const BACKGROUND_LAYER_FORREST_2_MOVEMENT_AMOUNT = 600;
  const BACKGROUND_LAYER_WINDMILLS_MOVEMENT_AMOUNT = endX - startX;
  const BACKGROUND_LAYER_GRASS_MOVEMENT_AMOUNT = 2000;

  if (translateX < startX || translateX > endX) {
    return null;
  }

  return (
    <div className="parallax-cow-latest">
      {/*<BackgroundLayer backgroundClassNameVarietiesArray={[
      'background-mountain1',
      'background-mountain2',
      'background-mountain3',
      'background-mountain4',
      'background-mountain5'
    ]}
      slideWidthInPx={slideWidthInPx}
      xPosition={`${getBackgroundLayerTranslateXInPx(translateX, BACKGROUND_LAYER_MOUNTAIN_MOVEMENT_AMOUNT, startX, endX)}px`}
      slidesCount={numberOfSlides}
      customCssClassName="background-layer--mountains"
  />*/}
      <BackgroundLayer
        backgroundClassNameVarietiesArray={[
          "background-hills11",
          "background-hills12",
          "background-hills13",
          "background-hills14",
          "background-hills15",
        ]}
        slideWidthInPx={slideWidthInPx}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_HILLS_1_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--hills1"
      />
      <BackgroundLayer
        backgroundClassNameVarietiesArray={[]}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_WINDMILLS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--windmills"
      >
        <WindmillGenerator
          numberOfSlides={numberOfSlides}
          slideWidthInPx={slideWidthInPx}
        />
      </BackgroundLayer>
      <BackgroundLayer
        backgroundClassNameVarietiesArray={[
          "background-hills21",
          "background-hills22",
          "background-hills23",
          "background-hills24",
          "background-hills25",
        ]}
        slideWidthInPx={slideWidthInPx}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_HILLS_2_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--hills2"
      />
      <BackgroundLayer
        backgroundClassNameVarietiesArray={[
          "background-forrest1",
          "background-forrest2",
          "background-forrest3",
          "background-forrest4",
          "background-forrest5",
        ]}
        slideWidthInPx={slideWidthInPx}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_FORREST_1_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--forrest1"
      />
      <BackgroundLayer
        backgroundClassNameVarietiesArray={[
          "background-forrest21",
          "background-forrest22",
          "background-forrest23",
          "background-forrest24",
          "background-forrest25",
        ]}
        slideWidthInPx={slideWidthInPx}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_FORREST_2_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--forrest2"
      />
      <BackgroundLayer
        backgroundClassNameVarietiesArray={[
          "background-grass1",
          "background-grass2",
          "background-grass3",
          "background-grass4",
          "background-grass5",
        ]}
        slideWidthInPx={slideWidthInPx}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_GRASS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--grass"
      />
    </div>
  );
}

export default ParallaxCowLatest;
