import React, { useRef } from "react";
import {
  translate,
  translateNumber,
  translateWithValues,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import "./SecondSlideSustainability.scss";
import ReferenceValueChart from "../../components/ReferenceValueChart/ReferenceValueChart";
import {
  SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MAX_COLOR,
  SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MID_COLOR,
  SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MIN_COLOR,
  SUSTAINABILITY_CHART_FOR_ADULTS_STROKE_WIDTH,
} from "../../common/constants";
import { translateDynamic } from "../../localization/TranslationUtil";
import useWindowDimensions from "../../hooks/useViewportDimensions";

const SecondSlideSustainability = ({ farmDetails }) => {
  //#region initialize viewport width value in order to be able to calculate properly the size of the sustainability diagram
  let { width: VIEWPORT_WIDTH_IN_PX, height: VIEWPORT_HEIGHT_IN_PX } =
    useWindowDimensions();
  const viewportWidthInPxRef = useRef(VIEWPORT_WIDTH_IN_PX);
  const viewportHeightInPxRef = useRef(VIEWPORT_HEIGHT_IN_PX);
  let isAppOpenedInLandscapeMode = VIEWPORT_WIDTH_IN_PX > VIEWPORT_HEIGHT_IN_PX;
  VIEWPORT_WIDTH_IN_PX = isAppOpenedInLandscapeMode
    ? VIEWPORT_WIDTH_IN_PX
    : VIEWPORT_HEIGHT_IN_PX;
  VIEWPORT_HEIGHT_IN_PX = isAppOpenedInLandscapeMode
    ? VIEWPORT_HEIGHT_IN_PX
    : VIEWPORT_WIDTH_IN_PX;
  viewportWidthInPxRef.current = VIEWPORT_WIDTH_IN_PX;
  viewportHeightInPxRef.current = VIEWPORT_HEIGHT_IN_PX;
  const diagramContainerWidthInPixels = 0.2 * viewportWidthInPxRef.current;
  const diagramRadiusInPixels = (1 / 3) * diagramContainerWidthInPixels;
  const diagramStrokeSizeInPixels = 0.02 * diagramContainerWidthInPixels;
  //#endregion

  const farmerName = translateDynamic(farmDetails?.farmer?.name);
  const farmName = translateDynamic(farmDetails?.farmName);
  const carbonDioxideStory = translateDynamic(
    farmDetails?.sustainabilityData?.carbonDioxideStory,
  );
  let carbonDioxideKilogramsPerLiterOfMilkValue = (farmDetails?.sustainabilityData?.carbonDioxideKilogramsPerLiterOfMilk == null) ? '0' : farmDetails?.sustainabilityData?.carbonDioxideKilogramsPerLiterOfMilk;
  return (
    <div className="sustainabilityStorySecondSlide">
      <ReferenceValueChart
        currentValue={
          carbonDioxideKilogramsPerLiterOfMilkValue
        }
        referenceValue={translateNumber(
          "SUSTAINABILITY_KG_CO2_PER_KILO_MILK_RANGE_REFERENCE",
        )}
        rangeMinValue={translateNumber(
          "SUSTAINABILITY_KG_CO2_PER_KILO_MILK_RANGE_MIN",
        )}
        rangeMaxValue={translateNumber(
          "SUSTAINABILITY_KG_CO2_PER_KILO_MILK_RANGE_MAX",
        )}
        rangeMinColor={SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MIN_COLOR}
        rangeMidColor={SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MID_COLOR}
        rangeMaxColor={SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MAX_COLOR}
        unitText={translate("SUSTAINABILITY_KG_CO2_PER_KILO_MILK_UNIT")}
        labelText={translate(
          "SUSTAINABILITY_KG_CO2_PER_KILO_MILK_DIAGRAM_LABEL",
        )}
        referenceLabelText={translate("SUSTAINABILITY_AVG_FARM_TEXT")}
        currentLabelText={farmName}
        diagramContainerWidthInPixels={diagramContainerWidthInPixels}
        diagramContainerHeightInPixels={diagramContainerWidthInPixels}
        diagramRadiusInPixels={diagramRadiusInPixels}
        gradientMeterStrokeWidthInPixels={diagramStrokeSizeInPixels}
        cssClassname={"kilogramCO2PerKiloMilkChart"}
      />
      <TextCaption additionalCssClassname="sustainabilityStorySecondSlide--textCaption">
        <div className="sustainabilityStorySecondSlide-title">
          {translate("SUSTAINABILITY_TITLE_SECOND_SLIDE")}
        </div>
        <div className="sustainabilityStorySecondSlide--text">
          {translate("SUSTAINABILITY_TEXT_SECOND_SLIDE")}
        </div>
        {carbonDioxideStory ? (
          <div>
            <div className="sustainabilityStorySecondSlide-farmer-title">
              {translateWithValues("SUSTAINABILITY_FARMER_NAME_TITLE", {
                farmerName,
              })}
            </div>
            <div className="sustainabilityStorySecondSlideFarmer--text">
              {carbonDioxideStory}
            </div>
          </div>
        ) : null}
        <a
          className="sustainabilityStorySecondSlide--link"
          href="https://www.nzo.nl/wp-content/uploads/2020/01/NZO-Factsheet-Broeikasgassen-in-de-Nederlandse-zuivelsector-jan-2020.pdf"
          target="_blank"
        >
          {translate("SUSTAINABILITY_SECOND_SLIDE_LINK")}
        </a>
      </TextCaption>
      <div className="sustainabilityStory-treeAndSmallBush"></div>
    </div>
  );
};

export default SecondSlideSustainability;
