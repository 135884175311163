import {
  HANDLE_COW_SELECTED,
  SHOW_COW_EXCREMENT,
  HIDE_COW_EXCREMENT,
} from "./types";

/** Create action to store the data for clicked cow
 * @param {Object} selectedCowData - The object containing the data for the currently selected cow
 * @return {Object} Returns action object
 */
export function selectCow(selectedCowData) {
  return {
    type: HANDLE_COW_SELECTED,
    payload: selectedCowData,
  };
}

/** Create action to show excrement for specific cow
 * @param {String} cowId - ID of the cow
 * @return {Object} Returns action object
 */
export function showExcrementForCow(cowId) {
  return {
    type: SHOW_COW_EXCREMENT,
    payload: {
      cowId: cowId,
    },
  };
}

/** Create action to remove excrement for specific cow
 * @param {String} cowId - ID of the cow
 * @return {Object} Returns action object
 */
export function removeExcrementForCow(cowId) {
  return {
    type: HIDE_COW_EXCREMENT,
    payload: {
      cowId: cowId,
    },
  };
}
