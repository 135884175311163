import { RESET_ACTOR_POSITIONS } from "../Actor/types";
import {
  GET_VIRTUAL_FARM_DETAILS_REQUEST,
  GET_VIRTUAL_FARM_DETAILS_REQUEST_SUCCESS,
  GET_VIRTUAL_FARM_DETAILS_REQUEST_ERROR,
  AGE_SELECTED,
  MARK_FIRST_VIRTUAL_FARM_TOUR_COMPLETE,
  UPDATE_COW_POLL_INFO,
  RESET_AGE_SELECTION,
} from "./types";

/** Create action which get token and farmId.
 * @param {string} farmId - Farm id
 * @param {string} batchId - Batch id
 * @return {Object} Return type, accessToken and farmId
 */
export function getVirtualFarmDetails(farmId, batchId) {
  return {
    type: GET_VIRTUAL_FARM_DETAILS_REQUEST,
    payload: {
      farmId: farmId,
      batchId: batchId,
    },
  };
}

/** Create action which to cause the farm details to be stored in the Redux store
 * @param {Object} farmDetails
 * @return {Object} Return type and farmDetails
 */
export function getVirtualFarmDetailsSuccess(farmDetails) {
  return {
    type: GET_VIRTUAL_FARM_DETAILS_REQUEST_SUCCESS,
    payload: { ...farmDetails },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getVirtualFarmDetailsError(errorMessage) {
  return {
    type: GET_VIRTUAL_FARM_DETAILS_REQUEST_ERROR,
    payload: errorMessage,
  };
}

/** Create action which will update the poll information for specific cow from the virtual farm
 * @param {Object} newPollDetails - new poll info about the cow (with the updated votes immediately after voting for the cow name)
 * @param {String} cowExternalId - the external id of the cow for whose name the user is voting
 * @return {Object} Return type and errorMessage
 */
export function updatePollInfoForCowWithId(newPollDetails, cowExternalId) {
  return {
    type: UPDATE_COW_POLL_INFO,
    payload: {
      newPollDetails: newPollDetails,
      cowExternalId: cowExternalId,
    },
  };
}
/** Create action which to force the update the farm theme based on selected age
 * @param {boolean} isAdultSelected
 * @return {Object} Return type and farmDetails
 */
export function updateFarmThemeBasedOnSelectedAge(isAdultSelected) {
  return {
    type: AGE_SELECTED,
    payload: isAdultSelected,
  };
}

/** Create action which resets the selected age/farm theme as if the user never chose one
 * @param {boolean} isAdultSelected
 * @return {Object} Return type and farmDetails
 */
export function resetAgeSelection(isAdultSelected) {
  return {
    type: RESET_AGE_SELECTION,
    payload: {},
  };
}
