import React from 'react'

/** Add margin and padding to components inside him
   * @param {Object} props - Passed params like props
   * @param {Object} props.children - The components / jsx
   * @param {String} [props.className] - The components class
   * @param {String} [props.testId] - Data-testId which is needed for testing the UI
   */
export default function SpacingWrapper({children, className, testId}) {
    return (
        <div data-testid={testId} className={className} style={{margin:"10px 10px 10px 10px", padding:"10px 10px 10px 10px"}}>
            {children}
        </div>
    )
}