import _ from "lodash";
import React, { useEffect } from "react";
import "./CowDailyRuminationPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { translate, translateWithValuesAndHtml } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import IconButton from "../../components/IconButton/IconButton";
import moment from "moment";
import { showPopup } from "../../common/components/PopupContainer/actions";
import { ACTOR_TYPE_RUMINATION } from "../../common/constants";

function CowDailyRuminationPage({ additionalCssClassname, cowDailyDetails }) {
  const dispatch = useDispatch();
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  function getHumanReadableRestingMinutes(restingMinutes) {
    let valueToBeDisplayed = "";
    let valueAppendix = "";
    if (restingMinutes > 59) {
      valueToBeDisplayed = Math.ceil(restingMinutes / 60) + " ";
      valueAppendix = translate("HOURS_APPENDIX");
    } else {
      valueToBeDisplayed = restingMinutes;
      valueAppendix = translate("MINUTES_APPENDIX");
    }
    return valueToBeDisplayed + valueAppendix;
  }
  useEffect(() => {
  }, [!_.isEmpty(selectedCowDetails)]);
  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div
        className={
          additionalCssClassname
            ? "cow-daily--rumination--slide " + additionalCssClassname
            : "cow-daily--rumination--slide"
        }
      >
        <TextCaption additionalCssClassname="cowDailyRumination">
          <div className="cowDailyRumination--date">
            {cowDailyDetails &&
              moment(new Date(cowDailyDetails.lastUpdated))
                .utc()
                .format("DD MMMM YYYY, HH:mm")
                .toUpperCase()}
          </div>
          <p
            className="cowDailyRumination--cowRuminationText"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml("COW_DAILY_RUMINATION_TEXT", {
                cowName : selectedCowDetails.name
              }),
            }}
          ></p>
        </TextCaption>
        <div
          className="cow-daily--rumination-cow"
          onClick={() => {
            dispatch(showPopup({ type: ACTOR_TYPE_RUMINATION }));
          }}
        >
          <IconButton
            cssClassName="cowDailyRumination--button"
            onClickFn={() => {
              dispatch(showPopup({ type: ACTOR_TYPE_RUMINATION }));
            }}
          />
        </div>
        <div className="cow-daily--rumination--arrowText">
          <span className="cow-daily--rumination--arrow"></span>
          <span
            className="cow-daily--rumination--text">{translateWithValuesAndHtml("COW_DAILY_RUMINATION_ARROW_TEXT", {restingDuration: getHumanReadableRestingMinutes(
              cowDailyDetails && cowDailyDetails.ruminationMinutes,
            )})}
          </span>
        </div>
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowDailyRuminationPage;
