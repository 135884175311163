import React, { useEffect } from 'react';
import _ from 'lodash';
import { translate } from '../../../localization/TranslationUtil';
import LabelledTextInput from '../../../common/components/LabelledTextInput/LabelledTextInput';
import CheckboxGroupSelection from '../../../common/components/CheckboxGroupSelection/CheckboxGroupSelection';
import { Grid } from '@material-ui/core';
import { OPTIONS_PER_COW_BREED } from './optionsPerCowBreed';
import './BreedColorSelector.scss';

/** BreedColorSelector component.
 * @param {Object} obj - Object which contains all props.
 * @param {Object} [obj.cowBreedData] - Object containing data for current cow breed
 * @param {Function} [obj.onCowBreedChangeFn] - Function that will be called whenever any of the text block properties has been changed by any of the underlying components
 */
function BreedColorSelector({ cowBreedData, onCowBreedChangeFn }) {
  useEffect(() => {
  }, [cowBreedData]);

  const getOptionsByCowBreedName = (cowBreedName) => {
    return OPTIONS_PER_COW_BREED[cowBreedName];
  };
  return (
    <div className="CowBreedBlock">
      {cowBreedData ? (
        <>
          <Grid xs={12} item>
            <CheckboxGroupSelection
              optionsArray={getOptionsByCowBreedName(cowBreedData?.name)}
              onSelectionChange={(newSelectedValue, isChecked) => {
                let newCowBreedData = _.cloneDeep(cowBreedData);
                newCowBreedData.colors = newCowBreedData.colors.map(
                  (currentBreedColor) => {
                    if (
                      currentBreedColor.name.toLowerCase() === newSelectedValue
                    ) {
                      currentBreedColor.isSelected = isChecked;
                    }
                    return currentBreedColor;
                  },
                );
                onCowBreedChangeFn(newCowBreedData);
              }}
              selectedOptions={cowBreedData?.colors
                .filter((currentCowBreedColor) =>
                  JSON.parse(currentCowBreedColor.isSelected),
                )
                .map((selectedCowBreedColor) =>
                  selectedCowBreedColor.name.toLowerCase(),
                )}
            />
          </Grid>
          <LabelledTextInput
            initialValue={cowBreedData?.probabilityPercentage}
            labelText={translate('COW_BREED_PERCENTAGE')}
            handleChangeFn={(newPercentageValue) => {
              let newCowBreedData = _.cloneDeep(cowBreedData);
              newCowBreedData.probabilityPercentage = newPercentageValue;
              onCowBreedChangeFn(newCowBreedData);
            }}
            style={{
              width: '30vw',
            }}
          />
        </>
      ) : null}
    </div>
  );
}

export default BreedColorSelector;
