import Graphic from "../Graphic/Graphic";
import React, { useCallback, useEffect, useRef } from "react";
import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
//import DynamicInlineSVG from '../../common/customHooks/DynamicInlineSVG';
import { getCowSvgFilePath } from "../../common/svgUtil";
import "./Cow.scss";
import {
  getRandomNumberBetweenMAndN,
  measureResponsive,
} from "../../components/VirtualFarm/util";
import { gsap } from "gsap";
import {
  ADULT_THEME_CODE,
  IS_CLICKABLE_CSS_CLASSNAME,
} from "../../common/constants";
//import SVG from 'react-inlinesvg';

/** Cow component */
function Cow({
  cowData,
  cowCssClassname,
  cowXCoordinate,
  cowYCoordinate,
  cowScaleX,
  cowClickHandlerFn,
  additionalCssClassnames,
  farmTheme,
  doPositionFixed,
  isInteractable,
  isAnimated,
  isClickable,
  isAdultSelected,
  cowIndex,
}) {
  const TOTAL_COW_LIFETIME_IN_SECONDS_BEFORE_BEING_FLIPPED = 250;
  const MINIMUM_TIME_BEFORE_FLIP_IN_SECONDS = 15;
  let cowRef = useRef(null);
  let timelineRef = useRef(null);
  let numberOfFlipsExecutedSoFar = useRef(0);

  useEffect(() => {
    if (isAnimated) {
      flipCow();
      timelineRef.current = gsap.timeline({});
    }
  }, []);

  function flipCow() {
    let currentCowTimeoutInSeconds = getRandomNumberBetweenMAndN(
      MINIMUM_TIME_BEFORE_FLIP_IN_SECONDS,
      TOTAL_COW_LIFETIME_IN_SECONDS_BEFORE_BEING_FLIPPED,
    );
    setTimeout(executeGsapAnimation, currentCowTimeoutInSeconds * 1000);
  }

  function executeGsapAnimation() {
    let isCowFlippedAlready = !numberOfFlipsExecutedSoFar.current % 2 === 0;
    let gsapGlobalConfig = {
      duration: 1,
      delay: 0,
      repeat: 0,
      ease: "power2",
    };
    if (isCowFlippedAlready) {
      timelineRef.current.reverse();
    } else {
      timelineRef.current.fromTo(
        cowRef.current,
        { x: 0, y: 0, scaleX: 1, ...gsapGlobalConfig },
        {
          x: measureResponsive(3),
          y: measureResponsive(1.5),
          scaleX: -1,
          ...gsapGlobalConfig,
        },
      );
    }
    numberOfFlipsExecutedSoFar.current = numberOfFlipsExecutedSoFar.current + 1;
    flipCow();
  }
  const getCowBreed = () => {
    return cowData.cowSubBreed ? cowData.cowSubBreed : 'cowHolsteinFriesian--one'; 
  } 
  const getCowSubBreed = () => {
    return cowData.subBreedCssClassName ? cowData.subBreedCssClassName : 'cowHolsteinFriesian--one'; 
  }
  let cowSubmoduleCssClassname =
    cowCssClassname + "--" + cowData &&
    getCowBreed() &&
    getCowBreed().toLowerCase() + " " + cowData &&
    getCowSubBreed(); // important in order to visualize the right cow type/the right cow SVG image
  cowSubmoduleCssClassname += isInteractable
    ? " virtualFarm--interactable virtualFarm--highlighted"
    : "";
  cowSubmoduleCssClassname = isClickable
    ? cowSubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : cowSubmoduleCssClassname;
  let transformValue =
    "translateX(" +
    cowXCoordinate +
    ") translateY(" +
    cowYCoordinate +
    ") scaleX(" +
    cowScaleX +
    ")";
  let cowPositionStyles = doPositionFixed ? {} : {};

  farmTheme = isAdultSelected ? ADULT_THEME_CODE : farmTheme;
  let cowSvgPath = getCowSvgFilePath(farmTheme, cowData.subBreedCssClassName);
  let onCompletedCallback = useCallback((e) => {}, []);
  let onErrorCallback = useCallback((e) => {
    console.log("@onError e:", e);
  }, []);
  const cowName =
    (cowData && cowData.poll && cowData.poll.chosenName) || cowData.name;
  console.log('cowCssClassname',cowCssClassname);
  console.log('cowSubmodule', cowSubmoduleCssClassname);
  console.log('additionalCss', additionalCssClassnames);
  return (
    <div
      style={{
        top: measureResponsive(cowXCoordinate),
        left: measureResponsive(cowYCoordinate),
        width: measureResponsive(3.463675),
        height: measureResponsive(3.925498),
      }}
      title={cowName}
      ref={cowRef}
      className={
        cowCssClassname +
        " " +
        cowSubmoduleCssClassname +
        " " +
        additionalCssClassnames
      }
      onClick={() => {
        cowClickHandlerFn(cowData);
      }}
    >
      <h2
        style={{
          transform: "scaleX(" + cowScaleX + ")",
        }}
        className={cowCssClassname + "--title"}
      >
        {cowData.name}
      </h2>
      {isInteractable ? (
        <DynamicInlineSVG
          svgFullFilePath={cowSvgPath}
          themeName={farmTheme}
          onCompleted={onCompletedCallback}
          onError={onErrorCallback}
        />
      ) : null}
      {isInteractable ? <Graphic cssClassName="is-actor-pointed" /> : null}
    </div>
  );
}
export default Cow;
