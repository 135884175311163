import React from "react";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import IconButton from "../IconButton/IconButton";
import { translate } from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import "./JunoPopup.scss";

function JunoPopup({ closePopupClickFn, callToActionClickFn,isAdultSelected }) {
  return (
    <div className="junoPopup">
      <div className="junoPopup--container">
        <IconButton
          cssClassName="junoPopup--btnClosePopup"
          onClickFn={closePopupClickFn}
        /> 
      <div className="junoPopup--title">{translate("JUNO_ROBOT_TITLE")}</div>
        <div className="junoPopup--description">
        {translate("JUNO_ROBOT_POPUP_DESCRIPTION")}
      </div>
      <CallToActionButton
        isAdultSelected={isAdultSelected}
        cssClassName="junoPopup-callToActionButton"
        label={translate("JUNO_ROBOT_POPUP_BUTTON")}
        onClickFn={() => {
          callToActionClickFn();
          closePopupClickFn();
        }}
      />
      </div>
      
      {/* <CallToActionButton
        cssClassName="junoPopup-callToActionButton"
        label={translate("MILK_FACTORY_POPUP_SEE_HISTORY_BUTTON_LABEL")}
        onClickFn={() => {
          callToActionClickFn();
          closePopupClickFn();
        }}
      /> */}
      <Graphic cssClassName="junoPopup--vector" /> 
    </div>
  );
}

export default JunoPopup;
