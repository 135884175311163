import React from "react";
import "./SecondSlideLely.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const SecondSlideLely = ({}) => {
  return (
    <div className="lelyStorySecondSlide">
      <TextCaption additionalCssClassname="lelyStorySecondSlide--textCaption">
        <div className="lelyStorySecondSlide--title">
          {translate("LELY_SECOND_SLIDE_TITLE")}
        </div>
        <div className="lelyStorySecondSlide--text">
          {translate("LELY_SECOND_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="lelyStorySecondSlide--imgCaption">
        <div className="lelyStorySecondSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default SecondSlideLely;
