import { MARK_FIRST_VIRTUAL_FARM_TOUR_COMPLETE } from "./types";

/** Create action which will mark the virtual farm tour as complete
 * so that users don't have to see it again the next time
 * they enter the Virtual Farm screen
 * (because presumably they will already know how to
 * use the functionality there)
 * @return {Object} Action object
 */
export function markFirstVirtualFarmTourAsComplete() {
  return {
    type: MARK_FIRST_VIRTUAL_FARM_TOUR_COMPLETE,
    payload: {},
  };
}