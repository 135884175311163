import React from "react";

// Styles
import "./ConfirmationDialog.scss";

/** ConfirmationDialog component.
 * @param {Object} obj - Object which contains all props.
 * @param {string} [obj.dialogTitle] - The title of the ConfirmationDialog
 * @param {string} [obj.dialogText] - The text of the ConfirmationDialog
 * @param {string} [obj.confirmationButtonLabelText] - The label text of the confirmation button in the ConfirmationDialog
 * @param {string} [obj.cancelButtonLabelText] - The label text of the cancel button in the ConfirmationDialog
 * @param {string} [obj.popupCssClassname] - Additional CSS class to apply to the ConfirmationDialog
 * @param {Function} [obj.onConfirmCallbackFn] - A callback function to be called upon clicking the Confirm button of the dialog
 * @param {Function} [obj.onCancelCallbackFn] - A callback function to be called upon cancelling or closing the confirmation dialog
 * @param {Function} [obj.onCloseCallbackFn] - Explicit callback function for the scenario in which the user clicks the X button at the top right corner of the dialog. If not specified explicitly, onCancelCallbackFn will be executed instead.
 * @param {string} [obj.cssClassname] - Specific CSS class to use on ConfirmationDialog parent element
 */
function ConfirmationDialog({
  dialogTitle,
  dialogText,
  confirmationButtonLabelText,
  cancelButtonLabelText,
  popupCssClassname,
  onConfirmCallbackFn,
  onCancelCallbackFn,
  onCloseCallbackFn,
  cssClassname,
}) {
  const confirmationDialogContainerCssClassname =
    cssClassname || "confirmationDialogContainer";
  return (
    <div className={confirmationDialogContainerCssClassname}>
      <div className={`confirmationDialog ${popupCssClassname}`}>
        <div className="confirmationDialog--header">
          <h3>{dialogTitle}</h3>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="close"
            onClick={onCloseCallbackFn || onCancelCallbackFn}
          >
            <i class="lely-icon-close"></i>
          </button>
        </div>
        <div className="confirmationDialog--body">
          <p>{dialogText}</p>
        </div>
        <div className="confirmationDialog--footer">
          <button
            className="confirmationDialog--btn-cancel"
            onClick={onCancelCallbackFn}
          >
            {cancelButtonLabelText}
          </button>
          <button
            className="confirmationDialog--btn-confirm"
            onClick={onConfirmCallbackFn}
          >
            {confirmationButtonLabelText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationDialog;
