import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
import { getDiscoverySvgFilePath } from "../../common/svgUtil";
import Graphic from "../Graphic/Graphic";
import React, { useCallback, useEffect, useRef } from "react";
import "./DiscoveryFeature.scss";
import {
  getRandomNumberBetweenMinAndMax,
  measureResponsive,
} from "../../components/VirtualFarm/util";
import { useDispatch, useSelector } from "react-redux";
import { gsap, Linear } from "gsap";
import { updateActorPosition } from "../../components/Actor/actions";
import { DiscoveryRouteSettings } from "./DiscoveryRouteSettings";
import {
  DISCOVERY_ACTOR_ID,
  IS_CLICKABLE_CSS_CLASSNAME,
  ADULT_THEME_CODE,
} from "../../common/constants";
import _ from "lodash";

/** DiscoveryFeature component */
function DiscoveryFeature({
  discoveryFeatureData,
  discoveryFeatureCssClassname,
  discoveryFeatureXCoordinate,
  discoveryFeatureYCoordinate,
  discoveryFeatureScaleX,
  discoveryFeatureClickHandlerFn,
  additionalCssClassnames,
  farmTheme,
  doPositionFixed,
  isInteractable,
  isClickable,
  isAnimationEnabled,
  isAdultSelected,
}) {
  //#region Discovery movement logic
  let discoveryRobotRef = useRef(null);
  let timelineRef = useRef(null);
  const isAnimationEnabledRef = useRef(true);
  const isAnimationCycleInitiated = useRef(false);

  const dispatch = useDispatch();
  const { actorPositions } = useSelector((state) => state.actorPositions);
  const MINIMUM_TIME_BETWEEN_DISCOVERY_ROUTES_IN_SECONDS = 10;
  const MAXIMUM_TIME_BETWEEN_DISCOVERY_ROUTES_IN_SECONDS = 40;
  const DISCOVERY_ROBOT_ROUTE = DiscoveryRouteSettings();
  const DISCOVERY_INITIAL_POSITION_IN_ANIMATION_LABEL = "start";

  useEffect(() => {
    timelineRef.current = gsap.timeline({});
  }, []);

  useEffect(() => {
    if (
      !isAnimationCycleInitiated.current &&
      Object.keys(actorPositions).length > 0
    ) {
      if (isAnimationEnabled) {
        isAnimationCycleInitiated.current = true;
        animateDiscoveryRobot(DISCOVERY_ROBOT_ROUTE);
      }
    }
  }, [actorPositions]);

  useEffect(() => {
    isAnimationEnabledRef.current = isAnimationEnabled;
    if (!isAnimationEnabled) {
      stopDiscoveryAnimationAndBringDiscoveryBackToInitialPosition();
    } else {
      timelineRef.current.resume();
      if (isAnimationCycleInitiated.current === true) {
      } else {
        isAnimationCycleInitiated.current = true;
        animateDiscoveryRobot(DISCOVERY_ROBOT_ROUTE);
      }
    }
  }, [isAnimationEnabled]);

  function stopDiscoveryAnimationAndBringDiscoveryBackToInitialPosition() {
    timelineRef.current.pause();
  }

  function animateDiscoveryRobot(robotRoute) {
    if (!isAnimationEnabledRef.current) {
      // skip animating the Discovery robot if user is currently in the virtual farm tour
      return;
    }
    resetDiscoveryRoute(robotRoute);
    robotRoute.STAGES.forEach((currentStageDetails) => {
      executeStage(currentStageDetails, actorPositions);
    });
  }

  function scheduleNextDiscoveryRobotRoute() {
    let secondsUntilNextDiscoveryRoute = getRandomNumberBetweenMinAndMax(
      MINIMUM_TIME_BETWEEN_DISCOVERY_ROUTES_IN_SECONDS,
      MAXIMUM_TIME_BETWEEN_DISCOVERY_ROUTES_IN_SECONDS,
    );
    setTimeout(function () {
      animateDiscoveryRobot(DISCOVERY_ROBOT_ROUTE);
    }, secondsUntilNextDiscoveryRoute * 1000);
  }

  function executeStage(stageDetails, actorPositions) {
    let plannedMoves = planMoves(stageDetails, actorPositions);
    executeGsapAnimation(stageDetails, plannedMoves);
  }

  // returns an array with { start, end } objects detailing each individual movement needed in order to
  // get from the start coordinates of the stage of the route
  // to the end coordinates of the stage of the route
  // Later on this array will be converted to GSOP.Timeline
  // that will effectively make the main actor (e.g.: Discovery) move
  function planMoves(stageDetails, actorPositions) {
    let plannedMoves = [];
    let latestMovementStartPosition = {
      x: stageDetails.startPosition.x,
      y: stageDetails.startPosition.y,
    };
    Object.keys(actorPositions).forEach((currentActorId) => {
      if (currentActorId !== DISCOVERY_ACTOR_ID) {
        let currentActorPosition = actorPositions[currentActorId];
        if (isActorOnStagePath(currentActorPosition, stageDetails)) {
          let stopPosition = getStopCoordinatesByActorPositionAndStageDetails(
            currentActorPosition,
            stageDetails,
          );
          plannedMoves.push({
            startPosition: latestMovementStartPosition,
            endPosition: stopPosition,
          });
          latestMovementStartPosition = stopPosition;
        }
      }
    });
    plannedMoves.push({
      startPosition: latestMovementStartPosition,
      endPosition: stageDetails.endPosition,
    });
    return plannedMoves;
  }

  function resetDiscoveryRoute(robotRoute) {
    robotRoute.STAGES.forEach((currentStage) => {
      currentStage.isCompleted = false;
    });
  }

  function checkIfDiscoveryRouteIsComplete() {
    let isDiscoveryRouteComplete = true;
    DISCOVERY_ROBOT_ROUTE.STAGES.forEach((currentStage) => {
      if (!currentStage.isCompleted) {
        isDiscoveryRouteComplete = false;
      }
    });
    if (isDiscoveryRouteComplete) {
      // restart the discovery route after random number of seconds (ranging between 10 and 40 seconds)
      timelineRef.current.to(discoveryRobotRef.current, {
        className:
          discoverySubmoduleCssClassname +
          " " +
          DISCOVERY_ROBOT_ROUTE.STAGES[0].stageId,
      });
      scheduleNextDiscoveryRobotRoute();
    }
  }

  let discoverySubmoduleCssClassname =
    discoveryFeatureCssClassname + " " + additionalCssClassnames;
  discoverySubmoduleCssClassname += isInteractable
    ? " virtualFarm--interactable virtualFarm--highlighted"
    : "";
  discoverySubmoduleCssClassname = isClickable
    ? discoverySubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : discoverySubmoduleCssClassname;

  // movesArray consists of objects of type
  // { startPosition: <x, y>, endPosition: <x, y>}
  function executeGsapAnimation(currentStage, movesArray) {
    let gsapGlobalConfig = {
      duration: 10,
      delay: 0,
      repeat: 0,
      ease: Linear.easeNone,
    };
    timelineRef.current.addLabel(DISCOVERY_INITIAL_POSITION_IN_ANIMATION_LABEL);
    movesArray.forEach((currentMoveDetails, currentMoveIndex) => {
      timelineRef.current.fromTo(
        discoveryRobotRef.current,
        {
          left: measureResponsive(currentMoveDetails.startPosition.x),
          top: measureResponsive(currentMoveDetails.startPosition.y),
          ...gsapGlobalConfig,
        },
        {
          left: measureResponsive(currentMoveDetails.endPosition.x),
          top: measureResponsive(currentMoveDetails.endPosition.y),
          className:
            discoverySubmoduleCssClassname + " " + currentStage.stageId,
          onComplete: function () {
            dispatch(
              updateActorPosition(
                DISCOVERY_ACTOR_ID,
                currentMoveDetails.endPosition,
              ),
            );
            let isLastMoveFromStage =
              currentMoveIndex === movesArray.length - 1;
            if (isLastMoveFromStage) {
              currentStage.isCompleted = true;
              checkIfDiscoveryRouteIsComplete();
            }
          },
          ...gsapGlobalConfig,
        },
      );
    });
  }

  function getStopCoordinatesByActorPositionAndStageDetails(
    actorPosition,
    stageDetails,
  ) {
    return {
      x: actorPosition.x,
      y: actorPosition.y,
    };
  }

  function isActorOnStagePath(actorPosition, stageDetails) {
    let stagePolygon = [
      {
        x: stageDetails.startPosition.x,
        y: stageDetails.startPosition.y,
        id: "A",
      },
      {
        x:
          stageDetails.startPosition.x +
          DISCOVERY_ROBOT_ROUTE.SETTINGS.DISCOVERY_WIDTH,
        y: stageDetails.startPosition.y,
        id: "B",
      },
      {
        x: stageDetails.endPosition.x,
        y: stageDetails.endPosition.y,
        id: "C",
      },
      {
        x:
          stageDetails.endPosition.x +
          DISCOVERY_ROBOT_ROUTE.SETTINGS.DISCOVERY_WIDTH,
        y: stageDetails.endPosition.y,
        id: "D",
      },
    ];
    return isPointInsidePolygon(
      stagePolygon,
      actorPosition.x + DISCOVERY_ROBOT_ROUTE.SETTINGS.ACTOR_WIDTH,
      actorPosition.y,
    );
  }

  function getIdealXStagePositionForActor(actorPosition, stageDetails) {
    let actorYToStageYRatio =
      (actorPosition.y - stageDetails.startPosition.y) /
      (stageDetails.endPosition.y - stageDetails.startPosition.y);
    let idealActorXPositionIfItOverlapsTheStagePath =
      actorYToStageYRatio *
      (stageDetails.endPosition.x - stageDetails.endPosition.x);
    return idealActorXPositionIfItOverlapsTheStagePath;
  }

  function isPointInsidePolygon(poly, pointx, pointy) {
    var i, j;
    var inside = false;
    for (i = 0, j = poly.length - 1; i < poly.length; j = i++) {
      if (
        poly[i].y > pointy != poly[j].y > pointy &&
        pointx <
          ((poly[j].x - poly[i].x) * (pointy - poly[i].y)) /
            (poly[j].y - poly[i].y) +
            poly[i].x
      )
        inside = !inside;
    }
    return inside;
  }
  //#endregion

  let discoverySvgPath = getDiscoverySvgFilePath(farmTheme);
  const onCompletedCallback = useCallback((e) => {}, []);
  const onErrorCallback = useCallback((e) => {}, []);
  return (
    <div
      style={{
        left: measureResponsive(
          DISCOVERY_ROBOT_ROUTE.SETTINGS.DISCOVERY_START_X,
        ),
        top: measureResponsive(
          DISCOVERY_ROBOT_ROUTE.SETTINGS.DISCOVERY_START_Y,
        ),
        width: measureResponsive(
          DISCOVERY_ROBOT_ROUTE.SETTINGS.DISCOVERY_WIDTH,
        ),
        height: measureResponsive(
          DISCOVERY_ROBOT_ROUTE.SETTINGS.DISCOVERY_HEIGHT,
        ),
      }}
      className={discoverySubmoduleCssClassname}
      ref={discoveryRobotRef}
      onClick={() => {
        discoveryFeatureClickHandlerFn(discoveryFeatureData);
      }}
    >
      <h2
        style={{
          transform: "scaleX(" + discoveryFeatureScaleX + ")",
        }}
        className={discoveryFeatureCssClassname + "--title"}
      >
        {discoveryFeatureData.name}
      </h2>
      {isInteractable ? (
        <DynamicInlineSVG
          svgFullFilePath={discoverySvgPath}
          themeName={isAdultSelected ? ADULT_THEME_CODE : farmTheme}
          onCompleted={onCompletedCallback}
          onError={onErrorCallback}
        />
      ) : null}
      {isInteractable ? <Graphic cssClassName="is-actor-pointed" /> : null}
    </div>
  );
}
export default DiscoveryFeature;
