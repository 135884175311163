export const REQUEST_LANGUAGES_INFORMATION =
  'REQUEST_LANGUAGES_INFORMATION';
export const REQUEST_LANGUAGES_INFORMATION_ERROR =
  'REQUEST_LANGUAGES_INFORMATION_ERROR';
export const REQUEST_LANGUAGES_INFORMATION_SUCCESS =
  'REQUEST_LANGUAGES_INFORMATION_SUCCESS';
export const SAVE_CHANGES_TO_LANGUAGES =
  'SAVE_CHANGES_TO_LANGUAGES';
export const SAVE_CHANGES_TO_LANGUAGES_SUCCESS =
  'SAVE_CHANGES_TO_LANGUAGES_SUCCESS';
export const SAVE_CHANGES_TO_LANGUAGES_ERROR =
  'SAVE_CHANGES_TO_LANGUAGES_ERROR';
