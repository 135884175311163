import React from "react";
import "./LastSlideDiscoverySlide.scss";

import PoleComponent from "../../common/components/PoleComponentEndingSlides/PoleComponent";

const LastSlideDiscoverySlide = ({ onNavigateToInitialSlideFn }) => {
  return (
    <div className="lelyStoryLastSlide">
      <PoleComponent onNavigateToInitialSlideFn={onNavigateToInitialSlideFn} />
    </div>
  );
};

export default LastSlideDiscoverySlide;
