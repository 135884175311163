import _ from "lodash";
import React from "react";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";
import "./FamilyMember.scss";

function FamilyMember({
  cowDetails,
  subBreed,
  relativeName,
  familyRole,
  isClickable,
  onFamilyMemberClickFn,
}) {
  let memberCssClassname =
    "familyMember--" + subBreed + " familyMember--" + familyRole;
  memberCssClassname = isClickable
    ? memberCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : memberCssClassname;
  if (!_.isEmpty(cowDetails)) {
    return (
      <div
        className={`familyMember ${memberCssClassname}`}
        onClick={() => {
          onFamilyMemberClickFn(cowDetails);
        }}
      >
        <span className="familyMember--name">
          {relativeName?.replace(/ +/g, "")}
        </span>
      </div>
    );
  } else {
    return "";
  }
}

export default FamilyMember;
