import axios from 'axios';
import { LOGOUT_SERVICE_URL } from './config';

export const api = {
  logout: async function(accessToken) {
    axios.post(LOGOUT_SERVICE_URL, {}, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
};
