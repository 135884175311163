import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../localization/TranslationUtil";
import "./DisclaimerPopup.scss";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import { showPopup } from "../../common/components/PopupContainer/actions";


const DisclaimerPopup = ({ closePopupClickFn, isAdultSelected }) => {
  const popupComponentRef = useRef(null);
  const fadeOutIntervalRef = useRef(null);
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const doShowChildVersion = farmDetails.isAdultSelected === false;
  const POPUP_FADE_OUT_ANIMATION_DURATION_IN_MILLISECONDS =  500;
  const dispatch = useDispatch();

  const getSelectedAgeIsChild = farmDetails.isAdultSelected == true || farmDetails.isAdultSelected == undefined ? false : true;
  const fadeOutPopup = () => {
    popupComponentRef.current.classList.add("is-hiding");
  };

  const closeDisclaimerClickFn = () => {
    fadeOutPopup();
    fadeOutIntervalRef.current = setTimeout(() => {
      dispatch(showPopup({}));
    }, POPUP_FADE_OUT_ANIMATION_DURATION_IN_MILLISECONDS);
  };

  return (
    <div
      className={
        doShowChildVersion
          ? "disclaimerPopup is-children"
          : "disclaimerPopup is-adult"
      }
      ref={popupComponentRef}
    >
      <div className="disclaimerPopup--headers">
        <div className="disclaimerPopup--title">
          {translate("DISCLAIMER_POPUP_TITLE_TEXT")}
        </div>
      </div>
      <div className="disclaimerPopup--body">
        <p>
          <span className="disclaimerPopup--body--textBeginning">
            {translate("DISCLAIMER_POPUP_DESCRIPTION_TEXT")}
          </span>
        </p>
      </div>
      <div className="disclaimerPopup--footer">

      <CallToActionButton
          cssClassName="disclaimerPopup--footer--btnCloseDisclaimer"
          label={translate("DISCLAIMER_POPUP_CLOSE_BUTTON")} 
          isAdultSelected={!getSelectedAgeIsChild}
          onClickFn={() => {
            closeDisclaimerClickFn()
          }}
        /> 
        {/* <div
          className={"disclaimerPopup--footer--btnCloseDisclaimer"}
          onClick={closeDisclaimerClickFn}
            >
          <span className="disclaimerPopup--footer--btnCloseDisclaimer--label">
            {translate("DISCLAIMER_POPUP_CLOSE_BUTTON")}
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default DisclaimerPopup;
