import _ from "lodash";
import React, { useEffect } from "react";
import "./FinalOnboardingSlide.scss";
import TextCaption from "../../../../components/TextCaption/TextCaption";
import {
  translate,
  translateWithValuesAndHtml,
} from "../../../../localization/TranslationUtil";
import {
  IS_CLICKABLE_CSS_CLASSNAME,
  SIDE_MENU_OPTION_AGE_SELECTION,
} from "../../../../common/constants";

const FinalOnboardingSlide = React.memo(({ onNavigateToInitialSlideFn }) => {
  const goToFarmText = translate("COW_OUTRO_GO_TO_FARM_BUTTON_CAPTION");
  return (
    <div className="FinalOnboardingSlide">
      <div className="FinalOnboardingSlide--background"></div>
      <TextCaption additionalCssClassname="textCaption--FinalOnboardingSlide">
        <p
          className="FinalOnboardingSlide--text"
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "MIJNMELK_ONBOARDING_FINAL_SLIDE",
            ),
          }}
        ></p>
      </TextCaption>
      <div
        className={
          "FinalOnboardingSlide--backButton " + IS_CLICKABLE_CSS_CLASSNAME
        }
        onClick={() =>
          onNavigateToInitialSlideFn &&
          onNavigateToInitialSlideFn(SIDE_MENU_OPTION_AGE_SELECTION)
        }
      >
        {goToFarmText}
      </div>
      <div className="FinalOnboardingSlide--bottle"></div>
      <div className="FinalOnboardingSlide--cup"></div>
      <div className="FinalOnboardingSlide--farmer"></div>
      <div className="arrowFinalSlideMelk"></div>
    </div>
  );
});

export default FinalOnboardingSlide;
