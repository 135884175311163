import React, { useEffect } from "react";
import { useSelector } from "react-redux";

//Components && Styles
import PropTypes from "prop-types";
import clsx from "clsx";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";

//Check if SENSE is online/offline
import { Offline } from "react-detect-offline";

const variantIcon = {
  error: ErrorIcon,
};

/** Snackbar styles */
const useStyles1 = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

/** Custom Snackbar wrapper */
function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["error"]).isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

/** Define snackbar error popup */
function StatusPopup(message, isPopupDisplayed = true) {
  const classes = useStyles2();
  const [open, setOpen] = React.useState(isPopupDisplayed);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  /** Reset snackbar popup value in order to show it after second reload */
  // TODO: Solution without timeout
  useEffect(() => {
    let setTimer = setTimeout(() => setOpen(true), 3000);
    return () => {
      clearTimeout(setTimer);
    };
  }, [open]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      onClose={handleClose}
    >
      <MySnackbarContentWrapper
        onClose={handleClose}
        variant="error"
        message={message}
      />
    </Snackbar>
  );
}

/** Render snackbar error popup */
export default function ErrorStatus() {
  const serverResponseStatusInfo = useSelector((state) => state.responseStatus);
  const getServerStatus = serverResponseStatusInfo.responseStatus;
  var snackbar = null;
  console.log("is it this?");
  /** Check Server Response Status */
  if (getServerStatus.message === "Network Error") {
    snackbar = StatusPopup("Server connection is lost", true);
  } else {
    snackbar = (
      <Offline polling={false}>
        {StatusPopup("Your network connection is lost", true)}
      </Offline>
    );
  }

  return snackbar;
}
