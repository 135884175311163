import React from "react";
import { translate } from "../../../localization/TranslationUtil";
import LabelledTextArea from "../../../common/components/LabelledTextArea/LabelledTextArea";
import "./MultiLanguageTextArea.scss";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import { MultilineChart } from "@material-ui/icons";

/** MultiLanguageTextArea component.
 * @param {Object} obj - Object which contains all props.
 * @param {Object} [obj.multiLanguageData] - Object containing translations in all available languages for the value that this text area should display
 * @param {String} [obj.fieldLabelTranslationKey] - The key from the translations file to be used when showing the label of this field
 * @param {String} [obj.fieldPlaceholderTextTranslationKey] - The key from the translations file to be used when showing the placeholder text of this text area
 * @param {String} [obj.additionalCssClassname] - Additional CSS classname that should be attached to the text area for styling purposes, etc.
 * @param {Array} [obj.languageKeysArray] - Those keys will influence the number of text areas displayed per field (as for each available language we display a separate text area)
 * @param {Function} [obj.handleDataChangedFn] - Function that will be called whenever the user changes the contents of ANY of the text areas for a specific language
 */
function MultiLanguageTextArea({
  multiLanguageData,
  fieldLabelTranslationKey,
  fieldPlaceholderTextTranslationKey,
  additionalCssClassname,
  languageKeysArray,
  handleDataChangedFn,
  givenLength
}) {  
  if (_.isEmpty(multiLanguageData) || _.isNull(multiLanguageData)) {
    multiLanguageData = {};
    multiLanguageData["en"] = "";
  }

  
  function getTextAreaJSXElementsByMultiLanguageData(multiLanguageData) {
    let textAreaJSXElements = [];
    if (_.isEmpty(multiLanguageData) || _.isNull(multiLanguageData)) {
      multiLanguageData = {};
      multiLanguageData["en"] = "";
    } else {
      if (
        typeof multiLanguageData === "string" &&
        multiLanguageData.length > 0
      ) {
        let previousStringData = _.cloneDeep(multiLanguageData);
        multiLanguageData = {};
        multiLanguageData["en"] = previousStringData;
      }
    }
    languageKeysArray.forEach((currentLanguageKey) => {
      textAreaJSXElements.push(
        getTextAreaComponentJSXByData(
          multiLanguageData[currentLanguageKey] || "",
          currentLanguageKey,
        ),
      );
      textAreaJSXElements.push(
        getTextAreaComponentJSXByData(
          multiLanguageData[currentLanguageKey + "s"] || "",
          currentLanguageKey + "s",
        ),
      );
    });
    return textAreaJSXElements;
    }
  function getTextAreaComponentJSXByData(
    dataTextForSpecificLanguage,
    currentLanguageKey,
  ) { 
    return (
      <LabelledTextArea
        labelText={translate("LANGUAGE_" + currentLanguageKey.toUpperCase())}
        initialValue={dataTextForSpecificLanguage}
        handleChangeFn={(newTextForSpecificLanguage) => { 
          let copyOfMultiLanguageData = _.cloneDeep(multiLanguageData);
          copyOfMultiLanguageData[currentLanguageKey] =
            newTextForSpecificLanguage;
          handleDataChangedFn(copyOfMultiLanguageData);
        }}
        placeholderText={translate(fieldPlaceholderTextTranslationKey)}
        additionalClassName={additionalCssClassname}
        givenLength={givenLength || 512}
      />
    );
  }

  return (
    <div className="multiLanguageTextArea">
      <div className="multiLanguageTextArea--label">
        {translate(fieldLabelTranslationKey)}
      </div>
      <Grid container className="multiLanguageTextArea--textAreasContainer">
        {getTextAreaJSXElementsByMultiLanguageData(multiLanguageData)}
      </Grid>
    </div>
  );
}

export default MultiLanguageTextArea;
