import React from "react";
import IconButton from "../IconButton/IconButton";
import {
  translateWithValues,
  translateDynamic,
} from "../../localization/TranslationUtil";
import "./FarmPopup.scss";
import { getDisableCacheAppendix } from "../../common/urlUtil";

function FarmPopup({ closePopupClickFn, farmData, popupItemInfo, selectedFarmDetails }) {  
  console.log('farmData',farmData)
  const farmPopupImage = (selectedFarmDetails?.imageData); 
  return (
    <div className="farmPopup">
      <div className="farmPopup--container">
      <IconButton
        cssClassName="farmPopup--btnClosePopup"
        onClickFn={closePopupClickFn}
      />
        <div className="farmPopup--title">
          {translateWithValues("FARM_POPUP_TITLE", { titleText: (farmData.farmName) })}
        </div>
        <div className="farmPopup--description">{translateDynamic(farmData.description)}</div>
      </div>
      <div className="farmPopup--farmImageContainer"> 
      {farmPopupImage ? (
        <img
          src={getDisableCacheAppendix(farmPopupImage)}
          className="farmPopup--farmImage"
        ></img>) : 
        <div className="farmPopup--farmDefaultImage"/>}
        <div className="farmPopup--frame" />
      </div>

      
    </div>
  );
}

export default FarmPopup;
