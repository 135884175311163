import { GET_COW_DAILY_DETAILS_REQUEST } from './types.js';
import {
  getCowDailyDetailsError,
  getCowDailyDetailsSuccess,
} from './actions.js';
import { put, take, call } from 'redux-saga/effects';
import { getServerResponseStatus } from '../ErrorPopUp/actions';
import {
  startLoading,
  endLoading,
} from '../../common/redux/loadManager/actions';
import { getLoadId } from '../../common/redux/loadManager/methods';
import moment from 'moment';

let currentLoadId = 0;

export default function* cowDailyDetailsSaga(api) {
  while (true) {
    const cowDailyDetailsRequestAction = yield take(
      GET_COW_DAILY_DETAILS_REQUEST,
    );
    /** Update state when server is online */
    yield put(getServerResponseStatus(''));
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { cowId, specificDate } = cowDailyDetailsRequestAction.payload;
    yield call(getCowDailyDetails, api, cowId, specificDate);
  }
}

/** Create request to get cow daily details
 * @param {string} cowId - The ID of the cow for which we want to get daily details
 * @param {Date} specificDate - The date/day for which we want to get daily details
 */
function* getCowDailyDetails(api, cowId, specificDate) {
  try {
    const cowDailyDetails = yield call(
      api.getCowDailyDetails,
      cowId,
      specificDate,
    );
    cowDailyDetails.data.formattedDate = moment(specificDate)
      .format('D MMM YYYY')
      .toUpperCase();
    cowDailyDetails.data.rawDate = specificDate;
    let cowDailyDetailsData = cowDailyDetails.data;
    const isEmptyCowDaily =
      cowDailyDetailsData &&
      cowDailyDetailsData.milkings &&
      cowDailyDetailsData.milkings.length === 0 &&
      cowDailyDetailsData.restingMinutes === null &&
      (cowDailyDetailsData.eatingMinutes === null ||
        cowDailyDetailsData.feedIntakeConcentrateKg === 0 ||
        cowDailyDetailsData.feedIntakeConcentrateKg === null);
    if (isEmptyCowDaily) {
      throw 'Empty cow';
    } else {
      yield put(getCowDailyDetailsSuccess(cowDailyDetails.data));
      /** Stop loader */
      yield put(endLoading(currentLoadId));
    }
  } catch (error) {
    console.log('@error:', error);
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(getCowDailyDetailsError(error));
    /** Listen for error state to show when server is disconected */
    yield put(getServerResponseStatus(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}
