import _, { isEmpty } from 'lodash';
import { INVALIDATE_APPLICATION_CACHE_IF_NEEDED } from './types.js';
import { put, take, select, delay, call } from 'redux-saga/effects';
import { applicationVersionNumberSelect } from '../../../selectors/application_version_number.js';
import { resetApplicationStoreWithoutUserSession } from '../../../common/redux/purgeStore';
import { updateApplicationVersionNumber } from './actions.js';
import { api as featureFlagsApi } from '../../../api/featureFlags/featureFlagsApi';
import { mockApi as featureFlagsMockApi } from '../../../api/featureFlags/featureFlagsApi';
import { getVersionFeatureFlagName } from '../../versionUtils.js';
import { API_PREFIX } from '../../../api/lelyBackend/config.js';

export default function* applicationCacheSaga() {
  while (true) {
    const invalidateApplicationCacheIfNeededAction = yield take(
      INVALIDATE_APPLICATION_CACHE_IF_NEEDED,
    );
    // Redux Saga allows to *sometimes* and preferrably rarely
    // access values from the Redux Store directly from a Saga.
    // It is a good practice to decentralize the access to store
    // properties in a selectors/ folder so that it is more transparent
    // where those interactions are happening and to extract them
    // from the Saga itself as in the case below.
    const lastApplicationVersionInLocalStorage = yield select(
      applicationVersionNumberSelect,
    );

    let latestApplicationVersionFeatureFlagName = getVersionFeatureFlagName(API_PREFIX);
    const lastApplicationVersionFeatureFlagResponse = yield call(
      featureFlagsApi.getFeatureFlagDetails,
      latestApplicationVersionFeatureFlagName
    );
    const { description: lastApplicationVersionFromFeatureFlag } = lastApplicationVersionFeatureFlagResponse.data;

    const envVariablesPassed = process.env;
    const currentApplicationVersionFromPackageJson =
      envVariablesPassed.REACT_APP_VERSION;
    // TODO: dont use package.json here, use the FeatureFlag API and the app version feature flag for the respective environment
    // put here yield GET request to get tje data for tje correct 
    // version feature flag;
    //.this should return the latest version
    // number deployed on the respective environment
    // (stg or prd)
    // the value from package.json that
    // was originally there can be used
    // as a fallback in case we are testing locally
    // or for some reason we could not fetch
    // the version feature flag value
    const latestVersion = lastApplicationVersionFromFeatureFlag || currentApplicationVersionFromPackageJson;
    const isVersionOutdated = isEmpty(latestVersion) || (latestVersion != lastApplicationVersionInLocalStorage);
    console.log('Last application version in local storage:', lastApplicationVersionInLocalStorage, 'Latest version:', latestVersion);
    if (isVersionOutdated) {
      console.log('Version is outdated.');
      yield put(
        updateApplicationVersionNumber(
          latestVersion,
        ),
      );
      yield put(resetApplicationStoreWithoutUserSession()); // this will delete everything in the store apart from the application version information
      yield delay(500);
      window.location.reload(true);
    }
  }
}
