import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import "./LiveDataPopup.scss";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import { measureResponsive } from "../../components/VirtualFarm/util";
import { getCowPopupSvgFilePath } from "../../common/svgUtil";
import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";

/** LiveDataPopup component */
function LiveDataPopup({ cowDetails, farmTheme, closePopupClickFn }) {
  const liveDataPopupCssClassname = "liveDataPopup";
  const cowHeadCssClassname =
    "liveDataPopup--cowHead familyMember--" + cowDetails.cowSubBreed;
  return (
    // cowDetails.name || cowDetails.cowSubBreed ?
    <div
      className={liveDataPopupCssClassname}
      style={{
        left: measureResponsive(35.5),
        top: measureResponsive(13.5),
      }}
    >
      <span
        className="liveDataPopup--title"
        dangerouslySetInnerHTML={{
          __html: translateWithValuesAndHtml("LIVE_DATA_POPUP_TEXT", {
            cowName: cowDetails.name,
          }),
        }}
      ></span>
      <Graphic cssClassName="liveDataPopup--liveIcon" />
      <div className={cowHeadCssClassname} />
      <div className="liveDataPopup--arrow"></div>
      <IconButton
        cssClassName="cowPopup--btnClosePopup"
        onClickFn={closePopupClickFn}
      />
    </div> 
    // : null
  );
}
export default LiveDataPopup;
