import React from "react";
import "./StartingSlideVector.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import StoryNavigationHint from "../../common/components/StoryNavigationHint/StoryNavigationHint";

const StartingSlideVector = ({}) => {
  return (
    <div className="vectorStoryStartingPoint">
      <TextCaption additionalCssClassname="vectorStoryStartingPoint--textCaption">
        <div className="vectorStoryStartingPoint--title">
          {translate("VECTOR_STORY_TITLE")}
        </div>
        <div className="vectorStoryStartingPoint--name">
          {translate("VECTOR_STORY_HEADER")}
        </div>
      </TextCaption>
      <div className="vectorStoryStartingPoint--robot"></div>
      <StoryNavigationHint />
    </div>
  );
};

export default StartingSlideVector;
