import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import "./AstronautCowDropdownMenu.scss";
import _ from "lodash";

function AstronautCowDropdownMenu({
  additionalClassName,
  initialValue,
  options,
  labelText,
  handleChangeFn,
}) {
  console.log(
    "@AstronautCowDropdownMenu initialValue:",
    initialValue,
    "options:",
    options,
    "handleChangeFn:",
    handleChangeFn,
  );

  useEffect(() => {}, [!_.isEmpty(initialValue)]);

  function handleChange(event) {
    console.log("@AstronautCowDropdownMenu handleChange event:", event);
    handleChangeFn(event.target.value);
  }
  return (
    <div>
      <label className="labelled-text-input--label">{labelText}</label>
      <FormControl
        className={`dropdown-text-input--textInput${
          additionalClassName ? " " + additionalClassName : ""
        }`}
        sx={{ m: 1, minWidth: 120, top: "20vh", left: "5vw" }}
      >
        <Select
          sx={{
            backgroundColor: "black",
            borderColor: "#43bf66",
            boxSizing: "border-box",
            border: "1px solid",
            width: "18vw",
            top: "10vh",
          }}
          onChange={handleChange}
          value={initialValue}
          onWheel={(event) => {
            console.log(
              "On wheel:",
              event,
              "event.nativeEvent:",
              event.nativeEvent,
            );
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            event.nativeEvent.stopPropagation();
          }}
        >
          {options &&
            options.map((currentOption) => {
              let currentLabel = currentOption.name;
              let currentOptionValue = currentOption.code;
              let currentOptionClassName = "dropdown-text-input--udder";
              return (
                <MenuItem
                  className={currentOptionClassName}
                  value={currentOptionValue}
                >
                  {currentLabel}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
}
export default AstronautCowDropdownMenu;
