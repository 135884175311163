import { SIDE_MENU_OPTION_AGE_SELECTION } from "../../common/constants";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { getSubBreedCssClassNameBySubBreed } from "../../common/components/LabelledCowBreedSelector/optionsPerCowBreed";
import RotatingStoryContainer from "../../common/components/RotatingStory/RotatingStoryContainer";
import { translateDynamic } from "../../localization/TranslationUtil";
import "./BottleStoryPage.scss";
import MijnMelkOnboardingAdultContainer from "../../components/MijnMelkOnboardingAdult/MijnMelkOnboardingAdultContainer";
import { getWeatherDataByFarmDetails } from "../../components/Weather/WeatherUtil";

function BottleStoryPage({ onNavigateToInitialSlideFn }) {
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const currentHourWeatherDetails = getWeatherDataByFarmDetails(
    farmDetails.realTimeData,
  );

  let farmName = translateDynamic(farmDetails.farmName);
  let farmerName = translateDynamic(farmDetails.farmer.name);
  let farmerImage = translateDynamic(farmDetails.farmer.imageData);
  const farmerStyle = farmDetails.farmer.style;
  let milkBatch = farmDetails && farmDetails.milkBatch;
  let selectedFarmBreeds = farmDetails.breeds.filter(
    (currentBreed) =>
      currentBreed.probabilityPercentage !== "0" && currentBreed.isSelected,
  );
  let selectedFarmSubbreeds = [];
  selectedFarmBreeds.forEach((currentFarmBreed) => {
    currentFarmBreed.colors
      .filter((currentColor) => currentColor.isSelected)
      .forEach((currentColor) => {
        selectedFarmSubbreeds.push(
          getSubBreedCssClassNameBySubBreed(currentColor.name),
        );
      });
  });
  function getOnboardingContainerJsxElement(milkBatch, farmDetails) {
    let onboardingContainerJsxElement = null;
    let isFirstTimeUser = true;
    if (milkBatch) {
      if (isFirstTimeUser) {
        onboardingContainerJsxElement = (
          <MijnMelkOnboardingAdultContainer
            onNavigateToInitialSlideFn={() => {
              onNavigateToInitialSlideFn(SIDE_MENU_OPTION_AGE_SELECTION);
            }}
            farmId={farmDetails.milkBatch.externalId}
            farmName={farmName}
            farmerName={farmerName}
            farmLocation={
              farmDetails && farmDetails.location && farmDetails.location.name
            }
            farmStyle={farmDetails && farmDetails.style}
            batchProductType={farmDetails?.milkBatch?.batchProductType}
            milkBatch={farmDetails.milkBatch.externalId}
            fatPercentage={farmDetails.milkBatch.fatPercentage}
            proteinPercentage={farmDetails.milkBatch.proteinPercentage}
            lactosePercentage={farmDetails.milkBatch.lactosePercentage}
            farmerImage={farmerImage}
            milkGoingToBottleHour={farmDetails?.milkBatch?.pasteurization}
            milkBatchStartDate={farmDetails.milkBatch.start}
            milkBatchEndDate={farmDetails.milkBatch.end}
            totalNumberOfCowsInMilkBatch={farmDetails.allCows.length}
            numberOfMilkingsInPast24Hours={
              farmDetails.milkBatch.milkingsLast24Hours
            }
            weatherData={currentHourWeatherDetails}
            currentFarmTime={farmDetails.realTimeData.dateTime}
            farmerStyle={farmerStyle}
            otherDevices={farmDetails.otherDevices}
          />
        );
      } else {
        onboardingContainerJsxElement = (
          <RotatingStoryContainer
            onNavigateToInitialSlideFn={() => {
              onNavigateToInitialSlideFn(SIDE_MENU_OPTION_AGE_SELECTION);
            }}
            farmId={farmDetails.milkBatch.externalId}
            farmName={farmName}
            farmerName={farmerName}
            farmLocation={
              farmDetails && farmDetails.location && farmDetails.location.name
            }
            farmStyle={farmDetails && farmDetails.style}
            farmBreeds={selectedFarmBreeds}
            farmSubbreeds={selectedFarmSubbreeds}
            milkBatch={milkBatch}
            farmerImage={farmerImage}
          />
        );
      }
    }
    return onboardingContainerJsxElement;
  }

  return (
    <div className="bottleStoryPage">
      {getOnboardingContainerJsxElement(milkBatch, farmDetails)}
    </div>
  );
}

export default BottleStoryPage;
