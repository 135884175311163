import axios from 'axios';
import { LOGIN_SERVICE_URL, REFRESH_ACCESS_TOKEN_SERVICE_URL } from './config';

export const api = {
  login: async function (username, password) {
    return axios.post(LOGIN_SERVICE_URL, {
      username,
      password,
    });
  },
  refreshAccessToken: async function (accessToken, refreshToken) {
    return axios.post(REFRESH_ACCESS_TOKEN_SERVICE_URL, {
      accessToken,
      refreshToken,
    });
  }
};
