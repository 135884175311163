import React from "react";
import {
  translate,
  translateDynamic,
} from "../../localization/TranslationUtil";
import IconButton from "../IconButton/IconButton";
import "./PetPopup.scss";
import { getDisableCacheAppendix } from "../../common/urlUtil";
import { ACTOR_TYPE_PET } from "../../common/constants";

/** PetPopup component */
function PetPopup({
  selectedPetDetails,
  callToActionClickFn,
  closePopupClickFn,
}) {
  const petPopupTitle =
    translate("FARMER_POPUP_TITLE_PREFIX") + selectedPetDetails.name;
  const petPopupImage = selectedPetDetails.imageData;
  let classNames = {};

  if (selectedPetDetails.animalKind !== "No") {
    switch (selectedPetDetails.type) {
      case ACTOR_TYPE_PET:
        classNames.popUpMain = "dogPopup";
        classNames.popUpTitle = "dogPopup--name";
        classNames.popUpDescription = "dogPopup--description";
        classNames.popUpGraphic = "dogPopup--dog";
        classNames.closeButton = "dogPopup--btnClosePopup";
        break;
    }
  }
  const animalDefaultImage = 'animalPopup--image' + ' ' + `${selectedPetDetails?.animalType}`; 
  console.log('animalDefaultImage',animalDefaultImage); 

  return (
  <div className='animalPopup'>
    <div className={classNames.popUpMain}>
    <IconButton
        cssClassName={classNames.closeButton}
        onClickFn={closePopupClickFn}
      />
      <div className={classNames.popUpTitle}>{petPopupTitle}</div>
      <div className={classNames.popUpDescription}>
        {translateDynamic(selectedPetDetails.story)}
      </div> 
    </div>
      <div className={classNames.popUpGraphic}>
        {
          petPopupImage ? (<img
            src={getDisableCacheAppendix(petPopupImage)}
            className="dogPopup--dog--image"
          ></img>) : <div className={animalDefaultImage} />
        }
        <div className="dogPopup--frame" />
      </div>
  </div> 
  );
}
export default PetPopup;
