import _ from "lodash";
import React, { useEffect } from "react";
import "./CowDailyAstronautPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { translateWithValuesAndHtml } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import IconButton from "../../components/IconButton/IconButton";
import { showPopup } from "../../common/components/PopupContainer/actions";
import { ACTOR_TYPE_ASTRONAUT } from "../../common/constants";

function CowDailyAstronautPage() {
  const dispatch = useDispatch();
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );

  useEffect(() => {}, [!_.isEmpty(selectedCowDetails)]);

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div className="cow-daily--astronaut--slide">
        <TextCaption additionalCssClassname="textCaption--cow-daily--astronaut">
          <span
            className="textCaption--cow-daily--astronaut--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml(
                "COW_DAILY_ASTRONAUT_BOX_TEXT",{cowName : selectedCowDetails?.name}
              ),
            }}
          ></span>
        </TextCaption>
        <div
          className="cow-daily--robot--astronaut"
          onClick={() => {
            dispatch(
              showPopup({ type: ACTOR_TYPE_ASTRONAUT, name: "astronaut" }),
            );
          }}
        ></div>
        <IconButton
          cssClassName="cowDailyAstronaut--button"
          onClickFn={() => {
            dispatch(
              showPopup({ type: ACTOR_TYPE_ASTRONAUT, name: "astronaut" }),
            );
          }}
        />
        <div className="cow-daily--astronaut--arrowText">
          <span className="cow-daily--astronaut--arrow"></span>
          <span
            className="cow-daily--astronaut--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml(
                "COW_DAILY_THIS_IS_ASTRONAUT_TEXT",
              ),
            }}
          ></span>
        </div>
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowDailyAstronautPage;
