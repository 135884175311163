import { POP_CONFETTI, RESET_CONFETTI_REDUCER } from '../common/components/Confetti/types';

/** Store states
 * @constant {Object} */
export const initialState = {
    confettiTrigger: false
};

/** Confetti Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function confettiReducer(state = initialState, action) {
    switch (action.type) {
        case POP_CONFETTI:
            return {
                ...state,
                confettiTrigger: action.payload.confettiTrigger
            };

        case RESET_CONFETTI_REDUCER:
            return {
                ...state,
                confettiTrigger: false
            };
        default:
            return state;
    }
}

export default confettiReducer;
