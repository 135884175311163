import React from "react";
import { translate } from "../../localization/TranslationUtil";
import LabelledTextArea from "../../common/components/LabelledTextArea/LabelledTextArea";
import LabelledImageDropZone from "../../common/components/LabelledImageDropZone/LabelledImageDropZone";
import "./StoryBlock.scss";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import LabelledTextInputForLanguage from "../../common/components/LabelledTextInputForLanguage/LabelledTextInputForLanguage";

/** StoryBlock component.
 * @param {Object} obj - Object which contains all props.
 * @param {Object} [obj.storyBlockData] - Object containing all the information about the story block such as name, photoUrl, etc.
 * @param {Array} [obj.languageKeysArray]
 * @param {Function} [obj.handleStoryBlockChangeFn] - Function that will be called whenever any of the story block information properties has been changed by any of the underlying components
 * @param {Function} [obj.deleteStoryBlockClickFn] - Function that will be called whenever the delete button for the story block is clicked
 * @param {Function} [obj.handleStoryBlockMoveUpClickFn] - Function that will be called whenever the move up story block button has been clicked
 * @param {Function} [obj.handleStoryBlockMoveDownClickFn] - Function that will be called whenever the move down story block button has been clicked
 * @param {Boolean} [obj.isMoveUpEnabled] - Controls whether the move up story block button is enabled
 * @param {Boolean} [obj.isMoveDownEnabled] - Controls whether the move down story block button is enabled
 */
function StoryBlock({
  storyBlockData,
  languageKeysArray,
  handleStoryBlockChangeFn,
  deleteStoryBlockClickFn,
  handleStoryBlockMoveUpClickFn,
  handleStoryBlockMoveDownClickFn,
  isMoveUpEnabled,
  isMoveDownEnabled,
  storyBlocksArray,
}) {
  function getTitleJSXElementsByStoryBlockData(storyBlockData) {
    let storyBlockStoryJSXElements = [];
    languageKeysArray.forEach((currentLanguageKey) => {
      storyBlockStoryJSXElements.push(
        getStoryTitleComponentJSXByData(
          storyBlockData.title[currentLanguageKey] || "",
          currentLanguageKey,
        ),
      );
      storyBlockStoryJSXElements.push(
        getStoryTitleComponentJSXByData(
          storyBlockData.title[currentLanguageKey + "s"] || "",
          currentLanguageKey + "s",
        ),
      );
    });
    return storyBlockStoryJSXElements;
  }

  function getDescriptionJSXElementsByStoryBlockData(storyBlockData) {
    let storyBlockStoryJSXElements = [];
    languageKeysArray.forEach((currentLanguageKey) => {
      storyBlockStoryJSXElements.push(
        getStoryDescriptionComponentJSXByData(
          storyBlockData.description[currentLanguageKey] || "",
          currentLanguageKey,
        ),
      );
      storyBlockStoryJSXElements.push(
        getStoryDescriptionComponentJSXByData(
          storyBlockData.description[currentLanguageKey + "s"] || "",
          currentLanguageKey + "s",
        ),
      );
    });
    return storyBlockStoryJSXElements;
  }
  
  function getStoryTitleComponentJSXByData(
    storyTextForSpecificLanguage,
    currentLanguageKey,
  ) {
    return (
      <LabelledTextInputForLanguage
        labelText={translate("LANGUAGE_" + currentLanguageKey.toUpperCase())}
        initialValue={storyTextForSpecificLanguage}
        handleChangeFn={(newStoryTextForSpecificLanguage) => {
          let copyOfStoryBlockData = _.cloneDeep(storyBlockData);
          copyOfStoryBlockData.title[currentLanguageKey] =
            newStoryTextForSpecificLanguage;
          handleStoryBlockChangeFn(copyOfStoryBlockData);
        }}
        givenLength={28}
        placeholderText={translate("ANIMALS_PAGE_TEXT_AREA_PLACEHOLDER_TEXT")}
        additionalClassName="more-about-you--textArea"
      />
    );
  }

  function getStoryDescriptionComponentJSXByData(
    storyTextForSpecificLanguage,
    currentLanguageKey,
  ) {
    return (
      <LabelledTextArea
        labelText={translate("LANGUAGE_" + currentLanguageKey.toUpperCase())}
        initialValue={storyTextForSpecificLanguage}
        handleChangeFn={(newStoryTextForSpecificLanguage) => {
          let copyOfStoryBlockData = _.cloneDeep(storyBlockData);
          copyOfStoryBlockData.description[currentLanguageKey] =
            newStoryTextForSpecificLanguage;
          handleStoryBlockChangeFn(copyOfStoryBlockData);
        }}
        placeholderText={translate("ANIMALS_PAGE_TEXT_AREA_PLACEHOLDER_TEXT")}
        additionalClassName="more-about-you--textArea"
        givenLength={220}
      />
    );
  }

  function handleDeleteStoryBlockButtonClicked() {
    deleteStoryBlockClickFn(storyBlockData.textBlockIndex);
  }

  function handleMoveUpStoryBlockButtonClicked() {
    handleStoryBlockMoveUpClickFn(storyBlockData.textBlockIndex);
  }

  function handleMoveDownStoryBlockButtonClicked() {
    handleStoryBlockMoveDownClickFn(storyBlockData.textBlockIndex);
  }

  return (
    <div className="StoryBlock">
      <h3 className="StoryBlock--title">{`Story Block ${storyBlockData.textBlockIndex}`}</h3>
      <div className="story-title-input--label">
        {translate("ABOUT_YOU_YOUR_STORY_BLOCK_TITLE_LABEL")}
      </div>
      <Grid container>
        {getTitleJSXElementsByStoryBlockData(storyBlockData)}
      </Grid>
      <div className="story-description-input--label">
        {translate("ABOUT_YOU_YOUR_STORY_BLOCK_DESCRIPTION_CAPTION")}
      </div>
      <Grid container>
        {getDescriptionJSXElementsByStoryBlockData(storyBlockData)}
      </Grid>
      <LabelledImageDropZone
        labelText={translate("ABOUT_YOU_YOUR_STORY_BLOCK_PHOTO_TEXT")}
        dropzoneText={translate(
          "ABOUT_YOU_YOUR_STORY_BLOCK_PHOTO_DROP_ZONE_LABEL",
        )}
        initialValue={storyBlockData && storyBlockData.imageData}
        key={storyBlockData && storyBlockData.imageData}
        onChangeFileFn={(newFileBase64) => {
          handleStoryBlockChangeFn({
            ...storyBlockData,
            imageData: newFileBase64,
          });
        }}
        onDeleteFileFn={() => {
          handleStoryBlockChangeFn({
            ...storyBlockData,
            imageData: "",
          });
        }}
        defaultPreviewCssClassname="storyImagePreview"
      />
      <div className="story-block-buttons-container">
        { storyBlocksArray.length > 1
        ? <button
            style={{ marginTop: "1vw", marginBottom: "1vw" }}
            className="btn-delete-animal-block"
            type="button"
            disabled={false}
            onClick={function () {
              handleDeleteStoryBlockButtonClicked();
            }}
          >
          <i className="lely-icon-trash"></i>
          <span className="btn-delete-story-block--label">{`Delete story block ${storyBlockData.textBlockIndex}`}</span>
        </button>
        : null }
        <div className="move-story-block-buttons-container">
          {isMoveUpEnabled ? (
            <button
              style={{ marginTop: "1vw", marginBottom: "1vw" }}
              className="btn-move-up-story-block"
              type="button"
              disabled={!isMoveUpEnabled}
              onClick={function () {
                handleMoveUpStoryBlockButtonClicked();
              }}
            >
              <i className="lely-icon-triangle"></i>
              <span className="btn-delete-story-block--label">Move up</span>
            </button>
          ) : null}
          {isMoveDownEnabled ? (
            <button
              style={{ marginTop: "1vw", marginBottom: "1vw" }}
              className="btn-move-down-story-block"
              type="button"
              disabled={!isMoveDownEnabled}
              onClick={function () {
                handleMoveDownStoryBlockButtonClicked();
              }}
            >
              <i className="lely-icon-triangle"></i>
              <span className="btn-delete-story-block--label">Move down</span>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default StoryBlock;
