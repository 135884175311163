import {
  CLOSE_SAVE_CHANGES_POPUP,
  REQUEST_APPLICATION_NAVIGATION,
  SAVE_CHANGES_AND_LEAVE,
  SAVE_CHANGES_POPUP_ACTION,
} from "./types.js";
import { put, take, call, select } from "redux-saga/effects";
import {
  NAVIGATION_TYPE_CLOSE_TAB,
  NAVIGATION_TYPE_NAVIGATE_TO_OTHER_PAGE,
} from "./config.js";
import { areChangesSavedGlobally } from "./selectors.js";
import { END_LOADING } from "../../redux/loadManager/types.js";
import { redirect } from "../../../components/CustomRedirect/actions.js";
import {
  hideConfirmSavingChangesPopup,
  requestConfirmSavingChanges,
} from "./actions.js";
import { getLatestSaveFn } from "./util.js";
import { printConsoleHeading } from "../../consoleUtil.js";

export default function* globalSaveChangesSaga(api) {
  while (true) {
    const requestApplicationNavigationAction = yield take(
      REQUEST_APPLICATION_NAVIGATION,
    );
    const {
      type: navigationEventType,
      additionalData: navigationEventAdditionalData,
    } = requestApplicationNavigationAction.payload;
    const areChangesSavedInGlobalSaveChangesReducer = yield select(
      areChangesSavedGlobally,
    );

    if (areChangesSavedInGlobalSaveChangesReducer) {
      // proceed forward with action
      yield call(
        proceedForwardWithAction,
        navigationEventType,
        navigationEventAdditionalData,
      );
    } else {
      // changes are not saved: prompt user with confirm save changes popup and act accordingly
      yield put(requestConfirmSavingChanges()); // force showing save changes confirmation popup
      const saveChangesPopupAction = yield take(SAVE_CHANGES_POPUP_ACTION);

      if (saveChangesPopupAction.payload.type === SAVE_CHANGES_AND_LEAVE) {
        console.log("Saving changes and leave #setSaved");
        let saveChangesClickHandlerForCurrentPageFn = getLatestSaveFn();
        console.log(
          "saveChangesClickHandlerForCurrentPageFn #setSaved",
          saveChangesClickHandlerForCurrentPageFn,
        );
        if (typeof saveChangesClickHandlerForCurrentPageFn === "function") {
          saveChangesClickHandlerForCurrentPageFn();
          yield take(END_LOADING); // wait for save changes request for respective admin app page to be executed successfully
        }
      }
      yield put(hideConfirmSavingChangesPopup());

      if (saveChangesPopupAction.payload.type !== CLOSE_SAVE_CHANGES_POPUP) {
        yield proceedForwardWithAction(
          navigationEventType,
          navigationEventAdditionalData,
        );
      }
    }
  }
}
/** Create request to get virtual farm details
 * @param {string} actionType - The type of user action the user wants (it's either closing the current tab with the application OR navigation to another section of the admin application)
 * @param {string} additionalData - Additional data that might be needed in order to fully execute what the user wants to do (if it is navigation, you will need to know which URL they want to go to next/which AppSidebar menu button they clicked)
 */
function* proceedForwardWithAction(actionType, additionalData) {
  switch (actionType) {
    case NAVIGATION_TYPE_CLOSE_TAB:
      // close the tab here
      //window.close();
      break;
    case NAVIGATION_TYPE_NAVIGATE_TO_OTHER_PAGE:
      yield put(redirect(`/admin/${additionalData}`));
      break;
    default:
      break;
  }
}
