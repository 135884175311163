import {
  START_LOADING,
  END_LOADING,
  END_SEEDING_FARM_DATA,
  START_SEEDING_FARM_DATA,
} from "./types";

/** Create action which will notify the loadManager reducer
 * for the beginning of new load operation
 * (which may be call to an API or some other asynchronous operation)
 * @param {Number} loadId - Number generated by the methods.js/loadId() method
 * @return {Object} Return action for starting loading
 */
export function startLoading(loadId) {
  return {
    type: START_LOADING,
    payload: {
      loadId: loadId,
    },
  };
}

/** Create action which will notify the loadManager reducer
 * for the end of existing load operation
 * @param {Number} loadId - Number generated by the methods.js/loadId() method
 * @return {Object} Return action for starting loading
 */
export function endLoading(loadId) {
  return {
    type: END_LOADING,
    payload: {
      loadId: loadId,
    },
  };
}

/** Create action which will notify the loadManager reducer
 * for the start of farm seeding operation
 * @return {Object} Return action for starting farm data seeding
 */
export function startFarmDataSeeding() {
  return {
    type: START_SEEDING_FARM_DATA,
    payload: {},
  };
}

/** Create action which will notify the loadManager reducer
 * for the end of farm seeding operation
 * @return {Object} Return action for ending farm data seeding
 */
export function endFarmDataSeeding() {
  return {
    type: END_SEEDING_FARM_DATA,
    payload: {},
  };
}
