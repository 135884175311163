import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
import { getGrazewaySvgFilePath } from "../../common/svgUtil";
import Graphic from "../Graphic/Graphic";
import React, { useCallback } from "react";
import "./GrazewayFeature.scss";
import { measureResponsive } from "../../components/VirtualFarm/util";
import {
  ADULT_THEME_CODE,
  IS_CLICKABLE_CSS_CLASSNAME,
} from "../../common/constants";

/** grazewayFeature component */
function GrazewayFeature({
  grazewayFeatureData,
  grazewayFeatureCssClassname,
  grazewayFeatureXCoordinate,
  grazewayFeatureYCoordinate,
  grazewayFeatureScaleX,
  grazewayFeatureClickHandlerFn,
  additionalCssClassnames,
  farmTheme,
  doPositionFixed,
  isClickable,
  isInteractable,
  isAdultSelected,
}) {
  let transformValue =
    "translateX(" +
    grazewayFeatureXCoordinate +
    ") translateY(" +
    grazewayFeatureYCoordinate +
    ") scaleX(" +
    grazewayFeatureScaleX +
    ")";
  let grazewayFeaturePositionStyles = doPositionFixed
    ? {}
    : {
        transform: transformValue,
      };
  let grazewaySubmoduleCssClassname =
    grazewayFeatureCssClassname + " " + additionalCssClassnames;
  grazewaySubmoduleCssClassname += isInteractable
    ? " virtualFarm--interactable virtualFarm--highlighted"
    : "";
  grazewaySubmoduleCssClassname = isClickable
    ? grazewaySubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : grazewaySubmoduleCssClassname;
  let grazewaySvgPath = getGrazewaySvgFilePath(farmTheme);
  const onCompletedCallback = useCallback((e) => {}, []);
  const onErrorCallback = useCallback((e) => {
    console.log("@onError e:", e);
  }, []);

  return (
    <div
      style={{
        left: measureResponsive(31.3658),
        width: measureResponsive(9.23646),
        top: measureResponsive(28.494),
        height: measureResponsive(9.23646),
      }}
      className={grazewaySubmoduleCssClassname}
      onClick={() => {
        grazewayFeatureClickHandlerFn(grazewayFeatureData);
      }}
    >
      <div
        className="grazewayFeature--door"
        style={{
          left: measureResponsive(40.779),
          width: measureResponsive(4.248775),
          top: measureResponsive(30.43416),
          height: measureResponsive(3.69458),
        }}
      ></div>
      <h2
        style={{
          transform: "scaleX(" + grazewayFeatureScaleX + ")",
        }}
        className={grazewayFeatureCssClassname + "--title"}
      >
        {grazewayFeatureData.name}
      </h2>
      {isInteractable ? (
        <DynamicInlineSVG
          svgFullFilePath={grazewaySvgPath}
          themeName={isAdultSelected ? ADULT_THEME_CODE : farmTheme}
          onCompleted={onCompletedCallback}
          onError={onErrorCallback}
        />
      ) : null}
      {isInteractable ? <Graphic cssClassName="is-actor-pointed" /> : null}
    </div>
  );
}
export default GrazewayFeature;
