import React from "react";
import "./Shop.scss";
import { measureResponsive } from "../../components/VirtualFarm/util";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";
/** Shop component */
function Shop({ shopData, shopCssClassname, isClickable, shopClickHandlerFn }) {
  return (
    <div
      style={{
        left: measureResponsive(76.2008),
        width: measureResponsive(8.31282),
        top: measureResponsive(6.92735),
        height: measureResponsive(10.1601),
      }}
      className={
        isClickable
          ? shopCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
          : shopCssClassname
      }
      onClick={() => {
        shopClickHandlerFn(shopData);
      }}
    />
  );
}
export default Shop;
