import { Slider } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

// Styles
import './LabelledSlider.scss';

/** LabelledSlider component.
 * @param {Object} obj - Object which contains all props.
 */
function LabelledSlider({
  tooltip,
  placeholder,
  dataTestID,
  labelText,
  minValue,
  maxValue,
  stepValue,
  defaultValue,
  onHandleChangeFn,
  isDisabled
}) {
  const [value, setValue] = useState('');


  /**
   * @param {Object} event - Contains current value of input filed.
   */
  function handleChange(event, value) {
    setValue(value);
    onHandleChangeFn(value);
  }


  return (
    <div className="labelled-slider">
      <label className="labelled-slider--label">{labelText}</label>
      <div className="labelled-slider--slider-container">
        <Slider aria-label={labelText}
          onChange={handleChange}
          min={minValue}
          max={maxValue}
          step={stepValue}
          defaultValue={defaultValue}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}

export default LabelledSlider;