import { GET_NETWORK_ERROR_CODE } from '../common/redux/errorCodeHandler/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  errorCode: '',
};

/** Network Error code Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function networkErrorCodeReducer(state = initialState, action) {
  switch (action.type) {
    /** Action Creator - Set network error code to store */
    case GET_NETWORK_ERROR_CODE:
      return {
        ...state,
        errorCode: action.payload.errorCode,
      };
    default:
      return state;
  }
}

export default networkErrorCodeReducer;
