import _ from "lodash";
import React, { useEffect, useState } from "react";
import "./CowDailyEatingPage.scss";
import { useSelector } from "react-redux";
import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import IconButton from "../../components/IconButton/IconButton";
import AstronautPopup from "../../components/AstronautPopup/AstronautPopup";
import Cow from "../../components/Cow/Cow";
import { COW_CSS_CLASSNAME } from "../../common/constants";
import moment from "moment";

function CowDailyEatingPage({
  showActorStoryFn,
  additionalCssClassname,
  cowDailyDetails,
}) {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const [isAstronautPopupDisplayed, setIsAstronautPopupDisplayed] =
    useState(false);
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  useEffect(() => {
  }, [!_.isEmpty(selectedCowDetails)]);

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div
        className={
          additionalCssClassname
            ? "cow-daily--eating--slide " + additionalCssClassname
            : "cow-daily--eating--slide"
        }
      >
        {additionalCssClassname === "CowDailyEating1Slide" ? (
          <TextCaption additionalCssClassname="textCaption--cowDailyEating">
            <h3 className="textCaption--heading textCaption--heading--milking">
              {cowDailyDetails &&
                moment(new Date(cowDailyDetails.lastUpdated))
                  .utc()
                  .format("DD MMMM YYYY, HH:mm")
                  .toUpperCase()}
            </h3>
            <p
              className="cowMilkingText"
              dangerouslySetInnerHTML={{
                __html: translateWithValuesAndHtml("COW_DAILY_EATING_TEXT", { 
                  cowName : selectedCowDetails.name,
                  foodInKg:
                    cowDailyDetails && cowDailyDetails.feedIntakeConcentrateKg,
                }),
              }}
            ></p>
          </TextCaption>
        ) : (
          ""
        )}
        {additionalCssClassname === "CowDailyEating1Slide" ? (
          <Cow
            cowData={selectedCowDetails}
            cowCssClassname={COW_CSS_CLASSNAME}
            cowXCoordinate={40 + "vw"}
            cowYCoordinate={0}
            cowScaleX={1}
            cowClickHandlerFn={(clickedCowData) => {}}
            additionalCssClassnames="cow--bigger cow--daily-eating"
            doPositionFixed={true}
          />
        ) : (
          ""
        )}
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowDailyEatingPage;
