import React from "react";
import { translateDynamic } from "../../localization/TranslationUtil";
import "./Pet.scss";
import { measureResponsive } from "../../components/VirtualFarm/util";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";
/** Pet component */
function Pet({
  petData,
  petCssClassname,
  petXCoordinate,
  petYCoordinate,
  petScaleX,
  isClickable,
  petClickHandlerFn,
}) {
  const animalType =
    petData && petData.animalType && petData.animalType.toLowerCase();
  let petSubmoduleCssClassname = petCssClassname + "--" + animalType;
  petSubmoduleCssClassname = isClickable
    ? petSubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : petSubmoduleCssClassname;
  return (
    <div
      style={{
        left: measureResponsive(petYCoordinate),
        width: measureResponsive(4.0408),
        top: measureResponsive(petXCoordinate),
        height: measureResponsive(5.6064),
        position: "absolute",
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
        className={petCssClassname + " " + petSubmoduleCssClassname}
        onClick={() => {
          petClickHandlerFn(petData);
        }}
      >
        <h2
          style={{
            transform: "scaleX(" + petScaleX + ")",
          }}
          className={petCssClassname + "--title"}
        >
          {translateDynamic(petData)}
        </h2>
      </div>
    </div>
  );
}
export default Pet;
