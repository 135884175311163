import _ from "lodash";
import React, { useEffect, useState } from "react";
import "./CowsPage.scss";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { translate } from "../../../localization/TranslationUtil";
import { Button } from "@material-ui/core";
import LabelledSwitch from "../../../common/components/LabelledSwitch/LabelledSwitch";
import LabelledSlider from "../../../common/components/LabelledSlider/LabelledSlider";
import { requestCowsPageInformation, saveChangesToCowsPage } from "./actions";
import LabelledDatePicker from "../../../common/components/LabelledDatePicker/LabelledDatePicker";
import LabelledTextInput from "../../../common/components/LabelledTextInput/LabelledTextInput";
import { getGrazingDates } from "../../../common/grazingDateUtil";
import LabelledCowBreedSelector from "../../../common/components/LabelledCowBreedSelector/LabelledCowBreedSelector";
import useSaveManager from "../../../hooks/useSaveManager";
import SaveChangesConfirmationDialog from "../../../components/SaveChangesConfirmationDialog/SaveChangesConfirmationDialog";

function CowsPage() {
  const dispatch = useDispatch();
  const [hideLargeImageClassname, setHideLargeImageClassname] = useState(
    "cow-hide-preview--large--blackstryped",
  );
  const [hideSmallImageClassname, setHideSmallImageClassname] = useState(
    "cow-hide-preview--small--white",
  );
  const { accessToken } = useSelector((state) => ({ ...state.userSession }));
  const { cowsPageDetails } = useSelector((state) => ({
    ...state.cowsPageDetails,
  }));
  useEffect(() => {
    dispatch(requestCowsPageInformation(accessToken));
  }, []);

  const [newCowsDetails, setNewCowsDetails] = useState(cowsPageDetails);
  const { isSaveChangesConfirmationPopupDisplayed } = useSelector((state) => ({
    ...state.saveChangesDetails,
  }));

  function handleSaveChangesButtonClick() {
    let cowsDetailsWithBreedSelection = mergeBreedSelectionIntoNewCowsDetails(
      latestBreedSelection,
      newCowsDetails,
    );
    dispatch(saveChangesToCowsPage(accessToken, cowsDetailsWithBreedSelection));
    setSaved(true);
  }

  const [isSaved, setIsSaved] = useState(true);
  const { setSaved, lastSaveTimestamp } = useSaveManager(
    handleSaveChangesButtonClick,
    isSaved,
    setIsSaved,
  );

  const [latestBreedSelection, setLatestBreedSelection] = useState([]);
  const [
    shouldPercentageErrorBeDisplayed,
    setShouldPercentageErrorBeDisplayed,
  ] = useState(false);

  useEffect(() => {
    setNewCowsDetails(cowsPageDetails);
    if (cowsPageDetails.cowType === "friesian") {
      setHideLargeImageClassname("cow-hide-preview--large--blackstryped");
    } else if (cowsPageDetails.cowType === "holstein") {
      setHideLargeImageClassname("cow-hide-preview--large--brown");
    } else if (cowsPageDetails.cowType === "whiteBlack") {
      setHideLargeImageClassname("cow-hide-preview--large--whiteBlack");
    } else if (cowsPageDetails.cowType === "pride") {
      setHideLargeImageClassname("cow-hide-preview--large--pride");
    } else {
      setHideLargeImageClassname("cow-hide-preview--large--blackstryped");
    }
  }, [cowsPageDetails]);

  useEffect(() => {
    setNewCowsDetails(cowsPageDetails);
  }, [cowsPageDetails]);

  useEffect(() => {}, [latestBreedSelection]);

  //#region cow preview size logic
  const [cowWidth, setCowWidth] = useState(100);
  const [cowHeight, setCowHeight] = useState(100);
  const MAX_COW_WIDTH = 100;
  const MAX_COW_HEIGHT = 100;
  const MIN_COW_WIDTH = 30;
  const MIN_COW_HEIGHT = 30;
  const MIN_LARGE_PREVIEW_IMAGE_SIZE_IN_VW = 25;
  const MAX_LARGE_PREVIEW_IMAGE_SIZE_IN_VW = 35;
  const MIN_SMALL_PREVIEW_IMAGE_SIZE_IN_VW = 5;
  const MAX_SMALL_PREVIEW_IMAGE_SIZE_IN_VW = 10;

  let calculatedLargePreviewWidth;
  let calculatedLargePreviewHeight;
  let calculatedSmallPreviewWidth;
  let calculatedSmallPreviewHeight;

  function areCurrentBreedPercentagesValid(latestBreedSelection) {
    let totalPercentage =
      getLatestBreedSelectionTotalPercentage(latestBreedSelection);
    return totalPercentage === 100;
  }

  function getLatestBreedSelectionTotalPercentage(latestBreedSelection) {
    let totalPercentage = 0;
    latestBreedSelection.map((currentBreed) => {
      totalPercentage += parseInt(currentBreed.probabilityPercentage);
    });
    return totalPercentage;
  }

  function getValueRatio(value, minValue, maxValue) {
    let distanceBetweenMinAndMax = maxValue - minValue;
    let delta = value - minValue;
    return delta / distanceBetweenMinAndMax;
  }

  let widthRatio = getValueRatio(cowWidth, MIN_COW_WIDTH, MAX_COW_WIDTH);
  let heightRatio = getValueRatio(cowHeight, MIN_COW_HEIGHT, MAX_COW_HEIGHT);
  calculatedLargePreviewWidth =
    widthRatio *
      (MAX_LARGE_PREVIEW_IMAGE_SIZE_IN_VW -
        MIN_LARGE_PREVIEW_IMAGE_SIZE_IN_VW) +
    MIN_LARGE_PREVIEW_IMAGE_SIZE_IN_VW;
  calculatedLargePreviewHeight =
    heightRatio *
      (MAX_LARGE_PREVIEW_IMAGE_SIZE_IN_VW -
        MIN_LARGE_PREVIEW_IMAGE_SIZE_IN_VW) +
    MIN_LARGE_PREVIEW_IMAGE_SIZE_IN_VW;
  calculatedSmallPreviewWidth =
    widthRatio *
      (MAX_SMALL_PREVIEW_IMAGE_SIZE_IN_VW -
        MIN_SMALL_PREVIEW_IMAGE_SIZE_IN_VW) +
    MIN_SMALL_PREVIEW_IMAGE_SIZE_IN_VW;
  calculatedSmallPreviewHeight =
    heightRatio *
      (MAX_SMALL_PREVIEW_IMAGE_SIZE_IN_VW -
        MIN_SMALL_PREVIEW_IMAGE_SIZE_IN_VW) +
    MIN_SMALL_PREVIEW_IMAGE_SIZE_IN_VW;
  //#endregion

  function editCowBreed(newCowBreedBlock) {
    let newCowsBreedBlock = _.cloneDeep(cowsPageDetails);
    let currentCowBreedBlocks = newCowsBreedBlock.breeds
      ? newCowsBreedBlock.breeds
      : [];
    let cowBlockBreedCopy = _.cloneDeep(currentCowBreedBlocks);
    cowBlockBreedCopy = newCowBreedBlock;
    newCowsBreedBlock.breeds = cowBlockBreedCopy;
  }

  function handleGrazingSwitchChange(labelText, value, checked) {
    let cowsPageDetailsCopy = _.cloneDeep(newCowsDetails);
    checked
      ? (cowsPageDetailsCopy.grazingData.flag = 1)
      : (cowsPageDetailsCopy.grazingData.flag = 0);
    setNewCowsDetails(cowsPageDetailsCopy);
    setSaved(false);
  }
  //#region exact preview images logic
  function getLargePreviewImageBySelectionDictionary(
    currentSelectionDictionary,
  ) {
    let selectedValues = Object.keys(currentSelectionDictionary).filter(
      (currentKey) => {
        return currentSelectionDictionary[currentKey] === true;
      },
    );
    const isFriesian = selectedValues.indexOf("friesian") > -1;
    const isHolstein = selectedValues.indexOf("holstein") > -1;
    const isWhiteBlack = selectedValues.indexOf("whiteBlack") > -1;
    const isPride = selectedValues.indexOf("pride") > -1;
    if (isFriesian) {
      setNewCowsDetails({ ...newCowsDetails, cowType: "friesian" });
      setHideLargeImageClassname("cow-hide-preview--large--blackstryped");
    } else if (isHolstein) {
      setHideLargeImageClassname("cow-hide-preview--large--brown");
      setNewCowsDetails({ ...newCowsDetails, cowType: "holstein" });
    } else if (isWhiteBlack) {
      setHideLargeImageClassname("cow-hide-preview--large--whiteBlack");
      setNewCowsDetails({ ...newCowsDetails, cowType: "whiteBlack" });
    } else if (isPride) {
      setHideLargeImageClassname("cow-hide-preview--large--pride");
      setNewCowsDetails({ ...newCowsDetails, cowType: "pride" });
    } else {
      setHideLargeImageClassname("cow-hide-preview--large--blackstryped");
      setNewCowsDetails({ ...newCowsDetails, cowType: "friesian" });
    }
  }
  //#endregion

  function isGrazingSelected(flag) {
    return flag ? true : false;
  }

  function doesBreedSelectionChangeRequireSaving(
    newBreedSelection,
    latestBreedSelection,
  ) {
    const isAnyOfBreedSelectionsEmpty =
      newBreedSelection.length === 0 || latestBreedSelection.length === 0; // might seriously imply uninitialized breed selection component
    const areThereNoChangesToOriginalSelection = _.isEqual(
      newBreedSelection,
      latestBreedSelection,
    );
    return (
      !isAnyOfBreedSelectionsEmpty && !areThereNoChangesToOriginalSelection
    );
  }

  function mergeBreedSelectionIntoNewCowsDetails(
    latestBreedSelection,
    newCowsDetails,
  ) {
    let newCowsDetailsCopy = _.cloneDeep(newCowsDetails);
    latestBreedSelection.forEach((currentBreed) => {
      newCowsDetailsCopy.breeds[currentBreed.index] = currentBreed;
    });
    let selectedBreedNames = latestBreedSelection.map(
      (currentBreed) => currentBreed.name,
    );
    newCowsDetailsCopy &&
      newCowsDetailsCopy.breeds &&
      newCowsDetailsCopy.breeds.forEach((currentBreed) => {
        if (selectedBreedNames.indexOf(currentBreed.name) !== -1) {
          currentBreed = latestBreedSelection.filter(
            (currentSelectedBreed) =>
              currentSelectedBreed.name === currentBreed.name,
          )[0];
          currentBreed.isSelected = true;
        } else {
          currentBreed.isSelected = false;
        }
      });
    return newCowsDetailsCopy;
  }
  const calculatedGrazingDays = getGrazingDates(
    newCowsDetails &&
      newCowsDetails.grazingData &&
      newCowsDetails.grazingData.dateTo,
    newCowsDetails &&
      newCowsDetails.grazingData &&
      newCowsDetails.grazingData.dateFrom,
  );

  let shouldShowBreedPercentageSumError =
    !areCurrentBreedPercentagesValid(latestBreedSelection);
  let breedSelectionContainerCssClassname = shouldShowBreedPercentageSumError
    ? "cow--breed--container is-percentage-error"
    : "cow--breed--container";
  return (
    <div className="CowsPage">
      <h3 className="cows--header">{translate("COWS_PAGE_HEADING")}</h3>
      <p className="cows--intro">{translate("COWS_PAGE_INTRO_TEXT")}</p>
      <div className="cows--grazing--box">
        <LabelledSwitch
          labelText={translate("COWS_PAGE_GRAZING_LABEL")}
          additionalClassName="cows--grazing"
          onHandleChangeFn={handleGrazingSwitchChange}
          defaultValue={isGrazingSelected(
            newCowsDetails &&
              newCowsDetails.grazingData &&
              newCowsDetails.grazingData.flag,
          )}
        />
        {newCowsDetails &&
        newCowsDetails.grazingData &&
        newCowsDetails.grazingData.flag == "1" ? (
          <LabelledDatePicker
            startDateLabelText={translate("DATE_PICKER_FROM_TEXT")}
            endDateLabelText={translate("DATE_PICKER_TILL_TEXT")}
            startDate={
              newCowsDetails &&
              newCowsDetails.grazingData &&
              newCowsDetails.grazingData.dateFrom
            }
            endDate={
              newCowsDetails &&
              newCowsDetails.grazingData &&
              newCowsDetails.grazingData.dateTo
            }
            onDateValidityChangeFunction={(date) => {}}
            onDateChangeFunction={(date) => {
              let modifiedGrazingDetailsCopy = _.cloneDeep(newCowsDetails);
              let modifiedGrazingStartDate = moment(date.start).format("L");
              let modifiedGrazingEndDate = moment(date.end).format("L");
              modifiedGrazingDetailsCopy.grazingData.dateFrom =
                modifiedGrazingStartDate;
              modifiedGrazingDetailsCopy.grazingData.dateTo =
                modifiedGrazingEndDate;
              setNewCowsDetails(modifiedGrazingDetailsCopy);
              setSaved(false);
            }}
          />
        ) : null}{" "}
        {newCowsDetails &&
        newCowsDetails.grazingData &&
        newCowsDetails.grazingData.flag == "1" ? (
          <LabelledTextInput
            disabled={true}
            initialValue={calculatedGrazingDays}
            labelText={translate("COWS_PAGE_DAYS_OUTSIDE_LABEL")}
            // handleChangeFn={(newGrazingDays) => {
            //   let modifiedGrazingDetailsCopy = _.cloneDeep(newCowsDetails);
            //   modifiedGrazingDetailsCopy.grazingData.days =
            //     calculatedGrazingDays;
            //   setNewCowsDetails(modifiedGrazingDetailsCopy);
            //   setSaved(false);
            // }}
            additionalClassName="cows--textField"
          />
        ) : null}
      </div>
      <div className={breedSelectionContainerCssClassname}>
        {newCowsDetails && newCowsDetails.breeds ? (
          <>
            <LabelledCowBreedSelector
              newCowsDetails={newCowsDetails}
              onBreedSelectionChangeFn={(newBreedSelection) => {
                if (
                  doesBreedSelectionChangeRequireSaving(
                    newBreedSelection,
                    latestBreedSelection,
                  )
                ) {
                  setSaved(false);
                }
                setLatestBreedSelection(newBreedSelection);
              }}
            />
            {shouldShowBreedPercentageSumError ? (
              <p className="sum-of-breed-percentages-error-msg">
                {translate("SUM_OF_BREED_ERROR_MESSAGE")}
              </p>
            ) : null}
          </>
        ) : null}
      </div>

      {/* <LabelledSwitch
        labelText={translate('COWS_PAGE_HORNS_LABEL')}
        value={'0'}
        additionalClassName="cows--horns"
        //handleChangeFn={() => setSaved(false)}
      />
      <CheckboxGroupSelection
        componentLabel={translate('COWS_PAGE_EARMARK_LABEL')}
        optionsArray={[
          {
            label: translate('COWS_PAGE_EARMARK_VALUE_1_LABEL'),
            classNameAppendix: 'earmark1',
          },
          {
            label: translate('COWS_PAGE_EARMARK_VALUE_2_LABEL'),
            classNameAppendix: 'earmark2',
          },
        ]}
        onSelectionChange={() => setSaved(false)}
      />
      <h4>{translate('COWS_PAGE_HIDE_COLORS_LABEL')}</h4> */}
      {/* <ImageCheckSelection
        componentLabel={translate('COWS_PAGE_HIDE_COLORS_LABEL')}
        optionsArray={[
          {
            label: '',
            classNameAppendix: 'hide-2',
            value: 'friesian',
          },
          {
            label: '',
            classNameAppendix: 'hide-3',
            value: 'whiteBlack',
          },
          {
            label: '',
            classNameAppendix: 'hide-5',
            value: 'holstein',
          },
          {
            label: '',
            classNameAppendix: 'hide-8',
            value: 'pride',
          },
        ]}
        initialSelectedValue={newCowsDetails.cowType}
        onSelectionChange={(newSelectionDictionary) => {
          getLargePreviewImageBySelectionDictionary(newSelectionDictionary);
          setSaved(false);
        }}
      /> */}
      <h4>{translate("COWS_PAGE_COW_SIZE_LABEL")}</h4>
      <LabelledSlider
        labelText={translate("COWS_PAGE_COW_SIZE_WIDTH_LABEL")}
        minValue={0}
        maxValue={100}
        stepValue={10}
        defaultValue={100}
        onHandleChangeFn={(newValue) => {
          setCowWidth(newValue);
          setSaved(false);
        }}
      />
      <LabelledSlider
        labelText={translate("COWS_PAGE_COW_SIZE_HEIGHT_LABEL")}
        minValue={0}
        maxValue={100}
        stepValue={10}
        defaultValue={100}
        onHandleChangeFn={(newValue) => {
          setCowHeight(newValue);
          setSaved(false);
        }}
      />
      <div
        className={"cow-hide-preview--large " + hideLargeImageClassname}
        style={{
          width: calculatedLargePreviewWidth + "vw",
          height: calculatedLargePreviewHeight + "vw",
        }}
      />
      {isSaved && lastSaveTimestamp !== 0 ? (
        <Button
          className="btn-already-saved"
          disabled={false}
          onClick={() => {}}
        >
          {translate("ADMIN_PAGE_ALREADY_SAVED_CHANGES_BUTTON_LABEL")}
        </Button>
      ) : null}
      {!isSaved ? (
        <Button
          className="btn-save-changes"
          disabled={shouldShowBreedPercentageSumError}
          onClick={handleSaveChangesButtonClick}
        >
          {translate("COWS_PAGE_SAVE_CHANGES_BUTTON_LABEL")}
        </Button>
      ) : null}
      {isSaveChangesConfirmationPopupDisplayed ? (
        <SaveChangesConfirmationDialog />
      ) : null}
    </div>
  );
}

export default CowsPage;
