import React from 'react';
import './LabelValue.scss';

/** LabelValue component */
function LabelValue({additionalCssClassname, label, value}) {
  return (
    <div className={"labelValue " + additionalCssClassname}>
      <span className="labelValue--label">{label}</span>      
      <span className="labelValue--value">{value}</span>
    </div>
  );
}
export default LabelValue;
