import React from "react";
import { useSelector } from "react-redux";
import "./Moon.scss";
import moment from "moment";
import { getPlanetsPositionByCurrentMomentAndViewportSize20 } from "../Sun/PlanetPositionUtil";

/** Moon component */
function Moon({
  viewportWidthInPx,
  viewportHeightInPx,
  cssClassName,
  isAdultThemeApplied,
}) {
  let { currentMoment, sunrise, sunset } = useSelector(
    (state) => state.storyMoment,
  );
  currentMoment = moment(currentMoment);

  let sunriseHour =
    sunrise && sunrise.split(":")[0] != "null" ? sunrise.split(":")[0] : "06";
  let sunriseMinutes =
    sunrise && sunrise.split(":")[1] != "null" ? sunrise.split(":")[1] : "00";
  let sunsetHour =
    sunset && sunset.split(":")[0] != "null" ? sunset.split(":")[0] : "22";
  let sunsetMinutes =
    sunset && sunset.split(":")[1] != "null" ? sunset.split(":")[1] : "00";

  let planetsPosition = getPlanetsPositionByCurrentMomentAndViewportSize20(
    currentMoment,
    viewportWidthInPx,
    viewportHeightInPx,
    sunriseHour,
    sunriseMinutes,
    sunsetHour,
    sunsetMinutes,
  );
  let moonCssClassname = "moon";
  moonCssClassname = isAdultThemeApplied
    ? moonCssClassname + " moon--adult"
    : moonCssClassname;

  return (
    <div className="moon--container">
      <div
        className={moonCssClassname}
        style={{
          transform: `translateX(${planetsPosition.moon.top}px) translateY(${planetsPosition.moon.left}px) translateZ(1px)`,
        }}
      ></div>
    </div>
  );
}
export default Moon;
