import {
  REQUEST_COWS_PAGE_INFORMATION,
  COWS_PAGE_INFORMATION_SUCCESS,
  COWS_PAGE_INFORMATION_ERROR,
  SAVE_CHANGES_TO_COWS_PAGE,
  SAVE_CHANGES_TO_COWS_PAGE_SUCCESS,
  SAVE_CHANGES_TO_COWS_PAGE_ERROR,
} from './types';

/** Create action which will receive the text blocks for the selected farmer
 * @param {string} accessToken - User access token
 * @return {Object} Returns action object
 */
export function requestCowsPageInformation(accessToken) {
  return {
    type: REQUEST_COWS_PAGE_INFORMATION,
    payload: {
      accessToken: accessToken,
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of the Cows page in the Admin app
 * @param {Object} textBlocksDetails - an object containing the data to be used in order to initialize the Cows page in the Admin app
 * @return {Object} Return type and farmerDetails
 */
export function getCowsInformationSuccess(textBlocksDetails) {
  return {
    type: COWS_PAGE_INFORMATION_SUCCESS,
    payload: textBlocksDetails,
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getCowsInformationError(errorMessage) {
  return {
    type: COWS_PAGE_INFORMATION_ERROR,
    payload: errorMessage,
  };
}

/** Create action which to be used whenever the user clicks the Save changes button in the Cows page in the Admin app
 * @param {string} accessToken - User access token
 * @param {Object} newCowsDetails - The latest state of farmer details page (with all the latest changes that the user has made)
 * @return {Object} Returns action object
 */
export function saveChangesToCowsPage(accessToken, newCowsDetails) {
  return {
    type: SAVE_CHANGES_TO_COWS_PAGE,
    payload: {
      accessToken: accessToken,
      newCowsDetails: newCowsDetails,
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of the Cows page in the Admin app
 * @return {Object} Returns action object
 */
export function saveChangesToCowsPageSuccess(newCowsDetails) {
  return {
    type: SAVE_CHANGES_TO_COWS_PAGE_SUCCESS,
    payload: { newCowsDetails },
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function saveChangesToCowsPageSuccessError(errorMessage) {
  return {
    type: SAVE_CHANGES_TO_COWS_PAGE_ERROR,
    payload: errorMessage,
  };
}
