import React from "react";
import "./MilkBatchTemperatureIcon.scss";

function MilkBatchTemperatureIcon({ weatherData }) {
  if (!weatherData) {
    return null;
  }

  return (
    <div className="milkBatchTemperatureIcon">
      <span className="milkBatchTemperatureIcon--icon"></span>
      <span className="milkBatchTemperatureIcon--temperatureText">
        {weatherData.temp + "°C"}
      </span>
    </div>
  );
}

export default MilkBatchTemperatureIcon;
