import _ from "lodash";
import React, { useEffect, useState } from "react";
import "./SlidesContainerPage.scss";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "../../components/IconButton/IconButton";
import SideMenu from "../../components/SideMenu/SideMenu";
import {
  ACTOR_TYPE_COOKIES,
  SAMPLE_BATCH_ID,
  SAMPLE_FARM_ID,
  SIDE_MENU_OPTION_AGE_SELECTION,
  SIDE_MENU_OPTION_HOW_TO,
  SIDE_MENU_OPTION_TO_THE_FARM,
} from "../../common/constants";
import { getVirtualFarmDetails } from "../../components/VirtualFarm/actions";
import Flow from "../../components/Flow/Flow";
import { useLocation } from "react-router";
import {
  DEFAULT_ONBOARDING,
  MIJN_MELK_ONBOARDING,
  KOOL_CHEESE_ONBOARDING,
  STORY_CHEESE_FACTORY,
  STORY_COW_HISTORY,
  STORY_COW_LATEST,
  STORY_FARMER,
  STORY_ICE_CREAM_FACTORY,
  MILK_BOTTLE_ONBOARDING,
  STORY_VECTOR_ROBOT,
  STORY_JUNO_ROBOT,
  STORY_ASTRONAUT_ROBOT,
  STORY_LELY_BRAND,
  STORY_LUNA,
  STORY_ORBITER_ROBOT,
  STORY_ORBITER_ROBOT_YOGHURT,
  STORY_DISCOVERY,
  STORY_MIJN_MELK,
  STORY_ASTRONAUT_LIVE_DATA,
  STORY_SUSTAINABILITY,
  STORY_ANIMAL_WELFARE,
} from "../../common/stories";
import { DEFAULT_THEME_OPTIONS } from "../../themes/themes";
import PopupContainer from "../../common/components/PopupContainer/PopupContainer";
import {
  resetPopupInfo,
  showPopup,
} from "../../common/components/PopupContainer/actions";
import { useCallback } from "react";
import {
  activateSimpleModeForCurrentLanguage,
  deactivateSimpleModeForCurrentLanguage,
} from "../../localization/TranslationUtil";
import UnavailablePage from "../UnavailablePage/UnavailablePage";
import { setGoogleAnalyticTracking } from "../../common/googleAnalyticTracking/actions";
import { BACK_TO_FARM_CLICKED } from "../../common/googleAnalyticTracking/types";

function SlidesContainerPage() {
  const dispatch = useDispatch();

  const search = useLocation().search;
  const farmId = new URLSearchParams(search).get("f");
  const batchId = new URLSearchParams(search).get("b");

  const [isSideMenuDisplayed, setIsSideMenuDisplayed] = useState(false);
  const [startPoint, setStartPoint] = useState("");

  const { areCookiesAccepted } = useSelector((state) => state.cookieDetails);

  useEffect(() => {
    let doUseSampleFarm = !farmId && !batchId;
    dispatch(resetPopupInfo());
    if (doUseSampleFarm) {
      dispatch(getVirtualFarmDetails(SAMPLE_FARM_ID, SAMPLE_BATCH_ID));
    } else {
      dispatch(getVirtualFarmDetails(farmId, batchId));
    }

    if (!areCookiesAccepted) {
      dispatch(showPopup({ type: ACTOR_TYPE_COOKIES }));
    }
  }, []);

  useEffect(() => {
    if (farmDetails.isAdultSelected) {
      deactivateSimpleModeForCurrentLanguage(dispatch);
    } else {
      activateSimpleModeForCurrentLanguage(dispatch);
    }
  }, [!_.isEmpty(farmDetails)]);

  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);

  const sideMenuClickFnCallback = useCallback((option) => {
    switch (option) {
      case SIDE_MENU_OPTION_TO_THE_FARM:
        dispatch(
          setGoogleAnalyticTracking(
            BACK_TO_FARM_CLICKED,
            BACK_TO_FARM_CLICKED,
            farmId + "/" + BACK_TO_FARM_CLICKED,
          ),
        );
        setStartPoint(SIDE_MENU_OPTION_TO_THE_FARM);
        break;
      case SIDE_MENU_OPTION_AGE_SELECTION:
        setStartPoint(SIDE_MENU_OPTION_AGE_SELECTION);
        break;
      case SIDE_MENU_OPTION_HOW_TO:
        setStartPoint(SIDE_MENU_OPTION_HOW_TO);
        break;
      // default:
      //   setStartPoint("");
      //   break;
    }
  }, []);

  function handleScrollOrSwipeInTheApp() {
    setIsSideMenuDisplayed(false);
  }

  function getOnboardingStoryConfigByThemeSelected(selectedTheme, batchless) {
    switch (selectedTheme) {
      case DEFAULT_THEME_OPTIONS[0].code:
        return DEFAULT_ONBOARDING;
      // case DEFAULT_THEME_OPTIONS[1].code:
      //   return DEFAULT_ONBOARDING;
      // case DEFAULT_THEME_OPTIONS[2].code:
      //   return MIJN_MELK_ONBOARDING;
      // case DEFAULT_THEME_OPTIONS[3].code:
      //   return MIJN_MELK_ONBOARDING;
      // case DEFAULT_THEME_OPTIONS[4].code:
      //   return KOOL_CHEESE_ONBOARDING;
      case DEFAULT_THEME_OPTIONS[1].code:
        return !batchless ? MILK_BOTTLE_ONBOARDING : DEFAULT_ONBOARDING;
      default:
        return DEFAULT_ONBOARDING;
    }
  }

  if (!_.isEmpty(farmDetails)) {
    let slidesContainerCssClassAppendix = farmDetails.isAdultSelected
      ? " theme-adult"
      : "";
    let onboardingStoryConfig = getOnboardingStoryConfigByThemeSelected(
      farmDetails.farmTheme,
      batchId === null,
    );
    return (
      <div
        className={`SlidesContainerPage theme-${farmDetails.farmTheme}${slidesContainerCssClassAppendix}`}
      >
        <Flow
          onboardingStoryConfig={onboardingStoryConfig}
          cowStoryHistoryConfig={STORY_COW_HISTORY}
          cowStoryLatestConfig={STORY_COW_LATEST}
          farmerStoryConfig={STORY_FARMER}
          cheeseFactoryStoryConfig={STORY_CHEESE_FACTORY}
          orbiterRobotStoryConfig={STORY_ORBITER_ROBOT}
          orbiterRobotYoghurtStoryConfig={STORY_ORBITER_ROBOT_YOGHURT}
          vectorRobotStoryConfig={STORY_VECTOR_ROBOT}
          junoRobotStoryConfig={STORY_JUNO_ROBOT}
          astronautRobotStoryConfig={STORY_ASTRONAUT_ROBOT}
          astronautRobotLiveDataStoryConfig={STORY_ASTRONAUT_LIVE_DATA}
          lelyBrandStoryConfig={STORY_LELY_BRAND}
          mijnMelkStoryConfig={STORY_MIJN_MELK}
          discoveryStoryConfig={STORY_DISCOVERY}
          lunaStoryConfig={STORY_LUNA}
          sustainabilityStoryConfig={STORY_SUSTAINABILITY}
          animalWelfareStoryConfig={STORY_ANIMAL_WELFARE}
          iceCreamFactoryStoryConfig={STORY_ICE_CREAM_FACTORY}
          onboardingCow={
            farmDetails.milkBatch
              ? farmDetails.milkBatch.cows[0]
              : farmDetails.cowsWithFullInfo[0]
          }
          startPoint={startPoint}
          setStartPoint={setStartPoint}
          themeName={farmDetails.farmTheme}
          onSideMenuNavigationCompleteFn={sideMenuClickFnCallback}
          onScrollOrSwipeFn={handleScrollOrSwipeInTheApp}
          cowGrazingData={farmDetails.grazingData}
          farmFeaturesDetails={farmDetails.otherDevices}
          farmId={farmId}
        />
        {!isSideMenuDisplayed ? (
          <IconButton
            cssClassName="btnIcon--showMenu"
            onClickFn={() => {
              setIsSideMenuDisplayed(true);
            }}
          />
        ) : null}
        <div
          className={
            isSideMenuDisplayed
              ? "sideMenu--background-overlay is-side-menu-displayed"
              : "sideMenu--background-overlay"
          }
          onClick={() => {
            setIsSideMenuDisplayed(false);
          }}
          onWheel={() => {
            setIsSideMenuDisplayed(false);
          }}
          onTouchStart={() => {
            setIsSideMenuDisplayed(false);
          }}
        ></div>
        <SideMenu
          cssClassName="sideMenu--mainMenu"
          isMenuDisplayed={isSideMenuDisplayed}
          onMenuCloseClickFn={() => {
            setIsSideMenuDisplayed(false);
          }}
          onMenuOptionClickFn={sideMenuClickFnCallback}
        />
        <PopupContainer
          farmId={farmId}
          isAdultSelected={farmDetails?.isAdultSelected}
        />
      </div>
    );
  } else {
    return null;
  }
}

export default SlidesContainerPage;
