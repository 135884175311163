import _ from "lodash";
import React, { useState, useEffect } from "react";
import { translate } from "../../../localization/TranslationUtil";
import CowBreedBlock from "./CowBreedBlock";

/** LabelledCowBreedSelector component
 * @param {Object} [obj.newCowsDetails]
 * @param {Function} {obj.onUpdateNewCowsDetailsFn}
 * @param {Function} {obj.onBreedSelectionChangeFn}
 */
function LabelledCowBreedSelector({
  newCowsDetails,
  onUpdateNewCowsDetailsFn,
  onBreedSelectionChangeFn,
}) {
  const [currentBreedSelection, setCurrentBreedSelection] = useState([]);

  useEffect(() => {
    //onUpdateNewCowsDetailsFn(newCowsDetailsCopy);
    onBreedSelectionChangeFn(_.cloneDeep(currentBreedSelection));
  }, [currentBreedSelection]);

  useEffect(() => {
    let breedsSelection =
      newCowsDetails && newCowsDetails.breeds
        ? newCowsDetails.breeds
            .map((currentBreed, currentBreedIndex) => {
              currentBreed.index = currentBreedIndex;
              return currentBreed;
            })
            .filter((currentBreed) => JSON.parse(currentBreed.isSelected))
        : [];
    breedsSelection.map((currentSelectionItem, currentSelectionItemIndex) => {
      currentSelectionItem.slotId = currentSelectionItemIndex;
    });
    setCurrentBreedSelection(breedsSelection);
  }, [newCowsDetails]);

  //#region breed selection controls
  function handleAddNewBreedButtonClicked() {
    let newBreedSelection = _.cloneDeep(currentBreedSelection);
    let newBreedFromSelection =
      newBreedSelection.length > 0
        ? currentBreedSelection[0]
        : newCowsDetails.breeds[0];
    newBreedSelection.push(newBreedFromSelection);
    setCurrentBreedSelection(newBreedSelection);
  }

  function unselectBreed(breedToBeUnselected) {
    let newBreedSelection = _.cloneDeep(currentBreedSelection);
    newBreedSelection = newBreedSelection.filter((currentBreed) => {
      return currentBreed.name !== breedToBeUnselected.name;
    });
    setCurrentBreedSelection(newBreedSelection);
    return newBreedSelection;
  }

  function selectBreed(breedToBeSelected, explicitSelection) {
    let isBreedAlreadyInSelection =
      explicitSelection.filter(
        (currentBreed) => currentBreed.name === breedToBeSelected.name,
      ).length > 0;
    let newBreedSelection = _.cloneDeep(explicitSelection);
    if (!isBreedAlreadyInSelection) {
      newBreedSelection.push(breedToBeSelected);
    }
    setCurrentBreedSelection(newBreedSelection);
  }

  function handleBreedColorsChanged(breedData) {
    let newBreedSelection = _.cloneDeep(currentBreedSelection);
    newBreedSelection = newBreedSelection.map((currentBreed) => {
      if (currentBreed.name === breedData.name) {
        return breedData;
      }
      return currentBreed;
    });
    setCurrentBreedSelection(newBreedSelection);
  }

  function handleBreedSelectedChanged(newBreedData, previousBreedData, slotId) {
    let modifiedCurrentSelection = _.cloneDeep(currentBreedSelection);
    newBreedData.isSelected = true;
    modifiedCurrentSelection[slotId] = newBreedData;
    setCurrentBreedSelection(modifiedCurrentSelection);
  }
  //#endregion

  let areAllBreedsAddedToFarm =
    currentBreedSelection.length === newCowsDetails.breeds.length;
  let isBreedDataNotInitialized =
    !newCowsDetails || _.isEmpty(newCowsDetails.breeds);
  let isAddBreedButtonDisabled =
    areAllBreedsAddedToFarm || isBreedDataNotInitialized;
  let isDeleteBreedButtonDisabled = currentBreedSelection.length <= 1;
  return (
    <div>
      {currentBreedSelection.map((currentSelectedBreed, selectedBreedIndex) => {
        return (
          <CowBreedBlock
            cowBreedConfiguration={newCowsDetails.breeds}
            onHandleBreedColorsChangedFn={(newBreedData) => {
              handleBreedColorsChanged(newBreedData);
            }}
            onHandleBreedSelectedChangedFn={(
              newBreedData,
              previousBreedData,
              slotId,
            ) => {
              handleBreedSelectedChanged(
                newBreedData,
                previousBreedData,
                slotId,
              );
            }}
            onDeleteBreedButtonClickFn={(breedToBeUnselected) => {
              unselectBreed(breedToBeUnselected);
            }}
            selectedBreed={currentSelectedBreed}
            selectedBreedIndex={
              currentSelectedBreed ? currentSelectedBreed.index : 0
            }
            slotId={selectedBreedIndex}
            isDeleteBreedButtonDisabled={isDeleteBreedButtonDisabled}
          />
        );
      })}
      {
        <button
          style={{ marginTop: "1vw" }}
          className="btn-add-new-breed-block"
          type="button"
          disabled={isAddBreedButtonDisabled}
          onClick={handleAddNewBreedButtonClicked}
        >
          <i className="lely-icon-plus"></i>
        </button>
      }
      <span style={{ paddingLeft: "1vw" }}>{translate("ADD_COW_BREED")}</span>
    </div>
  );
}
export default LabelledCowBreedSelector;
