import _ from "lodash";
import React, { useEffect } from "react";
import "./WelcomeScreenKool.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  translate,
  translateDynamic,
} from "../../../../localization/TranslationUtil";

const WelcomeScreenKool = React.memo(() => {
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  const farmName = farmDetails && farmDetails.farmName;

  return (
    <div className="WelcomeScreenKool">
      <div className="WelcomeScreenKool--minifarm"></div>
      <div className="WelcomeScreenKool--farmer"></div>
      <div className="WelcomeScreenKool--logo--farmName">
        <div className="WelcomeScreenKool--logo--farmName--text">
          {translateDynamic(farmName)}
        </div>
        <div className="WelcomeScreenKool--swipe"></div>
      </div>
      <div className="WelcomeScreenKool--cloud"></div>
    </div>
  );
});

export default WelcomeScreenKool;
