import React from 'react';
import moment from 'moment'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import './TimePickerInput.scss';

/** TimePickerInput component.
 * @param {string} [obj.labelText] - Label for the text input
 * @param {string} [obj.initialValue] - Initial value for the text input
 * @param {Function} [obj.handleChangeFn] - Function that will be called whenever the text input value has changed
 * @param {boolean} [obj.invalid] - Flag showing if the value entered is invalid
 */
function TimePickerInput({labelText, initialValue, handleChangeFn, invalid}) {
  return (
    <div className={`time-input--container${!initialValue ? ' empty' : ''}${invalid ? ' invalid' : ''}`}>
      <label className="time-input--label">{labelText}</label>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TimePicker
          ampm={false}
          inputVariant="outlined"
          value={moment(initialValue || '00:00', ['HH:mm', moment.ISO_8601])}
          onChange={time => handleChangeFn(moment(time).format('HH:mm'))}
        />
      </MuiPickersUtilsProvider>
    </div >
  );
}

export default TimePickerInput;
