import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function AccordionPanel({ title, text, panelCount }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    // <ExpansionPanel
    //   expanded={expanded === 'panel1' + panelCount}
    //   onChange={handleChange('panel1' + panelCount)}
    // >
    //   <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
    //     <Typography className={classes.heading}>{title}</Typography>
    //   </ExpansionPanelSummary>
    //   <AccordionDetails>
    //     <Typography>{text}</Typography>
    //   </AccordionDetails>
    // </ExpansionPanel>

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{text}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
