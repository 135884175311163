import { ACCEPT_ALL_COOKIES } from "../components/CookiesPopup/types";

/** Store states
 * @constant {Object} */
export const initialState = {
  areCookiesAccepted: false,
};

/** Cookies Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function cookiesReducer(state = initialState, action) {
  switch (action.type) {
    case ACCEPT_ALL_COOKIES:
      return {
        ...state,
        areCookiesAccepted: true,
      };
    default:
      return state;
  }
}

export default cookiesReducer;
