import {
  RESEND_VERIFICATION_EMAIL_REQUEST,
  RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS,
  RESEND_VERIFICATION_EMAIL_REQUEST_ERROR,
} from "./types";

/** Action which will initiate the backend to send another verification e-mail
 * to the e-mail address specified by the user
 * @param {string} emailAddress - User e-mail
 */
export function resendVerificationEmail(emailAddress) {
  return {
    type: RESEND_VERIFICATION_EMAIL_REQUEST,
    payload: {
      emailAddress: emailAddress,
    },
  };
}

/** On the backend successfully being able to re-send the verification e-mail
 * to the e-mail address specified by the user.
 */
export function resendVerificationEmailSuccess() {
  return {
    type: RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS,
    payload: {},
  };
}

/** On the case of an error happened while attempting to resend
 * the verification e-mail to the user
 * @param {string} errorMessage - Error message
 */
export function resendVerificationEmailError(errorMessage) {
  return {
    type: RESEND_VERIFICATION_EMAIL_REQUEST_ERROR,
    payload: errorMessage,
  };
}
