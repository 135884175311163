import {
  REQUEST_COWS_PAGE_INFORMATION,
  SAVE_CHANGES_TO_COWS_PAGE,
} from './types.js';
import {
  getCowsInformationSuccess,
  getCowsInformationError,
  saveChangesToCowsPageSuccess,
  saveChangesToCowsPageSuccessError,
} from './actions.js';
import { put, take, call } from 'redux-saga/effects';
import {
  startLoading,
  endLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import _ from 'lodash';
let currentLoadId = 0;

export function* initalizeCowsPageSaga(api) {
  while (true) {
    const cowsInformationRequestAction = yield take(
      REQUEST_COWS_PAGE_INFORMATION,
    );
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { accessToken } = cowsInformationRequestAction.payload;
    yield call(getCowsDetails, api, accessToken);
  }
}

/** Create request to get cows page latest state of data from the backend
 * @param {string} accessToken - User access token
 */
function* getCowsDetails(api, accessToken) {
  try {
    const cowsDetails = yield call(api.getCowsDetails, accessToken);
    yield put(getCowsInformationSuccess(cowsDetails.data));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(getCowsInformationError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}

export function* cowsSaveChangesSaga(api) {
  while (true) {
    const saveChangesCowsRequestAction = yield take(SAVE_CHANGES_TO_COWS_PAGE);
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { accessToken, newCowsDetails } =
      saveChangesCowsRequestAction.payload;
    yield call(saveChangesToCowsPage, api, accessToken, newCowsDetails);
  }
}

/** Create request to get cows page latest state of data from the backend
 * @param {string} accessToken - User access token
 * @param {Object} newCowsDetails - The latest state of the farm details page (with the latest changes)
 */
function* saveChangesToCowsPage(api, accessToken, newCowsDetails) {
  try {
    const updateCowsDataResponse = yield call(
      api.setCowsDetails,
      accessToken,
      newCowsDetails,
    );
    yield put(saveChangesToCowsPageSuccess(_.cloneDeep(updateCowsDataResponse.data)));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(saveChangesToCowsPageSuccessError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}
