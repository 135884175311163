import React from 'react';
import CallToActionButton from '../CallToActionButton/CallToActionButton';
import IconButton from '../IconButton/IconButton';
import { translate } from '../../localization/TranslationUtil';
import Graphic from '../Graphic/Graphic';
import './GrazewayPopup.scss';

function GrazewayPopup({ closePopupClickFn, isAdultSelected }) {
  return (
    <div className="grazewayPopup"> 
      <div className='grazewayPopup--container'>
        <IconButton
        cssClassName="grazewayPopup--btnClosePopup"
        onClickFn={closePopupClickFn}
        />
        <div className="grazewayPopup--title">
          {translate('COW_GRAZEWAY_TITLE')}
        </div>
        <div className="grazewayPopup--description">
          {translate('COW_GRAZEWAY_DESCRIPTION_TEXT')}
        </div>
       
      </div>
      
      {/* <CallToActionButton
        cssClassName="grazewayPopup--btnCallToAction"
        isAdultSelected={isAdultSelected}
        label={translate('COW_AVAILABLE_SOON_ACTION_BUTTON')}
        onClickFn={() => {}}
      /> */}
      <Graphic cssClassName="grazewayPopup--grazeway" />
      
    </div>
  );
}

export default GrazewayPopup;
