import _ from 'lodash';
import { GET_COW_DAILY_DETAILS_REQUEST_SUCCESS, GET_COW_DAILY_DETAILS_REQUEST_ERROR } from '../components/CowPopup/types.js';
import { WEATHER_TYPE_CLOUDY, WEATHER_TYPE_SUNNY, WEATHER_TYPE_DRIZZLE, WEATHER_TYPE_MIST, WEATHER_TYPE_RAIN, WEATHER_TYPE_HEAVY_RAIN, WEATHER_TYPE_SNOW, WEATHER_TYPE_SNOWSTORM, WEATHER_TYPE_THUNDERSTORM } from '../common/constants';

/** Store states
 * @constant {Object} */
export const initialState = {
  cowDailyDetails: {},
  isCowDailyDetailsError: false,
  errorMessage: '',
};

/** Cow Daily Details Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function cowDailyDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COW_DAILY_DETAILS_REQUEST_SUCCESS:
      const mockWeatherStrings = [WEATHER_TYPE_DRIZZLE, WEATHER_TYPE_THUNDERSTORM, WEATHER_TYPE_HEAVY_RAIN, WEATHER_TYPE_THUNDERSTORM, WEATHER_TYPE_SNOW];
      const mockTemperatures = [15, 12, 12, 12, 0, 0, 8, 32, 25];
      let modifiedCowDailyDetails = _.cloneDeep(action.payload);
      let sortedCowMilkings = _.cloneDeep(modifiedCowDailyDetails.milkings);
      function compareMilkingsByDate(a, b) {
        if (a.created < b.created)
          return -1;
        if (a.created > b.created)
          return 1;
        return 0;
      }

      sortedCowMilkings.sort(compareMilkingsByDate);
      modifiedCowDailyDetails.milkings = sortedCowMilkings;
      return {
        ...state,
        cowDailyDetails: modifiedCowDailyDetails,
        isCowDailyDetailsError: false,
        errorMessage: '',
      };

    case GET_COW_DAILY_DETAILS_REQUEST_ERROR:
      return {
        ...state,
        cowDailyDetails: {},
        isCowDailyDetailsError: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}

export default cowDailyDetailsReducer;
