export const getFarmHouseStyleCssClassname = (farmStyle) => {
  return farmStyle != undefined
    ? "cow-daily-intro--house--" + farmStyle
    : "cow-daily-intro--house--style1";
};

export const getFarmHouseStyleCssClassnameForMijnMelkAdultOnboarding = (
  farmStyle,
) => {
  return farmStyle != undefined
    ? "mijnMelkAdultOnboarding-slide4--farmHouse--" + farmStyle.toLowerCase()
    : "mijnMelkAdultOnboarding-slide4--farmHouse--style1";
};
