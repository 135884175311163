import React, { useState } from "react";
import "./UdderInfo.scss";
import Graphic from "../../components/Graphic/Graphic";

const UdderInfo = ({ isXYZ, children, udderType }) => {
  const [isXYZDisplayed, setIsXYZDisplayed] = useState(false);

  return (
    <div className={`udderInfo udderInfo--${udderType}`}>
      <Graphic cssClassName="udderInfoLine" />
      <div className="udderInfo--dataContainer">{children}</div>
    </div>
  );
};

export default UdderInfo;
