import _ from "lodash";
import React from "react";
import "./FarmerPresentationKool.scss";
import TextCaption from "../../../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";
import {
  translateWithValues,
  translateDynamic,
  translateWithValuesAndHtml,
} from "../../../../localization/TranslationUtil";
import { getDisableCacheAppendix } from "../../../../common/urlUtil";

const FarmerPresentationKool = React.memo(() => {
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  const farmName = translateDynamic(farmDetails && farmDetails.farmName);
  const farmerName = translateDynamic(farmDetails.farmer.name);
  const farmImage = translateDynamic(farmDetails && farmDetails.imageData);

  return (
    <div className="FarmerPresentationKool">
      <TextCaption additionalCssClassname="textCaption--FarmerPresentationKool">
        <p
          className="FarmerPresentationKool--text"
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml("FARMER_PRESENTATION_TEXT", {
              farmerName,
            }),
          }}
        ></p>
        <p
          className="FarmerPresentationKool--text--second"
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "FARMER_PRESENTATION_KOOL_SECOND_PART",
              { farmName },
            ),
          }}
        ></p>
      </TextCaption>
      <img
        className="FarmerPresentationKool--farmImage"
        src={getDisableCacheAppendix(farmImage)}
      ></img>
      <div className="FarmerPresentationKool--farmer"></div>
      <div className="FarmerPresentationKool--cloud"></div>
      <div className="FarmerPresentationKool--arrow"></div>
      <div className="FarmerPresentationKool--farmText">
        {translateWithValues("FARM_NAME_ONBOARDING", { farmName })}
      </div>
    </div>
  );
});

export default FarmerPresentationKool;
