import React from "react";
import { useSelector } from "react-redux";
import "./MistOverlay.scss";
import { WEATHER_TYPE_MIST } from "../../common/constants";
import {
  getRandomNumberBetweenMinAndMax,
  getRandomPositionForACloud,
} from "./CloudUtil";

/** Mist Overlay component */
function MistOverlay({
  viewportWidthInPx,
  viewportHeightInPx,
  cssClassName,
  isAdultThemeApplied,
}) {
  let { currentWeatherString, visibility, snow } = useSelector(
    (state) => state.storyMoment,
  );

  const MIST_CLOUDS_MIN_X_COORDINATE = 0;
  const MIST_CLOUDS_MAX_X_COORDINATE = viewportWidthInPx * 2;
  const MIST_CLOUDS_MIN_Y_COORDINATE = 0;
  const MIST_CLOUDS_MAX_Y_COORDINATE = 0.7 * viewportHeightInPx;

  const MIST_CLOUDS_BACKGROUND_START_INDEX = 1;
  const MIST_CLOUDS_BACKGROUND_END_INDEX = 7;

  function getJSXForMistCloud() {
    let randomCloudPosition = getRandomPositionForACloud(
      MIST_CLOUDS_MIN_X_COORDINATE,
      MIST_CLOUDS_MAX_X_COORDINATE,
      MIST_CLOUDS_MIN_Y_COORDINATE,
      MIST_CLOUDS_MAX_Y_COORDINATE,
    );
    let mistCloudStyleIndex = getRandomNumberBetweenMinAndMax(
      MIST_CLOUDS_BACKGROUND_START_INDEX,
      MIST_CLOUDS_BACKGROUND_END_INDEX,
    );
    let mistCloudCssClassname =
      "mist-cloud mist-cloud-" + mistCloudStyleIndex + " mist-cloud--small";
    mistCloudCssClassname = isAdultThemeApplied
      ? mistCloudCssClassname + " mist-cloud--adult"
      : mistCloudCssClassname;
    let mistCloudJSX = (
      <div
        className={mistCloudCssClassname}
        style={{
          left: randomCloudPosition.left,
          top: randomCloudPosition.top,
        }}
      ></div>
    );
    return mistCloudJSX;
  }

  function showMistClouds(totalNumberOfMistClouds) {
    let mistCloudJSXElements = [];

    for (var i = 0; i < totalNumberOfMistClouds; i++) {
      mistCloudJSXElements.push(getJSXForMistCloud());
    }

    return mistCloudJSXElements;
  }

  let numberOfMistCloudsToGenerate = 10;
  let isCurrentWeatherMist = snow === 0 && visibility <= 1;
  return (
    <div
      className="mist--container"
      style={{
        opacity: isCurrentWeatherMist ? 1 : 0,
      }}
    >
      <div
        className={
          isCurrentWeatherMist ? "mist-overlay is-mist-active" : "mist-overlay"
        }
      ></div>
      {showMistClouds(numberOfMistCloudsToGenerate)}
    </div>
  );
}
export default MistOverlay;
