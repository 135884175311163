export const MOCK_COW_ID = "123";
export const MOCK_SUSTAINABILITY_INFO_API_ERROR_RESPONSE = "error message";
export const MOCK_SUSTAINABILITY_INFO_API_SUCCESSFUL_RESPONSE = {
  data: {
    doesFarmHasSustainability: true,
    annualMilkProductionInLiters: 1,
    doesFarmSustainCarbonDioxide: true,
    carbonDioxideKilogramsPerLiterOfMilk: 10, // CO2
    carbonDioxideStory: {
      en: "English text about CO2 usage",
      nl: "",
      ens: "",
      nls: "",
    },
    doesFarmSustainConcentrates: true,
    gramsOfConcentrateOnYearlyBasis: 1000,
    concentratesStory: {
      en: "English text about concentrate usage",
      nl: "",
      ens: "",
      nls: "",
    },
    doesFarmSustainFertilizer: true,
    gramsOfFertilizerOnYearlyBasis: 1000,
    fertilizerStory: {
      en: "English text about fertilizer usage",
      nl: "",
      ens: "",
      nls: "",
    },
    doesFarmSustainRenewableEnergy: true,
    percentageOfRenewableEnergyUsedInFarm: 45,
    renewableEnergyStory: {
      en: "English text about how renewable energy is used in the farm",
      nl: "",
      ens: "",
      nls: "",
    },
    doesFarmSustainRoughage: true,
    percentageOfRoughageProducedInFarm: 45,
    roughageProductionStory: {
      en: "English text about roughage in the farm",
      nl: "",
      ens: "",
      nls: "",
    },
  },
};
export const MOCK_SET_SUSTAINABILITY_DETAILS_SUCCESS_RESPONSE = {
  data: {
    status: 200,
  },
};

export const api = {
  getSustainabilityDetails: async function (accessToken) {
    return new Promise((resolve) => {
      resolve(MOCK_SUSTAINABILITY_INFO_API_SUCCESSFUL_RESPONSE);
    });
  },
  setSustainabilityDetails: async function (
    accessToken,
    newSustainabilityDetails,
  ) {
    return new Promise((resolve) => {
      resolve(MOCK_SET_SUSTAINABILITY_DETAILS_SUCCESS_RESPONSE);
    });
  },
};
