import {
  REQUEST_ANIMALS_PAGE_INFORMATION,
  SAVE_CHANGES_TO_ANIMALS_PAGE,
} from './types.js';
import {
  getAnimalsPageInformationSuccess,
  getAnimalsPageInformationError,
  saveChangesToAnimalsPageSuccess,
  saveChangesToAnimalsPageError,
} from './actions.js';
import { put, take, call } from 'redux-saga/effects';
import {
  startLoading,
  endLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import { saveChangesToSelectedFarmLanguages } from '../../../common/components/LanguageSelection/actions.js';

let currentLoadId = 0;

export function* initializeAnimalsPageSaga(api) {
  while (true) {
    const animalsInformationRequestAction = yield take(
      REQUEST_ANIMALS_PAGE_INFORMATION,
    );
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { accessToken } = animalsInformationRequestAction.payload;
    yield call(getAnimalsDetails, api, accessToken);
  }
}

/** Create request to get animals page latest state of data from the backend
 * @param {string} accessToken - User access token
 */
function* getAnimalsDetails(api, accessToken) {
  try {
    const animalsDetails = yield call(
      api.getAnimals,
      accessToken,
    );
    yield put(getAnimalsPageInformationSuccess(animalsDetails.data));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(getAnimalsPageInformationError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}

export function* animalsSaveChangesSaga(api) {
  while (true) {
    const saveChangesRequestAction = yield take(
      SAVE_CHANGES_TO_ANIMALS_PAGE,
    );
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { accessToken, newAnimalsDetails, newLanguageDetails } =
      saveChangesRequestAction.payload;
    yield call(
      saveChangesAnimalsPage,
      api,
      accessToken,
      newAnimalsDetails,
      newLanguageDetails
    );
  }
}

/** Create request to get animals page latest state of data from the backend
 * @param {string} accessToken - User access token
 * @param {Object} newAnimalsDetails - The latest state of the animals details data coming directly from the frontend which we want to persist on the backend
 * @param {Object} newLanguageDetails - The latest state of the selected farm languages that need to be persisted to the backend as soon as the first request is over
 */
function* saveChangesAnimalsPage(
  api,
  accessToken,
  newAnimalsDetails,
  newLanguageDetails
) {
  try {
    const updateAnimalsDataResponse = yield call(
      api.setAnimals,
      accessToken,
      newAnimalsDetails
    );
    yield put(
      saveChangesToAnimalsPageSuccess(updateAnimalsDataResponse.data),
    );
    yield put(saveChangesToSelectedFarmLanguages(accessToken, newLanguageDetails));//
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(saveChangesToAnimalsPageError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}
