import { HANDLE_COW_SELECTED } from '../components/Cow/types.js';

/** Store states
 * @constant {Object} */
export const initialState = {
  selectedCowDetails: {}
};

/** Cow Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function cowReducer(state = initialState, action) {
  switch (action.type) {
    case HANDLE_COW_SELECTED:
      return {
        ...state,
        selectedCowDetails: action.payload
      };

    default:
      return state;
  }
}

export default cowReducer;
