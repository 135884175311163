import React from "react";
import "./LastSlideVectorSlide.scss";

import PoleComponent from "../../common/components/PoleComponentEndingSlides/PoleComponent";

const LastSlideVectorSlide = ({ onNavigateToInitialSlideFn }) => {
  return (
    <div className="vectorStoryLastSlide">
      <PoleComponent onNavigateToInitialSlideFn={onNavigateToInitialSlideFn} />
    </div>
  );
};

export default LastSlideVectorSlide;
