import React from "react";
import "./ThirdSlideMijnMelk.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const ThirdSlideMijnMelk = ({}) => {
  return (
    <div className="mijnMelkThirdSlide">
      <div className="mijnMelkThirdSlide--tree"></div>
      <TextCaption additionalCssClassname="mijnMelkThirdSlide--textCaption">
        <div className="mijnMelkThirdSlide--title">
          {translate("MIJN_MELK_THIRD_SLIDE_TITLE")}
        </div>
        <div className="mijnMelkThirdSlide--text">
          {translate("MIJN_MELK_THIRD_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="mijnMelkThirdSlide--imgCaption">
        <div className="mijnMelkThirdSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default ThirdSlideMijnMelk;
