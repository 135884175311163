import React from "react";
import "./StartingSlideOrbiterYoghurt.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const StartingSlideOrbiterYoghurt = ({}) => {
  return (
    <div className="orbiterStoryStartingPoint">
      <div className="orbiterStoryStartingPoint--container">
        <TextCaption additionalCssClassname="orbiterStoryStartingPoint--textCaption">
          <div className="orbiterStoryStartingPoint--title">
            {translate("ORBITER_STORY_FIRST_SLIDE_TITLE")}
          </div>
          <div className="orbiterStoryStartingPoint--name">
            {translate("ORBITER_STORY_FIRST_SLIDE_HEADER")}
          </div>
          <div className="orbiterStoryStartingPoint--info">
            {translate("ORBITER_STORY_FIRST_SLIDE_FROM_COW")}
          </div>
        </TextCaption>
        <div className="orbiterStoryStartingPoint--robot"></div>
      </div>
    </div>
  );
};

export default StartingSlideOrbiterYoghurt;
