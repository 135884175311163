import _ from "lodash";
import React, { useEffect } from "react";
import "./CowDailyVectorPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { ACTOR_TYPE_VECTOR_ROBOT } from "../../common/constants";
import { showPopup } from "../../common/components/PopupContainer/actions";

function CowDailyVectorPage({ additionalCssClassname, farmFeaturesDetails }) {
  const dispatch = useDispatch();
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );

  const isVectorSelected = farmFeaturesDetails.find(({ name }) =>
    name === "vector" ? name : "",
  );

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div
        className={
          additionalCssClassname
            ? "cow-daily--eating--slide " + additionalCssClassname
            : "cow-daily--eating--slide"
        }
      >
        {isVectorSelected ? (
          <div
            className="cow-daily--robot--vector"
            onClick={() => {
              dispatch(showPopup({ type: ACTOR_TYPE_VECTOR_ROBOT }));
            }}
          ></div>
        ) : null}
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowDailyVectorPage;
