import axios from 'axios';
import {
  GET_FEATURE_FLAG_DETAILS_URL,
  GITLAB_ACCESS_TOKEN
} from './config';

const MOCK_VERSION_FEATURE_FLAG_STAGE_DATA = {
  "name": "latest_application_version_stg",
  "description": "1.49.0-0",
  "active": true,
  "version": "new_version_flag",
  "created_at": "2021-04-28T09:09:22.979+02:00",
  "updated_at": "2021-04-28T10:19:15.497+02:00",
  "scopes": [],
  "strategies": [
      {
          "id": 6,
          "name": "default",
          "parameters": {},
          "scopes": [
              {
                  "id": 26,
                  "environment_scope": "*"
              }
          ]
      }
  ]
};

export const mockApi = {
  getFeatureFlagDetails: async function () {
    return new Promise((resolve) => {
      resolve({ data: MOCK_VERSION_FEATURE_FLAG_STAGE_DATA });
    });
  }  
};

export const api = {
  getFeatureFlagDetails: async function (featureFlagName) {
    var getFeatureFlagDetailsServiceUrl = GET_FEATURE_FLAG_DETAILS_URL + featureFlagName;
    return axios.get(getFeatureFlagDetailsServiceUrl, {
      headers: {
        'PRIVATE-TOKEN' : GITLAB_ACCESS_TOKEN
      },
    });
  },
};
