import _ from "lodash";
import React, { useEffect, useState } from "react";
import "./CowDailyMilkingBottlesPage.scss";
import { useSelector } from "react-redux";
import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import Graphic from "../../components/Graphic/Graphic";
import moment from "moment";

function CowDailyMilkingBottlesPage({
  showActorStoryFn,
  additionalCssClassname,
  milkingData,
}) {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const getBottlesJSXByMilkYieldInKilograms = function (milkYieldInKilograms) {
    let bottleJSXElements = [];
    let numberOfBottlesToDraw = Math.ceil(milkYieldInKilograms);
    [...Array(numberOfBottlesToDraw)].forEach(() => {
      bottleJSXElements.push(<Graphic cssClassName="bottleDaily" />);
    });
    return bottleJSXElements;
  };

  useEffect(() => {
  }, [!_.isEmpty(selectedCowDetails)]);

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div
        className={
          additionalCssClassname
            ? "cow-daily--milking--slide " + additionalCssClassname
            : "cow-daily--milking--slide"
        }
      >
        <TextCaption additionalCssClassname="textCaption--cowDailyMilkingBottles">
          <h3 className="textCaption--heading textCaption--heading--milking">
            {moment(milkingData?.created).utc().format("DD MMMM YYYY, HH:mm")}
          </h3>
          <p
            className="cowMilkingText"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml(
                "COW_DAILY_MILK_BOTTLES_PAGE_PARAGRAPH",
                {
                  milkYieldKg: milkingData?.milkYieldKg,
                  numberOfBottles: Math.ceil(milkingData.milkYieldKg),
                },
              ),
            }}
          ></p>
        </TextCaption>
        <div className="cow-daily--bottles-container">
          {getBottlesJSXByMilkYieldInKilograms(milkingData?.milkYieldKg)}
        </div> 
        { milkingData?.milkFatPercentage && milkingData?.milkProteinPercentage && milkingData?.milkLactosePercentage ?
        <div className="cow-daily--astronaut--arrowText">
          <span className="cow-daily--astronaut--arrow"></span>
          <span
            className="cow-daily--astronaut--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml(
                "COW_DAILY_MILK_QUALITY_PAGE_PARAGRAPH",
                {
                  fat: milkingData?.milkFatPercentage?.toFixed(1),
                  protein: milkingData?.milkProteinPercentage?.toFixed(1),
                  lactose: milkingData?.milkLactosePercentage?.toFixed(1),
                },
              ),
            }}
          ></span>
        </div> : ''}
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowDailyMilkingBottlesPage;
