import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import DropdownMenu from './DropdownMenu.scss';

function SelectLabels({
  additionalClassName,
  initialValue,
  options,
  labelText,
  handleChangeFn,
}) {
  /**
   * @param {Object} event - Contains current value of input filed.
   */

  function handleChange(event) {
    handleChangeFn(event.target.value);
  }
  return (
    <div>
      <label className="labelled-text-input--label">{labelText}</label>
      <FormControl
        className={`dropdown-text-input--textInput${
          additionalClassName ? ' ' + additionalClassName : ''
        }`}
        sx={{ m: 1, minWidth: 120 }}
      >
        <Select onChange={handleChange} value={initialValue}>
          {options &&
            options.map((currentOption) => {
              let currentLanguage = currentOption.name;
              let currentCode = currentOption.code;
              let currentLanguageOptionClassName =
                'dropdown-text-input' + '--' + 'languages';
              return (
                <MenuItem
                  className={currentLanguageOptionClassName}
                  value={currentCode}
                >
                  {currentLanguage}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
}
export default SelectLabels;
