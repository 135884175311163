import _ from "lodash";
import React from "react";
import "./RobotsOnboardingKool.scss";
import TextCaption from "../../../../components/TextCaption/TextCaption";
import { translateWithValuesAndHtml } from "../../../../localization/TranslationUtil";
import { useSelector } from "react-redux";

const RobotsOnboardingKool = React.memo(() => {
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  return (
    <div className="RobotsOnboardingKool">
      <TextCaption additionalCssClassname="textCaption--RobotsOnboardingKool">
        <p
          className="RobotsOnboardingKool--text"
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml("ROBOTS_ONBOARDING_TEXT"),
          }}
        ></p>
      </TextCaption>
      <div className="RobotsOnboardingKool--farmer"></div>
      <div className="RobotsOnboardingKool--astronaut"></div>
      <div className="RobotsOnboardingKool--cloud"></div>
      <div className="arrowRobots"></div>
      <div className="RobotsOnboardingKool--smallCloud"></div>
    </div>
  );
});

export default RobotsOnboardingKool;
