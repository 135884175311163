import 'date-fns';
import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import moment from 'moment';
import './BaseDatePicker.scss';
/**
 * DatePicker component.
 * @param {Object} obj - Object which contains all props.
 * @param {string} obj.title - Name of the date picker field.
 * @param {function} obj.setDate - Send current date to parent.
 * @param {Object} obj.startDate - Start date
 * @param {Object} [obj.location] - Sended component through react navigation
 */
function MaterialUIPickers({
  title,
  setDate,
  startDate,
  location,
  dateFormat,
  showError,
  enableFuture,
  invalidHandlerFn,
}) {
  const [startDateTimezoneDate, setStartDateTimezoneDate] = useState();
  /**
   * On DatePicker change update state
   * @param {Object} date - Selected date from DatePicker.
   */
  const handleDateChange = (date) => {
    if (typeof invalidHandlerFn === 'function') {
      if (date == 'Invalid Date') {
        invalidHandlerFn(true);
      } else {
        setStartDateTimezoneDate(date);
      }
      setDate(date);
    }
  };

  useEffect(() => {
    setStartDateTimezoneDate(startDate);
  }, [startDate]);

  return (
    <div className="custom-datepicker">
      <MuiPickersUtilsProvider utils={DateFnsUtils} libInstance={moment}>
        <div>
          <KeyboardDateTimePicker
            ampm={false}
            value={startDateTimezoneDate}
            onChange={handleDateChange}
            label={title}
            format={dateFormat}
            disableFuture={false}
            error={showError}
            variant="inline"
            autoOk={true}
            inputVariant="outlined"
            disableToolbar
            invalidDateMessage=""
            minDateMessage=""
            maxDateMessage=""
            minDate="2022-01-01"
            maxDate="2023-12-31"
            views={['month', 'date']}
            InputProps={{ readOnly: true }}
          />
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
}

MaterialUIPickers.propTypes = {
  title: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  dateFormat: PropTypes.string.isRequired,
};

MaterialUIPickers.defaultProps = {
  location: { state: { time: undefined } },
};

export default MaterialUIPickers;
