export const BIRD_VERTICAL_DIRECTION_TOP_TO_BOTTOM = 0;
export const BIRD_VERTICAL_DIRECTION_BOTTOM_TO_TOP = 1;
export const BIRD_DIRECTION_LEFT_TO_RIGHT = 2;
export const BIRD_DIRECTION_RIGHT_TO_LEFT = 3;
export const BIRD_MIN_FLIGHT_DURATION_IN_MILLISECONDS = 2000;
export const BIRD_MAX_FLIGHT_DURATION_IN_MILLISECONDS = 5000;
export const MAX_POSSIBLE_NUMBER_OF_BIRDS_TO_LAUNCH_SIMULTANEOUSLY = 2;
export const BIRD_BOTTOM_TO_TOP_START_Y = -17;
export const BIRD_TOP_TO_BOTTOM_START_Y = 115;
export const BIRD_LEFT_TO_RIGHT_START_X = -15;
export const BIRD_RIGHT_TO_LEFT_START_X = 115;
export const MIN_SECONDS_BETWEEN_LAUNCHING_BIRDS = 10;
export const MAX_SECONDS_BETWEEN_LAUNCHING_BIRDS = 30;
export const X_RANGE_MIN = 30;
export const X_RANGE_MAX = 70;
export const Y_RANGE_MIN = 30;
export const Y_RANGE_MAX = 70;
export const TIME_TO_UPDATE_BIRD_COORDINATES_TO_FINAL_POINT_OF_FLIGHT_IN_MS = 3000;
export const MIN_BIRD_SCALE = 0.5;
export const MAX_BIRD_SCALE = 1.2;
