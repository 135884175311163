import { SLIDE_TITLE_CHANGED, RESET_SLIDE_TITLE_INFO } from '../common/components/SlideTitle/types';

/** Store states
 * @constant {Object} */
export const initialState = {
    currentSlideTitle: {}
};

/** Slide Title Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function slideTitleReducer(state = initialState, action) {
    switch (action.type) {
        case SLIDE_TITLE_CHANGED:
            return {
                ...state,
                currentSlideTitle: action.payload.slideTitle
            };

        case RESET_SLIDE_TITLE_INFO:
            return {
                ...state,
                currentSlideTitle: ''
            };
        default:
            return state;
    }
}

export default slideTitleReducer;
