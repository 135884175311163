import { MOCK_BREED_DATA } from "../api/mockApi/virtualFarmApi.js";
import {
  GET_VIRTUAL_FARM_DETAILS_REQUEST_SUCCESS,
  GET_VIRTUAL_FARM_DETAILS_REQUEST_ERROR,
  UPDATE_COW_POLL_INFO,
  AGE_SELECTED,
  RESET_AGE_SELECTION,
} from "../components/VirtualFarm/types.js";
import _ from "lodash";

/** Store states
 * @constant {Object} */
export const initialState = {
  farmDetails: {},
  isFarmDetailsError: false,
  errorMessage: "",
};

/** Virtual Farm Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function virtualFarmReducer(state = initialState, action) {
  switch (action.type) {
    case GET_VIRTUAL_FARM_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        farmDetails: { ...action.payload },
        isFarmDetailsError: false,
        errorMessage: "",
      };

    case GET_VIRTUAL_FARM_DETAILS_REQUEST_ERROR:
      return {
        ...state,
        farmDetails: {},
        isFarmDetailsError: true,
        errorMessage: action.payload,
      };

    case UPDATE_COW_POLL_INFO:
      let stateCopy = _.cloneDeep(state);
      let cowExternalId = action.payload.cowExternalId;
      let newPollDetails = action.payload.newPollDetails;

      stateCopy.farmDetails.milkBatch &&
        stateCopy.farmDetails.milkBatch.cows &&
        stateCopy.farmDetails.milkBatch.cows.forEach((currentCow) => {
          if (currentCow.externalId == cowExternalId) {
            currentCow.poll = newPollDetails;
          }
        });
      return stateCopy;
    case AGE_SELECTED:
      let stateDuplicate = _.cloneDeep(state);
      // WORKAROUND for issue of selection Go to the farm from AgeSelection screen
      // if we didn't have this in place the workaround we had for AgeSelection
      // wouldn't work. But if we have this in place it will not be fired once age is
      // selected already. All started from the desire to have 2 separate ways
      // in which the menu functions in two different sections of the application.
      if (
        stateDuplicate.farmDetails &&
        !stateDuplicate.farmDetails.isAgeSelected
      ) {
        stateDuplicate.farmDetails.isAdultSelected = action.payload;
        stateDuplicate.farmDetails.isAgeSelected = true;
      }
      return stateDuplicate;
    case RESET_AGE_SELECTION:
      let stateDuplicate2 = _.cloneDeep(state);
      if (stateDuplicate2.farmDetails) {
        stateDuplicate2.farmDetails.isAgeSelected = undefined;
      }
      return stateDuplicate2;
    default:
      return state;
  }
}

export default virtualFarmReducer;
