import { MARK_FIRST_VIRTUAL_FARM_TOUR_COMPLETE } from '../components/VirtualFarm/virtualFarmTour/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  isVirtualFarmTourComplete: false
};

/** Virtual Farm Tour Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function virtualFarmTourReducer(state = initialState, action) {
  switch (action.type) {
    case MARK_FIRST_VIRTUAL_FARM_TOUR_COMPLETE:
      return {
        ...state,
        isVirtualFarmTourComplete: true
      };
    default:
      return state;
  }
}

export default virtualFarmTourReducer;
