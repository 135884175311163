import _ from "lodash";
import moment from "moment";
import { HANDLE_STORY_MOMENT_CHANGED } from "../components/Story/types.js";

/** Store states
 * @constant {Object} */
export const initialState = {
  currentMoment: moment(new Date()),
  currentWeatherString: "",
  newSunriseData: "",
  newSunsetData: "",
};

/** Story Moment Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function storyMomentReducer(state = initialState, action) {
  switch (action.type) {
    case HANDLE_STORY_MOMENT_CHANGED:
      let modifiedState = _.cloneDeep(state);
      let weatherData = action.payload.weatherData;
      let sunriseData = action.payload.newSunriseData;
      let sunsetData = action.payload.newSunsetData;
      modifiedState.currentMoment = action.payload.moment;
      modifiedState.sunrise = sunriseData;
      modifiedState.sunset = sunsetData;
      modifiedState.temperature = weatherData.temperature;
      modifiedState.cloudCover = weatherData.cloudCover;
      modifiedState.precipitation = weatherData.precipitation;
      modifiedState.snow = weatherData.snow;
      modifiedState.visibility = weatherData.visibility;
      modifiedState.windSpeed = weatherData.windSpeed;

      return modifiedState;

    default:
      return state;
  }
}

export default storyMomentReducer;
