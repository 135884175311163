import {
  SUSTAINABILITY_PAGE_INFORMATION_ERROR,
  SUSTAINABILITY_PAGE_INFORMATION_SUCCESS,
  SAVE_CHANGES_TO_SUSTAINABILITY_PAGE_SUCCESS,
  SAVE_CHANGES_TO_SUSTAINABILITY_PAGE_ERROR,
} from "../containers/Admin/SustainabilityPage/types";

/** Store states
 * @constant {Object} */
export const initialState = {
  sustainabilityDetails: {},
  errorMessage: "",
  isLatestSaveSuccessful: false,
  saveChangesErrorMessage: "",
};

/** Sustainability admin app page reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function sustainabilityReducer(state = initialState, action) {
  switch (action.type) {
    case SUSTAINABILITY_PAGE_INFORMATION_SUCCESS:
      return {
        ...state,
        sustainabilityDetails: action.payload,
        errorMessage: "",
      };
    case SUSTAINABILITY_PAGE_INFORMATION_ERROR:
      return {
        ...state,
        sustainabilityDetails: {},
        errorMessage: action.payload,
      };
    case SAVE_CHANGES_TO_SUSTAINABILITY_PAGE_SUCCESS:
      return {
        ...state,
        isLatestSaveSuccessful: true,
        saveChangesErrorMessage: "",
      };
    case SAVE_CHANGES_TO_SUSTAINABILITY_PAGE_ERROR:
      return {
        ...state,
        isLatestSaveSuccessful: false,
        saveChangesErrorMessage: action.payload,
      };

    default:
      return state;
  }
}

export default sustainabilityReducer;
