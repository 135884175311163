import React from "react";
import IconButton from "../IconButton/IconButton";
import "./ArrowPopup.scss";
import { ReactComponent as ArrowPopupBackground } from "../../resources/assets/themes/lely/16x9/popups/tour/arrow-popup-arrow-part.svg";
import { getDisableCacheAppendix } from "../../common/urlUtil";
import { GESTURE_TYPE_DRAG, GESTURE_TYPE_ZOOM } from "../../common/constants";  
import { translate } from "../../localization/TranslationUtil";

/** ArrowPopup component */
function ArrowPopup({
  popupHeaderText,
  popupBodyText,
  popupImageUrl,
  popupImageCaption,
  onPopupButtonClickFn,
  iconButtonCssClassname,
  gestureIconClassname,
  isAdultSelected,
}) {
  const isOpenedOnMobileDevice = window.matchMedia(
    "(any-pointer:coarse)",
  ).matches;
  let arrowPopupContainerCssClassname = isOpenedOnMobileDevice
    ? "arrowPopup arrowPopup--mobile arrowPopup--textArea"
    : "arrowPopup arrowPopup--desktop arrowPopup--textArea";

  let gestureIconCssClassname = "";
  if (gestureIconClassname === GESTURE_TYPE_ZOOM) {
    gestureIconCssClassname = isOpenedOnMobileDevice
      ? "arrowPopup--pinchZoomIcon"
      : "arrowPopup--scrollZoomIcon";
  } else if (gestureIconClassname === GESTURE_TYPE_DRAG) {
    gestureIconCssClassname = isOpenedOnMobileDevice
      ? "arrowPopup--swipeIcon"
      : "arrowPopup--clickAndDragIcon";
  }
  return (
    <>
      {popupImageUrl ? (
        <div className="arrowPopup--imageContainer">
          <img
            src={getDisableCacheAppendix(popupImageUrl)}
            className="arrowPopup--image"
          ></img>
          {/* <div className="farmerStoryDynamicSlide--imgText">
                        {popupImageCaption}
                    </div> */}
        </div>
      ) : null}
      <div className={arrowPopupContainerCssClassname}>
        <h2 className="arrowPopup--textElement arrowPopup--title">
          {popupHeaderText}
        </h2>
        <p className="arrowPopup--textElement arrowPopup--description">
          {popupBodyText}
        </p>
        <IconButton cssClassName={iconButtonCssClassname} />

        {gestureIconClassname ? (
          <div
            className={"arrowPopup--gestureIcon " + gestureIconCssClassname}
          />
        ) : null}

        <div className="btnCallToAction vectorPopup-callToActionButton--container">
          <button className="btnCallToAction vectorPopup-callToActionButton">
            {translate("NEXT_BUTTON")}
          </button>
          <div className="btnCallToAction vectorPopup-callToActionButton--arrows"></div>
        </div>
      </div>
    </>
  );
}
export default ArrowPopup;
