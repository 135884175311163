import {
  GET_UDDER_DETAILS_REQUEST,
  GET_UDDER_DETAILS_REQUEST_ERROR,
  GET_UDDER_DETAILS_REQUEST_SUCCESS,
  RESET_UDDER_DETAILS,
} from "./types";

/** Create action for initiating the request of data for the udder of respective cow
 * @param {string} farmId - Farm id
 * @param {string} cowId - Cow id
 * @return {Object} Action object
 */
export function getUdderDetails(farmId, cowId) {
  return {
    type: GET_UDDER_DETAILS_REQUEST,
    payload: {
      farmId: farmId,
      cowId: cowId,
    },
  };
}

/** Create action which to cause the udder details to be stored in the Redux store
 * @param {Object} udderDetails
 * @return {Object} Return type and udderDetails
 */
export function getUdderDetailsSuccess(udderDetails) {
  return {
    type: GET_UDDER_DETAILS_REQUEST_SUCCESS,
    payload: { ...udderDetails },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getUdderDetailsError(errorMessage) {
  return {
    type: GET_UDDER_DETAILS_REQUEST_ERROR,
    payload: errorMessage,
  };
}

/** Create action which will clear all udder details from the reducer
 * @return {Object} Action object
 */
export function resetUdderDetails() {
  return {
    type: RESET_UDDER_DETAILS,
    payload: {},
  };
}
