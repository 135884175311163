import React from "react";
import "./ThirdSlideLuna.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const ThirdSlideLuna = ({}) => {
  return (
    <div className="lunaStoryThirdSlide">
      <TextCaption additionalCssClassname="lunaStoryThirdSlide--textCaption">
        <div className="lunaStoryThirdSlide--title">
          {translate("LUNA_ROBOT_STORY_THIRD_SLIDE_TITLE")}
        </div>
        <div className="lunaStoryThirdSlide--text">
          {translate("LUNA_ROBOT_STORY_THIRD_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="discoveryStory--bottomColorOverlay"></div>
      <div className="lunaStoryThirdSlide--imgCaption">
        <div className="lunaStoryThirdSlide--imgContainer"></div>
      </div>
      <div className="lunaStoryThirdSlide--transitionTree"></div>
    </div>
  );
};

export default ThirdSlideLuna;
