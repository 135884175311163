import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core";

// Styles
import "./LabelledTextInput.scss";
import { initial } from "lodash";
import { sanitize } from "dompurify";

/** LabelledTextInput component.
 * @param {Object} obj - Object which contains all props.
 * @param {Object} [obj.tooltip] - The tooltip icon
 * @param {Function} [obj.tooltipFn] - Function that will be called on tooltip click
 * @param {string} [obj.placeholder] - Search placeholder
 * @param {string} [obj.dataTestID] - Data test ID
 * @param {string} [obj.labelText] - Label for the text input
 * @param {string} [obj.initialValue] - Initial value for the text input
 * @param {Function} [obj.handleChangeFn] - Function that will be called whenever the text input value has changed
 * @param {string} [obj.additionalClassName] - Adds additional css class name
 * @param {boolean} [obj.optional] - Flag if field is not required
 * @param {string} [obj.placeholder] - Placeholder text
 * @param {boolean} [obj.disabled] - Whether the text field is disabled or not
 * @param {string} [obj.inputType] - Allows the setting of different kind of input (e.g.: "password")
 */
function LabelledTextInput({
  tooltip,
  tooltipFn,
  placeholder,
  dataTestID,
  labelText,
  initialValue,
  handleChangeFn,
  additionalClassName,
  optional,
  placeholderText,
  disabled,
  inputType,
}) {
  const [value, setValue] = useState(initialValue);
  const [borderStyle, setBorderStyle] = useState("1px solid #cfcbc3");

  useEffect(() => {
    setValue(initialValue || value || "");
  }, [initialValue]);

  useEffect(() => {
    if (optional || (value && value.length > 0)) {
      setBorderStyle("1px solid #cfcbc3");
    } else {
      setBorderStyle("1px solid #FF0000");
    }
  }, [value]);
  /**
   * @param {Object} event - Contains current value of input filed.
   */
  function handleChange(event) {
    let sanitizedValue = sanitize(event.target.value);
    setValue(sanitizedValue);
    handleChangeFn(sanitizedValue);
  }
  return (
    <div className="labelled-text-input">
      <label className="labelled-text-input--label">{labelText}</label>
      <input
        type={inputType || "text"}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        data-testid={dataTestID}
        style={{ border: borderStyle }}
        placeholder={placeholderText || ""}
        className={`labelled-text-input--textInput${
          additionalClassName ? " " + additionalClassName : ""
        }${tooltip ? " tooltip-padding" : ""}`}
      />
      {tooltip && (
        <Tooltip className="labelled-text-input--tooltip" onClick={tooltipFn}>
          {tooltip}
        </Tooltip>
      )}
    </div>
  );
}

export default LabelledTextInput;
