export const getCowSubbreedByExternalIdAndFarmDetails = (cowExternalId, farmDetails, defaultSubbreed) => {
    /*
    let cowsWithThatExternalId = farmDetails.milkBatch.cows.filter(currentCow => currentCow.externalId == cowExternalId);
    /*
    if (cowsWithThatExternalId && cowsWithThatExternalId.length) {
        let cowSubbreed = cowsWithThatExternalId[0].cowSubBreed;
        return cowSubbreed;
    }
    */
    return defaultSubbreed;
};

export const populateCowRelativesWithSubbreeds = (cowDetails, farmDetails, defaultSubbreed, defaultCowType) => {
    cowDetails.relatives.forEach(currentRelative => {
        populateRelativeWithSubbreed(currentRelative, farmDetails, defaultSubbreed, defaultCowType);
    });
    cowDetails.siblings.forEach(currentSibling => {
        populateRelativeWithSubbreed(currentSibling, farmDetails, defaultSubbreed, defaultCowType);
    });
    populateRelativeWithSubbreed(cowDetails.grandMother, farmDetails, defaultSubbreed, defaultCowType);
    populateRelativeWithSubbreed(cowDetails.mother, farmDetails, defaultSubbreed, defaultCowType);
};

export const populateRelativeWithSubbreed = (relativeDetails, farmDetails, defaultSubbreed, defaultCowType) => {
    if (relativeDetails) {
        relativeDetails.cowSubBreed = getCowSubbreedByExternalIdAndFarmDetails(relativeDetails.externalId, farmDetails, defaultSubbreed);
        relativeDetails.cowType = defaultCowType;
        relativeDetails.doHideButtons = true;
    }
};