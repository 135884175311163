import _ from "lodash";
import React, { useEffect } from "react";
import "./CowGaveBirthFirstSlide.scss";
import TextCaption from "../../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";
import {
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../../localization/TranslationUtil";
import moment from "moment-timezone";

const CowGaveBirthFirstSlide = React.memo(() => {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div className="CowGaveBirthFirstSlide">
        <div className="cow-CowGaveBirthFirstSlide--calendar">
          <span className="cow-CowGaveBirthFirstSlide--calendarMonth">
            {moment(
              selectedCowDetails &&
                selectedCowDetails.firstCalf &&
                selectedCowDetails.firstCalf.birthTimestamp,
            ).format("MMMM")}
          </span>
          <span className="cow-CowGaveBirthFirstSlide--calendarDay">
            {moment(
              selectedCowDetails &&
                selectedCowDetails.firstCalf &&
                selectedCowDetails.firstCalf.birthTimestamp,
            ).format("DD")}
          </span>
        </div>
        <span className="cow-CowGaveBirthFirstSlide--stork"></span>
        <TextCaption additionalCssClassname="textCaption--CowGaveBirthFirstSlide--year">
          {moment(
            selectedCowDetails &&
              selectedCowDetails.firstCalf &&
              selectedCowDetails.firstCalf.birthTimestamp,
          ).format("YYYY")}
        </TextCaption>
        <TextCaption additionalCssClassname="textCaption--CowGaveBirthFirstSlide">
          <p
            className="cow-CowGaveBirthFirstSlide--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml("COW_GAVE_BIRTH_INFO" , {
                motherCowName: selectedCowDetails?.name, 
                cowName : selectedCowDetails?.firstCalf?.name != null ? selectedCowDetails?.firstCalf?.name : '',
              }),
            }}
          ></p>
        </TextCaption>
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
});

export default CowGaveBirthFirstSlide;
