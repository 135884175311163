import _ from "lodash";
import React, { useEffect } from "react";
import "./CowRelativesPage.scss";
import { useSelector } from "react-redux";
import FamilyTree from "../../../components/FamilyTree/FamilyTree";
import {
  COW_WITH_FULL_FAMILY_TREE,
  COW_WITH_LIMITED_FAMILY_TREE,
} from "../../../components/FamilyTree/mockData";
import { populateCowRelativesWithSubbreeds } from "../../../components/FamilyTree/CowInfoUtil";

const CowRelativesPage = React.memo(() => {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const cowSubbreed = selectedCowDetails.cowSubBreed;
  populateCowRelativesWithSubbreeds(
    selectedCowDetails,
    farmDetails,
    cowSubbreed,
    selectedCowDetails.cowType,
  );

  useEffect(() => {}, [!_.isEmpty(selectedCowDetails)]);
    
  return (
    <div></div>
  )
});

export default CowRelativesPage;
