import { GET_VIRTUAL_FARM_DETAILS_REQUEST } from "./types.js";
import {
  getVirtualFarmDetails,
  getVirtualFarmDetailsError,
  getVirtualFarmDetailsSuccess,
} from "./actions.js";
import { put, take, call } from "redux-saga/effects";
import { getServerResponseStatus } from "../ErrorPopUp/actions";
import {
  startLoading,
  endLoading,
} from "../../common/redux/loadManager/actions";
import { getLoadId } from "../../common/redux/loadManager/methods";
import {
  cowCoordinates,
  decorateCowsWithBreed,
  decorateVirtualFarmDataWithPositionInformation,
  getBreedPercentageDictionaryByBreedProportionConfig,
} from "./util.js";
import { MOCK_BREED_DATA } from "../../api/mockApi/virtualFarmApi.js";
import { MOCK_SUSTAINABILITY_INFO_API_SUCCESSFUL_RESPONSE } from "../../api/mockApi/sustainabilityInfoApi.js";
import { redirect } from "../CustomRedirect/actions.js";
import {
  initializeLanguageWithBackendSettings,
  setDefaultLanguage,
  setSupportedLanguagesForFarm,
} from "../../localization/TranslationUtil.js";

let currentLoadId = 0;

export default function* virtualFarmDetailsSaga(api) {
  while (true) {
    const farmDetailsRequestAction = yield take(
      GET_VIRTUAL_FARM_DETAILS_REQUEST,
    );
    /** Update state when server is online */
    yield put(getServerResponseStatus(""));
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { farmId, batchId } = farmDetailsRequestAction.payload;
    yield call(getFarmDetails, api, farmId, batchId);
  }
}

/** Create request to get virtual farm details
 * @param {string} accessToken - User access token
 * @param {any} farmId - Farm id
 */
function* getFarmDetails(api, farmId, batchId) {
  try {
    const virtualFarmDetails = yield call(
      api.getVirtualFarmDetails,
      farmId,
      batchId,
    );
    // //#region TODO: remove this logic for adding mock sustainability data after the backend is READY:
    // virtualFarmDetails.data.sustainability =
    //   MOCK_SUSTAINABILITY_INFO_API_SUCCESSFUL_RESPONSE.data;
    // console.log(
    //   "New virtualFarmDetails after adding mock sustainability data:",
    //   virtualFarmDetails,
    // );
    //#endregion
    let batch = virtualFarmDetails.data.milkBatch;
    let cowsFromMilkBatch = batch
      ? batch.cows
      : virtualFarmDetails.data.cowsWithFullInfo;
    let displayedCowsFromMilkBatch = cowsFromMilkBatch.slice(
      0,
      cowCoordinates.length,
    );
    let restOfCowsFromMilkBatch = cowsFromMilkBatch.slice(
      cowCoordinates.length,
    );
    let externalIdsOfCowsFromMilkBatch = cowsFromMilkBatch.map(
      (currentCow) => currentCow.externalId,
    );
    let cowsOutsideOfMilkBatch = virtualFarmDetails.data.allCows.filter(
      (currentCow) =>
        externalIdsOfCowsFromMilkBatch.indexOf(currentCow.externalId) === -1,
    );
    decorateCowsWithBreed(
      displayedCowsFromMilkBatch,
      virtualFarmDetails?.data?.breeds,
    );
    decorateCowsWithBreed(
      restOfCowsFromMilkBatch,
      virtualFarmDetails?.data?.breeds,
    );
    decorateCowsWithBreed(
      cowsOutsideOfMilkBatch,
      virtualFarmDetails?.data?.breeds,
    );
    let virtualFarmDataWithPositionInformation =
      decorateVirtualFarmDataWithPositionInformation(virtualFarmDetails?.data);

    //#region initialize translation util with the proper language selection logic
    // depending on data received from the backend
    if (virtualFarmDetails?.data?.farmer?.languages?.length > 0) {
      setSupportedLanguagesForFarm(virtualFarmDetails?.data?.farmer?.languages);
      setDefaultLanguage(virtualFarmDetails?.data?.farmer?.languages);
      initializeLanguageWithBackendSettings();
    }
    //#endregion

    yield put(
      getVirtualFarmDetailsSuccess(virtualFarmDataWithPositionInformation),
    );
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    console.log("@error:", error);
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(getVirtualFarmDetailsError(error));
    /** Listen for error state to show when server is disconected */
    yield put(getServerResponseStatus(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
    yield put(redirect("notFound"));
  }
}
