import {
  UPDATE_GLOBAL_SAVE_STATUS,
  RESET_GLOBAL_SAVE_STATUS,
  SHOW_CONFIRM_SAVING_CHANGES_POPUP,
  HIDE_CONFIRM_SAVING_CHANGES_POPUP,
} from "../common/components/SaveChangesManager/types";

/** Store states
 * @constant {Object} */
export const initialState = {
  areChangesSaved: true,
  saveChangesClickHandlerForCurrentPageFn: {},
  isSaveChangesConfirmationPopupDisplayed: false,
};

/** Global save changes reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function globalSaveChangesReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_GLOBAL_SAVE_STATUS:
      let { currentSaveClickHandlerFn, currentAreChangesSaved } =
        action.payload;
      return {
        ...state,
        saveChangesClickHandlerForCurrentPageFn: currentSaveClickHandlerFn,
        areChangesSaved: currentAreChangesSaved,
      };
    case SHOW_CONFIRM_SAVING_CHANGES_POPUP:
      return {
        ...state,
        isSaveChangesConfirmationPopupDisplayed: true,
      };
    case HIDE_CONFIRM_SAVING_CHANGES_POPUP:
      return {
        ...state,
        isSaveChangesConfirmationPopupDisplayed: false,
        areChangesSaved: true,
      };
    case RESET_GLOBAL_SAVE_STATUS:
      return {
        ...state,
        areChangesSaved: true,
        saveChangesClickHandlerForCurrentPageFn: {},
        isSaveChangesConfirmationPopupDisplayed: false,
      };
    default:
      return state;
  }
}

export default globalSaveChangesReducer;
