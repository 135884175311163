import moment from 'moment';

export const getGrazingDates = function (dateTo, dateFrom) {
  if (dateTo != null && dateFrom != null) {
    return dateTo == dateFrom
      ? 1
      : Math.ceil(
          moment.duration(moment(dateTo).diff(moment(dateFrom))).asDays(),
        ) + 1;
    //.toFixed();
  } else {
    return '';
  }
};
