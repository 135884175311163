import React from "react";
import "./AstronautTimeMilked.scss";
import MatrixBackground from "./MatrixBackground";
import { useSelector } from "react-redux";
import {
  translate,
  translateWithValues,
} from "../../localization/TranslationUtil";
import moment from "moment";
// import TextCaption from "../../components/TextCaption/TextCaption";
import MatrixColumn from "./MatrixColumn";

const AstronautTimeMilked = ({}) => {
  const { astronautDetails } = useSelector((state) => state.astronautDetails);
  const hoursMilkingLast24Hours = moment
    .utc(1000 * astronautDetails.secondsMilkingLast24Hours)
    .format("H");
  const minutesMilkingLast24Hours = moment
    .utc(1000 * astronautDetails.secondsMilkingLast24Hours)
    .format("m");
  return (
    <div className="astronautTimeMilked">
      <MatrixColumn
        cssClassName="testMatrixColumn10"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
      <MatrixColumn
        cssClassName="testMatrixColumn14"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
      <div className="astronautTimeMilked--blackBackground"></div>
      <div className="astronautTimeMilked--blackBoxes"></div>
      <div className="astronautTimeMilked--textCaption">
        <div className="astronautTimeMilked--text">
          {translateWithValues("ASTRONAUT_TIME_MILKED_IN_SECONDS", {secondsMilkingLast24Hours : astronautDetails?.secondsMilkingLast24Hours})}
        </div>
      </div>
      <div className="astronautTimeMilked--whiteTextCaption">
        <div className="astronautTimeMilked--whiteTextCaptionText">
          {translateWithValues("ASTRONAUT_TIME_MILKED_PAST_24_HOURS", {
            hour: hoursMilkingLast24Hours,
            minutes: minutesMilkingLast24Hours,
          })}
        </div>
      </div>
      <div className="astronautTimeMilked--arrowConnectingTextBoxes"></div>
      <div className="astronautTimeMilked--arrowConnectingSlides"></div>
    </div>
  );
};

export default AstronautTimeMilked;
