import {
  SET_NEW_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_REQUEST_SUCCESS,
  SET_NEW_PASSWORD_REQUEST_ERROR,
} from "./types";

/** Action which will initiate setting new password
 * for the user related to the specified token.
 * The token was initially received as a GET parameter
 * by the backend which sent an e-mail to the users with link
 * which contained that GET parameter.
 * This parameter can be seen as something of an access token
 * using which the backend knows exactly which user's password
 * should be changed.
 * @param {string} email - If this is invalid the new password will not be set to the desired user
 * @param {string} token - If this is invalid the new password will not be set to the desired user
 * @param {string} newPassword - The desired new password to be set to the account
 */
export function setNewPassword(email, token, newPassword) {
  return {
    type: SET_NEW_PASSWORD_REQUEST,
    payload: {
      email: email,
      token: token,
      newPassword: newPassword,
    },
  };
}

/** On the backend successfully being able to set the
 * new password to the already existing user
 */
export function setNewPasswordSuccess() {
  return {
    type: SET_NEW_PASSWORD_REQUEST_SUCCESS,
    payload: {},
  };
}

/** On the case of an error happened while attempting to set
 * the new password to the user
 * (might happen if the token they sent to the backend was not valid)
 * @param {string} errorMessage - Error message
 */
export function setNewPasswordError(errorMessage) {
  return {
    type: SET_NEW_PASSWORD_REQUEST_ERROR,
    payload: errorMessage,
  };
}
