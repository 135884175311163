import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import useWindowDimensions from "../../../hooks/useViewportDimensions";
import { useLocation } from "react-router";
// Styles
import { ReactComponent as Planet } from "../../../resources/assets/themes/lely/16x9/stories/milk/planet.svg";
import { ReactComponent as AnimatedBackground } from "../../../resources/assets/themes/lely/16x9/stories/milk/sunburst.svg";
import { ReactComponent as ChristmasHat } from "../../../resources/assets/themes/lely/16x9/stories/milk/christmas_hat.svg";
import { ReactComponent as ChristmasWhistle } from "../../../resources/assets/themes/lely/16x9/stories/milk/christmas_whistle.svg";

import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../../localization/TranslationUtil";

import AnimatedMilkBottle from "./MilkBottle";
import {
  SIDE_MENU_OPTION_AGE_SELECTION,
  SPECIAL_EVENT_APRIL_MILK,
  SPECIAL_EVENT_AUGUST_MILK,
  SPECIAL_EVENT_CLOUDY_MILK,
  SPECIAL_EVENT_DECEMBER_MILK,
  SPECIAL_EVENT_FEBRUARY_MILK,
  SPECIAL_EVENT_FIVE_SISTERS_MILK,
  SPECIAL_EVENT_FOUR_SISTER_MILK,
  SPECIAL_EVENT_GENERATION_MILK,
  SPECIAL_EVENT_HOT_MILK,
  SPECIAL_EVENT_JANUARY_MILK,
  SPECIAL_EVENT_JULY_MILK,
  SPECIAL_EVENT_JULY_MILK_2,
  SPECIAL_EVENT_JUNE_MILK,
  SPECIAL_EVENT_MARCH_MILK,
  SPECIAL_EVENT_MAY_MILK,
  SPECIAL_EVENT_MOTHER_DAUGHTER_MILK,
  SPECIAL_EVENT_NOVEMBER_MILK,
  SPECIAL_EVENT_OCTOBER_MILK,
  SPECIAL_EVENT_RAIN_MILK,
  SPECIAL_EVENT_SEPTEMBER_MILK,
  SPECIAL_EVENT_SNOW_MILK,
  SPECIAL_EVENT_THREE_SISTER_MILK,
  SPECIAL_EVENT_TWO_SISTERS_MILK,
} from "../../constants";
import CowBreedsPreview from "./CowBreedsPreview";
import { getDisableCacheAppendix } from "../../urlUtil";
import moment from "moment";
import Map from "./Map";
import { FARM_LOCATIONS } from "./FarmLocations";
import _ from "lodash";
import { getLatestSwipeDeltaX, setLatestSwipeDeltaX } from "../../globals";
import { getTopThreeCowsInMilkBatch } from "./MilkBatchUtil";
import Graphic from "../../../components/Graphic/Graphic";
import OnboardingClouds from "../../../components/Weather/OnboardingClouds";
import Snowfall from "../../../common/components/SnowFall/Snowfall";

function RotatingStoryContainer({
  farmName,
  farmerName,
  farmLocation,
  farmBreeds,
  farmSubbreeds,
  onNavigateToInitialSlideFn,
  milkBatch,
  farmerImage,
  farmStyle,
}) {
  const rotatedAreaContainerRef = useRef({});
  const rotatingStoryContainerRef = useRef({});
  const currentRotationDegree = useRef(1);
  let { width: VIEWPORT_WIDTH_IN_PX, height: VIEWPORT_HEIGHT_IN_PX } =
    useWindowDimensions();
  let isAppOpenedInLandscapeMode = VIEWPORT_WIDTH_IN_PX > VIEWPORT_HEIGHT_IN_PX;
  VIEWPORT_WIDTH_IN_PX = isAppOpenedInLandscapeMode
    ? VIEWPORT_WIDTH_IN_PX
    : VIEWPORT_HEIGHT_IN_PX;
  VIEWPORT_HEIGHT_IN_PX = isAppOpenedInLandscapeMode
    ? VIEWPORT_HEIGHT_IN_PX
    : VIEWPORT_WIDTH_IN_PX;
  const specialEventCode =
    milkBatch && milkBatch.specialEventCode
      ? milkBatch.specialEventCode.toUpperCase()
      : "";
  let milkBatchMoment =
    milkBatch && milkBatch.start ? moment(milkBatch.start) : moment();
  const milkBatchMonthName = milkBatchMoment.format("MMMM");
  const milkBatchDate = milkBatchMoment.format("DD");
  const farmLocationPinCoordinates = FARM_LOCATIONS[farmLocation]
    ? FARM_LOCATIONS[farmLocation]
    : null;
  const farmLocationMapPinX = farmLocationPinCoordinates
    ? farmLocationPinCoordinates.x
    : null;
  const farmLocationMapPinY = farmLocationPinCoordinates
    ? farmLocationPinCoordinates.y
    : null;
  let topMilkedCowInMilkBatch = getTopThreeCowsInMilkBatch(milkBatch)[0];
  const topMilkedCowCssClassName =
    "cow--breed-preview " + topMilkedCowInMilkBatch.subBreedCssClassName;
  const goToLelySite = translate("REDIRECT_TO_LELY_WEB");
  //#region MOUSE WHEEL FUNCTIONALITY
  function handleWheel(mouseEvent) {
    mouseEvent.stopPropagation();
    const deltaY = _.clone(mouseEvent.deltaY);
    createDebouncedWheelHandler(deltaY, 100);
  }
  const getFarmHouseStyle = (farmStyle) => {
    return farmStyle != undefined
      ? "slide4--house--" + farmStyle
      : "slide4--house--style1";
  };
  const numberOfCowsInTheBatch =
    milkBatch && milkBatch.cows && milkBatch.cows.length;
  function createDebouncedWheelHandler(deltaY, debounceIntervalInMs) {
    const mouseWheelHandler = _.debounce(() => {
      // _.debounce will combine multiple/a burst of mouse events into one event
      const isMoveRight = deltaY < 0;
      handleSlideChange(isMoveRight);
    }, debounceIntervalInMs);
    mouseWheelHandler(deltaY);
  }
  const getSpecialEventBackgroundJSX = (specialEventCode) => {
    switch (specialEventCode) {
      case SPECIAL_EVENT_RAIN_MILK:
        return (
          <>
            <OnboardingClouds
              numberOfCloudsToGenerate={15}
              areCloudsDark={true}
              areCloudsSmall={false}
              viewportWidthInPx={VIEWPORT_WIDTH_IN_PX}
              viewportHeightInPx={VIEWPORT_HEIGHT_IN_PX}
            />
            <div className="rainfall averagerain" />
          </>
        );
      case SPECIAL_EVENT_HOT_MILK:
        return <div className="hotMilk--sun"></div>;
      case SPECIAL_EVENT_CLOUDY_MILK:
        return (
          <OnboardingClouds
            numberOfCloudsToGenerate={20}
            areCloudsDark={false}
            areCloudsSmall={false}
            viewportWidthInPx={VIEWPORT_WIDTH_IN_PX}
            viewportHeightInPx={VIEWPORT_HEIGHT_IN_PX}
          />
        );
      case SPECIAL_EVENT_SNOW_MILK:
        return <Snowfall snowflakeCount={700} />;
      default:
        return null;
    }
  };

  const getSpecialEventElementsJSX = (specialEventCode) => {
    console.log("specialEventCode", specialEventCode);
    if (
      specialEventCode == SPECIAL_EVENT_JANUARY_MILK ||
      specialEventCode == SPECIAL_EVENT_FEBRUARY_MILK ||
      specialEventCode == SPECIAL_EVENT_MARCH_MILK ||
      specialEventCode == SPECIAL_EVENT_APRIL_MILK ||
      specialEventCode == SPECIAL_EVENT_MAY_MILK ||
      specialEventCode == SPECIAL_EVENT_JUNE_MILK ||
      specialEventCode === SPECIAL_EVENT_JULY_MILK ||
      specialEventCode === SPECIAL_EVENT_JULY_MILK_2 ||
      specialEventCode == SPECIAL_EVENT_AUGUST_MILK ||
      specialEventCode == SPECIAL_EVENT_SEPTEMBER_MILK ||
      specialEventCode == SPECIAL_EVENT_OCTOBER_MILK ||
      specialEventCode == SPECIAL_EVENT_NOVEMBER_MILK ||
      specialEventCode == SPECIAL_EVENT_DECEMBER_MILK
    ) {
      return (
        <>
          <div className="slide2-smallBush"></div>
          <div className="slide2-bigBush"></div>
          <div className="slide2-smallGrass"></div>
          <div className="slide2-bigGrass"></div>
          <div className="slide2-familyTree"></div>
        </>
      );
    } else if (
      specialEventCode === SPECIAL_EVENT_GENERATION_MILK ||
      specialEventCode === SPECIAL_EVENT_MOTHER_DAUGHTER_MILK ||
      specialEventCode === SPECIAL_EVENT_TWO_SISTERS_MILK ||
      specialEventCode === SPECIAL_EVENT_THREE_SISTER_MILK ||
      specialEventCode === SPECIAL_EVENT_FOUR_SISTER_MILK ||
      specialEventCode === SPECIAL_EVENT_FIVE_SISTERS_MILK
    ) {
      return (
        <>
          <CowBreedsPreview
            subbreedsInfo={farmSubbreeds}
            milkBatch={milkBatch}
          />
          <div className="slide2-familyTree"></div>
        </>
      );
    } else if (specialEventCode === "christmasmilk") {
      return (
        <>
          <Graphic
            cssClassName={topMilkedCowCssClassName + " cow--christmas"}
          />
          <ChristmasWhistle className="christmas--whistle" />
          <ChristmasHat className="christmas--hat" />
        </>
      );
    } else if (
      specialEventCode === SPECIAL_EVENT_RAIN_MILK ||
      specialEventCode === SPECIAL_EVENT_HOT_MILK ||
      specialEventCode === SPECIAL_EVENT_CLOUDY_MILK ||
      specialEventCode === SPECIAL_EVENT_SNOW_MILK
    ) {
      return (
        <>
          <div className="slide2-smallBush"></div>
          <div className="slide2-bigBush"></div>
          <div className="slide2-smallGrass"></div>
          <div className="slide2-bigGrass"></div>
        </>
      );
    }
  };
  //#endregion
  const search = useLocation().search;
  const farmId = new URLSearchParams(search).get("f");
  const getImageInLastMijnMelkSlide = (farmId) => {
    switch (farmId) {
      case "A4D1442E459E":
        return "slide4--image--dobblehoeve";
      case "8D8F970C4BB1":
        return "slide4--image--oostdam";
      case "9FCA2FEE4AB3":
        return "slide4--image--rotgans";
      case "9643297842C4":
        return "slide4--image--schutte";
      default:
        return "slide4--image--dobblehoeve";
    }
  };
  //#region SWIPE FUNCTIONALITY
  const debouncedSwipeHandler = _.throttle(swipeHandler, 10);

  function swipeHandler(deltaX) {
    let latestSwipeDeltaX = getLatestSwipeDeltaX();
    const isMoveRight = latestSwipeDeltaX < 0;
    handleSlideChange(isMoveRight);
  }

  const swipeEventHandlers = useSwipeable({
    onSwiped: (eventData) => {
      let eventDeltaValue;

      if (isAppOpenedInLandscapeMode) {
        eventDeltaValue = _.cloneDeep(eventData.deltaX);
      } else {
        eventDeltaValue = _.cloneDeep(eventData.deltaY);
      }
      debouncedSwipeHandler(eventDeltaValue);
    },
    onSwipeStart: (eventData) => {
      let eventDeltaValue;
      if (isAppOpenedInLandscapeMode) {
        eventDeltaValue = _.cloneDeep(eventData.deltaX);
      } else {
        eventDeltaValue = _.cloneDeep(eventData.deltaY);
      }
      setLatestSwipeDeltaX(eventDeltaValue);
    },
    trackMouse: false,
  });
  //#endregion

  //#region SLIDE CHANGE LOGIC
  function handleSlideChange(isMoveRight) {
    if (isMoveRight) {
      moveToNextSlide();
    } else {
      moveToPreviousSlide();
    }
    updateComponentCssAfterSlideChange();
  }

  function moveToNextSlide() {
    let nextSlideIndex =
      currentRotationDegree.current === 4
        ? currentRotationDegree.current
        : currentRotationDegree.current + 1;
    currentRotationDegree.current = nextSlideIndex;
  }

  function moveToPreviousSlide() {
    let nextSlideIndex =
      currentRotationDegree.current === 0
        ? currentRotationDegree.current
        : currentRotationDegree.current - 1;
    currentRotationDegree.current = nextSlideIndex;
  }

  function updateComponentCssAfterSlideChange() {
    let additionalSlide3CssClassAppendix =
      currentRotationDegree.current === 3 ? " is-ingredients-displayed" : "";
    rotatedAreaContainerRef.current.className =
      "rotated-area-container rotated-" +
      currentRotationDegree.current +
      additionalSlide3CssClassAppendix;
  }
  //#endregion
  // //{farmerName} in {farmLocation || "Netherlands"}
  return (
    <div
      className="rotating-story-container"
      ref={rotatingStoryContainerRef}
      onWheel={handleWheel}
      {...swipeEventHandlers}
    >
      <AnimatedBackground />
      <div
        className="rotated-area-container rotated-1"
        onClick={() => {}}
        ref={rotatedAreaContainerRef}
      >
        {specialEventCode && specialEventCode === SPECIAL_EVENT_CLOUDY_MILK ? (
          <div className="onboarding-clouds-background"></div>
        ) : null}
        <Planet />
        <div className="slide1">
          <h3 className="slide1-text">
            {translate("MILK_ONBOARDING_CONGRATULATIONS")}
          </h3>
          <h3 className="slide1-subtext">
            {translate("MILK_ONBOARDING_YOUR_MILK")}
          </h3>
          <div className="slide1-mijnMelkPlate"></div>
          <div className="slide1-batchNumber">
            <div className="slide1-batchNumber--text">
              {translate("MILK_ONBOARDING_BATCHNUMBER")}
            </div>
            <div className="slide1-batchNumber--number">
              {milkBatch.externalId}
            </div>
          </div>
          <div className="slide1-pole"></div>
          <div className="slide1-road--sign">
            <div className="slide1-road--sign--text">
              {translate("MIJNMELK_ONBOARDING_UNIQUE")}
            </div>
          </div>
          <div className="slide1-grassUnderThePole"></div>
        </div>
        <div className="slide2">
          {specialEventCode
            ? getSpecialEventBackgroundJSX(specialEventCode.toLowerCase())
            : null}
          <div className="slide2-batchNumber">
            <div className="slide2-batchNumber--text">
              {translate("MIJNMELK_ONBOARDING_SECOND_SLIDE_MILKED_ON")}
            </div>
            <div className="slide2-batchNumber--number">
              {moment(milkBatch.end).utc().format("DD-MM-YYYY")}
            </div>
          </div>
          <h3 className="slide2-text">
            {translate("MIJNMELK_ONBOARDING_SECOND_SLIDE_YOUR_MILK")}
            <br></br>
            <span className="slide2-subtext">
              {translate(`SPECIAL_${specialEventCode}_HEADER_TEXT`)}
            </span>
          </h3>
          <div className="slide2-grassUnderThePole"></div>
          <div className="slide2-grassLeftSide"></div>
          <div className="slide2-grassRightSide"></div>
          {specialEventCode
            ? getSpecialEventElementsJSX(specialEventCode.toLowerCase())
            : null}
          <div className="slide2-milkInfoPlate">
            <div className="slide2-milkInfo--text">
              {translate(`SPECIAL_${specialEventCode}_TEXT`)}
            </div>
          </div>
          <div className="slide2-firstPole"></div>
        </div>
        <div className="slide3">
          <div className="slide3-batchNumberPlate">
            <div className="slide3-batchNumberPlate--text">
              {translateWithValues("MIJNMELK_ONBOARDING_AMOUNT_OF_COWS", {
                cowsNumber: numberOfCowsInTheBatch,
              })}
            </div>
          </div>
          <div className="slide3-topThreeCows">
            <div className="slide3-topThreeCows--text">
              {translate("MIJNMELK_ONBOARDING_TOP_THREE_COWS")}
            </div>
          </div>
          <div className="slide3-bush"></div>
          <div className="slide3-grassUnderThePole"></div>
          <div className="slide3-pole"></div>
          <CowBreedsPreview
            subbreedsInfo={farmSubbreeds}
            milkBatch={milkBatch}
          />
        </div>
        <div className="slide4">
          <div className="milkBottleStory--imgCaption">
            <img
              src={getDisableCacheAppendix(farmerImage)}
              className="milkBottleStory--imgContainer"
            ></img>
            <div className="milkBottleStory--farmerName">{farmerName}</div>
          </div>
          <div className={getFarmHouseStyle(farmStyle)} />
          <div className="slide4-small-cow" />
          <div className="slide4-milkInfoPlate">
            <div className="slide4-milkInfo--text">
              {translateWithValues("MIJNMELK_ONBOARDING_MILK_INFO", {
                farmName: farmName,
              })}
              {farmLocation}
            </div>
          </div>
          <div className="slide4-farmPlate">
            <div className={getImageInLastMijnMelkSlide(farmId)}></div>
          </div>
          <h3
            className="slide4-text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml(
                "MIJNMELK_ONBOARDING_MILK_COMES_FROM",
                {
                  farmerName,
                },
              ),
            }}
          ></h3>
          <Map
            cssClassName="slide4-map"
            pinX={farmLocationMapPinX}
            pinY={farmLocationMapPinY}
          />
          <div className="slide4-cow"></div>
          <div className="slide4-smallGrass"></div>
          <div className="slide4-bigGrass"></div>
          <div
            className="slide4-sign"
            onClick={() =>
              onNavigateToInitialSlideFn(SIDE_MENU_OPTION_AGE_SELECTION)
            }
          >
            <div className="slide4-sign-text">
              {translate("MIJNMELK_ONBOARDING_DISCOVER_THE_FARM")}
            </div>
          </div>
          <div className="slide4-firstPole"></div>
          <div className="slide4-secondPole"></div>
        </div>
      </div>
      <div className="slide1-swipe--gesture"></div>
      <div className="bottle staticMilkBottle" />
      <AnimatedMilkBottle
        proteinPercentage={20}
        fatPercentage={40}
        lactosePercentage={40}
        milkBottleHeightInPx={241 * 2}
        milkBottleWidthInPx={100 * 2}
        breedsInfo={farmBreeds}
        milkBatch={milkBatch}
      />
    </div>
  );
}

export default RotatingStoryContainer;
