import _ from "lodash";
import {
  VOTE_FOR_COW_REQUEST_SUCCESS,
  VOTE_FOR_COW_REQUEST_ERROR,
  RESET_LAST_VOTE_RESULT
} from "../components/CowNameVoting/types.js";

/** Store states
 * @constant {Object} */
export const initialState = {
  cowIdsWithVote: [],
  isNamePollVoteError: false,
  errorMessage: "",
  lastVoteResult: {}
};

/** Name Poll Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function namePollReducer(state = initialState, action) {
  switch (action.type) {
    case VOTE_FOR_COW_REQUEST_SUCCESS:
      let stateCopy = _.cloneDeep(state);
      stateCopy.cowIdsWithVote.push(action.payload.cowExternalId);
      stateCopy.isNamePollVoteError = false;
      stateCopy.errorMessage = "";
      stateCopy.lastVoteResult = action.payload.newPollData;
      return stateCopy;

    case VOTE_FOR_COW_REQUEST_ERROR:
      return {
        ...state,
        isCowDailyDetailsError: true,
        lastVoteResult: {},
        errorMessage: action.payload,
      };
    case RESET_LAST_VOTE_RESULT:
      return {
        ...state,
        lastVoteResult: {},
      };

    default:
      return state;
  }
}

export default namePollReducer;
