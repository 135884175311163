import _ from "lodash";
import React, { useEffect } from "react";
import "./FamilyTree.scss";
import FamilyMember from "./FamilyMember";
import { translate } from "../../localization/TranslationUtil";
import { useDispatch } from "react-redux";
import { showPopup } from "../../common/components/PopupContainer/actions";
import Cow from "../../components/Cow/Cow";
import { COW_CSS_CLASSNAME } from "../../common/constants";

const FamilyTree = React.memo(
  ({ expandedCowDetails, cowDetails, subBreed }) => {
    const dispatch = useDispatch();
    let areThereRelativesOfTheCow = cowDetails.relatives.length > 0;

    let isFullFamilyTree = areThereRelativesOfTheCow;
    let familyTreeCssClassname = isFullFamilyTree
      ? "familyTree--bigger"
      : "familyTree--smaller";

    function handleCowClicked(clickedCowData) {
      dispatch(showPopup(clickedCowData));
    }

    if (!_.isEmpty(cowDetails)) {
      return (
        <div className={`familyTree ${familyTreeCssClassname}`}>
          <div className="familyTree--basePart">
            {cowDetails.relatives.map(
              (currentCowData, currentRelativeIndex) => {
                return (
                  <FamilyMember
                    cowDetails={currentCowData}
                    subBreed={subBreed}
                    relativeName={
                      currentCowData.name && currentCowData.name.toUpperCase()
                    }
                    familyRole={`relative-${currentRelativeIndex}`}
                    onFamilyMemberClickFn={handleCowClicked}
                    isClickable={true}
                  />
                );
              },
            )}
            {cowDetails.siblings.map((currentCowData, currentSiblingIndex) => {
              return (
                <FamilyMember
                  cowDetails={currentCowData}
                  subBreed={subBreed}
                  relativeName={translate("FAMILY_ROLE_SISTER")}
                  familyRole={`sister-${currentSiblingIndex}`}
                  onFamilyMemberClickFn={handleCowClicked}
                  isClickable={true}
                />
              );
            })}
            <FamilyMember
              cowDetails={cowDetails.grandMother}
              subBreed={subBreed}
              relativeName={translate("FAMILY_ROLE_GRANDMOTHER").toUpperCase()}
              familyRole="grandmother"
              onFamilyMemberClickFn={handleCowClicked}
              isClickable={true}
            />
            <FamilyMember
              cowDetails={cowDetails.mother}
              subBreed={subBreed}
              relativeName={translate("FAMILY_ROLE_MOTHER").toUpperCase()}
              familyRole="mother"
              onFamilyMemberClickFn={handleCowClicked}
              isClickable={true}
            />
            {/* <Cow
                    cowData={expandedCowDetails}
                    cowCssClassname={COW_CSS_CLASSNAME}
                    cowXCoordinate={40 + "vw"}
                    cowYCoordinate={0}
                    cowScaleX={1}
                    cowClickHandlerFn={(clickedCowData) => { }}
                    additionalCssClassnames="cow--bigger cow--fixed familyTree--cow"
                    doPositionFixed={true}
                /> */}
          </div>
        </div>
      );
    } else {
      return "";
    }
  },
);

export default FamilyTree;
