import React from "react";
import "./ThirdSlideOrbiterYoghurt.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const ThirdSlideOrbiterYoghurt = ({}) => {
  return (
    <div className="orbiterStoryThirdSlide">
      <div className="orbiterStoryThirdSlide--floor"></div>
      <TextCaption additionalCssClassname="orbiterStoryThirdSlide--textCaption">
        <div className="orbiterStoryThirdSlide--text">
          {translate("ORBITER_THIRD_SLIDE_YOGHURT_TEXT")}
        </div>
      </TextCaption>
      <div className="orbiterStoryThirdSlide--robot"></div>
      <div className="orbiterStoryThirdSlide--pipe"></div>
      <div className="orbiterStoryThirdSlide--secondPipe"></div>
      <div className="orbiterStoryThirdSlide--temperature"></div>
      <div className="orbiterStoryThirdSlide--temperatureAfter"></div>
      <div className="orbiterStoryThirdSlide--milkywayPipe"></div>
      <div className="orbiterStoryThirdSlide--arrow"></div> 
      <div className="orbiterStoryThirdSlide--temperatureContainerAdult"></div> 
      <div className="orbiterStoryThirdSlide--temperatureContainerAdultBefore"></div>
      <div className="orbiterStoryThirdSlide--secondArrow"></div>
      <div className="orbiterStoryThirdSlide--thirdArrow"></div>
      <div className="orbiterStoryThirdSlide--temperatureText">
        {translate("ORBITER_STORY_THIRD_TEMPERATURE_TEXT")}
      </div>
    </div>
  );
};

export default ThirdSlideOrbiterYoghurt;
