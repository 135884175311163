let latestSaveFn = {};
export function getLatestSaveFn() {
  return latestSaveFn;
}

export function setLatestSaveFn(newSaveFn) {
  latestSaveFn = newSaveFn;
}

let lastSaveStatus = true;
export function getLastSaveStatus() {
  return lastSaveStatus;
}

export function setLastSaveStatus(newSaveStatus) {
  lastSaveStatus = newSaveStatus;
}
