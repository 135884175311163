import React from "react";
import {
  translate,
  translateWithValues,
} from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "../IconButton/IconButton";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import LabelValue from "../LabelValue/LabelValue";
import "./OrbiterPopup.scss";


/** OrbiterPopUp component */
function OrbiterPopup({ closePopupClickFn, callToActionClickFn, isAdultSelected, secondCallToActionClickFn }) {
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  return (
    <div className="orbiterPopup">
      <div className="orbiterPopup--container">
      <IconButton
        cssClassName="orbiterPopup--btnClosePopup"
        onClickFn={closePopupClickFn}
      /> 
      <div className="orbiterPopup--title">{translate("ROBOT_ORBITER_TITLE")}</div>
        <div className="orbiterPopup--descriptionValue">
          {translate("ROBOT_ORBITER_DESCRIPTION")}
        </div>
    <div className="orbiter--buttons">
      <CallToActionButton
        isAdultSelected = {isAdultSelected}
        cssClassName="orbiterPopup--btnCallToAction"
        label={translate("ORBITER_MILK_STORY_BUTTON")}
        onClickFn={() => {
          callToActionClickFn();
          closePopupClickFn();
        }}
      />
      <CallToActionButton
        isAdultSelected = {isAdultSelected}
        cssClassName="orbiterPopup--btnCallToActionMilkStory"
        label={translate("ORBITER_YOGHURT_PROCESS")}
        onClickFn={() => {
          secondCallToActionClickFn();
          closePopupClickFn();
        }}
      /> 
      </div>
      </div>
      
      <Graphic cssClassName="orbiterPopup--logo" />
     
    </div>
  );
}
export default OrbiterPopup;
