import { CHANGE_LANGUAGE } from "../localization/types";

/** Store states
 * @constant {Object} */
export const initialState = {
  selectedLanguage: {},
};

/** End user app selected language reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function languageReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload.selectedLanguage,
      };
    default:
      return state;
  }
}

export default languageReducer;
