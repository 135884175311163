import _ from "lodash";
import React, { useEffect } from "react";
import BackgroundLayer from "../../BackgroundLayer/BackgroundLayer";
import { getBackgroundLayerTranslateXInPx } from "../parallax_util";
import "./ParallaxRobotStoryForAdults.scss";
import {
  WEATHER_TYPE_SNOW,
  WEATHER_TYPE_SNOWSTORM,
} from "../../../common/constants";
import WindmillGenerator from "../../Windmills/WindmillGenerator";

export const NUMBER_OF_SLIDES_FOR_MILKING = 2;

function ParallaxRobotStoryForAdults({
  numberOfSlides,
  slideWidthInPx,
  slideHeightInPx,
  translateX,
  startX,
  endX,
  cowDailyDetails,
  cowGrazingData,
}) {
  const BACKGROUND_LAYER_MOUNTAIN_MOVEMENT_AMOUNT = 100;
  const BACKGROUND_LAYER_STARS_MOVEMENT_AMOUNT = 200;
  const BACKGROUND_LAYER_CLOUDS_MOVEMENT_AMOUNT = 200;
  const BACKGROUND_LAYER_HILLS_1_MOVEMENT_AMOUNT =
    (numberOfSlides * slideWidthInPx) / 4;
  const BACKGROUND_LAYER_WINDMILLS_MOVEMENT_AMOUNT = endX - startX;
  const BACKGROUND_LAYER_HILLS_2_MOVEMENT_AMOUNT =
    (numberOfSlides * slideWidthInPx) / 4;
  const BACKGROUND_LAYER_TREES_AND_HOUSES_MOVEMENT_AMOUNT =
    (numberOfSlides * slideWidthInPx) / 2.0;
  const BACKGROUND_LAYER_SLIDE_BACKGROUND_MOVEMENT_AMOUNT = endX - startX;
  const BACKGROUND_LAYER_GRASS_MOVEMENT_AMOUNT = endX - startX;

  useEffect(() => {}, [numberOfSlides]);

  if (translateX < startX || translateX > endX) {
    return null;
  }

  let cssClassName = "parallax-cow-robot-adults";

  function getForegroundBackgroundLayerClassNameVarietiesByCowDailyDetails(
    cowDailyDetails,
  ) {
    let introClasses = [
      "background-pcd-grass9",
      "background-pcd-grass9",
      "background-pcd-grass9",
      "background-pcd-grass9",
      "background-pcd-grass9",
      "background-pcd-grass9",
      "background-pcd-grass9",
      "background-pcd-grass9",
      // 'background-pcd-grass5',
      // 'background-pcd-grass3',
    ]; // intro

    let outroClasses = ["background-pcd-grass5"];

    let backgroundClasses = [...introClasses]; // intro

    backgroundClasses = [...backgroundClasses, ...outroClasses]; // outro
    //#endregion

    return backgroundClasses;
  }

  function getGrassBackgroundLayerClassNameVarietiesByCowDailyDetails(
    cowDailyDetails,
  ) {
    let backgroundVarieties = [
      "background-grassDailyStoryAdult1",
      "background-grassDailyStoryAdult1",
      "background-grassDailyStoryAdult1",
    ];
    for (var i = 0; i < numberOfSlides - 3; i++) {
      backgroundVarieties.push("background-grassDailyStoryAdult1");
    }
    return backgroundVarieties;
  }

  const HILLS_CLASSNAME_VARIETIES = [
    "background-hillsAdult1",
    "background-hillsAdult2",
    "background-hillsAdult3",
    "background-hillsAdult4",
    "background-hillsAdult5",
  ];
  const HILLS_CLASSNAME_VARIETIES_WEATHER_SNOW = [
    "background-hills11-snow",
    "background-hills12-snow",
    "background-hills13-snow",
    "background-hills14-snow",
    "background-hills15-snow",
  ];

  function getHills1BackgroundLayerClassNameVarietiesByCowDailyDetails(
    cowDailyDetails,
  ) {
    let foregroundClassNames =
      getForegroundBackgroundLayerClassNameVarietiesByCowDailyDetails(
        cowDailyDetails,
      );

    let hillsClassNames = foregroundClassNames.map(
      (currentClassname, currentClassnameIndex) => {
        return HILLS_CLASSNAME_VARIETIES[
          currentClassnameIndex % HILLS_CLASSNAME_VARIETIES.length
        ];
      },
    );
    let currentMilkingIndex = 0;
    foregroundClassNames.forEach((currentClassname, currentClassnameIndex) => {
      let isMilkingClassname = currentClassname.indexOf("milking") !== -1;
      if (isMilkingClassname) {
        let currentMilkingData =
          cowDailyDetails &&
          cowDailyDetails.milkings &&
          cowDailyDetails.milkings[currentMilkingIndex];
        let isSnowingDuringRespectiveMilking =
          currentMilkingData.weatherString === WEATHER_TYPE_SNOW ||
          currentMilkingData.weatherString === WEATHER_TYPE_SNOWSTORM;
        if (isSnowingDuringRespectiveMilking) {
          hillsClassNames[currentClassnameIndex] =
            HILLS_CLASSNAME_VARIETIES_WEATHER_SNOW[0];
        }
      }
    });
    return hillsClassNames;
  }
  let hills1BackgroundClassNameVarieties =
    getHills1BackgroundLayerClassNameVarietiesByCowDailyDetails(
      cowDailyDetails,
    );

  return (
    <div className={cssClassName}>
      {/* <BackgroundLayer
        backgroundClassNameVarietiesArray={[
          "background-pcd-clouds1",
          "background-pcd-clouds2",
          "background-pcd-clouds3",
        ]}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_CLOUDS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--clouds"
      >
        <Clouds
          viewportWidthInPx={slideWidthInPx}
          viewportHeightInPx={slideHeightInPx}
        />
      </BackgroundLayer> */}
      <BackgroundLayer
        backgroundClassNameVarietiesArray={hills1BackgroundClassNameVarieties}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_HILLS_1_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--hills1"
        forceSvgInlining="true"
        farmTheme={"adult"}
      />
      <BackgroundLayer
        backgroundClassNameVarietiesArray={[]}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_WINDMILLS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--windmills"
      >
        <WindmillGenerator
          numberOfSlides={numberOfSlides}
          slideWidthInPx={slideWidthInPx}
        />
      </BackgroundLayer>
      <BackgroundLayer
        backgroundClassNameVarietiesArray={[
          "background-forrestAdult1",
          "background-forrestAdult2",
          "background-forrestAdult3",
          "background-forrestAdult4",
          "background-forrestAdult5",
        ]}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_HILLS_2_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--hills2"
        forceSvgInlining="true"
        farmTheme={"adult"}
      />
      {/* <BackgroundLayer
        backgroundClassNameVarietiesArray={[
          "background-pcd-trees10",
          "background-pcd-trees2",
          "background-pcd-trees3",
        ]}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_TREES_AND_HOUSES_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--trees"
      /> */}
      <BackgroundLayer
        backgroundClassNameVarietiesArray={getGrassBackgroundLayerClassNameVarietiesByCowDailyDetails(
          cowDailyDetails,
        )}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_GRASS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--grass"
        forceSvgInlining="true"
        farmTheme={"adult"}
      />
    </div>
  );
}

export default ParallaxRobotStoryForAdults;
