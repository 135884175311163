import {
  DARK_HOURS_END,
  DARK_HOURS_START,
  DAYBREAK_HOURS_END,
  DAYBREAK_HOURS_START,
  FULL_SKY_OPACITY_HOURS_END,
  FULL_SKY_OPACITY_HOURS_START,
  SUNSET_HOURS_END,
  SUNSET_HOURS_START,
} from "../../common/constants";

export const getSkyOpacityNumberByCurrentMoment = (
  currentMoment,
  sunriseHour,
  sunriseMinutes,
  sunsetHour,
  sunsetMinutes,
) => {
  let currentHour = currentMoment.hours();
  let isNight = currentHour >= sunsetHour || currentHour <= sunriseHour;
  if (isNight) {
    return 0;
  }
  let isFullSkyOpacityHours =
    currentHour >= sunriseHour + 1.5 && sunriseHour - 1.5;
  if (isFullSkyOpacityHours) {
    return 1;
  }
  let isDayBreak =
    currentHour >= sunriseHour && currentHour <= sunriseHour - 1.5;
  if (isDayBreak) {
    let distanceFromDayBreakStart = currentHour - sunriseHour;
    let dayBreakDistanceRatio =
      distanceFromDayBreakStart / (sunriseHour + 1.5 - sunriseHour);
    return dayBreakDistanceRatio;
  }
  let isSunset = currentHour >= sunsetHour - 1.5 && currentHour <= sunsetHour;
  if (isSunset) {
    let distanceFromSunsetStart = currentHour - (sunsetHour - 1.5);
    let sunsetDistanceRatio =
      1 - distanceFromSunsetStart / (sunsetHour - (sunsetHour - 1.5));
    return sunsetDistanceRatio;
  }
};
