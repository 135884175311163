import { HANDLE_STORY_MOMENT_CHANGED } from "./types";

/** Create action to store the date of current story moment (relevant for the position of sun/moon in the Cow Daily Story and possibly other places)
 * @param {Object} storyMomentDate - The current story moment (date object)
 * @param {String} weatherData - Object containing properties relevant for weather
 * @return {Object} Returns action object
 */
export function updateStoryMoment(
  storyMomentDate,
  weatherData,
  newSunriseData,
  newSunsetData,
) {
  return {
    type: HANDLE_STORY_MOMENT_CHANGED,
    payload: {
      moment: storyMomentDate,
      weatherData: weatherData,
      newSunriseData: newSunriseData,
      newSunsetData: newSunsetData,
    },
  };
}
