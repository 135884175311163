import React from "react";
import { 
  translate,
  translateWithValues,
  translateWithValuesAndHtml } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import "./StartingSlideAnimalWelfare.scss";
import {translateDynamic} from "../../localization/TranslationUtil";

const StartingSlideAnimalWelfare = ({farmDetails}) => {
  const farmName = translateDynamic(farmDetails?.farmName)

  return (
    <div className="sustainabilityStoryStartingPoint">
      <TextCaption additionalCssClassname="sustainabilityStoryStartingPoint--textCaption">
      <div className="sustainabilityStoryStartingPoint--title">
          {translate("ANIMAL_WELFARE_SUSTAINABILITY_POPUP")}
        </div>
        <div className="sustainabilityStoryStartingPoint--text">
          {translateWithValues("SUSTAINABILITY_STORY_HEADER",{farmName})}
        </div>
      </TextCaption>
      <div className="sustainabilityStory-leftTree"></div>
      <div className="sustainability-intro-house"></div>
      <div className="sustainability-intro-plate"></div>
      <div className="sustainability-intro-silo"></div>
      <div className="sustainability-intro-smallCow"></div>
    </div>
  );
};

export default StartingSlideAnimalWelfare;
