import React from "react";
import "./ThirdSlideJuno.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const ThirdSlideJuno = ({}) => {
  return (
    <div className="junoStoryThirdSlide">
      <TextCaption additionalCssClassname="junoStoryThirdSlide--textCaption">
        <div className="junoStoryThirdSlide--title">
          {translate("JUNO_TITLE_THIRD_SLIDE")}
        </div>
        <div className="junoStoryThirdSlide--text">
          {translate("JUNO_THIRD_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="junoStoryThirdSlide--imgCaption">
        <div className="junoStoryThirdSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default ThirdSlideJuno;
