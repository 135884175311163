import React from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import './PinchView.scss';


/**
 * PinchView component.
 * @param {Object} props - Object which contains all methods props of component.
 * @param {function} props.getSelectedItemDetails - Send details of the clicked canbus element
 */
function PinchView({
  getSelectedItemDetails,
  children,
  onZoomFn
}) {
  return (
    <TransformWrapper initialScale={1}
      maxScale={3}
      onZoom={onZoomFn}
      alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
      limitToBounds={true}
      maxPositionX={0}
      maxPositionY={0}
    >
      <TransformComponent>
        {children}
      </TransformComponent>
    </TransformWrapper>
  );
}

export default PinchView;
