import React from "react";
import "./StartingSlideLuna.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import StoryNavigationHint from "../../common/components/StoryNavigationHint/StoryNavigationHint";

const StartingSlideLuna = ({ name }) => {
  return (
    <div className="lunaStoryStartingSlide">
      <div className="lunaStoryStartingSlide--pole"></div>
      <TextCaption additionalCssClassname="lunaStoryStartingSlide--textCaption">
        <div className="lunaStoryStartingSlide--title">
          {translate("LUNA_ROBOT_STORY_TITLE")}
        </div>

        <div className="lunaStoryStartingSlide--name">
          {translate("LUNA_ROBOT_STORY_FIRST_SLIDE")}
        </div>
      </TextCaption>
      <div className="discoveryStory--bottomColorOverlay"></div>
      <div className="lunaStoryStartingSlide--robot"></div>
      <StoryNavigationHint />
    </div>
  );
};

export default StartingSlideLuna;
