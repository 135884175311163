import React from "react";
import "./AstronautNumberSlide.scss";
import {
  translate,
  translateWithValues,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";

const AstronautNumberSlide = ({name}) => {
  const { astronautDetails } = useSelector((state) => state.astronautDetails);
  return (
    <div className="astronautNumberSlide">
      <div className="astronautNumberSlide--matrixElements"></div>
      <TextCaption additionalCssClassname="astronautNumberSlide--textCaption">
        <div className="astronautNumberSlide--text">
          {translateWithValues("ASTRONAUT_LIVE_DATA_NUMBER_SLIDE", {
            farmerName: name,
            astronautName: astronautDetails.name, 
            astronautSerialNumber : astronautDetails.model
          })}
        </div>
      </TextCaption>
    </div>
  );
};

export default AstronautNumberSlide;
