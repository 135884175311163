//Style
import React, { useEffect, useState } from "react";
import "./Header.scss";
//Components
import ShareButtonDialog from "../../common/components/ShareButtonDialog/ShareButtonDialog";
// Redux
import { redirect } from "../CustomRedirect/actions.js";
import { useSelector, useDispatch } from "react-redux";
import { translate } from "../../localization/TranslationUtil";
/** Header component */
function Header() {
  const dispatch = useDispatch();
  /** The main function which contains route navigation logic and from where app start */
  const { currentUser: username } = useSelector((state) => state.userSession);
  //** Access token */
  const { accessToken, userLanguage } = useSelector(
    (state) => state.userSession,
  );
  /** URL */
  const url = window.location.pathname;
  const isUserOnHelpPage = url.indexOf("help") !== -1;
  const isUserOnFarmDetailsPage = url.indexOf("/farms/") !== -1;
  /** Redirect */
  const redirectOnClick = () => {
    dispatch(redirect("/"));
  };
  const farmerPageDetails = useSelector((state) => ({
    ...state.farmerPageDetails,
  }));

  // Dialog state
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  const [isShareButtonDisplayed, setShareButtonDisplayed] = useState(false);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  console.log("@@FarmDetails", farmerPageDetails)

  const internalId = farmerPageDetails.farmerPageDetails && farmerPageDetails.farmerPageDetails.farm && farmerPageDetails.farmerPageDetails.farm.internalId
  console.log("@@internalId", internalId)
  return (
    <div className="header">
      <div className="header--top" data-testid="div-header">
        <div className="header--top--logo"></div>
        <span className="header--top--logotext">
          {translate("ADMIN_HEADER_TEXT")}
        </span>
        <button
          onClick={() => {
            setShareButtonDisplayed(true);
          }}
          className="button"
          type="button"
        >
          <i className="shareButton"></i>
          <div className="shareButton--text">Share</div>
        </button>
        {isShareButtonDisplayed && (
          <ShareButtonDialog
            onCancelCallbackFn={() => {
              setShareButtonDisplayed(false);
            }}
            farmId={internalId || 340}
          />
        )}
      </div>
    </div>
  );
}
export default Header;
