const Y_CLOSENESS_THRESHOLD = 1.5;
const X_CLOSENESS_THRESHOLD = 5;

export const areThereOtherActorsReallyCloseBy = function (currentCowId, actorPositions, doCheckEqual) {
    let areThereOtherActorsReallyClose = false;
    let currentCowY = actorPositions[currentCowId] && +actorPositions[currentCowId].y;
    Object.keys(actorPositions).forEach(currentActorId => {
        if (currentActorId == currentCowId) {
            return;
        }
        let currentActorPosition = actorPositions[currentActorId];
        let isVerticallyClose = doCheckEqual ? currentActorPosition.y == currentCowY : currentActorPosition.y - currentCowY < Y_CLOSENESS_THRESHOLD && currentActorPosition.y != currentCowY;
        let isHorizontallyClose = currentActorPosition && actorPositions && actorPositions[currentCowId] && actorPositions[currentCowId].x ? Math.abs(currentActorPosition.x - actorPositions[currentCowId].x) <= X_CLOSENESS_THRESHOLD : false;
        if (isVerticallyClose && isHorizontallyClose) {
            areThereOtherActorsReallyClose = true;
        }
    });
    return areThereOtherActorsReallyClose;
};

export const checkWhetherActorWithIdOverlapsWithActorWithId = function (actor1X, actor1Y, actor2Id, actorPositions) {
    let actor2Position = actorPositions[actor2Id];

    if (actor2Position) {
        let isVerticallyClose = (actor1Y == actor2Position.y);
        let isHorizontallyClose = (actor1X ? Math.abs(actor1X - +actor2Position.x) <= X_CLOSENESS_THRESHOLD : false);
        return (isVerticallyClose && isHorizontallyClose);    
    }
    return false;
};