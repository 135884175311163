import _ from "lodash";
import React from "react";
import "./AgeSelectionPage.scss";
import TextCaption from "../../components/TextCaption/TextCaption";
import {
  activateSimpleModeForCurrentLanguage,
  deactivateSimpleModeForCurrentLanguage,
  translate,
} from "../../localization/TranslationUtil";
import { useDispatch } from "react-redux";
import { updateFarmThemeBasedOnSelectedAge } from "../../components/VirtualFarm/actions";
import ReferenceValueChart from "../../components/ReferenceValueChart/ReferenceValueChart";
import {
  SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MAX_COLOR,
  SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MID_COLOR,
  SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MIN_COLOR,
  SUSTAINABILITY_CHART_FOR_ADULTS_STROKE_WIDTH,
} from "../../common/constants";
import "./AgeSelectionPage.scss"

function AgeSelectionPage({ onProceedToNextScreenFn, farmType }) {
  const dispatch = useDispatch();
  const handleUnderAgeOptionClicked = () => {
    activateSimpleModeForCurrentLanguage(dispatch);
    dispatch(updateFarmThemeBasedOnSelectedAge(false));
    onProceedToNextScreenFn();
  };
  const handleOverAgeOptionClicked = () => {
    deactivateSimpleModeForCurrentLanguage(dispatch);
    dispatch(updateFarmThemeBasedOnSelectedAge(true));
    onProceedToNextScreenFn();
  };
  
  const AgeSelectionPageCssClassName = 
  farmType === "Lely"
  ? "AgeSelectionPage--lely"
  : "AgeSelectionPage"

  return (
   <div className={AgeSelectionPageCssClassName}>
      <TextCaption additionalCssClassname="AgeSelectionPage--welcome">
        <p className="AgeSelectionPage--welcome--heading">
          {translate("WELCOME_HEADING")}
        </p>
        <p className="AgeSelectionPage--welcome--question">
          {translate("WELCOME_SCREEN_AGE_SELECTION")}
        </p>
      </TextCaption>
      <div
        className="AgeSelectionPage--welcome--kid"
        onClick={handleUnderAgeOptionClicked}
      >
        <p className="AgeSelectionPage--welcome--kid-answer">
          {translate("WELCOME_SCREEN_AGE_ANSWER_UNDER")}
        </p>
      </div>
     { farmType === "Lely" ?
        <div
        className="AgeSelectionPage--welcome--man--lely"
        onClick={handleUnderAgeOptionClicked}
      >  
        <p className="AgeSelectionPage--welcome--man-answer--lely">
          {translate("WELCOME_SCREEN_AGE_ANSWER_ABOVE")}
        </p>
      </div>
      :
        <div
          className="AgeSelectionPage--welcome--man"
          onClick={handleOverAgeOptionClicked}
        >
          <p className="AgeSelectionPage--welcome--man-answer">
            {translate("WELCOME_SCREEN_AGE_ANSWER_ABOVE")}
          </p>
      </div> }
    </div>
  );
}

export default AgeSelectionPage;