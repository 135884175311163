import "./RibbonTitle.scss";
import React from "react";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";
import { useSelector } from "react-redux";
import { translateDynamic } from "../../localization/TranslationUtil";

/** RibbonTitle component */
function RibbonTitle({
  farmData,
  isClickable,
  onRibbonTitleClickFn,
  closePopupClickFn,
}) {
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );
  let ribbonTitleCssClassname = "ribbonTitle";
  ribbonTitleCssClassname = isClickable
    ? ribbonTitleCssClassname + "--" + IS_CLICKABLE_CSS_CLASSNAME
    : ribbonTitleCssClassname;
  return (
    <div className={ribbonTitleCssClassname}>
      <div
        className="ribbonTitle--title"
        onClick={() => {
          onRibbonTitleClickFn(farmData);
        }}
      >
        {translateDynamic(farmData.farmName)}
      </div>
    </div>
  );
}
export default RibbonTitle;
