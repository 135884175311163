import React from "react";
import "./ThirdSlideDiscovery.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const ThirdSlideDiscovery = ({otherDevices }) => { 
  const filteredDevices = otherDevices.filter((currentDevice) =>
    currentDevice.type.includes("discovery"),
  ); 

  return (
    <div className="discoveryStoryThirdSlide">
      <TextCaption additionalCssClassname="discoveryStoryThirdSlide--textCaption">
        <div className="discoveryStoryThirdSlide--title">
          {translate("DISCOVERY_COW_HEALTH_TITLE")}
        </div>
        <div className="discoveryStoryThirdSlide--text"> 
        {filteredDevices.length
            ? translate("DISCOVERY_COW_HEALTH_TEXT")
            : translate("DISCOVERY_COLLECTOR_COW_HEALTH_TEXT")}
        </div>
      </TextCaption>
      <div className="discoveryStory--bottomColorOverlay"></div> 
      <div className="discoveryStoryThirdSlide--treeForAdultVersion"></div>
      <div className="discoveryStoryThirdSlide--imgCaption">
        <div className="discoveryStoryThirdSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default ThirdSlideDiscovery;
