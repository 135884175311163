import React from "react";
import "./FifthSlideOrbiterYoghurt.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const FifthSlideOrbiterYoghurt = ({}) => {
  return (
    <div className="orbiterStoryFifthSlideYoghurt">
      <div className="orbiterStoryFifthSlideYoghurt--floor"></div>
      <TextCaption additionalCssClassname="orbiterStoryFifthSlideYoghurt--textCaption">
        <div className="orbiterStoryFifthSlideYoghurt--text">
          {translate("ORBITER_FIFTH_SLIDE_YOGHURT_TEXT")}
        </div>
      </TextCaption>
      <div className="orbiterStoryFifthSlideYoghurt--robot"></div>
      <div className="orbiterStoryFifthSlideYoghurt--temperature"></div>
      <div className="orbiterStoryFifthSlideYoghurt--pipe"></div>
      <div className="orbiterStoryFifthSlideYoghurt--arrow"></div> 
      <div className="orbiterStoryFifthSlideYoghurt--arrowText"></div> 
      <div className="orbiterStoryFifthSlideYoghurt--arrowTextBottom">{translate('ORBITER_FIFTH_SLIDE_YOGHURT_TEXT_UNDER_THE_ROBOT')}</div>
    </div>
  );
};

export default FifthSlideOrbiterYoghurt;
