import React, { useState } from "react";
import _ from "lodash";
import "./ReferenceValueChart.scss";
import { ReferenceValueChartProps } from "./types/ReferenceValueChart.types";
import uuid from "react-uuid";

/** ReferenceValueChart component */
const ReferenceValueChart: React.FunctionComponent<any> = ({
  cssClassname,
  referenceValue,
  currentValue,
  rangeMinValue,
  rangeMaxValue,
  labelText,
  unitText,
  currentLabelText,
  referenceLabelText,
  rangeMinColor,
  rangeMidColor,
  rangeMaxColor,
  diagramContainerHeightInPixels,
  diagramContainerWidthInPixels,
  diagramRadiusInPixels,
  gradientMeterStrokeWidthInPixels,
}: ReferenceValueChartProps) => {
  function getArrowAngleByValue(value, minValue, maxValue, maxAngle): number {
    let limitedValue = Math.min(value, maxValue);
    limitedValue = Math.max(limitedValue, minValue);
    const fullRangeDistance = maxValue - minValue;
    const currentValueDistanceInRange = limitedValue - minValue;
    const arrowAngle =
      (currentValueDistanceInRange / fullRangeDistance) * maxAngle;
    return arrowAngle;
  }

  const referenceArrowRotationAngle: number = getArrowAngleByValue(
    referenceValue,
    rangeMinValue,
    rangeMaxValue,
    180,
  );
  const currentArrowRotationAngle: number = getArrowAngleByValue(
    currentValue,
    rangeMinValue,
    rangeMaxValue,
    180,
  );

  const diagramXCoordinate: number =
    (diagramContainerWidthInPixels - diagramRadiusInPixels * 2) / 2;

  const componentCssClassname = cssClassname
    ? "reference-value-chart" + " " + cssClassname
    : "reference-value-chart";

  const shouldDiagramArcBeGoingUp = 1; // 0 for not going up (arc on the bottom side)
  const diagramHeightInPixels =
    diagramRadiusInPixels + gradientMeterStrokeWidthInPixels;
  const diagramWavePaddingWidthInPixels = 0.1 * diagramContainerWidthInPixels;
  const gradientId = "referenceValueChartGradient_" + uuid(); // if the same gradient ID is used all diagrams will share exactly the same gradient colors being used
  const diagramActualRadiusSize = diagramHeightInPixels * 0.6;
  const diagramStartPointX =
    gradientMeterStrokeWidthInPixels * 3 + diagramWavePaddingWidthInPixels;
  const diagramStartPointY = diagramHeightInPixels;
  const diagramEndPointX =
    diagramContainerWidthInPixels -
    gradientMeterStrokeWidthInPixels * 3 -
    diagramWavePaddingWidthInPixels;
  const diagramEndPointY = diagramHeightInPixels;

  return (
    <div
      className={componentCssClassname}
      style={{
        height: diagramContainerHeightInPixels + 10,
        width: diagramContainerWidthInPixels,
      }}
    >
      <div
        className="reference-value-chart--board-base"
        style={{
          position: "absolute",
          width: diagramContainerWidthInPixels / 4,
          height: diagramContainerHeightInPixels,
          left:
            (diagramContainerWidthInPixels -
              diagramContainerWidthInPixels / 4) /
            2,
        }}
      ></div>
      <div
        className="reference-value-chart--diagram-container"
        style={{
          width: diagramContainerWidthInPixels,
          height: diagramRadiusInPixels * 2,
        }}
      >
        <span className="reference-value-chart--diagramLabelText">
          {labelText}
        </span>
        <svg
          height={diagramRadiusInPixels + gradientMeterStrokeWidthInPixels * 2}
          width={diagramContainerWidthInPixels}
          className="reference-value-chart--diagram"
        >
          <linearGradient id={gradientId} x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stop-color={rangeMinColor}></stop>
            <stop offset="50%" stop-color={rangeMidColor}></stop>
            <stop offset="100%" stop-color={rangeMaxColor}></stop>
          </linearGradient>

          <path
            d={`M${diagramStartPointX} ${diagramStartPointY} A ${diagramActualRadiusSize} ${diagramActualRadiusSize} 0 0 ${shouldDiagramArcBeGoingUp} ${diagramEndPointX}
               ${diagramEndPointY}`}
            fill="transparent"
            stroke={`url(#${gradientId})`}
            strokeWidth={gradientMeterStrokeWidthInPixels}
          />
        </svg>
        <span
          className="reference-value-chart--currentValueText"
          style={{
            position: "absolute",
            top: diagramRadiusInPixels / 1.8,
            left: diagramXCoordinate,
          }}
        >
          {currentValue + unitText}
        </span>
        <span
          className="reference-value-chart--referenceValueText"
          style={{
            position: "absolute",
            top: diagramRadiusInPixels / 1.8,
            right: diagramXCoordinate,
          }}
        >
          {referenceValue + unitText}
        </span>
        <span
          className="reference-value-chart--minValueText"
          style={{
            position: "absolute",
            bottom: diagramRadiusInPixels / 8,
            left: diagramXCoordinate + gradientMeterStrokeWidthInPixels,
          }}
        >
          {rangeMinValue + unitText}
        </span>
        <span
          className="reference-value-chart--maxValueText"
          style={{
            position: "absolute",
            width:
              diagramRadiusInPixels * 2 - gradientMeterStrokeWidthInPixels * 2,
            textAlign: "right",
            bottom: diagramRadiusInPixels / 8,
            left: diagramXCoordinate,
          }}
        >
          {rangeMaxValue + unitText}
        </span>
        <div
          className="reference-value-chart--referenceArrow"
          style={{
            transform: "rotate(" + referenceArrowRotationAngle + "deg)",
            width: diagramRadiusInPixels,
            height: diagramRadiusInPixels / 4.05,
            left: diagramXCoordinate + diagramRadiusInPixels / 8,
            transformOrigin:
              diagramRadiusInPixels -
              diagramRadiusInPixels / 8 +
              "px " +
              diagramRadiusInPixels / 8 +
              "px",
          }}
        ></div>
        <div
          className="reference-value-chart--currentArrow"
          style={{
            transform: "rotate(" + currentArrowRotationAngle + "deg)",
            width: diagramRadiusInPixels,
            height: diagramRadiusInPixels / 4,
            left: diagramXCoordinate + diagramRadiusInPixels / 8,
            transformOrigin:
              diagramRadiusInPixels -
              diagramRadiusInPixels / 8 +
              "px " +
              diagramRadiusInPixels / 8 +
              "px",
          }}
        ></div>
      </div>
      <div
        className="reference-value-chart--legend-container"
        style={{
          top: diagramRadiusInPixels * 2 + diagramRadiusInPixels / 10,
          left: diagramXCoordinate,
        }}
      >
        <div
          className="reference-value-chart--labelContainer1"
          style={{
            width: diagramRadiusInPixels * 2,
            height: (diagramRadiusInPixels * 2) / 6,
          }}
        >
          <span
            className="reference-value-chart--currentLabelText"
            style={{
              height: (diagramRadiusInPixels * 2) / 6,
              lineHeight: (diagramRadiusInPixels * 2) / 6 + "px",
              paddingLeft: diagramRadiusInPixels / 3,
            }}
          >
            {currentLabelText}
          </span>
        </div>
        <div
          className="reference-value-chart--labelContainer2"
          style={{
            width: diagramRadiusInPixels * 2,
            height: (diagramRadiusInPixels * 2) / 6,
          }}
        >
          <span
            className="reference-value-chart--referenceLabelText"
            style={{
              height: (diagramRadiusInPixels * 2) / 6,
              lineHeight: (diagramRadiusInPixels * 2) / 6 + "px",
              paddingLeft: diagramRadiusInPixels / 3,
            }}
          >
            {referenceLabelText}
          </span>
        </div>
      </div>
    </div>
  );
};
export default ReferenceValueChart;
