const ICON_HEADING_FORMAT = 'background-color: grey; color: lightgrey; padding: 2px; border-radius: 4px;';
const CONSOLE_HEADING_FORMAT = 'background-color: black; color: orange; padding: 2px; border-radius: 4px;';
const CONSOLE_SUBHEADING_FORMAT = 'background-color: black; color: #eee; padding: 2px; border-radius: 4px;';
export const CHARACTER_STOPWATCH = '⏱';
export const CHARACTER_DISCOVERY = '🤖';

export const logMessageColorfully = function (label, value, consoleMessageCssSettings) {
    let consoleMessage = '%c ' + label;
    console.log(consoleMessage, consoleMessageCssSettings, value);
};

export const printConsoleHeading = (label, value) => {
    logMessageColorfully(label, value, CONSOLE_HEADING_FORMAT);
};

export const printConsoleSubheading = (label, value) => {
    logMessageColorfully(label, value, CONSOLE_SUBHEADING_FORMAT);
};

export const printIconHeading = (label, value, iconType) => {
    logMessageColorfully(iconType + label, value, ICON_HEADING_FORMAT);
};

export const printCustomMessage = (label, iconType, value, backgroundColor, color) => {
    let customCssFormat = 'background-color: ' + backgroundColor + '; color: ' + color + '; padding: 2px; border-radius: 4px';
    logMessageColorfully(iconType + label, value, customCssFormat);
};
