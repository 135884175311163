//#region Imports
import { TRANSLATIONS_NL } from "./nl";
import { TRANSLATIONS_NL_SIMPLE } from "./nl_simple";
import { TRANSLATIONS_EN } from "./en";
import { TRANSLATIONS_EN_SIMPLE } from "./en_simple";
import _ from "lodash";
import { sanitize } from "dompurify";
import moment from "moment";
import { changeLanguage } from "./actions";
//#endregion

//#region TranslationUtil settings
const TRANSLATIONS = {
  TRANSLATIONS_NLS: TRANSLATIONS_NL_SIMPLE,
  TRANSLATIONS_NL: TRANSLATIONS_NL,
  TRANSLATIONS_ENS: TRANSLATIONS_EN_SIMPLE,
  TRANSLATIONS_EN: TRANSLATIONS_EN,
};
const LANGUAGE_NL = "nl";
const LANGUAGE_NL_FOR_CHILDREN = "nls";
const LANGUAGE_EN = "en";
const LANGUAGE_EN_FOR_CHILDREN = "ens";
const LANGUAGE_ALL_EXCEPTION_FOR_ADMIN = "all";
let DEFAULT_LANGUAGE = LANGUAGE_EN;
let SUPPORTED_LANGUAGES = [LANGUAGE_EN, LANGUAGE_NL];
var currentTranslationDictionary = {};
var selectedLanguage = DEFAULT_LANGUAGE;
var isSimpleModeActivated = false;
//#endregion

export const initializeLanguageWithBackendSettings = function () {
  initialize(DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES);
};

export const setDefaultLanguage = function (farmLanguagesInBackendFormat) {
  let newDefaultLanguageCode = farmLanguagesInBackendFormat[0].code;
  selectedLanguage = newDefaultLanguageCode;
  DEFAULT_LANGUAGE = newDefaultLanguageCode;
};

export const setSupportedLanguagesForFarm = function (
  newLanguagesArrayInBackendFormat,
) {
  let newLanguagesArray = [];
  let languagesDictionary = {};
  newLanguagesArrayInBackendFormat.forEach((currentLanguage) => {
    if (currentLanguage.code?.indexOf(LANGUAGE_EN) !== -1) {
      languagesDictionary[LANGUAGE_EN] = 1;
    }
    if (currentLanguage.code?.indexOf(LANGUAGE_NL) !== -1) {
      languagesDictionary[LANGUAGE_NL] = 1;
    }
  });
  newLanguagesArray = Object.keys(languagesDictionary);
  SUPPORTED_LANGUAGES = newLanguagesArray;
};

//#region Public/exported API methods
export const translate = function (key) { 
  return currentTranslationDictionary[key] || key;
};

export const translateNumber = function (key) {
  return currentTranslationDictionary[key] || 0;
};

export const translateDynamic = function (value) {
  let languageToTranslateFrom = selectedLanguage;
  if (_.isEmpty(value)) {
    return "";
  }
  if (languageToTranslateFrom === LANGUAGE_NL_FOR_CHILDREN) {
    return translateFallback(
      [
        LANGUAGE_NL_FOR_CHILDREN,
        LANGUAGE_NL,
        LANGUAGE_EN_FOR_CHILDREN,
        LANGUAGE_EN,
        LANGUAGE_ALL_EXCEPTION_FOR_ADMIN,
      ],
      value,
    );
  }
  if (languageToTranslateFrom === LANGUAGE_NL) {
    return translateFallback([LANGUAGE_NL, LANGUAGE_EN], value);
  }
  if (languageToTranslateFrom === LANGUAGE_EN_FOR_CHILDREN) {
    return translateFallback([LANGUAGE_EN_FOR_CHILDREN, LANGUAGE_EN], value);
  }
  if (languageToTranslateFrom === LANGUAGE_EN) {
    return translateFallback([LANGUAGE_EN], value);
  }

  return value[languageToTranslateFrom]
    ? value[languageToTranslateFrom]
    : value[DEFAULT_LANGUAGE];
};

export const translateDate = function (dateObject) {
  let languageToTranslateFrom = selectedLanguage;
  switch (languageToTranslateFrom) {
    case LANGUAGE_NL:
    case LANGUAGE_NL_FOR_CHILDREN:
      return moment(dateObject).format("DD MMMM YYYY");
    case LANGUAGE_EN:
    case LANGUAGE_EN_FOR_CHILDREN:
      return moment(dateObject).format("MMMM Do YYYY");
    default:
      return moment(dateObject).format("MMMM Do YYYY");
  }
};

export const translateSlides = function (slidesData) {
  let languageToTranslateFrom = selectedLanguage;
  const slidesDataPreviouslyUsed = slidesData[languageToTranslateFrom]
    ? slidesData[languageToTranslateFrom]
    : slidesData[DEFAULT_LANGUAGE];
  let slidesDataWithAppliedTranslation = [];

  // 1. get farmer story slides descriptions for all languages
  const descriptionValueForAllSlides = getPropertyValueForSlidesBySlidesData(
    slidesData,
    "description",
    slidesDataPreviouslyUsed && slidesDataPreviouslyUsed.length,
  );
  // 2. get farmer story slides titles for all languages
  const titleValueForAllSlides = getPropertyValueForSlidesBySlidesData(
    slidesData,
    "title",
    slidesDataPreviouslyUsed && slidesDataPreviouslyUsed.length,
  );
  descriptionValueForAllSlides.forEach(
    (currentDescriptionTranslations, currentSlideIndex) => {
      slidesDataWithAppliedTranslation.push({});
      slidesDataWithAppliedTranslation[currentSlideIndex].description =
        currentDescriptionTranslations;
      slidesDataWithAppliedTranslation[currentSlideIndex].title =
        titleValueForAllSlides[currentSlideIndex];
      slidesDataWithAppliedTranslation[currentSlideIndex].textBlockIndex =
        slidesDataPreviouslyUsed[currentSlideIndex].textBlockIndex;
      slidesDataWithAppliedTranslation[currentSlideIndex].imageData =
        slidesDataPreviouslyUsed[currentSlideIndex].imageData;
    },
  );
  return slidesDataWithAppliedTranslation;
};

const getPropertyValueForSlidesBySlidesData = (
  slidesData,
  propertyName,
  numberOfSlides,
) => {
  let propertyValueForAllSlides = [];
  for (var p = 0; p < numberOfSlides; p++) {
    propertyValueForAllSlides.push({});
  }
  Object.keys(slidesData).forEach((currentLanguageKey) => {
    slidesData[currentLanguageKey].forEach(
      (currentSlide, currentSlideIndex) => {
        let currentSlideClone = _.cloneDeep(currentSlide);
        propertyValueForAllSlides[currentSlideIndex][currentLanguageKey] =
          _.clone(currentSlideClone[propertyName]);
      },
    );
  });
  return propertyValueForAllSlides;
};

export const translateFallback = function (
  languageCodesArraySortedByPriority,
  translationDictionary,
) {
  let translation = "";
  languageCodesArraySortedByPriority.some((currentLanguageCode) => {
    translation = translationDictionary[currentLanguageCode];
    return (
      translationDictionary[currentLanguageCode] &&
      translationDictionary[currentLanguageCode].length > 0
    );
  });

  return translation;
};

export const translateWithValues = function (key, keyValueDictionary) {
  let template = currentTranslationDictionary[key];
  return template.replace(/\$\{.+?}/g, (match) => {
    match = match.replace("${", "").replace("}", "");
    return keyValueDictionary[match];
  });
};

export const translateWithValuesAndHtml = function (key, keyValueDictionary) {
  let template = currentTranslationDictionary[key];
  let translatedHtml = template.replace(/\$\{.+?}/g, (match) => {
    match = match.replace("${", "").replace("}", "");
    return keyValueDictionary[match];
  });
  return sanitize(translatedHtml);
};

export const activateSimpleModeForCurrentLanguage = function (dispatchFn) {
  isSimpleModeActivated = true;
  let selectedLanguageCode =
    selectedLanguage.length > 2
      ? selectedLanguage.substr(0, 2)
      : selectedLanguage;
  const simpleModeAppendix = isSimpleModeActivated ? "s" : "";
  initializeWithLanguage(
    TRANSLATIONS[
      "TRANSLATIONS_" +
        selectedLanguageCode.toUpperCase() +
        simpleModeAppendix.toUpperCase()
    ],
    selectedLanguageCode + simpleModeAppendix,
    dispatchFn,
  );
};

export const activateRequestedLanguage = function (props, dispatchFn) {
  let selectedLanguageCode =
    props.language.length > 2 ? props.language.substr(0, 2) : props.language;
  const simpleModeAppendix = isSimpleModeActivated ? "s" : "";
  initializeWithLanguage(
    TRANSLATIONS[
      "TRANSLATIONS_" +
        selectedLanguageCode.toUpperCase() +
        simpleModeAppendix.toUpperCase()
    ],
    selectedLanguageCode + simpleModeAppendix,
    dispatchFn,
  );
};

export const deactivateSimpleModeForCurrentLanguage = function (dispatchFn) {
  isSimpleModeActivated = false;
  let selectedLanguageCode =
    selectedLanguage.length > 2
      ? selectedLanguage.substr(0, 2)
      : selectedLanguage;
  initializeWithLanguage(
    TRANSLATIONS["TRANSLATIONS_" + selectedLanguageCode.toUpperCase()],
    selectedLanguageCode,
    dispatchFn,
  );
};

export const isSimpleModeActive = function () {
  return isSimpleModeActivated;
};
//#endregion
export const getCurrentLanguage = (defLanguage, suppLanguages) => {
  return getUserLanguage(defLanguage, suppLanguages);
};

//#region Internal methods
function getUserLanguage(defaultLanguage, supportedLanguages) {
  let userLanguage = navigator.languages
    ? navigator.languages[0]
    : navigator.language || navigator.userLanguage;
  userLanguage =
    userLanguage.length > 0 ? userLanguage.substring(0, 2) : defaultLanguage;
  if (isLanguageSupported(userLanguage, supportedLanguages)) {
    return userLanguage;
  }
  return defaultLanguage;
}

function isLanguageSupported(language, supportedLanguages) {
  return supportedLanguages.indexOf(language) !== -1;
}

function initialize(
  defaultLanguage,
  supportedLanguages,
  isSimpleModeActive,
  dispatchFn,
) {
  const userLanguage = getUserLanguage(defaultLanguage, supportedLanguages);
  const simpleModeAppendix = isSimpleModeActive || isSimpleModeActive == undefined ? "s" : "";
  initializeWithLanguage(
    TRANSLATIONS[
      "TRANSLATIONS_" +
        userLanguage.toUpperCase() +
        simpleModeAppendix.toUpperCase()
    ],
    userLanguage + simpleModeAppendix,
    dispatchFn,
  );
}

function initializeWithLanguage(
  translationsDictionary,
  selectedLanguageValue,
  dispatchFn,
) { 
  
  currentTranslationDictionary = translationsDictionary;
  selectedLanguage = selectedLanguageValue;
  dispatchFn && dispatchFn(changeLanguage(selectedLanguage));
}
//#endregion

//#region Initialization
initialize(DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES);
//#endregion
