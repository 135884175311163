import React from "react";
import "./ViewportSizeDisplay.scss";
import useWindowDimensions from "../../hooks/useViewportDimensions";


/** ViewportSizeDisplay component - component used mainly for testing purposes in order to track viewport size values */
function ViewportSizeDisplay({
    currentFlowTranslateX
}) {
    let { width: VIEWPORT_WIDTH_IN_PX, height: VIEWPORT_HEIGHT_IN_PX } =
        useWindowDimensions();
    let isAppOpenedInLandscapeMode = (VIEWPORT_WIDTH_IN_PX > VIEWPORT_HEIGHT_IN_PX);
    VIEWPORT_WIDTH_IN_PX = isAppOpenedInLandscapeMode ? VIEWPORT_WIDTH_IN_PX : VIEWPORT_HEIGHT_IN_PX;
    VIEWPORT_HEIGHT_IN_PX = isAppOpenedInLandscapeMode ? VIEWPORT_HEIGHT_IN_PX : VIEWPORT_WIDTH_IN_PX;


    return (
        <div className="viewport-size-display">
            <span className="viewport-size-display--label">Viewport width:</span>
            <span className="viewport-size-display--value">{VIEWPORT_WIDTH_IN_PX}</span>
            <span className="viewport-size-display--label">Viewport height:</span>
            <span className="viewport-size-display--value">{VIEWPORT_HEIGHT_IN_PX}</span>
            <span className="viewport-size-display--label">Flow translate X:</span>
            <span className="viewport-size-display--value">{currentFlowTranslateX}</span>
        </div>
    );
}
export default ViewportSizeDisplay;
