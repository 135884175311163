var latestMoment = {};
export const getLatestMoment = function () {
  return latestMoment;
};

export const setLatestMoment = function (newLatestMomentValue) {
  latestMoment = newLatestMomentValue;
};
var latestSunriseValue = {};
export const setSunriseHour = function (newSunriseValue) {
  latestSunriseValue = newSunriseValue;
};
var latestSunsetValue = {};
export const setSunsetHour = function (newSunsetValue) {
  latestSunsetValue = newSunsetValue;
};
var latestWeatherData = {};
export const getLatestWeatherData = function () {
  return latestWeatherData;
};

export const setLatestWeatherData = function (newWeatherData) {
  latestWeatherData = newWeatherData;
};
