import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useSwipeable } from "react-swipeable";
import uuid from "react-uuid";
import { getCurrentlyActiveSlideIndex } from "../../common/globals";
import BackgroundLayer from "../BackgroundLayer/BackgroundLayer";
import Slide from "../Slide/Slide";
import "./SlidesContainer.scss";

/** SlidesContainer component */
function SlidesContainer({
  children,
  onChangeSlideFn,
  onNavigateToInitialSlideFn,
  isSwipeEnabled,
  backgroundComponentJSX,
  customCssClassname,
  numberOfSlides,
  explicitTranslateX,
  initialSlideIndex,
  explicitSlideWidthInPx,
}) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [slidesContainerXPosition, setSlidesContainerXPosition] = useState(0);
  const [latestInitialSlideIndex, setLatestInitialSlideIndex] = useState(0);

  const swipeAbleConfig = useSwipeable({
    onSwipedLeft: (eventData) => {
      if (isSwipeEnabledOnCurrentSlideIndex(currentSlideIndex)) {
        moveToNextSlide();
      }
    },
    onSwipedRight: (eventData) => {
      if (isSwipeEnabledOnCurrentSlideIndex(currentSlideIndex)) {
        moveToPreviousSlide();
      }
    },
    trackMouse: false,
  });
  const swipeEventHandlers = isSwipeEnabled ? swipeAbleConfig : null;

  function isSwipeEnabledOnCurrentSlideIndex(slideIndex) {
    return true;
  }

  function calculateSlideContainerXPositionBasedOnSlideIndex(slideIndex) {
    return -(slideIndex * 100);
  }

  function updateCurrentSlideIndex(currentSlideIndex, isIncrement) {
    let newSlideIndex;
    if (isIncrement) {
      newSlideIndex =
        currentSlideIndex + 1 < children.length ? currentSlideIndex + 1 : 0;
    } else {
      newSlideIndex =
        currentSlideIndex - 1 >= 0
          ? currentSlideIndex - 1
          : children.length - 1;
    }
    return newSlideIndex;
  }

  const goToSlide = useCallback((slideIndex) => {
    setCurrentSlideIndex(slideIndex);
    setSlidesContainerXPosition(
      calculateSlideContainerXPositionBasedOnSlideIndex(slideIndex),
    );
    onChangeSlideFn(slideIndex);
  }, []);

  const moveToNextSlide = useCallback(() => {
    let newSlideIndex = updateCurrentSlideIndex(currentSlideIndex, true);
    goToSlide(newSlideIndex);
  }, []);

  const moveToPreviousSlide = useCallback(() => {
    let newSlideIndex = updateCurrentSlideIndex(currentSlideIndex, false);
    goToSlide(newSlideIndex);
  }, []);

  const childrenWithProps = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      moveToNextSlideFn: moveToNextSlide,
      goToSlideFn: goToSlide,
      onNavigateToInitialSlideFn,
    });
  });
  let componentStyles = {
    position: "absolute",
    width: `${children.length * explicitSlideWidthInPx}px`,
    transform: `translateX(${-explicitTranslateX}px)`,
  };

  if (initialSlideIndex !== currentSlideIndex && initialSlideIndex > 0) {
    goToSlide(initialSlideIndex);
    onNavigateToInitialSlideFn();
  }

  const activeSlideIndexBasedOnTranslateXValue = getCurrentlyActiveSlideIndex(); // explicitTranslateX ? Math.floor(explicitTranslateX / explicitSlideWidthInPx) : 0;

  return (
    <div
      className={"slidesContainer " + customCssClassname}
      style={{ ...componentStyles }}
    >
      {" "}
      {childrenWithProps.map((currentChild, childIndex) => {
        return (
          <Slide
            explicitSlideWidthInPx={explicitSlideWidthInPx}
            slideIndex={childIndex}
            additionalCssClassname={
              activeSlideIndexBasedOnTranslateXValue === childIndex
                ? "is-active " + currentChild.type.name
                : currentChild.type.name
            }
          >
            {/*            key={uuid()}
             */}
            {currentChild}
          </Slide>
        );
      })}
    </div>
  );
}
export default SlidesContainer;
