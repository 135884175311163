export const REQUEST_SUSTAINABILITY_PAGE_INFORMATION =
  "REQUEST_SUSTAINABILITY_PAGE_INFORMATION";
export const SUSTAINABILITY_PAGE_INFORMATION_ERROR =
  "SUSTAINABILITY_PAGE_INFORMATION_ERROR";
export const SUSTAINABILITY_PAGE_INFORMATION_SUCCESS =
  "SUSTAINABILITY_PAGE_INFORMATION_SUCCESS";
export const SAVE_CHANGES_TO_SUSTAINABILITY_PAGE =
  "SAVE_CHANGES_TO_SUSTAINABILITY_PAGE";
export const SAVE_CHANGES_TO_SUSTAINABILITY_PAGE_SUCCESS =
  "SAVE_CHANGES_TO_SUSTAINABILITY_PAGE_SUCCESS";
export const SAVE_CHANGES_TO_SUSTAINABILITY_PAGE_ERROR =
  "SAVE_CHANGES_TO_SUSTAINABILITY_PAGE_ERROR";
