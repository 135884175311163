import React from "react";
import {
  translate,
  translateDynamic,
  translateWithValues,
} from "../../localization/TranslationUtil";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import Graphic from "../Graphic/Graphic";
import IconButton from "../IconButton/IconButton";
import "./MijnMelkPopup.scss";
import { getMijnMelkPopupSvgFilePath } from "../../common/svgUtil"; 

/** MijnMelkPopup component */
function MijnMelkPopup({
  selectedMijnMelkDetails,
  callToActionClickFn,
  secondCallToActionClickFn,
  closePopupClickFn,
  farmName,
  farmId,
  isAdultSelected,
  batchProductType
}) {
  const mijnMelkFarmNamePopup = translateDynamic(farmName);
  const mijnMelkDescription = translateWithValues(
    "MIJN_MELK_DESCRIPTION_POPUP",
    { mijnMelkFarmNamePopup },
  ); 

  const mijnMelkPopupFarmId = getMijnMelkPopupSvgFilePath(farmId,batchProductType);

  let mijnMelkPopupSubmoduleCssClassname =
    "mijnMelkPopup" + "--" + "image" + "--" + mijnMelkPopupFarmId;

  return (
    <div className="mijnMelkPopup"> 
      <div className="mijnMelkPopup--container">
        <IconButton
          cssClassName="mijnMelkPopup--btnClosePopup"
          onClickFn={closePopupClickFn}
        />
      <div className="mijnMelkPopup--title">{translate("MIJN_MELK_TITLE")}</div>
      <div className="mijnMelkPopup--description">{mijnMelkDescription}</div>
      
      {/* 
      <CallToActionButton
        cssClassName=" mijnMelkPopup--btnCallToAction"
        label={translate("MILK_FACTORY_POPUP_SEE_HISTORY_BUTTON_LABEL")}
        onClickFn={() => {
          callToActionClickFn();
          closePopupClickFn();
        }}
      />
        */}
      <div className="mijnMelkPopupButtons">
      <CallToActionButton
        isAdultSelected={isAdultSelected}
        cssClassName="mijnMelkPopup--btnCallToAction"
        label={translate("MIJN_MELK_WEBSITE_BUTTON")}
        onClickFn={() =>  window.open(`https://mijnmelk.nl`)
        }
      />  
      <CallToActionButton
        isAdultSelected={isAdultSelected}
        cssClassName="mijnMelkPopup--secondBtnCallToAction"
        label={translate("MIJN_MELK_SECOND_BUTTON_LABEL")}
        onClickFn={() => {
          closePopupClickFn();
          secondCallToActionClickFn();
        }}
      />
      </div> 
      </div>
      <Graphic cssClassName={mijnMelkPopupSubmoduleCssClassname}></Graphic>
    </div>
  );
}
export default MijnMelkPopup;
