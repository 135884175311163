import { useState, useEffect } from "react";

export default function useViewportDimensions() {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh =
      window.innerWidth > window.innerHeight
        ? window.innerHeight * 0.01
        : window.innerWidth * 0.01;
    let vw =
      window.innerWidth > window.innerHeight
        ? window.innerWidth * 0.01
        : window.innerHeight * 0.01;

    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.documentElement.style.setProperty("--vw", `${vw}px`);

    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      function handleOrientationChange() {
        setWindowDimensions(getWindowDimensions());
      }

      function handlePortraitChange() {
        setWindowDimensions(getWindowDimensions());
      }

      function handleLandscapeChange() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      if (window && window.screen && window.screen.orientation) {
        // Property doesn't exist on screen in IE11
        window.screen.orientation.addEventListener(
          "change",
          handleOrientationChange,
        );
      }

      window
        .matchMedia("(orientation:portrait)")
        .addEventListener("change", handlePortraitChange);
      window
        .matchMedia("(orientation:landscape)")
        .addEventListener("change", handleLandscapeChange);
      return () => {
        window.removeEventListener("resize", handleResize);
        if (window && window.screen && window.screen.orientation) {
          window.screen.orientation.removeEventListener(
            "change",
            handleOrientationChange,
          );
        }

        window
          .matchMedia("(orientation:landscape)")
          .removeEventListener("change", handleLandscapeChange);
        window
          .matchMedia("(orientation:portrait)")
          .removeEventListener("change", handlePortraitChange);
      };
    }
  }, [hasWindow]);

  return windowDimensions;
}
