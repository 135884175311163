export const API_PREFIX =
  window.REACT_APP_API_URL ||
  window.location.protocol + "//" + window.location.hostname + "/api";
export const ACCESS_TOKEN_EXPIRATION_PERIOD_IN_MINUTES = 120;
export const USER_MAX_IDLE_TIME_PERIOD_IN_MINUTES = 119;
export const WAIT_ACCESS_TOKEN_RENEW_IN_MS_IMMEDIATELY_AFTER_REFRESH = 60000; // a minute
export const MINUTES_BEFORE_ACCESS_TOKEN_EXPIRATION_TO_REFRESH = 1; // exactly how many minutes before access token expiration period to attempt to refresh the access token
export const LOGIN_SERVICE_URL = API_PREFIX + "/Users/login";
export const REFRESH_ACCESS_TOKEN_SERVICE_URL = API_PREFIX + "/users/refresh";
export const LOGOUT_SERVICE_URL = API_PREFIX + "/Users/logout";
export const GET_FARMS_SERVICE_URL = API_PREFIX + "/farms";
export const GET_NAME_POLLS_SERVICE_URL = API_PREFIX + "/cows/upsertpollinfo";
export const FILTER_POLL_ID = "?pollId=";
export const FILTER_FARM_ID = "&farmId=";
export const FILTER_SELECTED_NAME = "&selectedName=";
export const GET_FARM_ENTITIES_SERVICE_URL = API_PREFIX + "/farms/";
export const VIRTUAL_FARM_SERVICE_URL = API_PREFIX + "/Farms";
export const COW_DAILY_DETAILS_SERVICE_URL = API_PREFIX + "/cows";
export const FILTER_COW_ID = "?cowId=";
export const FILTER_ASTRONAUT_FARM_ID = "/astronaut/";
export const ABOUT_THE_FARM_SERVICE_URL = API_PREFIX + "/Users/FarmInfo";
export const ANIMALS_SERVICE_URL = API_PREFIX + "/Users/Animals";
export const LANGUAGES_SERVICE_URL = API_PREFIX + "/Users/Languages";
export const FARM_FEATURES_SERVICE_URL = API_PREFIX + "/Users/FarmFeatures";
export const ABOUT_THE_FARMER_SERVICE_URL = API_PREFIX + "/Users/AboutMe";
export const TELL_ME_MORE_SERVICE_URL = API_PREFIX + "/Users/Stories";
export const COWS_PAGE_SERVICE_URL = API_PREFIX + "/Users/CowCharacteristics";
export const LIVE_DATA_SIGNALR_URL = API_PREFIX + "/basehub";
export const GET_ASTRONAUT_DETAILS_SERVICE_URL = API_PREFIX + "/cows/";
export const LIVE_DATA_CHANNEL_NAME = "notifications";
export const SUSTAINABILITY_SERVICE_URL = API_PREFIX + "/Users/Sustainability";
export const USER_MANAGEMENT_REGISTER_USER_SERVICE_URL =
  API_PREFIX + "/Users/Register";
export const USER_MANAGEMENT_SEND_VERIFICATION_EMAIL_SERVICE_URL =
  API_PREFIX + "/TBD";
export const USER_MANAGEMENT_ACTIVATE_ACCOUNT_SERVICE_URL =
  API_PREFIX + "/Users/ConfirmEmail";
export const USER_MANAGEMENT_RECOVER_ACCOUNT_SERVICE_URL =
  API_PREFIX + "/Users/ForgottenPassword";
export const USER_MANAGEMENT_SET_NEW_ACCOUNT_PASSWORD_SERVICE_URL =
  API_PREFIX + "/Users/ResetPassword";
export const USER_MANAGEMENT_SEED_FARM_DATA_SERVICE_URL =
  API_PREFIX + "/dataseeder/UpsertNewFarmData";
export const USER_MANAGEMENT_SEED_FARM_DATA_HEADER = "";
