import React from "react";
import {
  translate,
  translateDynamic,
} from "../../localization/TranslationUtil";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import Graphic from "../Graphic/Graphic";
import IconButton from "../IconButton/IconButton";
import LabelValue from "../LabelValue/LabelValue";
import "./FarmerPopup.scss";
import { getDisableCacheAppendix } from "../../common/urlUtil";
import { useSelector } from "react-redux";
import _ from "lodash";

/** FarmerPopup component */
function FarmerPopup({
  selectedFarmerDetails,
  callToActionClickFn,
  closePopupClickFn,
  isAdultSelected,
}) {
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const farmerPopupTitle =
    translate("FARMER_POPUP_TITLE_PREFIX") +
    translateDynamic(selectedFarmerDetails.name).toUpperCase();
  const callToActionButtonLabel = translate(
    "MILK_FACTORY_POPUP_SEE_HISTORY_BUTTON_LABEL",
  );

  let isFarmerStoryEnabled = function (selectedFarmerDetails) {
    if (_.isEmpty(selectedFarmerDetails.storySlides)) {
      return false;
    }
    let isEnabled = true;
    Object.keys(selectedFarmerDetails.storySlides).forEach((currentKey) => {
      if (
        selectedFarmerDetails.storySlides[currentKey] &&
        selectedFarmerDetails.storySlides[currentKey].length === 0
      ) {
        isEnabled = false;
      }
    });
    return isEnabled;
  };
  const farmerDefaultImage = 'farmerPopup--farmer'  + '--' + (farmDetails?.farmer?.style || "").toLowerCase();
  const farmerPopupImage = (selectedFarmerDetails?.imageData?.all); 
  const cssClassAppendix = typeof isAdultSelected === "undefined" || isAdultSelected === true ?  " is-adult-selected" : "";
  let farmerPopupCssClassName =
  "farmerPopup" + 
  cssClassAppendix;
  
  return (
    <div className={farmerPopupCssClassName}> 
      <div className="farmerPopup--container">
        <IconButton
         cssClassName="farmerPopup--btnClosePopup"
         onClickFn={closePopupClickFn}
        />
        <div className="farmerPopup--title">{farmerPopupTitle}</div>
        <LabelValue
        label={translate("FARMER_FARM_NAME")}
        value={selectedFarmerDetails.farmName}
      />
      <LabelValue
        label={translate("FARMER_FAVOURITE_COW")}
        value={translateDynamic(selectedFarmerDetails.favoriteCowName)}
      />
      <div className="farmerPopup--farmerQuote">
        {translateDynamic(selectedFarmerDetails.personalQuote)}
      </div>
      {isFarmerStoryEnabled(selectedFarmerDetails) && farmDetails.isAgeSelected != undefined ? (
          <CallToActionButton
           isAdultSelected = {isAdultSelected}
           cssClassName="farmerPopup--btnCallToAction"
           label={callToActionButtonLabel}
           onClickFn={() => {
            callToActionClickFn();
            closePopupClickFn();
          }}
        />
       ) : null}
    </div> 
    <div className="farmerPopup--farmerContainer"> 
     {farmerPopupImage ? (<img
          src={getDisableCacheAppendix(farmerPopupImage)}
          className="farmerPopup--farmer"
        />) : <div className={farmerDefaultImage}/> 
      }
        <div className="farmerPopup--frame"></div>
      </div>
    </div>
  );
}
export default FarmerPopup;
