import React from "react";
import "./ThirdSlideVector.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const ThirdSlideVector = ({}) => {
  return (
    <div className="vectorStoryThirdSlide">
      <TextCaption additionalCssClassname="vectorStoryThirdSlide--textCaption">
        <div className="vectorStoryThirdSlide--title">
          {translate("VECTOR_TITLE_THIRD_SLIDE")}
        </div>
        <div className="vectorStoryThirdSlide--text">
          {translate("VECTOR_THIRD_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="vectorStoryThirdSlide--imgCaption">
        <div className="vectorStoryThirdSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default ThirdSlideVector;
