import React, { useEffect } from "react";
import { translate } from "../../localization/TranslationUtil";
import LabelledTextInput from "../../common/components/LabelledTextInput/LabelledTextInput";
import LabelledTextArea from "../../common/components/LabelledTextArea/LabelledTextArea";
import LabelledImageDropZone from "../../common/components/LabelledImageDropZone/LabelledImageDropZone";
import AnimalTypeSelection from "../../common/components/AnimalTypeSelection/AnimalTypeSelection";
import "./AnimalBlock.scss";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import {
  ANIMAL_TYPE_CAT,
  ANIMAL_TYPE_HORSE,
  ANIMAL_TYPE_LARGE_DOG,
} from "../../common/constants";
import { isCurrentAnimalDog } from "./Animal";

/** AnimalBlock component.
 * @param {Object} obj - Object which contains all props.
 * @param {Object} [obj.animalData] - Object containing all the information about the pet/animal such as name, photoUrl, etc.
 * @param {Array} [obj.languageKeysArray]
 * @param {Function} [obj.handleAnimalBlockChangeFn] - Function that will be called whenever any of the animal information properties has been changed by any of the underlying components
 * @param {Function} [obj.deleteAnimalClickFn] - Function that will be called whenever the delete button for the animal block is clicked
 * @param {Boolean} [obj.isAddingCatPossible]
 * @param {Boolean} [obj.isAddingDogPossible]
 * @param {Boolean} [obj.isAddingHorsePossible]
 */
function AnimalBlock({
  animalData,
  languageKeysArray,
  handleAnimalBlockChangeFn,
  deleteAnimalClickFn,
  isAddingCatPossible,
  isAddingDogPossible,
  isAddingHorsePossible,
}) {
  function getAnimalStoryJSXByAnimalData(animalData) {
    let animalStoryJSXElements = [];
    languageKeysArray.forEach((currentLanguageKey) => {
      animalStoryJSXElements.push(
        getStoryComponentJSXByData(
          animalData.story[currentLanguageKey] || "",
          currentLanguageKey,
        ),
      );
    });
    return animalStoryJSXElements;
  }

  function getStoryComponentJSXByData(
    storyTextForSpecificLanguage,
    currentLanguageKey,
  ) {
    return (
      <LabelledTextArea
        labelText={translate("LANGUAGE_" + currentLanguageKey.toUpperCase())}
        initialValue={storyTextForSpecificLanguage}
        handleChangeFn={(newStoryTextForSpecificLanguage) => {
          let copyOfAnimalBlockData = _.cloneDeep(animalData);
          copyOfAnimalBlockData.story[currentLanguageKey] =
            newStoryTextForSpecificLanguage;
          handleAnimalBlockChangeFn(copyOfAnimalBlockData);
        }}
        placeholderText={translate("ANIMALS_PAGE_TEXT_AREA_PLACEHOLDER_TEXT")}
        additionalClassName="more-about-you--textArea"
      />
    );
  }

  function handleDeleteAnimalButtonClicked() {
    deleteAnimalClickFn(animalData.textBlockIndex);
  }

  return (
    <div className="AnimalBlock">
      <h3 className="AnimalBlock--title">{`Animal ${animalData.textBlockIndex}`}</h3>
      <AnimalTypeSelection
        onAnimalTypeChangeFn={(newAnimalType) => {
          handleAnimalBlockChangeFn({
            ...animalData,
            animalType: newAnimalType,
          });
        }}
        areCatsEnabled={
          animalData.animalType === ANIMAL_TYPE_CAT || isAddingCatPossible
        }
        areDogsEnabled={isCurrentAnimalDog(animalData) || isAddingDogPossible}
        areHorsesEnabled={
          animalData.animalType === ANIMAL_TYPE_HORSE || isAddingHorsePossible
        }
        initialAnimalType={animalData.animalType}
      />
      <LabelledTextInput
        labelText={translate("ANIMALS_PAGE_ANIMAL_NAME_TEXT")}
        initialValue={animalData && animalData.name}
        handleChangeFn={(newTitle) => {
          handleAnimalBlockChangeFn({
            ...animalData,
            name: newTitle,
          });
        }}
        additionalClassName="more-about-you--textField"
      />
      <div className="story-input--label">
        {translate("ANIMALS_PAGE_ANIMAL_STORY_TEXT")}
      </div>
      <Grid container>{getAnimalStoryJSXByAnimalData(animalData)}</Grid>
      <LabelledImageDropZone
        labelText={translate("ANIMALS_PAGE_ANIMAL_PHOTO_TEXT")}
        dropzoneText={translate("ANIMALS_PAGE_PICTURE_DROP_ZONE_LABEL")}
        initialValue={animalData && animalData.imageData}
        key={animalData && animalData.imageData}
        onChangeFileFn={(newFileBase64) => {
          handleAnimalBlockChangeFn({
            ...animalData,
            imageData: newFileBase64,
          });
        }}
        onDeleteFileFn={() => {
          handleAnimalBlockChangeFn({
            ...animalData,
            imageData: "",
          });
        }}
        defaultPreviewCssClassname={`${animalData.animalType}ImagePreview`}
      />
      <button
        style={{ marginTop: "1vw", marginBottom: "1vw" }}
        className="btn-delete-animal-block"
        type="button"
        disabled={false}
        onClick={function () {
          handleDeleteAnimalButtonClicked();
        }}
      >
        <i className="lely-icon-trash"></i>
        <span className="btn-delete-animal-block--label">{`Delete animal ${animalData.textBlockIndex}`}</span>
      </button>
    </div>
  );
}

export default AnimalBlock;
