import React, { useEffect, useRef, useState } from "react";
import "./Confetti.scss";
import ConfettiExplosion from "@reonomy/react-confetti-explosion";
import { useSelector } from "react-redux";
import {
  CHARACTER_DISCOVERY,
  printCustomMessage,
} from "../../../common/consoleUtil";

/** Confetti component */
const Confetti = React.memo(
  ({ viewportWidthInPx, viewportHeightInPx, confettiTrigger }) => {
    printCustomMessage(
      "Confetti render",
      CHARACTER_DISCOVERY,
      "",
      "black",
      "lime",
    );
    const confettiContainerRef = useRef(null);
    const CONFETTI_ANIMATION_DURATION_IN_MS = 3000;
    const CONFETTI_COLORS = ["#F0F0E6", "#FF684D"];

    function fireConfetti() {
      confettiContainerRef.current.className = "confetti--container";
      setTimeout(() => { 
        if (confettiContainerRef && confettiContainerRef.current){
          confettiContainerRef.current.className =
          "confetti--container confetti--container--are-confetti-stopped";
        }
      }, CONFETTI_ANIMATION_DURATION_IN_MS + 1000);
    }

    useEffect(() => {
      fireConfetti();
    }, []);

    return (
      <div
        ref={confettiContainerRef}
        style={{
          width: `${viewportWidthInPx * 2}px`,
          height: `${viewportHeightInPx}px`,
          position: "relative",
          left: `${viewportWidthInPx}px`,
        }}
      >
        <>
          <ConfettiExplosion
            floorWidth={viewportWidthInPx * 2}
            floorHeight={viewportHeightInPx}
            duration={CONFETTI_ANIMATION_DURATION_IN_MS}
            particleCount={100}
            force={0.6}
            colors={CONFETTI_COLORS}
          />
          <ConfettiExplosion
            floorWidth={viewportWidthInPx * 2}
            floorHeight={viewportHeightInPx + 20}
            duration={CONFETTI_ANIMATION_DURATION_IN_MS}
            particleCount={20}
            force={0.5}
            colors={CONFETTI_COLORS}
          />
          <ConfettiExplosion
            floorWidth={viewportWidthInPx * 2}
            floorHeight={viewportHeightInPx + 60}
            duration={CONFETTI_ANIMATION_DURATION_IN_MS}
            particleCount={50}
            force={0.4}
            colors={CONFETTI_COLORS}
          />
        </>
      </div>
    );
  },
);

export default Confetti;
