import React from "react";
import "./LastSlideMijnMelk.scss";
import { translate } from "../../localization/TranslationUtil";
import { useLocation } from "react-router";
import PoleComponent from "../../common/components/PoleComponentEndingSlides/PoleComponent";

const LastSlideMijnMelk = ({ onNavigateToInitialSlideFn, name }) => {
  const goToLelySite = translate("MIJN_MELK_REDIRECTION_TO_SITE");

  const search = useLocation().search;
  const farmId = new URLSearchParams(search).get("f");
  let mijnMelkPopupFarmId = "";
  switch (farmId) {
    case "A4D1442E459E":
      mijnMelkPopupFarmId = "dedobbelhoeve";
      break;
    case "8D8F970C4BB1":
      mijnMelkPopupFarmId = "oostdam";
      break;
    case "9FCA2FEE4AB3":
      mijnMelkPopupFarmId = "boerreijer";
      break;
    case "9643297842C4":
      mijnMelkPopupFarmId = "boerkees";
      break;
    default:
      mijnMelkPopupFarmId = "dedobbelhoeve";
      break;
  }
  const farmerSite = "https://mijnmelk.nl/" + `${mijnMelkPopupFarmId}`;
  return (
    <div className="lastSlideMijnMelk">
      <div className="lastSlideMijnMelk--redirectionLely">
        <div
          className="lastSlideMijnMelk--secondText"
          onClick={() =>
            window.open(`https://mijnmelk.nl/${mijnMelkPopupFarmId}`)
          }
        >
          {goToLelySite}
        </div>
      </div>
      <PoleComponent onNavigateToInitialSlideFn={onNavigateToInitialSlideFn} />
    </div>
  );
};

export default LastSlideMijnMelk;
