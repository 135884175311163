import React from "react";
import "./StartingSlideJuno.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import StoryNavigationHint from "../../common/components/StoryNavigationHint/StoryNavigationHint";

const StartingSlideJuno = ({}) => {
  return (
    <div className="junoStoryStartingPoint">
      <TextCaption additionalCssClassname="junoStoryStartingPoint--textCaption">
        <div className="junoStoryStartingPoint--title">
          {translate("JUNO_STORY_TITLE")}
        </div>
        <div className="junoStoryStartingPoint--name">
          {translate("JUNO_STORY_HEADER")}
        </div>
      </TextCaption>
      <div className="junoStoryStartingPoint--robot"></div>
      <StoryNavigationHint />
    </div>
  );
};

export default StartingSlideJuno;
