import {
  MOCK_VALID_USERNAME,
  MOCK_VALID_PASSWORD,
  MOCK_LOGIN_SUCCESS_RESPONSE,
  MOCK_LOGIN_ERROR,
} from './config';

const mockAreUserCredentialsValid = function (username, password) {
  return username === MOCK_VALID_USERNAME && password === MOCK_VALID_PASSWORD;
};

export const api = {
  login: async function (username, password) {
    return new Promise((resolve) => {
      if (mockAreUserCredentialsValid(username, password)) {
        resolve({ data: MOCK_LOGIN_SUCCESS_RESPONSE });
      } else {
        throw MOCK_LOGIN_ERROR;
      }
    });
  },
};
