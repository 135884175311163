import _ from "lodash";
import React, { useEffect } from "react";
import "./FinalOnboardingSlideKool.scss";
import TextCaption from "../../../../components/TextCaption/TextCaption";
import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../../../localization/TranslationUtil";
import {
  IS_CLICKABLE_CSS_CLASSNAME,
  SIDE_MENU_OPTION_AGE_SELECTION,
} from "../../../../common/constants";
import { useSelector } from "react-redux";

const FinalOnboardingSlideKool = React.memo(
  ({ onNavigateToInitialSlideFn, farmName }) => {
    const goToFarmText = translate("COW_OUTRO_GO_TO_FARM_BUTTON_CAPTION");
    const exploreTheFarm = translateWithValues("EXPLORE_THE_FARM", {farmName : farmName});
    const revertButton = translate("COW_OUTRO_SEE_COW_STORY_BUTTON_CAPTION");
    const chooseDayButton = translate("COW_OUTRO_SELECT_DAY_BUTTON_CAPTION");
    const chooseDayText = translate(
      "MIJNMELK_ONBOARDING_FINAL_SLIDE_MORE_INFO",
    );
    return (
      <div className="FinalOnboardingSlideKool">
        <div className="FinalOnboardingSlideKool--background"></div>
        <TextCaption additionalCssClassname="textCaption--FinalOnboardingSlideKool">
          <p
            className="FinalOnboardingSlideKool--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml("CHEESE_ONBOARDING_END_TEXT"),
            }}
          ></p>
        </TextCaption>
        <div
          className={
            "FinalOnboardingSlideKool--backButton " + IS_CLICKABLE_CSS_CLASSNAME
          }
          onClick={() =>
            onNavigateToInitialSlideFn(SIDE_MENU_OPTION_AGE_SELECTION)
          }
        >
          {goToFarmText}
        </div>
        <div className="FinalOnboardingSlideKool--revertButton">
          {revertButton}
        </div>
        <div className="FinalOnboardingSlideKool--backButton--text">
          {exploreTheFarm}
        </div>
        <div className="FinalOnboardingSlideKool--chooseDayButton">
          {chooseDayButton}
        </div>
        <div className="FinalOnboardingSlideKool--chooseDayButton--text">
          {chooseDayText}
        </div>
        <div className="arrowFinalSlideKool"></div>
        <div className="FinalOnboardingSlideKool--chooseDayButton--arrow"></div>
        <div className="FinalOnboardingSlideKool--cheese"></div>
        <div className="FinalOnboardingSlideKool--cuttedCheese"></div>
        <div className="FinalOnboardingSlideKool--farmer"></div>
      </div>
    );
  },
);

export default FinalOnboardingSlideKool;
