import {
  VOTE_FOR_COW_REQUEST,
  VOTE_FOR_COW_REQUEST_SUCCESS,
  VOTE_FOR_COW_REQUEST_ERROR,
  RESET_LAST_VOTE_RESULT,
} from './types';

/** Create action which votes for specific cow name
 * @param {string} pollId - Poll id
 * @param {string} cowExternalId - The external id of the cow for whose name we are voting 
 * @param {string} cowName - The name of the cow for which we are voting
 * @return {Object} Return action object
 */
export function voteForCowName(pollId, cowExternalId, cowName) {
  return {
    type: VOTE_FOR_COW_REQUEST,
    payload: {
      pollId: pollId,
      cowExternalId: cowExternalId,
      cowName: cowName,
    },
  };
}

/** Create action which to be fired whenever the cow name has been persisted successfully
 * @param {String} cowExternalId - The external ID of the cow whose name we just voted for 
 * @param {Object} newPollData - The poll data after the cow vote has been made successfully (voting for a cow name might produce an end date for a vote)
 * @return {Object} Returns action object for successfully voting for cow name
 */
export function voteForCowNameSuccess({
  cowExternalId,
  newPollData
}) {
  return {
    type: VOTE_FOR_COW_REQUEST_SUCCESS,
    payload: {
      cowExternalId: cowExternalId,
      newPollData: newPollData
    },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function voteForCowNameError(errorMessage) {
  return {
    type: VOTE_FOR_COW_REQUEST_ERROR,
    payload: errorMessage,
  };
}

/** Create action that will reset the last stored value in lastVoteResult
 * @return {Object} Return type
 */
export function resetLastVoteResult() {
  return {
    type: RESET_LAST_VOTE_RESULT,
    payload: {},
  };
}