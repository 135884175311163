import { GET_SERVER_RESPONSE_STATUS } from './types';

/** Get server response status.
 * @param {string} status - Server response status
 */

export function getServerResponseStatus(status) {
  return {
    type: GET_SERVER_RESPONSE_STATUS,
    payload: {
      status: status,
    },
  };
}
