import axios from "axios";
import {
  USER_MANAGEMENT_ACTIVATE_ACCOUNT_SERVICE_URL,
  USER_MANAGEMENT_RECOVER_ACCOUNT_SERVICE_URL,
  USER_MANAGEMENT_REGISTER_USER_SERVICE_URL,
  USER_MANAGEMENT_SEED_FARM_DATA_HEADER,
  USER_MANAGEMENT_SEED_FARM_DATA_SERVICE_URL,
  USER_MANAGEMENT_SEND_VERIFICATION_EMAIL_SERVICE_URL,
  USER_MANAGEMENT_SET_NEW_ACCOUNT_PASSWORD_SERVICE_URL,
} from "./config";

export const api = {
  register: async function (licenseKey, password, emailAddress) {
    // return new Promise((resolve) => {
    //   resolve({});
    // });
    return axios.post(USER_MANAGEMENT_REGISTER_USER_SERVICE_URL, {
      licensekey: licenseKey,
      password: password,
      email: emailAddress,
    });
  },
  resendVerificationEmail: async function (emailAddress) {
    return axios.post(USER_MANAGEMENT_SEND_VERIFICATION_EMAIL_SERVICE_URL, {
      emailAddress,
    });
  },
  activateAccount: async function (accountToken, customerId, userId) {
    let activateAccountServiceUrl = `${USER_MANAGEMENT_ACTIVATE_ACCOUNT_SERVICE_URL}`;
    return axios.post(activateAccountServiceUrl, {
      uId: userId,
      cId: customerId,
      tkn: accountToken,
    });
  },
  seedFarmData: async function (licenseKey) {
    let seedFarmDataServiceUrl = `${USER_MANAGEMENT_SEED_FARM_DATA_SERVICE_URL}?licenseKey=${licenseKey}`;
    return axios.post(
      seedFarmDataServiceUrl,
      {},
      {
        headers: {
          Authorization: USER_MANAGEMENT_SEED_FARM_DATA_HEADER,
        },
      },
    );
  },
  recover: async function (emailAddress) {
    return axios.post(
      USER_MANAGEMENT_RECOVER_ACCOUNT_SERVICE_URL + "?email=" + emailAddress,
      {
        params: {
          email: emailAddress,
        },
      },
    );
  },
  setNewPassword: async function (email, token, newPassword) {
    let setNewPasswordServiceUrl = `${USER_MANAGEMENT_SET_NEW_ACCOUNT_PASSWORD_SERVICE_URL}`;
    return axios.post(
      setNewPasswordServiceUrl,
      {
        email: email,
        newpassword: newPassword,
      },
      {
        params: {
          token: token,
        },
      },
    );
  },
};
