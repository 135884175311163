import React from "react";
import "./StartingSlideAstronaut.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption"; 
import StoryNavigationHint from "../../common/components/StoryNavigationHint/StoryNavigationHint";

const StartingSlideAstronaut = ({}) => {
  return (
    <div className="astronautStoryStartingPoint">
      <TextCaption additionalCssClassname="astronautStoryStartingPoint--textCaption">
        <div className="astronautStoryStartingPoint--title">
          {translate("ASTRONAUT_ROBOT_TITLE")}
        </div>
        <div className="astronautStoryStartingPoint--name">
          {translate("ASTRONAUT_ROBOT_HEADER")}
        </div>
      </TextCaption>
      <div className="astronautStoryStartingPoint--robot"></div> 
      <StoryNavigationHint/>
    </div>
  );
};

export default StartingSlideAstronaut;
