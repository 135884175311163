import _ from "lodash";
import React, { useEffect } from "react";
import "./CowFirstBirthdaySlide.scss";
import TextCaption from "../../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";
import {
  translate,
  translateWithValues,
} from "../../../localization/TranslationUtil";
import moment from "moment-timezone";
import Confetti from "../../../common/components/Confetti/Confetti";
import useWindowDimensions from "../../../hooks/useViewportDimensions";
import {
  CHARACTER_DISCOVERY,
  printCustomMessage,
} from "../../../common/consoleUtil";

const CowFirstBirthdaySlide = React.memo(() => {
  printCustomMessage(
    "CowFirstBirthdaySlide render",
    CHARACTER_DISCOVERY,
    "",
    "black",
    "lime",
  );
  const { width: VIEWPORT_WIDTH_IN_PX, height: VIEWPORT_HEIGHT_IN_PX } =
    useWindowDimensions();
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );

  const { confettiTrigger } = useSelector((state) => state.confettiDetails);

  const firstBirthdayDate = new Date(selectedCowDetails.birthTimestamp);
  firstBirthdayDate.setFullYear(firstBirthdayDate.getFullYear() + 1);

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div className="cow--firstBirthdaySlide">
        <div className="cow-firstBirthdaySlide--calendar">
          <span className="cow-firstBirthdaySlide--calendarMonth">
            {moment(firstBirthdayDate).format("MMMM")}
          </span>
          <span className="cow-firstBirthdaySlide--calendarDay">
            {moment(firstBirthdayDate).format("DD")}
          </span>
        </div>
        <TextCaption additionalCssClassname="textCaption--cow--firstBirthdaySlide">
          <span className="cow--firstBirthdaySlide--text">
            {translateWithValues("COW_FIRST_BIRTHDAY_INFO", {
              cowName : selectedCowDetails.name
            })}
          </span>
        </TextCaption>
        <TextCaption additionalCssClassname="textCaption--firstBirthdaySlide--year">
          {moment(firstBirthdayDate).format("YYYY")}
        </TextCaption>
        <span className="cow--firstBirthdaySlide--box"></span>
        <span className="cow--firstBirthdaySlide--bigPartyFlag"></span>
        <span className="cow--firstBirthdaySlide--arrow"></span>
        <span className="cow--firstBirthdaySlide--smallPartyFlag"></span>
        <Confetti
          viewportWidthInPx={VIEWPORT_WIDTH_IN_PX}
          viewportHeightInPx={VIEWPORT_HEIGHT_IN_PX * 0.8}
          confettiTrigger={confettiTrigger}
        />
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
});

export default CowFirstBirthdaySlide;
