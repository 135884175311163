import React from "react";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import IconButton from "../IconButton/IconButton";
import { translate } from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import "./DiscoveryPopup.scss";

function DiscoveryPopup({
  callToActionClickFn,
  closePopupClickFn,
  seeHistoryClickFn,
  seeLatestClickFn,
  selectedDiscoveryFactoryDetails,
  isAdultSelected,
}) {
  const discoveryCssClassNameImage =
    "discoveryPopup--" + "" + "" + selectedDiscoveryFactoryDetails.type;

  return (
    <div className="discoveryPopup"> 
      <div className="discoveryPopup--container">
        <IconButton
         cssClassName="discoveryPopup--btnClosePopup"
         onClickFn={closePopupClickFn}
        />
      <div className="discoveryPopup--title">
        {selectedDiscoveryFactoryDetails &&
        selectedDiscoveryFactoryDetails.type == "discovery"
          ? translate("COW_COLLECTOR_TITLE")
          : translate("DISCOVERYSW_POPUP_TITLE")}
      </div>
      <div className="discoveryPopup--description">
        {selectedDiscoveryFactoryDetails &&
        selectedDiscoveryFactoryDetails.type == "discovery"
          ? translate("COW_DISCOVERY_DESCRIPTION_TEXT")
          : translate("DISCOVERYSW_POPUP_TEXT")}
      </div>
      <CallToActionButton
        isAdultSelected={isAdultSelected}
        cssClassName="discoveryPopup--btnCallToAction"
        label={translate("DISCOVERY_POPUP_SEE_HISTORY_BUTTON_LABEL")}
        onClickFn={() => {
          callToActionClickFn();
          closePopupClickFn();
        }}
      />
      </div>
      
      <Graphic cssClassName={discoveryCssClassNameImage} />
    </div>
  );
}

export default DiscoveryPopup;
