import {
  REQUEST_ANIMALS_PAGE_INFORMATION_SUCCESS,
  REQUEST_ANIMALS_PAGE_INFORMATION_ERROR,
  SAVE_CHANGES_TO_ANIMALS_PAGE_SUCCESS,
  SAVE_CHANGES_TO_ANIMALS_PAGE_ERROR,
} from '../containers/Admin/AnimalsPage/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  animalsDetails: {},
  errorMessage: '',
  isLatestSaveSuccessful: false,
  saveChangesErrorMessage: '',
};

/** Animals page reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function animalsPageReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ANIMALS_PAGE_INFORMATION_SUCCESS:
      return {
        ...state,
        animalsDetails: action.payload,
        errorMessage: '',
      };
    case REQUEST_ANIMALS_PAGE_INFORMATION_ERROR:
      return {
        ...state,
        animalsDetails: {},
        errorMessage: action.payload,
      };
    case SAVE_CHANGES_TO_ANIMALS_PAGE_SUCCESS:
      return {
        ...state,
        isLatestSaveSuccessful: true,
        saveChangesErrorMessage: '',
      };
    case SAVE_CHANGES_TO_ANIMALS_PAGE_ERROR:
      return {
        ...state,
        isLatestSaveSuccessful: false,
        saveChangesErrorMessage: action.payload,
      };

    default:
      return state;
  }
}

export default animalsPageReducer;
