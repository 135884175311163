var lastActivityTimestamp = new Date();
export const logUserActivity = () => {
    lastActivityTimestamp = new Date();
};

export const isUserActiveInLastNMinutes = (minutes) => {
    var currentTime = new Date().getTime();
    var differenceInMs = currentTime - lastActivityTimestamp;
    var differenceInSeconds = differenceInMs / 1000;
    var differenceInMinutes = Math.round(differenceInSeconds / 60);
    return (differenceInMinutes < minutes);
};