import React, { useEffect } from "react";
import {
  getRandomNumberBetweenMinAndMax,
  measureResponsive,
} from "../../components/VirtualFarm/util";

/** DiscoveryRouteSettings */
export const DiscoveryRouteSettings = function () {
  const DISCOVERY_START_X = 48.8147;
  const DISCOVERY_START_Y = 32.65;
  const DISCOVERY_MOVEMENT_SIDE_1 = 12;
  const DISCOVERY_MOVEMENT_SIDE_2 = 5;
  const DISCOVERY_MOVEMENT_PERSPECTIVE_Y = 4;
  const DISCOVERY_MOVEMENT_HEIGHT = 7;
  const DISCOVERY_WIDTH = 4.6182;
  const DISCOVERY_HEIGHT = 3.69458;
  const ACTOR_WIDTH = 4;

  let DISCOVERY_ROBOT_ROUTE = {
    SETTINGS: {
      DISCOVERY_START_X: DISCOVERY_START_X,
      DISCOVERY_START_Y: DISCOVERY_START_Y,
      DISCOVERY_MOVEMENT_SIDE_1: DISCOVERY_MOVEMENT_SIDE_1,
      DISCOVERY_MOVEMENT_SIDE_2: DISCOVERY_MOVEMENT_SIDE_2,
      DISCOVERY_MOVEMENT_PERSPECTIVE_Y: DISCOVERY_MOVEMENT_PERSPECTIVE_Y,
      DISCOVERY_MOVEMENT_HEIGHT: DISCOVERY_MOVEMENT_HEIGHT,
      DISCOVERY_WIDTH: DISCOVERY_WIDTH,
      DISCOVERY_HEIGHT: DISCOVERY_HEIGHT,
      ACTOR_WIDTH: ACTOR_WIDTH,
    },
    width: 100,
    STAGES: [
      {
        stageId: "moveAlongLeftEdge",
        startPosition: {
          x: DISCOVERY_START_X,
          y: DISCOVERY_START_Y,
        },
        endPosition: {
          x: DISCOVERY_START_X + DISCOVERY_MOVEMENT_SIDE_1,
          y: DISCOVERY_START_Y + DISCOVERY_MOVEMENT_HEIGHT,
        },
        isCompleted: false,
        waitAfterCompletionInMs: 0,
      },
      {
        stageId: "moveAlongBottomEdge",
        startPosition: {
          x: DISCOVERY_START_X + DISCOVERY_MOVEMENT_SIDE_1,
          y: DISCOVERY_START_Y + DISCOVERY_MOVEMENT_HEIGHT,
        },
        endPosition: {
          x:
            DISCOVERY_START_X +
            DISCOVERY_MOVEMENT_SIDE_1 +
            DISCOVERY_MOVEMENT_SIDE_2,
          y:
            DISCOVERY_START_Y +
            (DISCOVERY_MOVEMENT_HEIGHT - DISCOVERY_MOVEMENT_PERSPECTIVE_Y),
        },
        isCompleted: false,
        waitAfterCompletionInMs: 0,
      },
      {
        stageId: "moveAlongRightEdge",
        startPosition: {
          x:
            DISCOVERY_START_X +
            DISCOVERY_MOVEMENT_SIDE_1 +
            DISCOVERY_MOVEMENT_SIDE_2,
          y:
            DISCOVERY_START_Y +
            (DISCOVERY_MOVEMENT_HEIGHT - DISCOVERY_MOVEMENT_PERSPECTIVE_Y),
        },
        endPosition: {
          x: DISCOVERY_START_X + DISCOVERY_MOVEMENT_SIDE_2,
          y: DISCOVERY_START_Y - DISCOVERY_MOVEMENT_PERSPECTIVE_Y,
        },
        isCompleted: false,
        waitAfterCompletionInMs: 0,
      },
      {
        stageId: "moveAlongTopEdge",
        startPosition: {
          x: DISCOVERY_START_X + DISCOVERY_MOVEMENT_SIDE_2,
          y: DISCOVERY_START_Y - DISCOVERY_MOVEMENT_PERSPECTIVE_Y,
        },
        endPosition: {
          x: DISCOVERY_START_X,
          y: DISCOVERY_START_Y,
        },
        isCompleted: false,
        waitAfterCompletionInMs: 0,
      },
    ],
  };
  return DISCOVERY_ROBOT_ROUTE;
};
