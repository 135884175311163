import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
import { getAstronautFactorySvgFilePath } from "../../common/svgUtil";
import Graphic from "../Graphic/Graphic";
import React, { useCallback, useEffect } from "react";
import "./AstronautFactory.scss";
import { measureResponsive } from "../../components/VirtualFarm/util";
import {
  ADULT_THEME_CODE,
  IS_CLICKABLE_CSS_CLASSNAME,
} from "../../common/constants";
import { printConsoleHeading } from "../../common/consoleUtil";

/** astronautFactory component */
function AstronautFactory({
  astronautFactoryData,
  astronautFactoryCssClassname,
  astronautFactoryXCoordinate,
  astronautFactoryYCoordinate,
  astronautFactoryScaleX,
  astronautFactoryClickHandlerFn,
  additionalCssClassnames,
  farmTheme,
  doPositionFixed,
  isInteractable,
  isClickable,
  isAdultSelected,
}) {
  let transformValue =
    "translateX(" +
    astronautFactoryXCoordinate +
    ") translateY(" +
    astronautFactoryYCoordinate +
    ") scaleX(" +
    astronautFactoryScaleX +
    ")";
  let astronautFactoryPositionStyles = doPositionFixed
    ? {}
    : {
        transform: transformValue,
      };
  let astronautFactorySubmoduleCssClassname =
    astronautFactoryCssClassname + " " + additionalCssClassnames;
  astronautFactorySubmoduleCssClassname += isInteractable
    ? " virtualFarm--interactable virtualFarm--highlighted"
    : "";
  astronautFactorySubmoduleCssClassname = isClickable
    ? astronautFactorySubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : astronautFactorySubmoduleCssClassname;
  farmTheme = isAdultSelected ? ADULT_THEME_CODE : farmTheme;
  let astronautFactorySvgPath = getAstronautFactorySvgFilePath(farmTheme);
  const onCompletedCallback = useCallback((e) => {}, []);
  const onErrorCallback = useCallback((e) => {
    console.log("@onError e:", e);
  }, []);

  return (
    <div
      style={{
        left: measureResponsive(35.36),
        width: measureResponsive(7.3891739),
        top: measureResponsive(16.94928),
        height: measureResponsive(10.21433),
      }}
      className={astronautFactorySubmoduleCssClassname}
      onClick={() => {
        astronautFactoryClickHandlerFn(astronautFactoryData);
      }}
    >
      <h2
        style={{
          transform: "scaleX(" + astronautFactoryScaleX + ")",
        }}
        className={astronautFactoryCssClassname + "--title"}
      >
        {astronautFactoryData.type}
      </h2>
      {isInteractable ? (
        <DynamicInlineSVG
          svgFullFilePath={astronautFactorySvgPath}
          themeName={farmTheme}
          onCompleted={onCompletedCallback}
          onError={onErrorCallback}
        />
      ) : null}
      {isInteractable ? <Graphic cssClassName="is-actor-pointed" /> : null}
    </div>
  );
}
export default AstronautFactory;
