import { POP_CONFETTI, RESET_CONFETTI_REDUCER } from "./types";

/** Create action to fire up confetti/have the effect start in the current slide
 * @param {String} confettiTrigger - The value that is detected as React change in useEffect of the Confetti.jsx component and thus triggers the effect itself
 * @return {Object} Returns action object
 */
export function popConfetti(confettiTrigger) {
    return {
        type: POP_CONFETTI,
        payload: {
            confettiTrigger: confettiTrigger
        }
    };
}

/** Create action to reset info about the confetti state
 * @return {Object} Returns action object
 */
export function resetConfetti() {
    return {
        type: RESET_CONFETTI_REDUCER,
        payload: {}
    };
}