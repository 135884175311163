import React from "react";
import "./Windmill.scss";

function Windmill({ windmillVarietyCssClassname }) {
  const windmillContainerCssClassname = windmillVarietyCssClassname
    ? "windmill " + windmillVarietyCssClassname
    : "windmill";

  return (
    <div className={windmillContainerCssClassname}>
      <div className="windmill--body" />
      <div className="windmill--fin is-rotating" />
    </div>
  );
}

export default Windmill;
