export const COW_CSS_CLASSNAME = "cow";
export const FARMER_CSS_CLASSNAME = "farmer";
export const PET_CSS_CLASSNAME = "pet";
export const BRAND_CSS_CLASSNAME = "brand";
export const MIJN_MELK_CSS_CLASSNAME = "mijnMelk";
export const RIBON_TITLE_CSS_CLASSNAME = "ribbon title";
export const VEHICLE_CSS_CLASSNAME = "vehicle";
export const VEHICLE_LEFT_CSS_CLASSNAME = "vehicleLeft";
export const SHOP_CSS_CLASSNAME = "shop";
export const SOLARPANEL_CSS_CLASSNAME = "solarpanel";
export const CHEESE_FACTORY_CSS_CLASSNAME = "cheeseFactory";
export const MILK_FACTORY_CSS_CLASSNAME = "milkFactory";
export const ICE_CREAM_FACTORY_CSS_CLASSNAME = "iceCreamFactory";
export const ORBITER_FACTORY_CSS_CLASSNAME = "orbiterFactory";
export const PAGES_WITH_CONSTANT_COW_LOADER = [];
export const SAMPLE_FARM_ID = "mainTestFarm";
export const SAMPLE_BATCH_ID = "R2138502";
export const ANIMAL_TYPE_CAT = "cat";
export const ANIMAL_TYPE_SMALL_DOG = "smallDog";
export const ANIMAL_TYPE_MEDIUM_DOG = "mediumDog";
export const ANIMAL_TYPE_LARGE_DOG = "bigDog";
export const ANIMAL_TYPE_PONY = "pony";
export const ANIMAL_TYPE_HORSE = "horse";
export const ANIMAL_TYPE_LLAMA = "llama";
export const ANIMAL_TYPE_ALPACA = "alpaca";
export const ANIMAL_TYPE_DONKEY = "donkey";
export const ANIMAL_TYPE_GOAT = "goat";
export const ANIMAL_TYPE_SHEEP = "sheep";
export const ANIMAL_TYPE_CHICKEN = "chicken";
export const ACTOR_TYPE_COW = "cow";
export const ACTOR_TYPE_FARM = "farm";
export const ACTOR_TYPE_FARMER = "farmer";
export const ACTOR_TYPE_VECTOR_ROBOT = "vector";
export const ACTOR_TYPE_PET = "pet";
export const ACTOR_TYPE_BRAND = "brand";
export const ACTOR_TYPE_MIJN_MELK = "mijnmelkactor";
export const ACTOR_TYPE_DISCOVERY = "discovery";
export const ACTOR_TYPE_COLLECTOR = "collector";
export const ACTOR_TYPE_MILK_FACTORY = "orbiter";
export const ACTOR_TYPE_CALM_ROBOT = "calmrobot";
export const ACTOR_TYPE_CHEESE_FACTORY = "cheese";
export const ACTOR_TYPE_ICE_CREAM_FACTORY = "icecream";
export const ACTOR_TYPE_GRAZEWAY_ROBOT = "grazeway";
export const ACTOR_TYPE_ORBITER_FACTORY = "orbiterfactory";
export const ACTOR_TYPE_TRACTOR = "tractor";
export const ACTOR_TYPE_SHOP = "shop";
export const ACTOR_TYPE_OTHER_DEVICE = "other device";
export const ACTOR_TYPE_GRAZEWAY = "grazeway";
export const ACTOR_TYPE_LUNA = "luna";
export const ACTOR_TYPE_ASTRONAUT = "astronaut";
export const ACTOR_TYPE_VECTOR = "vector";
export const ACTOR_TYPE_JUNO = "juno";
export const ACTOR_TYPE_RIBBON_TITLE = "farm";
export const ACTOR_TYPE_SUSTAINABILITY_PLATE = "sustainability";
export const ACTOR_TYPE_RUMINATION = "rumination";
export const ACTOR_TYPE_SOLARPANEL = "solarpanel";
export const SIDE_MENU_OPTION_TO_THE_FARM = "SIDE_MENU_OPTION_TO_THE_FARM";
export const SIDE_MENU_OPTION_AGE_SELECTION = "SIDE_MENU_OPTION_AGE_SELECTION";
export const SIDE_MENU_OPTION_HOW_TO = "SIDE_MENU_OPTION_HOW_TO";
export const ACTOR_TYPE_BATCH_COW_LIST = "batchcowlist";
export const ACTOR_TYPE_COOKIES = "cookies";
export const ACTOR_TYPE_COOKIE_STATEMENT = "cookiestatement";
export const ACTOR_TYPE_DISCLAIMER = "disclaimer";
export const ACTOR_TYPE_DAIRY_FACTORY = "dairy";
export const AGE_SELECTION_CSS_CLASSNAME = "slide-age-selection";
export const VIRTUAL_FARM_CSS_CLASSNAME = "slide-virtual-farm";
export const MIJN_MELK_THEME_NAME = "mijnmelk";
export const ACTOR_TYPE_WHAT_IS_THIS_WEBSITE = "whatisthiswebsite";
export const DARK_HOURS_START = 22;
export const DARK_HOURS_END = 6;
export const DAYBREAK_HOURS_START = 6;
export const DAYBREAK_HOURS_END = 10;
export const FULL_SKY_OPACITY_HOURS_START = 10;
export const FULL_SKY_OPACITY_HOURS_END = 15;
export const SUNSET_HOURS_START = 15;
export const SUNSET_HOURS_END = 22;

export const DAY_START_HOUR = 6; // those two lines mean that the sun starts to rise at 06:10 in the morning
export const DAY_START_MINUTE = 10;

export const PLANETS_STARTING_X_POSITION_RATIO = 0.2;
export const PLANETS_STARTING_Y_POSITION_RATIO = 0.2;
export const PLANET_SIZE_TO_VW_RATIO = 0.2; // another way of saying '20vw'
export const WEATHER_TYPE_SOME_SNOW = "some snow";
export const WEATHER_TYPE_SNOW = "snow";
export const WEATHER_TYPE_CLEAR = "clear";
export const WEATHER_TYPE_SNOWSTORM = "snowstorm";
export const WEATHER_TYPE_DRIZZLE = "drizzle";
export const WEATHER_TYPE_RAIN = "rain";
export const WEATHER_TYPE_HEAVY_RAIN = "heavyrain";
export const WEATHER_TYPE_THUNDERSTORM = "thunderstorm";
export const WEATHER_TYPE_MIST = "mist";
export const WEATHER_TYPE_SUNNY = "sunny";
export const WEATHER_TYPE_CLOUDY = "cloudy";
export const WEATHER_TYPE_PARTIALLY_CLOUDY = "partially cloudy";
export const WEATHER_TYPE_RAIN_PARTIALLY_CLOUDY = "rain, partially cloudy";
export const WEATHER_TYPE_RAIN_OVERCAST = "rain, overcast";
export const WEATHER_DEFAULT_TEMPERATURE_IN_DEGREES_CELSIUS = 10;
export const DEFAULT_LANGUAGE_OPTIONS = [
  {
    name: "English",
    code: "en",
  },
  {
    name: "English for children",
    code: "ens",
  },
  {
    name: "Nederlands",
    code: "nl",
  },
  {
    name: "Nederlands voor kinderen",
    code: "nls",
  },
];
export const DISCOVERY_ACTOR_ID = "discovery";
export const EXCREMENT_PREFIX = "exc-";
export const MAX_NUMBER_OF_DOGS_PER_FARM = 3;

export const SPECIAL_EVENT_JANUARY_MILK = "january";
export const SPECIAL_EVENT_FEBRUARY_MILK = "february";
export const SPECIAL_EVENT_MARCH_MILK = "march";
export const SPECIAL_EVENT_APRIL_MILK = "april";
export const SPECIAL_EVENT_MAY_MILK = "may";
export const SPECIAL_EVENT_JUNE_MILK = "june";
export const SPECIAL_EVENT_JULY_MILK = "july";
export const SPECIAL_EVENT_JULY_MILK_2 = "julyfallback";
export const SPECIAL_EVENT_AUGUST_MILK = "august";
export const SPECIAL_EVENT_SEPTEMBER_MILK = "september";
export const SPECIAL_EVENT_OCTOBER_MILK = "october";
export const SPECIAL_EVENT_NOVEMBER_MILK = "november";
export const SPECIAL_EVENT_DECEMBER_MILK = "december";
export const SPECIAL_EVENT_HOT_MILK = "hotmilk";
export const SPECIAL_EVENT_RAIN_MILK = "rainmilk";
export const SPECIAL_EVENT_SNOW_MILK = "snowmilk";
export const SPECIAL_EVENT_CLOUDY_MILK = "cloudmilk";
export const SPECIAL_EVENT_GENERATION_MILK = "generationmilk";
export const SPECIAL_EVENT_MOTHER_DAUGHTER_MILK = "motherdaughter";
export const SPECIAL_EVENT_TWO_SISTERS_MILK = "twosister";
export const SPECIAL_EVENT_THREE_SISTER_MILK = "threesister";
export const SPECIAL_EVENT_FOUR_SISTER_MILK = "foursister";
export const SPECIAL_EVENT_FIVE_SISTERS_MILK = "fivesister";
export const LIVE_DATA_POPUP_LIFETIME_IN_SECONDS = 115;
export const IS_CLICKABLE_CSS_CLASSNAME = "is-clickable";
export const TRANSITION_DURATION_IN_SECONDS = 0.5;
export const ADULT_THEME_CODE = "adult";
export const HUD_MESSAGE_TYPE_NORMAL_LOG = "messageTypeNormalLog";
export const HUD_MESSAGE_TYPE_SPECIAL_LOG = "messageTypeSpecialLog";
export const FARMER_STYLE_FEMALE = "Female";
export const SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MIN_COLOR = "#5fb836";
export const SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MID_COLOR = "#eeba40";
export const SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MAX_COLOR = "#ea3323";
export const SUSTAINABILITY_CHART_FOR_ADULTS_STROKE_WIDTH = 7;
export const GESTURE_TYPE_ZOOM = "gesture type zoom";
export const GESTURE_TYPE_DRAG = "gesture type drag";
export const FARM_THEME_MILK_BOTTLE = "milkbottle";
