import _ from "lodash";
import React, { memo, useEffect, useMemo } from "react";
import Windmill from "./Windmill";
import { getRandomNumberBetweenMAndN } from "../VirtualFarm/util";
import { printConsoleSubheading } from "../../common/consoleUtil";

const WindmillGenerator = ({
  numberOfSlides,
  cowDailyDetails,
  slideWidthInPx,
}) => {
  //#region windmill generator complex computation body
  printConsoleSubheading("WindmillGenerator rerender");
  const AVAILABLE_WINDMILL_VARIETIES_CSS_CLASSNAMES = [
    // different variety = different horizontal position OR different size (scale)
    "windmill-1",
    "windmill-2",
    "windmill-3",
    "windmill-4",
  ];
  const MIN_POSSIBLE_NUMBER_OF_WINDMILLS_IN_A_WINDMILL_SLIDE = 1;
  const MAX_POSSIBLE_NUMBER_OF_WINDMILLS_IN_A_SLIDE = 2;

  function shouldAddWindmillsToCurrentSlideBasedOnSlideIndex(
    slideIndex,
    numberOfSlidesInStory,
  ) {
    const isCurrentSlideLastSlideFromStory =
      slideIndex === numberOfSlidesInStory;
    const isCurrentSlideFirstSlideFromStory = slideIndex === 0;
    const isCurrentSlidePenultimateSlideFromStory =
      slideIndex === numberOfSlidesInStory - 2;
    let shouldAddWindmillInCurrentSlide =
      isCurrentSlideFirstSlideFromStory ||
      isCurrentSlideLastSlideFromStory ||
      (currentSlideIndex % 2 === 1 && !isCurrentSlidePenultimateSlideFromStory);
    return shouldAddWindmillInCurrentSlide;
  }

  if (numberOfSlides) {
    let windmillSlides = [];

    for (
      var currentSlideIndex = 0;
      currentSlideIndex <= numberOfSlides;
      currentSlideIndex++
    ) {
      let shouldAddWindmillInCurrentSlide =
        shouldAddWindmillsToCurrentSlideBasedOnSlideIndex(
          currentSlideIndex,
          numberOfSlides,
        ); // because we don't want to have a windmill on every single slide
      if (shouldAddWindmillInCurrentSlide) {
        let availableWindmillCssClassnamesForThisSlide = [
          ...AVAILABLE_WINDMILL_VARIETIES_CSS_CLASSNAMES,
        ];
        let numberOfWindmillsToGenerateInThisSlide =
          getRandomNumberBetweenMAndN(
            MIN_POSSIBLE_NUMBER_OF_WINDMILLS_IN_A_WINDMILL_SLIDE,
            MAX_POSSIBLE_NUMBER_OF_WINDMILLS_IN_A_SLIDE,
          );
        //#region generates <N> windmills in the current BackgroundLayer slide
        let windmillJSXElementsINCurrentSlide = [];
        for (var w = 0; w < numberOfWindmillsToGenerateInThisSlide; w++) {
          let randomWindmillClassnameVarietyIndex = getRandomNumberBetweenMAndN(
            0,
            availableWindmillCssClassnamesForThisSlide.length - 1,
          );
          let currentWindmillVarietyCssClassname =
            availableWindmillCssClassnamesForThisSlide.splice(
              randomWindmillClassnameVarietyIndex,
              1,
            )[0];
          // the line above will remove the windmill CSS classname
          // from the available class names list so that
          // we can not possibly have 2 windmills
          // on top of each other at the exact same spot
          windmillJSXElementsINCurrentSlide.push(
            <Windmill
              windmillVarietyCssClassname={currentWindmillVarietyCssClassname}
            />,
          );
        }
        //#endregion
        windmillSlides.push(
          <div
            className="background-layer--slide"
            style={{
              position: "absolute",
              left: `${currentSlideIndex * slideWidthInPx}px`,
            }}
          >
            {windmillJSXElementsINCurrentSlide}
          </div>,
        );
      }
    }
    return windmillSlides;
  } else {
    return [];
  }
};

export default memo(WindmillGenerator);
