import {
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  Radio,
  withStyles,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "./ImageRadioSelection.scss";
import { lelyRed } from "../../styles/variables.scss";

const RedRadio = withStyles({
  root: {
    "&$checked": {
      color: " #c30a14",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

/** ImageRadioSelection component
 * A set of radio buttons
 * only one of which can be selected
 * @param {Object} props - Object with all props
 * @param {Number} props.initialSelectedValue - The value of the radio button that should be selected by default
 * @param {Array} props.optionsArray - Array of radio button labels. For each of those labels a Radio button component will be created
 * @param {String} props.componentLabel - Label that should be displayed on top of the radio button group
 * @param {Function} props.onSelectionChangeFn - Optional function that will be called when a radio button from the group is selected
 * @param {Boolean} [props.isDisabled] - Disable all radio buttons
 */
export default function ImageRadioSelection({
  initialSelectedValue,
  optionsArray,
  componentLabel,
  onSelectionChangeFn,
  isDisabled,
}) {
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);
  console.log("@@@@@!!!!!", initialSelectedValue);
  console.log("@@@!!", selectedValue);
  useEffect(() => {
    setSelectedValue(initialSelectedValue);
  }, [initialSelectedValue]);

  function handleRadioChange(event) {
    const newlySelectedRadioButtonValue = event.target.value;
    setSelectedValue(newlySelectedRadioButtonValue);
    if (typeof onSelectionChangeFn === "function") {
      onSelectionChangeFn(newlySelectedRadioButtonValue);
    }
  }

  return (
    <FormControl component="fieldset" className="image-radio-selection">
      <FormLabel
        component="legend"
        className="image-radio-selection--label"
        focused={false}
      >
        {componentLabel}
      </FormLabel>
      <RadioGroup
        value={selectedValue || null}
        onChange={handleRadioChange}
        className="image-radio-selection--radio-group"
      >
        {optionsArray.map((currentOption) => {
          let currentLabel = currentOption.label;
          let currentOptionValue = currentOption.value;
          let currentOptionClassName =
            "image-radio-selection--form-control " +
            "image-radio-selection--form-control-" +
            currentOption.classNameAppendix;
          return (
            <div className="image-radio-selection--radio-item">
              <FormControlLabel
                className={currentOptionClassName}
                key={currentLabel}
                value={currentOptionValue}
                control={<RedRadio />}
                label={currentLabel}
                disabled={isDisabled}
              />
            </div>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
