import React, { useRef } from "react";
import {
  translate,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import "./FifthSlideAnimalWelfare.scss";
import { translateDynamic } from "../../localization/TranslationUtil";
import { getDisableCacheAppendix } from "../../common/urlUtil";

const FifthSlideAnimalWelfare = ({ farmDetails }) => {

  const livingEnvironmentStory = translateDynamic(
    farmDetails?.animalWelfare?.livingEnvironmentStory,
  );
  const image = farmDetails?.animalWelfare?.livingEnvironmentImageData;


  return (
    <div className="animalWelfareStoryFifthSlide">
      <TextCaption additionalCssClassname="animalWelfareStoryFifthSlide--textCaption">
        <div className="animalWelfareStoryFifthSlide--title">
          {translate("ANIMAL_WELFARE_LIVING_ENVIRONMENT")}
        </div>
        <div className="animalWelfareStoryFifthSlide--text">
          {livingEnvironmentStory}
        </div>
      </TextCaption>
      <div className={`animalWelfareStoryFifthSlide--imgCaption`}>
        <img src={getDisableCacheAppendix(image)} className="animalWelfareStoryFifthSlide--imgContainer"></img>
        <div className="animalWelfareStoryFifthSlide--imgText">
        </div>
      </div>
      <div className="animalWelfareStoryFifthSlide-treeAndSmallBush"></div>
    </div>
  );
};

export default FifthSlideAnimalWelfare;
