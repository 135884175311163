import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenuOption from "../SideMenuOption/SideMenuOption";
import "../SideMenuOption//SideMenuOption.scss"; //or use dedicated file?
import {
  activateRequestedLanguage,
  isSimpleModeActive,
  translate,
} from "../../localization/TranslationUtil";
import uuid from "react-uuid"; 
import './SideMenuLanguageOptions.scss';

function SideMenuLanguageOptions({ onMenuCloseClickFn }) {
  const dispatch = useDispatch();
  let simpleMode = isSimpleModeActive();
  let codeLength = simpleMode ? 3 : 2;
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  let filteredLanguages =
    farmDetails && farmDetails.farmer && farmDetails.farmer.languages
      ? farmDetails.farmer.languages.filter(
          (item) => item.code.length === codeLength,
        )
      : null;

  return filteredLanguages && filteredLanguages.length > 1 ? (
    <div className="sideMenu--mainMenu--languages">
      {filteredLanguages.map((language) => {
        return language.code.length == codeLength ? (
          <SideMenuOption
            // label={translate(
            //   "SIDE_MENU_LANGUAGE_OPTION_" + language.code.toUpperCase(),
            // )}
            key={uuid()}
            cssClassName={`sideMenu--mainMenu--${language.code}`}
            onClickFn={() => {
              activateRequestedLanguage(
                {
                  language: language.code,
                },
                dispatch,
              );
              onMenuCloseClickFn();
            }}
          />
        ) : null;
      }).reverse()}
    </div>
  ) : null;
}
export default SideMenuLanguageOptions;
