import { POPUP_CALL_TO_ACTION_BUTTON_CLICKED, RESET_POPUP_INFO, SHOW_POPUP } from "./types";

/** Create action to store call to action data for popup
 * @param {Object} popupItemData - Object containing popup-specific data
 * @param {Object} selectedVariety
 * @param {Object} storyActorData
 * @param {String} showActorStoryTrigger - OPTIONAL for testing purposes; a timestamp that identifies uniquely the current popup show operation
 * @return {Object} Returns action object
 */
export function popupCallToActionClicked(popupItemData, selectedVariety, storyActorData, showActorStoryTrigger) {
    return {
        type: POPUP_CALL_TO_ACTION_BUTTON_CLICKED,
        payload: {
            popupItemData: popupItemData,
            selectedVariety: selectedVariety,
            storyActorData: storyActorData,
            showActorStoryTrigger: showActorStoryTrigger
        }
    };
}

/** Create action to show popup
 * @param {Object} popupInfo - Object containing popup-specific data
 * @return {Object} Returns action object
 */
export function showPopup(popupInfo) {
    return {
        type: SHOW_POPUP,
        payload: {
            popupInfo: popupInfo
        }
    };
}


/** Create action to reset popup info
 * @return {Object} Returns action object
 */
export function resetPopupInfo() {
    return {
        type: RESET_POPUP_INFO,
        payload: {}
    };
}