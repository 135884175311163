import { POPUP_CALL_TO_ACTION_BUTTON_CLICKED, RESET_POPUP_INFO, SHOW_POPUP } from '../common/components/PopupContainer/types';

/** Store states
 * @constant {Object} */
export const initialState = {
    popupItemDetails: {},
    selectedVariety: {},
    storyActorData: {},
    showActorStoryTrigger: '',
    popupInfo: {},
};

/** Popup Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function popupReducer(state = initialState, action) {
    switch (action.type) {
        case POPUP_CALL_TO_ACTION_BUTTON_CLICKED:
            return {
                ...state,
                popupItemDetails: action.payload.popupItemData,
                selectedVariety: action.payload.selectedVariety,
                storyActorData: action.payload.storyActorData,
                showActorStoryTrigger: (action.payload.showActorStoryTrigger && action.payload.showActorStoryTrigger.length) ? action.payload.showActorStoryTrigger : new Date().getTime().toString()
            };

        case SHOW_POPUP:
            return {
                ...state,
                popupInfo: action.payload.popupInfo,
            };

        case RESET_POPUP_INFO:
            return {
                ...state,
                popupItemDetails: {},
                selectedVariety: {},
                storyActorData: {},
                popupInfo: {},
                showActorStoryTrigger: ''
            };
        default:
            return state;
    }
}

export default popupReducer;
