import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
} from "../components/UserManagement/LoginForm/types.js";
import { LOGOUT_SUCCESS } from "../components/LogoutButton/types.js";
import { UPDATE_ACCESS_TOKEN_AND_REFRESH_TOKEN_WITH_NEW_ONES } from "../components/AccessToken/types.js";

/** Store states
 * @constant {Object} */
export const initialState = {
  loginError: "",
  currentUser: "",
  isLoggedIn: false,
  userRoles: [],
};

/** User session reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function userSessionReducer(state = initialState, action) {
  switch (action.type) {
    /** Action Creator - login
     * it will receive username and token dispatching from the saga
     */
    case LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload.username,
        accessToken: action.payload.accessToken,
        isLoggedIn: true,
        loginError: "",
        userRoles: action.payload.userRoles,
      };

    case UPDATE_ACCESS_TOKEN_AND_REFRESH_TOKEN_WITH_NEW_ONES:
      return {
        ...state,
        accessToken: action.payload.newAccessToken,
        refreshToken: action.payload.newRefreshToken,
      };

    /** Action Creator - Login failed
     * it will receive the error message  dispatching from the saga
     */
    case LOGIN_ERROR:
      return {
        ...state,
        currentUser: "",
        isLoggedIn: false,
        loginError: action.payload,
        userRoles: [],
      };

    /** Action Creator - logout
     * it will receive empty data  dispatching from the saga
     */
    case LOGOUT_SUCCESS:
      return {
        ...state,
        currentUser: "",
        accessToken: "",
        isLoggedIn: false,
        userRoles: [],
      };

    default:
      return state;
  }
}

export default userSessionReducer;
