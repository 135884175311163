import { useState } from "react";

export default function useDetectSamsungInternetBrowser() {
  function getIsSamsungInternetBrowser() {
    if (navigator && navigator.userAgent) {
      return (
        navigator.userAgent.indexOf("SamsungBrowser") > -1 &&
        navigator.userAgent.indexOf("SAMSUNG") > -1
      );
    }

    return false;
  }

  const [isSamsungInternetBrowser, setIsSamsungInternetBrowser] = useState(
    getIsSamsungInternetBrowser(),
  );

  return {
    isSamsungInternetBrowser,
  };
}
