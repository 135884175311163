import { translateDynamic } from "../localization/TranslationUtil";

var currentActorConfig = {};
export const setCurrentActorConfig = (newActorConfig) => {
  currentActorConfig = newActorConfig;
};

export const getCurrentActorConfig = () => {
  return currentActorConfig;
};

export const getFarmPopupInfoByFarmDetails = (farmDetails, farmActorType) => {
  let farmPopupInfo = {
    type: farmActorType,
    farmName: translateDynamic(farmDetails.farmName),
    description: (farmDetails.description),
    imageData: farmDetails?.imageData?.all
  };
  return farmPopupInfo;
};