import React from 'react';
import { translate } from '../../localization/TranslationUtil';
import CallToActionButton from '../CallToActionButton/CallToActionButton';
import Graphic from '../Graphic/Graphic';
import IconButton from '../IconButton/IconButton';
import LabelValue from '../LabelValue/LabelValue';
import './CheeseFactoryPopup.scss';

/** CheeseFactoryPopup component */
function CheeseFactoryPopup({ selectedCheeseFactoryDetails, callToActionClickFn, closePopupClickFn, isAdultSelected }) {
  return (
    <div className="cheeseFactoryPopup">
      <div className='cheeseFactoryPopup--container'>
        <IconButton cssClassName="cheeseFactoryPopup--btnClosePopup" onClickFn={closePopupClickFn} />
        <div className="cheeseFactoryPopup--title">{translate('COW_CHEESE_FACTORY_TITLE')}</div>
        <div className="cheeseFactoryPopup--description">
        {translate('COW_CHEESE_FACTORY_DESCRIPTION_TEXT')}
      </div>
      </div>
      <Graphic cssClassName="cheeseFactoryPopup--cheese" />
      {/* <CallToActionButton cssClassName="cheeseFactoryPopup--btnCallToAction"
        isAdultSelected={isAdultSelected}
        label={translate('COW_AVAILABLE_SOON_ACTION_BUTTON')}
        onClickFn={() => { callToActionClickFn(); }}
      /> */}
      
    </div>
  );
}
export default CheeseFactoryPopup;
