import { translate } from '../../../localization/TranslationUtil';
import React from 'react';
import _ from 'lodash';
import './StoryLanguageSelector.scss';
import CheckboxGroupSelection from '../CheckboxGroupSelection/CheckboxGroupSelection';


/** StoryLanguageSelector component.
 * @param {Object} obj - Object which contains all props.
 * @param {Array} obj.selectedLanguageKeys - Array with all of the keys of the selected story languages
 * @param {Array} obj.allAvailableLanguageCodes - Array with all available language codes
 * @param {Function} obj.handleLanguagesChangedFn - Function that will be called when the language selection has been changed
 */
function StoryLanguageSelector({
    selectedLanguageKeys,
    allAvailableLanguageCodes,
    handleLanguagesChangedFn
}) {
    let languageCheckboxOptions = allAvailableLanguageCodes.map(currentLanguageKey => (
        {
            classNameAppendix: 'languageKey',
            value: currentLanguageKey,
            label: translate('LANGUAGE_SELECTION_' + currentLanguageKey.toUpperCase() + '_LABEL'),
        }
    ));
    return (
        <div className="story-language-selector">
            <CheckboxGroupSelection
                optionsArray={languageCheckboxOptions}
                onSelectionChange={(newSelectedValue, isChecked) => {
                    let newLanguagesKeysSelected;
                    if (isChecked) {
                        newLanguagesKeysSelected = selectedLanguageKeys.concat(newSelectedValue);
                    } else {
                        newLanguagesKeysSelected = selectedLanguageKeys.filter(currentLanguageKey => currentLanguageKey !== newSelectedValue);
                    }
                    if (newLanguagesKeysSelected.length === 0) {
                        return;
                    }
                    handleLanguagesChangedFn(newLanguagesKeysSelected);
                }}
                selectedOptions={selectedLanguageKeys}
            />
        </div>
    );
}

export default StoryLanguageSelector;
