import { VOTE_FOR_COW_REQUEST } from './types.js';
import {
  voteForCowNameError,
  voteForCowNameSuccess,
} from './actions.js';
import { put, take, call } from 'redux-saga/effects';
import { getServerResponseStatus } from '../ErrorPopUp/actions';
import {
  startLoading,
  endLoading,
} from '../../common/redux/loadManager/actions';
import { getLoadId } from '../../common/redux/loadManager/methods';
import { updatePollInfoForCowWithId } from '../../components/VirtualFarm/actions.js';

let currentLoadId = 0;


export default function* cowNamePollingSaga(api) {
  while (true) {
    const voteForCowRequestAction = yield take(
      VOTE_FOR_COW_REQUEST,
    );
    /** Update state when server is online */
    yield put(getServerResponseStatus(''));
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { pollId, cowExternalId, cowName } = voteForCowRequestAction.payload;
    yield call(voteForCowName, api, pollId, cowExternalId, cowName);
  }
}

/** Create request to vote for cow name
 * @param {string} pollId - The ID of the name poll
 * @param {string} cowExternalId - The external id of the cow for which we are voting
 * @param {string} cowName - The name of the cow for which we are voting
 */
function* voteForCowName(api, pollId, cowExternalId, cowName) {
  try {
    const cowNameVoteDetails = yield call(
      api.voteForCowName,
      pollId,
      cowName,
    );
    let cowVoteData = cowNameVoteDetails.data;

    if (cowVoteData) {
      yield put(voteForCowNameSuccess({
        newPollData: cowVoteData,
        cowExternalId: cowExternalId
      }));
      yield put(updatePollInfoForCowWithId(cowVoteData, cowExternalId));
      /** Stop loader */
      yield put(endLoading(currentLoadId));
    }
  } catch (error) {
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(voteForCowNameError(error));
    /** Listen for error state to show when server is disconected */
    yield put(getServerResponseStatus(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}
