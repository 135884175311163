import React from "react";
import { translate, translateDynamic } from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import IconButton from "../IconButton/IconButton";
import "./SustainabilityPlatePopup.scss";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import { useSelector } from "react-redux";
import { getDisableCacheAppendix } from "../../common/urlUtil";

/** SustainabilityPlatePopup component */
function SustainabilityPlatePopup({
  selectedSustainabilityDetails,
  callToActionClickFn,
  secondCallToActionClickFn,
  closePopupClickFn,
  isAdultSelected,
}) {
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const hasSustainabilityData =
    farmDetails?.sustainabilityData?.hasSustainabilityData;
  const hasAnimalWelfareData = farmDetails?.animalWelfare?.hasAnimalWelfareData;
  const cssClassAppendix =
    typeof isAdultSelected === "undefined" || isAdultSelected === true
      ? " is-adult-selected"
      : "";
  let sustainabilityPopupCssClassName =
    "sustainabilityPopup" + cssClassAppendix; 
  const sustainabilityModalDescription = translateDynamic(farmDetails?.sustainabilityData?.sustainabilityPopupStory);
  const sustainabilityPopupImage = (farmDetails?.sustainabilityData?.sustainabilityPopupImageData); 
  console.log('sustainabilityModalDescription',sustainabilityModalDescription)
  return (
    <div className={sustainabilityPopupCssClassName}>
      <div className="sustainabilityPopup--container">
        <IconButton
          cssClassName="sustainabilityPopup--btnClosePopup"
          onClickFn={closePopupClickFn}
        />
        <div className="sustainabilityPopup--title">
          {translate("SUSTAINABILITY_TITLE")}
        </div>
        <div className="sustainabilityPopup--description">
        {''}
        {(sustainabilityModalDescription == null || sustainabilityModalDescription?.length < 1) ? translate("SUSTAINABILITY_DESCRIPTION") : sustainabilityModalDescription}
        </div>
        <div className="sustainabilityPopup--buttons">
        {hasSustainabilityData && farmDetails?.sustainabilityData?.hasCarbonDioxideMeasures || hasSustainabilityData && farmDetails?.sustainabilityData?.hasConcentratesData || hasSustainabilityData && farmDetails?.sustainabilityData?.hasFertilizerData || hasSustainabilityData && farmDetails?.sustainabilityData?.hasRenewableEnergy || hasSustainabilityData && farmDetails?.sustainabilityData?.hasRoughageProduction ? (
          <CallToActionButton
            isAdultSelected={isAdultSelected}
            cssClassName="sustainabilityPopup--btnCallToAction"
            label={translate("SUSTAINABILITY_CALL_TO_ACTION_BUTTON")}
            onClickFn={() => {
              callToActionClickFn();
              closePopupClickFn();
            }}
          />
        ) : null}
        {hasAnimalWelfareData && (farmDetails?.animalWelfare?.hasCowAndCalfData && farmDetails?.animalWelfare?.cowAndCalfImageData) || hasAnimalWelfareData && (farmDetails?.animalWelfare?.hasGrazingData && farmDetails?.animalWelfare?.grazingDays) || hasAnimalWelfareData && (farmDetails?.animalWelfare?.hasHerdBreedAndAgeData && farmDetails?.animalWelfare?.herdBreedAndAgeImageData) || hasAnimalWelfareData && (farmDetails?.animalWelfare?.hasLivingEnvironmentData && farmDetails?.animalWelfare?.livingEnvironmentImageData)? (
        <CallToActionButton
            isAdultSelected={isAdultSelected}
            cssClassName="sustainabilityPopup--btnCallToActionAnimalWelfare"
            label={translate("ANIMAL_WELFARE_SUSTAINABILITY_POPUP")}
            onClickFn={() => {
              secondCallToActionClickFn();
              closePopupClickFn();
            }}
          />
          ): null}
          </div>
      </div>
      {/* //<Graphic cssClassName="sustainabilityPopup--logo" /> */}
      <div className="farmPopup--farmImageContainer"> 
      {sustainabilityPopupImage ? (
        <img
          src={getDisableCacheAppendix(sustainabilityPopupImage)}
          className="farmPopup--farmImage"
        ></img>) : 
        <div className="sustainabilityPopup--defaultImage"/>}
        <div className="farmPopup--frame" />
      </div>
    </div>
  );
}
export default SustainabilityPlatePopup;
