import React, { useEffect } from "react";
import "./AccountActivated.scss";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { translate } from "../../../localization/TranslationUtil";
import { activateUser } from "./actions";
import { resetUserManagement } from "../CreateAccount/actions";
import { useLocation } from "react-router-dom";
import { redirect } from "../../CustomRedirect/actions";

/** AccountActivated component */
function AccountActivated() {
  const dispatch = useDispatch();
  const { isActivateAccountSuccess, isActivateAccountError, licenseKey } =
    useSelector((state) => state.userManagementDetails);
  const search = useLocation().search;

  console.log("@AccountActivated");
  const userId = new URLSearchParams(search).get("uId");
  const customerId = new URLSearchParams(search).get("cId");
  const activationToken = new URLSearchParams(search).get("tkn");
  console.log(
    "@AccountActivated userId:",
    userId,
    "customerId:",
    customerId,
    "activationToken:",
    activationToken,
  );

  useEffect(() => {
    dispatch(activateUser(activationToken, customerId, userId, licenseKey));
    return () => {
      dispatch(resetUserManagement());
    };
  }, [activationToken]);

  function handleLoginButtonClick() {
    dispatch(redirect("/admin/login"));
  }

  return (
    <div className="account-activated-container">
      {isActivateAccountSuccess || true ? (
        <>
          <h4>{translate("USER_MANAGEMENT_ACCOUNT_ACTIVATED_HEADING_TEXT")}</h4>
          <h5>{translate("USER_MANAGEMENT_ACCOUNT_ACTIVATED_BODY_TEXT")}</h5>
          <Button
            data-testid="btn-login"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="btn-login"
            onClick={handleLoginButtonClick}
          >
            {translate("USER_MANAGEMENT_ACCOUNT_ACTIVATED_LOGIN_BUTTON_TEXT")}
          </Button>
        </>
      ) : null}
      {isActivateAccountError ? (
        <h4>
          {translate(
            "USER_MANAGEMENT_ACCOUNT_ACTIVATED_ACTIVATION_ERROR_HEADING",
          )}
        </h4>
      ) : null}
    </div>
  );
}

export default AccountActivated;
