import { SLIDE_TITLE_CHANGED, RESET_SLIDE_TITLE_INFO } from "./types";

/** Create action to store the title of the latest slide that has been displayed/navigated to by the end user
 * @param {String} newSlideTitle - The new slide title
 * @return {Object} Returns action object
 */
export function slideTitleChanged(newSlideTitle) {
  return {
    type: SLIDE_TITLE_CHANGED,
    payload: {
      slideTitle: newSlideTitle,
    },
  };
}

/** Create action to reset info about the latest slide to which the user has navigated to
 * @return {Object} Returns action object
 */
export function resetSlideTitleInfo() {
  return {
    type: RESET_SLIDE_TITLE_INFO,
    payload: {},
  };
}
