export const getBackgroundLayerTranslateXInPx = function (
  parentTranslateX,
  totalMovementAmount,
  parentStartX,
  parentEndX,
) {
  const distanceFromStartInPx = parentTranslateX - parentStartX;
  const totalDistanceFromStartToEndInPx = parentEndX - parentStartX;
  const ratio = distanceFromStartInPx / totalDistanceFromStartToEndInPx;
  const translateX = ratio * totalMovementAmount;
  return -translateX;
};
