import _ from 'lodash';
import moment from 'moment';
import { translate } from '../../../localization/TranslationUtil';

/** Netherland time */
let currentTimeInNetherland = () => {
  return moment(moment.tz('Europe/Amsterdam').format().slice(0, -6));
};

/** Common date picker validate
 * @param {Object} datePickerStartDate - Date picker start date
 * @param {Object} datePickerEndDate - Date picker end date
 */
export function datePickerCommonValidation(
  datePickerStartDate,
  datePickerEndDate,
  disableFutureValidation = false,
) {
  const isStartDateAfterEndDate =
    moment(datePickerStartDate) > moment(datePickerEndDate);
  /** Check if start date is after end date*/
  if (isStartDateAfterEndDate) {
    return translate('START_DATE_NEEDS_TO_BE_BEFORE_END_DATE_ERROR_TEXT');
  }

  // const isStartDateSameAsEndDate = moment(datePickerStartDate).isSame(
  //   moment(datePickerEndDate),
  // );
  // if (isStartDateSameAsEndDate) {
  //   return translate(
  //     'START_DATE_NEEDS_TO_BE_DIFFERENT_FROM_END_DATE_ERROR_TEXT',
  //   );
  // }

  const isStartDateInTheFuture = moment(datePickerStartDate).isAfter(
    currentTimeInNetherland(),
  );

  /** Show error message if start date is in the future */
  // if (isStartDateInTheFuture) {
  //   return translate('START_DATE_IN_FUTURE_ERROR_TEXT');
  // }

  // const isEndDateInTheFuture = moment(datePickerEndDate).isAfter(currentTimeInNetherland());
  // /** Show error message if end date is in the future */
  // if (isEndDateInTheFuture && !disableFutureValidation) {
  //   return translate('END_DATE_IN_FUTURE_ERROR_TEXT');
  // }

  const isNoDateSelected =
    datePickerStartDate === null || datePickerEndDate === null;
  if (isNoDateSelected) {
    /** Show error message if either start or end date is not specified */
    return translate('NO_DATE_SELECTED_ERROR_TEXT');
  }

  const isDateDataNotFull =
    isNaN(moment(datePickerStartDate).seconds()) ||
    isNaN(moment(datePickerEndDate).seconds());
  /** Show error message if either start or end date are not filled */
  if (isDateDataNotFull) {
    return translate('INVALID_DATE_FORMAT_ERROR_TEXT');
  }

  const isStartDateBeforeMinDate = moment(datePickerStartDate).year() < 1900;
  if (isStartDateBeforeMinDate) {
    return translate('START_DATE_IS_BEFORE_MINIMAL_DATE');
  }
  const isEndDateBeforeMinDate = moment(datePickerEndDate).year() < 1900;
  if (isEndDateBeforeMinDate) {
    return translate('END_DATE_IS_BEFORE_MINIMAL_DATE');
  }
}

export function singleDatePickerValidation(datePickerStartDate) {
  const isStartDateInTheFuture = moment(datePickerStartDate).isAfter(
    currentTimeInNetherland(),
  );
  /** Show error message if start date is in the future */
  if (isStartDateInTheFuture) {
    return translate('DATE_IN_FUTURE_ERROR_TEXT');
  }

  const isNoDateSelected = datePickerStartDate === null;
  if (isNoDateSelected) {
    /** Show error message if start date is not specified */
    return translate('NO_DATE_SELECTED_ERROR_TEXT');
  }

  const isDateDataNotFull = isNaN(moment(datePickerStartDate).seconds());
  /** Show error message if start date is not filled */
  if (isDateDataNotFull) {
    return translate('INVALID_DATE_FORMAT_ERROR_TEXT');
  }

  const isStartDateBeforeMinDate = moment(datePickerStartDate).year() < 1900;
  if (isStartDateBeforeMinDate) {
    return translate('DATE_IS_BEFORE_MINIMAL_DATE');
  }
}
