import React from 'react';
import './DynamicSlide.scss';
import TextCaption from '../TextCaption/TextCaption';
import { getDisableCacheAppendix } from '../../common/urlUtil';
import { translateSlides, translateDynamic } from '../../localization/TranslationUtil';
import { useSelector } from "react-redux";

const DynamicSlide = ({ title, description, img, index }) => {

  const isOdd = () => {
    return index % 2;
  };
  return (
    <div className="farmerStoryDynamicSlide">
      <TextCaption
        additionalCssClassname="farmerStoryDynamicSlide--textCaption"
      >
        <div className="farmerStoryDynamicSlide--title">{translateDynamic(title)}</div>
        <div className="farmerStoryDynamicSlide--description">
         {translateDynamic(description)}
        </div>
      </TextCaption>
      <div className={`farmerStoryDynamicSlide--imgCaption--${isOdd()}`}>
        <img src={getDisableCacheAppendix(img)} className="farmerStoryDynamicSlide--imgContainer"></img>
        <div className="farmerStoryDynamicSlide--imgText">
        </div>
      </div>
    </div>
  );
};

export default DynamicSlide;
