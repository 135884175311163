import IconButton from "../IconButton/IconButton";
import React from "react";
import "./SideMenu.scss";
//import API_PREFIX from '../../api/lelyBackend/config';
import SideMenuOption from "../SideMenuOption/SideMenuOption";
import SideMenuLanguageOptions from "../SideMenuLanguageOptions/SideMenuLanguageOptions";
import { translate } from "../../localization/TranslationUtil";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import {
  ACTOR_TYPE_COOKIE_STATEMENT,
  ACTOR_TYPE_DISCLAIMER,
  ACTOR_TYPE_SUSTAINABILITY_PLATE,
  ACTOR_TYPE_WHAT_IS_THIS_WEBSITE,
} from "../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { showPopup } from "../../common/components/PopupContainer/actions";

/** SideMenu component */
function SideMenu({
  cssClassName,
  isMenuDisplayed,
  onMenuOptionClickFn,
  onMenuCloseClickFn,
}) {
  const dispatch = useDispatch();
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const farmerDetails = farmDetails?.farmer;
  const cssClassAppendix =
    typeof farmDetails.isAdultSelected === "undefined" ||
    farmDetails.isAdultSelected === true
      ? " is-adult-selected"
      : "";
  let shareableButtonsCssClassName =
    "sideMenu" + "--mainMenu" + "--shareableButtons" + cssClassAppendix;
  let sideMenuCssClassName =
    "sideMenu " +
    cssClassName +
    (isMenuDisplayed
      ? " is-" + cssClassName + "-displayed" + cssClassAppendix
      : "");
  let shareableURL = window.location.href.toString();

  shareableURL =
    shareableURL.indexOf("localhost") !== -1
      ? shareableURL.replace("localhost:3000", "mijnboerderij.mijnmelk.nl/")
      : shareableURL;

  return (
    <div className={sideMenuCssClassName}>
      <div className="sideMenu--mainMenu--upper">
        <SideMenuOption
          label={translate("SIDE_MENU_OPTION_TO_THE_FARM")}
          translationKey={"SIDE_MENU_OPTION_TO_THE_FARM"}
          onClickFn={(translationKey) => {
            onMenuOptionClickFn(translationKey);
            onMenuCloseClickFn();
          }}
          cssClassName="sideMenu--mainMenu--menuOption"
        />
        <SideMenuOption
          label={translate("SIDE_MENU_OPTION_HOW_TO")}
          translationKey={"SIDE_MENU_OPTION_HOW_TO"}
          onClickFn={(translationKey) => {
            onMenuOptionClickFn(translationKey);
            onMenuCloseClickFn();
          }}
          cssClassName="sideMenu--mainMenu--menuOption"
        />
        <SideMenuOption
          label={translate("SIDE_MENU_OPTION_THE_FARMER")}
          transationKey={"SIDE_MENU_OPTION_THE_FARMER"}
          onClickFn={(translationKey) => {
            onMenuCloseClickFn();
            dispatch(showPopup(farmerDetails));
          }}
          cssClassName="sideMenu--mainMenu--menuOption"
        />
        <SideMenuOption
          label={translate("SIDE_MENU_OPTION_SUSTAINABILITY")}
          transationKey={"SIDE_MENU_OPTION_SUSTAINABILITY"}
          onClickFn={(translationKey) => {
            onMenuCloseClickFn();
            dispatch(showPopup({ type: ACTOR_TYPE_SUSTAINABILITY_PLATE }));
          }}
          cssClassName="sideMenu--mainMenu--menuOption"
        />
        <SideMenuOption
          label={translate("SIDE_MENU_OPTION_ABOUT_THE_WEBSITE")}
          transationKey={"SIDE_MENU_OPTION_ABOUT_THE_WEBSITE"}
          onClickFn={(translationKey) => {
            onMenuCloseClickFn();
            dispatch(showPopup({ type: ACTOR_TYPE_WHAT_IS_THIS_WEBSITE }));
          }}
          cssClassName="sideMenu--mainMenu--menuOption"
        />
      </div>
      <SideMenuLanguageOptions
        onMenuCloseClickFn={() => {
          onMenuCloseClickFn();
        }}
      />
      <div className={shareableButtonsCssClassName}>
        <a
          className="Demo__some-network__share-button-mail"
          href="mailto:info@mijnboerderij.mijnmelk.nl?subject=Mail from our Website"
        >
          <EmailIcon
            size={"4vw"}
            round
            className="btn-social theme-btn-social"
          ></EmailIcon>
        </a>

        <LinkedinShareButton
          url={shareableURL}
          quote="link"
          className="Demo__some-network__share-button"
        >
          <LinkedinIcon
            size={"4vw"}
            round
            className="btn-social theme-btn-social"
          />
        </LinkedinShareButton>
        <FacebookShareButton
          url={shareableURL}
          quote="link"
          className="Demo__some-network__share-button"
        >
          <FacebookIcon
            size={"4vw"}
            round
            className="btn-social theme-btn-social"
          />
        </FacebookShareButton>
        <TwitterShareButton
          url={shareableURL}
          quote="link"
          className="Demo__some-network__share-button"
        >
          <TwitterIcon
            size={"4vw"}
            round
            className="btn-social theme-btn-social"
          />
        </TwitterShareButton>
      </div>
      <div className="sideMenu--mainMenu--legal">
        <SideMenuOption
          label={translate("SIDE_MENU_OPTION_PRIVACY_POLICY")}
          translationKey={"SIDE_MENU_OPTION_PRIVACY_POLICY"}
          cssClassName="sideMenu--mainMenu--privacyPolicy"
          onClickFn={(translationKey) => {
            onMenuCloseClickFn();
            dispatch(showPopup({ type: ACTOR_TYPE_DISCLAIMER }));
          }}
        />
        <SideMenuOption
          label={translate("SIDE_MENU_OPTION_COOKIES")}
          translationKey={"SIDE_MENU_OPTION_COOKIES"}
          onClickFn={(translationKey) => {
            onMenuCloseClickFn();
            dispatch(showPopup({ type: ACTOR_TYPE_COOKIE_STATEMENT }));
          }}
          cssClassName="sideMenu--mainMenu--cookies"
        />
      </div>
      {farmDetails.isAgeSelected === undefined ? (
        <IconButton
          cssClassName="sideMenu--mainMenu--btnCloseMenu--adult"
          onClickFn={() => {
            onMenuCloseClickFn();
          }}
        />
      ) : (
        <IconButton
          cssClassName="sideMenu--mainMenu--btnCloseMenu"
          onClickFn={() => {
            onMenuCloseClickFn();
          }}
        />
      )}
    </div>
  );
}
export default SideMenu;
