import React, { useEffect } from "react";
import "./ImagePreview.scss";
import { ImagePreviewProps } from "./ImagePreview.types";

/** ImagePreview component */
const ImagePreview: React.FC<any> = ({
  imageFileUrl,
  onDeleteFileFn,
  additionalCssClassname,
}: ImagePreviewProps) => {
  let cssClassname =
    imageFileUrl == "" ? "imagePreview is-no-image-selected" : "imagePreview";
  cssClassname = additionalCssClassname
    ? `${additionalCssClassname} ${cssClassname}`
    : cssClassname;
  return (
    <div className={cssClassname}>
      <img
        className="imagePreview--previewImage"
        src={imageFileUrl /* + "?timestamp=" + new Date().getTime()*/}
      />
      <div className="imagePreview--previewFrame"></div>
      <span className="imagePreview--btnRemoveImage" onClick={onDeleteFileFn}>
        Remove
      </span>
    </div>
  );
};

export default ImagePreview;
