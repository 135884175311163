import _ from "lodash";
import ReactGA from "react-ga4";

/**
 * Google Analytics Tracking Event
 * @param {string} categoryName - Category name of the selected event / Required
 * @param {string} eventName - Name of the Event / Required
 * @param {string} [eventLabel] - Optional event description
 */
export const googleAnalyticsEventTracker = (
  categoryName,
  eventName,
  eventLabel,
) => {
  console.log(
    "SENDING GOOGLE ANALYTICS EVENT category:",
    categoryName,
    "action:",
    eventName,
    "label:",
    eventLabel,
  );
  ReactGA.event({
    category: categoryName, // Required
    action: eventName, // Required
    label: eventLabel,
  });
};

const LIMIT_GOOGLE_ANALYTICS_EVENT_SENDING_TO_MILLISECONDS_COUNT = 2000;
export const debouncedGoogleAnalyticsPageVisitHandler = _.throttle((pageId) => {
  ReactGA.set({ page: pageId }); // Update the user's current page
  ReactGA.send({ hitType: "pageview", page: pageId, title: pageId }); // Record a pageview for the given page
}, LIMIT_GOOGLE_ANALYTICS_EVENT_SENDING_TO_MILLISECONDS_COUNT);

export const debouncedGoogleAnalyticsEventHandler = _.throttle(
  (eventType, farmId) => {
    googleAnalyticsEventTracker(eventType, eventType, farmId + "/" + eventType);
  },
  LIMIT_GOOGLE_ANALYTICS_EVENT_SENDING_TO_MILLISECONDS_COUNT,
);
