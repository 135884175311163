import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './Thunderstorm.scss';
import { getRandomNumberBetweenMAndN } from '../../components/VirtualFarm/util';


/** Thunderstorm component */
function Thunderstorm({ viewportWidthInPx, viewportHeightInPx }) {
  const SHORTEST_TIME_BETWEEN_LIGHTNINGS_IN_SECONDS = 10;
  const MIN_LIGHTNING_VISIBILITY_TIME_IN_SECONDS = 1;
  const MAX_LIGHTNING_VISIBILITY_TIME_IN_SECONDS = 2;
  const MAX_TIME_TO_WAIT_FOR_THUNDER_AFTER_NOW_IN_SECONDS = 10;
  const THUNDER_MIN_X_POSITION = 0.1 * viewportWidthInPx;
  const THUNDER_MAX_X_POSITION = 0.9 * viewportWidthInPx;
  const THUNDER_TYPE_VARITIES_COUNT = 6;
  const THUNDER_HEIGHT_IN_PX = 0.6 * viewportHeightInPx;

  const [currentThunder, setCurrentThunder] = useState({});


  function getRandomThunder() {
    let thunderVisibilityInSeconds = getRandomNumberBetweenMAndN(MIN_LIGHTNING_VISIBILITY_TIME_IN_SECONDS, MAX_LIGHTNING_VISIBILITY_TIME_IN_SECONDS);
    let thunderXPositionInPx = getRandomNumberBetweenMAndN(THUNDER_MIN_X_POSITION, THUNDER_MAX_X_POSITION);
    let thunderImageVarietyIndex = getRandomNumberBetweenMAndN(1, THUNDER_TYPE_VARITIES_COUNT);

    return {
      left: thunderXPositionInPx,
      lifetimeInSeconds: thunderVisibilityInSeconds,
      imageVarietyIndex: thunderImageVarietyIndex
    };
  }

  function showThunder() {
    let randomThunder = getRandomThunder();
    setCurrentThunder(randomThunder);
    setTimeout(() => {
      hideThunder();
    }, randomThunder.lifetimeInSeconds * 1000);
  }

  function hideThunder() {
    setCurrentThunder({});
  }

  useEffect(() => {
    const thunderInterval = setInterval(
      () => {
        let randomSecondsToWaitForThunderAfterNow = getRandomNumberBetweenMAndN(0, MAX_TIME_TO_WAIT_FOR_THUNDER_AFTER_NOW_IN_SECONDS);
        setTimeout(function () {
          showThunder();
        }, randomSecondsToWaitForThunderAfterNow);
      },
      SHORTEST_TIME_BETWEEN_LIGHTNINGS_IN_SECONDS * 1000
    );

    // clean up interval on unmount
    return () => {
      clearInterval(thunderInterval);
    };
  });

  return (
    <div className='thunderstorm--container'>
      {!_.isEmpty(currentThunder) ?
        [<div className="thunderstorm--overlay"></div>,
        <div
          style={{
            left: `${currentThunder.left}px`,
            height: `${THUNDER_HEIGHT_IN_PX}px`
          }}
          className={`thunder thunder-${currentThunder.imageVarietyIndex}`}></div>] : null
      }
    </div >
  );
}
export default Thunderstorm;
