import React, { useEffect } from "react";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import IconButton from "../IconButton/IconButton";
import { translate } from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import "./AstronautPopup.scss";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import LiveDataIndicator from "../LiveDataIndicator/LiveDataIndicator";
import { getAstronautDetails } from "./actions";

function AstronautPopup({
  farmId,
  closePopupClickFn,
  callToActionClickFn,
  seeHistoryClickFn,
  seeLatestClickFn,
  batchCows,
  milkingNotification,
  isAdultSelected,
}) {
  const { milkedCowDetails } = useSelector((state) => state.liveDataDetails);
  const { astronautDetails, isAstronautDetailsError } = useSelector(
    (state) => state.astronautDetails,
  );
  console.log("milkedCowDetails:", milkedCowDetails);
  const firstCowInTheList =
    batchCows && batchCows[0] && batchCows[0].externalId.trim();
  const dispatch = useDispatch();
  const lastCowMilkingNotification = milkingNotification?.slice(-1);
  console.log("milkingNotificationaaaa", milkingNotification);
  useEffect(() => {
    //let selectedCowId = selectedCowDetails.externalId;
    dispatch(getAstronautDetails(farmId, firstCowInTheList));
  }, []);

  useEffect(() => {}, [astronautDetails]);

  return (
    <div className="astronautPopup">
      {!_.isEmpty(milkedCowDetails) ? (
        <LiveDataIndicator cowDetails={milkedCowDetails[0]} />
      ) : null}
      <div className="astronautPopup--container">
        <IconButton
        cssClassName="astronautPopup--btnClosePopup"
        onClickFn={closePopupClickFn}
        />
      <div className="astronautPopup--title">
        {translate("COW_ASTRONAUT_TITLE")}
      </div>
      <div className="astronautPopup--description">
        {translate("COW_ASTRONAUT_DESCRIPTION_TEXT")}
      </div> 
      <div className="astronautPopup--buttons">
        {!isAstronautDetailsError &&
        !_.isEmpty(astronautDetails) &&
        milkingNotification &&
        milkingNotification.length > 0 ? (
          <CallToActionButton
            cssClassName="astronautPopupLiveData--btnCallToAction"
            isAdultSelected={isAdultSelected}
            label={translate("MILK_FACTORY_POPUP_SEE_LIVE_DATA_BUTTON_LABEL")}
            onClickFn={() => {
              seeHistoryClickFn(milkedCowDetails);
              closePopupClickFn();
            }}
          />
        ) : null}
        <CallToActionButton
          cssClassName="astronautPopup--btnCallToAction"
          isAdultSelected={isAdultSelected}
          label={translate("ASTRONAUT_CALL_TO_ACTION_BUTTON")}
          onClickFn={() => {
            seeLatestClickFn(milkedCowDetails);
            closePopupClickFn();
          }}
        />
      </div>
      </div>
      
      
      <Graphic cssClassName="astronautPopup--astronaut" />
      
    </div>
  );
}

export default AstronautPopup;
