import React from "react";
import "./FourthSlideVector.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const FourthSlideVector = ({}) => {
  return (
    <div className="vectorStoryFourthSlide">
      <TextCaption additionalCssClassname="vectorStoryFourthSlide--textCaption">
        <div className="vectorStoryFourthSlide--title">
          {translate("VECTOR_FOURTH_SLIDE_TITLE")}
        </div>
        <div className="vectorStoryFourthSlide--text">
          {translate("VECTOR_FOURTH_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="vectorStoryFourthSlide--imgCaption">
        <div className="vectorStoryFourthSlide--imgContainer"></div>
      </div> 
      <div className="vectorStoryFourthSlide--treeForAdultVersion"></div>
    </div>
  );
};

export default FourthSlideVector;
