import React from "react";
import "./MijnMelk.scss";

/** MijnMelk component */
function MijnMelk({
  mijnMelkData,
  mijnMelkCssClassname,
  mijnMelkClickHandlerFn,
}) {
  return (
    <div
      className="mijnMelk"
      onClick={() => {
        mijnMelkClickHandlerFn(mijnMelkData);
      }}
    ></div>
  );
}
export default MijnMelk;
