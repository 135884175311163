import useOrientationChange from "../../hooks/useOrientationChange";
import useWindowDimensions from "../../hooks/useViewportDimensions";
import React, { useEffect, useRef } from "react";

function MatrixColumn({
  cssClassName,
  columnWidthInPixels,
  timeout = 50,
  numberOfColumns = 1,
}) {
  const canvas = useRef();

  let { width: VIEWPORT_WIDTH_IN_PX, height: VIEWPORT_HEIGHT_IN_PX } =
    useWindowDimensions();
  let isAppOpenedInLandscapeMode = VIEWPORT_WIDTH_IN_PX > VIEWPORT_HEIGHT_IN_PX;
  VIEWPORT_WIDTH_IN_PX = isAppOpenedInLandscapeMode
    ? VIEWPORT_WIDTH_IN_PX
    : VIEWPORT_HEIGHT_IN_PX;
  VIEWPORT_HEIGHT_IN_PX = isAppOpenedInLandscapeMode
    ? VIEWPORT_HEIGHT_IN_PX
    : VIEWPORT_WIDTH_IN_PX;
  const orientationChange = useOrientationChange(() => {});

  useEffect(() => {
    const context = canvas.current.getContext("2d");

    const width = columnWidthInPixels;
    const height = VIEWPORT_HEIGHT_IN_PX;
    canvas.current.width = width;
    canvas.current.height = height;

    context.fillStyle = "#000";
    context.fillRect(0, 0, width, height);

    const columns = numberOfColumns;
    const yPositions = Array.from({ length: columns }).fill(0);

    context.fillStyle = "#000";
    context.fillRect(0, 0, width, height);
    const digitFontSizeInPx = 2.3 * (0.01 * VIEWPORT_HEIGHT_IN_PX);
    context.font = digitFontSizeInPx + "px monospace";

    const matrixEffect = () => {
      context.fillStyle = "#0001";
      context.fillRect(0, 0, width, height);

      context.fillStyle = "#0f0";

      yPositions.forEach((y, index) => {
        const text = Math.round(Math.random());
        const x = index * 10;
        context.fillText(text, x, y);

        if (y > 100 + Math.random() * 10000) {
          yPositions[index] = 0;
        } else {
          yPositions[index] = y + digitFontSizeInPx;
        }
      });
    };

    const interval = setInterval(matrixEffect, timeout);
    return () => {
      clearInterval(interval);
    };
  }, [canvas, timeout, VIEWPORT_HEIGHT_IN_PX]);

  return (
    <div
      style={{
        height: VIEWPORT_HEIGHT_IN_PX,
        width: columnWidthInPixels,
        zIndex: -1,
        top: "0",
      }}
      className={cssClassName}
    >
      <canvas
        ref={canvas}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
}

export default MatrixColumn;
