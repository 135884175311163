import moment from "moment";
import React from "react";
import {
  translate,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import TextCaption from "../TextCaption/TextCaption";
import "./MijnMelkAdultOnboardingSlide1.scss";

function MijnMelkAdultOnboardingSlide1({
  farmName,
  milkBatchDate,
  milkBatchStartDate,
  milkBatchEndDate,
  milkGoingToBottleHour,
  batchId,
  batchProductType,
}) { 
  const monthName = moment(milkBatchDate).format("MMMM");
  const localizedMonthName = translate("MONTH_" + monthName.toUpperCase());
  const dateText = moment(milkBatchDate).format("DD");
  const MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT1 = batchProductType === "Yogurt"
  ? "MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT1_YOGHURT"
  : "MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT1"
  const MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT4 = batchProductType === "Yogurt"
  ? "MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT4_YOGHURT"
  : "MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT4"
  const MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT4_WITHOUT_DATA = batchProductType === "Yogurt"
  ? "MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT4_WITHOUT_DATA_YOGHURT"
  : "MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT4_WITHOUT_DATA"
  return (
    <div>
      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide1-text1">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT1,
              {
                farmName: farmName,
              },
            ),
          }}
        ></span>
      </TextCaption>

      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide1-text2"> 
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT2",
              { milkBatchDate: dateText + ' ' + localizedMonthName },
            ),
          }}
        ></span>
      </TextCaption>

      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide1-text3">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT3",
              {
                milkHour1: moment(milkBatchStartDate).format("hh:mm"),
                milkHour2: moment(milkBatchEndDate).format("hh:mm"),
              },
            ),
          }}
        ></span>
      </TextCaption>
      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide1-text4"> 
      {milkGoingToBottleHour ? 
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT4,
              {
                milkGoingToBottleHour: moment(milkGoingToBottleHour).format(
                  "hh:mm",
                ),
              },
            ),
          }}
        ></span>: 
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              MIJN_MELK_ADULT_ONBOARDING_SLIDE1_TEXT4_WITHOUT_DATA
            ),
          }}
        ></span>}
      </TextCaption>

      <Graphic cssClassName="mijnMelkAdultOnboarding-slide1--temperatureIcon" />
      <div className="mijnMelkAdultOnboarding-slide1--batchArrow"></div>
      <div className="mijnMelkAdultOnboarding-slide1--locationPin"></div>
      <div className="mijnMelkAdultOnboarding-slide1--mijnMelk"></div>
      <div className="mijnMelkAdultOnboarding-slide1--grass"></div>
      <div className="mijnMelkAdultOnboarding-slide1--dataPassportLabel">
        {translate("MIJN_MELK_ADULT_ONBOARDING_SLIDE1_DATA_PASSPORT_LABEL")}
      </div>
      <div className="mijnMelkAdultOnboarding-slide1--batch">
        <span className="mijnMelkAdultOnboarding-slide1--batch--label">
          {translate("MIJN_MELK_ADULT_ONBOARDING_SLIDE1_BATCH_LABEL")}
        </span>
        <span className="mijnMelkAdultOnboarding-slide1--batch--batchID">
          {batchId}
        </span>
      </div>
    </div>
  );
}

export default MijnMelkAdultOnboardingSlide1;
