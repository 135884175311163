export const REQUEST_FARM_FEATURES_PAGE_INFORMATION =
  'REQUEST_FARM_FEATURES_PAGE_INFORMATION';
export const FARM_FEATURES_PAGE_INFORMATION_ERROR =
  'FARM_FEATURES_PAGE_INFORMATION_ERROR';
export const FARM_FEATURES_PAGE_INFORMATION_SUCCESS =
  'FARM_FEATURES_PAGE_INFORMATION_SUCCESS';
export const SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE =
  'SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE';
export const SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE_SUCCESS =
  'SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE_SUCCESS';
export const SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE_ERROR =
  'SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE_ERROR';
