export const HELP_INFORMATION_COMPONENTS = [
  {
    title: `Overview `, 
    text: `Users should be able to login to the Sense application with valid username and password.

    Any user that has assigned robot(s) listed in farms should be able to navigate to a chosen robot and see a robot overview page containing tabs for overview, domains, alarms, events, logs, settings for that robot.
    
    When the user visits the robot overview page, the overview tab shall be selected by default. The overview tab shall contain a  list of the last 10 events and 10 alarms for the selected robot. 
    
    When the user selects a robot that has no events or alarms a label shall be displayed that notifies the user that the selected robot has no events/alarms.
    `,
    status: 1,
  },
  {
    title: `Domains`,
    text: `Any user should be able to choose a robot that is assigned to him and select domains tab the user shall see links for canbus and fluids domains. 

    When the user clicks on the canbus/fluids domain link the user shall see graphical representation of the components of that domain as well as colors representing their state. 
    
    Each component shall have a label denoting the component’s current state. When the user selects a component the user shall see details about the selected component. 
    Components colour schema is as follows:
    For ThreeWayValveStates the representation of Up/Down state is a combination of arrow and color icon.

    Each domain shall have a time selection input for which the default time period is the current time. When the user updates the value of the time selection input the domain page shall update the states of the components as well as the details data for the components based on the data for the selected time. When the user updates the value of the time selection input with timestamp for which there is no data then all components shall be in default state(greyed-out) and no data shall be available in the details for the components.
    `,
    status: 2,
  },
  {
    title: `Alarms`,
    text: `Any user should be able to navigate to the alarms of a robot that is assigned to him where he can see a paginated table listing all alarms for that robot and a time range selection input that allows for the filtration of the list of alarms.

    The default time range selection is 7 days past from the current date till the current date. When the user selects a robot for which there were no alarms in the past seven days and selects alarms tab a message shall be displayed that no alarms are found. When the user selects a time range for which there are no alarms  a message shall be displayed that no alarms are found.
    
    The paginated table listing shall contain four columns for Date, Level, Message and Name of the alarm. Each of the table rows contains a context-sensitive link to Simplicity logs and links to Canbus and Fluids domains page with the alarm-specific moment in time (concrete timestamp). The paginated table listing shall show 10 rows per page by default, however options for 25 and 50 rows per page shall be also available. 
    
    When the user clicks on a row(alarm) the user shall see all the parameters for that alarm in tabular format and the message of that alarm.
    
    When the user clicks on the go to Canbus/Fluids domain link the user shall be directed to the domains tab graphical representation of the components of Canbus/Fluid domain with the page locked to the specific point in time in which the alarm occurred.
    
    When the user clicks on the Simplicity context-sensitive link for a selected alarm then the user gets the Simplicity system opened in another tab in the browser pointing to the time range of the selected alarm (+/- 10 mins around the concrete timestamp of the alarm). Users will be first asked to login Simplicity application before they can access the data.    
    `,
    status: 3,
  },
  {
    title: `Events`,
    text: `Any user should be able to navigate to the alarms of a robot that is assigned to him where he can see a paginated table listing all events for that robot and a time range selection input that allows for the filtration of the list of events.

    The default time range selection is 7 days past from the current date till the current date. When the user selects a robot for which there were no events in the past seven days and selects events tab a message shall be displayed that no events are found. When the user selects a time range for which there are no events a message shall be displayed that no events are found.
    
    The paginated table listing shall contain four columns for Date, Level, Message and Name of the event. Each of the table rows contains a context-sensitive link to Simplicity logs and links to Canbus and Fluids domains page with the event-specific moment in time (concrete timestamp). The paginated table listing shall show 10 rows per page by default, however options for 25 and 50 rows per page shall be also available. 
    
    When the user clicks on a row(event) the user shall see all the parameters for that event in tabular format and the message of that event.
    
    When the user clicks on the go to Canbus/Fluids domain link the user shall be directed to the domains tab graphical representation of the components of Canbus/Fluid domain with the page locked to the specific point in time in which the event occurred.
    
    When the user clicks on the Simplicity context-sensitive link for a selected event then the user gets the Simplicity system opened in another tab in the browser pointing to the time range of the selected event(+/- 10 mins around the concrete timestamp of the event).  Users will be first asked to login Simplicity application before they can access the data.    
    `,
    status: 4,
  },
  {
    title: `Logs`,
    text: ``,
    status: 5,
  },
  {
    title: `Settings`,
    text: ``,
    status: 6,
  }
];