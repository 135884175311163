import { stubTrue } from "lodash";

export const MOCK_GET_VIRTUAL_FARM_RESPONSE = {
  data: {
    farmName: {
      ens: "De Dobbelhoeve",
      nl: "Dobbelhoeve",
      en: "De Dobbelhoeve",
    },
    grazingData: { flag: true, dateFrom: "04/04/2022", dateTo: "10/19/2022" },
    otherDevices: [
      {
        name: "grazeway",
        type: "other device",
        clickable: true,
        description: null,
      },
      {
        name: "discovery",
        type: "other device",
        clickable: true,
        description: null,
      },
      {
        name: "calm",
        type: "other device",
        clickable: true,
        description: null,
      },
      {
        name: "vector",
        type: "other device",
        clickable: true,
        description: null,
      },
      {
        name: "astronaut",
        type: "other device",
        clickable: true,
        description: null,
      },
    ],
    vehicle: {
      type: "Tractor",
      flag: true,
      description: null,
      mainColor: "#3725FF",
      lineColor: "#E8E543",
      imageData: null,
    },
    breeds: [
      {
        name: "mixed",
        isSelected: false,
        probabilityPercentage: "0",
        colors: [
          { name: "maasRijn-brown", isSelected: false },
          { name: "holstein-black", isSelected: false },
          { name: "holstein-brown", isSelected: true },
          { name: "holstein-rainbow", isSelected: true },
          { name: "holstein-white", isSelected: false },
          { name: "jersey-brown", isSelected: false },
          { name: "jersey-white", isSelected: false },
          { name: "blaarkop-black", isSelected: false },
          { name: "blaarkop-brown", isSelected: false },
          { name: "lakenvelder-black", isSelected: false },
          { name: "lakenvelder-brown", isSelected: false },
        ],
      },
      {
        name: "maasRijn",
        isSelected: false,
        probabilityPercentage: "0",
        colors: [{ name: "maasRijn-brown", isSelected: false }],
      },
      {
        name: "lakenvelder",
        isSelected: false,
        probabilityPercentage: "0",
        colors: [
          { name: "lakenvelder-black", isSelected: false },
          { name: "lakenvelder-brown", isSelected: false },
        ],
      },
      {
        name: "blaarkop",
        isSelected: false,
        probabilityPercentage: "0",
        colors: [
          { name: "blaarkop-black", isSelected: false },
          { name: "blaarkop-brown", isSelected: false },
        ],
      },
      {
        name: "jersey",
        isSelected: false,
        probabilityPercentage: "0",
        colors: [
          { name: "jersey-brown", isSelected: false },
          { name: "jersey-white", isSelected: false },
        ],
      },
      {
        name: "holstein",
        isSelected: true,
        probabilityPercentage: "100",
        colors: [
          { name: "holstein-rainbow", isSelected: false },
          { name: "holstein-white", isSelected: false },
          { name: "holstein-brown", isSelected: true },
          { name: "holstein-black", isSelected: true },
        ],
      },
    ],
    allCows: [
      {
        type: "Cow",
        name: null,
        externalId: "IT 019500393890",
        birthTimestamp: null,
        id: 1610,
      },
      {
        type: "Cow",
        name: "Kitty 55",
        externalId: "NL 882742869",
        birthTimestamp: "2020-02-08T00:00:00",
        id: 2092,
      },
      {
        type: "Cow",
        name: "KITTY 51",
        externalId: "NL 639636953",
        birthTimestamp: "2014-11-20T00:00:00",
        id: 1611,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 520359307",
        birthTimestamp: null,
        id: 1903,
      },
      {
        type: "Cow",
        name: "KITTY 47",
        externalId: "NL 898036329",
        birthTimestamp: "2012-09-02T00:00:00",
        id: 1892,
      },
      {
        type: "Cow",
        name: "Kitty 53",
        externalId: "NL 724138380",
        birthTimestamp: "2016-09-20T00:00:00",
        id: 1629,
      },
      {
        type: "Cow",
        name: "Kitty 100",
        externalId: "NL 572443777",
        birthTimestamp: "2021-09-21T00:00:00",
        id: 1612,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 642041711",
        birthTimestamp: "2018-08-04T00:00:00",
        id: 2113,
      },
      {
        type: "Cow",
        name: "MOEVCHEN 31",
        externalId: "NL 724138032",
        birthTimestamp: "2015-08-14T00:00:00",
        id: 1644,
      },
      {
        type: "Cow",
        name: "MOEVCHEN 22",
        externalId: "NL 355928538",
        birthTimestamp: null,
        id: 6517,
      },
      {
        type: "Cow",
        name: "Moevchen 33",
        externalId: "NL 712239208",
        birthTimestamp: "2018-01-01T00:00:00",
        id: 1645,
      },
      {
        type: "Cow",
        name: "Mira 4680",
        externalId: "DE 0537194680",
        birthTimestamp: "2013-08-09T00:00:00",
        id: 1658,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DE 0354165497",
        birthTimestamp: null,
        id: 7099,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DE 0580586454",
        birthTimestamp: null,
        id: 7098,
      },
      {
        type: "Cow",
        name: "MIRA 4681",
        externalId: "NL 657643159",
        birthTimestamp: "2021-09-06T00:00:00",
        id: 1659,
      },
      {
        type: "Cow",
        name: "LINDA 85",
        externalId: "NL 882165631",
        birthTimestamp: "2013-10-12T00:00:00",
        id: 1671,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 440161437",
        birthTimestamp: null,
        id: 7073,
      },
      {
        type: "Cow",
        name: "LINDA 88",
        externalId: "NL 724138094",
        birthTimestamp: "2015-12-14T00:00:00",
        id: 1713,
      },
      {
        type: "Cow",
        name: "LINDA 89",
        externalId: "NL 724138397",
        birthTimestamp: "2016-11-28T00:00:00",
        id: 1672,
      },
      {
        type: "Cow",
        name: "Linda 93",
        externalId: "NL 882742821",
        birthTimestamp: "2020-02-26T00:00:00",
        id: 2149,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 470938683",
        birthTimestamp: null,
        id: 1686,
      },
      {
        type: "Cow",
        name: "Boukje 102",
        externalId: "NL 712239222",
        birthTimestamp: "2018-01-15T00:00:00",
        id: 1849,
      },
      {
        type: "Cow",
        name: "Linda 91",
        externalId: "NL 733439982",
        birthTimestamp: "2018-02-05T00:00:00",
        id: 1687,
      },
      {
        type: "Cow",
        name: "Linda 98",
        externalId: "NL 572443830",
        birthTimestamp: "2021-12-29T00:00:00",
        id: 1796,
      },
      {
        type: "Cow",
        name: "COOTJE 13",
        externalId: "NL 724138157",
        birthTimestamp: "2016-03-19T00:00:00",
        id: 1699,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 713398841",
        birthTimestamp: null,
        id: 1893,
      },
      {
        type: "Cow",
        name: "Cootje 5",
        externalId: "NL 496734254",
        birthTimestamp: null,
        id: 6925,
      },
      {
        type: "Cow",
        name: "Cootje 16",
        externalId: "NL 733440054",
        birthTimestamp: "2018-07-26T00:00:00",
        id: 1700,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 572444516",
        birthTimestamp: "2022-03-16T00:00:00",
        id: 11866,
      },
      {
        type: "Cow",
        name: "LINDA 94",
        externalId: "NL 882742775",
        birthTimestamp: "2020-05-23T00:00:00",
        id: 2321,
      },
      {
        type: "Cow",
        name: "IVA 4",
        externalId: "NL 639636960",
        birthTimestamp: "2014-11-23T00:00:00",
        id: 1725,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 429759583",
        birthTimestamp: null,
        id: 1916,
      },
      {
        type: "Cow",
        name: "IVA 3",
        externalId: "NL 898036040",
        birthTimestamp: null,
        id: 6988,
      },
      {
        type: "Cow",
        name: "Iva 5",
        externalId: "NL 733440030",
        birthTimestamp: "2018-07-03T00:00:00",
        id: 1726,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 394346135",
        birthTimestamp: null,
        id: 1739,
      },
      {
        type: "Cow",
        name: "AUKJE 98",
        externalId: "NL 724138195",
        birthTimestamp: "2016-06-10T00:00:00",
        id: 1740,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 519550898",
        birthTimestamp: null,
        id: 2470,
      },
      {
        type: "Cow",
        name: "AUKJE 89",
        externalId: "NL 898036064",
        birthTimestamp: null,
        id: 6775,
      },
      {
        type: "Cow",
        name: "Aukje 107",
        externalId: "NL 657642961",
        birthTimestamp: "2020-10-28T00:00:00",
        id: 1741,
      },
      {
        type: "Cow",
        name: "Marion 214",
        externalId: "NL 733440148",
        birthTimestamp: "2019-05-13T00:00:00",
        id: 1752,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 418576762",
        birthTimestamp: null,
        id: 6546,
      },
      {
        type: "Cow",
        name: "MARION 211",
        externalId: "NL 727573205",
        birthTimestamp: null,
        id: 6545,
      },
      {
        type: "Cow",
        name: "Marion 215",
        externalId: "NL 572443847",
        birthTimestamp: "2022-01-04T00:00:00",
        id: 1753,
      },
      {
        type: "Cow",
        name: "KOEAN MAR 38",
        externalId: "NL 712239114",
        birthTimestamp: "2017-08-24T00:00:00",
        id: 1766,
      },
      {
        type: "Cow",
        name: "HAVEP JACCO",
        externalId: "NL 873159694",
        birthTimestamp: null,
        id: 1836,
      },
      {
        type: "Cow",
        name: "HAVEP JACCO",
        externalId: "NL 724137969",
        birthTimestamp: null,
        id: 6633,
      },
      {
        type: "Cow",
        name: "KOEAN MAR 39",
        externalId: "NL 572443816",
        birthTimestamp: "2021-12-25T00:00:00",
        id: 1768,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 932421854",
        birthTimestamp: null,
        id: 1767,
      },
      {
        type: "Cow",
        name: "Aukje 110",
        externalId: "NL 657643065",
        birthTimestamp: "2021-03-16T00:00:00",
        id: 1951,
      },
      {
        type: "Cow",
        name: "Kitty",
        externalId: "NL 733440441",
        birthTimestamp: "2018-01-06T00:00:00",
        id: 1781,
      },
      {
        type: "Cow",
        name: "Kitty 98",
        externalId: "NL 657642978",
        birthTimestamp: "2020-11-20T00:00:00",
        id: 1782,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 543203919",
        birthTimestamp: null,
        id: 1795,
      },
      {
        type: "Cow",
        name: "SEMOULE 21",
        externalId: "NL 655090942",
        birthTimestamp: "2020-02-17T00:00:00",
        id: 1810,
      },
      {
        type: "Cow",
        name: null,
        externalId: "FR 3803078751",
        birthTimestamp: null,
        id: 7018,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 649285695",
        birthTimestamp: null,
        id: 7030,
      },
      {
        type: "Cow",
        name: "SEMOULE 22",
        externalId: "NL 572443861",
        birthTimestamp: "2022-01-08T00:00:00",
        id: 1811,
      },
      {
        type: "Cow",
        name: "Siamoise 6",
        externalId: "NL 655090810",
        birthTimestamp: "2019-12-23T00:00:00",
        id: 1823,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 649285587",
        birthTimestamp: null,
        id: 7017,
      },
      {
        type: "Cow",
        name: "SIAMOISE 7",
        externalId: "NL 572443854",
        birthTimestamp: "2022-01-06T00:00:00",
        id: 1824,
      },
      {
        type: "Cow",
        name: "HAVEP JACCO",
        externalId: "NL 724138018",
        birthTimestamp: null,
        id: 1835,
      },
      {
        type: "Cow",
        name: "Nelie 18",
        externalId: "NL 712239099",
        birthTimestamp: "2017-07-04T00:00:00",
        id: 1837,
      },
      {
        type: "Cow",
        name: "Roesveld 27",
        externalId: "NL 712239075",
        birthTimestamp: "2017-07-01T00:00:00",
        id: 2253,
      },
      {
        type: "Cow",
        name: "NELIE 19",
        externalId: "NL 882742922",
        birthTimestamp: "2019-09-15T00:00:00",
        id: 1861,
      },
      {
        type: "Cow",
        name: "BOUKJE 98",
        externalId: "NL 639636890",
        birthTimestamp: "2014-10-04T00:00:00",
        id: 1848,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 383729572",
        birthTimestamp: null,
        id: 7024,
      },
      {
        type: "Cow",
        name: "NELIE 20",
        externalId: "NL 657643142",
        birthTimestamp: "2021-09-05T00:00:00",
        id: 2409,
      },
      {
        type: "Cow",
        name: "Elli 2",
        externalId: "NL 655090740",
        birthTimestamp: "2019-09-29T00:00:00",
        id: 1872,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DK 03800004318",
        birthTimestamp: null,
        id: 6901,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 649285431",
        birthTimestamp: null,
        id: 7093,
      },
      {
        type: "Cow",
        name: "Elli 1",
        externalId: "NL 572443760",
        birthTimestamp: "2021-09-18T00:00:00",
        id: 1873,
      },
      {
        type: "Cow",
        name: "Aukje 101",
        externalId: "NL 712239183",
        birthTimestamp: "2017-11-29T00:00:00",
        id: 1882,
      },
      {
        type: "Cow",
        name: "AUKJE 92",
        externalId: "NL 898036141",
        birthTimestamp: "2012-01-30T00:00:00",
        id: 1915,
      },
      {
        type: "Cow",
        name: "Aukje 108",
        externalId: "NL 657642985",
        birthTimestamp: "2020-11-23T00:00:00",
        id: 1883,
      },
      {
        type: "Cow",
        name: "KITTY 43",
        externalId: "NL 296121984",
        birthTimestamp: null,
        id: 6994,
      },
      {
        type: "Cow",
        name: "Kitty 56",
        externalId: "NL 882742807",
        birthTimestamp: "2020-04-21T00:00:00",
        id: 1894,
      },
      {
        type: "Cow",
        name: "Kitty 103",
        externalId: "NL 572443979",
        birthTimestamp: "2022-05-20T00:00:00",
        id: 12087,
      },
      {
        type: "Cow",
        name: "AUKJE 81",
        externalId: "NL 428332662",
        birthTimestamp: null,
        id: 6793,
      },
      {
        type: "Cow",
        name: "Aukje 102",
        externalId: "NL 712239190",
        birthTimestamp: "2017-11-29T00:00:00",
        id: 1917,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 725399755",
        birthTimestamp: null,
        id: 1927,
      },
      {
        type: "Cow",
        name: "Aukje 103",
        externalId: "NL 733440100",
        birthTimestamp: "2019-03-19T00:00:00",
        id: 1940,
      },
      {
        type: "Cow",
        name: "Aukje 105",
        externalId: "NL 882742876",
        birthTimestamp: "2019-11-19T00:00:00",
        id: 1928,
      },
      {
        type: "Cow",
        name: "Aukje 22",
        externalId: "NL 639637949",
        birthTimestamp: "2014-06-16T00:00:00",
        id: 1939,
      },
      {
        type: "Cow",
        name: "Aukje 14",
        externalId: "NL 532435051",
        birthTimestamp: null,
        id: 6934,
      },
      {
        type: "Cow",
        name: "aukje 105",
        externalId: "NL 712239020",
        birthTimestamp: "2017-02-11T00:00:00",
        id: 1989,
      },
      {
        type: "Cow",
        name: "HAVEP JACCO",
        externalId: "NL 724137983",
        birthTimestamp: "2015-10-08T00:00:00",
        id: 1963,
      },
      {
        type: "Cow",
        name: "BOUKJE 95",
        externalId: "NL 752636571",
        birthTimestamp: "2013-08-01T00:00:00",
        id: 2245,
      },
      {
        type: "Cow",
        name: "BOUKJE 101",
        externalId: "NL 712239169",
        birthTimestamp: "2017-09-27T00:00:00",
        id: 1964,
      },
      {
        type: "Cow",
        name: "Kitty 36",
        externalId: "NL 724138102",
        birthTimestamp: "2016-01-02T00:00:00",
        id: 1975,
      },
      {
        type: "Cow",
        name: "Kitty 34",
        externalId: "NL 752636519",
        birthTimestamp: null,
        id: 6715,
      },
      {
        type: "Cow",
        name: "Kitty 99",
        externalId: "NL 657643072",
        birthTimestamp: "2021-04-07T00:00:00",
        id: 1976,
      },
      {
        type: "Cow",
        name: "Aukje 104",
        externalId: "NL 733440124",
        birthTimestamp: "2019-04-27T00:00:00",
        id: 1990,
      },
      {
        type: "Cow",
        name: "Aukje 112",
        externalId: "NL 572443878",
        birthTimestamp: "2022-02-09T00:00:00",
        id: 11858,
      },
      {
        type: "Cow",
        name: "MOEVCHEN 24",
        externalId: "NL 532435145",
        birthTimestamp: "2010-07-05T00:00:00",
        id: 2001,
      },
      {
        type: "Cow",
        name: "MOEVCHEN 21",
        externalId: "NL 296122103",
        birthTimestamp: null,
        id: 6736,
      },
      {
        type: "Cow",
        name: "MOEVCHEN 36",
        externalId: "NL 882742799",
        birthTimestamp: "2020-06-07T00:00:00",
        id: 2002,
      },
      {
        type: "Cow",
        name: "AUKJE 93",
        externalId: "NL 752636502",
        birthTimestamp: "2013-06-10T00:00:00",
        id: 2043,
      },
      {
        type: "Cow",
        name: "AUKJE 83",
        externalId: "NL 469933622",
        birthTimestamp: null,
        id: 6536,
      },
      {
        type: "Cow",
        name: "AUKJE 109",
        externalId: "NL 657643058",
        birthTimestamp: "2021-03-06T00:00:00",
        id: 2044,
      },
      {
        type: "Cow",
        name: "Boukje 103",
        externalId: "NL 657642992",
        birthTimestamp: "2020-11-26T00:00:00",
        id: 2055,
      },
      {
        type: "Cow",
        name: "Cootje 100",
        externalId: "NL 724138311",
        birthTimestamp: "2016-08-24T00:00:00",
        id: 2066,
      },
      {
        type: "Cow",
        name: "BOUKJE 91",
        externalId: "NL 898036196",
        birthTimestamp: null,
        id: 2447,
      },
      {
        type: "Cow",
        name: "COOTJE 24",
        externalId: "NL 882742612",
        birthTimestamp: "2020-06-27T00:00:00",
        id: 2067,
      },
      {
        type: "Cow",
        name: "GEA 8",
        externalId: "NL 639637482",
        birthTimestamp: "2015-05-01T00:00:00",
        id: 2080,
      },
      {
        type: "Cow",
        name: "GEA 3",
        externalId: "NL 495634605",
        birthTimestamp: null,
        id: 6507,
      },
      {
        type: "Cow",
        name: "GEA 9",
        externalId: "NL 657643089",
        birthTimestamp: "2021-05-01T00:00:00",
        id: 2081,
      },
      {
        type: "Cow",
        name: "COOTJE 28",
        externalId: "NL 572443962",
        birthTimestamp: "2022-05-19T00:00:00",
        id: 12088,
      },
      {
        type: "Cow",
        name: "AUKJE 106",
        externalId: "NL 882742768",
        birthTimestamp: "2020-04-04T00:00:00",
        id: 2102,
      },
      {
        type: "Cow",
        name: "AUKJE 113",
        externalId: "NL 572443986",
        birthTimestamp: "2022-06-06T00:00:00",
        id: 12153,
      },
      {
        type: "Cow",
        name: "LINDA 95",
        externalId: "NL 657643096",
        birthTimestamp: "2021-05-03T00:00:00",
        id: 2122,
      },
      {
        type: "Cow",
        name: "ROESVELD 26",
        externalId: "NL 712239006",
        birthTimestamp: "2017-02-06T00:00:00",
        id: 2135,
      },
      {
        type: "Cow",
        name: "Moevchen 19",
        externalId: "NL 898036134",
        birthTimestamp: null,
        id: 2426,
      },
      {
        type: "Cow",
        name: "Roesveld 30",
        externalId: "NL 733440092",
        birthTimestamp: "2019-02-24T00:00:00",
        id: 2161,
      },
      {
        type: "Cow",
        name: "Roesveld 28",
        externalId: "NL 882742852",
        birthTimestamp: "2020-02-10T00:00:00",
        id: 2136,
      },
      {
        type: "Cow",
        name: "ROESVELD 31",
        externalId: "NL 657643041",
        birthTimestamp: "2021-02-26T00:00:00",
        id: 2180,
      },
      {
        type: "Cow",
        name: "ROESVELD 25",
        externalId: "NL 724138241",
        birthTimestamp: "2016-07-14T00:00:00",
        id: 2169,
      },
      {
        type: "Cow",
        name: "Moevchen 21",
        externalId: "NL 639636737",
        birthTimestamp: "2014-04-28T00:00:00",
        id: 2349,
      },
      {
        type: "Cow",
        name: "ROESVELD 29",
        externalId: "NL 882742674",
        birthTimestamp: "2020-09-14T00:00:00",
        id: 2170,
      },
      {
        type: "Cow",
        name: "Havep Force",
        externalId: "NL 639636838",
        birthTimestamp: "2014-07-16T00:00:00",
        id: 2190,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 532435176",
        birthTimestamp: null,
        id: 2417,
      },
      {
        type: "Cow",
        name: "Cootje 23",
        externalId: "NL 882742838",
        birthTimestamp: "2020-02-18T00:00:00",
        id: 2213,
      },
      {
        type: "Cow",
        name: "Havep Force",
        externalId: "NL 673968629",
        birthTimestamp: null,
        id: 2191,
      },
      {
        type: "Cow",
        name: "Cootje 25",
        externalId: "NL 657643010",
        birthTimestamp: "2020-12-27T00:00:00",
        id: 2192,
      },
      {
        type: "Cow",
        name: "Moevchen 31",
        externalId: "NL 639636946",
        birthTimestamp: "2014-11-13T00:00:00",
        id: 2202,
      },
      {
        type: "Cow",
        name: "AGNES 1",
        externalId: "NL 530341871",
        birthTimestamp: null,
        id: 6584,
      },
      {
        type: "Cow",
        name: "AGNES 4",
        externalId: "NL 724138373",
        birthTimestamp: "2016-09-11T00:00:00",
        id: 2232,
      },
      {
        type: "Cow",
        name: "Moevchen 38",
        externalId: "NL 657643027",
        birthTimestamp: "2021-01-16T00:00:00",
        id: 2203,
      },
      {
        type: "Cow",
        name: "Ella 6",
        externalId: "NL 724138328",
        birthTimestamp: "2016-08-25T00:00:00",
        id: 2222,
      },
      {
        type: "Cow",
        name: "ELLA 4",
        externalId: "NL 532435075",
        birthTimestamp: null,
        id: 2481,
      },
      {
        type: "Cow",
        name: "Ella 8",
        externalId: "NL 733440131",
        birthTimestamp: "2019-04-29T00:00:00",
        id: 2223,
      },
      {
        type: "Cow",
        name: "BOUKJE 87",
        externalId: "NL 496234420",
        birthTimestamp: null,
        id: 6784,
      },
      {
        type: "Cow",
        name: "HAVEP JACCO",
        externalId: "NL 639637002",
        birthTimestamp: "2015-03-04T00:00:00",
        id: 2252,
      },
      {
        type: "Cow",
        name: "LUZIE 8066",
        externalId: "NL 712239138",
        birthTimestamp: "2017-09-08T00:00:00",
        id: 2262,
      },
      {
        type: "Cow",
        name: "LUZIE 8063",
        externalId: "NL 639636807",
        birthTimestamp: null,
        id: 6674,
      },
      {
        type: "Cow",
        name: "Luzie 8070",
        externalId: "NL 572443784",
        birthTimestamp: "2021-09-28T00:00:00",
        id: 2264,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 669280401",
        birthTimestamp: null,
        id: 2263,
      },
      {
        type: "Cow",
        name: "HAVEP JACCO",
        externalId: "NL 639637514",
        birthTimestamp: "2015-04-30T00:00:00",
        id: 2275,
      },
      {
        type: "Cow",
        name: "KINDE 61",
        externalId: "NL 727573135",
        birthTimestamp: null,
        id: 6611,
      },
      {
        type: "Cow",
        name: "Kinde 63",
        externalId: "NL 712239082",
        birthTimestamp: "2017-07-03T00:00:00",
        id: 2287,
      },
      {
        type: "Cow",
        name: "Kinde 64",
        externalId: "NL 572443791",
        birthTimestamp: "2021-09-29T00:00:00",
        id: 2276,
      },
      {
        type: "Cow",
        name: "Kinde 64",
        externalId: "NL 882742667",
        birthTimestamp: "2020-09-10T00:00:00",
        id: 2299,
      },
      {
        type: "Cow",
        name: "ATJE 63",
        externalId: "NL 712238988",
        birthTimestamp: "2016-12-26T00:00:00",
        id: 2310,
      },
      {
        type: "Cow",
        name: "ATJE 55",
        externalId: "NL 898036211",
        birthTimestamp: null,
        id: 6968,
      },
      {
        type: "Cow",
        name: "Atje 64",
        externalId: "NL 733440078",
        birthTimestamp: "2018-12-19T00:00:00",
        id: 2329,
      },
      {
        type: "Cow",
        name: "Atje 65",
        externalId: "NL 657643034",
        birthTimestamp: "2021-02-12T00:00:00",
        id: 2311,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 633644796",
        birthTimestamp: "2022-09-03T00:00:00",
        id: 12301,
      },
      {
        type: "Cow",
        name: "LINDA 96",
        externalId: "NL 657643128",
        birthTimestamp: "2021-07-08T00:00:00",
        id: 2340,
      },
      {
        type: "Cow",
        name: "Moevchen 37",
        externalId: "NL 882742751",
        birthTimestamp: "2020-10-02T00:00:00",
        id: 2350,
      },
      {
        type: "Cow",
        name: "NELIE 13",
        externalId: "NL 752636425",
        birthTimestamp: "2013-04-05T00:00:00",
        id: 2367,
      },
      {
        type: "Cow",
        name: "NELIE 6",
        externalId: "NL 383730048",
        birthTimestamp: null,
        id: 6869,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 657643483",
        birthTimestamp: "2021-08-22T00:00:00",
        id: 2369,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DE 0953951632",
        birthTimestamp: null,
        id: 2368,
      },
      {
        type: "Cow",
        name: "NELIE 12",
        externalId: "NL 752636362",
        birthTimestamp: "2012-11-27T00:00:00",
        id: 2378,
      },
      {
        type: "Cow",
        name: "NELIE 11",
        externalId: "NL 496434417",
        birthTimestamp: null,
        id: 6875,
      },
      {
        type: "Cow",
        name: "Nelie 20",
        externalId: "NL 882742908",
        birthTimestamp: "2019-10-14T00:00:00",
        id: 2380,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 927733371",
        birthTimestamp: null,
        id: 2379,
      },
      {
        type: "Cow",
        name: "Nelie 21",
        externalId: "NL 572443924",
        birthTimestamp: "2022-02-20T00:00:00",
        id: 11861,
      },
      {
        type: "Cow",
        name: "RESI 1098",
        externalId: "NL 712238995",
        birthTimestamp: "2017-01-19T00:00:00",
        id: 2387,
      },
      {
        type: "Cow",
        name: "RESI 1096",
        externalId: "NL 639636939",
        birthTimestamp: null,
        id: 6666,
      },
      {
        type: "Cow",
        name: "Resi 1099",
        externalId: "NL 882742915",
        birthTimestamp: "2019-10-08T00:00:00",
        id: 2388,
      },
      {
        type: "Cow",
        name: "Cootje 17",
        externalId: "NL 733440061",
        birthTimestamp: "2018-11-06T00:00:00",
        id: 2396,
      },
      {
        type: "Cow",
        name: "Cootje 84",
        externalId: "NL 536010845",
        birthTimestamp: null,
        id: 6852,
      },
      {
        type: "Cow",
        name: "COOTJE 26",
        externalId: "NL 657643135",
        birthTimestamp: "2021-08-28T00:00:00",
        id: 2397,
      },
      {
        type: "Cow",
        name: "BOUKJE 97",
        externalId: "NL 639636869",
        birthTimestamp: "2014-09-05T00:00:00",
        id: 2448,
      },
      {
        type: "Cow",
        name: "MOEVCHEN 32",
        externalId: "NL 712239107",
        birthTimestamp: null,
        id: 2457,
      },
      {
        type: "Cow",
        name: "Cora",
        externalId: "NL 882742061",
        birthTimestamp: "2019-10-21T00:00:00",
        id: 2458,
      },
      {
        type: "Cow",
        name: "Cora 2",
        externalId: "NL 572443900",
        birthTimestamp: "2022-02-17T00:00:00",
        id: 11865,
      },
      {
        type: "Cow",
        name: "CLARA 443",
        externalId: "NL 752636393",
        birthTimestamp: null,
        id: 2469,
      },
      {
        type: "Cow",
        name: "CLARA 444",
        externalId: "NL 724138164",
        birthTimestamp: "2016-03-20T00:00:00",
        id: 2471,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 572444523",
        birthTimestamp: "2022-03-17T00:00:00",
        id: 11860,
      },
      {
        type: "Cow",
        name: "GEA 5",
        externalId: "NL 898036172",
        birthTimestamp: null,
        id: 2489,
      },
      {
        type: "Cow",
        name: "GEA 7",
        externalId: "NL 639637545",
        birthTimestamp: "2015-05-03T00:00:00",
        id: 2490,
      },
      {
        type: "Cow",
        name: "GEA 10",
        externalId: "NL 572443917",
        birthTimestamp: "2022-02-18T00:00:00",
        id: 11857,
      },
      {
        type: "Cow",
        name: "COOTJE 8",
        externalId: "NL 752636386",
        birthTimestamp: null,
        id: 2501,
      },
      {
        type: "Cow",
        name: "COOTJE 11",
        externalId: "NL 639637026",
        birthTimestamp: "2015-04-08T00:00:00",
        id: 2503,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 936741600",
        birthTimestamp: null,
        id: 2502,
      },
      {
        type: "Cow",
        name: "LUZIE 8062",
        externalId: "NL 639636799",
        birthTimestamp: null,
        id: 2520,
      },
      {
        type: "Cow",
        name: "Luzie 8069",
        externalId: "NL 733439975",
        birthTimestamp: "2018-02-01T00:00:00",
        id: 2522,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 878742754",
        birthTimestamp: null,
        id: 2521,
      },
      {
        type: "Cow",
        name: "HAVEP JACCO",
        externalId: "NL 724138049",
        birthTimestamp: null,
        id: 2532,
      },
      {
        type: "Cow",
        name: "Luzie 8068",
        externalId: "NL 712239176",
        birthTimestamp: "2017-10-11T00:00:00",
        id: 2533,
      },
      {
        type: "Cow",
        name: "BETSIE 2",
        externalId: "NL 469933552",
        birthTimestamp: null,
        id: 2543,
      },
      {
        type: "Cow",
        name: "BETSIE 5",
        externalId: "NL 639636814",
        birthTimestamp: "2014-07-09T00:00:00",
        id: 2544,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 572444237",
        birthTimestamp: "2022-05-20T00:00:00",
        id: 12089,
      },
      {
        type: "Cow",
        name: "ELLA 7",
        externalId: "NL 712239121",
        birthTimestamp: null,
        id: 6498,
      },
      {
        type: "Cow",
        name: "Ella 9",
        externalId: "NL 882742883",
        birthTimestamp: "2019-11-13T00:00:00",
        id: 6499,
      },
      {
        type: "Cow",
        name: "Marion 213",
        externalId: "NL 733440023",
        birthTimestamp: "2018-05-06T00:00:00",
        id: 6564,
      },
      {
        type: "Cow",
        name: "FRISIA 387",
        externalId: "NL 752636401",
        birthTimestamp: null,
        id: 6573,
      },
      {
        type: "Cow",
        name: "FRISIA 392",
        externalId: "NL 882742782",
        birthTimestamp: "2020-06-01T00:00:00",
        id: 6574,
      },
      {
        type: "Cow",
        name: "Cootje 35",
        externalId: "NL 752636456",
        birthTimestamp: null,
        id: 6594,
      },
      {
        type: "Cow",
        name: "Dobbelhoeve 39",
        externalId: "NL 724138119",
        birthTimestamp: "2016-02-13T00:00:00",
        id: 6604,
      },
      {
        type: "Cow",
        name: "Dobbelhoeve 41",
        externalId: "NL 733440016",
        birthTimestamp: "2018-04-16T00:00:00",
        id: 6595,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 440127509",
        birthTimestamp: null,
        id: 6621,
      },
      {
        type: "Cow",
        name: "Cootje 18",
        externalId: "NL 733440117",
        birthTimestamp: "2019-03-29T00:00:00",
        id: 6622,
      },
      {
        type: "Cow",
        name: "GILDA 5",
        externalId: "NL 639636751",
        birthTimestamp: null,
        id: 6645,
      },
      {
        type: "Cow",
        name: "Gilda 8",
        externalId: "NL 712239152",
        birthTimestamp: "2017-09-18T00:00:00",
        id: 6646,
      },
      {
        type: "Cow",
        name: "BETSIE 4",
        externalId: "NL 639636698",
        birthTimestamp: null,
        id: 6656,
      },
      {
        type: "Cow",
        name: "Betsie 7",
        externalId: "NL 733440009",
        birthTimestamp: "2018-03-18T00:00:00",
        id: 6657,
      },
      {
        type: "Cow",
        name: "REGGY 9746",
        externalId: "NL 536010458",
        birthTimestamp: null,
        id: 6681,
      },
      {
        type: "Cow",
        name: "Reggy 1086",
        externalId: "NL 724138296",
        birthTimestamp: "2016-08-14T00:00:00",
        id: 6682,
      },
      {
        type: "Cow",
        name: "OLGA",
        externalId: "DE 0940063907",
        birthTimestamp: null,
        id: 6690,
      },
      {
        type: "Cow",
        name: "Moevchen 3",
        externalId: "NL 752636494",
        birthTimestamp: "2013-05-30T00:00:00",
        id: 6692,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 970178277",
        birthTimestamp: null,
        id: 6691,
      },
      {
        type: "Cow",
        name: "AUKJE 95",
        externalId: "NL 752636595",
        birthTimestamp: null,
        id: 6704,
      },
      {
        type: "Cow",
        name: "AUKJE 111",
        externalId: "NL 657643111",
        birthTimestamp: "2021-07-03T00:00:00",
        id: 6705,
      },
      {
        type: "Cow",
        name: "LUZIE 8059",
        externalId: "NL 428332516",
        birthTimestamp: null,
        id: 6726,
      },
      {
        type: "Cow",
        name: "LUZIE 8061",
        externalId: "NL 752636540",
        birthTimestamp: "2013-07-09T00:00:00",
        id: 6727,
      },
      {
        type: "Cow",
        name: "ATJE 58",
        externalId: "NL 752636603",
        birthTimestamp: null,
        id: 6745,
      },
      {
        type: "Cow",
        name: "ATJE 62",
        externalId: "NL 724137990",
        birthTimestamp: "2015-10-12T00:00:00",
        id: 6746,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 572444181",
        birthTimestamp: "2022-04-26T00:00:00",
        id: 12058,
      },
      {
        type: "Cow",
        name: "RITA 3",
        externalId: "NL 530341840",
        birthTimestamp: null,
        id: 6753,
      },
      {
        type: "Cow",
        name: "Kitty 4",
        externalId: "NL 639636977",
        birthTimestamp: "2014-11-25T00:00:00",
        id: 6754,
      },
      {
        type: "Cow",
        name: "DOBBELHOEVE 17",
        externalId: "NL 383729402",
        birthTimestamp: null,
        id: 6764,
      },
      {
        type: "Cow",
        name: "Cootje 24",
        externalId: "NL 469933660",
        birthTimestamp: "2008-02-10T00:00:00",
        id: 6765,
      },
      {
        type: "Cow",
        name: "Cootje 22",
        externalId: "NL 882742845",
        birthTimestamp: "2020-02-12T00:00:00",
        id: 6883,
      },
      {
        type: "Cow",
        name: "RESI 1095",
        externalId: "NL 536010953",
        birthTimestamp: null,
        id: 6804,
      },
      {
        type: "Cow",
        name: "Kitty 97",
        externalId: "NL 724138056",
        birthTimestamp: "2015-08-21T00:00:00",
        id: 6805,
      },
      {
        type: "Cow",
        name: "Kitty 101",
        externalId: "NL 572443948",
        birthTimestamp: "2022-03-05T00:00:00",
        id: 11864,
      },
      {
        type: "Cow",
        name: "ANNIE 18",
        externalId: "NL 532435284",
        birthTimestamp: null,
        id: 6817,
      },
      {
        type: "Cow",
        name: "ANNIE 22",
        externalId: "NL 712239013",
        birthTimestamp: "2017-02-07T00:00:00",
        id: 6818,
      },
      {
        type: "Cow",
        name: "LINDA 86",
        externalId: "NL 882165718",
        birthTimestamp: "2013-10-29T00:00:00",
        id: 6827,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DE 0113878473",
        birthTimestamp: null,
        id: 7128,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 523862574",
        birthTimestamp: null,
        id: 7127,
      },
      {
        type: "Cow",
        name: "LINDA 87",
        externalId: "NL 724138087",
        birthTimestamp: "2015-12-01T00:00:00",
        id: 6829,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 429467307",
        birthTimestamp: null,
        id: 6828,
      },
      {
        type: "Cow",
        name: "Linda 92",
        externalId: "NL 733439999",
        birthTimestamp: "2018-03-09T00:00:00",
        id: 6836,
      },
      {
        type: "Cow",
        name: "1048 Naomi 1048",
        externalId: "NL 536010489",
        birthTimestamp: null,
        id: 6844,
      },
      {
        type: "Cow",
        name: "NAOMI 1325",
        externalId: "NL 680613255",
        birthTimestamp: "2013-09-16T00:00:00",
        id: 6846,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 536010768",
        birthTimestamp: null,
        id: 6845,
      },
      {
        type: "Cow",
        name: "Demi",
        externalId: "DE 0943932264",
        birthTimestamp: "2009-08-03T00:00:00",
        id: 6860,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DE 0939356285",
        birthTimestamp: null,
        id: 7120,
      },
      {
        type: "Cow",
        name: "Demi 1",
        externalId: "NL 882742814",
        birthTimestamp: "2020-03-30T00:00:00",
        id: 6862,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 930656562",
        birthTimestamp: null,
        id: 6861,
      },
      {
        type: "Cow",
        name: "REGGY 1083",
        externalId: "NL 536010838",
        birthTimestamp: "2011-09-27T00:00:00",
        id: 6892,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 385038533",
        birthTimestamp: null,
        id: 7083,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 481505878",
        birthTimestamp: null,
        id: 7082,
      },
      {
        type: "Cow",
        name: "REGGY 1087",
        externalId: "NL 657643104",
        birthTimestamp: "2021-06-18T00:00:00",
        id: 6893,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 709873480",
        birthTimestamp: null,
        id: 6900,
      },
      {
        type: "Cow",
        name: "COR 885",
        externalId: "NL 655090733",
        birthTimestamp: "2019-09-24T00:00:00",
        id: 6902,
      },
      {
        type: "Cow",
        name: "BLACKROSE 3 F2",
        externalId: "NL 655090726",
        birthTimestamp: "2019-09-23T00:00:00",
        id: 7089,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 633644811",
        birthTimestamp: "2022-09-08T00:00:00",
        id: 12320,
      },
      {
        type: "Cow",
        name: "KOEAN MAR 37",
        externalId: "NL 712238964",
        birthTimestamp: "2016-12-22T00:00:00",
        id: 6909,
      },
      {
        type: "Cow",
        name: "Koean Mar 40",
        externalId: "NL 572443955",
        birthTimestamp: "2022-05-05T00:00:00",
        id: 12066,
      },
      {
        type: "Cow",
        name: "Cootje 33",
        externalId: "NL 898036350",
        birthTimestamp: null,
        id: 6916,
      },
      {
        type: "Cow",
        name: "Cootje 19",
        externalId: "NL 882742953",
        birthTimestamp: "2019-08-13T00:00:00",
        id: 6917,
      },
      {
        type: "Cow",
        name: "COOTJE 15",
        externalId: "NL 712239051",
        birthTimestamp: "2017-05-07T00:00:00",
        id: 6926,
      },
      {
        type: "Cow",
        name: "Cootje 20",
        externalId: "NL 882742946",
        birthTimestamp: "2019-08-13T00:00:00",
        id: 6941,
      },
      {
        type: "Cow",
        name: "ROZA 21",
        externalId: "NL 532435099",
        birthTimestamp: null,
        id: 6947,
      },
      {
        type: "Cow",
        name: "ROZA 25",
        externalId: "NL 639636744",
        birthTimestamp: "2014-04-29T00:00:00",
        id: 6948,
      },
      {
        type: "Cow",
        name: "FRIEDA 63",
        externalId: "NL 469933521",
        birthTimestamp: null,
        id: 6959,
      },
      {
        type: "Cow",
        name: "FRIEDA 65",
        externalId: "NL 639636991",
        birthTimestamp: "2015-02-28T00:00:00",
        id: 6960,
      },
      {
        type: "Cow",
        name: "MOEVCHEN 26",
        externalId: "NL 898036057",
        birthTimestamp: null,
        id: 6975,
      },
      {
        type: "Cow",
        name: "Moevchen 35",
        externalId: "NL 882742890",
        birthTimestamp: "2019-10-15T00:00:00",
        id: 6977,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 437064008",
        birthTimestamp: null,
        id: 6976,
      },
      {
        type: "Cow",
        name: null,
        externalId: "UK 160193703425",
        birthTimestamp: null,
        id: 6995,
      },
      {
        type: "Cow",
        name: "KITTY 49",
        externalId: "NL 639636681",
        birthTimestamp: "2014-02-10T00:00:00",
        id: 6996,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DE 0535172081",
        birthTimestamp: null,
        id: 7008,
      },
      {
        type: "Cow",
        name: "Vampir 1680",
        externalId: "DE 0537871680",
        birthTimestamp: "2013-07-11T00:00:00",
        id: 7010,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DE 0536418642",
        birthTimestamp: null,
        id: 7009,
      },
      {
        type: "Cow",
        name: "Loreley 1",
        externalId: "NL 655090788",
        birthTimestamp: "2019-11-10T00:00:00",
        id: 7052,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 649285772",
        birthTimestamp: null,
        id: 7037,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 655090834",
        birthTimestamp: "2020-01-05T00:00:00",
        id: 7038,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DE 0537612247",
        birthTimestamp: null,
        id: 7045,
      },
      {
        type: "Cow",
        name: "Jessi 2",
        externalId: "NL 655090841",
        birthTimestamp: "2020-01-05T00:00:00",
        id: 7046,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DE 0537343870",
        birthTimestamp: null,
        id: 7051,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 534073156",
        birthTimestamp: null,
        id: 7057,
      },
      {
        type: "Cow",
        name: "SIENTJE 138",
        externalId: "NL 655090889",
        birthTimestamp: "2020-01-15T00:00:00",
        id: 7058,
      },
      {
        type: "Cow",
        name: "SIENTJE 1",
        externalId: "NL 572443885",
        birthTimestamp: "2022-02-11T00:00:00",
        id: 11867,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 523162373",
        birthTimestamp: null,
        id: 7063,
      },
      {
        type: "Cow",
        name: "CARLA 100",
        externalId: "NL 882165509",
        birthTimestamp: "2013-08-07T00:00:00",
        id: 7065,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DE 0768555470",
        birthTimestamp: null,
        id: 7064,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 649285462",
        birthTimestamp: null,
        id: 7088,
      },
      {
        type: "Cow",
        name: "BLACKROSE 3 F 3",
        externalId: "NL 572444011",
        birthTimestamp: "2022-10-03T00:00:00",
        id: 12343,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 667284944",
        birthTimestamp: null,
        id: 7105,
      },
      {
        type: "Cow",
        name: "faith 3",
        externalId: "NL 655090872",
        birthTimestamp: "2020-01-13T00:00:00",
        id: 7106,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 969476216",
        birthTimestamp: null,
        id: 7112,
      },
      {
        type: "Cow",
        name: "SIENTJE 137",
        externalId: "NL 655090795",
        birthTimestamp: "2019-11-11T00:00:00",
        id: 7113,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 868875420",
        birthTimestamp: null,
        id: 7133,
      },
      {
        type: "Cow",
        name: "SIENTJE 136",
        externalId: "NL 655090771",
        birthTimestamp: "2019-11-09T00:00:00",
        id: 7135,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DK 03372306015",
        birthTimestamp: null,
        id: 7134,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 649285640",
        birthTimestamp: null,
        id: 7139,
      },
      {
        type: "Cow",
        name: "SECRETE 24",
        externalId: "NL 655090827",
        birthTimestamp: "2019-12-30T00:00:00",
        id: 7140,
      },
      {
        type: "Cow",
        name: "Secrete 25",
        externalId: "NL 572443931",
        birthTimestamp: "2022-02-25T00:00:00",
        id: 11862,
      },
      {
        type: "Cow",
        name: null,
        externalId: "DE 0534960223",
        birthTimestamp: null,
        id: 7145,
      },
      {
        type: "Cow",
        name: "Leticia 8151",
        externalId: "DE 0538128151",
        birthTimestamp: "2013-08-18T00:00:00",
        id: 7146,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 572444509",
        birthTimestamp: "2022-03-06T00:00:00",
        id: 11859,
      },
      {
        type: "Cow",
        name: "BOUKJE 102",
        externalId: "NL 572443892",
        birthTimestamp: "2022-02-12T00:00:00",
        id: 11863,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 933015324",
        birthTimestamp: null,
        id: 12067,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 572444198",
        birthTimestamp: "2022-05-06T00:00:00",
        id: 12068,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 898158027",
        birthTimestamp: null,
        id: 12111,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 572444244",
        birthTimestamp: "2022-05-22T00:00:00",
        id: 12112,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 572444275",
        birthTimestamp: "2022-05-28T00:00:00",
        id: 12123,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 572444299",
        birthTimestamp: "2022-06-14T00:00:00",
        id: 12157,
      },
      {
        type: "Cow",
        name: "ROESVELD 32",
        externalId: "NL 572443993",
        birthTimestamp: "2022-07-18T00:00:00",
        id: 12233,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 661903733",
        birthTimestamp: null,
        id: 12244,
      },
      {
        type: "Cow",
        name: "GEA 11",
        externalId: "NL 572444004",
        birthTimestamp: "2022-07-20T00:00:00",
        id: 12245,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 456789012",
        birthTimestamp: null,
        id: 12279,
      },
      {
        type: "Cow",
        name: null,
        externalId: "NL 887310414",
        birthTimestamp: null,
        id: 12353,
      },
      {
        type: "Cow",
        name: "NELIE 21",
        externalId: "NL 5872444035",
        birthTimestamp: "2022-10-15T00:00:00",
        id: 12354,
      },
    ],
    farmer: {
      type: "Farmer",
      name: {
        ens: "Guus van Roessel",
        nls: "Guus van Roessel",
        nl: "Guus van Roessel",
        en: "Guus van Roessel",
      },
      style: null,
      personalQuote: {
        nl: "Het is pas koud als de boeren ijs melken",
        nls: "Het is pas koud als de boeren ijs melken",
        ens: "It is cold when farmers milk icecream",
        en: "It is cold when farmers milk icecream",
      },
      visionQuote: {
        en: "Farmer Guus believes in the power of nature and has therefore chosen to farm organically. “We don't use fertilizers or poisons. On the farm, a lot of attention has been paid to a natural meadow – with herb-rich grass, flower borders and trees”.",
        nls: "Boer Guus gelooft in de kracht van de natuur en heeft er daarom voor gekozen om biologisch te boeren. “We gebruiken geen kunstmest of gif. Op de boerderij is er veel aandacht besteed aan een natuurlijke weide – met kruidenrijk gras, bloemenranden en bomen”.",
        nl: "Boer Guus gelooft in de kracht van de natuur en heeft er daarom voor gekozen om biologisch te boeren. “We gebruiken geen kunstmest of gif. Op de boerderij is er veel aandacht besteed aan een natuurlijke weide – met kruidenrijk gras, bloemenranden en bomen”.",
        ens: "Farmer Guus believes in the power of nature and has therefore chosen to farm organically. “We don't use fertilizers or poisons. On the farm, a lot of attention has been paid to a natural meadow – with herb-rich grass, flower borders and trees”.",
      },
      webSiteURL: "https://www.mijnmelk.nl/dedobbelhoeve/",
      imageData: {
        nls: "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/adc80b0f-20a5-4b67-85b3-4249f88518d6.jpeg",
        ens: "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/2e995373-7746-4416-bd36-9287fd9c28c1.jpeg",
        en: "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/32c3bd82-de8d-4fee-82d8-eac00152540d.jpeg",
        nl: "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/9ceb1dcd-f68b-40ee-a4c0-7878b976465f.jpeg",
      },
      storySlides: {
        nl: [
          {
            textBlockIndex: 0,
            title: "T1",
            description: "D1",
            imageData:
              "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/c899ee0b-ceb0-45ae-9588-99cb45e07c20.jpeg",
          },
          {
            textBlockIndex: 2,
            title: "T3",
            description: "D3",
            imageData:
              "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/c317d881-ec97-47d6-9251-536e461a058a.jpeg",
          },
          {
            textBlockIndex: 1,
            title: "T2",
            description: "D2",
            imageData:
              "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/b1d002a1-8eb8-4f6e-bb01-9a2a25b3fc82.jpeg",
          },
        ],
        en: [
          {
            textBlockIndex: 0,
            title: "TE1",
            description: "DE1",
            imageData:
              "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/35804c9a-ca43-4c2f-9ee1-cd4b636a8c80.jpeg",
          },
          {
            textBlockIndex: 1,
            title: "TE2",
            description: "DE2",
            imageData:
              "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/7025cdea-faf9-4936-a624-0a68fb935b39.jpeg",
          },
        ],
        ens: [
          {
            textBlockIndex: 1,
            title: "TE_CH1",
            description: "DE_CH1",
            imageData:
              "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/f0287b04-f500-4790-87b6-656d6f8e2dc8.jpeg",
          },
        ],
      },
      favoriteCowName: {
        nls: "Roesveld 27",
        nl: "Roesveld 27",
        en: "Guus van Roessel",
        ens: "Roesveld 27",
      },
      languages: [
        { code: "en", name: "English" },
        { code: "ens", name: "English for children" },
        { code: "nl", name: "Nederlands" },
        { code: "nls", name: "Nederlands voor kinderen" },
      ],
      id: "testingUser005",
    },
    milkBatch: {
      externalId: "R2229102",
      start: "2022-07-18T11:41:38.503",
      end: "2022-07-18T16:18:21.04",
      liters: 490.12,
      fatPercentage: 3.94,
      proteinPercentage: 3.31,
      lactosePercentage: 4.56,
      cows: [
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "cold",
          milkMoment: null,
          milkWeightInKgs: 8.6,
          milkFatPercentage: 3.5,
          milkProteinPercentage: 3.2,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "AUKJE 83",
            externalId: "NL 469933622",
            birthTimestamp: null,
            id: 6536,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 9,
          weightKg: 737.0,
          temperatureAtBirth: 12.4,
          temperatureAtFirstBirthday: 0.0,
          firstCalf: {
            temperatureAtBirthDefinition: "cold",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "AUKJE 93",
              externalId: "NL 752636502",
              birthTimestamp: "2013-06-10T00:00:00",
              id: 2043,
            },
            cowType: "holstein",
            ageInYears: 1,
            weightKg: 0.0,
            temperatureAtBirth: 0.9,
            temperatureAtFirstBirthday: 1.6,
            type: "Cow",
            name: "AUKJE 109",
            externalId: "NL 657643058",
            birthTimestamp: "2021-03-06T00:00:00",
            id: 2044,
          },
          poll: {
            id: 1203,
            cow: null,
            name1: "Aagje",
            name2: "Aaltje",
            name3: "Aartje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "AUKJE 93",
          externalId: "NL 752636502",
          birthTimestamp: "2013-06-10T00:00:00",
          id: 2043,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "warm",
          milkMoment: null,
          milkWeightInKgs: 8.8,
          milkFatPercentage: 3.4,
          milkProteinPercentage: 3.1,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "warm",
            mother: {
              type: "Cow",
              name: "Cootje 5",
              externalId: "NL 496734254",
              birthTimestamp: null,
              id: 6925,
            },
            cowType: "holstein",
            ageInYears: 6,
            weightKg: 0.0,
            temperatureAtBirth: 6.0,
            temperatureAtFirstBirthday: 11.5,
            type: "Cow",
            name: "COOTJE 13",
            externalId: "NL 724138157",
            birthTimestamp: "2016-03-19T00:00:00",
            id: 1699,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Cootje 5",
            externalId: "NL 496734254",
            birthTimestamp: null,
            id: 6925,
          },
          siblings: [],
          relatives: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "warm",
              mother: {
                type: "Cow",
                name: "Cootje 5",
                externalId: "NL 496734254",
                birthTimestamp: null,
                id: 6925,
              },
              cowType: "holstein",
              ageInYears: 5,
              weightKg: 0.0,
              temperatureAtBirth: 11.8,
              temperatureAtFirstBirthday: 19.5,
              type: "Cow",
              name: "COOTJE 15",
              externalId: "NL 712239051",
              birthTimestamp: "2017-05-07T00:00:00",
              id: 6926,
            },
          ],
          cowType: "holstein",
          ageInYears: 4,
          weightKg: 0.0,
          temperatureAtBirth: 27.4,
          temperatureAtFirstBirthday: 28.9,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "cold",
            mother: {
              type: "Cow",
              name: "Cootje 16",
              externalId: "NL 733440054",
              birthTimestamp: "2018-07-26T00:00:00",
              id: 1700,
            },
            cowType: "holstein",
            ageInYears: 0,
            weightKg: 0.0,
            temperatureAtBirth: 8.7,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 572444516",
            birthTimestamp: "2022-03-16T00:00:00",
            id: 11866,
          },
          poll: null,
          type: "Cow",
          name: "Cootje 16",
          externalId: "NL 733440054",
          birthTimestamp: "2018-07-26T00:00:00",
          id: 1700,
        },
        {
          temperatureAtBirthDefinition: "cold",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 9.0,
          milkFatPercentage: 3.7,
          milkProteinPercentage: 3.2,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "RESI 1096",
            externalId: "NL 639636939",
            birthTimestamp: null,
            id: 6666,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 5,
          weightKg: 0.0,
          temperatureAtBirth: -2.4,
          temperatureAtFirstBirthday: 3.6,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "RESI 1098",
              externalId: "NL 712238995",
              birthTimestamp: "2017-01-19T00:00:00",
              id: 2387,
            },
            cowType: "holstein",
            ageInYears: 3,
            weightKg: 0.0,
            temperatureAtBirth: 13.5,
            temperatureAtFirstBirthday: 13.3,
            type: "Cow",
            name: "Resi 1099",
            externalId: "NL 882742915",
            birthTimestamp: "2019-10-08T00:00:00",
            id: 2388,
          },
          poll: {
            id: 1214,
            cow: null,
            name1: "Ada",
            name2: "Aafke",
            name3: "Aagje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "RESI 1098",
          externalId: "NL 712238995",
          birthTimestamp: "2017-01-19T00:00:00",
          id: 2387,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 6.3,
          milkFatPercentage: 4.2,
          milkProteinPercentage: 3.6,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 394346135",
            birthTimestamp: null,
            id: 1739,
          },
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "MOEVCHEN 21",
              externalId: "NL 296122103",
              birthTimestamp: null,
              id: 6736,
            },
            cowType: "holstein",
            ageInYears: 12,
            weightKg: 0.0,
            temperatureAtBirth: 18.3,
            temperatureAtFirstBirthday: 18.5,
            type: "Cow",
            name: "MOEVCHEN 24",
            externalId: "NL 532435145",
            birthTimestamp: "2010-07-05T00:00:00",
            id: 2001,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "MOEVCHEN 21",
            externalId: "NL 296122103",
            birthTimestamp: null,
            id: 6736,
          },
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 13.3,
          temperatureAtFirstBirthday: 17.2,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "MOEVCHEN 36",
          externalId: "NL 882742799",
          birthTimestamp: "2020-06-07T00:00:00",
          id: 2002,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 8.8,
          milkFatPercentage: 4.9,
          milkProteinPercentage: 3.3,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 519550898",
            birthTimestamp: null,
            id: 2470,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "AUKJE 89",
            externalId: "NL 898036064",
            birthTimestamp: null,
            id: 6775,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 6,
          weightKg: 0.0,
          temperatureAtBirth: 15.9,
          temperatureAtFirstBirthday: 17.8,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "AUKJE 98",
              externalId: "NL 724138195",
              birthTimestamp: "2016-06-10T00:00:00",
              id: 1740,
            },
            cowType: "holstein",
            ageInYears: 1,
            weightKg: 0.0,
            temperatureAtBirth: 10.8,
            temperatureAtFirstBirthday: 11.6,
            type: "Cow",
            name: "Aukje 107",
            externalId: "NL 657642961",
            birthTimestamp: "2020-10-28T00:00:00",
            id: 1741,
          },
          poll: {
            id: 1147,
            cow: null,
            name1: "Aartje",
            name2: "Aagje",
            name3: "Aaltje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "AUKJE 98",
          externalId: "NL 724138195",
          birthTimestamp: "2016-06-10T00:00:00",
          id: 1740,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 9.8,
          milkFatPercentage: 4.8,
          milkProteinPercentage: 3.3,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "DE 0354165497",
            birthTimestamp: null,
            id: 7099,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "DE 0580586454",
            birthTimestamp: null,
            id: 7098,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 9,
          weightKg: 0.0,
          temperatureAtBirth: 17.3,
          temperatureAtFirstBirthday: 19.6,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "warm",
            mother: {
              type: "Cow",
              name: "Mira 4680",
              externalId: "DE 0537194680",
              birthTimestamp: "2013-08-09T00:00:00",
              id: 1658,
            },
            cowType: "holstein",
            ageInYears: 1,
            weightKg: 0.0,
            temperatureAtBirth: 17.0,
            temperatureAtFirstBirthday: 21.2,
            type: "Cow",
            name: "MIRA 4681",
            externalId: "NL 657643159",
            birthTimestamp: "2021-09-06T00:00:00",
            id: 1659,
          },
          poll: {
            id: 1156,
            cow: null,
            name1: "Aaltje",
            name2: "Ada",
            name3: "Aafke",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Mira 4680",
          externalId: "DE 0537194680",
          birthTimestamp: "2013-08-09T00:00:00",
          id: 1658,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 8.5,
          milkFatPercentage: 4.0,
          milkProteinPercentage: 3.2,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 437064008",
            birthTimestamp: null,
            id: 6976,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "MOEVCHEN 26",
            externalId: "NL 898036057",
            birthTimestamp: null,
            id: 6975,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 3,
          weightKg: 0.0,
          temperatureAtBirth: 15.3,
          temperatureAtFirstBirthday: 9.4,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Moevchen 35",
          externalId: "NL 882742890",
          birthTimestamp: "2019-10-15T00:00:00",
          id: 6977,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "warm",
          milkMoment: null,
          milkWeightInKgs: 8.3,
          milkFatPercentage: 3.5,
          milkProteinPercentage: 3.0,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "FR 3803078751",
            birthTimestamp: null,
            id: 7018,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 969476216",
            birthTimestamp: null,
            id: 7112,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 5.2,
          temperatureAtFirstBirthday: 11.1,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "SIENTJE 137",
          externalId: "NL 655090795",
          birthTimestamp: "2019-11-11T00:00:00",
          id: 7113,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 8.1,
          milkFatPercentage: 3.1,
          milkProteinPercentage: 2.2,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "cold",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "RESI 1096",
              externalId: "NL 639636939",
              birthTimestamp: null,
              id: 6666,
            },
            cowType: "holstein",
            ageInYears: 5,
            weightKg: 0.0,
            temperatureAtBirth: -2.4,
            temperatureAtFirstBirthday: 3.6,
            type: "Cow",
            name: "RESI 1098",
            externalId: "NL 712238995",
            birthTimestamp: "2017-01-19T00:00:00",
            id: 2387,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "RESI 1096",
            externalId: "NL 639636939",
            birthTimestamp: null,
            id: 6666,
          },
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 3,
          weightKg: 0.0,
          temperatureAtBirth: 13.5,
          temperatureAtFirstBirthday: 13.3,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Resi 1099",
          externalId: "NL 882742915",
          birthTimestamp: "2019-10-08T00:00:00",
          id: 2388,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "warm",
          milkMoment: null,
          milkWeightInKgs: 12.2,
          milkFatPercentage: 3.0,
          milkProteinPercentage: 3.1,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "IT 019500393890",
            birthTimestamp: null,
            id: 1610,
          },
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: null,
              externalId: "NL 532435176",
              birthTimestamp: null,
              id: 2417,
            },
            cowType: "holstein",
            ageInYears: 8,
            weightKg: 0.0,
            temperatureAtBirth: 20.0,
            temperatureAtFirstBirthday: 19.7,
            type: "Cow",
            name: "Havep Force",
            externalId: "NL 639636838",
            birthTimestamp: "2014-07-16T00:00:00",
            id: 2190,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 532435176",
            birthTimestamp: null,
            id: 2417,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Havep Force",
                externalId: "NL 639636838",
                birthTimestamp: "2014-07-16T00:00:00",
                id: 2190,
              },
              cowType: "holstein",
              ageInYears: 1,
              weightKg: 0.0,
              temperatureAtBirth: 5.1,
              temperatureAtFirstBirthday: 6.8,
              type: "Cow",
              name: "Cootje 25",
              externalId: "NL 657643010",
              birthTimestamp: "2020-12-27T00:00:00",
              id: 2192,
            },
          ],
          relatives: [],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 7.5,
          temperatureAtFirstBirthday: 8.7,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Cootje 23",
          externalId: "NL 882742838",
          birthTimestamp: "2020-02-18T00:00:00",
          id: 2213,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 11.8,
          milkFatPercentage: 5.0,
          milkProteinPercentage: 3.4,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "ELLA 7",
            externalId: "NL 712239121",
            birthTimestamp: null,
            id: 6498,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 6.2,
          temperatureAtFirstBirthday: 9.6,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Ella 9",
          externalId: "NL 882742883",
          birthTimestamp: "2019-11-13T00:00:00",
          id: 6499,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 7.2,
          milkFatPercentage: 1.9,
          milkProteinPercentage: 3.0,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 543203919",
            birthTimestamp: null,
            id: 1795,
          },
          mother: {
            temperatureAtBirthDefinition: "cold",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "LINDA 85",
              externalId: "NL 882165631",
              birthTimestamp: "2013-10-12T00:00:00",
              id: 1671,
            },
            cowType: "holstein",
            ageInYears: 5,
            weightKg: 0.0,
            temperatureAtBirth: 0.7,
            temperatureAtFirstBirthday: 4.5,
            type: "Cow",
            name: "LINDA 89",
            externalId: "NL 724138397",
            birthTimestamp: "2016-11-28T00:00:00",
            id: 1672,
          },
          grandMother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: null,
              externalId: "NL 440161437",
              birthTimestamp: null,
              id: 7073,
            },
            cowType: "holstein",
            ageInYears: 9,
            weightKg: 0.0,
            temperatureAtBirth: 7.9,
            temperatureAtFirstBirthday: 12.4,
            type: "Cow",
            name: "LINDA 85",
            externalId: "NL 882165631",
            birthTimestamp: "2013-10-12T00:00:00",
            id: 1671,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "cold",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "LINDA 89",
                externalId: "NL 724138397",
                birthTimestamp: "2016-11-28T00:00:00",
                id: 1672,
              },
              cowType: "holstein",
              ageInYears: 1,
              weightKg: 0.0,
              temperatureAtBirth: 8.8,
              temperatureAtFirstBirthday: 11.0,
              type: "Cow",
              name: "LINDA 95",
              externalId: "NL 657643096",
              birthTimestamp: "2021-05-03T00:00:00",
              id: 2122,
            },
          ],
          relatives: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "LINDA 85",
                externalId: "NL 882165631",
                birthTimestamp: "2013-10-12T00:00:00",
                id: 1671,
              },
              cowType: "holstein",
              ageInYears: 6,
              weightKg: 0.0,
              temperatureAtBirth: 7.0,
              temperatureAtFirstBirthday: 7.3,
              type: "Cow",
              name: "LINDA 88",
              externalId: "NL 724138094",
              birthTimestamp: "2015-12-14T00:00:00",
              id: 1713,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "LINDA 85",
                externalId: "NL 882165631",
                birthTimestamp: "2013-10-12T00:00:00",
                id: 1671,
              },
              cowType: "holstein",
              ageInYears: 4,
              weightKg: 0.0,
              temperatureAtBirth: 0.8,
              temperatureAtFirstBirthday: 3.6,
              type: "Cow",
              name: "Linda 91",
              externalId: "NL 733439982",
              birthTimestamp: "2018-02-05T00:00:00",
              id: 1687,
            },
            {
              temperatureAtBirthDefinition: "warm",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Linda 91",
                externalId: "NL 733439982",
                birthTimestamp: "2018-02-05T00:00:00",
                id: 1687,
              },
              cowType: "holstein",
              ageInYears: 0,
              weightKg: 0.0,
              temperatureAtBirth: 9.7,
              temperatureAtFirstBirthday: 0.0,
              type: "Cow",
              name: "Linda 98",
              externalId: "NL 572443830",
              birthTimestamp: "2021-12-29T00:00:00",
              id: 1796,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "LINDA 88",
                externalId: "NL 724138094",
                birthTimestamp: "2015-12-14T00:00:00",
                id: 1713,
              },
              cowType: "holstein",
              ageInYears: 2,
              weightKg: 0.0,
              temperatureAtBirth: 15.6,
              temperatureAtFirstBirthday: 12.0,
              type: "Cow",
              name: "LINDA 94",
              externalId: "NL 882742775",
              birthTimestamp: "2020-05-23T00:00:00",
              id: 2321,
            },
          ],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 2.8,
          temperatureAtFirstBirthday: 7.2,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Linda 93",
          externalId: "NL 882742821",
          birthTimestamp: "2020-02-26T00:00:00",
          id: 2149,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "warm",
          milkMoment: null,
          milkWeightInKgs: 9.8,
          milkFatPercentage: 4.9,
          milkProteinPercentage: 3.3,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "HAVEP JACCO",
            externalId: "NL 873159694",
            birthTimestamp: null,
            id: 1836,
          },
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "Moevchen 19",
              externalId: "NL 898036134",
              birthTimestamp: null,
              id: 2426,
            },
            cowType: "holstein",
            ageInYears: 7,
            weightKg: 0.0,
            temperatureAtBirth: 4.4,
            temperatureAtFirstBirthday: 2.3,
            type: "Cow",
            name: "HAVEP JACCO",
            externalId: "NL 639637002",
            birthTimestamp: "2015-03-04T00:00:00",
            id: 2252,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Moevchen 19",
            externalId: "NL 898036134",
            birthTimestamp: null,
            id: 2426,
          },
          siblings: [],
          relatives: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "cold",
              mother: {
                type: "Cow",
                name: "Moevchen 19",
                externalId: "NL 898036134",
                birthTimestamp: null,
                id: 2426,
              },
              cowType: "holstein",
              ageInYears: 5,
              weightKg: 0.0,
              temperatureAtBirth: 4.4,
              temperatureAtFirstBirthday: -1.9,
              type: "Cow",
              name: "ROESVELD 26",
              externalId: "NL 712239006",
              birthTimestamp: "2017-02-06T00:00:00",
              id: 2135,
            },
            {
              temperatureAtBirthDefinition: "warm",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Moevchen 19",
                externalId: "NL 898036134",
                birthTimestamp: null,
                id: 2426,
              },
              cowType: "holstein",
              ageInYears: 8,
              weightKg: 0.0,
              temperatureAtBirth: 13.2,
              temperatureAtFirstBirthday: 6.0,
              type: "Cow",
              name: "Moevchen 21",
              externalId: "NL 639636737",
              birthTimestamp: "2014-04-28T00:00:00",
              id: 2349,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "ROESVELD 26",
                externalId: "NL 712239006",
                birthTimestamp: "2017-02-06T00:00:00",
                id: 2135,
              },
              cowType: "holstein",
              ageInYears: 3,
              weightKg: 0.0,
              temperatureAtBirth: 6.9,
              temperatureAtFirstBirthday: 7.4,
              type: "Cow",
              name: "Roesveld 30",
              externalId: "NL 733440092",
              birthTimestamp: "2019-02-24T00:00:00",
              id: 2161,
            },
            {
              temperatureAtBirthDefinition: "warm",
              temperatureAtFirstBirthdayDefinition: "cold",
              mother: {
                type: "Cow",
                name: "ROESVELD 26",
                externalId: "NL 712239006",
                birthTimestamp: "2017-02-06T00:00:00",
                id: 2135,
              },
              cowType: "holstein",
              ageInYears: 2,
              weightKg: 0.0,
              temperatureAtBirth: 8.5,
              temperatureAtFirstBirthday: -4.7,
              type: "Cow",
              name: "Roesveld 28",
              externalId: "NL 882742852",
              birthTimestamp: "2020-02-10T00:00:00",
              id: 2136,
            },
          ],
          cowType: "holstein",
          ageInYears: 5,
          weightKg: 0.0,
          temperatureAtBirth: 16.1,
          temperatureAtFirstBirthday: 22.7,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Roesveld 27",
          externalId: "NL 712239075",
          birthTimestamp: "2017-07-01T00:00:00",
          id: 2253,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 10.4,
          milkFatPercentage: 5.1,
          milkProteinPercentage: 3.6,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "LUZIE 8063",
            externalId: "NL 639636807",
            birthTimestamp: null,
            id: 6674,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 5,
          weightKg: 0.0,
          temperatureAtBirth: 15.3,
          temperatureAtFirstBirthday: 14.5,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "cold",
            mother: {
              type: "Cow",
              name: "LUZIE 8066",
              externalId: "NL 712239138",
              birthTimestamp: "2017-09-08T00:00:00",
              id: 2262,
            },
            cowType: "holstein",
            ageInYears: 1,
            weightKg: 0.0,
            temperatureAtBirth: 13.6,
            temperatureAtFirstBirthday: 9.5,
            type: "Cow",
            name: "Luzie 8070",
            externalId: "NL 572443784",
            birthTimestamp: "2021-09-28T00:00:00",
            id: 2264,
          },
          poll: {
            id: 1210,
            cow: null,
            name1: "Aartje",
            name2: "Aafje",
            name3: "Aaltje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "LUZIE 8066",
          externalId: "NL 712239138",
          birthTimestamp: "2017-09-08T00:00:00",
          id: 2262,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "warm",
          milkMoment: null,
          milkWeightInKgs: 12.7,
          milkFatPercentage: 2.3,
          milkProteinPercentage: 3.5,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 713398841",
            birthTimestamp: null,
            id: 1893,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "FRISIA 387",
            externalId: "NL 752636401",
            birthTimestamp: null,
            id: 6573,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 19.2,
          temperatureAtFirstBirthday: 19.6,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "FRISIA 392",
          externalId: "NL 882742782",
          birthTimestamp: "2020-06-01T00:00:00",
          id: 6574,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "warm",
          milkMoment: null,
          milkWeightInKgs: 11.3,
          milkFatPercentage: 3.5,
          milkProteinPercentage: 3.2,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "HAVEP JACCO",
              externalId: "NL 724138018",
              birthTimestamp: null,
              id: 1835,
            },
            cowType: "holstein",
            ageInYears: 5,
            weightKg: 0.0,
            temperatureAtBirth: 18.7,
            temperatureAtFirstBirthday: 21.5,
            type: "Cow",
            name: "Nelie 18",
            externalId: "NL 712239099",
            birthTimestamp: "2017-07-04T00:00:00",
            id: 1837,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "HAVEP JACCO",
            externalId: "NL 724138018",
            birthTimestamp: null,
            id: 1835,
          },
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 3,
          weightKg: 0.0,
          temperatureAtBirth: 15.0,
          temperatureAtFirstBirthday: 23.3,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "warm",
            mother: {
              type: "Cow",
              name: "NELIE 19",
              externalId: "NL 882742922",
              birthTimestamp: "2019-09-15T00:00:00",
              id: 1861,
            },
            cowType: "holstein",
            ageInYears: 1,
            weightKg: 0.0,
            temperatureAtBirth: 16.8,
            temperatureAtFirstBirthday: 21.7,
            type: "Cow",
            name: "NELIE 20",
            externalId: "NL 657643142",
            birthTimestamp: "2021-09-05T00:00:00",
            id: 2409,
          },
          poll: {
            id: 1209,
            cow: null,
            name1: "Aartje",
            name2: "Aaltje",
            name3: "Ada",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "NELIE 19",
          externalId: "NL 882742922",
          birthTimestamp: "2019-09-15T00:00:00",
          id: 1861,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 12.9,
          milkFatPercentage: 5.5,
          milkProteinPercentage: 3.0,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 440127509",
            birthTimestamp: null,
            id: 6621,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Cootje 35",
            externalId: "NL 752636456",
            birthTimestamp: null,
            id: 6594,
          },
          grandMother: null,
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Cootje 35",
                externalId: "NL 752636456",
                birthTimestamp: null,
                id: 6594,
              },
              cowType: "holstein",
              ageInYears: 6,
              weightKg: 0.0,
              temperatureAtBirth: 1.4,
              temperatureAtFirstBirthday: 3.1,
              type: "Cow",
              name: "Dobbelhoeve 39",
              externalId: "NL 724138119",
              birthTimestamp: "2016-02-13T00:00:00",
              id: 6604,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Cootje 35",
                externalId: "NL 752636456",
                birthTimestamp: null,
                id: 6594,
              },
              cowType: "holstein",
              ageInYears: 4,
              weightKg: 0.0,
              temperatureAtBirth: 12.3,
              temperatureAtFirstBirthday: 13.0,
              type: "Cow",
              name: "Dobbelhoeve 41",
              externalId: "NL 733440016",
              birthTimestamp: "2018-04-16T00:00:00",
              id: 6595,
            },
          ],
          relatives: [],
          cowType: "holstein",
          ageInYears: 3,
          weightKg: 0.0,
          temperatureAtBirth: 9.2,
          temperatureAtFirstBirthday: 4.8,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Cootje 18",
          externalId: "NL 733440117",
          birthTimestamp: "2019-03-29T00:00:00",
          id: 6622,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "warm",
          milkMoment: null,
          milkWeightInKgs: 11.6,
          milkFatPercentage: 3.5,
          milkProteinPercentage: 3.4,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "FR 3803078751",
            birthTimestamp: null,
            id: 7018,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 649285587",
            birthTimestamp: null,
            id: 7017,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 7.8,
          temperatureAtFirstBirthday: 11.6,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "Siamoise 6",
              externalId: "NL 655090810",
              birthTimestamp: "2019-12-23T00:00:00",
              id: 1823,
            },
            cowType: "holstein",
            ageInYears: 0,
            weightKg: 0.0,
            temperatureAtBirth: 2.8,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "SIAMOISE 7",
            externalId: "NL 572443854",
            birthTimestamp: "2022-01-06T00:00:00",
            id: 1824,
          },
          poll: {
            id: 1155,
            cow: null,
            name1: "Aafje",
            name2: "Aaltje",
            name3: "Ada",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Siamoise 6",
          externalId: "NL 655090810",
          birthTimestamp: "2019-12-23T00:00:00",
          id: 1823,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "warm",
          milkMoment: null,
          milkWeightInKgs: 5.5,
          milkFatPercentage: 2.4,
          milkProteinPercentage: 3.0,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 930656562",
            birthTimestamp: null,
            id: 6861,
          },
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "cold",
            mother: {
              type: "Cow",
              name: null,
              externalId: "DE 0939356285",
              birthTimestamp: null,
              id: 7120,
            },
            cowType: "holstein",
            ageInYears: 12,
            weightKg: 0.0,
            temperatureAtBirth: 15.3,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Demi",
            externalId: "DE 0943932264",
            birthTimestamp: "2009-08-03T00:00:00",
            id: 6860,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "DE 0939356285",
            birthTimestamp: null,
            id: 7120,
          },
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 3.3,
          temperatureAtFirstBirthday: 13.2,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Demi 1",
          externalId: "NL 882742814",
          birthTimestamp: "2020-03-30T00:00:00",
          id: 6862,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 14.0,
          milkFatPercentage: 3.8,
          milkProteinPercentage: 3.0,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "RESI 1095",
            externalId: "NL 536010953",
            birthTimestamp: null,
            id: 6804,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 7,
          weightKg: 0.0,
          temperatureAtBirth: 19.9,
          temperatureAtFirstBirthday: 16.7,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "cold",
            mother: {
              type: "Cow",
              name: "Kitty 97",
              externalId: "NL 724138056",
              birthTimestamp: "2015-08-21T00:00:00",
              id: 6805,
            },
            cowType: "holstein",
            ageInYears: 0,
            weightKg: 0.0,
            temperatureAtBirth: 2.4,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Kitty 101",
            externalId: "NL 572443948",
            birthTimestamp: "2022-03-05T00:00:00",
            id: 11864,
          },
          poll: {
            id: 1219,
            cow: null,
            name1: "Aafje",
            name2: "Aafke",
            name3: "Aagje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Kitty 97",
          externalId: "NL 724138056",
          birthTimestamp: "2015-08-21T00:00:00",
          id: 6805,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 6.9,
          milkFatPercentage: 6.1,
          milkProteinPercentage: 3.6,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: null,
              externalId: "NL 440161437",
              birthTimestamp: null,
              id: 7073,
            },
            cowType: "holstein",
            ageInYears: 9,
            weightKg: 0.0,
            temperatureAtBirth: 7.9,
            temperatureAtFirstBirthday: 12.4,
            type: "Cow",
            name: "LINDA 85",
            externalId: "NL 882165631",
            birthTimestamp: "2013-10-12T00:00:00",
            id: 1671,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 440161437",
            birthTimestamp: null,
            id: 7073,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "cold",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "LINDA 85",
                externalId: "NL 882165631",
                birthTimestamp: "2013-10-12T00:00:00",
                id: 1671,
              },
              cowType: "holstein",
              ageInYears: 5,
              weightKg: 0.0,
              temperatureAtBirth: 0.7,
              temperatureAtFirstBirthday: 4.5,
              type: "Cow",
              name: "LINDA 89",
              externalId: "NL 724138397",
              birthTimestamp: "2016-11-28T00:00:00",
              id: 1672,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "LINDA 85",
                externalId: "NL 882165631",
                birthTimestamp: "2013-10-12T00:00:00",
                id: 1671,
              },
              cowType: "holstein",
              ageInYears: 4,
              weightKg: 0.0,
              temperatureAtBirth: 0.8,
              temperatureAtFirstBirthday: 3.6,
              type: "Cow",
              name: "Linda 91",
              externalId: "NL 733439982",
              birthTimestamp: "2018-02-05T00:00:00",
              id: 1687,
            },
          ],
          relatives: [],
          cowType: "holstein",
          ageInYears: 6,
          weightKg: 0.0,
          temperatureAtBirth: 7.0,
          temperatureAtFirstBirthday: 7.3,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "LINDA 88",
              externalId: "NL 724138094",
              birthTimestamp: "2015-12-14T00:00:00",
              id: 1713,
            },
            cowType: "holstein",
            ageInYears: 2,
            weightKg: 0.0,
            temperatureAtBirth: 15.6,
            temperatureAtFirstBirthday: 12.0,
            type: "Cow",
            name: "LINDA 94",
            externalId: "NL 882742775",
            birthTimestamp: "2020-05-23T00:00:00",
            id: 2321,
          },
          poll: {
            id: 1171,
            cow: null,
            name1: "Aagje",
            name2: "Ada",
            name3: "Aafje",
            name1Votes: 0,
            name2Votes: 1,
            name3Votes: 0,
            chosenName: "Ada",
            startDate: "2022-08-30T00:00:00",
            endDate: "2022-09-06T00:00:00",
          },
          type: "Cow",
          name: "LINDA 88",
          externalId: "NL 724138094",
          birthTimestamp: "2015-12-14T00:00:00",
          id: 1713,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "cold",
          milkMoment: null,
          milkWeightInKgs: 12.0,
          milkFatPercentage: 3.7,
          milkProteinPercentage: 3.0,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "IT 019500393890",
            birthTimestamp: null,
            id: 1610,
          },
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "Kitty 34",
              externalId: "NL 752636519",
              birthTimestamp: null,
              id: 6715,
            },
            cowType: "holstein",
            ageInYears: 6,
            weightKg: 0.0,
            temperatureAtBirth: 6.7,
            temperatureAtFirstBirthday: 1.9,
            type: "Cow",
            name: "Kitty 36",
            externalId: "NL 724138102",
            birthTimestamp: "2016-01-02T00:00:00",
            id: 1975,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Kitty 34",
            externalId: "NL 752636519",
            birthTimestamp: null,
            id: 6715,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "cold",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Kitty 36",
                externalId: "NL 724138102",
                birthTimestamp: "2016-01-02T00:00:00",
                id: 1975,
              },
              cowType: "holstein",
              ageInYears: 1,
              weightKg: 0.0,
              temperatureAtBirth: 2.6,
              temperatureAtFirstBirthday: 9.8,
              type: "Cow",
              name: "Kitty 99",
              externalId: "NL 657643072",
              birthTimestamp: "2021-04-07T00:00:00",
              id: 1976,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "cold",
              mother: {
                type: "Cow",
                name: "Kitty 36",
                externalId: "NL 724138102",
                birthTimestamp: "2016-01-02T00:00:00",
                id: 1975,
              },
              cowType: "holstein",
              ageInYears: 0,
              weightKg: 0.0,
              temperatureAtBirth: 14.8,
              temperatureAtFirstBirthday: 0.0,
              type: "Cow",
              name: null,
              externalId: "NL 572444299",
              birthTimestamp: "2022-06-14T00:00:00",
              id: 12157,
            },
          ],
          relatives: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "warm",
              mother: {
                type: "Cow",
                name: "Kitty 34",
                externalId: "NL 752636519",
                birthTimestamp: null,
                id: 6715,
              },
              cowType: "holstein",
              ageInYears: 5,
              weightKg: 0.0,
              temperatureAtBirth: 5.8,
              temperatureAtFirstBirthday: 9.2,
              type: "Cow",
              name: "KOEAN MAR 37",
              externalId: "NL 712238964",
              birthTimestamp: "2016-12-22T00:00:00",
              id: 6909,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "cold",
              mother: {
                type: "Cow",
                name: "KOEAN MAR 37",
                externalId: "NL 712238964",
                birthTimestamp: "2016-12-22T00:00:00",
                id: 6909,
              },
              cowType: "holstein",
              ageInYears: 0,
              weightKg: 0.0,
              temperatureAtBirth: 13.5,
              temperatureAtFirstBirthday: 0.0,
              type: "Cow",
              name: "Koean Mar 40",
              externalId: "NL 572443955",
              birthTimestamp: "2022-05-05T00:00:00",
              id: 12066,
            },
          ],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 7.4,
          temperatureAtFirstBirthday: -5.6,
          firstCalf: {
            temperatureAtBirthDefinition: "warm",
            temperatureAtFirstBirthdayDefinition: "cold",
            mother: {
              type: "Cow",
              name: "Kitty 55",
              externalId: "NL 882742869",
              birthTimestamp: "2020-02-08T00:00:00",
              id: 2092,
            },
            cowType: "holstein",
            ageInYears: 0,
            weightKg: 0.0,
            temperatureAtBirth: 19.2,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "COOTJE 28",
            externalId: "NL 572443962",
            birthTimestamp: "2022-05-19T00:00:00",
            id: 12088,
          },
          poll: {
            id: 1163,
            cow: null,
            name1: "Ada",
            name2: "Aagje",
            name3: "Aafke",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Kitty 55",
          externalId: "NL 882742869",
          birthTimestamp: "2020-02-08T00:00:00",
          id: 2092,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "cold",
          milkMoment: null,
          milkWeightInKgs: 7.7,
          milkFatPercentage: 4.4,
          milkProteinPercentage: 3.3,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 725399755",
            birthTimestamp: null,
            id: 1927,
          },
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "cold",
            mother: {
              type: "Cow",
              name: "Moevchen 19",
              externalId: "NL 898036134",
              birthTimestamp: null,
              id: 2426,
            },
            cowType: "holstein",
            ageInYears: 5,
            weightKg: 0.0,
            temperatureAtBirth: 4.4,
            temperatureAtFirstBirthday: -1.9,
            type: "Cow",
            name: "ROESVELD 26",
            externalId: "NL 712239006",
            birthTimestamp: "2017-02-06T00:00:00",
            id: 2135,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Moevchen 19",
            externalId: "NL 898036134",
            birthTimestamp: null,
            id: 2426,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "ROESVELD 26",
                externalId: "NL 712239006",
                birthTimestamp: "2017-02-06T00:00:00",
                id: 2135,
              },
              cowType: "holstein",
              ageInYears: 3,
              weightKg: 0.0,
              temperatureAtBirth: 6.9,
              temperatureAtFirstBirthday: 7.4,
              type: "Cow",
              name: "Roesveld 30",
              externalId: "NL 733440092",
              birthTimestamp: "2019-02-24T00:00:00",
              id: 2161,
            },
            {
              temperatureAtBirthDefinition: "warm",
              temperatureAtFirstBirthdayDefinition: "cold",
              mother: {
                type: "Cow",
                name: "ROESVELD 26",
                externalId: "NL 712239006",
                birthTimestamp: "2017-02-06T00:00:00",
                id: 2135,
              },
              cowType: "holstein",
              ageInYears: 0,
              weightKg: 0.0,
              temperatureAtBirth: 23.4,
              temperatureAtFirstBirthday: 0.0,
              type: "Cow",
              name: "ROESVELD 32",
              externalId: "NL 572443993",
              birthTimestamp: "2022-07-18T00:00:00",
              id: 12233,
            },
          ],
          relatives: [
            {
              temperatureAtBirthDefinition: "warm",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Moevchen 19",
                externalId: "NL 898036134",
                birthTimestamp: null,
                id: 2426,
              },
              cowType: "holstein",
              ageInYears: 8,
              weightKg: 0.0,
              temperatureAtBirth: 13.2,
              temperatureAtFirstBirthday: 6.0,
              type: "Cow",
              name: "Moevchen 21",
              externalId: "NL 639636737",
              birthTimestamp: "2014-04-28T00:00:00",
              id: 2349,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Moevchen 19",
                externalId: "NL 898036134",
                birthTimestamp: null,
                id: 2426,
              },
              cowType: "holstein",
              ageInYears: 7,
              weightKg: 0.0,
              temperatureAtBirth: 4.4,
              temperatureAtFirstBirthday: 2.3,
              type: "Cow",
              name: "HAVEP JACCO",
              externalId: "NL 639637002",
              birthTimestamp: "2015-03-04T00:00:00",
              id: 2252,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "warm",
              mother: {
                type: "Cow",
                name: "HAVEP JACCO",
                externalId: "NL 639637002",
                birthTimestamp: "2015-03-04T00:00:00",
                id: 2252,
              },
              cowType: "holstein",
              ageInYears: 5,
              weightKg: 0.0,
              temperatureAtBirth: 16.1,
              temperatureAtFirstBirthday: 22.7,
              type: "Cow",
              name: "Roesveld 27",
              externalId: "NL 712239075",
              birthTimestamp: "2017-07-01T00:00:00",
              id: 2253,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Moevchen 21",
                externalId: "NL 639636737",
                birthTimestamp: "2014-04-28T00:00:00",
                id: 2349,
              },
              cowType: "holstein",
              ageInYears: 6,
              weightKg: 0.0,
              temperatureAtBirth: 14.8,
              temperatureAtFirstBirthday: 15.0,
              type: "Cow",
              name: "ROESVELD 25",
              externalId: "NL 724138241",
              birthTimestamp: "2016-07-14T00:00:00",
              id: 2169,
            },
          ],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 8.5,
          temperatureAtFirstBirthday: -4.7,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Roesveld 28",
          externalId: "NL 882742852",
          birthTimestamp: "2020-02-10T00:00:00",
          id: 2136,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 9.6,
          milkFatPercentage: 4.7,
          milkProteinPercentage: 3.5,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Cootje 35",
            externalId: "NL 752636456",
            birthTimestamp: null,
            id: 6594,
          },
          grandMother: null,
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Cootje 35",
                externalId: "NL 752636456",
                birthTimestamp: null,
                id: 6594,
              },
              cowType: "holstein",
              ageInYears: 4,
              weightKg: 0.0,
              temperatureAtBirth: 12.3,
              temperatureAtFirstBirthday: 13.0,
              type: "Cow",
              name: "Dobbelhoeve 41",
              externalId: "NL 733440016",
              birthTimestamp: "2018-04-16T00:00:00",
              id: 6595,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Cootje 35",
                externalId: "NL 752636456",
                birthTimestamp: null,
                id: 6594,
              },
              cowType: "holstein",
              ageInYears: 3,
              weightKg: 0.0,
              temperatureAtBirth: 9.2,
              temperatureAtFirstBirthday: 4.8,
              type: "Cow",
              name: "Cootje 18",
              externalId: "NL 733440117",
              birthTimestamp: "2019-03-29T00:00:00",
              id: 6622,
            },
          ],
          relatives: [],
          cowType: "holstein",
          ageInYears: 6,
          weightKg: 0.0,
          temperatureAtBirth: 1.4,
          temperatureAtFirstBirthday: 3.1,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Dobbelhoeve 39",
          externalId: "NL 724138119",
          birthTimestamp: "2016-02-13T00:00:00",
          id: 6604,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "warm",
          milkMoment: null,
          milkWeightInKgs: 7.6,
          milkFatPercentage: 3.8,
          milkProteinPercentage: 3.5,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "MOEVCHEN 22",
              externalId: "NL 355928538",
              birthTimestamp: null,
              id: 6517,
            },
            cowType: "holstein",
            ageInYears: 7,
            weightKg: 0.0,
            temperatureAtBirth: 21.2,
            temperatureAtFirstBirthday: 17.8,
            type: "Cow",
            name: "MOEVCHEN 31",
            externalId: "NL 724138032",
            birthTimestamp: "2015-08-14T00:00:00",
            id: 1644,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "MOEVCHEN 22",
            externalId: "NL 355928538",
            birthTimestamp: null,
            id: 6517,
          },
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 4,
          weightKg: 0.0,
          temperatureAtBirth: 6.9,
          temperatureAtFirstBirthday: 7.4,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Moevchen 33",
          externalId: "NL 712239208",
          birthTimestamp: "2018-01-01T00:00:00",
          id: 1645,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 10.2,
          milkFatPercentage: 4.1,
          milkProteinPercentage: 3.3,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "warm",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "ELLA 4",
              externalId: "NL 532435075",
              birthTimestamp: null,
              id: 2481,
            },
            cowType: "holstein",
            ageInYears: 6,
            weightKg: 0.0,
            temperatureAtBirth: 24.8,
            temperatureAtFirstBirthday: 17.8,
            type: "Cow",
            name: "Ella 6",
            externalId: "NL 724138328",
            birthTimestamp: "2016-08-25T00:00:00",
            id: 2222,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "ELLA 4",
            externalId: "NL 532435075",
            birthTimestamp: null,
            id: 2481,
          },
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 3,
          weightKg: 0.0,
          temperatureAtBirth: 9.6,
          temperatureAtFirstBirthday: 12.7,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Ella 8",
          externalId: "NL 733440131",
          birthTimestamp: "2019-04-29T00:00:00",
          id: 2223,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 9.8,
          milkFatPercentage: 4.4,
          milkProteinPercentage: 3.3,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "HAVEP JACCO",
            externalId: "NL 873159694",
            birthTimestamp: null,
            id: 1836,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "HAVEP JACCO",
            externalId: "NL 724137969",
            birthTimestamp: null,
            id: 6633,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 5,
          weightKg: 0.0,
          temperatureAtBirth: 18.4,
          temperatureAtFirstBirthday: 16.7,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "KOEAN MAR 38",
              externalId: "NL 712239114",
              birthTimestamp: "2017-08-24T00:00:00",
              id: 1766,
            },
            cowType: "holstein",
            ageInYears: 0,
            weightKg: 0.0,
            temperatureAtBirth: 1.5,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "KOEAN MAR 39",
            externalId: "NL 572443816",
            birthTimestamp: "2021-12-25T00:00:00",
            id: 1768,
          },
          poll: {
            id: 1159,
            cow: null,
            name1: "Aartje",
            name2: "Aagje",
            name3: "Aafke",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "KOEAN MAR 38",
          externalId: "NL 712239114",
          birthTimestamp: "2017-08-24T00:00:00",
          id: 1766,
        },
        {
          temperatureAtBirthDefinition: "cold",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: -5.8,
          milkFatPercentage: 4.9,
          milkProteinPercentage: 3.6,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: null,
              externalId: "NL 440161437",
              birthTimestamp: null,
              id: 7073,
            },
            cowType: "holstein",
            ageInYears: 9,
            weightKg: 0.0,
            temperatureAtBirth: 7.9,
            temperatureAtFirstBirthday: 12.4,
            type: "Cow",
            name: "LINDA 85",
            externalId: "NL 882165631",
            birthTimestamp: "2013-10-12T00:00:00",
            id: 1671,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 440161437",
            birthTimestamp: null,
            id: 7073,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "LINDA 85",
                externalId: "NL 882165631",
                birthTimestamp: "2013-10-12T00:00:00",
                id: 1671,
              },
              cowType: "holstein",
              ageInYears: 6,
              weightKg: 0.0,
              temperatureAtBirth: 7.0,
              temperatureAtFirstBirthday: 7.3,
              type: "Cow",
              name: "LINDA 88",
              externalId: "NL 724138094",
              birthTimestamp: "2015-12-14T00:00:00",
              id: 1713,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "LINDA 85",
                externalId: "NL 882165631",
                birthTimestamp: "2013-10-12T00:00:00",
                id: 1671,
              },
              cowType: "holstein",
              ageInYears: 4,
              weightKg: 0.0,
              temperatureAtBirth: 0.8,
              temperatureAtFirstBirthday: 3.6,
              type: "Cow",
              name: "Linda 91",
              externalId: "NL 733439982",
              birthTimestamp: "2018-02-05T00:00:00",
              id: 1687,
            },
          ],
          relatives: [],
          cowType: "holstein",
          ageInYears: 5,
          weightKg: 0.0,
          temperatureAtBirth: 0.7,
          temperatureAtFirstBirthday: 4.5,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "LINDA 89",
              externalId: "NL 724138397",
              birthTimestamp: "2016-11-28T00:00:00",
              id: 1672,
            },
            cowType: "holstein",
            ageInYears: 2,
            weightKg: 0.0,
            temperatureAtBirth: 2.8,
            temperatureAtFirstBirthday: 7.2,
            type: "Cow",
            name: "Linda 93",
            externalId: "NL 882742821",
            birthTimestamp: "2020-02-26T00:00:00",
            id: 2149,
          },
          poll: {
            id: 1215,
            cow: null,
            name1: "Aagje",
            name2: "Aaltje",
            name3: "Aafke",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "LINDA 89",
          externalId: "NL 724138397",
          birthTimestamp: "2016-11-28T00:00:00",
          id: 1672,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 4.6,
          milkFatPercentage: 2.7,
          milkProteinPercentage: 3.3,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "BOUKJE 91",
            externalId: "NL 898036196",
            birthTimestamp: null,
            id: 2447,
          },
          grandMother: null,
          siblings: [
            {
              temperatureAtBirthDefinition: "warm",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "BOUKJE 91",
                externalId: "NL 898036196",
                birthTimestamp: null,
                id: 2447,
              },
              cowType: "holstein",
              ageInYears: 6,
              weightKg: 0.0,
              temperatureAtBirth: 24.1,
              temperatureAtFirstBirthday: 18.4,
              type: "Cow",
              name: "Cootje 100",
              externalId: "NL 724138311",
              birthTimestamp: "2016-08-24T00:00:00",
              id: 2066,
            },
          ],
          relatives: [],
          cowType: "holstein",
          ageInYears: 8,
          weightKg: 0.0,
          temperatureAtBirth: 18.9,
          temperatureAtFirstBirthday: 13.4,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "BOUKJE 97",
          externalId: "NL 639636869",
          birthTimestamp: "2014-09-05T00:00:00",
          id: 2448,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "warm",
          milkMoment: null,
          milkWeightInKgs: 7.2,
          milkFatPercentage: 3.5,
          milkProteinPercentage: 3.3,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "FR 3803078751",
            birthTimestamp: null,
            id: 7018,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 649285695",
            birthTimestamp: null,
            id: 7030,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 8.0,
          temperatureAtFirstBirthday: 9.7,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "SEMOULE 21",
              externalId: "NL 655090942",
              birthTimestamp: "2020-02-17T00:00:00",
              id: 1810,
            },
            cowType: "holstein",
            ageInYears: 0,
            weightKg: 0.0,
            temperatureAtBirth: 2.6,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "SEMOULE 22",
            externalId: "NL 572443861",
            birthTimestamp: "2022-01-08T00:00:00",
            id: 1811,
          },
          poll: {
            id: 1151,
            cow: null,
            name1: "Aafje",
            name2: "Aafke",
            name3: "Ada",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "SEMOULE 21",
          externalId: "NL 655090942",
          birthTimestamp: "2020-02-17T00:00:00",
          id: 1810,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "cold",
          milkMoment: null,
          milkWeightInKgs: 11.1,
          milkFatPercentage: 3.9,
          milkProteinPercentage: 2.9,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 418576762",
            birthTimestamp: null,
            id: 6546,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "MARION 211",
            externalId: "NL 727573205",
            birthTimestamp: null,
            id: 6545,
          },
          grandMother: null,
          siblings: [
            {
              temperatureAtBirthDefinition: "warm",
              temperatureAtFirstBirthdayDefinition: "cold",
              mother: {
                type: "Cow",
                name: "MARION 211",
                externalId: "NL 727573205",
                birthTimestamp: null,
                id: 6545,
              },
              cowType: "holstein",
              ageInYears: 4,
              weightKg: 0.0,
              temperatureAtBirth: 18.2,
              temperatureAtFirstBirthday: 6.0,
              type: "Cow",
              name: "Marion 213",
              externalId: "NL 733440023",
              birthTimestamp: "2018-05-06T00:00:00",
              id: 6564,
            },
          ],
          relatives: [],
          cowType: "holstein",
          ageInYears: 3,
          weightKg: 0.0,
          temperatureAtBirth: 9.9,
          temperatureAtFirstBirthday: 8.6,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "Marion 214",
              externalId: "NL 733440148",
              birthTimestamp: "2019-05-13T00:00:00",
              id: 1752,
            },
            cowType: "holstein",
            ageInYears: 0,
            weightKg: 0.0,
            temperatureAtBirth: 7.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Marion 215",
            externalId: "NL 572443847",
            birthTimestamp: "2022-01-04T00:00:00",
            id: 1753,
          },
          poll: {
            id: 1153,
            cow: null,
            name1: "Aafke",
            name2: "Aagje",
            name3: "Ada",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Marion 214",
          externalId: "NL 733440148",
          birthTimestamp: "2019-05-13T00:00:00",
          id: 1752,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 8.2,
          milkFatPercentage: 5.3,
          milkProteinPercentage: 3.7,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Moevchen 19",
            externalId: "NL 898036134",
            birthTimestamp: null,
            id: 2426,
          },
          grandMother: null,
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "cold",
              mother: {
                type: "Cow",
                name: "Moevchen 19",
                externalId: "NL 898036134",
                birthTimestamp: null,
                id: 2426,
              },
              cowType: "holstein",
              ageInYears: 5,
              weightKg: 0.0,
              temperatureAtBirth: 4.4,
              temperatureAtFirstBirthday: -1.9,
              type: "Cow",
              name: "ROESVELD 26",
              externalId: "NL 712239006",
              birthTimestamp: "2017-02-06T00:00:00",
              id: 2135,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Moevchen 19",
                externalId: "NL 898036134",
                birthTimestamp: null,
                id: 2426,
              },
              cowType: "holstein",
              ageInYears: 7,
              weightKg: 0.0,
              temperatureAtBirth: 4.4,
              temperatureAtFirstBirthday: 2.3,
              type: "Cow",
              name: "HAVEP JACCO",
              externalId: "NL 639637002",
              birthTimestamp: "2015-03-04T00:00:00",
              id: 2252,
            },
          ],
          relatives: [],
          cowType: "holstein",
          ageInYears: 8,
          weightKg: 0.0,
          temperatureAtBirth: 13.2,
          temperatureAtFirstBirthday: 6.0,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "Moevchen 21",
              externalId: "NL 639636737",
              birthTimestamp: "2014-04-28T00:00:00",
              id: 2349,
            },
            cowType: "holstein",
            ageInYears: 6,
            weightKg: 0.0,
            temperatureAtBirth: 14.8,
            temperatureAtFirstBirthday: 15.0,
            type: "Cow",
            name: "ROESVELD 25",
            externalId: "NL 724138241",
            birthTimestamp: "2016-07-14T00:00:00",
            id: 2169,
          },
          poll: {
            id: 1224,
            cow: null,
            name1: "Aaltje",
            name2: "Aartje",
            name3: "Aafke",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Moevchen 21",
          externalId: "NL 639636737",
          birthTimestamp: "2014-04-28T00:00:00",
          id: 2349,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 8.0,
          milkFatPercentage: 4.1,
          milkProteinPercentage: 3.7,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 970178277",
            birthTimestamp: null,
            id: 6691,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "OLGA",
            externalId: "DE 0940063907",
            birthTimestamp: null,
            id: 6690,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 9,
          weightKg: 0.0,
          temperatureAtBirth: 12.1,
          temperatureAtFirstBirthday: 12.5,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Moevchen 3",
          externalId: "NL 752636494",
          birthTimestamp: "2013-05-30T00:00:00",
          id: 6692,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 9.5,
          milkFatPercentage: 4.1,
          milkProteinPercentage: 3.4,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 429467307",
            birthTimestamp: null,
            id: 6828,
          },
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "cold",
            mother: {
              type: "Cow",
              name: null,
              externalId: "NL 523862574",
              birthTimestamp: null,
              id: 7127,
            },
            cowType: "holstein",
            ageInYears: 8,
            weightKg: 0.0,
            temperatureAtBirth: 10.4,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "LINDA 86",
            externalId: "NL 882165718",
            birthTimestamp: "2013-10-29T00:00:00",
            id: 6827,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 523862574",
            birthTimestamp: null,
            id: 7127,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "LINDA 86",
                externalId: "NL 882165718",
                birthTimestamp: "2013-10-29T00:00:00",
                id: 6827,
              },
              cowType: "holstein",
              ageInYears: 4,
              weightKg: 0.0,
              temperatureAtBirth: 6.1,
              temperatureAtFirstBirthday: 9.4,
              type: "Cow",
              name: "Linda 92",
              externalId: "NL 733439999",
              birthTimestamp: "2018-03-09T00:00:00",
              id: 6836,
            },
          ],
          relatives: [],
          cowType: "holstein",
          ageInYears: 6,
          weightKg: 0.0,
          temperatureAtBirth: 10.3,
          temperatureAtFirstBirthday: 5.5,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "LINDA 87",
          externalId: "NL 724138087",
          birthTimestamp: "2015-12-01T00:00:00",
          id: 6829,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 11.2,
          milkFatPercentage: 4.0,
          milkProteinPercentage: 3.4,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "holstein",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 970173683",
            birthTimestamp: null,
            id: 1733,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "DE 0534960223",
            birthTimestamp: null,
            id: 7145,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 9,
          weightKg: 0.0,
          temperatureAtBirth: 19.5,
          temperatureAtFirstBirthday: 14.2,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "Leticia 8151",
          externalId: "DE 0538128151",
          birthTimestamp: "2013-08-18T00:00:00",
          id: 7146,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 15.4,
          milkFatPercentage: 3.4,
          milkProteinPercentage: 3.2,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "warm",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "Moevchen 19",
              externalId: "NL 898036134",
              birthTimestamp: null,
              id: 2426,
            },
            cowType: "holstein",
            ageInYears: 8,
            weightKg: 0.0,
            temperatureAtBirth: 13.2,
            temperatureAtFirstBirthday: 6.0,
            type: "Cow",
            name: "Moevchen 21",
            externalId: "NL 639636737",
            birthTimestamp: "2014-04-28T00:00:00",
            id: 2349,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Moevchen 19",
            externalId: "NL 898036134",
            birthTimestamp: null,
            id: 2426,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Moevchen 21",
                externalId: "NL 639636737",
                birthTimestamp: "2014-04-28T00:00:00",
                id: 2349,
              },
              cowType: "holstein",
              ageInYears: 2,
              weightKg: 0.0,
              temperatureAtBirth: 12.3,
              temperatureAtFirstBirthday: 14.3,
              type: "Cow",
              name: "Moevchen 37",
              externalId: "NL 882742751",
              birthTimestamp: "2020-10-02T00:00:00",
              id: 2350,
            },
          ],
          relatives: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "cold",
              mother: {
                type: "Cow",
                name: "Moevchen 19",
                externalId: "NL 898036134",
                birthTimestamp: null,
                id: 2426,
              },
              cowType: "holstein",
              ageInYears: 5,
              weightKg: 0.0,
              temperatureAtBirth: 4.4,
              temperatureAtFirstBirthday: -1.9,
              type: "Cow",
              name: "ROESVELD 26",
              externalId: "NL 712239006",
              birthTimestamp: "2017-02-06T00:00:00",
              id: 2135,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Moevchen 19",
                externalId: "NL 898036134",
                birthTimestamp: null,
                id: 2426,
              },
              cowType: "holstein",
              ageInYears: 7,
              weightKg: 0.0,
              temperatureAtBirth: 4.4,
              temperatureAtFirstBirthday: 2.3,
              type: "Cow",
              name: "HAVEP JACCO",
              externalId: "NL 639637002",
              birthTimestamp: "2015-03-04T00:00:00",
              id: 2252,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "warm",
              mother: {
                type: "Cow",
                name: "HAVEP JACCO",
                externalId: "NL 639637002",
                birthTimestamp: "2015-03-04T00:00:00",
                id: 2252,
              },
              cowType: "holstein",
              ageInYears: 5,
              weightKg: 0.0,
              temperatureAtBirth: 16.1,
              temperatureAtFirstBirthday: 22.7,
              type: "Cow",
              name: "Roesveld 27",
              externalId: "NL 712239075",
              birthTimestamp: "2017-07-01T00:00:00",
              id: 2253,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "ROESVELD 26",
                externalId: "NL 712239006",
                birthTimestamp: "2017-02-06T00:00:00",
                id: 2135,
              },
              cowType: "holstein",
              ageInYears: 3,
              weightKg: 0.0,
              temperatureAtBirth: 6.9,
              temperatureAtFirstBirthday: 7.4,
              type: "Cow",
              name: "Roesveld 30",
              externalId: "NL 733440092",
              birthTimestamp: "2019-02-24T00:00:00",
              id: 2161,
            },
          ],
          cowType: "holstein",
          ageInYears: 6,
          weightKg: 0.0,
          temperatureAtBirth: 14.8,
          temperatureAtFirstBirthday: 15.0,
          firstCalf: {
            temperatureAtBirthDefinition: "warm",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "ROESVELD 25",
              externalId: "NL 724138241",
              birthTimestamp: "2016-07-14T00:00:00",
              id: 2169,
            },
            cowType: "holstein",
            ageInYears: 2,
            weightKg: 0.0,
            temperatureAtBirth: 20.0,
            temperatureAtFirstBirthday: 17.4,
            type: "Cow",
            name: "ROESVELD 29",
            externalId: "NL 882742674",
            birthTimestamp: "2020-09-14T00:00:00",
            id: 2170,
          },
          poll: {
            id: 1212,
            cow: null,
            name1: "Aafje",
            name2: "Aaltje",
            name3: "Aagje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "ROESVELD 25",
          externalId: "NL 724138241",
          birthTimestamp: "2016-07-14T00:00:00",
          id: 2169,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 8.5,
          milkFatPercentage: 5.4,
          milkProteinPercentage: 3.9,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Kitty 34",
            externalId: "NL 752636519",
            birthTimestamp: null,
            id: 6715,
          },
          grandMother: null,
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "warm",
              mother: {
                type: "Cow",
                name: "Kitty 34",
                externalId: "NL 752636519",
                birthTimestamp: null,
                id: 6715,
              },
              cowType: "holstein",
              ageInYears: 5,
              weightKg: 0.0,
              temperatureAtBirth: 5.8,
              temperatureAtFirstBirthday: 9.2,
              type: "Cow",
              name: "KOEAN MAR 37",
              externalId: "NL 712238964",
              birthTimestamp: "2016-12-22T00:00:00",
              id: 6909,
            },
          ],
          relatives: [],
          cowType: "holstein",
          ageInYears: 6,
          weightKg: 0.0,
          temperatureAtBirth: 6.7,
          temperatureAtFirstBirthday: 1.9,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "cold",
            mother: {
              type: "Cow",
              name: "Kitty 36",
              externalId: "NL 724138102",
              birthTimestamp: "2016-01-02T00:00:00",
              id: 1975,
            },
            cowType: "holstein",
            ageInYears: 2,
            weightKg: 0.0,
            temperatureAtBirth: 7.4,
            temperatureAtFirstBirthday: -5.6,
            type: "Cow",
            name: "Kitty 55",
            externalId: "NL 882742869",
            birthTimestamp: "2020-02-08T00:00:00",
            id: 2092,
          },
          poll: {
            id: 1169,
            cow: null,
            name1: "Aagje",
            name2: "Aafke",
            name3: "Aartje",
            name1Votes: 0,
            name2Votes: 1,
            name3Votes: 0,
            chosenName: "Aafke",
            startDate: "2022-08-30T00:00:00",
            endDate: "2022-09-06T00:00:00",
          },
          type: "Cow",
          name: "Kitty 36",
          externalId: "NL 724138102",
          birthTimestamp: "2016-01-02T00:00:00",
          id: 1975,
        },
        {
          temperatureAtBirthDefinition: "cold",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 9.4,
          milkFatPercentage: 3.6,
          milkProteinPercentage: 3.0,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 429759583",
            birthTimestamp: null,
            id: 1916,
          },
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "warm",
            mother: {
              type: "Cow",
              name: "AUKJE 81",
              externalId: "NL 428332662",
              birthTimestamp: null,
              id: 6793,
            },
            cowType: "holstein",
            ageInYears: 10,
            weightKg: 0.0,
            temperatureAtBirth: -1.4,
            temperatureAtFirstBirthday: 11.3,
            type: "Cow",
            name: "AUKJE 92",
            externalId: "NL 898036141",
            birthTimestamp: "2012-01-30T00:00:00",
            id: 1915,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "AUKJE 81",
            externalId: "NL 428332662",
            birthTimestamp: null,
            id: 6793,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "cold",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "AUKJE 92",
                externalId: "NL 898036141",
                birthTimestamp: "2012-01-30T00:00:00",
                id: 1915,
              },
              cowType: "holstein",
              ageInYears: 4,
              weightKg: 0.0,
              temperatureAtBirth: 2.2,
              temperatureAtFirstBirthday: 10.4,
              type: "Cow",
              name: "Aukje 102",
              externalId: "NL 712239190",
              birthTimestamp: "2017-11-29T00:00:00",
              id: 1917,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "AUKJE 92",
                externalId: "NL 898036141",
                birthTimestamp: "2012-01-30T00:00:00",
                id: 1915,
              },
              cowType: "holstein",
              ageInYears: 2,
              weightKg: 0.0,
              temperatureAtBirth: 4.6,
              temperatureAtFirstBirthday: 8.6,
              type: "Cow",
              name: "Aukje 105",
              externalId: "NL 882742876",
              birthTimestamp: "2019-11-19T00:00:00",
              id: 1928,
            },
          ],
          relatives: [],
          cowType: "holstein",
          ageInYears: 4,
          weightKg: 0.0,
          temperatureAtBirth: 2.2,
          temperatureAtFirstBirthday: 10.4,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "Aukje 101",
              externalId: "NL 712239183",
              birthTimestamp: "2017-11-29T00:00:00",
              id: 1882,
            },
            cowType: "holstein",
            ageInYears: 1,
            weightKg: 0.0,
            temperatureAtBirth: 6.5,
            temperatureAtFirstBirthday: 4.6,
            type: "Cow",
            name: "Aukje 108",
            externalId: "NL 657642985",
            birthTimestamp: "2020-11-23T00:00:00",
            id: 1883,
          },
          poll: {
            id: 1160,
            cow: null,
            name1: "Aartje",
            name2: "Aafje",
            name3: "Aaltje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Aukje 101",
          externalId: "NL 712239183",
          birthTimestamp: "2017-11-29T00:00:00",
          id: 1882,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 21.1,
          milkFatPercentage: 1.9,
          milkProteinPercentage: 3.3,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Aukje 14",
            externalId: "NL 532435051",
            birthTimestamp: null,
            id: 6934,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 8,
          weightKg: 0.0,
          temperatureAtBirth: 14.4,
          temperatureAtFirstBirthday: 12.5,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "Aukje 22",
              externalId: "NL 639637949",
              birthTimestamp: "2014-06-16T00:00:00",
              id: 1939,
            },
            cowType: "holstein",
            ageInYears: 5,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 4.2,
            type: "Cow",
            name: "aukje 105",
            externalId: "NL 712239020",
            birthTimestamp: "2017-02-11T00:00:00",
            id: 1989,
          },
          poll: {
            id: 1220,
            cow: null,
            name1: "Aafke",
            name2: "Aagje",
            name3: "Aaltje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Aukje 22",
          externalId: "NL 639637949",
          birthTimestamp: "2014-06-16T00:00:00",
          id: 1939,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 8.1,
          milkFatPercentage: 4.7,
          milkProteinPercentage: 3.5,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 927733371",
            birthTimestamp: null,
            id: 2379,
          },
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "NELIE 11",
              externalId: "NL 496434417",
              birthTimestamp: null,
              id: 6875,
            },
            cowType: "holstein",
            ageInYears: 9,
            weightKg: 0.0,
            temperatureAtBirth: 7.0,
            temperatureAtFirstBirthday: 4.6,
            type: "Cow",
            name: "NELIE 12",
            externalId: "NL 752636362",
            birthTimestamp: "2012-11-27T00:00:00",
            id: 2378,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "NELIE 11",
            externalId: "NL 496434417",
            birthTimestamp: null,
            id: 6875,
          },
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 3,
          weightKg: 0.0,
          temperatureAtBirth: 15.9,
          temperatureAtFirstBirthday: 8.9,
          firstCalf: {
            temperatureAtBirthDefinition: "warm",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "Nelie 20",
              externalId: "NL 882742908",
              birthTimestamp: "2019-10-14T00:00:00",
              id: 2380,
            },
            cowType: "holstein",
            ageInYears: 0,
            weightKg: 0.0,
            temperatureAtBirth: 8.2,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Nelie 21",
            externalId: "NL 572443924",
            birthTimestamp: "2022-02-20T00:00:00",
            id: 11861,
          },
          poll: {
            id: 1152,
            cow: null,
            name1: "Aaltje",
            name2: "Aafje",
            name3: "Ada",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Nelie 20",
          externalId: "NL 882742908",
          birthTimestamp: "2019-10-14T00:00:00",
          id: 2380,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 6.8,
          milkFatPercentage: 4.6,
          milkProteinPercentage: 3.2,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 667284944",
            birthTimestamp: null,
            id: 7105,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 7.7,
          temperatureAtFirstBirthday: 3.2,
          firstCalf: null,
          poll: null,
          type: "Cow",
          name: "faith 3",
          externalId: "NL 655090872",
          birthTimestamp: "2020-01-13T00:00:00",
          id: 7106,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 9.5,
          milkFatPercentage: 5.3,
          milkProteinPercentage: 3.5,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 470938683",
            birthTimestamp: null,
            id: 1686,
          },
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: null,
              externalId: "NL 440161437",
              birthTimestamp: null,
              id: 7073,
            },
            cowType: "holstein",
            ageInYears: 9,
            weightKg: 0.0,
            temperatureAtBirth: 7.9,
            temperatureAtFirstBirthday: 12.4,
            type: "Cow",
            name: "LINDA 85",
            externalId: "NL 882165631",
            birthTimestamp: "2013-10-12T00:00:00",
            id: 1671,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 440161437",
            birthTimestamp: null,
            id: 7073,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "LINDA 85",
                externalId: "NL 882165631",
                birthTimestamp: "2013-10-12T00:00:00",
                id: 1671,
              },
              cowType: "holstein",
              ageInYears: 6,
              weightKg: 0.0,
              temperatureAtBirth: 7.0,
              temperatureAtFirstBirthday: 7.3,
              type: "Cow",
              name: "LINDA 88",
              externalId: "NL 724138094",
              birthTimestamp: "2015-12-14T00:00:00",
              id: 1713,
            },
            {
              temperatureAtBirthDefinition: "cold",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "LINDA 85",
                externalId: "NL 882165631",
                birthTimestamp: "2013-10-12T00:00:00",
                id: 1671,
              },
              cowType: "holstein",
              ageInYears: 5,
              weightKg: 0.0,
              temperatureAtBirth: 0.7,
              temperatureAtFirstBirthday: 4.5,
              type: "Cow",
              name: "LINDA 89",
              externalId: "NL 724138397",
              birthTimestamp: "2016-11-28T00:00:00",
              id: 1672,
            },
          ],
          relatives: [],
          cowType: "holstein",
          ageInYears: 4,
          weightKg: 0.0,
          temperatureAtBirth: 0.8,
          temperatureAtFirstBirthday: 3.6,
          firstCalf: {
            temperatureAtBirthDefinition: "warm",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "Linda 91",
              externalId: "NL 733439982",
              birthTimestamp: "2018-02-05T00:00:00",
              id: 1687,
            },
            cowType: "holstein",
            ageInYears: 0,
            weightKg: 0.0,
            temperatureAtBirth: 9.7,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Linda 98",
            externalId: "NL 572443830",
            birthTimestamp: "2021-12-29T00:00:00",
            id: 1796,
          },
          poll: {
            id: 1161,
            cow: null,
            name1: "Ada",
            name2: "Aagje",
            name3: "Aafje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Linda 91",
          externalId: "NL 733439982",
          birthTimestamp: "2018-02-05T00:00:00",
          id: 1687,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 11.4,
          milkFatPercentage: 6.3,
          milkProteinPercentage: 4.0,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "MOEVCHEN 21",
            externalId: "NL 296122103",
            birthTimestamp: null,
            id: 6736,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 12,
          weightKg: 0.0,
          temperatureAtBirth: 18.3,
          temperatureAtFirstBirthday: 18.5,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "MOEVCHEN 24",
              externalId: "NL 532435145",
              birthTimestamp: "2010-07-05T00:00:00",
              id: 2001,
            },
            cowType: "holstein",
            ageInYears: 2,
            weightKg: 0.0,
            temperatureAtBirth: 13.3,
            temperatureAtFirstBirthday: 17.2,
            type: "Cow",
            name: "MOEVCHEN 36",
            externalId: "NL 882742799",
            birthTimestamp: "2020-06-07T00:00:00",
            id: 2002,
          },
          poll: {
            id: 1222,
            cow: null,
            name1: "Aagje",
            name2: "Aafje",
            name3: "Aaltje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "MOEVCHEN 24",
          externalId: "NL 532435145",
          birthTimestamp: "2010-07-05T00:00:00",
          id: 2001,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 12.6,
          milkFatPercentage: 4.1,
          milkProteinPercentage: 3.7,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "BOUKJE 87",
            externalId: "NL 496234420",
            birthTimestamp: null,
            id: 6784,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 9,
          weightKg: 0.0,
          temperatureAtBirth: 25.0,
          temperatureAtFirstBirthday: 20.3,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "BOUKJE 95",
              externalId: "NL 752636571",
              birthTimestamp: "2013-08-01T00:00:00",
              id: 2245,
            },
            cowType: "holstein",
            ageInYears: 7,
            weightKg: 0.0,
            temperatureAtBirth: 13.1,
            temperatureAtFirstBirthday: 11.0,
            type: "Cow",
            name: "HAVEP JACCO",
            externalId: "NL 724137983",
            birthTimestamp: "2015-10-08T00:00:00",
            id: 1963,
          },
          poll: {
            id: 1172,
            cow: null,
            name1: "Aafke",
            name2: "Aartje",
            name3: "Aagje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "BOUKJE 95",
          externalId: "NL 752636571",
          birthTimestamp: "2013-08-01T00:00:00",
          id: 2245,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 12.6,
          milkFatPercentage: 3.4,
          milkProteinPercentage: 2.7,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 429759583",
            birthTimestamp: null,
            id: 1916,
          },
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "KITTY 43",
              externalId: "NL 296121984",
              birthTimestamp: null,
              id: 6994,
            },
            cowType: "holstein",
            ageInYears: 10,
            weightKg: 0.0,
            temperatureAtBirth: 15.2,
            temperatureAtFirstBirthday: 16.8,
            type: "Cow",
            name: "KITTY 47",
            externalId: "NL 898036329",
            birthTimestamp: "2012-09-02T00:00:00",
            id: 1892,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "KITTY 43",
            externalId: "NL 296121984",
            birthTimestamp: null,
            id: 6994,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "KITTY 47",
                externalId: "NL 898036329",
                birthTimestamp: "2012-09-02T00:00:00",
                id: 1892,
              },
              cowType: "holstein",
              ageInYears: 7,
              weightKg: 0.0,
              temperatureAtBirth: 6.1,
              temperatureAtFirstBirthday: 7.6,
              type: "Cow",
              name: "KITTY 51",
              externalId: "NL 639636953",
              birthTimestamp: "2014-11-20T00:00:00",
              id: 1611,
            },
            {
              temperatureAtBirthDefinition: "warm",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "KITTY 47",
                externalId: "NL 898036329",
                birthTimestamp: "2012-09-02T00:00:00",
                id: 1892,
              },
              cowType: "holstein",
              ageInYears: 2,
              weightKg: 0.0,
              temperatureAtBirth: 14.5,
              temperatureAtFirstBirthday: 9.4,
              type: "Cow",
              name: "Kitty 56",
              externalId: "NL 882742807",
              birthTimestamp: "2020-04-21T00:00:00",
              id: 1894,
            },
          ],
          relatives: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "KITTY 43",
                externalId: "NL 296121984",
                birthTimestamp: null,
                id: 6994,
              },
              cowType: "holstein",
              ageInYears: 8,
              weightKg: 733.0,
              temperatureAtBirth: 4.9,
              temperatureAtFirstBirthday: 0.0,
              type: "Cow",
              name: "KITTY 49",
              externalId: "NL 639636681",
              birthTimestamp: "2014-02-10T00:00:00",
              id: 6996,
            },
          ],
          cowType: "holstein",
          ageInYears: 4,
          weightKg: 0.0,
          temperatureAtBirth: 4.2,
          temperatureAtFirstBirthday: 5.5,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "Kitty",
              externalId: "NL 733440441",
              birthTimestamp: "2018-01-06T00:00:00",
              id: 1781,
            },
            cowType: "holstein",
            ageInYears: 1,
            weightKg: 0.0,
            temperatureAtBirth: 4.8,
            temperatureAtFirstBirthday: 9.8,
            type: "Cow",
            name: "Kitty 98",
            externalId: "NL 657642978",
            birthTimestamp: "2020-11-20T00:00:00",
            id: 1782,
          },
          poll: {
            id: 1149,
            cow: null,
            name1: "Aaltje",
            name2: "Aafke",
            name3: "Ada",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Kitty",
          externalId: "NL 733440441",
          birthTimestamp: "2018-01-06T00:00:00",
          id: 1781,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 9.1,
          milkFatPercentage: 4.3,
          milkProteinPercentage: 3.6,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 532435176",
            birthTimestamp: null,
            id: 2417,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 8,
          weightKg: 0.0,
          temperatureAtBirth: 20.0,
          temperatureAtFirstBirthday: 19.7,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "warm",
            mother: {
              type: "Cow",
              name: "Havep Force",
              externalId: "NL 639636838",
              birthTimestamp: "2014-07-16T00:00:00",
              id: 2190,
            },
            cowType: "holstein",
            ageInYears: 2,
            weightKg: 0.0,
            temperatureAtBirth: 7.5,
            temperatureAtFirstBirthday: 8.7,
            type: "Cow",
            name: "Cootje 23",
            externalId: "NL 882742838",
            birthTimestamp: "2020-02-18T00:00:00",
            id: 2213,
          },
          poll: {
            id: 1225,
            cow: null,
            name1: "Aaltje",
            name2: "Aafke",
            name3: "Aagje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Havep Force",
          externalId: "NL 639636838",
          birthTimestamp: "2014-07-16T00:00:00",
          id: 2190,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 12.2,
          milkFatPercentage: 2.1,
          milkProteinPercentage: 2.9,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 713398841",
            birthTimestamp: null,
            id: 1893,
          },
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "KITTY 43",
              externalId: "NL 296121984",
              birthTimestamp: null,
              id: 6994,
            },
            cowType: "holstein",
            ageInYears: 10,
            weightKg: 0.0,
            temperatureAtBirth: 15.2,
            temperatureAtFirstBirthday: 16.8,
            type: "Cow",
            name: "KITTY 47",
            externalId: "NL 898036329",
            birthTimestamp: "2012-09-02T00:00:00",
            id: 1892,
          },
          grandMother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "KITTY 43",
            externalId: "NL 296121984",
            birthTimestamp: null,
            id: 6994,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "KITTY 47",
                externalId: "NL 898036329",
                birthTimestamp: "2012-09-02T00:00:00",
                id: 1892,
              },
              cowType: "holstein",
              ageInYears: 7,
              weightKg: 0.0,
              temperatureAtBirth: 6.1,
              temperatureAtFirstBirthday: 7.6,
              type: "Cow",
              name: "KITTY 51",
              externalId: "NL 639636953",
              birthTimestamp: "2014-11-20T00:00:00",
              id: 1611,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "KITTY 47",
                externalId: "NL 898036329",
                birthTimestamp: "2012-09-02T00:00:00",
                id: 1892,
              },
              cowType: "holstein",
              ageInYears: 4,
              weightKg: 0.0,
              temperatureAtBirth: 4.2,
              temperatureAtFirstBirthday: 5.5,
              type: "Cow",
              name: "Kitty",
              externalId: "NL 733440441",
              birthTimestamp: "2018-01-06T00:00:00",
              id: 1781,
            },
          ],
          relatives: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "KITTY 43",
                externalId: "NL 296121984",
                birthTimestamp: null,
                id: 6994,
              },
              cowType: "holstein",
              ageInYears: 8,
              weightKg: 733.0,
              temperatureAtBirth: 4.9,
              temperatureAtFirstBirthday: 0.0,
              type: "Cow",
              name: "KITTY 49",
              externalId: "NL 639636681",
              birthTimestamp: "2014-02-10T00:00:00",
              id: 6996,
            },
          ],
          cowType: "holstein",
          ageInYears: 2,
          weightKg: 0.0,
          temperatureAtBirth: 14.5,
          temperatureAtFirstBirthday: 9.4,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "cold",
            mother: {
              type: "Cow",
              name: "Kitty 56",
              externalId: "NL 882742807",
              birthTimestamp: "2020-04-21T00:00:00",
              id: 1894,
            },
            cowType: "holstein",
            ageInYears: 0,
            weightKg: 0.0,
            temperatureAtBirth: 14.1,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "Kitty 103",
            externalId: "NL 572443979",
            birthTimestamp: "2022-05-20T00:00:00",
            id: 12087,
          },
          poll: {
            id: 1162,
            cow: null,
            name1: "Aafje",
            name2: "Aagje",
            name3: "Ada",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "Kitty 56",
          externalId: "NL 882742807",
          birthTimestamp: "2020-04-21T00:00:00",
          id: 1894,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 9.3,
          milkFatPercentage: 4.9,
          milkProteinPercentage: 3.9,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: "NELIE 11",
            externalId: "NL 496434417",
            birthTimestamp: null,
            id: 6875,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 9,
          weightKg: 0.0,
          temperatureAtBirth: 7.0,
          temperatureAtFirstBirthday: 4.6,
          firstCalf: {
            temperatureAtBirthDefinition: "warm",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "NELIE 12",
              externalId: "NL 752636362",
              birthTimestamp: "2012-11-27T00:00:00",
              id: 2378,
            },
            cowType: "holstein",
            ageInYears: 3,
            weightKg: 0.0,
            temperatureAtBirth: 15.9,
            temperatureAtFirstBirthday: 8.9,
            type: "Cow",
            name: "Nelie 20",
            externalId: "NL 882742908",
            birthTimestamp: "2019-10-14T00:00:00",
            id: 2380,
          },
          poll: {
            id: 1221,
            cow: null,
            name1: "Aafje",
            name2: "Aaltje",
            name3: "Ada",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "NELIE 12",
          externalId: "NL 752636362",
          birthTimestamp: "2012-11-27T00:00:00",
          id: 2378,
        },
        {
          temperatureAtBirthDefinition: "warm",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 12.0,
          milkFatPercentage: 3.5,
          milkProteinPercentage: 3.1,
          father: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 429759583",
            birthTimestamp: null,
            id: 1916,
          },
          mother: {
            temperatureAtBirthDefinition: "none",
            temperatureAtFirstBirthdayDefinition: "none",
            mother: null,
            cowType: "friesian",
            ageInYears: null,
            weightKg: 0.0,
            temperatureAtBirth: 0.0,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 383729572",
            birthTimestamp: null,
            id: 7024,
          },
          grandMother: null,
          siblings: [],
          relatives: [],
          cowType: "holstein",
          ageInYears: 8,
          weightKg: 0.0,
          temperatureAtBirth: 15.6,
          temperatureAtFirstBirthday: 10.9,
          firstCalf: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "BOUKJE 98",
              externalId: "NL 639636890",
              birthTimestamp: "2014-10-04T00:00:00",
              id: 1848,
            },
            cowType: "holstein",
            ageInYears: 4,
            weightKg: 0.0,
            temperatureAtBirth: 4.9,
            temperatureAtFirstBirthday: 5.6,
            type: "Cow",
            name: "Boukje 102",
            externalId: "NL 712239222",
            birthTimestamp: "2018-01-15T00:00:00",
            id: 1849,
          },
          poll: {
            id: 1165,
            cow: null,
            name1: "Aaltje",
            name2: "Aartje",
            name3: "Aagje",
            name1Votes: 0,
            name2Votes: 0,
            name3Votes: 0,
            chosenName: null,
            startDate: null,
            endDate: null,
          },
          type: "Cow",
          name: "BOUKJE 98",
          externalId: "NL 639636890",
          birthTimestamp: "2014-10-04T00:00:00",
          id: 1848,
        },
        {
          temperatureAtBirthDefinition: "normal",
          temperatureAtFirstBirthdayDefinition: "normal",
          milkMoment: null,
          milkWeightInKgs: 4.1,
          milkFatPercentage: 3.5,
          milkProteinPercentage: 3.4,
          father: null,
          mother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "KITTY 47",
              externalId: "NL 898036329",
              birthTimestamp: "2012-09-02T00:00:00",
              id: 1892,
            },
            cowType: "holstein",
            ageInYears: 7,
            weightKg: 0.0,
            temperatureAtBirth: 6.1,
            temperatureAtFirstBirthday: 7.6,
            type: "Cow",
            name: "KITTY 51",
            externalId: "NL 639636953",
            birthTimestamp: "2014-11-20T00:00:00",
            id: 1611,
          },
          grandMother: {
            temperatureAtBirthDefinition: "normal",
            temperatureAtFirstBirthdayDefinition: "normal",
            mother: {
              type: "Cow",
              name: "KITTY 43",
              externalId: "NL 296121984",
              birthTimestamp: null,
              id: 6994,
            },
            cowType: "holstein",
            ageInYears: 10,
            weightKg: 0.0,
            temperatureAtBirth: 15.2,
            temperatureAtFirstBirthday: 16.8,
            type: "Cow",
            name: "KITTY 47",
            externalId: "NL 898036329",
            birthTimestamp: "2012-09-02T00:00:00",
            id: 1892,
          },
          siblings: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "KITTY 51",
                externalId: "NL 639636953",
                birthTimestamp: "2014-11-20T00:00:00",
                id: 1611,
              },
              cowType: "holstein",
              ageInYears: 1,
              weightKg: 0.0,
              temperatureAtBirth: 11.9,
              temperatureAtFirstBirthday: 11.3,
              type: "Cow",
              name: "Kitty 100",
              externalId: "NL 572443777",
              birthTimestamp: "2021-09-21T00:00:00",
              id: 1612,
            },
          ],
          relatives: [
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "KITTY 47",
                externalId: "NL 898036329",
                birthTimestamp: "2012-09-02T00:00:00",
                id: 1892,
              },
              cowType: "holstein",
              ageInYears: 4,
              weightKg: 0.0,
              temperatureAtBirth: 4.2,
              temperatureAtFirstBirthday: 5.5,
              type: "Cow",
              name: "Kitty",
              externalId: "NL 733440441",
              birthTimestamp: "2018-01-06T00:00:00",
              id: 1781,
            },
            {
              temperatureAtBirthDefinition: "warm",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "KITTY 47",
                externalId: "NL 898036329",
                birthTimestamp: "2012-09-02T00:00:00",
                id: 1892,
              },
              cowType: "holstein",
              ageInYears: 2,
              weightKg: 0.0,
              temperatureAtBirth: 14.5,
              temperatureAtFirstBirthday: 9.4,
              type: "Cow",
              name: "Kitty 56",
              externalId: "NL 882742807",
              birthTimestamp: "2020-04-21T00:00:00",
              id: 1894,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "normal",
              mother: {
                type: "Cow",
                name: "Kitty",
                externalId: "NL 733440441",
                birthTimestamp: "2018-01-06T00:00:00",
                id: 1781,
              },
              cowType: "holstein",
              ageInYears: 1,
              weightKg: 0.0,
              temperatureAtBirth: 4.8,
              temperatureAtFirstBirthday: 9.8,
              type: "Cow",
              name: "Kitty 98",
              externalId: "NL 657642978",
              birthTimestamp: "2020-11-20T00:00:00",
              id: 1782,
            },
            {
              temperatureAtBirthDefinition: "normal",
              temperatureAtFirstBirthdayDefinition: "cold",
              mother: {
                type: "Cow",
                name: "Kitty 56",
                externalId: "NL 882742807",
                birthTimestamp: "2020-04-21T00:00:00",
                id: 1894,
              },
              cowType: "holstein",
              ageInYears: 0,
              weightKg: 0.0,
              temperatureAtBirth: 14.1,
              temperatureAtFirstBirthday: 0.0,
              type: "Cow",
              name: "Kitty 103",
              externalId: "NL 572443979",
              birthTimestamp: "2022-05-20T00:00:00",
              id: 12087,
            },
          ],
          cowType: "holstein",
          ageInYears: 6,
          weightKg: 0.0,
          temperatureAtBirth: 13.9,
          temperatureAtFirstBirthday: 13.1,
          firstCalf: {
            temperatureAtBirthDefinition: "warm",
            temperatureAtFirstBirthdayDefinition: "cold",
            mother: {
              type: "Cow",
              name: "Kitty 53",
              externalId: "NL 724138380",
              birthTimestamp: "2016-09-20T00:00:00",
              id: 1629,
            },
            cowType: "friesian",
            ageInYears: 3,
            weightKg: 0.0,
            temperatureAtBirth: 23.9,
            temperatureAtFirstBirthday: 0.0,
            type: "Cow",
            name: null,
            externalId: "NL 642041711",
            birthTimestamp: "2018-08-04T00:00:00",
            id: 2113,
          },
          poll: null,
          type: "Cow",
          name: "Kitty 53",
          externalId: "NL 724138380",
          birthTimestamp: "2016-09-20T00:00:00",
          id: 1629,
        },
      ],
      specialEventCode: "MotherDaughter",
      id: 345,
    },
    languages: null,
    brand: {
      type: "Brand",
      name: "Lely",
      description:
        " Als internationaal familiebedrijf in de agrisector zijn we dagelijks bezig het leven van veehouders aangenamer te maken met innovatieve oplossingen en gerichte services.",
    },
    producingDevice: {
      name: "orbiter",
      type: "orbiter",
      clickable: true,
      description: null,
    },
    description: {
      nl: "Op de dobbelhoeve doen we al meer dan 20 jaar aan biologische landbouw. De ouders van boer Guus beseften lang geleden al dat biologische landbouw het beste brengt voor koe en milieu.",
    },
    animals: [
      {
        animalType: "cat",
        name: "Kitty",
        imageData:
          "https://ldp1elelymilkywaydataprd.blob.core.windows.net/testuser005images/c48a5c80-d62e-4ac1-b0b8-bc317da85175.jpeg",
        textBlockIndex: "1",
        story: {
          en: "I like to see myself as the CEO of the farm as I oversee the entire farm operation and even the farmer himself all the time. My ‘job’ requires me to travel a lot.",
          ens: "I like to see myself as the CEO of the farm as I oversee the entire farm operation and even the farmer himself all the time. My ‘job’ requires me to travel a lot.",
          nl: "I like to see myself as the CEO of the farm as I oversee the entire farm operation and even the farmer himself all the time. My ‘job’ requires me to travel a lot.",
          nls: "I like to see myself as the CEO of the farm as I oversee the entire farm operation and even the farmer himself all the time. My ‘job’ requires me to travel a lot.",
        },
      },
      {
        animalType: "smallDog",
        name: "Hondje",
        imageData:
          "https://ldp1elelymilkywaydataprd.blob.core.windows.net/testuser005images/5012049d-cd91-4d53-ace2-6b262ad56513.jpeg",
        textBlockIndex: "2",
        story: {
          en: "Hey, I am the smallest dog in the farm and my name is Hondje. I have a lot of friends in the farm and I really like to hang with my biggest friend, the farmer.",
          ens: "Hey, I am the smallest dog in the farm and my name is Hondje. I have a lot of friends in the farm and I really like to hang with my biggest friend, the farmer.",
          nl: "Hey, I am the smallest dog in the farm and my name is Hondje. I have a lot of friends in the farm and I really like to hang with my biggest friend, the farmer.",
          nls: "Hey, I am the smallest dog in the farm and my name is Hondje. I have a lot of friends in the farm and I really like to hang with my biggest friend, the farmer.",
        },
      },
      {
        animalType: "chicken",
        name: "Polly",
        imageData:
          "https://ldp1elelymilkywaydataprd.blob.core.windows.net/testuser005images/311c7fc6-c53e-4f51-9d21-f39e62873798.jpeg",
        textBlockIndex: "3",
        story: {
          en: "I like to hangout with my other animal friends. Being in the farm feels really nice.",
          ens: "I like to hangout with my other animal friends. Being in the farm feels really nice.",
          nl: "I like to hangout with my other animal friends. Being in the farm feels really nice.",
          nls: "I like to hangout with my other animal friends. Being in the farm feels really nice.",
        },
      },
      {
        animalType: "bigDog",
        name: "Bo",
        imageData:
          "https://ldp1elelymilkywaydataprd.blob.core.windows.net/testuser005images/3c3e6c20-c7cb-4df6-8bed-828a180949cc.jpeg",
        textBlockIndex: "4",
        story: {
          en: "Hey, I am the chief security officer of the farm. My job is very responsible but I would do anything for my cow friends and the farmer.",
          ens: "Hey, I am the chief security officer of the farm. My job is very responsible but I would do anything for my cow friends and the farmer.",
          nl: "Hey, I am the chief security officer of the farm. My job is very responsible but I would do anything for my cow friends and the farmer.",
          nls: "Hey, I am the chief security officer of the farm. My job is very responsible but I would do anything for my cow friends and the farmer.",
        },
      },
      {
        animalType: "horse",
        name: "Champion",
        imageData:
          "https://ldp1elelymilkywaydataprd.blob.core.windows.net/testuser005images/45f55bf4-8186-4d99-a7e9-7e9d6c943d47.jpeg",
        textBlockIndex: "5",
        story: {
          en: "I really like it in the farm where I spend most of my day chilling and enjoying the fine weather.",
          ens: "I really like it in the farm where I spend most of my day chilling and enjoying the fine weather.",
          nl: "I really like it in the farm where I spend most of my day chilling and enjoying the fine weather.",
          nls: "I really like it in the farm where I spend most of my day chilling and enjoying the fine weather.",
        },
      },
      {
        animalType: "alpaca",
        name: "Penny",
        imageData:
          "https://ldp1elelymilkywaydataprd.blob.core.windows.net/testuser005images/f0692c9a-df05-4f9c-b846-47ae37e7c4c4.jpeg",
        textBlockIndex: "6",
        story: {
          en: "The farm would not be as funny without my personality. I bring character and cows love me for my great sense of humour.",
          ens: "The farm would not be as funny without my personality. I bring character and cows love me for my great sense of humour.",
          nl: "The farm would not be as funny without my personality. I bring character and cows love me for my great sense of humour.",
          nls: "The farm would not be as funny without my personality. I bring character and cows love me for my great sense of humour.",
        },
      },
      {
        animalType: "llama",
        name: "Bernard",
        imageData:
          "https://ldp1elelymilkywaydataprd.blob.core.windows.net/testuser005images/10bbbe9e-3f71-465a-a2fb-9e302965cdf6.jpeg",
        textBlockIndex: "7",
        story: {
          en: "I can compete with most of the cows in the farm for the title ‘most charming animal’ in the farm. I have my own character and sometimes I might spit a little, but I have a lot of friends here.",
          ens: "I can compete with most of the cows in the farm for the title ‘most charming animal’ in the farm. I have my own character and sometimes I might spit a little, but I have a lot of friends here.",
          nl: "I can compete with most of the cows in the farm for the title ‘most charming animal’ in the farm. I have my own character and sometimes I might spit a little, but I have a lot of friends here.",
          nls: "I can compete with most of the cows in the farm for the title ‘most charming animal’ in the farm. I have my own character and sometimes I might spit a little, but I have a lot of friends here.",
        },
      },
      {
        animalType: "sheep",
        name: "Dolly",
        imageData:
          "https://ldp1elelymilkywaydataprd.blob.core.windows.net/testuser005images/4136a371-6e6b-4699-acbc-e1c60eba9df8.jpeg",
        textBlockIndex: "8",
        story: {
          en: "I like to spend my days chilling and enjoying the good food I can found around the farm. I have a lot of friends here.",
          ens: "I like to spend my days chilling and enjoying the good food I can found around the farm. I have a lot of friends here.",
          nl: "I like to spend my days chilling and enjoying the good food I can found around the farm. I have a lot of friends here.",
          nls: "I like to spend my days chilling and enjoying the good food I can found around the farm. I have a lot of friends here.",
        },
      },
    ],
    location: {
      name: "Tilburg",
      averageTemperatures: [
        {
          month: 1,
          temperatureAverage: 3.0,
          averageTempUpperLimit: 7.0,
          averageTempLowerLimit: -1.4000000000000004,
        },
        {
          month: 2,
          temperatureAverage: 3.5,
          averageTempUpperLimit: 7.5,
          averageTempLowerLimit: -0.5999999999999996,
        },
        {
          month: 3,
          temperatureAverage: 6.0,
          averageTempUpperLimit: 10.0,
          averageTempLowerLimit: 1.5,
        },
        {
          month: 4,
          temperatureAverage: 9.0,
          averageTempUpperLimit: 13.0,
          averageTempLowerLimit: 4.8,
        },
        {
          month: 5,
          temperatureAverage: 13.0,
          averageTempUpperLimit: 17.0,
          averageTempLowerLimit: 9.4,
        },
        {
          month: 6,
          temperatureAverage: 15.5,
          averageTempUpperLimit: 19.5,
          averageTempLowerLimit: 11.7,
        },
        {
          month: 7,
          temperatureAverage: 18.0,
          averageTempUpperLimit: 22.0,
          averageTempLowerLimit: 14.3,
        },
        {
          month: 8,
          temperatureAverage: 17.5,
          averageTempUpperLimit: 21.5,
          averageTempLowerLimit: 13.4,
        },
        {
          month: 9,
          temperatureAverage: 14.5,
          averageTempUpperLimit: 18.5,
          averageTempLowerLimit: 10.2,
        },
        {
          month: 10,
          temperatureAverage: 11.0,
          averageTempUpperLimit: 15.0,
          averageTempLowerLimit: 6.9,
        },
        {
          month: 11,
          temperatureAverage: 6.5,
          averageTempUpperLimit: 10.5,
          averageTempLowerLimit: 2.5,
        },
        {
          month: 12,
          temperatureAverage: 3.5,
          averageTempUpperLimit: 7.5,
          averageTempLowerLimit: -0.2999999999999998,
        },
      ],
    },
    farmTheme: "milkbottle",
    style: "Style1",
    imageData: {
      nl: "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/9ceb1dcd-f68b-40ee-a4c0-7878b976465f.jpeg",
      en: "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/32c3bd82-de8d-4fee-82d8-eac00152540d.jpeg",
      ens: "https://ldp1elelymilkywaydatastg.blob.core.windows.net/testuser005images/2e995373-7746-4416-bd36-9287fd9c28c1.jpeg",
    },
    shop: null,
    solarPanel: null,
    id: 31,
  },
};
export const MOCK_BREED_DATA = [
  {
    name: "holstein",
    isSelected: true,
    probabilityPercentage: 50,
    colors: [
      {
        name: "cow-holstein-friesian-one",
        isSelected: true,
      },
      {
        name: "cow-holstein-friesian-two",
        isSelected: true,
      },
      {
        name: "cow-holstein-friesian-three",
        isSelected: false,
      },
    ],
  },
  {
    name: "cow-blaarkop",
    isSelected: false,
    probabilityPercentage: 60,
    colors: [
      {
        name: "cow-blaarkop-one",
        isSelected: true,
      },
      {
        name: "cow-blaarkop-two",
        isSelected: true,
      },
    ],
  },
  {
    name: "cow-jersey",
    isSelected: false,
    probabilityPercentage: 0,
    colors: [
      {
        name: "cow-jersey-one",
        isSelected: false,
      },
      {
        name: "cow-jersey-two",
        isSelected: false,
      },
    ],
  },
  {
    name: "cow-lakenvender",
    isSelected: false,
    probabilityPercentage: 50,
    colors: [
      {
        name: "cow-lakenvender-one",
        isSelected: true,
      },
      {
        name: "cow-lakenvender-two",
        isSelected: true,
      },
    ],
  },
  {
    name: "cow-pride",
    isSelected: true,
    probabilityPercentage: 50,
    colors: [
      {
        name: "cow-pride-one",
        isSelected: true,
      },
    ],
  },
];

export const api = {
  getVirtualFarmDetails: async function (accessToken, entityId) {
    return new Promise((resolve) => {
      resolve(MOCK_GET_VIRTUAL_FARM_RESPONSE);
    });
  },
};
