import {
  WEATHER_TYPE_CLEAR,
  WEATHER_TYPE_CLOUDY,
  WEATHER_TYPE_DRIZZLE,
  WEATHER_TYPE_HEAVY_RAIN,
  WEATHER_TYPE_MIST,
  WEATHER_TYPE_PARTIALLY_CLOUDY,
  WEATHER_TYPE_RAIN,
  WEATHER_TYPE_RAIN_OVERCAST,
  WEATHER_TYPE_RAIN_PARTIALLY_CLOUDY,
  WEATHER_TYPE_SNOW,
  WEATHER_TYPE_SNOWSTORM,
  WEATHER_TYPE_SUNNY,
  WEATHER_TYPE_THUNDERSTORM,
} from "../../common/constants";
import React from "react";
import { useSelector } from "react-redux";
import "./Clouds.scss";
import {
  getRandomNumberBetweenMinAndMax,
  getRandomPositionForACloud,
} from "./CloudUtil";

/** Clouds component */
function Clouds({
  viewportWidthInPx,
  viewportHeightInPx,
  cssClassName,
  isAdultThemeApplied,
}) {
  let { precipitation, cloudCover } = useSelector((state) => state.storyMoment);

  const CLOUDS_MIN_X_COORDINATE = 0;
  const CLOUDS_MAX_X_COORDINATE = viewportWidthInPx * 2;
  const CLOUDS_MIN_Y_COORDINATE = 0;
  const CLOUDS_MAX_Y_COORDINATE = 0.5 * viewportHeightInPx;

  const BIG_CLOUDS_BACKGROUND_START_INDEX = 1;
  const BIG_CLOUDS_BACKGROUND_END_INDEX = 12;
  const SMALL_CLOUDS_BACKGROUND_START_INDEX = 13;
  const SMALL_CLOUDS_BACKGROUND_END_INDEX = 19;

  function getJSXForACloud(isCloudSmall, isCloudDark) {
    let randomCloudPosition = getRandomPositionForACloud(
      CLOUDS_MIN_X_COORDINATE,
      CLOUDS_MAX_X_COORDINATE,
      CLOUDS_MIN_Y_COORDINATE,
      CLOUDS_MAX_Y_COORDINATE,
    );
    let cloudStyleIndex = isCloudSmall
      ? getRandomNumberBetweenMinAndMax(
          SMALL_CLOUDS_BACKGROUND_START_INDEX,
          SMALL_CLOUDS_BACKGROUND_END_INDEX,
        )
      : getRandomNumberBetweenMinAndMax(
          BIG_CLOUDS_BACKGROUND_START_INDEX,
          BIG_CLOUDS_BACKGROUND_END_INDEX,
        );
    let cloudCssClassname = "cloud cloud-" + cloudStyleIndex;
    cloudCssClassname = isCloudDark
      ? cloudCssClassname + "--grey cloud--dark"
      : cloudCssClassname;
    cloudCssClassname = isCloudSmall
      ? cloudCssClassname + " cloud--small"
      : cloudCssClassname + " cloud--big";
    cloudCssClassname = isAdultThemeApplied
      ? cloudCssClassname + " cloud--adult"
      : cloudCssClassname;
    let cloudJSX = (
      <div
        className={cloudCssClassname}
        style={{
          left: randomCloudPosition.left,
          top: randomCloudPosition.top,
        }}
      ></div>
    );
    return cloudJSX;
  }

  function fillTheSkyWithClouds(
    totalNumberOfClouds,
    areCloudsMostlySmallOnes,
    areCloudsDark,
  ) {
    let cloudsJSXElements = [];

    for (var i = 0; i < totalNumberOfClouds; i++) {
      cloudsJSXElements.push(
        getJSXForACloud(areCloudsMostlySmallOnes, areCloudsDark),
      );
    }

    return cloudsJSXElements;
  }

  let numberOfCloudsToGenerate;
  let areCloudsSmall;
  let areCloudsDark;
  let MAX_NUMBER_OF_CLOUDS_TO_GENERATE = 10;
  numberOfCloudsToGenerate =
    cloudCover > 0 ? (cloudCover / 100) * MAX_NUMBER_OF_CLOUDS_TO_GENERATE : 0;
  if (numberOfCloudsToGenerate > 0.9 * MAX_NUMBER_OF_CLOUDS_TO_GENERATE) {
    areCloudsSmall = false;
    areCloudsDark = true;
  } else {
    if (precipitation < 0) {
      areCloudsSmall = false;
      areCloudsDark = false;
    } else {
      areCloudsSmall = false;
      areCloudsDark = false;
    }
  }

  return (
    <div
      className={
        areCloudsDark ? "clouds-container clouds--dark" : "clouds-container"
      }
    >
      {fillTheSkyWithClouds(
        numberOfCloudsToGenerate,
        areCloudsSmall,
        areCloudsDark,
      )}
    </div>
  );
}
export default Clouds;
