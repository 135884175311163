import React from "react";
import {
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import Graphic from "../Graphic/Graphic";
import "./LiveDataIndicator.scss";

/** LiveDataIndicator component */
function LiveDataIndicator({ cowDetails }) {
  const liveDataPopupCssClassname = "liveDataIndicator";
  const cowHeadCssClassname =
    "liveDataIndicator--cowHead familyMember--" + cowDetails.cowSubBreed;
  return (
    cowDetails.name || cowDetails.cowSubBreed ?
    <div className={liveDataPopupCssClassname}>
      <Graphic cssClassName="liveDataIndicator--liveIcon" />
      <span className="liveDataIndicator--title">
        {translateWithValues("ASTRONAUT_POPUP_LIVE_DATA_TEXT", {
          cowName: cowDetails.name,
        })}
      </span>
      <div className="liveDataIndicator--arrow"></div>
      <div className={cowHeadCssClassname} />
    </div> : null
  );
}
export default LiveDataIndicator;
