import {
  DAY_START_HOUR,
  DAY_START_MINUTE,
  PLANET_SIZE_TO_VW_RATIO,
  DARK_HOURS_END,
  DARK_HOURS_START,
  DAYBREAK_HOURS_END,
  DAYBREAK_HOURS_START,
  FULL_SKY_OPACITY_HOURS_END,
  FULL_SKY_OPACITY_HOURS_START,
  SUNSET_HOURS_END,
  SUNSET_HOURS_START,
  PLANETS_STARTING_X_POSITION_RATIO,
  PLANETS_STARTING_Y_POSITION_RATIO,
} from "../../common/constants";
import _ from "lodash";
import moment from "moment";

export const getPlanetsPositionByCurrentMomentAndViewportSize20 = (
  currentMoment,
  viewportWidthInPx,
  viewportHeightInPx,
  sunriseHour,
  sunriseMinutes,
  sunsetHour,
  sunsetMinutes,
) => {
  let planetSizeInPx = PLANET_SIZE_TO_VW_RATIO * viewportWidthInPx;
  let planetRadiusInPx = planetSizeInPx / 2;
  let planetOrbitSizeInPx = 0.7 * viewportWidthInPx;
  let planetOrbitRadiusInPx = planetOrbitSizeInPx / 2;

  let planetAngleInDegrees = getPlanetAngleInDegrees(
    currentMoment,
    sunriseHour,
    sunriseMinutes,
  );
  let sunAngleInDegrees = planetAngleInDegrees - 180;
  let moonAngleInDegrees = planetAngleInDegrees;

  let sunAngleInRadians = getAngleInDegreesAsRadians(sunAngleInDegrees);
  let moonAngleInRadians = getAngleInDegreesAsRadians(moonAngleInDegrees);

  let { left: sunLeftPosition, top: sunTopPosition } =
    getCirclePointXYByOrbitRadiusAngleAndPlanetRadius(
      planetOrbitRadiusInPx,
      sunAngleInRadians,
      planetRadiusInPx,
    );
  let { left: moonLeftPosition, top: moonTopPosition } =
    getCirclePointXYByOrbitRadiusAngleAndPlanetRadius(
      planetOrbitRadiusInPx,
      moonAngleInRadians,
      planetRadiusInPx,
    );
  return {
    sun: {
      left: sunLeftPosition + 0.3 * viewportWidthInPx,
      top: sunTopPosition + viewportHeightInPx,
    },
    moon: {
      left: moonLeftPosition + 0.3 * viewportWidthInPx,
      top: moonTopPosition + viewportHeightInPx,
    },
  };
};

export const getPlanetAngleInDegrees = (
  currentMoment,
  sunriseHour,
  sunriseMinutes,
) => {
  const SECONDS_IN_24_HOURS = 86400;
  let currentDayStart = getDayStartMomentByCurrentMoment(
    currentMoment,
    sunriseHour,
    sunriseMinutes,
  );
  let secondsPassedSinceDayBreak = currentMoment.diff(
    currentDayStart,
    "seconds",
  );
  let currentMomentTo24HoursRatio = (
    secondsPassedSinceDayBreak / SECONDS_IN_24_HOURS
  ).toFixed(2);
  let roughAngleInDegrees = parseInt(currentMomentTo24HoursRatio * 360);
  let planetAngleInDegrees =
    roughAngleInDegrees < 0 ? 360 + roughAngleInDegrees : roughAngleInDegrees;
  return planetAngleInDegrees;
};

export const getAngleInDegreesAsRadians = (angleInDegrees) => {
  return angleInDegrees * (Math.PI / 180);
};

export const getDayStartMomentByCurrentMoment = (
  currentMoment,
  sunriseHour,
  sunriseMinutes,
) => {
  let dayStartMoment = _.cloneDeep(currentMoment).toDate();
  dayStartMoment.setHours(sunriseHour);
  dayStartMoment.setMinutes(sunriseMinutes);
  dayStartMoment.setSeconds(0);
  return moment(dayStartMoment);
};

export const getCirclePointXYByOrbitRadiusAngleAndPlanetRadius = (
  planetOrbitRadius,
  angleInRadians,
  planetRadius,
) => {
  let circlePointX = planetOrbitRadius * Math.sin(angleInRadians);
  let circlePointY = planetOrbitRadius * Math.cos(angleInRadians);
  //  circlePointX -= planetRadius / 2;
  //  circlePointY -= planetRadius / 2;
  return {
    left: circlePointX,
    top: circlePointY,
  };
};

export const getSunPositionByCurrentMomentAndViewportSize = (
  currentMoment,
  viewportWidthInPx,
  viewportHeightInPx,
  sunriseHour,
  sunriseMinutes,
  sunsetHour,
  sunsetMinutes,
) => {
  let currentHour = currentMoment.hours();
  let planetSizeInPx = PLANET_SIZE_TO_VW_RATIO * viewportWidthInPx;
  let rightmostPlanetXPosition =
    (1 - PLANETS_STARTING_X_POSITION_RATIO) * viewportWidthInPx -
    planetSizeInPx / 2;
  let leftmostPlanetXPosition =
    PLANETS_STARTING_X_POSITION_RATIO * viewportWidthInPx - planetSizeInPx / 2;
  let middlePlanetXPosition = 0.5 * viewportWidthInPx - planetSizeInPx / 2;
  let bottommostPlanetYPosition =
    (1 - PLANETS_STARTING_Y_POSITION_RATIO) * viewportHeightInPx;
  let topmostPlanetYPosition =
    PLANETS_STARTING_Y_POSITION_RATIO * viewportHeightInPx - planetSizeInPx / 2;

  let isExactly12 = currentHour === 12;
  if (isExactly12) {
    return { top: topmostPlanetYPosition, left: middlePlanetXPosition };
  }

  let isNight = currentHour >= sunsetHour || currentHour <= sunriseHour;
  if (isNight) {
    return { top: bottommostPlanetYPosition, left: rightmostPlanetXPosition }; // position the sun out of screen (practically make it invisible)
  }

  let isAfternoon = currentHour > 12;
  if (isAfternoon) {
    let distanceFromMiddayToDarkHoursStartRatio =
      (currentHour - 12) / sunsetHour;

    let distanceInPixelsBetweenMiddayXPositionAndDarkHoursPosition =
      leftmostPlanetXPosition - middlePlanetXPosition;
    let distanceFromMiddayInPixels =
      distanceFromMiddayToDarkHoursStartRatio *
      distanceInPixelsBetweenMiddayXPositionAndDarkHoursPosition;
    let afternoonPlanetPositionX =
      middlePlanetXPosition + distanceFromMiddayInPixels;

    let distanceInPixelsBetweenMiddayYPositionAndDarkHoursPosition =
      bottommostPlanetYPosition - topmostPlanetYPosition;
    let distanceFromTopmostPositionInPixels =
      distanceFromMiddayToDarkHoursStartRatio *
      distanceInPixelsBetweenMiddayYPositionAndDarkHoursPosition;
    let afternoonPlanetPositionY =
      topmostPlanetYPosition + distanceFromTopmostPositionInPixels;

    return {
      top: afternoonPlanetPositionY,
      left: afternoonPlanetPositionX,
    };
  }

  let isMorning = currentHour >= sunriseHour && currentHour < 12;
  if (isMorning) {
    let distanceFromDayBreakStart = currentHour - sunriseHour;
    let distanceToMiddayRatio = distanceFromDayBreakStart / (12 - sunriseHour);

    let distanceInPixelsBetweenLowestPlanetYAndMiddayPlanetY =
      bottommostPlanetYPosition - topmostPlanetYPosition;
    let pixelsToMiddayY =
      (1 - distanceToMiddayRatio) *
      distanceInPixelsBetweenLowestPlanetYAndMiddayPlanetY; // ??TODO: wrap those calculations up

    let distanceInPixelsBetweenLeftmostPlanetXAndMiddayPlanetX =
      middlePlanetXPosition - leftmostPlanetXPosition;
    let pixelsToMiddleX =
      distanceToMiddayRatio *
      distanceInPixelsBetweenLeftmostPlanetXAndMiddayPlanetX;
    let morningPlanetPositionX = leftmostPlanetXPosition + pixelsToMiddleX;
    let morningPlanetPositionY = topmostPlanetYPosition + pixelsToMiddayY;

    return {
      top: morningPlanetPositionY,
      left: morningPlanetPositionX,
    };
  }
};

export const getMoonPositionByCurrentMomentAndViewportSize = (
  currentMoment,
  viewportWidthInPx,
  viewportHeightInPx,
  sunsetHour,
  sunriseHour,
) => {
  let currentHour = currentMoment.hours();
  let planetSizeInPx = PLANET_SIZE_TO_VW_RATIO * viewportWidthInPx;
  let isNight = currentHour >= sunsetHour || currentHour <= sunriseHour;
  let middlePlanetXPosition = 0.5 * viewportWidthInPx - planetSizeInPx / 2;
  let topmostPlanetYPosition =
    PLANETS_STARTING_Y_POSITION_RATIO * viewportHeightInPx - planetSizeInPx / 2;
  let bottommostPlanetYPosition =
    (1 - PLANETS_STARTING_Y_POSITION_RATIO) * viewportHeightInPx;
  let rightmostPlanetXPosition =
    (1 - PLANETS_STARTING_X_POSITION_RATIO) * viewportWidthInPx -
    planetSizeInPx / 2;
  if (isNight) {
    return { top: topmostPlanetYPosition, left: middlePlanetXPosition };
  } else {
    return { top: bottommostPlanetYPosition, left: rightmostPlanetXPosition }; // position the sun out of screen (practically make it invisible)
  }
};
