import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
import { getDairyFactorySvgFilePath } from "../../common/svgUtil";
import Graphic from "../Graphic/Graphic";
import React, { useCallback } from "react";
import "./DairyFactory.scss";
import { measureResponsive } from "../../components/VirtualFarm/util";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";
/** DairyFactory component */
function DairyFactory({
  dairyFactoryData,
  dairyFactoryCssClassname,
  dairyFactoryXCoordinate,
  dairyFactoryYCoordinate,
  dairyFactoryScaleX,
  dairyFactoryClickHandlerFn,
  additionalCssClassnames,
  farmTheme,
  doPositionFixed,
  isInteractable,
  isClickable,
}) {
  let transformValue =
    "translateX(" +
    dairyFactoryXCoordinate +
    ") translateY(" +
    dairyFactoryYCoordinate +
    ") scaleX(" +
    dairyFactoryScaleX +
    ")";
  let dairyFactoryPositionStyles = doPositionFixed
    ? {}
    : {
        transform: transformValue,
      };
  let dairyFactorySubmoduleCssClassname =
    dairyFactoryCssClassname + " " + additionalCssClassnames;
  dairyFactorySubmoduleCssClassname += isInteractable
    ? " virtualFarm--interactable virtualFarm--highlighted"
    : "";
  dairyFactorySubmoduleCssClassname = isClickable
    ? dairyFactorySubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : dairyFactorySubmoduleCssClassname;
  let dairyFactorySvgPath = getDairyFactorySvgFilePath(farmTheme);
  const onCompletedCallback = useCallback((e) => {}, []);
  const onErrorCallback = useCallback((e) => {
    console.log("@onError e:", e);
  }, []);

  return (
    <div
      style={{
        left: measureResponsive(63.8473),
        width: measureResponsive(11.5455),
        top: measureResponsive(19.8584),
        height: measureResponsive(13.854),
      }}
      className={dairyFactorySubmoduleCssClassname}
      onClick={() => {
        dairyFactoryClickHandlerFn(dairyFactoryData);
      }}
    >
      <h2
        style={{
          transform: "scaleX(" + dairyFactoryScaleX + ")",
        }}
        className={dairyFactoryCssClassname + "--title"}
      >
        {dairyFactoryData.name}
      </h2>
      {isInteractable ? (
        <DynamicInlineSVG
          svgFullFilePath={dairyFactorySvgPath}
          themeName={farmTheme}
          onCompleted={onCompletedCallback}
          onError={onErrorCallback}
        />
      ) : null}
      {isInteractable ? <Graphic cssClassName="is-actor-pointed" /> : null}
    </div>
  );
}
export default DairyFactory;
