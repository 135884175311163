import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./Flow.scss";
import useDetectSamsung from "../../hooks/useDetectSamsungInternetBrowser";
import {
  getLatestFlowTranslateX,
  getLatestSwipeDeltaX,
  setLatestFlowTranslateX,
  setLatestSwipeDeltaX,
  getIsSwipeInProgress,
  setIsSwipeInProgress,
  getAreSlidesContainerTransitionsDisabled,
  setAreSlidesContainerTransitionsDisabled,
  setSlideIndexToTransitionTo,
  getSlideIndexToTransitionTo,
  setCurrentlyActiveSlideIndex,
  getIsOnboardingOver,
  setIsOnboardingOver,
} from "../../common/globals";
import SlidesContainer from "../../components/SlidesContainer/SlidesContainer";
import VirtualFarmPage from "../../containers/VirtualFarmPage/VirtualFarmPage";
import Story from "../../components/Story/Story";
import {
  STORY_VARIETY_COW_LATEST,
  STORY_VARIETY_COW_HISTORY,
  STORY_VARIETY_ASTRONAUT_HISTORY,
  getCowDailyStoryTextBlocksByCowData,
  PRE_ACTOR_STORY_FORBIDDEN_SWIPE_RANGES,
  SLIDES_WITH_SWIPE_END_ONLY,
  STORY_VARIETY_ORBITER_YOGHURT,
  STORY_VARIETY_SUSTAINABILITY_STORY,
} from "../../common/stories";
import { slideTitleChanged } from "../../common/components/SlideTitle/actions";
import {
  ACTOR_TYPE_BRAND,
  ACTOR_TYPE_CHEESE_FACTORY,
  ACTOR_TYPE_COW,
  ACTOR_TYPE_FARMER,
  ACTOR_TYPE_ICE_CREAM_FACTORY,
  ACTOR_TYPE_LUNA,
  ACTOR_TYPE_MILK_FACTORY,
  ACTOR_TYPE_PET,
  ACTOR_TYPE_TRACTOR,
  ACTOR_TYPE_VECTOR,
  ACTOR_TYPE_JUNO,
  ACTOR_TYPE_ASTRONAUT,
  CHEESE_FACTORY_CSS_CLASSNAME,
  COW_CSS_CLASSNAME,
  FARMER_CSS_CLASSNAME,
  ICE_CREAM_FACTORY_CSS_CLASSNAME,
  MILK_FACTORY_CSS_CLASSNAME,
  SIDE_MENU_OPTION_TO_THE_FARM,
  SIDE_MENU_OPTION_HOW_TO,
  VIRTUAL_FARM_CSS_CLASSNAME,
  SIDE_MENU_OPTION_AGE_SELECTION,
  AGE_SELECTION_CSS_CLASSNAME,
  ACTOR_TYPE_MIJN_MELK,
  ACTOR_TYPE_DISCOVERY,
  ACTOR_TYPE_COLLECTOR,
  TRANSITION_DURATION_IN_SECONDS,
  ACTOR_TYPE_SUSTAINABILITY_PLATE,
} from "../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import Cow from "../Cow/Cow";
import Farmer from "../Farmer/Farmer";
import CheeseFactory from "../CheeseFactory/CheeseFactory";
import MilkFactory from "../MilkFactory/MilkFactory";
import IceCreamFactory from "../IceCreamFactory/IceCreamFactory";
import ParallaxCowDaily from "../Parallax/ParallaxCowDaily/ParallaxCowDaily";
import {
  setCurrentActorConfig,
  getCurrentActorConfig,
} from "../../common/actorUtil";
import AgeSelectionPage from "../../containers/AgeSelectionPage/AgeSelectionPage";
import { isSimpleModeActive } from "../../localization/TranslationUtil";
import useWindowDimensions from "../../hooks/useViewportDimensions";
import useOrientationChange from "../../hooks/useOrientationChange";
import { updateStoryMoment } from "../Story/actions";
import ReactGA from "react-ga4";
import {
  setSunriseHour,
  setLatestMoment,
  getLatestMoment,
  setLatestWeatherData,
  setSunsetHour,
} from "../../common/currentMomentUtil";
import moment from "moment";
import {
  getWeatherDataForTime,
  initializeCowDailyMilkingsWithWeatherData,
} from "../Weather/WeatherUtil";
import { showPopup } from "../../common/components/PopupContainer/actions";
import ParallaxCowHistory from "../Parallax/ParallaxCowHistory/ParallaxCowHistory";
import ParallaxCowHistoryForAdults from "../Parallax/ParallaxCowHistoryForAdults/ParallaxCowHistoryForAdults";
import BirthdayPartyHat from "../../components/BirthdayPartyHat/BirthdayPartyHat";
import BirthdayPartyFlute from "../../components/BirthdayPartyFlute/BirthdayPartyFlute";
import ParallaxRobotStory from "../../components/Parallax/ParallaxRobotStory/ParallaxRobotStory";
import {
  popConfetti,
  resetConfetti,
} from "../../common/components/Confetti/actions";
import ViewportSizeDisplay from "../../components/Helpers/ViewportSizeDisplay";
import {
  logMessageColorfully,
  printConsoleHeading,
} from "../../common/consoleUtil";
import { setGoogleAnalyticTracking } from "../../common/googleAnalyticTracking/actions";
import {
  LAST_STORY_SLIDE_REACHED,
  MILKYWAY_SCREEN_REACHED_CATEGORY,
} from "../../common/googleAnalyticTracking/types";
import {
  debouncedGoogleAnalyticsEventHandler,
  debouncedGoogleAnalyticsPageVisitHandler,
} from "../../api/googleAnalytics/googleAnalyticsEventTracker";
import {
  resetAgeSelection,
  updateFarmThemeBasedOnSelectedAge,
} from "../VirtualFarm/actions";
import ParallaxCowDailyForAdults from "../Parallax/ParallaxCowDailyForAdults/ParallaxCowDailyForAdults";
import ParallaxRobotStoryForAdults from "../Parallax/ParallaxRobotStoryForAdults/ParallaxRobotStoryForAdults";
import SamsungPopup from "../SamsungPopup/SamsungPopup";
import { isUserActiveInLastNMinutes } from "../../common/activity";
import { selectActor } from "../Actor/actions";

function Flow({
  onboardingStoryConfig,
  cowStoryHistoryConfig,
  cowStoryLatestConfig,
  farmerStoryConfig,
  vectorRobotStoryConfig,
  junoRobotStoryConfig,
  astronautRobotStoryConfig,
  astronautRobotLiveDataStoryConfig,
  lelyBrandStoryConfig,
  mijnMelkStoryConfig,
  orbiterRobotStoryConfig,
  orbiterRobotYoghurtStoryConfig,
  cheeseFactoryStoryConfig,
  discoveryStoryConfig,
  lunaStoryConfig,
  iceCreamFactoryStoryConfig,
  onboardingCow,
  startPoint,
  setStartPoint,
  themeName,
  onSideMenuNavigationCompleteFn,
  onScrollOrSwipeFn,
  cowGrazingData,
  farmFeaturesDetails,
  sustainabilityStoryConfig,
  animalWelfareStoryConfig,
  farmId,
}) {
  const dispatch = useDispatch();
  const {
    popupItemDetails,
    selectedVariety,
    storyActorData,
    showActorStoryTrigger,
  } = useSelector((state) => state.popupDetails);
  let { width: VIEWPORT_WIDTH_IN_PX, height: VIEWPORT_HEIGHT_IN_PX } =
    useWindowDimensions();
  let { isSamsungInternetBrowser } = useDetectSamsung();
  console.log("isSamsungInternetBrowser:", isSamsungInternetBrowser);
  const viewportWidthInPxRef = useRef(VIEWPORT_WIDTH_IN_PX);
  const viewportHeightInPxRef = useRef(VIEWPORT_HEIGHT_IN_PX);
  let isAppOpenedInLandscapeMode = VIEWPORT_WIDTH_IN_PX > VIEWPORT_HEIGHT_IN_PX;
  VIEWPORT_WIDTH_IN_PX = isAppOpenedInLandscapeMode
    ? VIEWPORT_WIDTH_IN_PX
    : VIEWPORT_HEIGHT_IN_PX;
  VIEWPORT_HEIGHT_IN_PX = isAppOpenedInLandscapeMode
    ? VIEWPORT_HEIGHT_IN_PX
    : VIEWPORT_WIDTH_IN_PX;
  viewportWidthInPxRef.current = VIEWPORT_WIDTH_IN_PX;
  viewportHeightInPxRef.current = VIEWPORT_HEIGHT_IN_PX;

  const orientationChange = useOrientationChange(() => {
    //window && window.location && window.location.reload();
  });

  let onboardingStorySlides = Story({
    id: "onboardingStory",
    config: onboardingStoryConfig,
  });
  const SCROLL_INCREMENT_IN_PX = 0.5 * VIEWPORT_WIDTH_IN_PX;
  let AGE_SELECTION_SLIDE_INDEX = onboardingStorySlides.length;
  let ONBOARDING_STORY_SLIDE_INDEX =
    onboardingStorySlides.length === 0 || onboardingStorySlides.length > 1
      ? 0
      : onboardingStorySlides.length;
  let VIRTUAL_FARM_SLIDE_INDEX = onboardingStorySlides.length + 1;
  let ACTOR_STORY_FIRST_SLIDE_INDEX = onboardingStorySlides.length + 2;
  let firstSlideIndex =
    startPoint === SIDE_MENU_OPTION_TO_THE_FARM ? VIRTUAL_FARM_SLIDE_INDEX : 0;
  firstSlideIndex =
    startPoint === SIDE_MENU_OPTION_HOW_TO
      ? VIRTUAL_FARM_SLIDE_INDEX
      : firstSlideIndex;
  firstSlideIndex =
    startPoint === SIDE_MENU_OPTION_AGE_SELECTION
      ? AGE_SELECTION_SLIDE_INDEX
      : firstSlideIndex;
  const [cowDailyDetails, setCowDailyDetails] = useState({});
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const selectedSlideTitle = useRef("");
  const [flowTranslateX, setFlowTranslateX] = useState(
    firstSlideIndex * VIEWPORT_WIDTH_IN_PX,
  );
  const [selectedActorStoryConfig, setSelectedActorStoryConfig] = useState(
    _.cloneDeep(cowStoryLatestConfig),
  );
  let { selectedActorDetails } = useSelector(
    (state) => state.selectedActorDetails,
  );
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  useEffect(() => {}, [selectedActorStoryConfig]);
  let { confettiTrigger } = useSelector((state) => state.confettiDetails);

  useEffect(() => {}, [cowDailyDetails]);

  //#region Inactivity checking logic
  const isAgeSelectedInTheFarm =  farmDetails.isAdultSelected;
  const checkInactivityIntervalRef = useRef();
  const USER_MAX_IDLE_TIME_IN_MINUTES = 10; // PREV: 10;
  const INACTIVITY_CHECK_INTERVAL_IN_MILLISECONDS = 300000; // PREV: 300000; // 300000 // check every 5 minutes
  useEffect(() => {
    checkInactivityIntervalRef.current = setInterval(() => {
      if ((!isUserActiveInLastNMinutes(USER_MAX_IDLE_TIME_IN_MINUTES)) && (isAgeSelectedInTheFarm)) { 
        console.log('@EZE isAgeSelectedInTheFarm', isAgeSelectedInTheFarm);
        transitionTo(AGE_SELECTION_SLIDE_INDEX, undefined, true);
        dispatch(selectActor({}));
        dispatch(showPopup());
        dispatch(resetAgeSelection({}));
      }
    }, INACTIVITY_CHECK_INTERVAL_IN_MILLISECONDS);
    return () => {
      if (checkInactivityIntervalRef.current) {
        clearInterval(checkInactivityIntervalRef.current);
      }
    };
  }, [isAgeSelectedInTheFarm]);
  //#endregion
  useEffect(() => {
    setFlowTranslateX(selectedSlideIndex * VIEWPORT_WIDTH_IN_PX);
  }, [VIEWPORT_WIDTH_IN_PX]);
  
  useEffect(() => {
    if (startPoint) {
      if (selectedSlideIndex === firstSlideIndex) {
        // disable navigating from one slide to the same slide (doesn't make sense to have that enabled)
        return;
      }
      updateFlowContainerCssClassname(
        firstSlideIndex * VIEWPORT_WIDTH_IN_PX,
        VIEWPORT_WIDTH_IN_PX,
      );
      transitionTo(firstSlideIndex, undefined, true); 
      if (!(startPoint === SIDE_MENU_OPTION_AGE_SELECTION)) {
        dispatch(updateFarmThemeBasedOnSelectedAge(false));
      }
    }
  }, [startPoint]);

  useEffect(() => {
    if (popupItemDetails && popupItemDetails.type) {
      setStartPoint("");
      handleShowActorStoryCallback(
        popupItemDetails,
        selectedVariety,
        storyActorData,
      );
    }
  }, [showActorStoryTrigger]);

  const handleShowActorStoryCallback = useCallback(
    (selectedItem, storyVariety, detailedActorData) => {
      let selectedItemType = selectedItem.type.toLowerCase();
      let newActorStoryConfig;
      dispatch(showPopup({}));
      switch (selectedItemType) {
        case ACTOR_TYPE_COW:
          if (storyVariety === STORY_VARIETY_COW_LATEST) {
            let cowLatestStoryConfig = getCowDailyStoryTextBlocksByCowData(
              detailedActorData,
              cowGrazingData,
              farmFeaturesDetails,
              farmDetails,
            );
            setCowDailyDetails(detailedActorData);
            initializeCowDailyMilkingsWithWeatherData(detailedActorData);
            initializeStoryMomentInTheBeginningOfCowDailyStory(
              detailedActorData,
            );
            newActorStoryConfig = _.cloneDeep(cowLatestStoryConfig);
          } else if (storyVariety === STORY_VARIETY_COW_HISTORY) {
            let cowHistoryConfig = _.cloneDeep(cowStoryHistoryConfig);
            if (_.isEmpty(selectedItem.firstCalf)) {
              // don't include first calf slides if there is no data
              let outroPageSlide = cowHistoryConfig.textBlocks.pop();
              cowHistoryConfig.textBlocks = cowHistoryConfig.textBlocks.slice(
                0,
                8,
              );
              cowHistoryConfig.textBlocks.push(outroPageSlide);
            }
            const shouldAddCalmSlide =
              farmDetails.otherDevices.filter((currentDevice) =>
                currentDevice.type.includes("calm"),
              ).length > 0;
            const shoulAddRelativesSlide =
              detailedActorData?.relatives.length > 0 ? true : false;
            cowHistoryConfig.textBlocks = cowHistoryConfig.textBlocks.filter(
              (currentSlide) => {
                return !shoulAddRelativesSlide
                  ? currentSlide.slideTitle != "CowHistoryRelativesPage"
                  : true;
              },
            );
            const shouldAddBirthdaySlide =
              selectedItem.ageInYears > 0 ? true : false;
            cowHistoryConfig.textBlocks = cowHistoryConfig.textBlocks.filter(
              (currentSlide) => {
                return !shouldAddCalmSlide
                  ? currentSlide.slideTitle !== "CowHistoryCalmRobotSlide"
                  : true;
              },
            );
            cowHistoryConfig.textBlocks = cowHistoryConfig.textBlocks.filter(
              (currentSlide) => {
                return !shouldAddCalmSlide
                  ? currentSlide.slideTitle !== "CowHistoryCalmRobotSecondSlide"
                  : true;
              },
            );
            cowHistoryConfig.textBlocks = cowHistoryConfig.textBlocks.filter(
              (currentSlide) => {
                return !shouldAddBirthdaySlide
                  ? currentSlide.slideTitle !== "CowHistoryFirstBirthdaySlide"
                  : true;
              },
            );
            cowHistoryConfig.textBlocks = cowHistoryConfig.textBlocks.filter(
              (currentSlide) => {
                return !shouldAddBirthdaySlide
                  ? currentSlide.slideTitle !== "CowHistoryBirthdayCakeSlide"
                  : true;
              },
            );
            newActorStoryConfig = cowHistoryConfig;
          }
          break;
        case ACTOR_TYPE_FARMER:
          newActorStoryConfig = farmerStoryConfig(farmDetails);
          break;
        case ACTOR_TYPE_PET:
          newActorStoryConfig = {};
          break;
        case ACTOR_TYPE_BRAND:
          newActorStoryConfig = lelyBrandStoryConfig(farmDetails);
          break;
        case ACTOR_TYPE_MIJN_MELK:
          newActorStoryConfig = mijnMelkStoryConfig(farmDetails);
          break;
        case ACTOR_TYPE_ASTRONAUT:
          storyVariety === STORY_VARIETY_ASTRONAUT_HISTORY
            ? (newActorStoryConfig = astronautRobotStoryConfig(farmDetails))
            : (newActorStoryConfig =
                astronautRobotLiveDataStoryConfig(farmDetails));
          break;
        case ACTOR_TYPE_VECTOR:
          newActorStoryConfig = vectorRobotStoryConfig(farmDetails);
          break;
        case ACTOR_TYPE_JUNO:
          newActorStoryConfig = junoRobotStoryConfig(farmDetails);
          break;
        case ACTOR_TYPE_DISCOVERY:
          newActorStoryConfig = discoveryStoryConfig(farmDetails);
          break;
        case ACTOR_TYPE_COLLECTOR:
          newActorStoryConfig = discoveryStoryConfig(farmDetails);
          break;
        case ACTOR_TYPE_LUNA:
          newActorStoryConfig = lunaStoryConfig(farmDetails);
          break;
        case ACTOR_TYPE_SUSTAINABILITY_PLATE:
          if (storyVariety !== STORY_VARIETY_SUSTAINABILITY_STORY) {
            let sustainabilityPlateStoryConfig = _.cloneDeep(
              sustainabilityStoryConfig(farmDetails),
            );
            newActorStoryConfig = sustainabilityPlateStoryConfig;
            sustainabilityPlateStoryConfig.textBlocks =
              sustainabilityPlateStoryConfig.textBlocks.filter(
                (currentSlide) => {
                  return !farmDetails.sustainabilityData
                    ?.hasCarbonDioxideMeasures
                    ? currentSlide.slideTitle !==
                        "RobotStorySustainabilityStory2"
                    : true;
                },
              );
            sustainabilityPlateStoryConfig.textBlocks =
              sustainabilityPlateStoryConfig.textBlocks.filter(
                (currentSlide) => {
                  return !farmDetails.sustainabilityData.hasConcentratesData
                    ? currentSlide.slideTitle !==
                        "RobotStorySustainabilityStory3"
                    : true;
                },
              );
            sustainabilityPlateStoryConfig.textBlocks =
              sustainabilityPlateStoryConfig.textBlocks.filter(
                (currentSlide) => {
                  return !farmDetails.sustainabilityData.hasFertilizerData
                    ? currentSlide.slideTitle !==
                        "RobotStorySustainabilityStory4"
                    : true;
                },
              );
            sustainabilityPlateStoryConfig.textBlocks =
              sustainabilityPlateStoryConfig.textBlocks.filter(
                (currentSlide) => {
                  return !farmDetails.sustainabilityData.hasRenewableEnergy
                    ? currentSlide.slideTitle !==
                        "RobotStorySustainabilityStory5"
                    : true;
                },
              );
            sustainabilityPlateStoryConfig.textBlocks =
              sustainabilityPlateStoryConfig.textBlocks.filter(
                (currentSlide) => {
                  return !farmDetails.sustainabilityData.hasRoughageProduction
                    ? currentSlide.slideTitle !==
                        "RobotStorySustainabilityStory6"
                    : true;
                },
              );
            break;
          } else {
            let sustainabilityPlateStoryConfig = _.cloneDeep(
              animalWelfareStoryConfig(farmDetails),
            );
            newActorStoryConfig = sustainabilityPlateStoryConfig;
            sustainabilityPlateStoryConfig.textBlocks =
              sustainabilityPlateStoryConfig.textBlocks.filter(
                (currentSlide) => {
                  return !farmDetails?.animalWelfare?.hasCowAndCalfData ||
                    !farmDetails?.animalWelfare?.cowAndCalfImageData
                    ? currentSlide.slideTitle !==
                        "RobotStoryAnimalWelfareStory2"
                    : true;
                },
              );
            sustainabilityPlateStoryConfig.textBlocks =
              sustainabilityPlateStoryConfig.textBlocks.filter(
                (currentSlide) => {
                  return !farmDetails?.animalWelfare?.hasHerdBreedAndAgeData ||
                    !farmDetails?.animalWelfare?.herdBreedAndAgeImageData
                    ? currentSlide.slideTitle !==
                        "RobotStoryAnimalWelfareStory3"
                    : true;
                },
              );
            sustainabilityPlateStoryConfig.textBlocks =
              sustainabilityPlateStoryConfig.textBlocks.filter(
                (currentSlide) => {
                  return !farmDetails?.animalWelfare?.hasGrazingData
                    ? currentSlide.slideTitle !==
                        "RobotStoryAnimalWelfareStory4"
                    : true;
                },
              );
            sustainabilityPlateStoryConfig.textBlocks =
              sustainabilityPlateStoryConfig.textBlocks.filter(
                (currentSlide) => {
                  return !farmDetails?.animalWelfare
                    ?.hasLivingEnvironmentData ||
                    !farmDetails?.animalWelfare?.livingEnvironmentImageData
                    ? currentSlide.slideTitle !==
                        "RobotStoryAnimalWelfareStory5"
                    : true;
                },
              );
            break;
          }
        case ACTOR_TYPE_MILK_FACTORY:
          console.log("storyVariety@@@", storyVariety);
          storyVariety === STORY_VARIETY_ORBITER_YOGHURT
            ? (newActorStoryConfig =
                orbiterRobotYoghurtStoryConfig(farmDetails))
            : (newActorStoryConfig = orbiterRobotStoryConfig(farmDetails));
          break;
        case ACTOR_TYPE_CHEESE_FACTORY:
          newActorStoryConfig = _.cloneDeep(cheeseFactoryStoryConfig);
          break;
        case ACTOR_TYPE_ICE_CREAM_FACTORY:
          newActorStoryConfig = _.cloneDeep(iceCreamFactoryStoryConfig);
          break;
        case ACTOR_TYPE_TRACTOR:
          newActorStoryConfig = {};
          break;
      }
      setSelectedActorStoryConfig(newActorStoryConfig);
      setCurrentActorConfig(newActorStoryConfig);
      updateFlowContainerCssClassname(
        ACTOR_STORY_FIRST_SLIDE_INDEX * VIEWPORT_WIDTH_IN_PX,
        VIEWPORT_WIDTH_IN_PX,
      );
      transitionTo(ACTOR_STORY_FIRST_SLIDE_INDEX, VIRTUAL_FARM_SLIDE_INDEX);
    },
    [],
  );

  const handleProceedToScreenAfterAgeSelectionCallback = useCallback(() => {
    const screenAfterAgeSelectionIndex = VIRTUAL_FARM_SLIDE_INDEX; // IMPORTANT: if any new screen is added before welcome page screen, this number will need to be updated
    console.log("handleProceedToScreenAfterAgeSelectionCallback");
    transitionTo(screenAfterAgeSelectionIndex, null, true);
    updateFlowContainerCssClassname(
      screenAfterAgeSelectionIndex * viewportWidthInPxRef.current,
      viewportWidthInPxRef.current,
    );
  }, []);

  function isInCowDaily() {
    return (
      selectedSlideTitle &&
      selectedSlideTitle.current &&
      (selectedSlideTitle.current.indexOf("CowDaily") === 0 ||
        selectedSlideTitle.current === "flow-container-Cow-slide-")
    );
  }

  let isOnboarding =
    selectedSlideIndex <= onboardingStorySlides.length &&
    selectedSlideIndex > 0;
  let isActorVisible =
    selectedSlideIndex > VIRTUAL_FARM_SLIDE_INDEX &&
    !isInCowDaily() &&
    !(
      selectedSlideTitle.current &&
      selectedSlideTitle.current.indexOf("virtual-farm") !== -1
    );

  const cowClickHandlerCallback = useCallback((clickedCowData) => {}, []);
  const farmerClickHandlerCallback = useCallback((clickedFarmerData) => {}, []);
  const cheeseFactoryClickHandlerCallback = useCallback(
    (clickedCheeseFactory) => {},
    [],
  );
  const milkFactoryClickHandlerCallback = useCallback(
    (clickedMilkFactory) => {},
    [],
  );
  const iceCreamFactoryClickHandlerCallback = useCallback(
    (clickedIceCreamFactory) => {},
    [],
  );
  function getSelectedActorJSX(selectedActorData) {
    if (_.isEmpty(selectedActorData) || !selectedActorData.type) {
      return;
    }
    switch (selectedActorData.type.toLowerCase()) {
      case ACTOR_TYPE_COW:
        return (
          <Cow
            cowData={selectedActorData}
            cowCssClassname={COW_CSS_CLASSNAME}
            cowXCoordinate={40 + "vw"}
            cowYCoordinate={0}
            cowScaleX={1}
            cowClickHandlerFn={cowClickHandlerCallback}
            additionalCssClassnames="cow--bigger cow--fixed"
            doPositionFixed={true}
          />
        );
      // case ACTOR_TYPE_FARMER:
      //   return (
      //     <Farmer
      //       farmerData={selectedActorData}
      //       farmerCssClassname={FARMER_CSS_CLASSNAME}
      //       farmerFarmName={farmDetails.farmName}
      //       farmerYCoordinate={0}
      //       farmerScaleX={1}
      //       farmerClickHandlerFn={farmerClickHandlerCallback}
      //       additionalCssClassnames="farmer--bigger farmer--fixed"
      //       doPositionFixed={true}
      //     />
      //   );
      case ACTOR_TYPE_CHEESE_FACTORY:
        return (
          <CheeseFactory
            cheeseFactoryData={selectedActorData}
            cheeseFactoryCssClassname={CHEESE_FACTORY_CSS_CLASSNAME}
            cheeseFactoryXCoordinate={40 + "vw"}
            cheeseFactoryYCoordinate={0}
            cheeseFactoryScaleX={1}
            cheeseFactoryClickHandlerFn={cheeseFactoryClickHandlerCallback}
            additionalCssClassnames="cheeseFactory--bigger cheeseFactory--fixed"
            doPositionFixed={true}
          />
        );
      case ACTOR_TYPE_MILK_FACTORY:
        return (
          <MilkFactory
            milkFactoryData={selectedActorData}
            milkFactoryCssClassname={MILK_FACTORY_CSS_CLASSNAME}
            milkFactoryXCoordinate={40 + "vw"}
            milkFactoryYCoordinate={0}
            milkFactoryScaleX={1}
            milkFactoryClickHandlerFn={milkFactoryClickHandlerCallback}
            additionalCssClassnames="milkFactory--bigger milkFactory--fixed"
            doPositionFixed={true}
          />
        );
      case ACTOR_TYPE_ICE_CREAM_FACTORY:
        return (
          <IceCreamFactory
            iceCreamFactoryData={selectedActorData}
            iceCreamFactoryCssClassname={ICE_CREAM_FACTORY_CSS_CLASSNAME}
            iceCreamFactoryXCoordinate={40 + "vw"}
            iceCreamFactoryYCoordinate={0}
            iceCreamFactoryScaleX={1}
            iceCreamFactoryClickHandlerFn={iceCreamFactoryClickHandlerCallback}
            additionalCssClassnames="iceCreamFactory--bigger iceCreamFactory--fixed"
            doPositionFixed={true}
          />
        );
      default:
        break;
    }
  }

  function transitionTo(
    slideIndex,
    previousSlideIndex,
    doEnforceOnboardingEnded,
  ) {
    const currentSlideIndex = previousSlideIndex
      ? previousSlideIndex
      : Math.floor(flowTranslateX / viewportWidthInPxRef.current);
    setCurrentlyActiveSlideIndex(currentSlideIndex);
    setSlideIndexToTransitionTo(slideIndex);
    setAreSlidesContainerTransitionsDisabled(true);
    setFlowTranslateX(slideIndex * viewportWidthInPxRef.current); // important to use Ref here as otherwise VIEWPORT_WIDTH_IN_PX value gets freezed into a closure
    setTimeout(() => {
      setAreSlidesContainerTransitionsDisabled(false);
      setCurrentlyActiveSlideIndex(slideIndex);
      if (doEnforceOnboardingEnded) {
        setIsOnboardingOver(true);
      }
    }, TRANSITION_DURATION_IN_SECONDS * 1000);
  }

  let simpleModeActivatedCssClassName = isSimpleModeActive()
    ? "is-simple-mode-activated "
    : "";
  let isCowDailyCurrentlyShown =
    selectedSlideTitle &&
    selectedSlideTitle.current &&
    selectedSlideTitle.current.indexOf("CowDaily") !== -1;
  let isCowHistoryCurrentlyShown =
    selectedSlideTitle &&
    selectedSlideTitle.current &&
    selectedSlideTitle.current.indexOf("CowHistory") !== -1;
  let isRobotStoryCurrentlyShown =
    selectedSlideTitle &&
    selectedSlideTitle.current &&
    selectedSlideTitle.current.indexOf("RobotStory") !== -1;
  let swipeInProgressClassnameAppendix = getIsSwipeInProgress()
    ? " is-swipe-in-progress2"
    : "";
  let slidesContainerTransitionsDisabledClassnameAppendix =
    getAreSlidesContainerTransitionsDisabled()
      ? " are-slides-container-transitions-disabled"
      : "";
  let slidesContainerTransitionToSlideIndexClassnameAppendix =
    getSlideIndexToTransitionTo() && getAreSlidesContainerTransitionsDisabled()
      ? " is-transition-to-" + getSlideIndexToTransitionTo() + "-in-progress"
      : "";
  let flowContainerCssClassName =
    simpleModeActivatedCssClassName +
    "flow-container flow-container-" +
    selectedActorDetails.type +
    "-slide-" +
    selectedSlideTitle.current +
    " theme-" +
    themeName +
    swipeInProgressClassnameAppendix +
    " " +
    slidesContainerTransitionsDisabledClassnameAppendix +
    " " +
    slidesContainerTransitionToSlideIndexClassnameAppendix +
    " " +
    selectedSlideTitle.current +
    " is-age-selected-" +
    farmDetails.isAgeSelected;
  flowContainerCssClassName = isCowDailyCurrentlyShown
    ? flowContainerCssClassName + " is-cow-daily-currently-shown"
    : flowContainerCssClassName;
  flowContainerCssClassName = getIsOnboardingOver()
    ? flowContainerCssClassName + " is-onboarding-over"
    : flowContainerCssClassName + " is-onboarding-not-over";
  console.log('flowContainerCssClassName',flowContainerCssClassName)
  let numberOfSlidesInActorStory =
    Story({
      id: "selectedActorStory",
      config: selectedActorStoryConfig,
    }).length +
    onboardingStorySlides.length +
    1;
  // the value below is used in order to not allow users to mouse
  // wheel pan to the right after they have reached the last actor story slide
  const MAX_FLOW_TRANSLATE_X =
    numberOfSlidesInActorStory * VIEWPORT_WIDTH_IN_PX;

  function getActorStorySlideIndexByCurrentSlideIndex(currentSlideIndex) {
    return currentSlideIndex - ACTOR_STORY_FIRST_SLIDE_INDEX;
  }

  function getCurrentSlideClassnameByFlowTranslateXAndViewportWidthInPx(
    flowTranslateX,
    viewportWidthInPx,
  ) {
    let currentSlideIndex =
      getCurrentSlideIndexByFlowTranslateXAndViewportWidthInPx(
        flowTranslateX,
        viewportWidthInPx,
      );
    let actorStorySlideIndex =
      getActorStorySlideIndexByCurrentSlideIndex(currentSlideIndex);
    let currentActorConfig = !_.isEmpty(getCurrentActorConfig())
      ? getCurrentActorConfig()
      : _.cloneDeep(cowStoryLatestConfig);
    console.log(
      "currentActorConfig.textBlocks[actorStorySlideIndex]",
      currentActorConfig.textBlocks[actorStorySlideIndex],
    );
    let slideTitle =
      actorStorySlideIndex >= 0 &&
      currentActorConfig.textBlocks[actorStorySlideIndex]
        ? currentActorConfig.textBlocks[actorStorySlideIndex].slideTitle
        : "";
    let isUserCurrentlyInOnboardingSlides =
      onboardingStoryConfig &&
      onboardingStoryConfig.textBlocks &&
      onboardingStoryConfig.textBlocks.length &&
      currentSlideIndex < AGE_SELECTION_SLIDE_INDEX;
    let onboardingStorySlideIndex = currentSlideIndex;
    slideTitle = isUserCurrentlyInOnboardingSlides
      ? onboardingStoryConfig &&
        onboardingStoryConfig.textBlocks &&
        onboardingStoryConfig.textBlocks.length > 0 &&
        onboardingStoryConfig.textBlocks[onboardingStorySlideIndex].slideTitle
      : slideTitle;
    slideTitle = isAgeSelectionDisplayedCurrently(
      currentSlideIndex,
      flowTranslateX,
    )
      ? AGE_SELECTION_CSS_CLASSNAME
      : slideTitle;
    slideTitle = isVirtualFarmDisplayedCurrently(
      currentSlideIndex,
      flowTranslateX,
    )
      ? VIRTUAL_FARM_CSS_CLASSNAME
      : slideTitle;
    return slideTitle;
  }

  function isAgeSelectionDisplayedCurrently(
    currentSlideIndex,
    currentFlowTranslateX,
  ) {
    return currentSlideIndex === AGE_SELECTION_SLIDE_INDEX;
  }

  function isVirtualFarmDisplayedCurrently(
    currentSlideIndex,
    currentFlowTranslateX,
  ) {
    return (
      currentSlideIndex === onboardingStorySlides.length + 1 &&
      currentFlowTranslateX <
        VIEWPORT_WIDTH_IN_PX * ACTOR_STORY_FIRST_SLIDE_INDEX
    );
  }

  function isBirthdaySlideDisplayedCurrently() {
    return (
      selectedSlideTitle.current === "CowHistoryFirstBirthdaySlide" ||
      selectedSlideTitle.current === "CowHistoryBirthdayCakeSlide"
    );
  }

  function shouldFireConfetti() {
    if (!storyActorData || !storyActorData.externalId) {
      return false;
    }
    return (
      isBirthdaySlideDisplayedCurrently() &&
      confettiTrigger !== storyActorData.externalId
    );
  }

  function getCurrentSlideIndexByFlowTranslateXAndViewportWidthInPx(
    flowTranslateX,
    viewportWidthInPx,
  ) {
    return Math.round(Math.abs(flowTranslateX) / viewportWidthInPx);
  }

  function updateFlowContainerCssClassname(
    nextFlowTranslate,
    viewportWidthInPx,
  ) {
    const currentSlideTitle =
      getCurrentSlideClassnameByFlowTranslateXAndViewportWidthInPx(
        nextFlowTranslate,
        viewportWidthInPx,
      );
    console.log("currentSlideTitle", currentSlideTitle);
    selectedSlideTitle.current = currentSlideTitle;
    const currentSlideIndex =
      getCurrentSlideIndexByFlowTranslateXAndViewportWidthInPx(
        nextFlowTranslate,
        viewportWidthInPx,
      );
    setSelectedSlideIndex(currentSlideIndex);
    if (nextFlowTranslate % viewportWidthInPx === 0) {
      let isCurrentSlideLastStorySlide =
        currentSlideIndex === numberOfSlidesInActorStory;
      if (isCurrentSlideLastStorySlide) {
        debouncedGoogleAnalyticsPageVisitHandler(currentSlideTitle);
        debouncedGoogleAnalyticsEventHandler(LAST_STORY_SLIDE_REACHED, farmId);
      } else {
        debouncedGoogleAnalyticsPageVisitHandler(currentSlideTitle);
      }
      dispatch(slideTitleChanged(currentSlideTitle));
    }
    if (shouldFireConfetti()) {
      dispatch(popConfetti(storyActorData.externalId));
    }
  }

  function shouldMouseWheelBeDisabled(optionalDelta, latestFlowTranslateX) {
    const isMoveRight = optionalDelta < 0;
    if (
      (flowTranslateX === 0 &&
        farmDetails &&
        farmDetails.farmTheme === "milkbottle") ||
      selectedSlideTitle.current === "MilkBottleOnboarding" ||
      selectedSlideTitle.current === AGE_SELECTION_CSS_CLASSNAME ||
      (flowTranslateX === 0 && onboardingStorySlides.length === 0) ||
      (selectedSlideTitle.current === "KoolCheeseFinalOnboardingSlide" &&
        getLatestFlowTranslateX() % VIEWPORT_WIDTH_IN_PX === 0 &&
        isMoveRight) ||
      (selectedSlideTitle.current === "MijnMelkFinalOnboardingSlide" &&
        getLatestFlowTranslateX() % VIEWPORT_WIDTH_IN_PX === 0 &&
        isMoveRight)
    ) {
      return true;
    }
    return (
      selectedSlideTitle.current === VIRTUAL_FARM_CSS_CLASSNAME &&
      getLatestFlowTranslateX() >=
        VIEWPORT_WIDTH_IN_PX * VIRTUAL_FARM_SLIDE_INDEX &&
      getLatestFlowTranslateX() <
        (VIEWPORT_WIDTH_IN_PX + 1) * VIRTUAL_FARM_SLIDE_INDEX
    );
  }

  function shouldSwipeBeDisabled(optionalDelta, latestFlowTranslateX) {
    const LATEST_SLIDE_INDEX_BEFORE_AGE_SELECTION =
      AGE_SELECTION_SLIDE_INDEX - 1;
    let isMoveRight = optionalDelta < 0;
    let isSwipeRight = isMoveRight || typeof isMoveRight === "undefined";
    let futureFlowTranslateX =
      latestFlowTranslateX + Math.abs(optionalDelta) * MOBILE_SWIPE_VELOCITY;
    let xInWhichLatestOnboardingSlideIsFullyShown =
      VIEWPORT_WIDTH_IN_PX * LATEST_SLIDE_INDEX_BEFORE_AGE_SELECTION;
    let isSwipeBeyondLatestOnboardingSlide =
      futureFlowTranslateX > xInWhichLatestOnboardingSlideIsFullyShown;
    if (
      (flowTranslateX === 0 &&
        farmDetails &&
        farmDetails.farmTheme === "milkbottle") ||
      selectedSlideTitle.current === "MilkBottleOnboarding" ||
      selectedSlideTitle.current === AGE_SELECTION_CSS_CLASSNAME ||
      (selectedSlideIndex === LATEST_SLIDE_INDEX_BEFORE_AGE_SELECTION &&
        isSwipeRight &&
        isSwipeBeyondLatestOnboardingSlide)
    ) {
      return true;
    }
    return (
      selectedSlideTitle.current === VIRTUAL_FARM_CSS_CLASSNAME &&
      flowTranslateX >= VIEWPORT_WIDTH_IN_PX * VIRTUAL_FARM_SLIDE_INDEX &&
      flowTranslateX < (VIEWPORT_WIDTH_IN_PX + 1) * VIRTUAL_FARM_SLIDE_INDEX
    );
  }

  function isMoveLeftFromFirstActorSlide(isMoveLeft) {
    const currentSlideIndex =
      getCurrentSlideIndexByFlowTranslateXAndViewportWidthInPx(
        flowTranslateX,
        VIEWPORT_WIDTH_IN_PX,
      );
    return isMoveLeft && currentSlideIndex === ACTOR_STORY_FIRST_SLIDE_INDEX;
  }

  function createDebouncedWheelHandler(
    deltaY,
    scrollIncrementInPx,
    debounceIntervalInMs,
  ) {
    const mouseWheelHandler = _.debounce(() => {
      // _.debounce will combine multiple/a burst of mouse events into one event
      let nextFlowTranslate;
      const isMoveRight = deltaY < 0;
      if (shouldMouseWheelBeDisabled(deltaY, getLatestFlowTranslateX())) {
        return;
      }
      if (isMoveLeftFromFirstActorSlide(!isMoveRight)) {
        return; // disable going back to virtual farm from first actor slide
      }

      if (isMoveRight) {
        nextFlowTranslate = _.cloneDeep(flowTranslateX) + VIEWPORT_WIDTH_IN_PX; // + scrollIncrementInPx;
        nextFlowTranslate = getForbiddenAreaValidatedNextTranslateX(
          nextFlowTranslate,
          isMoveRight,
        );
        nextFlowTranslate =
          nextFlowTranslate > MAX_FLOW_TRANSLATE_X
            ? MAX_FLOW_TRANSLATE_X
            : nextFlowTranslate;
      } else {
        nextFlowTranslate = _.cloneDeep(flowTranslateX) - VIEWPORT_WIDTH_IN_PX; // - scrollIncrementInPx;
        nextFlowTranslate = getForbiddenAreaValidatedNextTranslateX(
          nextFlowTranslate,
          isMoveRight,
        );
        nextFlowTranslate = nextFlowTranslate < 0 ? 0 : nextFlowTranslate;
      }
      updateFlowContainerCssClassname(nextFlowTranslate, VIEWPORT_WIDTH_IN_PX);
      updateStoryMomentReducer(nextFlowTranslate);
      onScrollOrSwipeFn();
      setLatestFlowTranslateX(nextFlowTranslate);
      setFlowTranslateX(nextFlowTranslate);
    }, debounceIntervalInMs);
    mouseWheelHandler(deltaY);
  }

  function updateStoryMomentReducer(nextFlowTranslate) {
    let nextSlideIndex =
      getCurrentSlideIndexByFlowTranslateXAndViewportWidthInPx(
        nextFlowTranslate,
        VIEWPORT_WIDTH_IN_PX,
      );
    let actorStorySlideIndex =
      getActorStorySlideIndexByCurrentSlideIndex(nextSlideIndex);
    let actorConfig = getCurrentActorConfig();
    let currentSlideConfig =
      actorConfig && actorConfig.textBlocks
        ? actorConfig.textBlocks[actorStorySlideIndex]
        : null;

    let isCurrentSlideMilkingSlide =
      currentSlideConfig &&
      currentSlideConfig.props &&
      typeof currentSlideConfig.props.milkingData !== "undefined";
    let milkingData = isCurrentSlideMilkingSlide
      ? currentSlideConfig.props.milkingData
      : null;
    let cowDailyDataLastUpdatedDate =
      currentSlideConfig &&
      currentSlideConfig.props &&
      currentSlideConfig.props.cowDailyDetails &&
      currentSlideConfig.props.cowDailyDetails.lastUpdated;
    let momentToBeApplied = isCurrentSlideMilkingSlide
      ? moment(milkingData.created).utc()
      : moment(cowDailyDataLastUpdatedDate).utc();
    let currentWeatherData = isCurrentSlideMilkingSlide
      ? milkingData.weatherData
      : getWeatherDataForTime(
          cowDailyDataLastUpdatedDate &&
            cowDailyDataLastUpdatedDate.toString &&
            cowDailyDataLastUpdatedDate.toString(),
          currentSlideConfig &&
            currentSlideConfig.props &&
            currentSlideConfig.props.cowDailyDetails &&
            currentSlideConfig.props.cowDailyDetails &&
            currentSlideConfig.props.cowDailyDetails.weatherData &&
            currentSlideConfig.props.cowDailyDetails.weatherData.hours,
        );
    applyNewStoryMomentAndWeatherIfNecessary(
      momentToBeApplied,
      currentWeatherData,
    );
  }

  function applyNewStoryMomentAndWeatherIfNecessary(
    momentToBeApplied,
    weatherData,
  ) {
    let latestMoment = getLatestMoment();
    let newMomentTime = momentToBeApplied;
    let isMomentReducerUpdateNeeded =
      latestMoment && latestMoment.valueOf
        ? newMomentTime.valueOf() != latestMoment.valueOf()
        : true;
    let newSunriseData =
      cowDailyDetails &&
      cowDailyDetails.weatherData &&
      cowDailyDetails.weatherData.sunrise;
    let newSunsetData =
      cowDailyDetails &&
      cowDailyDetails.weatherData &&
      cowDailyDetails.weatherData.sunset;

    if (isMomentReducerUpdateNeeded) {
      setLatestMoment(newMomentTime);
      setLatestWeatherData(weatherData);
      setSunriseHour(newSunriseData);
      setSunsetHour(newSunsetData);
      updateWindSpeedCssVariable(weatherData);
      dispatch(
        updateStoryMoment(
          moment(newMomentTime).utc(),
          weatherData,
          newSunriseData,
          newSunsetData,
        ),
      );
    }
  }

  function updateWindSpeedCssVariable(weatherData) {
    const FASTEST_WINDMILL_ROTATION_POSSIBLE_IN_MILLISECONDS = 400;
    const DEFAULT_WINDMILL_ROTATION_SPEED_IN_MILLISECONDS = 2000;
    function getWindmillAnimationDuration(
      windSpeed,
      slowestWindmillRotationInMilliseconds,
      fastestWindmillRotationInMilliseconds,
    ) {
      let windmillRotationSpeedInMilliseconds;
      let relativeSpeed = windSpeed / 100;
      let rest =
        relativeSpeed *
        (slowestWindmillRotationInMilliseconds -
          fastestWindmillRotationInMilliseconds);
      windmillRotationSpeedInMilliseconds =
        slowestWindmillRotationInMilliseconds - rest;
      return windmillRotationSpeedInMilliseconds;
    }

    let windmillRotationSpeedInMilliseconds;
    if (weatherData.windSpeed) {
      if (weatherData.windSpeed > 100) {
        windmillRotationSpeedInMilliseconds =
          FASTEST_WINDMILL_ROTATION_POSSIBLE_IN_MILLISECONDS;
      } else if (weatherData.windSpeed < 5) {
        windmillRotationSpeedInMilliseconds = 10000;
      } else {
        windmillRotationSpeedInMilliseconds = getWindmillAnimationDuration(
          weatherData.windSpeed,
          DEFAULT_WINDMILL_ROTATION_SPEED_IN_MILLISECONDS,
          FASTEST_WINDMILL_ROTATION_POSSIBLE_IN_MILLISECONDS,
        );
      }
    } else {
      windmillRotationSpeedInMilliseconds =
        DEFAULT_WINDMILL_ROTATION_SPEED_IN_MILLISECONDS;
    }
    document.documentElement.style.setProperty(
      "--windmillRotationSpeed",
      `${windmillRotationSpeedInMilliseconds}ms`,
    );
  }

  function initializeStoryMomentInTheBeginningOfCowDailyStory(cowDailyData) {
    let cowDailyDataLastUpdatedDate =
      cowDailyData &&
      cowDailyData.lastUpdated &&
      cowDailyData.lastUpdated.toString();
    let lastUpdatedMoment = moment(cowDailyDataLastUpdatedDate).utc();
    let lastKnownWeatherAtFarm = getWeatherDataForTime(
      cowDailyDataLastUpdatedDate,
      cowDailyData.weatherData.hours,
    );
    applyNewStoryMomentAndWeatherIfNecessary(
      lastUpdatedMoment,
      lastKnownWeatherAtFarm,
    );
  }

  function getForbiddenAreaValidatedNextTranslateX(
    nextTranslateX,
    isMoveRight,
  ) {
    let forbiddenRangeThatNextTranslateXIsIn =
      isTranslateXInForbiddenRange(nextTranslateX);
    if (forbiddenRangeThatNextTranslateXIsIn) {
      setIsSwipeInProgress(false);
      if (isMoveRight) {
        return forbiddenRangeThatNextTranslateXIsIn.end;
      } else {
        // is move left
        return forbiddenRangeThatNextTranslateXIsIn.start;
      }
    }
    return nextTranslateX;
  }

  function isTranslateXInForbiddenRange(translateX) {
    let isInForbiddenRange = false;
    let forbiddenRange = {};
    forbiddenSwipeRanges.forEach((currentForbiddenRange) => {
      if (
        translateX > currentForbiddenRange.start &&
        translateX < currentForbiddenRange.end
      ) {
        isInForbiddenRange = true;
        forbiddenRange = currentForbiddenRange;
      }
    });
    return isInForbiddenRange ? forbiddenRange : false;
  }

  const MOBILE_SWIPE_VELOCITY = 2; // previously was 1.5
  function swipeHandler(deltaX) {
    let latestSwipeDeltaX = getLatestSwipeDeltaX();
    const isMoveRight = latestSwipeDeltaX < 0;
    let latestFlowTranslateX = getLatestFlowTranslateX();
    if (shouldSwipeBeDisabled(deltaX, latestFlowTranslateX)) {
      return;
    }
    if (isMoveLeftFromFirstActorSlide(!isMoveRight)) {
      return; // disable going back to virtual farm from first actor slide
    }
    let currentDelta = _.cloneDeep(deltaX);
    let deltaDifference = currentDelta - latestSwipeDeltaX;
    let nextFlowTranslate;
    if (isMoveRight) {
      nextFlowTranslate = _.cloneDeep(flowTranslateX) + VIEWPORT_WIDTH_IN_PX;
    } else {
      nextFlowTranslate = _.cloneDeep(flowTranslateX) - VIEWPORT_WIDTH_IN_PX;
    }
    nextFlowTranslate = getForbiddenAreaValidatedNextTranslateX(
      nextFlowTranslate,
      isMoveRight,
    );
    nextFlowTranslate =
      nextFlowTranslate > MAX_FLOW_TRANSLATE_X
        ? MAX_FLOW_TRANSLATE_X
        : nextFlowTranslate;
    nextFlowTranslate = nextFlowTranslate < 0 ? 0 : nextFlowTranslate;
    setLatestSwipeDeltaX(currentDelta);
    updateFlowContainerCssClassname(nextFlowTranslate, VIEWPORT_WIDTH_IN_PX);
    setLatestFlowTranslateX(_.cloneDeep(nextFlowTranslate));
    updateStoryMomentReducer(nextFlowTranslate);
    onScrollOrSwipeFn();
    setFlowTranslateX(nextFlowTranslate);
  }

  function handleWheel(mouseEvent) {
    const deltaY = _.clone(mouseEvent.deltaY);
    createDebouncedWheelHandler(deltaY, SCROLL_INCREMENT_IN_PX, 100);
  }

  const debouncedSwipeHandler = _.throttle(swipeHandler, 10);
  const debouncedSwipingHandler = _.throttle(swipeHandler, 1000);

  const getForbiddenSwipeRangesArray = () => {
    let forbiddenSwipeRanges = [];
    let firstActorStorySlideStartX =
      ACTOR_STORY_FIRST_SLIDE_INDEX * VIEWPORT_WIDTH_IN_PX;
    selectedActorStoryConfig &&
      selectedActorStoryConfig.textBlocks &&
      selectedActorStoryConfig.textBlocks.forEach(
        (currentSlide, currentSlideIndex) => {
          if (currentSlide.forbiddenSwipeRanges) {
            currentSlide.forbiddenSwipeRanges.forEach((currentSwipeRange) => {
              forbiddenSwipeRanges.push({
                start:
                  firstActorStorySlideStartX +
                  currentSlideIndex * VIEWPORT_WIDTH_IN_PX +
                  (currentSwipeRange.start / 100) * VIEWPORT_WIDTH_IN_PX,
                end:
                  firstActorStorySlideStartX +
                  currentSlideIndex * VIEWPORT_WIDTH_IN_PX +
                  (currentSwipeRange.end / 100) * VIEWPORT_WIDTH_IN_PX,
              });
            });
          }
        },
      );
    PRE_ACTOR_STORY_FORBIDDEN_SWIPE_RANGES.forEach(
      (currentForbiddenRangeInfo) => {
        if (typeof currentForbiddenRangeInfo.slideIndex !== "undefined") {
          forbiddenSwipeRanges.push({
            start:
              currentForbiddenRangeInfo.slideIndex * VIEWPORT_WIDTH_IN_PX +
              (currentForbiddenRangeInfo.start / 100) * VIEWPORT_WIDTH_IN_PX,
            end:
              currentForbiddenRangeInfo.slideIndex * VIEWPORT_WIDTH_IN_PX +
              (currentForbiddenRangeInfo.end / 100) * VIEWPORT_WIDTH_IN_PX,
          });
        }
      },
    );

    //add final onboarding slide to forbidden ranges, so no partially shown age selection screen occurs
    if (onboardingStorySlides && onboardingStorySlides.length > 0) {
      forbiddenSwipeRanges.push({
        start:
          onboardingStorySlides.length * VIEWPORT_WIDTH_IN_PX +
          0 * VIEWPORT_WIDTH_IN_PX,
        end:
          onboardingStorySlides.length * VIEWPORT_WIDTH_IN_PX +
          VIEWPORT_WIDTH_IN_PX,
      });
    }

    // add virtual farm to actor story exception
    // (so that we are not able to scroll slightly after virtual farm and see only part of the first actor story slide)
    forbiddenSwipeRanges.push({
      start:
        VIRTUAL_FARM_SLIDE_INDEX * VIEWPORT_WIDTH_IN_PX +
        0 * VIEWPORT_WIDTH_IN_PX,
      end:
        VIRTUAL_FARM_SLIDE_INDEX * VIEWPORT_WIDTH_IN_PX + VIEWPORT_WIDTH_IN_PX,
    });
    return forbiddenSwipeRanges;
  };
  const forbiddenSwipeRanges = getForbiddenSwipeRangesArray();

  function isCurrentSlideWithSwipeEndOnly() {
    const currentSlideIndex =
      getCurrentSlideIndexByFlowTranslateXAndViewportWidthInPx(
        flowTranslateX,
        VIEWPORT_WIDTH_IN_PX,
      );
    return (
      SLIDES_WITH_SWIPE_END_ONLY.filter(
        (currentSlide) => currentSlide.slideIndex === currentSlideIndex,
      ).length > 0
    );
  }

  function getEventDeltaBasedOnScreenOrientation(eventData) {
    let eventDeltaValue;
    if (isAppOpenedInLandscapeMode) {
      eventDeltaValue = _.cloneDeep(eventData.deltaX);
    } else {
      eventDeltaValue = _.cloneDeep(eventData.deltaY);
    }
    return eventDeltaValue;
  }

  const swipeEventHandlers = useSwipeable({
    onSwipeStart: (eventData) => {
      let eventDeltaValue = getEventDeltaBasedOnScreenOrientation(eventData);
      if (!shouldSwipeBeDisabled(eventDeltaValue, flowTranslateX)) {
        setLatestSwipeDeltaX(eventDeltaValue);
        setLatestFlowTranslateX(_.cloneDeep(flowTranslateX));
        setIsSwipeInProgress(true);
      }
    },
    onSwiped: (eventData) => {
      let eventDeltaValue = getEventDeltaBasedOnScreenOrientation(eventData);
      if (!shouldSwipeBeDisabled(eventDeltaValue, getLatestFlowTranslateX())) {
        debouncedSwipeHandler(eventDeltaValue);
        setIsSwipeInProgress(false);
      }
    },
    trackMouse: false,
  });

  console.log("@Flow.jsx farmDetails:", farmDetails);
  let virtualFarmScreen = farmDetails.isAgeSelected ? (
    <VirtualFarmPage
      showActorStoryFn={handleShowActorStoryCallback}
      startPoint={startPoint}
      setStartPoint={setStartPoint}
      farmId={farmId}
      isAgeSelected={farmDetails.isAgeSelected}
    />
  ) : (
    <></>
  );
  console.log("@Flow rerender");

  return (
    <div
      className={flowContainerCssClassName}
      onWheel={handleWheel}
      {...swipeEventHandlers}
    >
      {isCowHistoryCurrentlyShown ? (
        farmDetails.isAdultSelected ? (
          <ParallaxCowHistoryForAdults
            translateX={flowTranslateX}
            startX={(onboardingStorySlides.length + 2) * VIEWPORT_WIDTH_IN_PX}
            endX={
              (onboardingStorySlides.length + 3 + numberOfSlidesInActorStory) *
              VIEWPORT_WIDTH_IN_PX
            }
            numberOfSlides={numberOfSlidesInActorStory || 12}
            slideWidthInPx={VIEWPORT_WIDTH_IN_PX}
            slideHeightInPx={VIEWPORT_HEIGHT_IN_PX}
            cowDailyDetails={cowDailyDetails}
            cowGrazingData={farmDetails.grazingData}
            farmFeaturesDetails={farmDetails.otherDevices}
            selectedActor={selectedActorDetails}
          />
        ) : (
          <ParallaxCowHistory
            translateX={flowTranslateX}
            startX={(onboardingStorySlides.length + 2) * VIEWPORT_WIDTH_IN_PX}
            endX={
              (onboardingStorySlides.length + 3 + numberOfSlidesInActorStory) *
              VIEWPORT_WIDTH_IN_PX
            }
            numberOfSlides={numberOfSlidesInActorStory || 12}
            slideWidthInPx={VIEWPORT_WIDTH_IN_PX}
            slideHeightInPx={VIEWPORT_HEIGHT_IN_PX}
            cowDailyDetails={cowDailyDetails}
            cowGrazingData={farmDetails.grazingData}
            farmFeaturesDetails={farmDetails.otherDevices}
            selectedActor={selectedActorDetails}
          />
        )
      ) : (
        ""
      )}
      {isCowDailyCurrentlyShown ? (
        farmDetails.isAdultSelected ? (
          <ParallaxCowDailyForAdults
            translateX={flowTranslateX}
            startX={(onboardingStorySlides.length + 2) * VIEWPORT_WIDTH_IN_PX}
            endX={
              (onboardingStorySlides.length + 3 + numberOfSlidesInActorStory) *
              VIEWPORT_WIDTH_IN_PX
            }
            numberOfSlides={numberOfSlidesInActorStory || 12}
            slideWidthInPx={VIEWPORT_WIDTH_IN_PX}
            slideHeightInPx={VIEWPORT_HEIGHT_IN_PX}
            cowDailyDetails={cowDailyDetails}
            cowGrazingData={farmDetails.grazingData}
            farmFeaturesDetails={farmDetails.otherDevices}
            isAdultThemeApplied={farmDetails.isAdultSelected}
            farmTheme={themeName}
          />
        ) : (
          <ParallaxCowDaily
            translateX={flowTranslateX}
            startX={(onboardingStorySlides.length + 2) * VIEWPORT_WIDTH_IN_PX}
            endX={
              (onboardingStorySlides.length + 3 + numberOfSlidesInActorStory) *
              VIEWPORT_WIDTH_IN_PX
            }
            numberOfSlides={numberOfSlidesInActorStory || 12}
            slideWidthInPx={VIEWPORT_WIDTH_IN_PX}
            slideHeightInPx={VIEWPORT_HEIGHT_IN_PX}
            cowDailyDetails={cowDailyDetails}
            cowGrazingData={farmDetails.grazingData}
            farmFeaturesDetails={farmDetails.otherDevices}
            isAdultThemeApplied={farmDetails.isAdultSelected}
          />
        )
      ) : (
        ""
      )}
      {isRobotStoryCurrentlyShown ? (
        farmDetails.isAdultSelected ? (
          <ParallaxRobotStoryForAdults
            translateX={flowTranslateX}
            startX={(onboardingStorySlides.length + 2) * VIEWPORT_WIDTH_IN_PX}
            endX={
              (onboardingStorySlides.length + 3 + numberOfSlidesInActorStory) *
              VIEWPORT_WIDTH_IN_PX
            }
            numberOfSlides={numberOfSlidesInActorStory || 12}
            slideWidthInPx={VIEWPORT_WIDTH_IN_PX}
            slideHeightInPx={VIEWPORT_HEIGHT_IN_PX}
            cowDailyDetails={cowDailyDetails}
            cowGrazingData={farmDetails.grazingData}
            farmFeaturesDetails={farmDetails.otherDevices}
            isAdultThemeApplied={farmDetails.isAdultSelected}
            farmTheme={themeName}
          />
        ) : (
          <ParallaxRobotStory
            translateX={flowTranslateX}
            startX={(onboardingStorySlides.length + 2) * VIEWPORT_WIDTH_IN_PX}
            endX={
              (onboardingStorySlides.length + 3 + numberOfSlidesInActorStory) *
              VIEWPORT_WIDTH_IN_PX
            }
            numberOfSlides={numberOfSlidesInActorStory || 12}
            slideWidthInPx={VIEWPORT_WIDTH_IN_PX}
            slideHeightInPx={VIEWPORT_HEIGHT_IN_PX}
            cowDailyDetails={cowDailyDetails}
            cowGrazingData={farmDetails.grazingData}
            farmFeaturesDetails={farmDetails.otherDevices}
            isAdultThemeApplied={farmDetails.isAdultSelected}
            farmTheme={themeName}
          />
        )
      ) : (
        ""
      )}

      <SlidesContainer
        onChangeSlideFn={useCallback((newSlideIndex) => {
          //setFlowTranslateX(newSlideIndex * VIEWPORT_WIDTH_IN_PX);
        }, [])}
        onNavigateToInitialSlideFn={onSideMenuNavigationCompleteFn}
        numberOfSlides={numberOfSlidesInActorStory}
        initialSlideIndex={firstSlideIndex}
        explicitTranslateX={flowTranslateX}
        explicitSlideWidthInPx={VIEWPORT_WIDTH_IN_PX}
        children={[
          ...onboardingStorySlides,
          <AgeSelectionPage
            onProceedToNextScreenFn={
              handleProceedToScreenAfterAgeSelectionCallback
            }
            farmType={farmDetails?.farmType}
          />,
          virtualFarmScreen,
          ...Story({
            id: "selectedActorStory",
            config: selectedActorStoryConfig,
          }),
        ]}
        isSwipeEnabled={true}
        customCssClassname={"slidesContainer-main"}
      />
      {isOnboarding && !onboardingStoryConfig.isActorHidden
        ? getSelectedActorJSX(onboardingCow)
        : null}
      {isActorVisible && !selectedActorStoryConfig.isActorHidden
        ? getSelectedActorJSX(selectedActorDetails)
        : null}
      {isBirthdaySlideDisplayedCurrently() ? (
        <BirthdayPartyHat totalNumberOfAvailablePartyHatVarieties={3} />
      ) : null}
      {isBirthdaySlideDisplayedCurrently() ? (
        <BirthdayPartyFlute totalNumberOfAvailablePartyFluteVarieties={3} />
      ) : null}
      {isSamsungInternetBrowser ? <SamsungPopup /> : null}
    </div>
  );
}

export default Flow;
