import axios from 'axios';
import { ABOUT_THE_FARM_SERVICE_URL, GET_ABOUT_THE_FARM_TEXT_BLOCKS_SERVICE_URL } from './config';

export const api = {
  getFarmStoryDetails: async function (accessToken) {
    /*
    return {
      data: {
        productionType: 'cheese',
        farmName: 'Mock farm name title',
        description: 'Mock farm text description',
        imageData: {},
      },
    }; // <-- those are the text blocks that the backend has returned (mock data)
    */

    // uncomment code below when backend is ready
    return axios.get(ABOUT_THE_FARM_SERVICE_URL, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  setFarmStoryTextBlocks: async function (accessToken, aboutTheFarmDetails) {
    /*
    return {
      data: {
        status: 200,
      },
    }; // <-- mock backend response
    */
    // uncomment code below when backend is ready
    return axios.patch(ABOUT_THE_FARM_SERVICE_URL,
                       aboutTheFarmDetails,
                       {
                          headers: {
                            Authorization: 'Bearer ' + accessToken,
                          }
                       }
    );
  },
};
