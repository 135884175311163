import axios from 'axios';
import { ANIMALS_SERVICE_URL } from './config';

export const api = {
  getAnimals: async function (accessToken) {
    /*
    return {
      data: {
        hasOtherAnimals: 1,
        animals: [
          {
            animalType: 'dogSmall',
            name: 'Sharo',
            story: { 'en': 'short story in english', 'ens': 'short story in english for children' },
            imageData: 'http://azurePhotoUrl.png',
            animalId: 0
          },
          {
            animalType: 'alpaca',
            name: 'Alpaca1',
            story: { 'en': 'short story about alpaca in english', 'ens': 'short story about alpaca in english for children' },
            imageData: 'http://azurePhotoUrl.png',
            animalId: 1
          }
        ]
      },
    }; // <-- those are the text blocks that the backend has returned (mock data)
    */
    // uncomment code below when backend is ready
    return axios.get(ANIMALS_SERVICE_URL, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  setAnimals: async function (accessToken, animalsDetails) {
    /*
    return {
      data: {
        status: 200,
      },
    }; // <-- mock backend response
    */
    // uncomment code below when backend is ready
    return axios.patch(ANIMALS_SERVICE_URL,
      animalsDetails,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        }
      }
    );
  },
};
