import React from "react";
import UserManagementContainer from "../../../components/UserManagement/UserManagementContainer/UserManagementContainer";
import "./CreateAccountPage.scss";
import CreateAccount from "../../../components/UserManagement/CreateAccount/CreateAccount";

function CreateAccountPage() {
  return (
    <UserManagementContainer>
      <CreateAccount></CreateAccount>
    </UserManagementContainer>
  );
}

export default CreateAccountPage;
