import React from "react";
import "./FirstSlideMijnMelk.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const FirstSlideMijnMelk = ({}) => {
  return (
    <div className="mijnMelkFirstSlide">
      <div className="mijnMelkFirstSlide--background">
        <TextCaption additionalCssClassname="mijnMelkFirstSlide--textCaption">
          <div className="mijnMelkFirstSlide--title">
            {translate("MIJN_MELK_STORY_TITLE")}
          </div>
          <div className="mijnMelkFirstSlide--name">{"Mijn Melk"}</div>
        </TextCaption>
        <div className="mijnMelkFirstSlide--robot"></div>
        <div className="mijnMelkFirstSlide--tree"></div>
      </div>
    </div>
  );
};

export default FirstSlideMijnMelk;
