import axios from 'axios';
import { LANGUAGES_SERVICE_URL } from './config';

export const api = {
  getSelectedFarmLanguages: async function (accessToken) {

    const SAMPLE_LANGUAGES_RESPONSE = {
      data: [
        {
          "code": "en",
          "name": "English"
        },
        {
          "code": "ens",
          "name": "English for children"
        },
        {
          "code": "nl",
          "name": "Dutch"
        },
        {
          "code": "nls",
          "name": "Dutch for children"
        }
      ]
    };
    // uncomment code below when backend is ready
    //return SAMPLE_LANGUAGES_RESPONSE;

    return axios.get(LANGUAGES_SERVICE_URL, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  setSelectedFarmLanguages: async function (accessToken, languagesDetails) {
    return axios.patch(LANGUAGES_SERVICE_URL,
      languagesDetails,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        }
      }
    );
  },
};
