import React from "react";
import "./SixthSlideLely.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const SixthSlideLely = ({}) => {
  return (
    <div className="lelyStorySixthSlide">
      <div className="lelyStorySixthSlide--floor"></div>
      <TextCaption additionalCssClassname="lelyStorySixthSlide--textCaption">
        <div className="lelyStorySixthSlide--title">
          {translate("LELY_SIXTH_SLIDE_TITLE")}
        </div>
        <div className="lelyStorySixthSlide--text">
          {translate("LELY_SIXTH_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="lelyStorySixthSlide--imgCaption">
        <div className="lelyStorySixthSlide--imgContainer"></div>
      </div>
      <div className="lelyStorySixthSlide--robot"></div>
      <div className="lelyStorySixthSlide--tree"></div>
    </div>
  );
};

export default SixthSlideLely;
