import { useState, useEffect } from "react";

export default function useOrientationChange(callbackFn) {
  const hasWindow = typeof window !== "undefined";

  useEffect(() => {
    if (hasWindow) {
      function handleOrientationChange() {
        typeof callbackFn === "function" && callbackFn();
      }

      window.addEventListener("orientationchange", handleOrientationChange);
      return () =>
        window.removeEventListener(
          "orientationchange",
          handleOrientationChange,
        );
    }
  }, [hasWindow]);

  return {};
}
