import { REGISTER_USER_REQUEST } from "./types.js";
import { registerUserSuccess, registerUserError } from "./actions.js";
import { put, take, call, spawn } from "redux-saga/effects";
import { getServerResponseStatus } from "../../ErrorPopUp/actions";
import {
  startLoading,
  endLoading,
} from "../../../common/redux/loadManager/actions";
import { getLoadId } from "../../../common/redux/loadManager/methods";

let currentLoadId = 0;

export default function* registerUserSaga(api) {
  while (true) {
    const registerUserRequest = yield take(REGISTER_USER_REQUEST);
    if (registerUserRequest.payload) {
      /** Run loader */
      currentLoadId = getLoadId();
      yield put(startLoading(currentLoadId));
      const { licenseKey, password, emailAddress } =
        registerUserRequest.payload;
      yield call(registerUser, api, licenseKey, password, emailAddress);
    }
  }
}

/** Create register new user request
 * @param {object} api - API providing access to the Lely backend
 * @param {string} licenseKey - License key of the new user
 * @param {string} password - Password of the desired new user
 * @param {string} emailAddress - E-mail address of the desired new user
 */
function* registerUser(api, licenseKey, password, emailAddress) {
  var response;
  try {
    yield call(api.register, licenseKey, password, emailAddress);
    yield put(registerUserSuccess(licenseKey));
    yield put(getServerResponseStatus(""));
    yield put(endLoading(currentLoadId));
    /** Stop loader */
  } catch (error) {
    yield put(registerUserError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
    /** Listen for error state to show when server is disconected */
    yield put(getServerResponseStatus(error));
  }
}
