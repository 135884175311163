import React, { useState, useEffect } from "react";
import { Switch, Tooltip, withStyles } from "@material-ui/core";
import { lelyRed } from "../../styles/variables.scss";

// Styles
import "./LabelledSwitch.scss";
//
const RedSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: lelyRed,
    },
    "&$checked + $track": {
      backgroundColor: lelyRed,
    },
  },
  checked: {},
  track: {},
})((props) => <Switch {...props} />);

/** LabelledSwitch component.
 * @param {Object} obj - Object which contains all props.
 * @param {string} [obj.tooltip] - Text for tooltip
 * @param {string} [obj.placeholder] - Search placeholder
 * @param {string} [obj.dataTestID] - Data test ID
 * @param {string} [obj.labelText] - Label for the text input
 * @param {string} [obj.keyValue] - Data key for the text input
 * @param {boolean} {obj.defaultValue} - By default the switch is off/unchecked/false
 */
function LabelledSwitch({
  tooltip,
  placeholder,
  dataTestID,
  labelText,
  keyValue,
  onHandleChangeFn,
  defaultValue = false,
  additionalClassName,
}) {
  const [value, setValue] = useState(defaultValue);

  /**
   * @param {Object} event - Contains current value of input filed.
   */
  function handleSwitchChange(event) {
    const isSwitchChecked = event.target.checked;
    setValue(isSwitchChecked);
    onHandleChangeFn(labelText, keyValue, isSwitchChecked);
  }

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="labelled-switch">
      <label className="labelled-switch--label">{labelText}</label>
      <div className="labelled-switch--switch-container">
        <RedSwitch
          label={labelText}
          checked={value}
          onChange={handleSwitchChange}
          className={`labelled-switch--switch labelled-text-input--textInput${
            additionalClassName ? " " + additionalClassName : ""
          }`}
        />
      </div>
    </div>
  );
}

export default LabelledSwitch;
