import React from "react";
import _ from "lodash";
import "./CowDailyOutroPage.scss";
import { useSelector } from "react-redux";
import PoleComponent from "../../common/components/PoleComponentEndingSlides/PoleComponent";

function CowDailyOutroPage({
  additionalCssClassname,
  onNavigateToInitialSlideFn,
}) {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div
        className={
          additionalCssClassname
            ? "cow-daily--outro--slide " + additionalCssClassname
            : "cow-daily--outro--slide"
        }
      >
        <PoleComponent
          onNavigateToInitialSlideFn={onNavigateToInitialSlideFn}
        />
        <div className="cow-daily--outro--slide--grass"></div>
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowDailyOutroPage;
