import {
  REGISTER_USER_REQUEST,
  REGISTER_USER_REQUEST_ERROR,
  REGISTER_USER_REQUEST_SUCCESS,
  RESET_USER_MANAGEMENT,
} from "./types";

/** Action which will initiate a user register saga which will make request to the backend
 * in order to register a new user
 * @param {string} licenseKey - The license key for the new user
 * @param {string} password - The desired password for the new user
 * @param {string} emailAddress - E-mail tied to the new user
 */
export function registerUser(licenseKey, password, emailAddress) {
  return {
    type: REGISTER_USER_REQUEST,
    payload: {
      licenseKey: licenseKey,
      password: password,
      emailAddress: emailAddress,
    },
  };
}

/** On successfully being able to create/register the new user on the backend
 * @param {string} licenseKey - The license key using which successful registration has been established
 */
export function registerUserSuccess(licenseKey) {
  return {
    type: REGISTER_USER_REQUEST_SUCCESS,
    payload: {
      licenseKey,
    },
  };
}

/** On the case of an error happened while attempting to create/register the new user
 * @param {string} errorMessage - Error message
 */
export function registerUserError(errorMessage) {
  return {
    type: REGISTER_USER_REQUEST_ERROR,
    payload: errorMessage,
  };
}

/** After closing any user management screen reset the user management reducer state
 */
export function resetUserManagement() {
  return {
    type: RESET_USER_MANAGEMENT,
    payload: {},
  };
}
