import {
  ACTIVATE_ACCOUNT_REQUEST_SUCCESS,
  ACTIVATE_ACCOUNT_REQUEST_ERROR,
} from "../components/UserManagement/AccountActivated/types";
import {
  REGISTER_USER_REQUEST_SUCCESS,
  REGISTER_USER_REQUEST_ERROR,
  RESET_USER_MANAGEMENT,
} from "../components/UserManagement/CreateAccount/types";
import {
  RECOVER_USER_REQUEST_SUCCESS,
  RECOVER_USER_REQUEST_ERROR,
} from "../components/UserManagement/RecoverAccount/types";
import {
  RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS,
  RESEND_VERIFICATION_EMAIL_REQUEST_ERROR,
} from "../components/UserManagement/ResendVerificationEmail/types";
import {
  SET_NEW_PASSWORD_REQUEST_SUCCESS,
  SET_NEW_PASSWORD_REQUEST_ERROR,
} from "../components/UserManagement/SetNewPassword/types";

/** Store states
 * @constant {Object} */
export const initialState = {
  isActivateAccountSuccess: false,
  isActivateAccountError: false,
  isRegisterUserSuccess: false,
  isRegisterUserError: false,
  isRecoverUserSuccess: false,
  isRecoverUserError: false,
  isResendVerificationEmailSuccess: false,
  isResendVerificationEmailError: false,
  isSetNewPasswordSuccess: false,
  isSetNewPasswordError: false,
  latestErrorMessage: "",
  latestDetails: {},
};

/** User Management Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVATE_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        latestDetails: action.payload,
        isActivateAccountSuccess: true,
        isActivateAccountError: false,
      };

    case ACTIVATE_ACCOUNT_REQUEST_ERROR:
      return {
        ...state,
        isActivateAccountSuccess: false,
        isActivateAccountError: true,
        latestErrorMessage: action.payload,
      };

    case REGISTER_USER_REQUEST_SUCCESS:
      return {
        ...state,
        latestDetails: action.payload,
        isRegisterUserSuccess: true,
        licenseKey: action.payload.licenseKey,
        isRegisterUserError: false,
      };

    case REGISTER_USER_REQUEST_ERROR:
      return {
        ...state,
        isRegisterUserSuccess: false,
        isRegisterUserError: true,
        latestErrorMessage: action.payload,
      };

    case RECOVER_USER_REQUEST_SUCCESS:
      return {
        ...state,
        latestDetails: action.payload,
        isRecoverUserSuccess: true,
        isRecoverUserError: false,
      };

    case RECOVER_USER_REQUEST_ERROR:
      return {
        ...state,
        isRecoverUserSuccess: false,
        isRecoverUserError: true,
        latestErrorMessage: action.payload,
      };

    case RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        latestDetails: action.payload,
        isResendVerificationEmailSuccess: true,
        isResendVerificationEmailError: false,
      };

    case RESEND_VERIFICATION_EMAIL_REQUEST_ERROR:
      return {
        ...state,
        isResendVerificationEmailSuccess: false,
        isResendVerificationEmailError: true,
        latestErrorMessage: action.payload,
      };

    case SET_NEW_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        latestDetails: action.payload,
        isSetNewPasswordSuccess: true,
        isSetNewPasswordError: false,
      };

    case SET_NEW_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        isSetNewPasswordSuccess: false,
        isSetNewPasswordError: true,
        latestErrorMessage: action.payload,
      };

    case RESET_USER_MANAGEMENT:
      return {
        isActivateAccountSuccess: false,
        isActivateAccountError: false,
        isRegisterUserSuccess: false,
        isRegisterUserError: false,
        isRecoverUserSuccess: false,
        isRecoverUserError: false,
        isResendVerificationEmailSuccess: false,
        isResendVerificationEmailError: false,
        isSetNewPasswordSuccess: false,
        isSetNewPasswordError: false,
        latestErrorMessage: "",
        latestDetails: {},
        licenseKey: "",
      };

    default:
      return state;
  }
}

export default userManagementReducer;
