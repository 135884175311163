import { translate } from "../../localization/TranslationUtil";
import React, { useRef, useState } from "react";
import AnimalBlock from "../AnimalBlock/AnimalBlock";
import {
  doesAnimalsListHasNDogsAlready,
  doesAnimalsListHaveCatAlready,
  getEmptyAnimal,
  doesAnimalsListHaveHorseAlready,
} from "../../components/AnimalBlock/Animal";
import "./AnimalsList.scss";
import _ from "lodash";
import ConfirmationDialog from "../../common/components/ConfirmationDialog/ConfirmationDialog";
import { MAX_NUMBER_OF_DOGS_PER_FARM } from "../../common/constants";

/** AnimalsList component.
 * @param {Object} obj - Object which contains all props.
 * @param {Array} [obj.animalsArray] - Array of animal objects (containing all of the animal-relevant data like name, photoUrl, story (for each specific language), etc.)
 * @param {Array} [obj.languageKeysArray] - Array of language keys which are going to be supported for the story of each animal in the animals list
 * @param {Function} [obj.handleAnimalsListChangeFn] - Function that will be called whenever any kind of change is made to the list of animals (creation, update, deletion)
 */
function AnimalsList({
  animalsArray,
  languageKeysArray,
  handleAnimalsListChangeFn,
  maxNumberOfAnimalBlocks,
}) {
  const [
    isConfirmDeleteAnimalPopupDisplayed,
    setIsConfirmDeleteAnimalPopupDisplayed,
  ] = useState(false);
  const animalToDeleteIdRef = useRef(0);

  function handleAddNewAnimalButtonClicked() {
    let newAnimalsArray = _.cloneDeep(animalsArray) || [];
    newAnimalsArray.push(
      getEmptyAnimal(languageKeysArray, animalsArray.length + 1),
    );
    handleAnimalsListChangeFn(newAnimalsArray);
  }

  function handleDeleteAnimalButtonClicked(animalIdToDelete) {
    let newAnimalsArray = _.cloneDeep(animalsArray);
    newAnimalsArray = newAnimalsArray.filter(
      (currentAnimal) => animalIdToDelete !== currentAnimal.textBlockIndex,
    );
    newAnimalsArray.forEach((currentAnimal, currentAnimalIndex) => {
      currentAnimal.textBlockIndex = currentAnimalIndex + 1;
    });
    handleAnimalsListChangeFn(newAnimalsArray);
    setIsConfirmDeleteAnimalPopupDisplayed(false);
  }

  function showDeleteAnimalConfirmationPopup(animalIdToDelete) {
    animalToDeleteIdRef.current = animalIdToDelete;
    setIsConfirmDeleteAnimalPopupDisplayed(true);
  }
  function isAddAnimalBlockButtonAvailable() {
    if (animalsArray && animalsArray.length >= maxNumberOfAnimalBlocks) {
      return false;
    } else {
      return true;
    }
  }
  console.log(
    "isAddAnimalBlockButtonAvailable",
    isAddAnimalBlockButtonAvailable(),
  );
  console.log("animalsArray:", animalsArray);
  const isThereSlotForAddingAnotherDogToTheFarm =
    !doesAnimalsListHasNDogsAlready(animalsArray, MAX_NUMBER_OF_DOGS_PER_FARM);
  const isThereSlotForAddingAnotherCatToTheFarm =
    !doesAnimalsListHaveCatAlready(animalsArray);
  const isThereSlotForAddingAnotherHorseToTheFarm =
    !doesAnimalsListHaveHorseAlready(animalsArray);

  return (
    <div className="AnimalsList">
      <>
        {animalsArray &&
          animalsArray.length > 0 &&
          animalsArray.map((currentAnimalData) => (
            <AnimalBlock
              animalData={currentAnimalData}
              languageKeysArray={languageKeysArray}
              isAddingCatPossible={isThereSlotForAddingAnotherCatToTheFarm}
              isAddingDogPossible={isThereSlotForAddingAnotherDogToTheFarm}
              isAddingHorsePossible={isThereSlotForAddingAnotherHorseToTheFarm}
              handleAnimalBlockChangeFn={(newAnimalData) => {
                let newAnimalsArray = _.cloneDeep(animalsArray);
                newAnimalsArray.forEach((currentAnimal, currentAnimalIndex) => {
                  if (
                    currentAnimal.textBlockIndex ===
                    newAnimalData.textBlockIndex
                  ) {
                    newAnimalsArray[currentAnimalIndex] = newAnimalData;
                  }
                });
                handleAnimalsListChangeFn(newAnimalsArray);
              }}
              deleteAnimalClickFn={(animalIdToDelete) => {
                showDeleteAnimalConfirmationPopup(animalIdToDelete);
              }}
            />
          ))}
      </>
      {isAddAnimalBlockButtonAvailable() ? (
        <div className="add-new-animal-container">
          <button
            className="btn-add-new-animal-block"
            type="button"
            disabled={
              animalsArray && animalsArray.length >= maxNumberOfAnimalBlocks
            }
            onClick={handleAddNewAnimalButtonClicked}
          >
            <i className="lely-icon-plus"></i>
            <span className="add-new-animal-label">
              {translate("ANIMALS_PAGE_ADD_ANOTHER_ANIMAL_TEXT")}
            </span>
          </button>
        </div>
      ) : null}
      {isConfirmDeleteAnimalPopupDisplayed ? (
        <ConfirmationDialog
          onConfirmCallbackFn={() => {
            handleDeleteAnimalButtonClicked(animalToDeleteIdRef.current);
          }}
          onCancelCallbackFn={() => {
            setIsConfirmDeleteAnimalPopupDisplayed(false);
          }}
          dialogTitle={`Delete animal ${animalToDeleteIdRef.current}`}
          dialogText={`Are you sure you want to remove animal ${animalToDeleteIdRef.current}?`}
          cancelButtonLabelText="No"
          confirmationButtonLabelText={`Yes, delete animal ${animalToDeleteIdRef.current}`}
        />
      ) : null}
    </div>
  );
}

export default AnimalsList;
