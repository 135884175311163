export const base64toBlob = function (base64Data) {
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays);
};

export const baseToBlob = function (imageDataFromBackend, onReadSuccessFn) {
  fetch(imageDataFromBackend)
    .then(res => onReadSuccessFn(res.blob()))
    .then(console.log)
};

export const fileToBase64 = function (file, onReadSuccessFn) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    onReadSuccessFn && onReadSuccessFn(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
};