import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import { ColorPicker } from "material-ui-color";
import {
  getCurrentLanguage,
  translate,
} from "../../../localization/TranslationUtil";
import CheckboxGroupSelection from "../../../common/components/CheckboxGroupSelection/CheckboxGroupSelection";
import LabelledImageDropZone from "../../../common/components/LabelledImageDropZone/LabelledImageDropZone";
import ImageRadioSelection from "../../../common/components/ImageRadioSelection/ImageRadioSelection";
import LabelledTextInput from "../../../common/components/LabelledTextInput/LabelledTextInput";
import Tractor from "../../../components/Tractor/Tractor";
import {
  saveChangesToFarmFeaturesPage,
  requestFarmFeaturesPageInformation,
} from "./actions";
import "./FarmFeaturesPage.scss";
import MultiLanguageTextArea from "../../../common/components/MultiLanguageTextArea/MultiLanguageTextArea";
import { DEFAULT_LANGUAGE_OPTIONS } from "../../../common/constants";
import useSaveManager from "../../../hooks/useSaveManager";
import SaveChangesConfirmationDialog from "../../../components/SaveChangesConfirmationDialog/SaveChangesConfirmationDialog";

export const colorLelyRed = "#c30a14";
export const colorLelyRedDark = "#850c18";

function FarmFeaturesPage() {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => ({ ...state.userSession }));
  const farmFeaturesPageDetails = useSelector((state) => ({
    ...state.farmFeaturesPageDetails,
  }));
  const [newFarmFeaturesDetails, setNewFarmFeaturesDetails] = useState(
    farmFeaturesPageDetails.farmFeaturesPageDetails,
  );
  const { isSaveChangesConfirmationPopupDisplayed } = useSelector((state) => ({
    ...state.saveChangesDetails,
  }));

  const { farmLanguagesDetails } = useSelector((state) => ({
    ...state.selectedFarmLanguages,
  }));
  const [hasTractor, setHasTractor] = useState(
    newFarmFeaturesDetails &&
      newFarmFeaturesDetails.vehicle &&
      newFarmFeaturesDetails.vehicle.flag,
  );

  const getInitialValueOfAstronaut = () => {
    let isThereAstronaut =
      newFarmFeaturesDetails &&
      newFarmFeaturesDetails.otherDevices &&
      newFarmFeaturesDetails.otherDevices.filter(
        (currentDeviceEntity) => currentDeviceEntity.type == "astronaut",
      );
    return isThereAstronaut && isThereAstronaut.length;
  };

  const [hasAstronaut, setHasAstronaut] = useState(
    getInitialValueOfAstronaut(),
  );
  const [tractorLineColor, setTractorLineColor] = useState(
    newFarmFeaturesDetails &&
      newFarmFeaturesDetails.vehicle &&
      newFarmFeaturesDetails.vehicle.lineColor,
  );
  const [tractorMainColor, setTractorMainColor] = useState(
    newFarmFeaturesDetails &&
      newFarmFeaturesDetails.vehicle &&
      newFarmFeaturesDetails.vehicle.mainColor,
  );
  const [isSaved, setIsSaved] = useState(true);
  const handleSaveChangesButtonClick = () => {
    dispatch(
      saveChangesToFarmFeaturesPage(accessToken, newFarmFeaturesDetails),
    );
    setSaved(true);
  };

  const { setSaved, lastSaveTimestamp } = useSaveManager(
    handleSaveChangesButtonClick,
    isSaved,
    setIsSaved,
  );

  const getInitialLanguage = () => {
    if (newFarmFeaturesDetails.languages) {
      const supportedLanguages = newFarmFeaturesDetails.languages.map(
        (lang) => lang.code,
      );
      const defLanguage =
        supportedLanguages.indexOf("en") > -1 ? "en" : supportedLanguages[0];
      const currentLanguage = getCurrentLanguage(
        defLanguage,
        supportedLanguages,
      );
      return currentLanguage;
    }
    return "";
  };
  function initializeLanguageSelection(languagesDataFromBE) {
    let selectedLanguageObjects =
      languagesDataFromBE && languagesDataFromBE.languages
        ? languagesDataFromBE.languages
        : DEFAULT_LANGUAGE_OPTIONS;
    let initialLanguageSelection = selectedLanguageObjects
      .filter(
        (currentLanguageOption) => currentLanguageOption.code.length === 2,
      )
      .map((currentLanguageOption) => currentLanguageOption.code);
    return initialLanguageSelection;
  }

  let initialLanguageSelection =
    initializeLanguageSelection(farmLanguagesDetails);
  const [currentLanguage, setCurrentLanguage] = useState(getInitialLanguage());
  const [farmLanguages, setFarmLanguages] = useState(initialLanguageSelection);

  useEffect(() => {
    dispatch(requestFarmFeaturesPageInformation(accessToken));
  }, []);
  useEffect(() => {}, [getInitialValueOfAstronaut]);

  useEffect(() => {
    setNewFarmFeaturesDetails(farmFeaturesPageDetails.farmFeaturesPageDetails);
    farmFeaturesPageDetails.farmFeaturesPageDetails &&
      farmFeaturesPageDetails.farmFeaturesPageDetails.vehicle &&
      farmFeaturesPageDetails.farmFeaturesPageDetails.vehicle.flag &&
      setHasTractor(true);
  }, [farmFeaturesPageDetails.farmFeaturesPageDetails]);

  function getPreviewJSX() {
    return (
      newFarmFeaturesDetails &&
      newFarmFeaturesDetails.otherDevices &&
      newFarmFeaturesDetails.otherDevices.map((currentDeviceEntity, index) => {
        return (
          <div className="robotsOnPreview">
            <div
              className={
                "farm-features-mobile-" + currentDeviceEntity.type.toLowerCase()
              }
              key={index}
            />
            <div
              className={
                "farm-features-mobile-" +
                newFarmFeaturesDetails.producingDevice.type.toLowerCase()
              }
              key={index}
            />
          </div>
        );
      })
    );
  }

  function handleLanguageSwitchChange(labelText, keyValue, checked) {
    let newFarmFeaturesDetailsCopy = _.cloneDeep(newFarmFeaturesDetails);
    if (!checked) {
      newFarmFeaturesDetailsCopy.languages =
        newFarmFeaturesDetails.languages.filter((currentLanguage) => {
          return currentLanguage.code !== keyValue;
        });
    } else {
      newFarmFeaturesDetailsCopy.languages.push({
        code: keyValue,
        name: labelText,
      });
    }
    setNewFarmFeaturesDetails(newFarmFeaturesDetailsCopy);
    setSaved(false);
  }

  function isCurrentLanguageSelected(languageCode) {
    let isLanguageSelected =
      newFarmFeaturesDetails.languages &&
      newFarmFeaturesDetails.languages.filter((currentLanguage) => {
        return currentLanguage.code === languageCode;
      }).length > 0;
    return isLanguageSelected;
  }
  const getNameOfTheAstronaut = () => {
    let astronautName = "";
    newFarmFeaturesDetails &&
      newFarmFeaturesDetails.otherDevices.forEach((currentDeviceEntity) => {
        if (currentDeviceEntity.type == "astronaut") {
          astronautName = currentDeviceEntity.name;
        }
      });
    return astronautName;
  };
  const getInitialValueOfDiscovery = () => {
    let initialValueOfDiscovery = "";
    newFarmFeaturesDetails &&
      newFarmFeaturesDetails.otherDevices &&
      newFarmFeaturesDetails.otherDevices.some((currentDeviceEntity) => {
        if (
          currentDeviceEntity.type == "discovery" ||
          currentDeviceEntity.type == "collector"
        ) {
          return (initialValueOfDiscovery = currentDeviceEntity.type);
        } else {
          initialValueOfDiscovery = "no-cleaning-robot";
        }
      });
    return initialValueOfDiscovery;
  };
  const getInitialValueOfFeedingRobot = () => {
    let initialValueOfDiscovery = "";
    newFarmFeaturesDetails &&
      newFarmFeaturesDetails.otherDevices &&
      newFarmFeaturesDetails.otherDevices.some((currentDeviceEntity) => {
        if (
          currentDeviceEntity.type == "vector" ||
          currentDeviceEntity.type == "juno"
        ) {
          return (initialValueOfDiscovery = currentDeviceEntity.type);
        } else {
          initialValueOfDiscovery = "no-feeding-robot";
        }
      });
    return initialValueOfDiscovery;
  };
  function checkIfThereIsCleaningRobot(currentDeviceEntity) {
    if (
      currentDeviceEntity.type == "discovery" ||
      currentDeviceEntity.type == "collector" ||
      currentDeviceEntity.type == "no-cleaning-robot"
    ) {
      return true;
    } else {
      return false;
    }
  }
  function checkIfThereIsFeedingRobot(currentDeviceEntity) {
    if (
      currentDeviceEntity.type == "vector" ||
      currentDeviceEntity.type == "juno" ||
      currentDeviceEntity.type == "no-feeding-robot"
    ) {
      return true;
    } else {
      return false;
    }
  }
  let tractorDescriptionData =
    newFarmFeaturesDetails.vehicle &&
    newFarmFeaturesDetails.vehicle.description;
  if (
    newFarmFeaturesDetails.vehicle &&
    newFarmFeaturesDetails.vehicle.description &&
    typeof newFarmFeaturesDetails.vehicle.description === "string"
  ) {
    let previousTractorDescriptionString = _.cloneDeep(
      newFarmFeaturesDetails.vehicle.description,
    );
    tractorDescriptionData = {};
    tractorDescriptionData["en"] = previousTractorDescriptionString;
  }

  return (
    <div className="FarmFeaturesPage">
      <h3 className="farm-features-page--header">
        {translate("FARM_FEATURES_PAGE_HEADING")}
      </h3>
      <p className="farm-features-intro">
        {translate("FARM_FEATURES_PAGE_INTRO_TEXT")}
      </p>
      <div className="farmFeaturesSelection">
        <Grid xs={12} item>
          <CheckboxGroupSelection
            componentLabel={translate("FARM_ROBOT_SELECTION")}
            optionsArray={[
              {
                label: "Astronaut",
                classNameAppendix: "astronaut",
                value: "astronaut",
              },
              {
                label: "Calm",
                classNameAppendix: "calm",
                value: "calm",
              },
              {
                label: "Grazeway",
                classNameAppendix: "grazeway",
                value: "grazeway",
              },
              {
                label: "Luna",
                classNameAppendix: "luna",
                value: "luna",
              },
              // {
              //   label: "Vector",
              //   classNameAppendix: "vector",
              //   value: "vector",
              // },
            ]}
            onSelectionChange={(newSelectedValue, isChecked) => {
              let modifiedFarmFeaturesDetailsCopy = _.cloneDeep(
                newFarmFeaturesDetails,
              );
              modifiedFarmFeaturesDetailsCopy.otherDevices =
                modifiedFarmFeaturesDetailsCopy.otherDevices.filter(
                  (currentDeviceEntity) => {
                    return (
                      currentDeviceEntity.type !==
                      (newSelectedValue && newSelectedValue.toLowerCase())
                    );
                  },
                );
              if (isChecked) {
                modifiedFarmFeaturesDetailsCopy.otherDevices.push({
                  name: "",
                  type: newSelectedValue.toLowerCase(),
                  clickable: "true",
                  description: null,
                });
              }
              setNewFarmFeaturesDetails(modifiedFarmFeaturesDetailsCopy);
              setSaved(false);
            }}
            selectedOptions={
              newFarmFeaturesDetails &&
              newFarmFeaturesDetails.otherDevices &&
              newFarmFeaturesDetails.otherDevices.map((currentDeviceEntity) => {
                return currentDeviceEntity.type;
              })
            }
          />
        </Grid>
        <ImageRadioSelection
          optionsArray={[
            {
              label: translate("NO_ROBOT"),
              classNameAppendix: "no-feeding-robot",
              value: "no-feeding-robot",
            },
            {
              label: "Juno",
              classNameAppendix: "juno",
              value: "juno",
            },
            {
              label: "Vector",
              classNameAppendix: "vector",
              value: "vector",
            },
          ]}
          initialSelectedValue={getInitialValueOfFeedingRobot()}
          onSelectionChangeFn={(newSelectedValue) => {
            let modifiedFarmDetailsCopy = _.cloneDeep(newFarmFeaturesDetails);
            const ifThereIsFeedingRobot =
              modifiedFarmDetailsCopy?.otherDevices.some(
                checkIfThereIsFeedingRobot,
              );
            if (ifThereIsFeedingRobot) {
              modifiedFarmDetailsCopy.otherDevices.forEach(
                (currentDeviceEntity) => {
                  if (
                    currentDeviceEntity.type == "vector" ||
                    currentDeviceEntity.type == "juno" ||
                    currentDeviceEntity.type == "no-feeding-robot"
                  ) {
                    currentDeviceEntity.type = newSelectedValue.toLowerCase();
                    currentDeviceEntity.name = newSelectedValue;
                  }
                },
              );
            } else {
              modifiedFarmDetailsCopy.otherDevices.push({
                name: newSelectedValue.toLowerCase(),
                type: newSelectedValue.toLowerCase(),
                clickable: "true",
                description: null,
              });
            }
            modifiedFarmDetailsCopy.otherDevices =
              modifiedFarmDetailsCopy.otherDevices.filter(
                (robot) => robot.name != "no-feeding-robot",
              );

            setNewFarmFeaturesDetails(modifiedFarmDetailsCopy);
            setSaved(false);
          }}
        />
        <ImageRadioSelection
          optionsArray={[
            {
              label: translate("NO_ROBOT"),
              classNameAppendix: "no-cleaning-robot",
              value: "no-cleaning-robot",
            },
            {
              label: "90 S/SW",
              classNameAppendix: "collector",
              value: "collector",
            },
            {
              label: "Collector",
              classNameAppendix: "discovery",
              value: "discovery",
            },
          ]}
          initialSelectedValue={getInitialValueOfDiscovery()}
          onSelectionChangeFn={(newSelectedValue) => {
            let modifiedFarmDetailsCopy = _.cloneDeep(newFarmFeaturesDetails);
            const ifThereIsCleaningRobot =
              modifiedFarmDetailsCopy?.otherDevices.some(
                checkIfThereIsCleaningRobot,
              );
            if (ifThereIsCleaningRobot) {
              modifiedFarmDetailsCopy.otherDevices.forEach(
                (currentDeviceEntity) => {
                  if (
                    currentDeviceEntity.type == "discovery" ||
                    currentDeviceEntity.type == "collector" ||
                    currentDeviceEntity.type == "no-cleaning-robot"
                  ) {
                    currentDeviceEntity.type = newSelectedValue.toLowerCase();
                    currentDeviceEntity.name = newSelectedValue;
                  }
                },
              );
            } else {
              modifiedFarmDetailsCopy.otherDevices.push({
                name: newSelectedValue.toLowerCase(),
                type: newSelectedValue.toLowerCase(),
                clickable: "true",
                description: null,
              });
            }
            modifiedFarmDetailsCopy.otherDevices =
              modifiedFarmDetailsCopy.otherDevices.filter(
                (robot) => robot.name != "no-cleaning-robot",
              );

            setNewFarmFeaturesDetails(modifiedFarmDetailsCopy);
            setSaved(false);
          }}
        />

        {getInitialValueOfAstronaut() ? (
          <LabelledTextInput
            initialValue={getNameOfTheAstronaut()}
            labelText={translate("FARM_FEATURES_NAME_OF_THE_ASTRONAUT")}
            placeholderText={translate("ROBOT_NAME")}
            handleChangeFn={(newAstronautName) => {
              let modifiedFarmFeaturesDetailsCopy = _.cloneDeep(
                newFarmFeaturesDetails,
              );
              modifiedFarmFeaturesDetailsCopy.otherDevices.forEach(
                (currentDeviceEntity) => {
                  if (currentDeviceEntity.type == "astronaut") {
                    currentDeviceEntity.name = newAstronautName;
                    setHasAstronaut(true);
                  }
                },
              );
              setNewFarmFeaturesDetails(modifiedFarmFeaturesDetailsCopy);
              setSaved(false);
            }}
            additionalClassName="about-the-farm--textField"
          />
        ) : null}
      </div>

      <div className="farm-features-mobile">{getPreviewJSX()}</div>
      <div className="farm-features-tractor-block">
        <div className="farm-features-do-you-have-a-tractor">
          {translate("DO_YOU_HAVE_A_TRACTOR")}
        </div>
        <ImageRadioSelection
          optionsArray={[
            {
              label: translate("YES"),
              value: "1",
              classNameAppendix: "style3",
            },
            {
              label: translate("NO"),
              value: "0",
              classNameAppendix: "style3",
            },
          ]}
          initialSelectedValue={
            newFarmFeaturesDetails &&
            newFarmFeaturesDetails.vehicle &&
            newFarmFeaturesDetails.vehicle.flag
              ? "1"
              : "0"
          }
          onSelectionChangeFn={(newSelectedValue) => {
            setNewFarmFeaturesDetails({
              ...newFarmFeaturesDetails,
              vehicle:
                newSelectedValue === "1"
                  ? {
                      type: "Tractor",
                      flag: true,
                      mainColor: tractorMainColor || colorLelyRed,
                      lineColor: tractorLineColor || colorLelyRedDark,
                    }
                  : { ...newFarmFeaturesDetails.vehicle, flag: false },
            });
            setSaved(false);
            setHasTractor(newSelectedValue === "1");
          }}
        />
        {hasTractor && (
          <div>
            <MultiLanguageTextArea
              multiLanguageData={tractorDescriptionData}
              fieldLabelTranslationKey={"TRACTOR_DESCRIPTION"}
              fieldPlaceholderTextTranslationKey={""}
              handleDataChangedFn={(newTractorDescriptionMultiLanguageData) => {
                setNewFarmFeaturesDetails({
                  ...newFarmFeaturesDetails,
                  vehicle: {
                    ...newFarmFeaturesDetails.vehicle,
                    description: newTractorDescriptionMultiLanguageData,
                  },
                });
                setSaved(false);
              }}
              languageKeysArray={farmLanguages}
              additionalCssClassname="multiLanguageTextArea--tractorDescription--textArea"
            />
            <div className="farm-features-tractor-container">
              <div>
                <div className="farm-features-tractor-main-color">
                  Tractor main color
                </div>
                <div className="farm-features-main-color-picker">
                  <ColorPicker
                    disableAlpha
                    disablePlainColor
                    defaultValue={tractorMainColor || colorLelyRed}
                    disableTextfield={false}
                    value={tractorMainColor}
                    onChange={(newMainColor) => {
                      let isNewColorValid = true; // (newMainColor && newMainColor.length === 7);
                      let newMainColorHex = newMainColor.css
                        ? newMainColor.css.backgroundColor.indexOf("#") !== -1
                          ? newMainColor.css.backgroundColor
                          : newMainColor.raw
                        : newMainColor;
                      if (isNewColorValid) {
                        setNewFarmFeaturesDetails({
                          ...newFarmFeaturesDetails,
                          vehicle: {
                            ...newFarmFeaturesDetails.vehicle,
                            mainColor: newMainColorHex,
                          },
                        });
                        setTractorMainColor(newMainColorHex);
                        setSaved(false);
                      }
                    }}
                  />
                </div>
                <div className="farm-features-tractor-line-color">
                  Tractor line color
                </div>
                <div className="farm-features-main-color-picker">
                  <ColorPicker
                    disableAlpha
                    disableTextfield={false}
                    disablePlainColor
                    defaultValue={tractorLineColor || colorLelyRedDark}
                    value={tractorLineColor || colorLelyRedDark}
                    onChange={(newLineColor) => {
                      let isNewColorValid = true; // (newLineColor && newLineColor.length === 7);
                      let newLineColorHex = newLineColor.css
                        ? newLineColor.css.backgroundColor.indexOf("#") !== -1
                          ? newLineColor.css.backgroundColor
                          : newLineColor.raw
                        : newLineColor;
                      if (isNewColorValid) {
                        setNewFarmFeaturesDetails({
                          ...newFarmFeaturesDetails,
                          vehicle: {
                            ...newFarmFeaturesDetails.vehicle,
                            lineColor: newLineColorHex,
                          },
                        });
                        setTractorLineColor(newLineColorHex);
                        setSaved(false);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="farm-features-tractor">
                <Tractor
                  lineColor={tractorLineColor}
                  mainColor={tractorMainColor}
                />
              </div>
            </div>
            <div className="farm-features-tractor--dropzone">
              <LabelledImageDropZone
                labelText={translate("TRACTOR_PICTURE_LABEL")}
                dropzoneText={translate(
                  "ABOUT_YOU_PAGE_CHANGE_PROFILE_PICTURE_DROP_ZONE_LABEL",
                )}
                initialValue={
                  newFarmFeaturesDetails.vehicle &&
                  newFarmFeaturesDetails.vehicle.imageData
                }
                key={
                  newFarmFeaturesDetails.vehicle &&
                  newFarmFeaturesDetails.vehicle.imageData
                }
                onChangeFileFn={(newFileBase64) => {
                  setNewFarmFeaturesDetails({
                    ...newFarmFeaturesDetails,
                    vehicle: {
                      ...newFarmFeaturesDetails.vehicle,
                      imageData: newFileBase64,
                    },
                  });
                  setSaved(false);
                }}
                onDeleteFileFn={() => {
                  setNewFarmFeaturesDetails({
                    ...newFarmFeaturesDetails,
                    vehicle: {
                      ...newFarmFeaturesDetails.vehicle,
                      imageData: "",
                    },
                  });
                  setSaved(false);
                }}
                defaultPreviewCssClassname="tractorImagePreview"
              />
            </div>
          </div>
        )}
      </div>
      {isSaved && lastSaveTimestamp !== 0 ? (
        <Button
          className="btn-already-saved"
          disabled={false}
          onClick={() => {}}
        >
          {translate("ADMIN_PAGE_ALREADY_SAVED_CHANGES_BUTTON_LABEL")}
        </Button>
      ) : null}
      {!isSaved ? (
        <Button
          className="btn-save-changes"
          onClick={handleSaveChangesButtonClick}
        >
          {translate("ABOUT_THE_FARM_PAGE_SAVE_CHANGES_BUTTON_LABEL")}
        </Button>
      ) : null}
      {isSaveChangesConfirmationPopupDisplayed ? (
        <SaveChangesConfirmationDialog />
      ) : null}
    </div>
  );
}

export default FarmFeaturesPage;
