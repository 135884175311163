import moment from "moment";
import React from "react";
import {
  translate,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import TextCaption from "../TextCaption/TextCaption";
import "./MijnMelkAdultOnboardingSlide3.scss";

function MijnMelkAdultOnboardingSlide3({
  fatPercentage,
  proteinPercentage,
  lactosePercentage,
  batchProductType
}) {

  const ONBOARDING_SLIDE3_TEXT2 = batchProductType === "Yogurt"
  ?  "MIJN_MELK_ADULT_ONBOARDING_SLIDE3_TEXT2_YOGHURT"
  : "MIJN_MELK_ADULT_ONBOARDING_SLIDE3_TEXT2"
  return (
    <div>
      <div className="mijnMelkAdultOnboarding-slide3--fromBottleToLips"></div>
      <div className="mijnMelkAdultOnboarding-slide3--chickens"></div>
      <div className="mijnMelkAdultOnboarding-slide1--grass"></div>
      <div className="mijnMelkAdultOnboarding-slide3--cow"></div> 
      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide3-text1">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE3_TEXT1",
              {},
            ),
          }}
        ></span>
      </TextCaption>

      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide3-text2">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              ONBOARDING_SLIDE3_TEXT2,
              {
                fatPercentage: fatPercentage.toFixed(1),
              },
            ),
          }}
        ></span>
      </TextCaption>

      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide3-text3">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE3_TEXT3",
              {
                proteinPercentage: proteinPercentage.toFixed(1),
              },
            ),
          }}
        ></span>
      </TextCaption>

      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide3-text4">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE3_TEXT4",
              {
                lactosePercentage: lactosePercentage.toFixed(1),
              },
            ),
          }}
        ></span>
      </TextCaption>
    </div>
  );
}

export default MijnMelkAdultOnboardingSlide3;
