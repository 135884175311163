import React from "react";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";
import "./Brand.scss";

/** Brand component */
function Brand({
  brandData,
  brandCssClassname,
  isClickable,
  brandClickHandlerFn,
}) {
  let brandSubmoduleCssClassname =
    brandCssClassname + "--" + brandData.name.toLowerCase();
  brandSubmoduleCssClassname = isClickable
    ? brandSubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : brandSubmoduleCssClassname;

  return (
    <div
      className={brandCssClassname + " " + brandSubmoduleCssClassname}
      onClick={() => {
        brandClickHandlerFn(brandData);
      }}
    ></div>
  );
}
export default Brand;
