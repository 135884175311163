import OptionButtonsSelect from "../../common/components/OptionButtonsSelect/OptionButtonsSelect";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
    translate,
    translateDate,
    translateDynamic,
    translateWithValues,
} from "../../localization/TranslationUtil";
import "./CowNameVoting.scss";
import { voteForCowName } from "./actions";
import { useDispatch, useSelector } from "react-redux";
import { hasCowNameBeenChosen } from "./CowNameUtil";
import _ from "lodash";

/** CowNameVoting component */
function CowNameVoting({ cowDetails, farmDetails }) {
    const dispatch = useDispatch();

    const { cowIdsWithVote, lastVoteResult } = useSelector(
        (state) => state.namePollDetails,
    );

    useEffect(() => { }, [lastVoteResult]);

    let cowPollDetails = _.isEmpty(lastVoteResult)
        ? cowDetails.poll
        : lastVoteResult;

    let isCowNameVotedForAlready =
        cowIdsWithVote.indexOf(cowDetails.externalId) !== -1;
    let isPollEndDateAfterCurrentDate = moment(cowPollDetails.endDate).isAfter(
        new Date(),
    );
    let isPollActive =
        _.isEmpty(cowPollDetails.chosenName) || isPollEndDateAfterCurrentDate;
    let hasCowNameBeenChosenAlready = hasCowNameBeenChosen(cowPollDetails);

    //#region convert current poll object structure to proper poll options object
    function getPercentageByVotes(numberOfVotes, totalNumberOfVotes) {
        return Math.round((numberOfVotes / totalNumberOfVotes) * 100);
    }

    let pollOptions = {};
    let totalNumberOfVotes =
        cowPollDetails.name1Votes +
        cowPollDetails.name2Votes +
        cowPollDetails.name3Votes;
    pollOptions[cowPollDetails.name1] =
        totalNumberOfVotes > 0
            ? getPercentageByVotes(cowPollDetails.name1Votes, totalNumberOfVotes)
            : 0;
    pollOptions[cowPollDetails.name2] =
        totalNumberOfVotes > 0
            ? getPercentageByVotes(cowPollDetails.name2Votes, totalNumberOfVotes)
            : 0;
    pollOptions[cowPollDetails.name3] =
        totalNumberOfVotes > 0
            ? getPercentageByVotes(cowPollDetails.name3Votes, totalNumberOfVotes)
            : 0;
    let pollAvailableNameOptions = Object.keys(pollOptions);
    //#endregion

    function handleCowNameClicked(cowName) {
        const pollId = cowPollDetails.id;
        const cowExternalId = cowDetails.externalId;
        dispatch(voteForCowName(pollId, cowExternalId, cowName));
    }

    let containerCssClassname = hasCowNameBeenChosenAlready
        ? "cowNameVoting is-cow-name-chosen-already"
        : "cowNameVoting";

    return (
        <div className={containerCssClassname}>
            {isPollActive && isCowNameVotedForAlready ? (
                <h3 className="cowNameVoting--header">
                    {translate("COW_NAME_POLL_THANK_YOU_HEADER_TEXT")}
                </h3>
            ) : null}
            {isPollActive && isCowNameVotedForAlready ? (
                <h3 className="cowNameVoting--subheading">
                    {translateWithValues("COW_NAME_POLL_THANK_YOU_SUBHEADING_TEXT", {
                        endPollDate: translateDate(cowPollDetails.endDate),
                    })}
                </h3>
            ) : null}
            {isPollActive && !isCowNameVotedForAlready ? (
                <h3 className="cowNameVoting--header">
                    {translate("COW_NAME_POLL_HEADER_TEXT")}
                </h3>
            ) : null}
            {isPollActive && !isCowNameVotedForAlready ? (
                <h3 className="cowNameVoting--subheading">
                    {translateWithValues("COW_NAME_POLL_INTRO_TEXT", {
                        farmerName: translateDynamic(farmDetails.farmer.name),
                    })}
                </h3>
            ) : null}
            {hasCowNameBeenChosenAlready ? (
                <>
                    <span className="cowNameVoting--party"></span>
                    <h3 className="cowNameVoting--subheading">
                        {translate("COW_NAME_CHOSEN_TEXT")}
                    </h3>
                </>
            ) : null}
            {isPollActive ? (
                <OptionButtonsSelect
                    onOptionSelectedCallbackFn={(optionText) => {
                        handleCowNameClicked(optionText);
                    }}
                    doDisplayPercentages={isCowNameVotedForAlready}
                    values={pollOptions}
                    options={pollAvailableNameOptions}
                />
            ) : null}
        </div>
    );
}
export default CowNameVoting;
