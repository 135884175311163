import React from "react";
import { useSelector } from "react-redux";
import "./Sun.scss";
import { getPlanetsPositionByCurrentMomentAndViewportSize20 } from "./PlanetPositionUtil";
import moment from "moment";
import {
  WEATHER_TYPE_SNOW,
  WEATHER_TYPE_SNOWSTORM,
} from "../../common/constants";

/** Sun component */
function Sun({
  viewportWidthInPx,
  viewportHeightInPx,
  cssClassName,
  isAdultThemeApplied,
}) {
  let { currentWeatherString, currentMoment, sunrise, sunset } = useSelector(
    (state) => state.storyMoment,
  );
  currentMoment = moment(currentMoment);

  let sunriseHour =
    sunrise && sunrise.split(":")[0] != "null" ? sunrise.split(":")[0] : "06";
  let sunriseMinutes =
    sunrise && sunrise.split(":")[1] != "null" ? sunrise.split(":")[1] : "00";
  let sunsetHour =
    sunset && sunset.split(":")[0] != "null" ? sunset.split(":")[0] : "22";
  let sunsetMinutes =
    sunset && sunset.split(":")[1] != "null" ? sunset.split(":")[1] : "00";

  let planetsPosition = getPlanetsPositionByCurrentMomentAndViewportSize20(
    currentMoment,
    viewportWidthInPx,
    viewportHeightInPx,
    sunriseHour,
    sunriseMinutes,
    sunsetHour,
    sunsetMinutes,
  );
  let isItSnowing =
    currentWeatherString === WEATHER_TYPE_SNOW ||
    currentWeatherString === WEATHER_TYPE_SNOWSTORM;
  let sunCssClassname = "sun";
  sunCssClassname = isAdultThemeApplied
    ? sunCssClassname + " sun--adult"
    : sunCssClassname;
  return (
    <div className="sun--container">
      {!isItSnowing ? (
        <div
          className={sunCssClassname}
          style={{
            transform: `translateX(${planetsPosition.sun.top}px) translateY(${planetsPosition.sun.left}px) translateZ(1px)`,
          }}
        ></div>
      ) : null}
    </div>
  );
}
export default Sun;
