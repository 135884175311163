import React from "react";
import { useSelector, useDispatch } from "react-redux";
// Actions
import { redirect } from "../../components/CustomRedirect/actions";
// Styles
import "./AppSidebar.scss";
// Translations
import { translate } from "../../localization/TranslationUtil";
import { requestApplicationNavigation } from "../../common/components/SaveChangesManager/actions";
import { NAVIGATION_TYPE_NAVIGATE_TO_OTHER_PAGE } from "../../common/components/SaveChangesManager/config";
import { useLocation } from "react-router-dom";

function AppSidebar() {
  const dispatch = useDispatch();
  const currentAdminAppUrl = useLocation().pathname;

  const getNavigationElements = () => {
    return [
      getMenuButton("aboutyou", translate("MENU_ITEM_ABOUT_YOU"), "aboutyou"),
      getMenuButton(
        "aboutthefarm",
        translate("MENU_ITEM_ABOUT_THE_FARM"),
        "aboutthefarm",
      ),
      getMenuButton("animals", translate("MENU_ITEM_ANIMALS"), "animals"),
      getMenuButton("cows", translate("MENU_ITEM_THE_COWS"), "cows"),
      getMenuButton(
        "farmfeatures",
        translate("MENU_ITEM_FARM_FEATURES"),
        "farmfeatures",
      ),
      getMenuButton(
        "sustainability",
        translate("MENU_ITEM_SUSTAINABILITY"),
        "sustainability",
      ),
    ];
  };

  const getMenuButton = (iconId, menuOptionTitle, appendixUrl) => {
    let menuButtonAdditionalIconCssClassname =
      currentAdminAppUrl.indexOf(appendixUrl) !== -1
        ? "sidebar__navitem sidebar__navitem-" +
          iconId +
          " sidebar__selectedOption"
        : "sidebar__navitem sidebar__navitem-" + iconId;
    return (
      <li key={appendixUrl} className={menuButtonAdditionalIconCssClassname}>
        <a
          href="#"
          title={menuOptionTitle}
          className="btn sidebar__navbtn"
          onClick={(event) => {
            event.preventDefault();
            dispatch(
              requestApplicationNavigation(
                NAVIGATION_TYPE_NAVIGATE_TO_OTHER_PAGE,
                appendixUrl,
              ),
            );
          }}
        >
          <span className="sidebar__navtitle">
            <i className={"lely-icon-" + iconId}></i>
            {menuOptionTitle}
          </span>
        </a>
      </li>
    );
  };

  let sidebarClassName =
    "sidebar-fixed-wrapper sidebar-fixed-wrapper--small app-sidebar hoverable";
  let navigationElements = getNavigationElements();

  const redirectOnClick = (path) => {
    dispatch(redirect(path));
  };

  return (
    <div className={sidebarClassName}>
      <div className="sidebar sidebar--small">
        <div className="sidebar__bar">
          <div>
            <div className="sidebar__bar-inner">
              <ul className="sidebar__navlist d-sm-none">
                {navigationElements}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppSidebar;
