import React from "react";
import useDynamicSVGImport from "./useDynamicSVGImport";

/**
 * Simple wrapper for dynamic SVG import hook.
 */
const DynamicInlineSVG = ({
  svgFullFilePath,
  themeName,
  onCompleted,
  onError,
  ...rest
}) => { 
  if (!themeName) {
    themeName = 'lely';
  }
  const { error, loading, SvgElement } = useDynamicSVGImport(
    svgFullFilePath,
    themeName,
    {
      onCompleted,
      onError,
    },
  );
  if (error) {
    return error.message;
  }
  if (loading) {
    return "Loading...";
  }
  if (SvgElement) {
    return <SvgElement {...rest} />;
  }
  return null;
};

export default DynamicInlineSVG;
