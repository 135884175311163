import { getRandomNumberBetweenMAndN } from "../../components/VirtualFarm/util";
import React, { useEffect, useRef } from "react";
import "./BirthdayPartyFlute.scss";


function BirthdayPartyFlute({ totalNumberOfAvailablePartyFluteVarieties }) {
    const BASE_CLASSNAME = "birthdayPartyFlute";
    const birthdayPartyFluteContainerRef = useRef(BASE_CLASSNAME);

    useEffect(() => {
        let randomPartyFluteId = getRandomNumberBetweenMAndN(1, totalNumberOfAvailablePartyFluteVarieties);
        birthdayPartyFluteContainerRef.current.className = BASE_CLASSNAME + " " + BASE_CLASSNAME + "-" + randomPartyFluteId;
    }, []);

    return <div
        className={BASE_CLASSNAME}
        ref={birthdayPartyFluteContainerRef}
    >
    </div>;
}

export default BirthdayPartyFlute;
