import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import "./VirtualFarmPage.scss";
import "./VirtualFarmTour.scss";
import RibbonTitle from "../../components/RibbonTitle/RibbonTitle";
import { useDispatch, useSelector } from "react-redux";
import Cow from "../../components/Cow/Cow";
import { ReactComponent as AdultHills } from "../../resources/assets/themes/adult/16x9/screens/virtualFarm/background_farm_hill.svg";
import Farmer from "../../components/Farmer/Farmer";
import Pet from "../../components/Pet/Pet";
import Brand from "../../components/Brand/Brand";
import MilkFactory from "../../components/MilkFactory/MilkFactory";
import CheeseFactory from "../../components/CheeseFactory/CheeseFactory";
import IceCreamFactory from "../../components/IceCreamFactory/IceCreamFactory";
import DairyFactory from "../../components/DairyFactory/DairyFactory";
import AstronautFactory from "../../components/AstronautFactory/AstronautFactory";
import DiscoveryFeature from "../../components/DiscoveryFeature/DiscoveryFeature";
import VectorFeature from "../../components/VectorFeature/VectorFeature";
import Vehicle from "../../components/Vehicle/Vehicle";
import Shop from "../../components/Shop/Shop";
import { setGoogleAnalyticTracking } from "../../common/googleAnalyticTracking/actions";
import useWindowDimensions from "../../hooks/useViewportDimensions";
import {
  COW_CSS_CLASSNAME,
  FARMER_CSS_CLASSNAME,
  PET_CSS_CLASSNAME,
  BRAND_CSS_CLASSNAME,
  VEHICLE_CSS_CLASSNAME,
  VEHICLE_LEFT_CSS_CLASSNAME,
  SHOP_CSS_CLASSNAME,
  SOLARPANEL_CSS_CLASSNAME,
  ACTOR_TYPE_PET,
  ACTOR_TYPE_MIJN_MELK,
  ACTOR_TYPE_BATCH_COW_LIST,
  ACTOR_TYPE_FARM,
  ACTOR_TYPE_SUSTAINABILITY_PLATE,
  SIDE_MENU_OPTION_HOW_TO,
  MIJN_MELK_CSS_CLASSNAME,
  ANIMAL_TYPE_SMALL_DOG,
  ANIMAL_TYPE_CAT,
  ANIMAL_TYPE_MEDIUM_DOG,
  ANIMAL_TYPE_LARGE_DOG,
  LIVE_DATA_POPUP_LIFETIME_IN_SECONDS,
  ACTOR_TYPE_SHOP,
  ACTOR_TYPE_SOLARPANEL,
  ANIMAL_TYPE_HORSE,
  GESTURE_TYPE_ZOOM,
  GESTURE_TYPE_DRAG,
} from "../../common/constants";
import { selectActor } from "../../components/Actor/actions";
import { selectCow } from "../../components/Cow/actions";
import PinchView from "../../components/PinchView/PinchView";
import IconButton from "../../components/IconButton/IconButton";
import {
  cowCoordinates,
  farmerPetCoordinates,
  otherAnimalCoordinates,
} from "../../components/VirtualFarm/util";
import {
  translate,
  translateDynamic,
  translateWithValues,
} from "../../localization/TranslationUtil";
import { getFarmPopupInfoByFarmDetails } from "../../common/actorUtil";
import GrazewayFeature from "../../components/Grazeway/GrazewayFeature";
import ArrowPopup from "../../components/ArrowPopup/ArrowPopup";
import { markFirstVirtualFarmTourAsComplete } from "../../components/VirtualFarm/virtualFarmTour/actions";
import MijnMelk from "../../components/MijnMelk/MijnMelk";
import Cat from "../../components/Cat/Cat";
import { measureResponsive } from "../../components/VirtualFarm/util";
import { showPopup } from "../../common/components/PopupContainer/actions";
import SpaceAwareCow from "../../components/Cow/SpaceAwareCow";
import Excrement from "../../components/Cow/Excrement";
import DiscoveryRoute from "../../components/DiscoveryFeature/DiscoveryRoute";
import { DiscoveryRouteSettings } from "../../components/DiscoveryFeature/DiscoveryRouteSettings";
import SampleTSComponent from "../../components/SampleTSComponent/SampleTSComponent";
import { liveData, stopSignalRConnection } from "../../common/data/live_data";
import { LIVE_DATA_SIGNALR_URL } from "../../api/lelyBackend/config";
import { printConsoleHeading } from "../../common/consoleUtil";
import LiveDataPopup from "../../components/LiveDataPopup/LiveDataPopup";
import {
  cowEntered,
  cowExit,
  resetAstronautState,
} from "../../components/LiveDataPopup/actions";
import SolarPanel from "../../components/SolarPanel/SolarPanel";
import {
  VIRTUAL_FARM_TOUR_STARTED,
  VIRTUAL_FARM_TOUR_WATCHED,
} from "../../common/googleAnalyticTracking/types";
import LunaFeature from "../../components/LunaFeature/LunaFeature";
import CollectorFeature from "../../components/RobotCollector/CollectorFeature";
import VirtualFarmFooter from "../../components/VirtualFarmFooter/VirtualFarmFooter";
import HudDisplay from "../../common/components/HudDisplay/HudDisplay";
import UnavailablePage from "../UnavailablePage/UnavailablePage";
import BirdContainer from "../../components/Bird/BirdContainer";
import JunoFeature from "../../components/JunoFeature/JunoFeature";

const farmerCoordinates = {
  x: "calc(var(--vh, 1vh) * 26.5)",
  y: "calc(var(--vh, 1vh) * 122",
};
const VirtualFarmPage = React.memo(
  ({
    moveToNextSlideFn,
    showActorStoryFn,
    startPoint,
    setStartPoint,
    farmId,
    isAgeSelected,
  }) => {
    const { selectedLanguage } = useSelector(
      (state) => state.selectedLanguageDetails,
    );
    const { width: VIEWPORT_WIDTH_IN_PX, height: VIEWPORT_HEIGHT_IN_PX } =
      useWindowDimensions();
    const [isZoomedIn, setIsZoomedIn] = useState(false);
    const [latestSelectedItem, setLatestSelectedItem] = useState({});
    const [currentlyMilkedCow, setCurrentlyMilkedCow] = useState({});
    const dispatch = useDispatch();
    const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
    const {
      isVirtualFarmTourComplete: isVirtualFarmTourCompleteFromLocalStorage,
    } = useSelector((state) => state.virtualFarmTour);
    const farmerName = translateDynamic(farmDetails?.farmer?.name);
    const farmName = translateDynamic(farmDetails?.farmName).toUpperCase();
    const farmLocation = farmDetails?.location?.name;
    //#region Virtual Farm Tour logic
    const [isVirtualTourActive, setIsVirtualTourActive] = useState(
      !isVirtualFarmTourCompleteFromLocalStorage,
    );
    const [currentVirtualTourIndex, setCurrentVirtualTourIndex] = useState(0);
    const [hudDetails, setHudDetails] = useState({});

    useEffect(() => {
      if (startPoint === SIDE_MENU_OPTION_HOW_TO) {
        startVirtualFarmTour();
      }
    }, [startPoint]);

    useEffect(() => {
      setHudDetails(farmDetails.realTimeData);
    }, [farmDetails]);

    const markCowAsMilked = (cowExternalId) => {
      if (!cowExternalId) {
        return;
      }
      let milkedCowDetails =
        farmDetails && farmDetails.milkBatch && farmDetails.milkBatch.cows
          ? farmDetails.milkBatch.cows.filter(
              (currentCowData) => currentCowData.externalId == cowExternalId,
            )
          : null;
      if (milkedCowDetails) {
        dispatch(cowEntered(milkedCowDetails));
        setCurrentlyMilkedCow(milkedCowDetails && milkedCowDetails[0]);
      }
    };

    const removeLiveDataPopup = () => {
      setCurrentlyMilkedCow("");
      dispatch(cowExit());
    };
    const lastCowMilkingNotification =
      farmDetails?.realTimeData?.milkingNotifications.slice(-1);

    console.log("milkingNotification", markCowAsMilked);
    //#region Live Data logic
    useEffect(() => {
      markCowAsMilked(lastCowMilkingNotification[0]?.cowId); // initialize live data popup after refreshing the page

      const liveDataInstance = liveData(
        farmId && farmId.toLowerCase(),
        LIVE_DATA_SIGNALR_URL,
        (message) => {
          printConsoleHeading("SignalR message received: ", message);
          const isHudMessage =
            typeof message.farmLocationWeatherData !== "undefined";
          if (isHudMessage) {
            printConsoleHeading("SignalR HUD message received: ", message);
            setHudDetails(_.cloneDeep(message));
          } else {
            markCowAsMilked(message);
            setTimeout(() => {
              removeLiveDataPopup();
            }, LIVE_DATA_POPUP_LIFETIME_IN_SECONDS * 1000);
          }
        },
      );

      return () => {
        dispatch(resetAstronautState());
        /** Stop web socket connection on page leaving  */
        if (liveDataInstance) {
          stopSignalRConnection(liveDataInstance);
        }
      };
    }, []);
    //#endregion

    const isOpenedOnMobileDevice = window.matchMedia(
      "(any-pointer:coarse)",
    ).matches;

    const VIRTUAL_TOUR_CONFIG = [
      {
        slideIndex: 0,
        popupHeaderText: translateWithValues(
          "VIRTUAL_FARM_HOW_TO_SLIDE_0_HEADER_TEXT",
          { farmName },
        ),
        popupBodyText: translateWithValues("VIRTUAL_FARM_HOW_TO_SLIDE_0_TEXT", {
          farmerName,
          farmName,
          farmLocation,
        }),
        iconButtonCssClassname: "btn-side-right-arrow",
        popupImageUrl: farmDetails?.farmer?.imageData?.all,
        popupImageCaption: translateDynamic(farmDetails.farmer.name),
      },
      {
        slideIndex: 1,
        popupHeaderText: isOpenedOnMobileDevice
          ? translateWithValues("VIRTUAL_TOUR_SLIDE_1_MOBILE")
          : translateWithValues("VIRTUAL_TOUR_SLIDE_1_DESKTOP"),
        gestureIconClassname: GESTURE_TYPE_ZOOM,
        iconButtonCssClassname: "btn-side-right-arrow",
        popupImageCaption: translateDynamic(farmDetails.farmer.name),
      },
      {
        slideIndex: 2,
        popupHeaderText: isOpenedOnMobileDevice
          ? translateWithValues("VIRTUAL_TOUR_SLIDE_2_MOBILE")
          : translateWithValues("VIRTUAL_TOUR_SLIDE_2_DESKTOP"),
        gestureIconClassname: GESTURE_TYPE_DRAG,
        iconButtonCssClassname: "btn-side-right-arrow",
        popupImageCaption: translateDynamic(farmDetails.farmer.name),
      },
      {
        slideIndex: 3,
        popupHeaderText: farmDetails.isAdultSelected
          ? translateWithValues("VIRTUAL_TOUR_SLIDE_3_HEADER_ADULT", {
              farmerName,
            })
          : translateWithValues("VIRTUAL_TOUR_SLIDE_3_HEADER"),
        popupBodyText: translate("VIRTUAL_TOUR_SLIDE_3_DESCRIPTION"),
        iconButtonCssClassname: "btn-top-close",
      },
    ];

    function startVirtualFarmTour() { 
      console.log('isVirtualTourStarted')
      setCurrentVirtualTourIndex(0);
      setIsVirtualTourActive(true);
      dispatch(
        setGoogleAnalyticTracking(
          VIRTUAL_FARM_TOUR_STARTED,
          VIRTUAL_FARM_TOUR_STARTED,
          farmId + "/" + VIRTUAL_FARM_TOUR_STARTED,
        ),
      );
    }

    function endVirtualFarmTour() {
      setIsVirtualTourActive(false);
      setStartPoint("");
      dispatch(markFirstVirtualFarmTourAsComplete());
      dispatch(
        setGoogleAnalyticTracking(
          VIRTUAL_FARM_TOUR_WATCHED,
          VIRTUAL_FARM_TOUR_WATCHED,
          farmId + "/" + VIRTUAL_FARM_TOUR_WATCHED,
        ),
      );
    }

    function goToNextVirtualFarmTourSlide() {
      setCurrentVirtualTourIndex((currentValue) => {
        let areThereAnyOtherSlidesFromVirtualFarmTour =
          currentValue + 1 < VIRTUAL_TOUR_CONFIG.length;
        if (areThereAnyOtherSlidesFromVirtualFarmTour) {
          return currentValue + 1;
        }
        endVirtualFarmTour();
        return 0;
      });
    }
    //#endregion

    //#region pet/other animal positioning logic
    function populateFarmAnimalsWithPositions(farmDetails) {
      if (
        !(farmDetails && farmDetails.animals && farmDetails.animals.forEach)
      ) {
        return;
      }
      const petAnimalTypes = [
        ANIMAL_TYPE_SMALL_DOG,
        ANIMAL_TYPE_CAT,
        ANIMAL_TYPE_MEDIUM_DOG,
        ANIMAL_TYPE_LARGE_DOG,
      ];
      let petAnimals = farmDetails.animals.filter(
        (currentAnimal) =>
          petAnimalTypes.indexOf(currentAnimal.animalType) !== -1,
      );
      let otherAnimals = farmDetails.animals.filter(
        (currentAnimal) =>
          petAnimalTypes.indexOf(currentAnimal.animalType) === -1,
      );
      populateAnimalsArrayWithPositions(petAnimals, farmerPetCoordinates);
      if (!farmDetails.isAdultSelected) {
        // when farm theme is adult theme don't show animals at the bottom right corner of the virtual farm
        populateAnimalsArrayWithPositions(otherAnimals, otherAnimalCoordinates);
      }
    }

    function populateAnimalsArrayWithPositions(animalsArray, positionsArray) {
      animalsArray.forEach((currentAnimal, currentAnimalIndex) => {
        currentAnimal.x = positionsArray[currentAnimalIndex].x;
        currentAnimal.y = positionsArray[currentAnimalIndex].y;
      });
    }
    //#endregion

    const removeUnits = (value) => {
      return value.slice(0, value.length - 2);
    };

    if (!_.isEmpty(farmDetails) && isAgeSelected) {
      let cowCoordinatesToApply = _.cloneDeep(cowCoordinates);
      let doesFarmHasGrazeway =
        farmDetails &&
        farmDetails.otherDevices &&
        farmDetails.otherDevices.some(
          (currentDevice) => currentDevice.type === "grazeway",
        );
      populateFarmAnimalsWithPositions(farmDetails);

      if (!doesFarmHasGrazeway) {
        // place 2 of the cows in the location of the grazeway
        // if there is none for currently selected farm
        cowCoordinatesToApply[0] = {
          x: `${64 / 2.16533}`,
          y: `${72 / 2.16533}`,
        };
        cowCoordinatesToApply[1] = {
          x: `${66 / 2.16533}`,
          y: `${85 / 2.16533}`,
        };
      }
      const getHouseStyle = (style) => {
        if (style == "Style1") {
          return "virtualFarm--house--style1";
        } else if (style == "Style2") {
          return "virtualFarm--house--style2";
        } else if (style == "Style3") {
          return "virtualFarm--house--style3";
        } else {
          return "virtualFarm--house--style1";
        }
      };
      return (
        <div
          className={
            isVirtualTourActive
              ? "is-virtual-farm-tour-active virtual-farm-tour-slide-" +
                currentVirtualTourIndex
              : ""
          }
        >
          <PinchView
            onZoomFn={useCallback((zoomState) => {
              let isZoomResetToInitial = zoomState.state.scale === 1;
              if (zoomState.state.positionX > 0) {
                zoomState.setTransform(
                  0,
                  zoomState.state.positionY,
                  zoomState.state.scale,
                );
              }
              if (zoomState.state.positionY > 0) {
                zoomState.setTransform(
                  zoomState.state.positionX,
                  0,
                  zoomState.state.scale,
                );
              }
              if (isZoomResetToInitial) {
                zoomState.resetTransform();
              }
              setIsZoomedIn(!isZoomResetToInitial);
            }, [])}
          >
            <div className="virtualFarm--container">
              <div className="virtualFarm">
                <div className="virtualFarm--actors-container">
                  {farmDetails.isAdultSelected ? (
                    <AdultHills
                      className="virtualFarm--hill"
                      style={{
                        left: measureResponsive(0),
                        top: measureResponsive(4.239221),
                      }}
                    />
                  ) : (
                    <div
                      className="virtualFarm--hill"
                      style={{
                        left: measureResponsive(0),
                        top: measureResponsive(4.239221),
                      }}
                    ></div>
                  )}
                  <div
                    className="virtualFarm--bus"
                    style={{
                      left: measureResponsive(77.04),
                      width: measureResponsive(8.8211),
                      top: measureResponsive(17.739221),
                      height: measureResponsive(7.634217),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--left--barn"
                    style={{
                      left: measureResponsive(33.239),
                      width: measureResponsive(44.2265),
                      height: measureResponsive(33.19309),
                      top: measureResponsive(11.305623413206),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--right--production"
                    style={{
                      left: measureResponsive(61.53826),
                      width: measureResponsive(20.89588),
                      height: measureResponsive(19.5123),
                      top: measureResponsive(14.67108098248466),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--right--street"
                    style={{
                      left: measureResponsive(70.7747),
                      width: measureResponsive(22.62195),
                      height: measureResponsive(23.071),
                      top: measureResponsive(7.27),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--animal--fence"
                    style={{
                      left: measureResponsive(67.7747),
                      width: measureResponsive(35.62195),
                      height: measureResponsive(23.371),
                      top: measureResponsive(28.54158),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--grass1"
                    style={{
                      left: measureResponsive(5.7747),
                      width: measureResponsive(1.23154158),
                      height: measureResponsive(1),
                      top: measureResponsive(38.23154158),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--grass2"
                    style={{
                      left: measureResponsive(22.7747),
                      width: measureResponsive(1.23154158),
                      height: measureResponsive(1),
                      top: measureResponsive(34.23154158),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--grass3"
                    style={{
                      left: measureResponsive(81.7747),
                      width: measureResponsive(1.23154158),
                      height: measureResponsive(1),
                      top: measureResponsive(4.9),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--grass4"
                    style={{
                      left: measureResponsive(25.7747),
                      width: measureResponsive(1.23154158),
                      height: measureResponsive(1),
                      top: measureResponsive(38.23154158),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--grass5"
                    style={{
                      left: measureResponsive(87.7747),
                      width: measureResponsive(1.23154158),
                      height: measureResponsive(1),
                      top: measureResponsive(23.23154158),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--grass6"
                    style={{
                      left: measureResponsive(15.7747),
                      width: measureResponsive(1.23154158),
                      height: measureResponsive(1),
                      top: measureResponsive(24.23154158),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--grass7"
                    style={{
                      left: measureResponsive(5.7747),
                      width: measureResponsive(1.23154158),
                      height: measureResponsive(1),
                      top: measureResponsive(13.23154158),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--grass8"
                    style={{
                      left: measureResponsive(5.7747),
                      width: measureResponsive(1.23154158),
                      height: measureResponsive(1),
                      top: measureResponsive(8.23154158),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--grass9"
                    style={{
                      left: measureResponsive(64.7747),
                      width: measureResponsive(1.23154158),
                      height: measureResponsive(1),
                      top: measureResponsive(43.23154158),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--grass10"
                    style={{
                      left: measureResponsive(95.7747),
                      width: measureResponsive(1.23154158),
                      height: measureResponsive(1),
                      top: measureResponsive(25.23154158),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--grass11"
                    style={{
                      left: measureResponsive(5.77477),
                      width: measureResponsive(1.23154158),
                      height: measureResponsive(1),
                      top: measureResponsive(38.23154158),
                    }}
                  ></div>
                  <div
                    className="virtualFarm--grass12"
                    style={{
                      left: measureResponsive(94.9747),
                      width: measureResponsive(1.23154158),
                      height: measureResponsive(1),
                      top: measureResponsive(25.23154158),
                    }}
                  ></div>
                  <div
                    // className="virtualFarm--house--style3"
                    className={getHouseStyle(farmDetails && farmDetails.style)}
                    style={{
                      left: measureResponsive(54.84183),
                      width: measureResponsive(22.85071),
                      height: measureResponsive(17.03693783969744),
                      top: measureResponsive(0.128067),
                    }}
                  ></div>
                  <Farmer
                    farmerData={farmDetails.farmer}
                    farmerYCoordinate={farmerCoordinates.y}
                    farmerXCoordinate={farmerCoordinates.x}
                    farmerFarmName={translateDynamic(farmDetails.farmName)}
                    farmerScaleX={1}
                    farmerStyle={farmDetails.farmer.style}
                    farmerCssClassname={FARMER_CSS_CLASSNAME}
                    farmTheme={farmDetails.farmTheme}
                    farmerClickHandlerFn={useCallback((clickedFarmerData) => {
                      dispatch(selectActor(clickedFarmerData));
                      setLatestSelectedItem({
                        x: removeUnits(farmerCoordinates.x),
                        y: removeUnits(farmerCoordinates.y),
                        name: clickedFarmerData.name,
                      });
                      dispatch(showPopup(clickedFarmerData));
                    }, [])}
                    isClickable={true}
                    isInteractable={true}
                    isAdultSelected={farmDetails.isAdultSelected}
                  />

                  {farmDetails &&
                    farmDetails.hasAnimals !== false &&
                    farmDetails.animals &&
                    farmDetails.animals.map(
                      (currentAnimal, currentAnimalIndex) => {
                        const currentCoordinates =
                          cowCoordinatesToApply[currentAnimalIndex];
                        if (currentAnimal.animalType === ANIMAL_TYPE_CAT) {
                          return (
                            <Cat
                              petData={currentAnimal}
                              petCssClassname={PET_CSS_CLASSNAME}
                              petXCoordinate={measureResponsive(10.84183)}
                              petYCoordinate={measureResponsive(20.84183)}
                              petScaleX={0.7}
                              isClickable={true}
                              isAnimationDisabled={farmDetails.isAdultSelected}
                              petClickHandlerFn={useCallback(
                                (clickedPetData) => {
                                  clickedPetData.type = ACTOR_TYPE_PET;
                                  dispatch(selectActor(clickedPetData));
                                  setLatestSelectedItem({
                                    x: removeUnits(currentAnimal.x),
                                    y: removeUnits(currentAnimal.y),
                                    name: clickedPetData.name,
                                  });
                                  dispatch(showPopup(clickedPetData));
                                },
                                [],
                              )}
                            />
                          );
                        } else if (
                          currentAnimal.animalType === ANIMAL_TYPE_HORSE &&
                          !farmDetails.isAdultSelected
                        ) {
                          return (
                            <Pet
                              petData={currentAnimal}
                              petCssClassname={PET_CSS_CLASSNAME}
                              petXCoordinate={30.84183}
                              petYCoordinate={88.84183}
                              petScaleX={0.7}
                              isClickable={true}
                              petClickHandlerFn={useCallback(
                                (clickedPetData) => {
                                  clickedPetData.type = ACTOR_TYPE_PET;
                                  dispatch(selectActor(clickedPetData));
                                  setLatestSelectedItem({
                                    x: removeUnits(currentAnimal.x),
                                    y: removeUnits(currentAnimal.y),
                                    name: clickedPetData.name,
                                  });
                                  dispatch(showPopup(clickedPetData));
                                },
                                [],
                              )}
                            />
                          );
                        }
                        if (!farmDetails.isAdultSelected) {
                          return (
                            <Pet
                              petData={currentAnimal}
                              petCssClassname={PET_CSS_CLASSNAME}
                              petXCoordinate={currentAnimal.x}
                              petYCoordinate={currentAnimal.y}
                              petScaleX={1}
                              isClickable={true}
                              petClickHandlerFn={useCallback((clickedPetData) => {
                                clickedPetData.type = ACTOR_TYPE_PET;
                                dispatch(selectActor(clickedPetData));
                                setLatestSelectedItem({
                                  x: removeUnits(currentAnimal.x),
                                  y: removeUnits(currentAnimal.y),
                                  name: clickedPetData.name,
                                });
                                dispatch(showPopup(clickedPetData));
                              }, [])}
                            />
                          );
                        }
                      
                      },
                    )}
                  {
                    <MijnMelk
                      mijnMelkData={farmDetails}
                      mijnMelkCssClassname={MIJN_MELK_CSS_CLASSNAME}
                      mijnMelkClickHandlerFn={useCallback(
                        (clickedMijnMelkData) => {
                          dispatch(selectActor(clickedMijnMelkData));
                          dispatch(showPopup({ type: ACTOR_TYPE_MIJN_MELK }));
                        },
                        [],
                      )}
                    />
                  }
                  {farmDetails.brand ? (
                    <Brand
                      brandData={farmDetails.brand}
                      brandCssClassname={BRAND_CSS_CLASSNAME}
                      brandClickHandlerFn={useCallback((clickedBrandData) => {
                        dispatch(selectActor(clickedBrandData));
                        dispatch(showPopup(clickedBrandData));
                      }, [])}
                      isClickable={true}
                    />
                  ) : null}

                  {farmDetails &&
                    farmDetails.producingDevice &&
                    Object.entries(farmDetails.producingDevice)[0].map(
                      (currentProducingDevice) => {
                        switch (currentProducingDevice) {
                          case "orbiter":
                            return (
                              <MilkFactory
                                milkFactoryCssClassname={"milkFactory"}
                                milkFactoryClickHandlerFn={useCallback(
                                  (clickedMilkFactoryData) => {
                                    dispatch(
                                      selectActor(clickedMilkFactoryData),
                                    );
                                    dispatch(showPopup(clickedMilkFactoryData));
                                  },
                                  [],
                                )}
                                milkFactoryData={farmDetails.producingDevice}
                                farmTheme={farmDetails.farmTheme}
                                isClickable={true}
                                isInteractable={true}
                                isAdultSelected={farmDetails.isAdultSelected}
                              />
                            );
                          case "cheese":
                            return (
                              <CheeseFactory
                                cheeseFactoryCssClassname={"cheeseFactory"}
                                cheeseFactoryClickHandlerFn={useCallback(
                                  (clickedCheeseFactoryData) => {
                                    dispatch(
                                      selectActor(clickedCheeseFactoryData),
                                    );
                                    dispatch(
                                      showPopup(clickedCheeseFactoryData),
                                    );
                                  },
                                  [],
                                )}
                                cheeseFactoryData={farmDetails.producingDevice}
                                farmTheme={farmDetails.farmTheme}
                                isInteractable={true}
                                isClickable={true}
                              />
                            );
                          case "icecream":
                            return (
                              <IceCreamFactory
                                iceCreamFactoryCssClassname={"iceCreamFactory"}
                                iceCreamFactoryClickHandlerFn={useCallback(
                                  (clickedIceCreamFactoryData) => {
                                    dispatch(
                                      selectActor(clickedIceCreamFactoryData),
                                    );
                                    dispatch(
                                      showPopup(clickedIceCreamFactoryData),
                                    );
                                  },
                                  [],
                                )}
                                iceCreamFactoryData={
                                  farmDetails.producingDevice
                                }
                                farmTheme={farmDetails.farmTheme}
                                isInteractable={true}
                                isClickable={true}
                              />
                            );
                          case "dairy":
                            return (
                              <DairyFactory
                                dairyFactoryCssClassname={"dairyFactory"}
                                dairyFactoryClickHandlerFn={useCallback(
                                  (clickedDairyFactoryData) => {
                                    dispatch(
                                      selectActor(clickedDairyFactoryData),
                                    );
                                    dispatch(
                                      showPopup(clickedDairyFactoryData),
                                    );
                                  },
                                  [],
                                )}
                                dairyFactoryData={farmDetails.producingDevice}
                                farmTheme={farmDetails.farmTheme}
                                isInteractable={true}
                                isClickable={true}
                              />
                            );
                          default:
                            break;
                        }
                        return;
                      },
                    )}
                  {farmDetails.otherDevices &&
                    farmDetails.otherDevices.length &&
                    farmDetails.otherDevices
                      .sort((a, b) => a.type.localeCompare(b.type))
                      .map(
                        (
                          currentProducingDevice,
                          currentProducingDeviceIndex,
                        ) => {
                          switch (currentProducingDevice.type) {
                            case "astronaut":
                              return (
                                <AstronautFactory
                                  astronautFactoryCssClassname={
                                    "astronautFactory"
                                  }
                                  astronautFactoryClickHandlerFn={useCallback(
                                    (clickedAstronautFactoryData) => {
                                      dispatch(
                                        selectActor(
                                          clickedAstronautFactoryData,
                                        ),
                                      );
                                      dispatch(
                                        showPopup(clickedAstronautFactoryData),
                                      );
                                    },
                                    [],
                                  )}
                                  astronautFactoryData={currentProducingDevice}
                                  farmTheme={farmDetails.farmTheme}
                                  isClickable={true}
                                  isInteractable={
                                    currentProducingDeviceIndex === 0
                                      ? true
                                      : false
                                  }
                                  isAdultSelected={farmDetails.isAdultSelected}
                                />
                              );
                            case "discovery":
                              return (
                                <>
                                  <DiscoveryFeature
                                    discoveryFeatureCssClassname={
                                      "discoveryFeature"
                                    }
                                    discoveryFeatureClickHandlerFn={useCallback(
                                      (clickedDiscoveryFeatureData) => {
                                        dispatch(
                                          selectActor(
                                            clickedDiscoveryFeatureData,
                                          ),
                                        );
                                        dispatch(
                                          showPopup(
                                            clickedDiscoveryFeatureData,
                                          ),
                                        );
                                      },
                                      [],
                                    )}
                                    discoveryFeatureData={
                                      currentProducingDevice
                                    }
                                    farmTheme={farmDetails.farmTheme}
                                    isInteractable={
                                      currentProducingDeviceIndex === 0
                                        ? true
                                        : false
                                    }
                                    isClickable={true}
                                    isAnimationEnabled={!isVirtualTourActive}
                                  />
                                </>
                              );
                            case "collector":
                              return (
                                <>
                                  <CollectorFeature
                                    discoveryFeatureCssClassname={
                                      "discoveryFeaturesSW"
                                    }
                                    discoveryFeatureClickHandlerFn={useCallback(
                                      (clickedDiscoveryFeatureData) => {
                                        dispatch(
                                          selectActor(
                                            clickedDiscoveryFeatureData,
                                          ),
                                        );
                                        dispatch(
                                          showPopup(
                                            clickedDiscoveryFeatureData,
                                          ),
                                        );
                                      },
                                      [],
                                    )}
                                    discoveryFeatureData={
                                      currentProducingDevice
                                    }
                                    farmTheme={farmDetails.farmTheme}
                                    isAdultSelected={
                                      farmDetails.isAdultSelected
                                    }
                                    isInteractable={
                                      currentProducingDeviceIndex === 0
                                        ? true
                                        : false
                                    }
                                    isClickable={true}
                                    isAnimationEnabled={!isVirtualTourActive}
                                  />
                                </>
                              );
                            case "grazeway":
                              return (
                                <GrazewayFeature
                                  grazewayFeatureCssClassname={
                                    "grazewayFeature"
                                  }
                                  grazewayFeatureClickHandlerFn={useCallback(
                                    (clickedGrazewayFeatureData) => {
                                      dispatch(
                                        selectActor(clickedGrazewayFeatureData),
                                      );
                                      dispatch(
                                        showPopup(clickedGrazewayFeatureData),
                                      );
                                    },
                                    [],
                                  )}
                                  grazewayFeatureData={currentProducingDevice}
                                  farmTheme={farmDetails.farmTheme}
                                  isClickable={true}
                                  isInteractable={
                                    currentProducingDeviceIndex === 0
                                      ? true
                                      : false
                                  }
                                  isAdultSelected={farmDetails.isAdultSelected}
                                />
                              );
                            case "luna":
                              return (
                                <LunaFeature
                                  lunaFeatureCssClassname={"lunaFeature"}
                                  lunaFeatureClickHandlerFn={useCallback(
                                    (clickedLunaFeatureData) => {
                                      dispatch(
                                        selectActor(clickedLunaFeatureData),
                                      );
                                      dispatch(
                                        showPopup(clickedLunaFeatureData),
                                      );
                                    },
                                    [],
                                  )}
                                  lunaFeatureData={currentProducingDevice}
                                  farmTheme={farmDetails.farmTheme}
                                  isClickable={true}
                                  isInteractable={
                                    currentProducingDeviceIndex === 0
                                      ? true
                                      : false
                                  }
                                />
                              );
                            case "vector":
                              return (
                                <VectorFeature
                                  vectorFeatureCssClassname={"vectorFeature"}
                                  vectorFeatureClickHandlerFn={useCallback(
                                    (clickedVectorFeatureData) => {
                                      dispatch(
                                        selectActor(clickedVectorFeatureData),
                                      );
                                      dispatch(
                                        showPopup(clickedVectorFeatureData),
                                      );
                                    },
                                    [],
                                  )}
                                  isAnimationEnabled={!isVirtualTourActive}
                                  vectorFeatureData={currentProducingDevice}
                                  farmTheme={farmDetails.farmTheme}
                                  isClickable={true}
                                  isInteractable={
                                    currentProducingDeviceIndex === 0
                                      ? true
                                      : false
                                  }
                                  isAdultSelected={farmDetails.isAdultSelected}
                                />
                              );
                              case "juno":
                                return (
                                  <JunoFeature
                                    junoFeatureCssClassname={"junoFeature"}
                                    junoFeatureClickHandlerFn={useCallback(
                                      (clickedJunoFeatureData) => {
                                        dispatch(
                                          selectActor(clickedJunoFeatureData),
                                        );
                                        dispatch(
                                          showPopup(clickedJunoFeatureData),
                                        );
                                      },
                                      [],
                                    )}
                                    isAnimationEnabled={!isVirtualTourActive}
                                    junoFeatureData={currentProducingDevice}
                                    farmTheme={farmDetails.farmTheme}
                                    isClickable={true}
                                    isInteractable={
                                      currentProducingDeviceIndex === 0
                                        ? true
                                        : false
                                    }
                                    isAdultSelected={farmDetails.isAdultSelected}
                                  />
                                );
                            default:
                              break;
                          }
                          return;
                        },
                      )}
                  <div
                    className="fence"
                    style={{
                      left: measureResponsive(42.1377),
                      top: measureResponsive(20.12687),
                      width: measureResponsive(30.5961),
                      height: measureResponsive(19.559143),
                    }}
                  />

                  {farmDetails.vehicle && farmDetails.vehicle.flag ? (
                    <Vehicle
                      vehicleData={farmDetails.vehicle}
                      vehicleCssClassname={
                        VEHICLE_CSS_CLASSNAME +
                        `${
                          farmDetails.shop && farmDetails.shop.flag
                            ? " " + VEHICLE_LEFT_CSS_CLASSNAME
                            : ""
                        }`
                      }
                      isClickable={true}
                      isAdultSelected={farmDetails.isAdultSelected}
                      farmTheme={farmDetails.farmTheme}
                      vehicleClickHandlerFn={useCallback(
                        (clickedVehicleData) => {
                          dispatch(showPopup(clickedVehicleData));
                        },
                        [],
                      )}
                      hasShop={farmDetails.shop && farmDetails.shop.flag}
                    />
                  ) : null}
                  {farmDetails.shop && farmDetails.shop.flag ? (
                    <Shop
                      shopData={farmDetails.shop}
                      shopCssClassname={SHOP_CSS_CLASSNAME}
                      isClickable={true}
                      shopClickHandlerFn={useCallback(() => {
                        dispatch(showPopup(farmDetails.shop));
                      }, [])}
                    />
                  ) : null}
                  {farmDetails.solarPanel && farmDetails.solarPanel.flag ? (
                    <SolarPanel
                      farmName={translateDynamic(farmDetails.farmName)}
                      solarPanelData={farmDetails.solarPanel}
                      solarPanelCssClassname={SOLARPANEL_CSS_CLASSNAME}
                      isClickable={true}
                      solarpanelClickHandlerFn={useCallback(() => {
                        dispatch(selectActor(farmDetails.solarPanel));
                        dispatch(showPopup({ type: ACTOR_TYPE_SOLARPANEL }));
                      }, [])}
                    />
                  ) : null}
                  {farmDetails.milkBatch
                    ? farmDetails.milkBatch.cows
                        .slice(0, cowCoordinatesToApply.length)
                        .map((currentCow, index) => {
                          const currentCoordinates =
                            cowCoordinatesToApply[index];
                          if (currentCoordinates.isSpaceAware) {
                            return (
                              <>
                                <SpaceAwareCow
                                  cowData={_.cloneDeep(currentCow)}
                                  cowCssClassname={COW_CSS_CLASSNAME}
                                  cowXCoordinate={currentCoordinates.x}
                                  cowYCoordinate={currentCoordinates.y}
                                  cowScaleX={currentCow.scale}
                                  farmTheme={farmDetails.farmTheme}
                                  isClickable={true}
                                  cowClickHandlerFn={useCallback(
                                    (clickedCowData) => {
                                      dispatch(selectCow(clickedCowData));
                                      dispatch(selectActor(clickedCowData));
                                      setLatestSelectedItem({
                                        x: removeUnits(currentCoordinates.x),
                                        y: removeUnits(currentCoordinates.y),
                                        name: clickedCowData.name,
                                      });
                                      dispatch(showPopup(clickedCowData));
                                    },
                                    [],
                                  )}
                                  isInteractable={index === 5 ? true : false}
                                  isAnimated={true}
                                  cowIndex={index}
                                />
                                {currentCoordinates.hasExcrements ? (
                                  <Excrement
                                    cowXCoordinate={currentCoordinates.x}
                                    cowYCoordinate={currentCoordinates.y}
                                    cowData={currentCow}
                                  />
                                ) : null}
                              </>
                            );
                          }
                          return (
                            <Cow
                              cowData={currentCow}
                              cowCssClassname={COW_CSS_CLASSNAME}
                              cowXCoordinate={currentCoordinates.x}
                              cowYCoordinate={currentCoordinates.y}
                              cowScaleX={currentCow.scale}
                              farmTheme={farmDetails.farmTheme}
                              isClickable={true}
                              cowClickHandlerFn={useCallback(
                                (clickedCowData) => {
                                  dispatch(selectCow(clickedCowData));
                                  dispatch(selectActor(clickedCowData));
                                  setLatestSelectedItem({
                                    x: removeUnits(currentCoordinates.x),
                                    y: removeUnits(currentCoordinates.y),
                                    name: clickedCowData.name,
                                  });
                                  dispatch(showPopup(clickedCowData));
                                },
                                [],
                              )}
                              isInteractable={index === 5 ? true : false}
                              isAdultSelected={farmDetails.isAdultSelected}
                              isAnimated={true}
                              cowIndex={index}
                            />
                          );
                        })
                    : farmDetails.cowsWithFullInfo
                        .slice(0, cowCoordinatesToApply.length)
                        .map((currentCow, index) => {
                          const currentCoordinates =
                            cowCoordinatesToApply[index];
                          if (currentCoordinates.isSpaceAware) {
                            return (
                              <>
                                <SpaceAwareCow
                                  cowData={_.cloneDeep(currentCow)}
                                  cowCssClassname={COW_CSS_CLASSNAME}
                                  cowXCoordinate={currentCoordinates.x}
                                  cowYCoordinate={currentCoordinates.y}
                                  cowScaleX={currentCow.scale}
                                  farmTheme={farmDetails.farmTheme}
                                  isClickable={true}
                                  cowClickHandlerFn={useCallback(
                                    (clickedCowData) => {
                                      dispatch(selectCow(clickedCowData));
                                      dispatch(selectActor(clickedCowData));
                                      setLatestSelectedItem({
                                        x: removeUnits(currentCoordinates.x),
                                        y: removeUnits(currentCoordinates.y),
                                        name: clickedCowData.name,
                                      });
                                      dispatch(showPopup(clickedCowData));
                                    },
                                    [],
                                  )}
                                  isInteractable={index === 5 ? true : false}
                                  isAdultSelected={farmDetails.isAdultSelected}
                                  isAnimated={true}
                                  cowIndex={index}
                                />
                                {currentCoordinates.hasExcrements ? (
                                  <Excrement
                                    cowXCoordinate={currentCoordinates.x}
                                    cowYCoordinate={currentCoordinates.y}
                                    cowData={currentCow}
                                  />
                                ) : null}
                              </>
                            );
                          }
                          return (
                            <Cow
                              cowData={currentCow}
                              cowCssClassname={COW_CSS_CLASSNAME}
                              cowXCoordinate={currentCoordinates.x}
                              cowYCoordinate={currentCoordinates.y}
                              cowScaleX={currentCow.scale}
                              farmTheme={farmDetails.farmTheme}
                              isClickable={true}
                              cowClickHandlerFn={useCallback(
                                (clickedCowData) => {
                                  dispatch(selectCow(clickedCowData));
                                  dispatch(selectActor(clickedCowData));
                                  setLatestSelectedItem({
                                    x: removeUnits(currentCoordinates.x),
                                    y: removeUnits(currentCoordinates.y),
                                    name: clickedCowData.name,
                                  });
                                  dispatch(showPopup(clickedCowData));
                                },
                                [],
                              )}
                              isInteractable={index === 5 ? true : false}
                              isAdultSelected={farmDetails.isAdultSelected}
                              isAnimated={true}
                            />
                          );
                        })}
                  {!_.isEmpty(currentlyMilkedCow) &&
                  isVirtualFarmTourCompleteFromLocalStorage ? (
                    <LiveDataPopup
                      cowDetails={currentlyMilkedCow}
                      farmTheme={farmDetails.farmTheme}
                      closePopupClickFn={() => {}}
                    />
                  ) : null}

                  <IconButton
                    cssClassName="sustainabilityPlate"
                    style={{
                      left: measureResponsive(33.239),
                      width: measureResponsive(44.2265),
                      height: measureResponsive(33.19309),
                      top: measureResponsive(11.305623413206),
                    }}
                    onClickFn={() => {
                      dispatch(
                        showPopup({ type: ACTOR_TYPE_SUSTAINABILITY_PLATE }),
                      );
                    }}
                  />
                  <div className="virtual-farm-tour--overlay" />
                </div>
                <RibbonTitle
                  farmData={farmDetails}
                  isClickable={true}
                  closePopupClickFn={() => {
                    dispatch(showPopup({}));
                  }}
                  onRibbonTitleClickFn={() => {
                    const farmActorData = getFarmPopupInfoByFarmDetails(
                      farmDetails,
                      ACTOR_TYPE_FARM,
                    );
                    dispatch(showPopup(farmActorData));
                  }}
                />
                <IconButton
                  cssClassName="batchCowListButton"
                  onClickFn={() => {
                    dispatch(showPopup({ type: ACTOR_TYPE_BATCH_COW_LIST }));
                  }}
                  hasShop={farmDetails.shop && farmDetails.shop.flag}
                />
                {farmDetails.isAdultSelected ? (
                  <HudDisplay
                    hudDetails={hudDetails}
                    milkingsLast24Hours={
                      farmDetails?.milkBatch?.milkingsLast24Hours
                    }
                    allCowsList={farmDetails.allCows}
                    farmLocation={farmDetails.location.name}
                  />
                ) : (
                  ""
                )}
                <VirtualFarmFooter />
                <BirdContainer doDisableAnimation={isVirtualTourActive} />
                <div className="virtual-farm-tour--overlay" />
              </div>
            </div>
          </PinchView>
          {isVirtualTourActive ? (
            <div
              className="virtual-farm-tour-container"
              onClick={() => {
                goToNextVirtualFarmTourSlide();
              }}
              onWheel={e => e.stopPropagation()}
            >
              {VIRTUAL_TOUR_CONFIG.filter(
                (
                  currentVirtualFarmSlideConfig,
                  currentVirtualFarmSlideConfigIndex,
                ) => {
                  return (
                    currentVirtualFarmSlideConfigIndex ===
                    currentVirtualTourIndex
                  );
                },
              ).map((selectedVirtualFarmSlideConfig) => {
                return (
                  <div
                    className={`virtual-farm-tour--slide${currentVirtualTourIndex}`}
                  >
                    <ArrowPopup
                      popupHeaderText={
                        selectedVirtualFarmSlideConfig.popupHeaderText
                      }
                      popupBodyText={
                        selectedVirtualFarmSlideConfig.popupBodyText
                      }
                      iconButtonCssClassname={
                        selectedVirtualFarmSlideConfig.iconButtonCssClassname
                      }
                      popupImageUrl={
                        selectedVirtualFarmSlideConfig.popupImageUrl
                      }
                      gestureIconClassname={
                        selectedVirtualFarmSlideConfig.gestureIconClassname
                      }
                      popupImageCaption={
                        selectedVirtualFarmSlideConfig.popupImageCaption
                      }
                      isAdultSelected={farmDetails.isAdultSelected}
                    />
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  },
);

export default VirtualFarmPage;
