import React from "react";
import { translate } from "../../../localization/TranslationUtil";
import "./MultiLanguageTextInput.scss";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import LabelledTextInputForLanguage from "../LabelledTextInputForLanguage/LabelledTextInputForLanguage";

/** MultiLanguageTextInput component.
 * @param {Object} obj - Object which contains all props.
 * @param {Object} [obj.multiLanguageData] - Object containing translations in all available languages for the value that this text area should display
 * @param {String} [obj.fieldLabelTranslationKey] - The key from the translations file to be used when showing the label of this field
 * @param {String} [obj.fieldPlaceholderTextTranslationKey] - The key from the translations file to be used when showing the placeholder text of this text area
 * @param {String} [obj.additionalCssClassname] - Additional CSS classname that should be attached to the text area for styling purposes, etc.
 * @param {Array} [obj.languageKeysArray] - Those keys will influence the number of text areas displayed per field (as for each available language we display a separate text area)
 * @param {Function} [obj.handleDataChangedFn] - Function that will be called whenever the user changes the contents of ANY of the text areas for a specific language
 */
function MultiLanguageTextInput({
  multiLanguageData,
  fieldLabelTranslationKey,
  fieldPlaceholderTextTranslationKey,
  additionalCssClassname,
  languageKeysArray,
  handleDataChangedFn,
}) {
  console.log("@MultiLanguageTextInput multiLanguageData:", multiLanguageData);
  function getTextInputJSXElementsByMultiLanguageData(multiLanguageData) {
    let textInputJSXElements = [];
    if (!_.isEmpty(multiLanguageData)) {
      languageKeysArray.forEach((currentLanguageKey) => {
        textInputJSXElements.push(
          getTextInputComponentJSXByData(
            multiLanguageData[currentLanguageKey] || "",
            currentLanguageKey,
          ),
        );
        textInputJSXElements.push(
          getTextInputComponentJSXByData(
            multiLanguageData[currentLanguageKey + "s"] || "",
            currentLanguageKey + "s",
          ),
        );
      });
    }

    return textInputJSXElements;
  }

  function getTextInputComponentJSXByData(
    dataTextForSpecificLanguage,
    currentLanguageKey,
  ) {
    return (
      <LabelledTextInputForLanguage
        labelText={translate("LANGUAGE_" + currentLanguageKey.toUpperCase())}
        initialValue={dataTextForSpecificLanguage}
        handleChangeFn={(newTextForSpecificLanguage) => {
          let copyOfMultiLanguageData = _.cloneDeep(multiLanguageData);
          copyOfMultiLanguageData[currentLanguageKey] =
            newTextForSpecificLanguage;
          handleDataChangedFn(copyOfMultiLanguageData);
        }}
        placeholderText={translate(fieldPlaceholderTextTranslationKey)}
        additionalClassName={additionalCssClassname}
      />
    );
  }

  return (
    <div className="multiLanguageTextInput">
      <div className="multiLanguageTextInput--label">
        {translate(fieldLabelTranslationKey)}
      </div>
      <Grid container className="multiLanguageTextInput--textInputsContainer">
        {getTextInputJSXElementsByMultiLanguageData(multiLanguageData)}
      </Grid>
    </div>
  );
}

export default MultiLanguageTextInput;
