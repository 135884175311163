export const OPTIONS_PER_COW_BREED = {
  holstein: [
    {
      classNameAppendix: 'cowHolsteinFriesian--one',
      value: 'holstein-white',
      label: 'holstein-white',
    },
    {
      classNameAppendix: 'cowHolsteinFriesian--two',
      value: 'holstein-black',
      label: 'holstein-black',
    },
    {
      classNameAppendix: 'cowHolsteinFriesian--three',
      value: 'holstein-brown',
      label: 'holstein-brown',
    },
    {
      classNameAppendix: 'cowPride--one',
      value: 'holstein-rainbow',
      label: 'holstein-rainbow',
    },
  ],
  lakenvelder: [
    {
      classNameAppendix: 'cowLakenvelder--one',
      value: 'lakenvelder-black',
      label: 'lakenvelder-black',
    },
    {
      classNameAppendix: 'cowLakenvelder--two',
      value: 'lakenvelder-brown',
      label: 'lakenvelder-brown',
    },
  ],
  blaarkop: [
    {
      classNameAppendix: 'cowBlaarKop--one',
      value: 'blaarkop-black',
      label: 'blaarkop-black',
    },
    {
      classNameAppendix: 'cowBlaarKop--two',
      value: 'blaarkop-brown',
      label: 'blaarkop-brown',
    },
  ],
  jersey: [
    {
      classNameAppendix: 'cowJersey--one',
      value: 'jersey-brown',
      label: 'jersey-brown',
    },
    {
      classNameAppendix: 'cowJersey--two',
      value: 'jersey-white',
      label: 'jersey-white',
    },
  ],
  maasRijn: [
    {
      classNameAppendix: 'cowMaasRijn--one',
      value: 'maasrijn-brown',
      label: 'maasrijn-brown',
    },
  ],
  mixed: [
    {
      classNameAppendix: 'cowHolsteinFriesian--one',
      value: 'holstein-white',
      label: 'holstein-white',
    },
    {
      classNameAppendix: 'cowHolsteinFriesian--two',
      value: 'holstein-black',
      label: 'holstein-black',
    },
    {
      classNameAppendix: 'cowHolsteinFriesian--three',
      value: 'holstein-brown',
      label: 'holstein-brown',
    },
    {
      classNameAppendix: 'cowPride--one',
      value: 'holstein-rainbow',
      label: 'holstein-rainbow',
    },
    {
      classNameAppendix: 'cowLakenvelder--one',
      value: 'lakenvelder-black',
      label: 'lakenvelder-black',
    },
    {
      classNameAppendix: 'cowLakenvelder--two',
      value: 'lakenvelder-brown',
      label: 'lakenvelder-brown',
    },
    {
      classNameAppendix: 'cowBlaarKop--one',
      value: 'blaarkop-black',
      label: 'blaarkop-black',
    },
    {
      classNameAppendix: 'cowBlaarKop--two',
      value: 'blaarkop-brown',
      label: 'blaarkop-brown',
    },
    {
      classNameAppendix: 'cowJersey--one',
      value: 'jersey-brown',
      label: 'jersey-brown',
    },
    {
      classNameAppendix: 'cowJersey--two',
      value: 'jersey-white',
      label: 'jersey-white',
    },
    {
      classNameAppendix: 'cowMaasRijn--one',
      value: 'maasrijn-brown',
      label: 'maasrijn-brown',
    },
  ],
};

export const getSubBreedCssClassNameBySubBreed = function (subBreedName) {
  let subBreedCssClassname = '';
  Object.keys(OPTIONS_PER_COW_BREED).forEach((currentBreedName) => {
    OPTIONS_PER_COW_BREED[currentBreedName].forEach((currentSubBreed) => {
      if (currentSubBreed.value === subBreedName.toLowerCase()) {
        subBreedCssClassname = currentSubBreed.classNameAppendix;
      }
    });
  });
  return subBreedCssClassname;
};
