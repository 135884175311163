import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
import { getLunaFactorySvgFilePath } from "../../common/svgUtil";
import Graphic from "../Graphic/Graphic";
import React, { useCallback } from "react";
import "./LunaFeature.scss";
import { measureResponsive } from "../VirtualFarm/util";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";

/** lunaFeature component */
function LunaFeature({
  lunaFeatureData,
  lunaFeatureCssClassname,
  lunaFeatureClickHandlerFn,
  additionalCssClassnames,
  farmTheme,
  isClickable,
  isInteractable,
}) {
  let lunaSubmoduleCssClassname =
    lunaFeatureCssClassname + " " + additionalCssClassnames;
  lunaSubmoduleCssClassname += isInteractable
    ? " virtualFarm--interactable virtualFarm--highlighted"
    : "";
  lunaSubmoduleCssClassname = isClickable
    ? lunaSubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : lunaSubmoduleCssClassname;
  let lunaSvgPath = getLunaFactorySvgFilePath(farmTheme);
  const onCompletedCallback = useCallback((e) => {}, []);
  const onErrorCallback = useCallback((e) => {}, []);

  return (
    <div
      style={{
        left: measureResponsive(65.3658),
        width: measureResponsive(3.93646),
        top: measureResponsive(35.894),
        height: measureResponsive(6.23646),
      }}
      className={lunaSubmoduleCssClassname}
      onClick={() => {
        lunaFeatureClickHandlerFn(lunaFeatureData);
      }}
    >
      {isInteractable ? (
        <DynamicInlineSVG
          svgFullFilePath={lunaSvgPath}
          themeName={farmTheme}
          onCompleted={onCompletedCallback}
          onError={onErrorCallback}
        />
      ) : null}
      {isInteractable ? <Graphic cssClassName="is-actor-pointed" /> : null}
    </div>
  );
}
export default LunaFeature;
