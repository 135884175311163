import React, { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
// Styles
import "./LabelledImageDropZone.scss";
import { fileToBase64 } from "./util";
import ImagePreview from "./ImagePreview";
import { translate } from "../../../localization/TranslationUtil";

/** LabelledTextArea component.
 * @param {Object} obj - Object which contains all props.
 * @param {string} [obj.tooltip] - Text for tooltip
 * @param {string} [obj.placeholder] - Search placeholder
 * @param {string} [obj.dataTestID] - Data test ID
 * @param {string} [obj.labelText] - Label for the text input
 * @param {string} [obj.dropzoneText] - Label for the text input
 * @param {string} [obj.initialValue] - Base64 code of the image to be shown by default
 * @param {Function} [obj.onChangeFileFn] - Function that will be called whenever a new image is selected
 * @param {string} [obj.defaultPreviewCssClassname] - Default class to be added to preview image, so that custom styling can be done for different image upload components
 */
function LabelledImageDropZone({
  tooltip,
  dataTestID,
  labelText,
  dropzoneText,
  initialValue,
  onChangeFileFn,
  onDeleteFileFn,
  defaultPreviewCssClassname,
}) {
  const [files, setFiles] = useState(initialValue || "");

  useEffect(() => {
    setFiles(initialValue || "");
  }, [initialValue]);

  /**
   * @param {Object} files - Contains current value selected for image upload
   */
  function handleFilesChange(files) {
    setFiles(files);
  }

  return (
    <div className={"labelled-image-drop-zone"}>
      <label className="labelled-image-drop-zone--label">{labelText}</label>
      <div className="labelled-image-drop-zone--container">
        <div className="labelled-image-drop-zone--userFacingContents">
          <h3>{dropzoneText}</h3>
          <h4>{translate("FILE_FORMATS_IMAGE_UPLOAD_TEXT")}</h4>
        </div>
        <DropzoneArea
          acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
          dropzoneText={dropzoneText}
          getPreviewIcon={(fileObject) => {
            return "";
          }}
          onDrop={(files) => {
            setFiles(files);
            files && files[0] && fileToBase64(files[0], onChangeFileFn);
          }}
          onChange={(files) => handleFilesChange(files)}
          initialFiles={[files]}
          fileObjects={files}
          onDelete={onDeleteFileFn}
          filesLimit={1}
          className="labelled-image-drop-zone--dropZone"
        />
        <ImagePreview
          imageFileUrl={initialValue}
          onDeleteFileFn={onDeleteFileFn}
          additionalCssClassname={defaultPreviewCssClassname}
        />
      </div>
    </div>
  );
}

export default LabelledImageDropZone;
