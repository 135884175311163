import _ from "lodash";
import React, { useEffect } from "react";
import "./CowBirthFirstPage.scss";
import { useSelector } from "react-redux";

function CowBirthFirstPage() {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );

  if (!_.isEmpty(selectedCowDetails)) {
    return <div className="cowBirthFirstPage">
    <div className="cow--cowBirthFirstPage--treeAdultVersion"></div>
    </div>; 
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowBirthFirstPage;
