import React from "react";
import "./ThirdSlideLely.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const ThirdSlideLely = ({}) => {
  return (
    <div className="lelyStoryThirdSlide">
      <TextCaption additionalCssClassname="lelyStoryThirdSlide--textCaption">
        <div className="lelyStoryThirdSlide--title">
          {translate("LELY_THIRD_SLIDE_TITLE")}
        </div>
        <div className="lelyStoryThirdSlide--text">
          {translate("LELY_THIRD_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="lelyStoryThirdSlide--imgCaption">
        <div className="lelyStoryThirdSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default ThirdSlideLely;
