import _ from 'lodash';
import React from 'react';
import GenericSlide from '../GenericSlide/GenericSlide';

export default function Story({ id, config }) {
  if (config && config.textBlocks) {
    return (
      config &&
      config.textBlocks &&
      config.textBlocks.map((currentTextBlockData) => {
        let currentBlockCssClassName =
          (currentTextBlockData && currentTextBlockData.customCssClassName) ||
          '';
        if (currentTextBlockData.component) {
          return React.createElement(currentTextBlockData.component, {
            className: currentBlockCssClassName,
            additionalCssClassname: currentBlockCssClassName,
            ...currentTextBlockData.props,
          });
        } else {
          return (
            <GenericSlide
              headingText={currentTextBlockData.heading}
              bodyText={currentTextBlockData.body}
              backgroundImageUrl={currentTextBlockData.backgroundImageUrl}
              cssClassName={currentBlockCssClassName}
            />
          );
        }
      })
    );
  } else {
    return [];
  }
}
