import React from "react";
import {
  translate,
  translateWithValuesAndHtml,
} from "../../../localization/TranslationUtil";
// Styles
import "./TermsAndConditionsDialog.scss";

/** ConfirmationDialog component.
 * @param {Object} obj - Object which contains all props.
 * @param {Function} [obj.onCancelCallbackFn] - A callback function to be called upon cancelling or closing the terms and conditions dialog
 * @param {Function} [obj.onCloseCallbackFn] - Explicit callback function for the scenario in which the user clicks the X button at the top right corner of the dialog. If not specified explicitly, onCancelCallbackFn will be executed instead.
 * @param {string} [obj.cssClassname] - Specific CSS class to use on TermsAndConditionsDialog parent element

 */
function TermsAndConditionsDialog({
  onCancelCallbackFn,
  onCloseCallbackFn,
  cssClassname,

}) {
  const confirmationDialogContainerCssClassname =
    cssClassname || "confirmationDialogContainer";

  return (
    <div className={confirmationDialogContainerCssClassname}>
      <div className="termsAndConditionsDialog">
        <div className="termsAndConditions--header">
          <div className="termsAndConditions--text">{translate('TERMS_AND_CONDITIONS_TITLE')}</div>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="close"
            onClick={onCloseCallbackFn || onCancelCallbackFn}
          >
            <i class="lely-icon-close"></i>
          </button>
        </div>
        <div className="termsAndConditions--body">
          <p>{translate('TERMS_AND_CONDITIONS_FIRST_PART')}</p>
          <p
            className="termsAndConditions--body--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml('TERMS_AND_CONDITIONS_SECOND_PART'),
            }}
          ></p>
          <p
            className="termsAndConditions--body--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml('TERMS_AND_CONDITIONS_THIRD_PART'),
            }}
          ></p>
          <p
            className="termsAndConditions--body--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml('TERMS_AND_CONDITIONS_FOURTH_PART'),
            }}
          ></p>
          <li className="termsAndConditions--subpoint--text">
            {translate(
              "TERMS_AND_CONDITIONS_FIRST_SUBPOINT",
            )}
          </li>
          <li className="termsAndConditions--subpoint--text">
            {translate(
              "TERMS_AND_CONDITIONS_SECOND_SUBPOINT",
            )}
          </li>
          <li className="termsAndConditions--subpoint--text">
            {translate(
              "TERMS_AND_CONDITIONS_THIRD_SUBPOINT",
            )}
          </li>
          <li className="termsAndConditions--subpoint--text">
            {translate(
              "TERMS_AND_CONDITIONS_FOURTH_SUBPOINT",
            )}
          </li>
          <li className="termsAndConditions--subpoint--text">
            {translate(
              "TERMS_AND_CONDITIONS_FIFTH_SUBPOINT",
            )}
          </li>
          <li className="termsAndConditions--subpoint--text">
            {translate(
              "TERMS_AND_CONDITIONS_SIXTH_SUBPOINT",
            )}
          </li>
          <li className="termsAndConditions--subpoint--text">
            {translate(
              "TERMS_AND_CONDITIONS_SEVENTH_SUBPOINT",
            )}
          </li>
          <li className="termsAndConditions--subpoint--text">
            {translate(
              "TERMS_AND_CONDITIONS_EIGHTH_SUBPOINT",
            )}
          </li>
          <li className="termsAndConditions--subpoint--text">
            {translate(
              "TERMS_AND_CONDITIONS_NINTH_SUBPOINT",
            )}
          </li>
          <li className="termsAndConditions--subpoint--text">
            {translate(
              "TERMS_AND_CONDITIONS_TENTH_SUBPOINT",
            )}
          </li>
          <li className="termsAndConditions--subpoint--text">
            {translate(
              "TERMS_AND_CONDITIONS_ELEVENTH_SUBPOINT",
            )}
          </li>
          <li className="termsAndConditions--subpoint--text">
            {translate(
              "TERMS_AND_CONDITIONS_TWELFTH_SUBPOINT",
            )}
          </li>
          <p
            className="termsAndConditions--body--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml('TERMS_AND_CONDITIONS_FIFTH_PART'),
            }}
          ></p>
          <p
            className="termsAndConditions--body--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml('TERMS_AND_CONDITIONS_SIXTH_PART'),
            }}
          ></p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditionsDialog;
