import { COW_ENTERED, COW_EXIT, RESET_ASTRONAUT_STATE } from "./types";

/** Create action which notifies the store that a cow has entered in the Astronaut robot for milking
 * @param {Object} cowDetails - The details for the cow that is currently being milked
 * @return {Object} Return action for cow entered
 */
export function cowEntered(cowDetails) {
  return {
    type: COW_ENTERED,
    payload: {
      cowDetails: cowDetails,
    },
  };
}

/** Create action which notifies the store that a cow has exit the Astronaut robot after milking
 * @return {Object} Return action for cow exit
 */
export function cowExit() {
  return {
    type: COW_EXIT,
    payload: {},
  };
}

/** Create action which resets the Astronaut milking robot state to none actor is being milked at the moment
 * @return {Object} Return action for resetting astronaut robot milking state
 */
export function resetAstronautState() {
  return {
    type: RESET_ASTRONAUT_STATE,
    payload: {},
  };
}
