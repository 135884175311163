import React, { useEffect, useRef, useState } from "react";
import "./OptionButtonsSelect.scss";

/** OptionButtonsSelect component */
function OptionButtonsSelect({
    options,
    values,
    doDisplayPercentages,
    selectedOptionText,
    onOptionSelectedCallbackFn
}) {
    const [selectedOption, setSelectedOption] = useState(selectedOptionText || "");
    const [arePercentagesDisplayed, setArePercentagesDisplayed] = useState(doDisplayPercentages || false);
    useEffect(() => {
        setSelectedOption(selectedOptionText);
    }, [selectedOptionText]);
    useEffect(() => {
        setArePercentagesDisplayed(doDisplayPercentages);
    }, [doDisplayPercentages]);

    let optionButtonsSelectCssClassname = arePercentagesDisplayed ? "optionButtonsSelect optionButtonsSelect--arePercentagesDisplayed" : "optionButtonsSelect";
    return (
        <div className={optionButtonsSelectCssClassname}>
            {options && options.map(currentOption => {
                return <button
                    style={{
                        flexBasis: arePercentagesDisplayed ? values[currentOption] + '%' : ''
                    }}
                    disabled={doDisplayPercentages}
                    className={selectedOption === currentOption ? "optionButtonsSelect--option is-selected" : "optionButtonsSelect--option"}
                    onClick={() => {
                        setSelectedOption(currentOption);
                        setArePercentagesDisplayed(true);
                        if (typeof onOptionSelectedCallbackFn === 'function') {
                            onOptionSelectedCallbackFn(currentOption);
                        }
                    }}
                ><span className="optionButtonsSelect--option--percentagePart">{`${arePercentagesDisplayed ? values[currentOption] + '%' : ''}`}</span><span className="optionButtonsSelect--option--optionNamePart">{`${currentOption}`}</span></button>
            })}
        </div>
    );
}
export default OptionButtonsSelect;
