import {
  START_WAITING_FOR_ACCESS_TOKEN_EXPIRATION,
  STOP_WAITING_FOR_ACCESS_TOKEN_EXPIRATION,
} from './types.js';
import { put, take, call, fork, cancel, delay } from 'redux-saga/effects';
import { accessTokenRefreshSuccess } from './actions.js';
import { isUserActiveInLastNMinutes } from '../../common/activity.js';
import {
  ACCESS_TOKEN_EXPIRATION_PERIOD_IN_MINUTES,
  MINUTES_BEFORE_ACCESS_TOKEN_EXPIRATION_TO_REFRESH,
  USER_MAX_IDLE_TIME_PERIOD_IN_MINUTES,
  WAIT_ACCESS_TOKEN_RENEW_IN_MS_IMMEDIATELY_AFTER_REFRESH,
} from '../../api/lelyBackend/config.js';

export default function* accessTokenSaga(api) {
  var periodInMinutesAfterWhichToTryToRefreshAccessToken =
    ACCESS_TOKEN_EXPIRATION_PERIOD_IN_MINUTES -
    MINUTES_BEFORE_ACCESS_TOKEN_EXPIRATION_TO_REFRESH;
  var periodInMillisecondsAfterWhichToTryToRefreshAccessToken =
    periodInMinutesAfterWhichToTryToRefreshAccessToken * 60 * 1000;

  // check here whether there is access token already
  // this pretty much means that:
  // - we are already logged in
  // - so we have executed a page refresh or direct page navigation to this URL
  // if so, directly try to refresh the access token
  if (
    window.localStorage &&
    window.localStorage['persist:root'] &&
    window.localStorage['persist:root'].userSession
  ) {
    const userSessionInfo = JSON.parse(
      JSON.parse(window.localStorage['persist:root']).userSession,
    );
    const isUserLoggedIn = userSessionInfo.isLoggedIn;
    if (isUserLoggedIn) {
      const { accessToken, refreshToken } = userSessionInfo;
      yield delay(WAIT_ACCESS_TOKEN_RENEW_IN_MS_IMMEDIATELY_AFTER_REFRESH);
      yield fork(
        requestRefreshAccessToken,
        api,
        periodInMillisecondsAfterWhichToTryToRefreshAccessToken,
        accessToken,
        refreshToken,
      );
    }
  }

  // that saga won't ever stop listening for the
  // START_WAITING_FOR_ACCESS_TOKEN_EXPIRATION action
  // (that's why we put while (true) here...)
  while (true) {
    const waitForAccessTokenToExpireRequest = yield take(
      START_WAITING_FOR_ACCESS_TOKEN_EXPIRATION,
    );
    const {
      newAccessToken,
      newRefreshToken,
    } = waitForAccessTokenToExpireRequest.payload;
    yield fork(
      waitLoop,
      api,
      periodInMillisecondsAfterWhichToTryToRefreshAccessToken,
      newAccessToken,
      newRefreshToken,
    );
  }
}

function* waitLoop(
  api,
  periodInMillisecondsAfterWhichToTryToRefreshAccessToken,
  newAccessToken,
  newRefreshToken,
) {
  const waitToExpireTask = yield fork(
    waitForExpirationPeriodToAlmostPass,
    api,
    periodInMillisecondsAfterWhichToTryToRefreshAccessToken,
    newAccessToken,
    newRefreshToken,
  );
  // it is important to be able to cancel the waiting period
  // for access token refresh because
  // if the user logs out and logs in immediately afterwards
  // there might be some bugs if several forked sagas
  // try to get a new refresh token all at the same time
  // (if several access token sagas all wait for the period in parallel)
  // the code below will only be executed after user logout:
  const stopWaitAction = yield take(STOP_WAITING_FOR_ACCESS_TOKEN_EXPIRATION);
  yield cancel(waitToExpireTask);
}

function* requestRefreshAccessToken(
  api,
  periodInMillisecondsAfterWhichToTryToRefreshAccessToken,
  lastAccessToken,
  lastRefreshToken,
) {
  try {
    const refreshResponse = yield call(
      api.refreshAccessToken,
      lastAccessToken,
      lastRefreshToken,
    );
    const { accessToken, refreshToken } = refreshResponse.data;
    yield put(accessTokenRefreshSuccess(accessToken, refreshToken));
    yield fork(
      waitLoop,
      api,
      periodInMillisecondsAfterWhichToTryToRefreshAccessToken,
      accessToken,
      refreshToken,
    );
  } catch (error) {
    console.log('Refresh token error: ', error);
  }
}

/** Wait for expiration period of access token to almost pass
 * then check if user has had an activity
 * in the last N minutes and if
 * that is the case get new access and refresh tokens
 * in order to keep the user session alive
 * @param {object} api - API providing access to the Lely backend
 * @param {number} expirationPeriodInMilliseconds
 * @param {string} lastAccessToken
 * @param {string} lastRefreshToken
 */
function* waitForExpirationPeriodToAlmostPass(
  api,
  expirationPeriodInMilliseconds,
  lastAccessToken,
  lastRefreshToken,
) {
  yield delay(expirationPeriodInMilliseconds);
  if (isUserActiveInLastNMinutes(USER_MAX_IDLE_TIME_PERIOD_IN_MINUTES)) {
    // if the user had some activity
    // (currently we are checking for a click)
    // in the last N minutes we will try to refresh their access token
    // so that their user session is not interrupted
    yield fork(
      requestRefreshAccessToken,
      api,
      expirationPeriodInMilliseconds,
      lastAccessToken,
      lastRefreshToken,
    );
  } else {
    console.log(
      'User was NOT active. Abort attempting to refresh the access token.',
    );
  }
  // if the user was inactive in last N minutes
  // their access token will not be refreshed
  // and their user session will not be continued
  // (they will be redirected to the login page upon next request to the server)
}
