import _, { valuesIn } from "lodash";
import React, { useEffect } from "react";
import "./CowDailyRestingPage.scss";
import { useSelector } from "react-redux";
import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import IconButton from "../../components/IconButton/IconButton";
import moment from "moment";

function CowDailyRestingPage({
  showActorStoryFn,
  additionalCssClassname,
  cowDailyDetails,
}) {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );

  function getHumanReadableRestingMinutes(restingMinutes) {
    let valueToBeDisplayed = "";
    let valueAppendix = "";
    if (restingMinutes > 59) {
      valueToBeDisplayed = Math.ceil(restingMinutes / 60) + " ";
      valueAppendix = translate("HOURS_APPENDIX");
    } else {
      valueToBeDisplayed = restingMinutes;
      valueAppendix = translate("MINUTES_APPENDIX");
    }
    return valueToBeDisplayed + valueAppendix;
  }

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div
        className={
          additionalCssClassname
            ? "cow-daily--resting--slide " + additionalCssClassname
            : "cow-daily--resting--slide"
        }
      >
        <TextCaption additionalCssClassname="cowDailyResting">
          <h3 className="cowDailyResting--date">
            {cowDailyDetails &&
              moment(new Date(cowDailyDetails.lastUpdated))
                .utc()
                .format("DD MMMM YYYY, HH:mm")
                .toUpperCase()}
          </h3>
          <div
            className="cowDailyResting--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml("COW_DAILY_RESTING_TEXT", {
                restingDuration: getHumanReadableRestingMinutes(
                  cowDailyDetails && cowDailyDetails.restingMinutes,
                ),
                cowName : selectedCowDetails.name
              }),
            }}
          ></div>
        </TextCaption>
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
}

export default CowDailyRestingPage;
