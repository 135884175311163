import React, { useRef } from "react";
import {
  translate,
  translateNumber,
  translateWithValues,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import "./SecondSlideAnimalWelfare.scss";
import { translateDynamic } from "../../localization/TranslationUtil";
import { getDisableCacheAppendix } from '../../common/urlUtil';



const SecondSlideAnimalWelfare = ({ farmDetails }) => {

  const cowAndCalfStory = translateDynamic(
    farmDetails?.animalWelfare?.cowAndCalfStory,
  );
  const image = farmDetails?.animalWelfare?.cowAndCalfImageData;
  return (
    <div className="animalWelfareStorySecondSlide">
      <TextCaption additionalCssClassname="animalWelfareStorySecondSlide--textCaption">
        <div className="animalWelfareStorySecondSlide--title">
          {translate("ANIMAL_WELFARE_SECOND_SLIDE_COW_AND_CALF")}
        </div>
        <div className="animalWelfareStorySecondSlide--text">
          {cowAndCalfStory}
        </div>
      </TextCaption>
      <div className={`animalWelfareStorySecondSlide--imgCaption`}>
        <img src={getDisableCacheAppendix(image)} className="animalWelfareStorySecondSlide--imgContainer"></img>
        <div className="animalWelfareStorySecondSlide--imgText">
        </div>
      </div>
      <div className="animalWelfareStorySecondSlide-treeAndSmallBush"></div>
    </div>
  );
};

export default SecondSlideAnimalWelfare;
