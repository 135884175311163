import {
  getRandomCatPosition,
  getRandomNumberBetweenMAndN,
  measureResponsive,
} from "../VirtualFarm/util";
import React, { useEffect, useRef, useState } from "react";
import { translateDynamic } from "../../localization/TranslationUtil";
import "./Cat.scss";
import { CatPositionType, CatProps } from "./types/Cat.types";

/** Cat component */
const Cat: React.FunctionComponent<any> = ({
  petData,
  petCssClassname,
  petXCoordinate,
  petYCoordinate,
  petScaleX,
  isClickable,
  petClickHandlerFn,
  isAnimationDisabled,
}: CatProps) => {
  const [currentCatCoordinates, setCurrentCatCoordinates] =
    useState<CatPositionType>({
      x: petXCoordinate,
      y: petYCoordinate,
    });
  const currentCatPositionPresetId = useRef(0);
  const [animationCssClassname, setAnimationCssClassname] = useState("");

  const TOTAL_CAT_LIFETIME_AT_POSITION_IN_SECONDS = 10;
  const TOTAL_CAT_TIME_BEING_HIDDEN_IN_SECONDS = 4;
  const animalType =
    petData && petData.animalType && petData.animalType.toLowerCase();

  const petSubmoduleCssClassname = petCssClassname + "--" + animalType;

  useEffect(() => {
    if (!isAnimationDisabled) {
      showCat();
    }
  }, []);

  function showCat(): void {
    let newCatPosition = getRandomCatPosition(
      currentCatPositionPresetId.current,
    );
    setCurrentCatCoordinates({
      x: measureResponsive(newCatPosition.x),
      y: measureResponsive(newCatPosition.y),
    });
    currentCatPositionPresetId.current = newCatPosition.preset;
    setAnimationCssClassname("is-appearing preset" + newCatPosition.preset);
    setTimeout(hideCat, TOTAL_CAT_LIFETIME_AT_POSITION_IN_SECONDS * 1000);
  }

  function hideCat(): void {
    setAnimationCssClassname("is-disappearing");
    setTimeout(showCat, TOTAL_CAT_TIME_BEING_HIDDEN_IN_SECONDS * 1000);
  }

  let catCssClassname =
    petCssClassname +
    " " +
    petSubmoduleCssClassname +
    " " +
    animationCssClassname;
  catCssClassname = isClickable
    ? catCssClassname + " " + "is-clickable"
    : catCssClassname;
  return (
    <div
      style={{
        top: `${currentCatCoordinates.y}`,
        left: `${currentCatCoordinates.x}`,
      }}
      className={catCssClassname}
      onClick={() => {
        petClickHandlerFn(petData);
      }}
    >
      <h2
        style={{
          transform: "scaleX(" + petScaleX + ")",
        }}
        className={petCssClassname + "--title"}
      >
        {translateDynamic(petData)}
      </h2>
    </div>
  );
};

export default Cat;
