import { GET_NETWORK_ERROR_CODE } from './types';

import { redirect } from '../../../components/CustomRedirect/actions';
import { logoutUser } from '../../../components/LogoutButton/actions';
import { put, take, call } from 'redux-saga/effects';
export default function* errorCodeSaga() {
  while (true) {
    const networkErrorCodeAction = yield take(GET_NETWORK_ERROR_CODE);
    switch (networkErrorCodeAction.payload.errorCode) {
      case '401':
        yield put(logoutUser(networkErrorCodeAction.payload.accessToken));
        break;
      case '403':
        if (window.location.pathname !== '/login')
          yield put(redirect('/forbidden'));
        break;
      default:
        //TODO : Add Geri's popup
        break;
    }
  }
}
