import React from 'react';
import './IconButton.scss';

/** IconButton component */
function IconButton({cssClassName, onClickFn, buttonTooltipText}) {
  let iconButtonCssClassName = "btnIcon " + cssClassName;
  return (
    <button className={iconButtonCssClassName}
            onClick={onClickFn}
            title={buttonTooltipText}
    />
  );
}
export default IconButton;
