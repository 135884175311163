/* Validation functions return true/false depending on the input value */
export const emailValidationFn = (currentValue) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(currentValue);
};

export const sameValueValidationFn = (value1, value2) => {
  console.log("sameValueValidationFn value1:", value1, "value2:", value2);
  return value1.toString() === value2.toString();
};

export const strongPasswordValidationFn = (currentValue) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{}[\]:;"'|\\,.?/~`]).{8,}$/;

  return passwordRegex.test(currentValue);
};

export const emptyValidationFn = (currentValue) => {
  return currentValue != "";
};
