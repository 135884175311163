import React, { useRef, useState } from "react";
import "./Udder.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";
import Graphic from "../../components/Graphic/Graphic";
import ScanBar from "./ScanBar";
import UdderInfo from "./UdderInfo";
import UdderXYZInfo from "./UdderXYZInfo";
import UdderRGBInfo from "./UdderRGBInfo";
import _ from "lodash";
import { useEffect } from "react";

const Udder = ({ }) => {
  const { astronautDetails } = useSelector((state) => state.astronautDetails);
  const { udderDetails } = useSelector((state) => state.udderDetails);

  useEffect(() => {
    animateCurrentUdderScreen();
  }, [udderDetails]);

  const componentData = _.isEmpty(udderDetails) ? astronautDetails : udderDetails;

  const astronautUdderEl = useRef(null);
  const [isXYZDisplayed, setIsXYZDisplayed] = useState(false);

  function animateCurrentUdderScreen(forceNewXYZDisplayed) {
    astronautUdderEl.current.classList.remove("is-animation-enabled");
    setTimeout(() => {
      astronautUdderEl.current.classList.add("is-animation-enabled");
      if (typeof forceNewXYZDisplayed !== 'undefined') {
        setIsXYZDisplayed(forceNewXYZDisplayed);
      }
    }, 1000);
  }

  const handleUdderSectionChange = (newXYZDisplayedValue) => {
    animateCurrentUdderScreen(newXYZDisplayedValue);
  };

  return (
    <div className="astronautUdder" ref={astronautUdderEl}>
      <div className="astronautUdder--textHeaderArea">
        {isXYZDisplayed ? (
          <TextCaption additionalCssClassname="astronautUdder--xyzTitle">
            <div className="astronautCommonData--text">
              {translate("ASTRONAUT_LIVE_DATA_UDDER_XYZ_TEXT")}
            </div>
          </TextCaption>
        ) : (
          <TextCaption additionalCssClassname="astronautUdder--colorsTitle">
            <div className="astronautCommonData--text">
              {translate("ASTRONAUT_LIVE_DATA_UDDER_COLOR_TEXT")}
            </div>
          </TextCaption>
        )}
      </div>
      <div className="astronautUdder--animationArea">
        <ScanBar />
        <Graphic cssClassName="astronautUdder--udder" />
        <UdderInfo udderType={3}>
          {isXYZDisplayed ? (
            <UdderXYZInfo
              x={componentData.milkingTechnicalInfo.teatFrontLeftCoordinateX}
              y={componentData.milkingTechnicalInfo.teatFrontLeftCoordinateY}
              z={componentData.milkingTechnicalInfo.teatFrontLeftCoordinateZ}
            />
          ) : (
            <UdderRGBInfo
              red={componentData.milkingTechnicalInfo.teatMilkFrontLeftRed}
              green={
                componentData.milkingTechnicalInfo.teatMilkFrontLeftGreen
              }
              blue={componentData.milkingTechnicalInfo.teatMilkFrontLeftBlue}
            />
          )}
        </UdderInfo>
        <UdderInfo udderType={4}>
          {isXYZDisplayed ? (
            <UdderXYZInfo
              x={componentData.milkingTechnicalInfo.teatRearLeftCoordinateX}
              y={componentData.milkingTechnicalInfo.teatRearLeftCoordinateY}
              z={componentData.milkingTechnicalInfo.teatRearLeftCoordinateZ}
            />
          ) : (
            <UdderRGBInfo
              red={componentData.milkingTechnicalInfo.teatMilkRearLeftRed}
              green={
                componentData.milkingTechnicalInfo.teatMilkRearLeftGreen
              }
              blue={componentData.milkingTechnicalInfo.teatMilkRearLeftBlue}
            />
          )}
        </UdderInfo>
        <UdderInfo udderType={1}>
          {isXYZDisplayed ? (
            <UdderXYZInfo
              x={
                componentData.milkingTechnicalInfo.teatFrontRightCoordinateX
              }
              y={
                componentData.milkingTechnicalInfo.teatFrontRightCoordinateY
              }
              z={
                componentData.milkingTechnicalInfo.teatFrontRightCoordinateZ
              }
            />
          ) : (
            <UdderRGBInfo
              red={componentData.milkingTechnicalInfo.teatMilkFrontRightRed}
              green={
                componentData.milkingTechnicalInfo.teatMilkFrontRightGreen
              }
              blue={
                componentData.milkingTechnicalInfo.teatMilkFrontRightBlue
              }
            />
          )}
        </UdderInfo>
        <UdderInfo udderType={2}>
          {isXYZDisplayed ? (
            <UdderXYZInfo
              x={componentData.milkingTechnicalInfo.teatRearRightCoordinateX}
              y={componentData.milkingTechnicalInfo.teatRearRightCoordinateY}
              z={componentData.milkingTechnicalInfo.teatRearRightCoordinateZ}
            />
          ) : (
            <UdderRGBInfo
              red={componentData.milkingTechnicalInfo.teatMilkRearRightRed}
              green={
                componentData.milkingTechnicalInfo.teatMilkRearRightGreen
              }
              blue={componentData.milkingTechnicalInfo.teatMilkRearRightBlue}
            />
          )}
        </UdderInfo>
      </div>
      <div className="astronautUdder--bottomNavigationArea">
        <span
          className={
            isXYZDisplayed
              ? "astronautUdder--bottomNavigationArea--positionButton is-active"
              : "astronautUdder--bottomNavigationArea--positionButton is-not-active"
          }
          onClick={() => {
            handleUdderSectionChange(true);
          }}
        >
          {translate("UDDER_BOTTOM_NAVIGATION_POSITION_BUTTON_CAPTION")}
        </span>
        <span
          className={
            !isXYZDisplayed
              ? "astronautUdder--bottomNavigationArea--milkColorButton is-active"
              : "astronautUdder--bottomNavigationArea--milkColorButton is-not-active"
          }
          onClick={() => {
            handleUdderSectionChange(false);
          }}
        >
          {translate("UDDER_BOTTOM_NAVIGATION_MILK_COLOR_BUTTON_CAPTION")}
        </span>
      </div>
    </div>
  );
};

export default Udder;
