import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  translate,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import "./WhatIsThisWebsite.scss";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import { showPopup } from "../../common/components/PopupContainer/actions";

const WhatIsThisWebsitePopup = ({ closePopupClickFn, isAdultSelected }) => {
  const dispatch = useDispatch();
  const popupComponentRef = useRef(null);
  const fadeOutIntervalRef = useRef(null);
  const POPUP_FADE_OUT_ANIMATION_DURATION_IN_MILLISECONDS = 500;
  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const doShowChildVersion = typeof farmDetails.isAdultSelected === "undefined" || farmDetails.isAdultSelected == false;

  useEffect(() => {
    return () => {
      if (fadeOutIntervalRef.current) {
        clearInterval(fadeOutIntervalRef.current);
      }
    };
  });

  const closeWhatIsThisWebsiteClickFn = () => {
    fadeOutPopup();
    fadeOutIntervalRef.current = setTimeout(() => {
      dispatch(showPopup({}));
    }, POPUP_FADE_OUT_ANIMATION_DURATION_IN_MILLISECONDS);
  };

  const fadeOutPopup = () => {
    popupComponentRef.current.classList.add("is-hiding");
  };
  const getSelectedAgeIsChild = farmDetails.isAdultSelected == true || farmDetails.isAdultSelected == undefined ? false : true;
  
  return (


    <div
      className={
        getSelectedAgeIsChild 
          ? "cookieStatementPopup is-children"
          : "cookieStatementPopup is-adult"
      }
      ref={popupComponentRef}
    >
      <div className="cookieStatementPopup--headers">
        <div className="cookieStatementPopup--title">
          {translate("WHAT_IS_THIS_WEBSITE_POPUP_TITLE_TEXT")}
        </div>
      </div>
      {getSelectedAgeIsChild && doShowChildVersion ? (
        <div className="cookieStatementPopup--body">
          <div
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml(
                "WHAT_IS_THIS_WEBSITE_POPUP_DESCRIPTION_TEXT",
              ),
            }}
          />
        </div>
      ) : (
        <div className="cookieStatementPopup--body">
          <p  dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml(
                "WHAT_IS_THIS_WEBSITE_POPUP_DESCRIPTION_TEXT",
              ),
            }}></p>
        </div>
      )}
      {/* <div className= {doShowChildVersion && farmDetails.isAgeSelected != undefined
          ? "cookieStatementPopup--footer is-children"
          : "cookieStatementPopup--footer is-adult"}> */}
          
         <CallToActionButton
          label={translate("DISCLAIMER_POPUP_CLOSE_BUTTON")}
          isAdultSelected={!getSelectedAgeIsChild}
          cssClassName="cookieStatementPopup--footer--btnAcceptCookies"
          onClickFn={() => {
            closeWhatIsThisWebsiteClickFn()
          }}
        
        >
        </CallToActionButton>
    </div>
  
  );
};

export default WhatIsThisWebsitePopup;
