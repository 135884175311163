import React from "react";
import "./StartingPoint.scss";
import TextCaption from "../TextCaption/TextCaption";
import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import { getFarmHouseStyleCssClassname } from "../../common/farmUtil";
import StoryNavigationHint from "../../common/components/StoryNavigationHint/StoryNavigationHint";

const StartingPoint = ({ name, gender, farmName, style }) => {
  const farmNameText = translateWithValues("AND_THE_FARM", { farmName });
  const titleText = translate("FARMER");
  const farmStyle = style && style.toLowerCase();
  const getGenderCss = () => {
    return gender ? gender.toLowerCase() : "male";
  };
  return (
    <div className="farmerStoryStartingPoint">
      <div className="farmerStoryStartingPoint--background"></div>
      <div
        className={`farmerStoryStartingPoint--farmer--${getGenderCss()}`}
      ></div>
      <div className={getFarmHouseStyleCssClassname(farmStyle)}></div>
      <div className="farmerStoryStartingPoint--dutchSilo"></div>
      <TextCaption additionalCssClassname="textCaption--cowIntro">
        <p
          className="cow-intro--text"
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml("FARMER_INTRO_TEXT"),
          }}
        ></p>
        <span className="cow-intro--name">
          {translateWithValues("COW_INTRO_NAME", {
            cowName: name,
          })}
        </span>
      </TextCaption>
      <div className="farmer-intro-leftTree"></div>
      <div className="farmer-intro-smallCow"></div>
      <StoryNavigationHint />
    </div>
  );
};

export default StartingPoint;
