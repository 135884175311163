import moment from "moment";
import React from "react";
import {
  FARMER_STYLE_FEMALE,
  IS_CLICKABLE_CSS_CLASSNAME,
  SIDE_MENU_OPTION_AGE_SELECTION,
} from "../../common/constants";
import { getFarmHouseStyleCssClassnameForMijnMelkAdultOnboarding } from "../../common/farmUtil";
import {
  translate,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import TextCaption from "../TextCaption/TextCaption";
import "./MijnMelkAdultOnboardingSlide4.scss";

function MijnMelkAdultOnboardingSlide4({
  farmerName,
  farmName,
  farmLocation,
  farmerStyle,
  farmStyle,
  totalNumberOfCowsInMilkBatch,
  numberOfMilkingsInPast24Hours,
  onNavigateToInitialSlideFn,
  otherDevices,
  batchProductType,
}) {
  const farmerStyleCssClassname =
    farmerStyle === FARMER_STYLE_FEMALE
      ? "mijnMelkAdultOnboarding-slide4--farmer--female"
      : "mijnMelkAdultOnboarding-slide4--farmer--male";
  const farmHouseStyleCssClassname =
    getFarmHouseStyleCssClassnameForMijnMelkAdultOnboarding(farmStyle);
  
  const numberOfAstronautsInTheFarm = otherDevices.filter(otherDevices => otherDevices.type === "astronaut" || otherDevices.type === "auxiliaryAstronaut").length

  const endingMilkRobotText4 = 
  numberOfAstronautsInTheFarm < 2 
  ? ""
  : "_MULTIPLE_ROBOTS"

  const MIJN_MELK_ADULT_ONBOARDING_SLIDE4_TEXT1 = batchProductType === "Yogurt"
  ? "MIJN_MELK_ADULT_ONBOARDING_SLIDE4_TEXT1_YOGHURT"
  : "MIJN_MELK_ADULT_ONBOARDING_SLIDE4_TEXT1"

  return (
    <div>
      <div className="mijnMelkAdultOnboarding-slide4--fromPinToBottle"></div>
      {/* <div className="mijnMelkAdultOnboarding-slide3--chickens"></div> */}
      <div className="mijnMelkAdultOnboarding-slide4--grass"></div>
      {/* <div className="mijnMelkAdultOnboarding-slide3--cow"></div>  */}
      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide4-text1">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              MIJN_MELK_ADULT_ONBOARDING_SLIDE4_TEXT1,
              {
                farmerName: farmerName,
              },
            ),
          }}
        ></span>
      </TextCaption>

      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide4-text2">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE4_TEXT2",
              {
                farmName: farmName,
                farmLocation: farmLocation,
              },
            ),
          }}
        ></span>
      </TextCaption>

      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide4-text3">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE4_TEXT3",
              {
                numberOfCowsInMilkBatch: totalNumberOfCowsInMilkBatch,
              },
            ),
          }}
        ></span>
      </TextCaption>
    
      <TextCaption additionalCssClassname="textCaption--mijnMelkAdultOnboarding-slide4-text4">
        <span
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE4_TEXT4" + endingMilkRobotText4,
              {
                numberOfMilkingsInPast24Hours: numberOfMilkingsInPast24Hours,
                numberOfAstronautsInTheFarm,
              },
            ),
          }}
        ></span>
      </TextCaption>

      <div
        className={
          "mijnMelkAdultOnboarding-slide4--toTheFarmButton " +
          IS_CLICKABLE_CSS_CLASSNAME
        }
        onClick={() => {
          onNavigateToInitialSlideFn(SIDE_MENU_OPTION_AGE_SELECTION);
        }}
      >
        <span className="mijnMelkAdultOnboarding-slide4--toTheFarmButton--label">
          {translate(
            "MIJN_MELK_ADULT_ONBOARDING_SLIDE4_NAVIGATE_TO_FARM_BUTTON_LABEL",
          )}
        </span>
        <span className="mijnMelkAdultOnboarding-slide4--toTheFarmButton--farmName">
          {farmName}
        </span>
      </div>
      <div
        className={
          "mijnMelkAdultOnboarding-slide4--farmHouse " +
          farmHouseStyleCssClassname
        }
      />
      <div className="mijnMelkAdultOnboarding-slide4--farmerFence" />
      <div
        className={
          "mijnMelkAdultOnboarding-slide4--farmer " + farmerStyleCssClassname
        }
      />
      <div className="mijnMelkAdultOnboarding-slide4--tapHandIcon" />
    </div>
  );
}

export default MijnMelkAdultOnboardingSlide4;
