import {
  REQUEST_ANIMALS_PAGE_INFORMATION,
  REQUEST_ANIMALS_PAGE_INFORMATION_ERROR,
  REQUEST_ANIMALS_PAGE_INFORMATION_SUCCESS,
  SAVE_CHANGES_TO_ANIMALS_PAGE,
  SAVE_CHANGES_TO_ANIMALS_PAGE_ERROR,
  SAVE_CHANGES_TO_ANIMALS_PAGE_SUCCESS
} from './types';

/** Create action which will receive the animal information for the selected farm
 * @param {string} accessToken - User access token
 * @return {Object} Returns action object
 */
export function requestAnimalsPageInformation(accessToken) {
  return {
    type: REQUEST_ANIMALS_PAGE_INFORMATION,
    payload: {
      accessToken: accessToken,
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of the Animals page in the Admin app
 * @param {Object} animalsDetails - an object containing the data to be used in order to initialize the Animals page in the Admin app
 * @return {Object} Return type and animalsDetails
 */
export function getAnimalsPageInformationSuccess(animalsDetails) {
  return {
    type: REQUEST_ANIMALS_PAGE_INFORMATION_SUCCESS,
    payload: animalsDetails,
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getAnimalsPageInformationError(errorMessage) {
  return {
    type: REQUEST_ANIMALS_PAGE_INFORMATION_ERROR,
    payload: errorMessage,
  };
}

/** Create action which to be used whenever the user clicks the Save changes button in the Animals page in the Admin app
 * @param {string} accessToken - User access token
 * @param {Object} newAnimalsDetails - The latest state of the animals details
 * @param {Object} newLanguageDetails - The latest state of the language selection control which needs to be persisted to the languages services as soon as the other request has been carried (both requests can't happen at the same time as there is concurrency issue with the database access)
 * @return {Object} Returns action object
 */
export function saveChangesToAnimalsPage(
  accessToken,
  newAnimalsDetails,
  newLanguageDetails
) {
  return {
    type: SAVE_CHANGES_TO_ANIMALS_PAGE,
    payload: {
      accessToken: accessToken,
      newAnimalsDetails,
      newLanguageDetails
    },
  };
}

/**
 * @return {Object} Returns action object
 */
export function saveChangesToAnimalsPageSuccess() {
  return {
    type: SAVE_CHANGES_TO_ANIMALS_PAGE_SUCCESS,
    payload: {},
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function saveChangesToAnimalsPageError(errorMessage) {
  return {
    type: SAVE_CHANGES_TO_ANIMALS_PAGE_ERROR,
    payload: errorMessage,
  };
}