import React from "react";
import "./StartingSlideOrbiter.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import StoryNavigationHint from "../../common/components/StoryNavigationHint/StoryNavigationHint";

const StartingSlideOrbiter = ({}) => {
  return (
    <div className="orbiterStoryStartingPoint">
      <TextCaption additionalCssClassname="orbiterStoryStartingPoint--textCaption">
        <div className="orbiterStoryStartingPoint--title">
          {translate("ORBITER_STORY_FIRST_SLIDE_TITLE")}
        </div>
        <div className="orbiterStoryStartingPoint--name">
          {translate("ORBITER_STORY_FIRST_SLIDE_HEADER")}
        </div>
        <div className="orbiterStoryStartingPoint--info">
          {translate("ORBITER_STORY_FIRST_SLIDE_FROM_COW")}
        </div>
      </TextCaption>
      <div className="orbiterStoryStartingPoint--robot"></div>
      <StoryNavigationHint />
    </div>
  );
};

export default StartingSlideOrbiter;
