import DynamicInlineSVG from "../../common/customHooks/DynamicInlineSVG";
import { getIceCreamFactorySvgFilePath } from "../../common/svgUtil";
import Graphic from "../Graphic/Graphic";
import React, { useCallback } from "react";
import "./IceCreamFactory.scss";
import { measureResponsive } from "../../components/VirtualFarm/util";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";

/** IceCreamFactory component */
function IceCreamFactory({
  iceCreamFactoryData,
  iceCreamFactoryCssClassname,
  iceCreamFactoryXCoordinate,
  iceCreamFactoryYCoordinate,
  iceCreamFactoryScaleX,
  iceCreamFactoryClickHandlerFn,
  additionalCssClassnames,
  farmTheme,
  doPositionFixed,
  isInteractable,
  isClickable,
}) {
  let transformValue =
    "translateX(" +
    iceCreamFactoryXCoordinate +
    ") translateY(" +
    iceCreamFactoryYCoordinate +
    ") scaleX(" +
    iceCreamFactoryScaleX +
    ")";
  let iceCreamFactoryPositionStyles = doPositionFixed
    ? {}
    : {
        transform: transformValue,
      };
  let iceCreamFactorySubmoduleCssClassname =
    iceCreamFactoryCssClassname + " " + additionalCssClassnames;
  iceCreamFactorySubmoduleCssClassname += isInteractable
    ? " virtualFarm--interactable virtualFarm--highlighted"
    : "";
  iceCreamFactorySubmoduleCssClassname = isClickable
    ? iceCreamFactorySubmoduleCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
    : iceCreamFactorySubmoduleCssClassname;
  let iceCreamFactorySvgPath = getIceCreamFactorySvgFilePath(farmTheme);
  const onCompletedCallback = useCallback((e) => {}, []);
  const onErrorCallback = useCallback((e) => {
    console.log("@onError e:", e);
  }, []);

  return (
    <div
      style={{
        left: measureResponsive(63.36),
        width: measureResponsive(12.0074),
        top: measureResponsive(18.439221),
        height: measureResponsive(10.62193),
      }}
      className={iceCreamFactorySubmoduleCssClassname}
      onClick={() => {
        iceCreamFactoryClickHandlerFn(iceCreamFactoryData);
      }}
    >
      <h2
        style={{
          transform: "scaleX(" + iceCreamFactoryScaleX + ")",
        }}
        className={iceCreamFactoryCssClassname + "--title"}
      >
        {iceCreamFactoryData.name}
      </h2>
      {isInteractable ? (
        <DynamicInlineSVG
          svgFullFilePath={iceCreamFactorySvgPath}
          themeName={farmTheme}
          onCompleted={onCompletedCallback}
          onError={onErrorCallback}
        />
      ) : null}
      {isInteractable ? <Graphic cssClassName="is-actor-pointed" /> : null}
    </div>
  );
}
export default IceCreamFactory;
