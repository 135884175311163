import {
  RECOVER_USER_REQUEST,
  RECOVER_USER_REQUEST_ERROR,
  RECOVER_USER_REQUEST_SUCCESS,
} from "./types";

/** Action which will initiate a user recover saga which will make request to the backend
 * in order to recover an existing user by the e-mail tied to their account in the system
 * @param {string} emailAddress - E-mail tied to the new user
 */
export function recoverUser(emailAddress) {
  return {
    type: RECOVER_USER_REQUEST,
    payload: {
      emailAddress: emailAddress,
    },
  };
}

/** On successfully being able to initiate the recovery process for the user on the backend
 */
export function recoverUserSuccess() {
  return {
    type: RECOVER_USER_REQUEST_SUCCESS,
    payload: {},
  };
}

/** On the case of an error happened while attempting to recover the user
 * (might happen if the e-mail was not registered in the system or
 * another scenario)
 * @param {string} errorMessage - Error message
 */
export function recoverUserError(errorMessage) {
  return {
    type: RECOVER_USER_REQUEST_ERROR,
    payload: errorMessage,
  };
}
