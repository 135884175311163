import moment from "moment";
import React from "react";
import "./MilkBatchWeatherIcon.scss";

function MilkBatchWeatherIcon({ weatherData, currentTimeInFarm }) {
  if (!weatherData || !currentTimeInFarm) {
    return null;
  }
  const isNight =
    moment(currentTimeInFarm).hour() > 20 &&
    moment(currentTimeInFarm).hour() < 6;
  const weatherStringWithoutSpaces =
    weatherData &&
    weatherData.conditions &&
    weatherData.conditions.replace(/\s/g, "");
  let isLowSnow = weatherData.snow > 0 && weatherData.visibility >= 1;
  let isMediumSnow =
    weatherData.snow > 0 &&
    weatherData.visibility >= 0.5 &&
    weatherData.visibility < 1;
  let isSnowStorm = weatherData.snow > 0 && weatherData.visibility < 0.5;
  let isLowRain =
    weatherData.snow === 0 &&
    weatherData.precip > 0 &&
    weatherData.precip < 2.5;
  let isMediumRain =
    weatherData.snow === 0 &&
    weatherData.precip > 2.5 &&
    weatherData.precip < 10;
  let isHeavyRain =
    weatherData.snow === 0 &&
    weatherData.precip >= 10 &&
    weatherData.precip < 50;
  let isThunderStorm = weatherData.snow === 0 && weatherData.precip > 50;
  let isMist = weatherData.snow === 0 && weatherData.visibility <= 1;

  const CONDITIONS_TO_CLASSNAME_MAPPING = {
    Partiallycloudy: "lightCloudy",
    Clear: "sunny",
    Overcast: "cloudy",
  };
  let weatherClassName =
    CONDITIONS_TO_CLASSNAME_MAPPING[weatherStringWithoutSpaces]; // if possible do direct matching between conditions string and CSS classname
  if (!weatherClassName) {
    if (isLowSnow) {
      weatherClassName = "lightSnow";
    } else if (isMediumSnow) {
      weatherClassName = "snow";
      if (isNight) {
        weatherClassName = "snowNight";
      }
    } else if (isSnowStorm) {
      weatherClassName = "heavySnow";
    } else if (isLowRain) {
      weatherClassName = "lightRain";
    } else if (isMediumRain) {
      weatherClassName = "rain";
      if (isNight) {
        weatherClassName = "rainNight";
      }
    } else if (isHeavyRain) {
      weatherClassName = "heavyRain";
    } else if (isThunderStorm) {
      weatherClassName = "thunderstorm";
    } else if (isMist) {
      weatherClassName = "mist";
      if (isNight) {
        weatherClassName = "mistNight";
      }
    } else {
      weatherClassName = "sunny";
    }
  }
  let containerElementCssClassName =
    "milkBatchWeatherIcon milkBatchWeatherIcon--" + weatherClassName;

  return <div className={containerElementCssClassName}></div>;
}

export default MilkBatchWeatherIcon;
