import _ from "lodash";
import React, { useEffect, useState } from "react";
import "./AnimalsPage.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getCurrentLanguage,
  translate,
} from "../../../localization/TranslationUtil";
import { Button } from "@material-ui/core";
import {
  requestAnimalsPageInformation,
  saveChangesToAnimalsPage,
} from "./actions";
import AnimalsList from "../../../components/AnimalsList/AnimalsList";
import ImageRadioSelection from "../../../common/components/ImageRadioSelection/ImageRadioSelection";
import { DEFAULT_LANGUAGE_OPTIONS } from "../../../common/constants";
import LanguageSelection from "../../../common/components/LanguageSelection/LanguageSelection";
import {
  convertLanguageSelectionToBE,
  getLanguagesPlusSimpleOptions,
} from "../../../common/components/LanguageSelection/LanguageUtil";
import {
  getSelectedFarmLanguagesInformationError,
  requestSelectedFarmLanguagesInformation,
  saveChangesToSelectedFarmLanguages,
} from "../../../common/components/LanguageSelection/actions";
import useSaveManager from "../../../hooks/useSaveManager";
import SaveChangesConfirmationDialog from "../../../components/SaveChangesConfirmationDialog/SaveChangesConfirmationDialog";

function AnimalsPage() {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => ({ ...state.userSession }));
  const { animalsDetails } = useSelector((state) => ({
    ...state.animalsDetails,
  }));
  useEffect(() => {
    dispatch(requestAnimalsPageInformation(accessToken));
    dispatch(requestSelectedFarmLanguagesInformation(accessToken));
  }, []);

  const [newAnimalsDetails, setNewAnimalsDetails] = useState(animalsDetails);
  const { isSaveChangesConfirmationPopupDisplayed } = useSelector((state) => ({
    ...state.saveChangesDetails,
  }));

  let languageKeysArray =
    newAnimalsDetails &&
    newAnimalsDetails.languages &&
    newAnimalsDetails.languages.map((currentLanguage) => currentLanguage.code);

  //#region farm languages logic
  const { farmLanguagesDetails } = useSelector((state) => ({
    ...state.selectedFarmLanguages,
  }));
  let languageKeysForAllLanguagesAvailable = DEFAULT_LANGUAGE_OPTIONS.filter(
    (currentLanguageOption) => currentLanguageOption.code.length === 2,
  ).map((currentLanguageOption) => currentLanguageOption.code);

  function initializeLanguageSelection(languagesDataFromBE) {
    let selectedLanguageObjects =
      languagesDataFromBE && languagesDataFromBE.languages
        ? languagesDataFromBE.languages
        : DEFAULT_LANGUAGE_OPTIONS;
    let initialLanguageSelection = selectedLanguageObjects
      .filter(
        (currentLanguageOption) => currentLanguageOption.code.length === 2,
      )
      .map((currentLanguageOption) => currentLanguageOption.code);
    return initialLanguageSelection;
  }

  let initialLanguageSelection =
    initializeLanguageSelection(farmLanguagesDetails);
  const [farmLanguages, setFarmLanguages] = useState(initialLanguageSelection);
  const farmLanguagesWithSimpleOptionsKey =
    getLanguagesPlusSimpleOptions(farmLanguages);

  useEffect(() => {
    let initialLanguageSelection =
      initializeLanguageSelection(farmLanguagesDetails);
    setFarmLanguages(initialLanguageSelection);
  }, [farmLanguagesDetails.languages]);

  const getInitialLanguage = () => {
    if (farmLanguagesDetails.languages) {
      const supportedLanguages = farmLanguagesDetails.languages.map(
        (lang) => lang.code,
      );
      const defLanguage =
        supportedLanguages.indexOf("en") > -1 ? "en" : supportedLanguages[0];
      const currentLanguage = getCurrentLanguage(
        defLanguage,
        supportedLanguages,
      );
      return currentLanguage;
    }
    return "";
  };

  const [currentLanguage, setCurrentLanguage] = useState(getInitialLanguage());
  //#endregion

  const [isSaved, setIsSaved] = useState(true);
  function handleSaveChangesButtonClick() {
    let newLanguageDetails = convertLanguageSelectionToBE(
      farmLanguages,
      DEFAULT_LANGUAGE_OPTIONS,
    );
    dispatch(
      saveChangesToAnimalsPage(
        accessToken,
        newAnimalsDetails,
        newLanguageDetails,
      ),
    );

    setSaved(true);
  }

  const { setSaved, lastSaveTimestamp } = useSaveManager(
    handleSaveChangesButtonClick,
    isSaved,
    setIsSaved,
  );

  useEffect(() => {
    setNewAnimalsDetails(animalsDetails);
  }, [animalsDetails]);

  function handleOtherAnimalsQuestionChange(newSelectedValue) {
    let animalsDetailsCopy = _.cloneDeep(newAnimalsDetails);
    newSelectedValue == 1
      ? (animalsDetailsCopy.hasAnimals = 1)
      : (animalsDetailsCopy.hasAnimals = 0);
    setNewAnimalsDetails(animalsDetailsCopy);
    setSaved(false);
  }

  return (
    <div className="AnimalsPage">
      <h3 className="animals--header">{translate("ANIMALS_PAGE_HEADING")}</h3>
      <p className="animals--intro">{translate("ANIMALS_PAGE_INTRO_TEXT")}</p>

      <LanguageSelection
        handleLanguagesChangedFn={(
          newLanguageCodesSelected,
          isManualChange,
        ) => {
          setFarmLanguages(newLanguageCodesSelected);
          if (isManualChange) {
            setSaved(false);
          }
        }}
        allAvailableLanguageCodesArray={languageKeysForAllLanguagesAvailable}
        selectedLanguageCodesArray={farmLanguages}
      />

      <div className="animals--animalsAreaContainer adminPanel--section">
        <div className="animals--otherAnimals">
          <ImageRadioSelection
            componentLabel={translate("ANIMALS_PAGE_QUESTION_TEXT")}
            optionsArray={[
              {
                label: translate("YES"),
                value: "1",
                classNameAppendix: "inline",
              },
              {
                label: translate("NO"),
                value: "0",
                classNameAppendix: "inline",
              },
            ]}
            initialSelectedValue={
              newAnimalsDetails &&
              newAnimalsDetails.hasAnimals !== false &&
              newAnimalsDetails.hasAnimals !== "0" &&
              newAnimalsDetails.animals &&
              newAnimalsDetails.animals.length > 0
                ? "1"
                : "0"
            }
            onSelectionChangeFn={handleOtherAnimalsQuestionChange}
          />
        </div>
        <div>
          {newAnimalsDetails && newAnimalsDetails.hasAnimals ? (
            <AnimalsList
              animalsArray={newAnimalsDetails.animals}
              handleAnimalsListChangeFn={(newAnimalsArray) => {
                let animalsCopy = _.cloneDeep(newAnimalsDetails);
                animalsCopy.animals = newAnimalsArray;
                setNewAnimalsDetails(animalsCopy);
                setSaved(false);
              }}
              languageKeysArray={farmLanguagesWithSimpleOptionsKey}
              maxNumberOfAnimalBlocks={10}
            />
          ) : null}
        </div>
      </div>

      {isSaved && lastSaveTimestamp !== 0 ? (
        <Button
          className="btn-already-saved"
          disabled={false}
          onClick={() => {}}
        >
          {translate("ADMIN_PAGE_ALREADY_SAVED_CHANGES_BUTTON_LABEL")}
        </Button>
      ) : null}
      {!isSaved ? (
        <Button
          className="btn-save-changes"
          onClick={handleSaveChangesButtonClick}
        >
          {translate("ANIMALS_PAGE_SAVE_CHANGES_BUTTON_LABEL")}
        </Button>
      ) : null}
      {isSaveChangesConfirmationPopupDisplayed ? (
        <SaveChangesConfirmationDialog />
      ) : null}
    </div>
  );
}

export default AnimalsPage;
