export const getCowNameByAllCowsList = (allCowsList, cowExternalId) => {
  console.log(
    "@getCowNameByAllCowsList allCowsList:",
    allCowsList,
    "cowExternalId:",
    cowExternalId,
  );
  let cowsWithThatExternalId = allCowsList.filter((currentCowData) => {
    return currentCowData.externalId === cowExternalId;
  });
  if (cowsWithThatExternalId.length > 0) {
    return cowsWithThatExternalId[0].name;
  }
  return cowExternalId;
};
