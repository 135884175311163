import CowIntroPage from "../containers/CowHistoryStory/CowIntroPage/CowIntroPage";
import CowOutroPage from "../containers/CowHistoryStory/CowOutroPage/CowOutroPage";
import CowParentsPage from "../containers/CowHistoryStory/CowParentsPage/CowParentsPage";
import CowRelativesPage from "../containers/CowHistoryStory/CowRelativesPage/CowRelativesPage";
import CowBirthdayPage from "../containers/CowHistoryStory/CowBirthdayPage/CowBirthdayPage";
import CowBirthFirstPage from "../containers/CowHistoryStory/CowBirthFirstPage/CowBirthFirstPage";
import CowGaveBirthFirstSlide from "../containers/CowHistoryStory/CowGaveBirthFirstSlide/CowGaveBirthFirstSlide";
import CowGaveBirthSecondSlide from "../containers/CowHistoryStory/CowGaveBirthSecondSlide/CowGaveBirthSecondSlide";
import CowGaveBirthThirdSlide from "../containers/CowHistoryStory/CowGaveBirthThirdSlide/CowGaveBirthThirdSlide";
import CowCalmRobotSlide from "../containers/CowHistoryStory/CowCalmRobotSlide/CowCalmRobotSlide";
import CowFirstBirthdaySlide from "../containers/CowHistoryStory/CowFirstBirthdaySlide/CowFirstBirthdaySlide";
import CowGaveBirthFourthSlide from "../containers/CowHistoryStory/CowGaveBirthFourthSlide/CowGaveBirthFourthSlide";
import CowFirstMilkingSlide from "../containers/CowHistoryStory/CowFirstMilkingSlide/CowFirstMilkingSlide";
import CowFirstMilkingSecondSlide from "../containers/CowHistoryStory/CowFirstMilkingSecondSlide/CowFirstMilkingSecondSlide";
import CowFirstYearSlide from "../containers/CowHistoryStory/CowFirstYearSlide/CowFirstYearSlide";
import DynamicSlide from "../components/FarmerStory/DynamicSlide";
import StartingPoint from "../components/FarmerStory/StartingPoint";
import EndingPoint from "../components/FarmerStory/EndingPoint";
import ParallaxCowLatest from "../components/Parallax/ParallaxCowLatest/ParallaxCowLatest";
import CowDailyIntroPage from "../containers/CowDailyIntroPage/CowDailyIntroPage";
import CowDailyAstronautPage from "../containers/CowDailyAstronautPage/CowDailyAstronautPage";
import CowDailyMilkingPage from "../containers/CowDailyMilkingPage/CowDailyMilkingPage";
import CowDailyEatingPage from "../containers/CowDailyEatingPage/CowDailyEatingPage";
import CowDailyRestingPage from "../containers/CowDailyRestingPage/CowDailyRestingPage";
import CowDailyGrazingPage from "../containers/CowDailyGrazingPage/CowDailyGrazingPage";
import CowDailyGrazeWayPage from "../containers/CowDailyGrazeWayPage/CowDailyGrazeWayPage";
import {
  translateDynamic,
  translateSlides,
} from "../localization/TranslationUtil";
import CowDailyRuminationPage from "../containers/CowDailyRuminationPage/CowDailyRuminationPage";
import CowDailyOutroPage from "../containers/CowDailyOutroPage/CowDailyOutroPage";
import { NUMBER_OF_SLIDES_FOR_MILKING } from "../components/Parallax/ParallaxCowDaily/ParallaxCowDaily";
import CowDailyMilkingBottlesPage from "../containers/CowDailyMilkingBottlesPage/CowDailyMilkingBottlesPage";
import WelcomeScreen from "../containers/OnboardingScreens/MijnMelk/WelcomeScreen/WelcomeScreen";
import FarmerPresentation from "../containers/OnboardingScreens/MijnMelk/FarmerPresentation/FarmerPresentation";
import OrganicFarming from "../containers/OnboardingScreens/MijnMelk/OrganicFarming/OrganicFarming";
import MeadowScreen from "../containers/OnboardingScreens/MijnMelk/MeadowScreen/MeadowScreen";
import RobotsOnboarding from "../containers/OnboardingScreens/MijnMelk/RobotsOnboarding/RobotsOnboarding";
import OrbiterScreen from "../containers/OnboardingScreens/MijnMelk/OrbiterScreen/OrbiterScreen";
import FinalOnboardingSlide from "../containers/OnboardingScreens/MijnMelk/FinalOnboardingSlide/FinalOnboardingSlide";
import WelcomeScreenKool from "../containers/OnboardingScreens/CheeseFarmKool/WelcomeScreenKool/WelcomeScreenKool";
import FarmerPresentationKool from "../containers/OnboardingScreens/CheeseFarmKool/FarmerPresentationKool/FarmerPresentationKool";
import RobotsOnboardingKool from "../containers/OnboardingScreens/CheeseFarmKool/RobotsOnboardingKool/RobotsOnboardingKool";
import CheeseFactoryScreen from "../containers/OnboardingScreens/CheeseFarmKool/CheeseFactoryScreen/CheeseFactoryScreen";
import FinalOnboardingSlideKool from "../containers/OnboardingScreens/CheeseFarmKool/FinalOnboardingSlide/FinalOnboardingSlideKool";
import BottleStoryPage from "../containers/BottleStoryPage/BottleStoryPage";
import moment from "moment";
import StartingSlideVector from "../containers/VectorRobotStory/StartingSlideVector";
import SecondSlideVector from "../containers/VectorRobotStory/SecondSlideVector";
import ThirdSlideVector from "../containers/VectorRobotStory/ThirdSlideVector";
import FourthSlideVector from "../containers/VectorRobotStory/FourthSlideVector";
import LastSlideVectorSlide from "../containers/VectorRobotStory/LastSlideVectorSlide";
import StartingSlideAstronaut from "../containers/AstronautRobotStory/StartingSlideAstronaut";
import SecondSlideAstronaut from "../containers/AstronautRobotStory/SecondSlideAstronaut";
import ThirdSlideAstronaut from "../containers/AstronautRobotStory/ThirdSlideAstronaut";
import FourthSlideAstronaut from "../containers/AstronautRobotStory/FourthSlideAstronaut";
import FifthSlideAstronaut from "../containers/AstronautRobotStory/FifthSlideAstronaut";
import LastSlideAstronautSlide from "../containers/AstronautRobotStory/LastSlideAstronautSlide";
import StartingSlideSustainability from "../containers/SustainabilityStory/StartingSlideSustainability";
import SecondSlideSustainability from "../containers/SustainabilityStory/SecondSlideSustainability";
import ThirdSlideSustainability from "../containers/SustainabilityStory/ThirdSlideSustainability";
import FourthSlideSustainability from "../containers/SustainabilityStory/FourthSlideSustainability";
import FifthSlideSustainability from "../containers/SustainabilityStory/FifthSlideSustainability";
import SixthSlideSustainability from "../containers/SustainabilityStory/SixthSlideSustainability";
import LastSlideSustainability from "../containers/SustainabilityStory/LastSlideSustainability";
import StartingSlideOrbiter from "../containers/OrbiterRobotStory/StartingSlideOrbiter";
import SecondSlideOrbiter from "../containers/OrbiterRobotStory/SecondSlideOrbiter";
import ThirdSlideOrbiter from "../containers/OrbiterRobotStory/ThirdSlideOrbiter";
import FourthSlideOrbiter from "../containers/OrbiterRobotStory/FourthSlideOrbiter";
import FifthSlideOrbiter from "../containers/OrbiterRobotStory/FifthSlideOrbiter";
import SixthSlideOrbiter from "../containers/OrbiterRobotStory/SixthSlideOrbiter";
import LastSlideOrbiterSlide from "../containers/OrbiterRobotStory/LastSlideOrbiterSlide";
import StartingSlideLely from "../containers/LelyBrandStory/StartingSlideLely";
import SecondSlideLely from "../containers/LelyBrandStory/SecondSlideLely";
import ThirdSlideLely from "../containers/LelyBrandStory/ThirdSlideLely";
import FourthSlideLely from "../containers/LelyBrandStory/FourthSlideLely";
import FifthSlideLely from "../containers/LelyBrandStory/FifthSlideLely";
import SixthSlideLely from "../containers/LelyBrandStory/SixthSlideLely";
import LastSlideLelySlide from "../containers/LelyBrandStory/LastSlideLelySlide";
import StartingSlideDiscovery from "../containers/LelyDiscoveryStory/StartingSlideDiscovery";
import SecondSlideDiscovery from "../containers/LelyDiscoveryStory/SecondSlideDiscovery";
import LastSlideDiscoverySlide from "../containers/LelyDiscoveryStory/LastSlideDiscoverySlide";
import ThirdSlideDiscovery from "../containers/LelyDiscoveryStory/ThirdSlideDiscovery";
import FirstSlideMijnMelk from "../containers/MijnMelkStory/FirstSlideMijnMelk";
import SecondSlideMijnMelk from "../containers/MijnMelkStory/SecondSlideMijnMelk";
import ThirdSlideMijnMelk from "../containers/MijnMelkStory/ThirdSlideMijnMelk";
import LastSlideMijnMelk from "../containers/MijnMelkStory/LastSlideMijnMelk";
import CowDailyBlankPage from "../containers/CowDailyBlankPage/CowDailyBlankPage";
import CowDailyVectorPage from "../containers/CowDailyVectorPage/CowDailyVectorPage";
import CowCalmRobotSecondSlide from "../containers/CowHistoryStory/CowCalmRobotSecondSlide/CowCalmRobotSecondSlide";
import CowBirthdayCakeSlide from "../containers/CowHistoryStory/CowBirthdayCakeSlide/CowBirthdayCakeSlide";
import AstronautNameSlide from "../containers/LiveDataAstronautStory/AstronautNameSlide";
import AstronautNumberSlide from "../containers/LiveDataAstronautStory/AstronautNumberSlide";
import AstronautAmountOfSeconds from "../containers/LiveDataAstronautStory/AstronautAmountOfSeconds";
import AstronautCommonData from "../containers/LiveDataAstronautStory/AstronautCommonData";
import AstronautTeetAnimationSlide from "../containers/LiveDataAstronautStory/AstronautTeetAnimationSlide";
import AstronautTimeMilked from "../containers/LiveDataAstronautStory/AstronautTimeMilked";
import AstronautTimeCleanedAndMoved from "../containers/LiveDataAstronautStory/AstronautTimeCleanedAndMoved";
import StartingSlideLuna from "../containers/LunaRobotStory/StartingSlideLuna";
import SecondSlideLuna from "../containers/LunaRobotStory/SecondSlideLuna";
import ThirdSlideLuna from "../containers/LunaRobotStory/ThirdSlideLuna";
import StartingSlideOrbiterYoghurt from "../containers/OrbiterRobotStoryYoghurt/StartingSlideOrbiterYoghurt";
import SecondSlideOrbiterYoghurt from "../containers/OrbiterRobotStoryYoghurt/SecondSlideOrbiterYoghurt";
import ThirdSlideOrbiterYoghurt from "../containers/OrbiterRobotStoryYoghurt/ThirdSlideOrbiterYoghurt";
import FourthSlideOrbiterYoghurt from "../containers/OrbiterRobotStoryYoghurt/FourthSlideOrbiterYoghurt";
import FifthSlideOrbiterYoghurt from "../containers/OrbiterRobotStoryYoghurt/FifthSlideOrbiterYoghurt";
import SixthSlideOrbiterYoghurt from "../containers/OrbiterRobotStoryYoghurt/SixthSlideOrbiterYoghurt";
import SeventhSlideOrbiterYoghurt from "../containers/OrbiterRobotStoryYoghurt/SeventhSlideOrbiterYoghurt";
import StartingSlideJuno from "../containers/JunoRobotStory/StartingSlideJuno";
import SecondSlideJuno from "../containers/JunoRobotStory/SecondSlideJuno";
import ThirdSlideJuno from "../containers/JunoRobotStory/ThirdSlideJuno";
import FourthSlideJuno from "../containers/JunoRobotStory/FourthSlideJuno";
import StartingSlideAnimalWelfare from "../containers/AnimalWelfareStory/StartingSlideAnimalWelfare";
import SecondSlideAnimalWelfare from "../containers/AnimalWelfareStory/SecondSlideAnimalWelfare";
import ThirdSlideAnimalWelfare from "../containers/AnimalWelfareStory/ThirdSlideAnimalWelfare";
import FourthSlideAnimalWelfare from "../containers/AnimalWelfareStory/FourthSlideAnimalWelfare";
import FifthSlideAnimalWelfare from "../containers/AnimalWelfareStory/FifthSlideAnimalWelfare";

export const STORY_VARIETY_COW_LATEST = 1;
export const STORY_VARIETY_COW_HISTORY = 0;
export const STORY_VARIETY_ASTRONAUT_LIVE_DATA = 1;
export const STORY_VARIETY_ASTRONAUT_HISTORY = 0;
export const STORY_VARIETY_ORBITER_YOGHURT = 1;
export const STORY_VARIETY_ORBITER_MILK = 0;
export const STORY_VARIETY_SUSTAINABILITY_STORY = 1;

export const PRE_ACTOR_STORY_FORBIDDEN_SWIPE_RANGES = [
  {
    start: 0,
    end: 100,
    slideIndex: 0,
  },
  {
    start: 0,
    end: 100,
    slideIndex: 2,
  },
];

export const SLIDES_WITH_SWIPE_END_ONLY = [
  {
    slideIndex: 0,
  },
];

export const STORY_COW_HISTORY = {
  textBlocks: [
    {
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowIntroPage,
      slideTitle: "CowHistoryIntroPage",
      forbiddenSwipeRanges: [
        {
          start: 0,
          end: 100,
        },
      ],
    },
    {
      heading: "translate:COW_MILKING_PAGE_WHEN_MILKING_TEXT",
      body: "translate:COW_MILKING_PAGE_INTRO_TODAYS_THE_DAY_TEXT",
      backgroundImageUrl: "",
      component: CowParentsPage,
      slideTitle: "CowHistoryParentsPage",
    },
    {
      heading: "Slide COW HISTORY slide 3",
      body: "Text body goes here",
      backgroundImageUrl: "",
      component: CowRelativesPage,
      customCssClassName: "cowParentSlide",
      slideTitle: "CowHistoryRelativesPage",
    },
    {
      heading: "Slide COW HISTORY slide 5",
      body: "Text body goes here",
      backgroundImageUrl: "",
      component: CowBirthdayPage,
      customCssClassName: "cowBirthdaySlide",
      slideTitle: "CowHistoryBirthdayPage",
    },
    {
      heading: "Slide COW HISTORY slide 6",
      body: "Text body goes here",
      backgroundImageUrl: "",
      component: CowCalmRobotSlide,
      customCssClassName: "CowCalmRobotSlide",
      slideTitle: "CowHistoryCalmRobotSlide",
    },
    {
      heading: "Slide COW HISTORY slide 7",
      body: "Text body goes here",
      backgroundImageUrl: "",
      component: CowCalmRobotSecondSlide,
      customCssClassName: "CowCalmRobotSecondSlide",
      slideTitle: "CowHistoryCalmRobotSecondSlide",
    },
    {
      heading: "Slide COW HISTORY slide 8",
      body: "Text body goes here",
      backgroundImageUrl: "",
      component: CowFirstBirthdaySlide,
      customCssClassName: "CowFirstBirthdaySlide",
      slideTitle: "CowHistoryFirstBirthdaySlide",
    },
    {
      heading: "Slide COW HISTORY slide 8.5",
      body: "Text body goes here",
      backgroundImageUrl: "",
      component: CowBirthdayCakeSlide,
      customCssClassName: "CowBirthdayCakeSlide",
      slideTitle: "CowHistoryBirthdayCakeSlide",
    },
    {
      heading: "Slide COW HISTORY slide 10",
      body: "Text body goes here",
      backgroundImageUrl: "",
      component: CowGaveBirthFirstSlide,
      customCssClassName: "CowGaveBirthFirstSlide",
      slideTitle: "CowHistoryGaveBirthFirstSlide",
    },
    {
      heading: "Slide COW HISTORY slide 11",
      body: "Text body goes here",
      backgroundImageUrl: "",
      component: CowGaveBirthSecondSlide,
      customCssClassName: "CowGaveBirthSecondSlide",
      slideTitle: "CowHistoryGaveBirthSecondSlide",
    },
    {
      heading: "Slide COW HISTORY slide 9",
      body: "Text body goes here",
      backgroundImageUrl: "",
      component: CowBirthFirstPage,
      customCssClassName: "CowBirthFirstPage",
      slideTitle: "CowHistoryBirthFirstPage",
    },
    // {
    //   heading: "Slide COW HISTORY slide 12",
    //   body: "Text body goes here",
    //   backgroundImageUrl: "",
    //   component: CowGaveBirthThirdSlide,
    //   customCssClassName: "CowGaveBirthThirdSlide",
    //   slideTitle: "CowGaveBirthThirdSlide",
    // },
    // {
    //   heading: "Slide COW HISTORY slide 13",
    //   body: "Text body goes here",
    //   backgroundImageUrl: "",
    //   component: CowGaveBirthFourthSlide,
    //   customCssClassName: "CowGaveBirthFourthSlide",
    //   slideTitle: "CowGaveBirthFourthSlide",
    // },
    // {
    //   heading: "Slide COW HISTORY slide 14",
    //   body: "Text body goes here",
    //   backgroundImageUrl: "",
    //   component: CowFirstMilkingSlide,
    //   customCssClassName: "CowFirstMilkingSlide",
    //   slideTitle: "CowFirstMilkingSlide",
    // },
    // {
    //   heading: "Slide COW HISTORY slide 15",
    //   body: "Text body goes here",
    //   backgroundImageUrl: "",
    //   component: CowFirstMilkingSecondSlide,
    //   customCssClassName: "CowFirstMilkingSecondSlide",
    //   slideTitle: "CowFirstMilkingSecondSlide",
    // },
    {
      heading: "Slide COW HISTORY slide 16",
      body: "Text body goes here",
      backgroundImageUrl: "",
      component: CowOutroPage,
      slideTitle: "CowHistoryOutroPage",
    },
  ],
};

export const getCowDailyStoryTextBlocksByCowData = function (
  cowDailyDetails,
  cowGrazingData,
  farmFeaturesDetails,
  farmDetails,
) {
  //#region CowDaily always starts with cow introduction slides
  let introSlides = [];
  const comparedDate = moment(
    new Date(cowDailyDetails && cowDailyDetails.lastUpdated),
  ).format("D MMM YYYY");
  const isBetweenDate = moment(comparedDate).isBetween(
    cowGrazingData.dateFrom,
    cowGrazingData.dateTo,
  );
  introSlides.push({
    heading: "",
    body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
    backgroundImageUrl: "",
    component: CowDailyIntroPage,
    slideTitle: "CowDailyIntroPage",
    // forbiddenSwipeRanges: [
    //   {
    //     start: 0,
    //     end: 100,
    //   },
    // ],
    props: {
      cowDailyDetails: cowDailyDetails,
      farmHouseStyle: farmDetails,
    },
  });
  if (cowGrazingData.flag) {
    introSlides.push({
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyGrazingPage,
      slideTitle: "CowDailyGrazingPage",
      props: {
        cowDailyDetails: cowDailyDetails,
        cowGrazingData: cowGrazingData,
      },
    });
    introSlides.push({
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyGrazeWayPage,
      slideTitle: "CowDailyGrazeWayPage",
      props: {
        cowDailyDetails: cowDailyDetails,
        cowGrazingData: cowGrazingData,
        farmFeaturesDetails: farmFeaturesDetails,
      },
    });
  }
  //#endregion

  //#region setup slides for all milkings
  let milkingSlides = [];
  if (
    cowDailyDetails &&
    cowDailyDetails.milkings &&
    cowDailyDetails.milkings.length > 0
  ) {
    cowDailyDetails.milkings.forEach((currentMilkingData) => {
      milkingSlides.push({
        heading: "",
        body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
        backgroundImageUrl: "",
        component: CowDailyMilkingPage,
        slideTitle: "CowDailyMilkingPage",
        props: {
          milkingData: currentMilkingData,
        },
      });
      milkingSlides.push({
        heading: "",
        body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
        backgroundImageUrl: "",
        component: CowDailyMilkingBottlesPage,
        slideTitle: "CowDailyMilkingBottlesPage",
        props: {
          milkingData: currentMilkingData,
        },
      });
    });
  }
  //#endregion

  //#region setup eating slides
  let eatingSlides = [];
  let isThereEnoughInformationAboutEating =
    cowDailyDetails.eatingMinutes !== null &&
    cowDailyDetails.eatingMinutes > 0 &&
    (cowDailyDetails.feedIntakeConcentrateKg !== null ||
      cowDailyDetails.feedIntakeConcentrateKg !== 0);
  if (isThereEnoughInformationAboutEating) {
    eatingSlides.push({
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyEatingPage,
      customCssClassName: "CowDailyEating1Slide",
      slideTitle: "CowDailyEating1Slide",
      props: {
        cowDailyDetails: cowDailyDetails,
      },
    });
    eatingSlides.push({
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyVectorPage,
      slideTitle: "CowDailyVectorPage",
      props: {
        cowDailyDetails: cowDailyDetails,
        cowGrazingData: cowGrazingData,
        farmFeaturesDetails: farmFeaturesDetails,
      },
    });
    // eatingSlides.push({
    //   heading: "",
    //   body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
    //   backgroundImageUrl: "",
    //   component: CowDailyEatingPage,
    //   customCssClassName: "CowDailyEating3Slide",
    //   slideTitle: "CowDailyEating3Slide",
    //   props: {
    //     cowDailyDetails: cowDailyDetails,
    //   },
    // });
    //#endregion
  }
  //#region setup resting slides
  let ruminationSlides = [];
  let isThereEnoughInformationAboutRumination =
    cowDailyDetails.ruminationMinutes > 0;
  if (isThereEnoughInformationAboutRumination) {
    ruminationSlides.push({
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyRuminationPage,
      slideTitle: "CowDailyRuminationPage",
      props: {
        cowDailyDetails: cowDailyDetails,
      },
    });
    ruminationSlides.push({
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyBlankPage,
      slideTitle: "CowDailyBlankPage",
      props: {
        cowDailyDetails: cowDailyDetails,
        cowGrazingData: cowGrazingData,
        farmFeaturesDetails: farmFeaturesDetails,
      },
    });
  }
  //#endregion

  //#region setup resting slides
  let restingSlides = [];
  let isThereEnoughInformationAboutResting = cowDailyDetails.restingMinutes > 0;
  if (isThereEnoughInformationAboutResting) {
    restingSlides.push({
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyRestingPage,
      customCssClassName: "CowDailyResting1Slide",
      slideTitle: "CowDailyResting1Slide",
      props: {
        cowDailyDetails: cowDailyDetails,
      },
    });
    restingSlides.push({
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyBlankPage,
      slideTitle: "CowDailyBlankPage",
      props: {
        cowDailyDetails: cowDailyDetails,
        cowGrazingData: cowGrazingData,
        farmFeaturesDetails: farmFeaturesDetails,
      },
    });
    // restingSlides.push({
    //   heading: "",
    //   body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
    //   backgroundImageUrl: "",
    //   component: CowDailyRestingPage,
    //   customCssClassName: "CowDailyResting3Slide",
    //   slideTitle: "CowDailyRestingPage",
    //   props: {
    //     cowDailyDetails: cowDailyDetails,
    //   },
    // });
  }
  //#endregion

  let outroSlides = [];
  outroSlides.push({
    heading: "",
    body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
    backgroundImageUrl: "",
    component: CowDailyOutroPage,
    customCssClassName: "CowDailyOutroPage",
    slideTitle: "CowDailyOutroPage",
    props: {
      cowDailyDetails: cowDailyDetails,
    },
  });

  const isThereMilking = milkingSlides.length > 0;
  const isThereSecondMilking =
    milkingSlides.length > NUMBER_OF_SLIDES_FOR_MILKING;
  const isThereThreeOrMoreMilkings =
    milkingSlides.length > NUMBER_OF_SLIDES_FOR_MILKING * 2;

  //#region composite the story with all of its slides
  let storySlides = [...introSlides]; // intro
  if (isThereMilking) {
    // first milking
    storySlides = [
      ...storySlides,
      ...milkingSlides.slice(0, NUMBER_OF_SLIDES_FOR_MILKING),
    ];
  }
  storySlides = [...storySlides, ...eatingSlides]; // eating
  if (isThereSecondMilking) {
    // second milking
    storySlides = [
      ...storySlides,
      ...milkingSlides.slice(
        NUMBER_OF_SLIDES_FOR_MILKING,
        NUMBER_OF_SLIDES_FOR_MILKING * 2,
      ),
    ];
  }
  storySlides = [...storySlides, ...ruminationSlides]; // rumination
  if (isThereThreeOrMoreMilkings) {
    // third milking and beyond
    storySlides = [
      ...storySlides,
      ...milkingSlides.slice(NUMBER_OF_SLIDES_FOR_MILKING * 2),
    ];
  }
  storySlides = [...storySlides, ...restingSlides]; // resting
  storySlides = [...storySlides, ...outroSlides]; // outro
  //#endregion

  let story = {};
  story.textBlocks = [...storySlides];

  return story;
};

export const DEFAULT_ONBOARDING = {
  textBlocks: [],
};

export const MILK_BOTTLE_ONBOARDING = {
  isActorHidden: true,
  textBlocks: [
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: BottleStoryPage,
      slideTitle: "MilkBottleOnboarding",
    },
  ],
};

export const MIJN_MELK_ONBOARDING = {
  isActorHidden: true,
  textBlocks: [
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: WelcomeScreen,
      slideTitle: "MijnMelkWelcomeScreen",
    },
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: FarmerPresentation,
      slideTitle: "MijnMelkFarmerPresentation",
    },
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: OrganicFarming,
      slideTitle: "MijnMelkOrganicFarming",
    },
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: MeadowScreen,
      slideTitle: "MijnMelkMeadowScreen",
    },
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: RobotsOnboarding,
      slideTitle: "MijnMelkRobotsOnboarding",
    },
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: OrbiterScreen,
      slideTitle: "MijnMelkOrbiterScreen",
    },
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: FinalOnboardingSlide,
      slideTitle: "MijnMelkFinalOnboardingSlide",
    },
  ],
};
export const KOOL_CHEESE_ONBOARDING = {
  isActorHidden: true,
  textBlocks: [
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: WelcomeScreenKool,
      slideTitle: "KoolCheeseWelcomeScreen",
    },
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: FarmerPresentationKool,
      slideTitle: "KoolCheeseFarmerPresentation",
    },
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: RobotsOnboardingKool,
      slideTitle: "KoolCheeseRobotsOnboarding",
    },
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: CheeseFactoryScreen,
      slideTitle: "KoolCheeseOrbiterScreen",
    },
    {
      heading: "",
      body: "",
      backgroundImageUrl: "",
      component: FinalOnboardingSlideKool,
      // props: {

      //   farmName: translateDynamic(farmDetails.farmName),
      // },
      slideTitle: "KoolCheeseFinalOnboardingSlide",
    },
  ],
};

export const STORY_COW_LATEST = {
  textBlocks: [
    {
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyIntroPage,
      slideTitle: "CowDailyIntroPage",
      forbiddenSwipeRanges: [
        {
          start: 0,
          end: 100,
        },
      ],
    },
    {
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyAstronautPage,
      slideTitle: "CowDailyAstronautPage",
    },
    {
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyEatingPage,
      customCssClassName: "CowDailyEating1Slide",
      slideTitle: "CowDailyEating1Slide",
    },
    {
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyEatingPage,
      slideTitle: "CowDailyEatingPage",
    },
    {
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyEatingPage,
      customCssClassName: "CowDailyEating3Slide",
      slideTitle: "CowDailyEating3Slide",
    },
    {
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyMilkingPage,
      slideTitle: "CowDailyMilkingPage",
    },
    {
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyMilkingPage,
      customCssClassName: "CowDailyMilking2Slide",
      slideTitle: "CowDailyMilking2Slide",
    },
    {
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyRestingPage,
      customCssClassName: "CowDailyResting1Slide",
      slideTitle: "CowDailyResting1Slide",
    },
    {
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyRestingPage,
      slideTitle: "CowDailyRestingPage",
    },
    {
      heading: "",
      body: "translate:COW_INTRO_PAGE_INTRO_PARAGRAPH",
      backgroundImageUrl: "",
      component: CowDailyRestingPage,
      customCssClassName: "CowDailyResting3Slide",
      slideTitle: "CowDailyRestingPage",
    },
  ],
  parallaxBackground: ParallaxCowLatest,
};

export const STORY_FARMER = (farmDetails) => {
  const translatedFarmerSlides = translateSlides(
    farmDetails.farmer.storySlides,
  );
  const dynamicSlides =
    translatedFarmerSlides &&
    translatedFarmerSlides.map((ss, index) => ({
      component: DynamicSlide,
      slideTitle: "RobotStoryFarmer2",
      props: {
        index,
        title: (ss.title),
        description: (ss.description),
        img: ss.imageData,
        slideTitle: ss.title,
      },
    }));
  return {
    isActorHidden: true,
    textBlocks: [
      {
        component: StartingPoint,
        slideTitle: "RobotStoryFarmer",
        props: {
          name: translateDynamic(farmDetails.farmer.name),
          gender: farmDetails.farmer.style,
          farmName: translateDynamic(farmDetails.farmName),
          style: farmDetails.style,
        },
        // forbiddenSwipeRanges: [
        //   {
        //     start: 0,
        //     end: 100,
        //   },
        // ],
      },
      ...dynamicSlides,
      {
        component: EndingPoint,
        slideTitle: "RobotStoryFarmer3",
        props: {
          webSiteURL: farmDetails.farmer.webSiteURL,
          farmName: translateDynamic(farmDetails.farmName),
        },
      },
    ],
  };
};
export const STORY_VECTOR_ROBOT = (farmDetails) => {
  return {
    isActorHidden: true,
    textBlocks: [
      {
        heading: "VECTOR ROBOT slide 1",
        body: "Text body goes here",
        component: StartingSlideVector,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryVector1",
        // forbiddenSwipeRanges: [
        //   {
        //     start: 0,
        //     end: 100,
        //   },
        // ],
      },
      {
        heading: "VECTOR ROBOT slide 2",
        body: "Text body goes here",
        component: SecondSlideVector,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryVector2",
      },
      {
        heading: "VECTOR ROBOT slide 3",
        body: "Text body goes here",
        component: ThirdSlideVector,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryVector3",
      },
      {
        heading: "VECTOR ROBOT slide 4",
        body: "Text body goes here",
        component: FourthSlideVector,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryVector4",
      },
      {
        heading: "VECTOR ROBOT slide 5",
        body: "Text body goes here",
        component: LastSlideVectorSlide,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryVector5",
      },
    ],
  };
};
export const STORY_JUNO_ROBOT = (farmDetails) => {
  return {
    isActorHidden: true,
    textBlocks: [
      {
        heading: "VECTOR ROBOT slide 1",
        body: "Text body goes here",
        component: StartingSlideJuno,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryVector1",
        // forbiddenSwipeRanges: [
        //   {
        //     start: 0,
        //     end: 100,
        //   },
        // ],
      },
      {
        heading: "VECTOR ROBOT slide 2",
        body: "Text body goes here",
        component: SecondSlideJuno,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryVector2",
      },
      {
        heading: "VECTOR ROBOT slide 3",
        body: "Text body goes here",
        component: ThirdSlideJuno,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryVector3",
      },
      {
        heading: "VECTOR ROBOT slide 4",
        body: "Text body goes here",
        component: FourthSlideJuno,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryVector4",
      },
      {
        heading: "VECTOR ROBOT slide 5",
        body: "Text body goes here",
        component: LastSlideVectorSlide,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryVector5",
      },
    ],
  };
};

export const STORY_LELY_BRAND = (farmDetails) => {
  return {
    isActorHidden: true,
    textBlocks: [
      {
        heading: "LELY BRAND slide 1",
        body: "Text body goes here",
        component: StartingSlideLely,
        props: {
          name: farmDetails && farmDetails.brand && farmDetails.brand.name,
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStoryLely1",
        // forbiddenSwipeRanges: [
        //   {
        //     start: 0,
        //     end: 100,
        //   },
        // ],
      },
      {
        heading: "LELY BRAND slide 2",
        body: "Text body goes here",
        component: SecondSlideLely,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryLely2",
      },
      {
        heading: "LELY BRAND slide 3",
        body: "Text body goes here",
        component: ThirdSlideLely,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryLely3",
      },
      {
        heading: "LELY BRAND slide 4",
        body: "Text body goes here",
        component: FourthSlideLely,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryLely4",
      },
      {
        heading: "LELY BRAND slide 5",
        body: "Text body goes here",
        component: FifthSlideLely,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryLely5",
      },
      {
        heading: "LELY BRAND slide 6",
        body: "Text body goes here",
        component: SixthSlideLely,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryLely6",
      },
      {
        heading: "LELY BRAND slide 7",
        body: "Text body goes here",
        component: LastSlideLelySlide,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryLely7",
      },
    ],
  };
};

export const STORY_DISCOVERY = (farmDetails) => {
  return {
    isActorHidden: true,
    textBlocks: [
      {
        heading: "DISCOVERY slide 1",
        body: "Text body goes here",
        component: StartingSlideDiscovery,
        props: {
          name: farmDetails && farmDetails.brand && farmDetails.brand.name,
          otherDevices: farmDetails && farmDetails.otherDevices,
        },
        // forbiddenSwipeRanges: [
        //   {
        //     start: 0,
        //     end: 100,
        //   },
        // ],
        backgroundImageUrl: "",
        slideTitle: "RobotStoryDiscovery1",
      },
      {
        heading: "DISCOVERY slide 2",
        body: "Text body goes here",
        component: SecondSlideDiscovery,
        props: { otherDevices: farmDetails && farmDetails.otherDevices },
        backgroundImageUrl: "",
        slideTitle: "RobotStoryDiscovery2",
      },
      {
        heading: "DISCOVERY slide 3",
        body: "Text body goes here",
        component: ThirdSlideDiscovery,
        props: { otherDevices: farmDetails && farmDetails.otherDevices },
        backgroundImageUrl: "",
        slideTitle: "RobotStoryDiscovery3",
      },
      {
        heading: "DISCOVERY slide 4",
        body: "Text body goes here",
        component: LastSlideDiscoverySlide,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryDiscovery4",
      },
    ],
  };
};
export const STORY_LUNA = (farmDetails) => {
  return {
    isActorHidden: true,
    textBlocks: [
      {
        heading: "DISCOVERY slide 1",
        body: "Text body goes here",
        component: StartingSlideLuna,
        props: {
          name: farmDetails && farmDetails.brand && farmDetails.brand.name,
        },
        // forbiddenSwipeRanges: [
        //   {
        //     start: 0,
        //     end: 100,
        //   },
        // ],
        backgroundImageUrl: "",
        slideTitle: "RobotStoryDiscovery1",
      },
      {
        heading: "DISCOVERY slide 2",
        body: "Text body goes here",
        component: SecondSlideLuna,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryDiscovery2",
      },
      {
        heading: "DISCOVERY slide 3",
        body: "Text body goes here",
        component: ThirdSlideLuna,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryDiscovery3",
      },
      {
        heading: "DISCOVERY slide 4",
        body: "Text body goes here",
        component: LastSlideDiscoverySlide,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryDiscovery4",
      },
    ],
  };
};

export const STORY_ASTRONAUT_LIVE_DATA = (farmDetails) => {
  return {
    isActorHidden: true,
    textBlocks: [
      {
        heading: "ASTRONAUT ROBOT slide 1",
        body: "Text body goes here",
        component: AstronautNameSlide,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAstronaut1",
      },
      {
        heading: "ASTRONAUT ROBOT slide 2",
        body: "Text body goes here",
        component: AstronautNumberSlide,
        props: {
          name: translateDynamic(farmDetails?.farmer?.name),
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAstronaut2",
      },
      {
        heading: "ASTRONAUT ROBOT slide 3",
        body: "Text body goes here",
        component: AstronautAmountOfSeconds,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAstronaut3",
      },
      {
        heading: "ASTRONAUT ROBOT slide 3",
        body: "Text body goes here",
        component: AstronautCommonData,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAstronaut3",
      },
      {
        heading: "ASTRONAUT ROBOT slide 3",
        body: "Text body goes here",
        component: AstronautTeetAnimationSlide,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAstronaut3",
      },
      {
        heading: "ASTRONAUT ROBOT slide 4",
        body: "Text body goes here",
        component: AstronautTimeMilked,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAstronaut4",
      },
      {
        heading: "ASTRONAUT ROBOT slide 5",
        body: "Text body goes here",
        component: AstronautTimeCleanedAndMoved,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAstronaut5",
      },
      {
        heading: "ASTRONAUT ROBOT slide 6",
        body: "Text body goes here",
        component: LastSlideAstronautSlide,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAstronaut6",
      },
    ],
  };
};
export const STORY_ASTRONAUT_ROBOT = (farmDetails) => {
  return {
    isActorHidden: true,
    textBlocks: [
      {
        heading: "ASTRONAUT ROBOT slide 1",
        body: "Text body goes here",
        component: StartingSlideAstronaut,
        backgroundImageUrl: "",
        // forbiddenSwipeRanges: [
        //   {
        //     start: 0,
        //     end: 100,
        //   },
        // ],
        slideTitle: "RobotStoryAstronaut1",
      },
      {
        heading: "ASTRONAUT ROBOT slide 2",
        body: "Text body goes here",
        component: SecondSlideAstronaut,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAstronaut2",
      },
      {
        heading: "ASTRONAUT ROBOT slide 3",
        body: "Text body goes here",
        component: ThirdSlideAstronaut,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAstronaut3",
      },
      {
        heading: "ASTRONAUT ROBOT slide 4",
        body: "Text body goes here",
        component: FourthSlideAstronaut,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAstronaut4",
      },
      // {
      //   heading: "ASTRONAUT ROBOT slide 5",
      //   body: "Text body goes here",
      //   component: FifthSlideAstronaut,
      //   backgroundImageUrl: "",
      //   slideTitle: "RobotStoryAstronaut5",
      // },
      {
        heading: "ASTRONAUT ROBOT slide 6",
        body: "Text body goes here",
        component: LastSlideAstronautSlide,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAstronaut6",
      },
    ],
  };
};
export const STORY_SUSTAINABILITY = (farmDetails) => {
  return {
    isActorHidden: true,
    textBlocks: [
      {
        heading: "STORY_SUSTAINABILITY slide 1",
        body: "Text body goes here",
        component: StartingSlideSustainability,
        props: {
          farmDetails,
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStorySustainabilityStory1",
      },
      {
        heading: "STORY_SUSTAINABILITY slide 2",
        body: "Text body goes here",
        component: SecondSlideSustainability,
        props: {
          farmDetails,
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStorySustainabilityStory2",
      },
      {
        heading: "STORY_SUSTAINABILITY slide 3",
        body: "Text body goes here",
        component: ThirdSlideSustainability,
        props: {
          farmDetails,
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStorySustainabilityStory3",
      },
      {
        heading: "STORY_SUSTAINABILITY slide 4",
        body: "Text body goes here",
        component: FourthSlideSustainability,
        props: {
          farmDetails,
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStorySustainabilityStory4",
      },
      {
        heading: "STORY_SUSTAINABILITY slide 5",
        body: "Text body goes here",
        component: FifthSlideSustainability,
        props: {
          farmDetails,
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStorySustainabilityStory5",
      },
      {
        heading: "STORY_SUSTAINABILITY slide 6",
        body: "Text body goes here",
        component: SixthSlideSustainability,
        props: {
          farmDetails,
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStorySustainabilityStory6",
      },
      {
        heading: "STORY_SUSTAINABILITY slide 7",
        body: "Text body goes here",
        component: LastSlideSustainability,
        backgroundImageUrl: "",
        slideTitle: "RobotStorySustainabilityStory7",
      },
    ],
  };
};
export const STORY_ANIMAL_WELFARE = (farmDetails) => {
  return {
    isActorHidden: true,
    textBlocks: [
      {
        heading: "STORY_SUSTAINABILITY slide 1",
        body: "Text body goes here",
        component: StartingSlideAnimalWelfare,
        props: {
          farmDetails,
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAnimalWelfareStory1",
      },
      {
        heading: "STORY_SUSTAINABILITY slide 2",
        body: "Text body goes here",
        component: SecondSlideAnimalWelfare,
        props: {
          farmDetails,
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAnimalWelfareStory2",
      },
      {
        heading: "STORY_SUSTAINABILITY slide 3",
        body: "Text body goes here",
        component: ThirdSlideAnimalWelfare,
        props: {
          farmDetails,
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAnimalWelfareStory3",
      },
      {
        heading: "STORY_SUSTAINABILITY slide 4",
        body: "Text body goes here",
        component: FourthSlideAnimalWelfare,
        props: {
          farmDetails,
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAnimalWelfareStory4",
      },
      {
        heading: "STORY_SUSTAINABILITY slide 5",
        body: "Text body goes here",
        component: FifthSlideAnimalWelfare,
        props: {
          farmDetails,
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAnimalWelfareStory5",
      },
      {
        heading: "STORY_SUSTAINABILITY slide 7",
        body: "Text body goes here",
        component: LastSlideSustainability,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryAnimalWelfareStory6",
      },
    ],
  };
};
export const STORY_ORBITER_ROBOT = (farmDetails) => {
  return {
    isActorHidden: true,
    textBlocks: [
      {
        heading: "ORBITER ROBOT slide 1",
        body: "Text body goes here",
        component: StartingSlideOrbiter,
        backgroundImageUrl: "",
        // forbiddenSwipeRanges: [
        //   {
        //     start: 0,
        //     end: 100,
        //   },
        // ],
        slideTitle: "RobotStoryOrbiter1",
      },
      {
        heading: "ORBITER ROBOT slide 2",
        body: "Text body goes here",
        component: SecondSlideOrbiter,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter2",
      },
      {
        heading: "ORBITER ROBOT slide 3",
        body: "Text body goes here",
        component: ThirdSlideOrbiter,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter3",
      },
      {
        heading: "ORBITER ROBOT slide 4",
        body: "Text body goes here",
        component: FourthSlideOrbiter,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter4",
      },
      {
        heading: "ORBITER ROBOT slide 5",
        body: "Text body goes here",
        component: FifthSlideOrbiter,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter5",
      },
      {
        heading: "ORBITER ROBOT slide 6",
        body: "Text body goes here",
        component: SixthSlideOrbiter,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter6",
      },
      {
        heading: "ORBITER ROBOT slide 7",
        body: "Text body goes here",
        component: LastSlideOrbiterSlide,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter7",
      },
    ],
  };
}; 
export const STORY_ORBITER_ROBOT_YOGHURT = (farmDetails) => {
  return {
    isActorHidden: true,
    textBlocks: [
      {
        heading: "ORBITER ROBOT slide 1",
        body: "Text body goes here",
        component: StartingSlideOrbiterYoghurt,
        backgroundImageUrl: "",
        // forbiddenSwipeRanges: [
        //   {
        //     start: 0,
        //     end: 100,
        //   },
        // ],
        slideTitle: "RobotStoryOrbiter1",
      },
      {
        heading: "ORBITER ROBOT slide 2",
        body: "Text body goes here",
        component: SecondSlideOrbiterYoghurt,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter2",
      },
      {
        heading: "ORBITER ROBOT slide 3",
        body: "Text body goes here",
        component: ThirdSlideOrbiterYoghurt,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter3",
      },
      {
        heading: "ORBITER ROBOT slide 4",
        body: "Text body goes here",
        component: FourthSlideOrbiterYoghurt,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter4",
      },
      {
        heading: "ORBITER ROBOT slide 5",
        body: "Text body goes here",
        component: FifthSlideOrbiterYoghurt,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter5",
      },
      {
        heading: "ORBITER ROBOT slide 5",
        body: "Text body goes here",
        component: SixthSlideOrbiterYoghurt,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter5",
      },
      {
        heading: "ORBITER ROBOT slide 6",
        body: "Text body goes here",
        component: SeventhSlideOrbiterYoghurt,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter6",
      },
      {
        heading: "ORBITER ROBOT slide 7",
        body: "Text body goes here",
        component: LastSlideOrbiterSlide,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryOrbiter7",
      },
    ],
  };
};
export const STORY_MIJN_MELK = (farmDetails) => {
  return {
    isActorHidden: true,
    textBlocks: [
      {
        heading: "MIJN MELK STORY SLIDE 1",
        body: "Text body goes here",
        component: FirstSlideMijnMelk,
        // forbiddenSwipeRanges: [
        //   {
        //     start: 0,
        //     end: 100,
        //   },
        // ],
        backgroundImageUrl: "",
        slideTitle: "RobotStoryMijnMelk1",
      },
      {
        heading: "MIJN MELK STORY SLIDE 2",
        body: "Text body goes here",
        component: SecondSlideMijnMelk,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryMijnMelk2",
      },
      {
        heading: "MIJN MELK STORY SLIDE 3",
        body: "Text body goes here",
        component: ThirdSlideMijnMelk,
        backgroundImageUrl: "",
        slideTitle: "RobotStoryMijnMelk3",
      },
      {
        heading: "MIJN MELK STORY SLIDE 4",
        body: "Text body goes here",
        component: LastSlideMijnMelk,
        props: {
          name: translateDynamic(farmDetails.farmer.name),
        },
        backgroundImageUrl: "",
        slideTitle: "RobotStoryMijnMelk4",
      },
    ],
  };
};
// export const STORY_MILK_FACTORY = {
//   textBlocks: [
//     {
//       heading: "MILK FACTORY slide 1",
//       body: "Text body goes here",
//       backgroundImageUrl: "",
//       slideTitle: "MILK FACTORY slide 1",
//     },
//     {
//       heading: "MILK FACTORY slide 2",
//       body: "Text body goes here",
//       backgroundImageUrl: "",
//       slideTitle: "MILK FACTORY slide 2",
//     },
//     {
//       heading: "MILK FACTORY slide 3",
//       body: "Text body goes here",
//       backgroundImageUrl: "",
//       slideTitle: "MILK FACTORY slide 3",
//     },
//     {
//       heading: "MILK FACTORY slide 4",
//       body: "Text body goes here",
//       backgroundImageUrl: "",
//       slideTitle: "MILK FACTORY slide 4",
//     },
//   ],
// };

export const STORY_ICE_CREAM_FACTORY = {
  textBlocks: [
    {
      heading: "ICE CREAM FACTORY slide 1",
      body: "Text body goes here",
      backgroundImageUrl: "",
      // forbiddenSwipeRanges: [
      //   {
      //     start: 0,
      //     end: 100,
      //   },
      // ],
      slideTitle: "ICE CREAM FACTORY slide 1",
    },
    {
      heading: "ICE CREAM FACTORY slide 2",
      body: "Text body goes here",
      backgroundImageUrl: "",
      slideTitle: "ICE CREAM FACTORY slide 2",
    },
    {
      heading: "ICE CREAM FACTORY slide 3",
      body: "Text body goes here",
      backgroundImageUrl: "",
      slideTitle: "ICE CREAM FACTORY slide 3",
    },
  ],
};

export const STORY_CHEESE_FACTORY = {
  textBlocks: [
    {
      heading: "CHEESE FACTORY slide 1",
      body: "Text body goes here",
      backgroundImageUrl: "",
      // forbiddenSwipeRanges: [
      //   {
      //     start: 0,
      //     end: 100,
      //   },
      // ],
      slideTitle: "CHEESE FACTORY slide 1",
    },
    {
      heading: "CHEESE FACTORY slide 2",
      body: "Text body goes here",
      backgroundImageUrl: "",
      slideTitle: "CHEESE FACTORY slide 2",
    },
    {
      heading: "CHEESE FACTORY slide 3",
      body: "Text body goes here",
      backgroundImageUrl: "",
      slideTitle: "CHEESE FACTORY slide 3",
    },
  ],
};
