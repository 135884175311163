import { GET_UDDER_DETAILS_REQUEST } from "./types.js";
import {
  getUdderDetailsError,
  getUdderDetailsSuccess,
} from "./actions.js";
import { put, take, call } from "redux-saga/effects";
import { getServerResponseStatus } from "../../../components/ErrorPopUp/actions";
import {
  startLoading,
  endLoading,
} from "../../redux/loadManager/actions";
import { getLoadId } from "../../redux/loadManager/methods";

let currentLoadId = 0;

export default function* udderDetailsSaga(api) {
  while (true) {
    const udderDetailsRequestAction = yield take(
      GET_UDDER_DETAILS_REQUEST,
    );
    /** Update state when server is online */
    yield put(getServerResponseStatus(""));
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { farmId, cowId } = udderDetailsRequestAction.payload;
    yield call(getUdderDetails, api, farmId, cowId);
  }
}

/** Create request to get udder details
 * @param {string} farmId - Farm id
 * @param {string} cowId - Cow id
 */
function* getUdderDetails(api, farmId, cowId) {
  try {
    const udderDetails = yield call(api.getAstronautDetails, farmId, cowId);
    yield put(getUdderDetailsSuccess(udderDetails.data));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    console.log("@error:", error);
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(getUdderDetailsError(error));
    /** Listen for error state to show when server is disconected */
    yield put(getServerResponseStatus(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}
