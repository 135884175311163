import React, { useEffect } from 'react';
import './TextCaption.scss';

/** TextCaption component */
function TextCaption({children, additionalCssClassname, explicitWidth}) {
  return (
    <div  style={{
            width: explicitWidth
          }}
          className={"textCaption " + additionalCssClassname}>
      {children}
    </div>
  );
}
export default TextCaption;
