import React from "react";
import "./SolarPanel.scss";
import { measureResponsive } from "../VirtualFarm/util";
import { IS_CLICKABLE_CSS_CLASSNAME } from "../../common/constants";
/** SolarPanel component */
function SolarPanel({
  solarPanelData,
  farmName,
  solarPanelCssClassname,
  isClickable,
  solarpanelClickHandlerFn,
}) {
  solarPanelData.farmName = farmName;
  return (
    <div
      style={{
        left: measureResponsive(33.2008),
        width: measureResponsive(15.61282),
        top: measureResponsive(10.99735),
        height: measureResponsive(15.9601),
      }}
      className={
        isClickable
          ? solarPanelCssClassname + " " + IS_CLICKABLE_CSS_CLASSNAME
          : solarPanelCssClassname
      }
      onClick={() => {
        solarpanelClickHandlerFn(solarPanelData);
      }}
    />
  );
}
export default SolarPanel;
