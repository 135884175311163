import { ACCEPT_ALL_COOKIES } from "./types";

/** Create action to accept all cookies
 * @return {Object} Returns action object
 */
export function acceptAllCookies() {
  return {
    type: ACCEPT_ALL_COOKIES,
    payload: {},
  };
}
