import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core";

// Styles
import "./ValidatedTextInput.scss";
import { initial } from "lodash";
import { sanitize } from "dompurify";

/** ValidatedTextInput component.
 * @param {Object} obj - Object which contains all props.
 * @param {Object} [obj.tooltip] - The tooltip icon
 * @param {Function} [obj.tooltipFn] - Function that will be called on tooltip click
 * @param {string} [obj.placeholder] - Search placeholder
 * @param {string} [obj.dataTestID] - Data test ID
 * @param {string} [obj.labelText] - Label for the text input
 * @param {string} [obj.initialValue] - Initial value for the text input
 * @param {Function} [obj.handleChangeFn] - Function that will be called whenever the text input value has changed
 * @param {string} [obj.additionalClassName] - Adds additional css class name
 * @param {boolean} [obj.optional] - Flag if field is not required
 * @param {string} [obj.placeholderText] - Placeholder text
 * @param {boolean} [obj.disabled] - Whether the text field is disabled or not
 * @param {string} [obj.inputType] - Allows the setting of different kind of input (e.g.: "password")
 * @param {boolean} [obj.isValid] - Marks the field as invalid (red border around the text input) if set to false
 */
function ValidatedTextInput({
  tooltip,
  tooltipFn,
  placeholder,
  dataTestID,
  labelText,
  initialValue,
  handleChangeFn,
  additionalClassName,
  optional,
  placeholderText,
  disabled,
  inputType,
  isValid,
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue || value || "");
  }, [initialValue]);

  /**
   * @param {Object} event - Contains current value of input filed.
   */
  function handleChange(event) {
    let sanitizedValue = sanitize(event.target.value);
    setValue(sanitizedValue);
    handleChangeFn(sanitizedValue);
  }
  return (
    <div className="labelled-text-input">
      <label className="labelled-text-input--label">{labelText}</label>
      <input
        type={inputType || "text"}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        data-testid={dataTestID}
        placeholder={placeholderText || ""}
        className={`${
          isValid ? "is-valid " : "is-invalid "
        } labelled-text-input--textInput${
          additionalClassName ? " " + additionalClassName : ""
        }${tooltip ? " tooltip-padding" : ""}`}
      />
      {tooltip && (
        <Tooltip className="labelled-text-input--tooltip" onClick={tooltipFn}>
          {tooltip}
        </Tooltip>
      )}
    </div>
  );
}

export default ValidatedTextInput;
