import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core";

// Styles
import "./LabelledTextInputForLanguage.scss";
import { sanitize } from "dompurify";

/** LabelledTextInputForLanguage component.
 * @param {Object} obj - Object which contains all props.
 * @param {Object} [obj.tooltip] - The tooltip icon
 * @param {Function} [obj.tooltipFn] - Function that will be called on tooltip click
 * @param {string} [obj.placeholder] - Search placeholder
 * @param {string} [obj.dataTestID] - Data test ID
 * @param {string} [obj.labelText] - Label for the text input
 * @param {string} [obj.initialValue] - Initial value for the text input
 * @param {Function} [obj.handleChangeFn] - Function that will be called whenever the text input value has changed
 * @param {string} [obj.additionalClassName] - Adds additional css class name
 * @param {boolean} [obj.optional] - Flag if field is not required
 */
function LabelledTextInputForLanguage({
  tooltip,
  tooltipFn,
  placeholder,
  dataTestID,
  labelText,
  initialValue,
  handleChangeFn,
  additionalClassName,
  optional,
  disabled,
  givenLength,
}) {
  const [value, setValue] = useState(initialValue);
  const [borderStyle, setBorderStyle] = useState("1px solid #cfcbc3");
  givenLength = givenLength ? givenLength : 250;

  useEffect(() => {
    setValue(initialValue || value || "");
  }, [initialValue]);

  useEffect(() => {
    if (optional || (value && value.length > 0)) {
      setBorderStyle("1px solid #cfcbc3");
    } else {
      setBorderStyle("1px solid #FF0000");
    }
  }, [value]);
  /**
   * @param {Object} event - Contains current value of input filed.
   */
  function handleChange(event) {
    let sanitizedValue = sanitize(event.target.value);
    setValue(sanitizedValue);
    handleChangeFn(sanitizedValue);
  }
  return (
    <div className="labelled-text-input-for-language">
      <label className="labelled-text-input-for-language--label">
        {labelText}
      </label>
      <input
        type="text"
        value={value}
        disabled={disabled}
        onChange={handleChange}
        data-testid={dataTestID}
        maxLength={givenLength}
        style={{ border: borderStyle }}
        className={`labelled-text-input--textInput${
          additionalClassName ? " " + additionalClassName : ""
        }${tooltip ? " tooltip-padding" : ""}`}
      />
      {tooltip && (
        <Tooltip className="labelled-text-input--tooltip" onClick={tooltipFn}>
          {tooltip}
        </Tooltip>
      )}
    </div>
  );
}

export default LabelledTextInputForLanguage;
