import {
  REQUEST_FARM_FEATURES_PAGE_INFORMATION,
  FARM_FEATURES_PAGE_INFORMATION_SUCCESS,
  FARM_FEATURES_PAGE_INFORMATION_ERROR,
  SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE,
  SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE_SUCCESS,
  SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE_ERROR,
} from './types';

/** Create action which will receive the text blocks for the selected farm
 * @param {string} accessToken - User access token
 * @return {Object} Returns action object
 */
export function requestFarmFeaturesPageInformation(accessToken) {
  return {
    type: REQUEST_FARM_FEATURES_PAGE_INFORMATION,
    payload: {
      accessToken: accessToken,
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of the About the farm page in the Admin app
 * @param {Object} textBlocksDetails - an object containing the data to be used in order to initialize the About the farm page in the Admin app
 * @return {Object} Return type and farmDetails
 */
export function getFarmFeaturesInformationSuccess(textBlocksDetails) {
  return {
    type: FARM_FEATURES_PAGE_INFORMATION_SUCCESS,
    payload: textBlocksDetails,
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getFarmFeaturesIformationError(errorMessage) {
  return {
    type: FARM_FEATURES_PAGE_INFORMATION_ERROR,
    payload: errorMessage,
  };
}

/** Create action which to be used whenever the user clicks the Save changes button in the About the farm page in the Admin app
 * @param {string} accessToken - User access token
 * @param {Object} newFarmFeaturesDetails - The latest state of the about the farm details (some new text blocks might be added, the contents of existing ones might have been changed)
 * @return {Object} Returns action object
 */
export function saveChangesToFarmFeaturesPage(
  accessToken,
  newFarmFeaturesDetails,
) {
  return {
    type: SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE,
    payload: {
      accessToken: accessToken,
      newFarmFeaturesDetails,
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of the About The Farm page in the Admin app
 * @return {Object} Returns action object
 */
export function saveChangesToFarmFeaturesPageSuccess(newFarmFeaturesData) {
  return {
    type: SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE_SUCCESS,
    payload: newFarmFeaturesData
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function saveChangesToFarmFeaturesPageSuccessError(errorMessage) {
  return {
    type: SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE_ERROR,
    payload: errorMessage,
  };
}
