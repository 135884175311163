import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getLastSaveStatus,
  setLastSaveStatus,
  setLatestSaveFn,
} from "../common/components/SaveChangesManager/util";
import { updateCurrentSaveStatus } from "../common/components/SaveChangesManager/actions";
import uuid from "react-uuid";

const useSaveManager = (
  saveChangesButtonClickHandlerFn,
  isSaved,
  setIsSaved,
) => {
  const dispatch = useDispatch();
  const [lastSaveTimestamp, setLastSaveTimestamp] = useState(0);
  useEffect(() => {
    setLatestSaveFn(saveChangesButtonClickHandlerFn);
    if (lastSaveTimestamp !== 0) {
      dispatch(
        updateCurrentSaveStatus(
          saveChangesButtonClickHandlerFn,
          getLastSaveStatus(),
        ),
      );
    }
  }, [lastSaveTimestamp]);

  return {
    lastSaveTimestamp: lastSaveTimestamp,
    setSaved: (newValue) => {
      setLastSaveTimestamp(uuid());
      setIsSaved(newValue);
      setLastSaveStatus(newValue);
    },
  };
};

export default useSaveManager;
