import axios from 'axios';
import { ABOUT_THE_FARMER_SERVICE_URL } from './config';

export const api = {
  getFarmerDetails: async function (accessToken) {
    /*
    return {
      data: {
          farmerName: 'Mock first name farmer',
          farmerStyle: 'male',
          favoriteCow: 'My favourite cow is Aukjie 104',
          personalQuote: 'Personal quote goes here',
          visionQuote: 'Farmer vision quote goes here',
          websiteURL: 'www.farmer.com',
          dogName: 'Dog name goes here',
          dogType: 'medium',
          dogStory: 'Dog story text goes here',
          otherAnimalType: 'horse',
          imageData: {},
        }
    }; // <-- those are the text blocks that the backend has returned (mock data)
    */

    // uncomment code below when backend is ready
    return axios.get(ABOUT_THE_FARMER_SERVICE_URL, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  setFarmerDetails: async function (accessToken, newFarmerDetails) {
    /*
    return {
      data: {
        status: 200,
      },
    }; // <-- mock backend response
    */

    // uncomment code below when backend is ready
    return axios.patch(ABOUT_THE_FARMER_SERVICE_URL,
      newFarmerDetails,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        }
      }
    );
  },
};
