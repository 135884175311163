import { RECOVER_USER_REQUEST, RECOVER_USER_REQUEST_SUCCESS } from "./types.js";
import {
  registerUserSuccess,
  registerUserError,
  recoverUserSuccess,
  recoverUserError,
} from "./actions.js";
import { put, take, call } from "redux-saga/effects";
import { redirect } from "../../CustomRedirect/actions";
import { getServerResponseStatus } from "../../ErrorPopUp/actions";
import {
  startLoading,
  endLoading,
} from "../../../common/redux/loadManager/actions";
import { getLoadId } from "../../../common/redux/loadManager/methods";

let currentLoadId = 0;

export default function* recoverUserSaga(api) {
  while (true) {
    const recoverUserRequest = yield take(RECOVER_USER_REQUEST);
    if (recoverUserRequest.payload) {
      /** Run loader */
      currentLoadId = getLoadId();
      yield put(startLoading(currentLoadId));
      const { emailAddress } = recoverUserRequest.payload;
      yield call(recoverUser, api, emailAddress);
    }
  }
}

/** Create recover user request
 * @param {object} api - API providing access to the Lely backend
 * @param {string} emailAddress - E-mail address of the user whose access need to be recovered
 */
function* recoverUser(api, emailAddress) {
  var response;
  try {
    response = yield call(api.recover, emailAddress);
    //#region TODO: delete this region after e-mail user verification is possible
    let resetPasswordUrlFromBackend = response.data;
    const emailUrlParamString = "?email=";
    const tokenUrlParamString = "&token=";
    let emailParameterPositionInString =
      resetPasswordUrlFromBackend.indexOf(emailUrlParamString);
    let tokenParameterPositionInString =
      resetPasswordUrlFromBackend.indexOf(tokenUrlParamString);

    let emailValue = resetPasswordUrlFromBackend.substring(
      emailParameterPositionInString + emailUrlParamString.length,
      tokenParameterPositionInString,
    );
    let tokenValue = resetPasswordUrlFromBackend.substring(
      tokenParameterPositionInString + tokenUrlParamString.length,
    );
    let setNewPasswordUrl = `/admin/passwordreset?email=${emailValue}&token=${tokenValue}`;
    yield put(recoverUserSuccess());
    yield put(getServerResponseStatus(""));
    yield put(endLoading(currentLoadId));
    console.log("Set new password service URL: ", setNewPasswordUrl);
    window.location = setNewPasswordUrl;
    //#endregion
  } catch (error) {
    yield put(recoverUserError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
    /** Listen for error state to show when server is disconected */
    yield put(getServerResponseStatus(error));
  }
}
