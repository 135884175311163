import React from "react";
import "./AstronautNameSlide.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";

const AstronautNameSlide = ({}) => {
  const { astronautDetails } = useSelector((state) => state.astronautDetails);
  return (
    <div className="astronautNameSlide">
      <TextCaption additionalCssClassname="astronautNameSlide--textCaption">
        <div className="astronautNameSlide--title">
          {translate("ASTRONAUT_LIVE_DATA_ROBOT_TITLE")}
        </div>
        <div className="astronautNameSlide--name">{astronautDetails.name}</div>
      </TextCaption>
      <div className="astronautNameSlide--robot"></div>
    </div>
  );
};

export default AstronautNameSlide;
