import React from "react";
import _ from "lodash";
import { translate } from "../../../localization/TranslationUtil";
import "./AnimalTypeSelection.scss";
import ImageRadioSelection from "../ImageRadioSelection/ImageRadioSelection";
import {
  ANIMAL_TYPE_ALPACA,
  ANIMAL_TYPE_CAT,
  ANIMAL_TYPE_CHICKEN,
  ANIMAL_TYPE_DONKEY,
  ANIMAL_TYPE_HORSE,
  ANIMAL_TYPE_LARGE_DOG,
  ANIMAL_TYPE_LLAMA,
  ANIMAL_TYPE_MEDIUM_DOG,
  ANIMAL_TYPE_PONY,
  ANIMAL_TYPE_SHEEP,
  ANIMAL_TYPE_SMALL_DOG,
  ANIMAL_TYPE_GOAT,
} from "../../../common/constants";
import { getAnimalOptionsArray } from "../../../components/AnimalBlock/Animal";

/** AnimalTypeSelection component.
 * @param {Object} obj - Object which contains all props.
 * @param {Function} [obj.onAnimalTypeChangeFn] - Function that will be called whenever the animal type is changed
 * @param {String} [obj.initialAnimalType] - The initially selected animal type
 * @param {Boolean} [obj.areCatsEnabled]
 * @param {Boolean} [obj.areDogsEnabled]
 * @param {Boolean} [obj.areHorsesEnabled]
 */
function AnimalTypeSelection({
  onAnimalTypeChangeFn,
  initialAnimalType,
  areCatsEnabled,
  areDogsEnabled,
  areHorsesEnabled,
}) {
  return (
    <div className="AnimalTypeSelection">
      <>
        <ImageRadioSelection
          componentLabel={translate("ANIMALS_PAGE_ANIMAL_KIND_QUESTION_TEXT")}
          optionsArray={getAnimalOptionsArray(
            areCatsEnabled,
            areDogsEnabled,
            areHorsesEnabled,
          )}
          initialSelectedValue={initialAnimalType}
          onSelectionChangeFn={(newSelectedValue) => {
            onAnimalTypeChangeFn(newSelectedValue);
          }}
        />
      </>
    </div>
  );
}

export default AnimalTypeSelection;
