import {
  START_LOADING,
  END_LOADING,
  END_SEEDING_FARM_DATA,
  START_SEEDING_FARM_DATA,
} from "../common/redux/loadManager/types";
import { cloneDeep } from "lodash";
import { PAGES_WITH_CONSTANT_COW_LOADER } from "../common/constants";
/** Store states
 * @constant {Object} */
export const initialState = {
  loadingOperations: [],
  loadedScreen: "",
  initialPageLoad: false,
  isSeedingFarmDataInProgress: false,
};

/** Load Manager Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function loadManagerReducer(state = initialState, action) {
  const loadId = action.payload && action.payload.loadId;
  const newLoadOperations = cloneDeep(state.loadingOperations);
  let newInitialPageLoad = cloneDeep(state.initialPageLoad);
  let newLoadedScreen = cloneDeep(state.loadedScreen);
  // example: "overview"
  const currentPageUrlPath = window.location.pathname
    .split("/")
    .slice(-1)
    .pop()
    .toLowerCase();

  // In some pages cow loader need to be always shown
  const isThePageWithConstantCowLoader =
    PAGES_WITH_CONSTANT_COW_LOADER.includes(currentPageUrlPath);
  const isThePageChanged = newLoadedScreen !== currentPageUrlPath;

  switch (action.type) {
    case START_LOADING:
      newLoadOperations.push(loadId);
      if (isThePageChanged || isThePageWithConstantCowLoader) {
        // If the page has been change, show initial load
        newInitialPageLoad = true;
      }
      return {
        ...state,
        loadingOperations: newLoadOperations,
        initialPageLoad: newInitialPageLoad,
      };
    case START_SEEDING_FARM_DATA:
      return {
        ...state,
        isSeedingFarmDataInProgress: true,
      };
    case END_SEEDING_FARM_DATA:
      return {
        ...state,
        isSeedingFarmDataInProgress: false,
      };
    case END_LOADING:
      newLoadOperations.splice(newLoadOperations.indexOf(loadId), 1);
      if (newLoadOperations.length === 0) {
        // If initialLoad is done , hide initial load for other requests
        newInitialPageLoad = false;
        newLoadedScreen = isThePageChanged
          ? currentPageUrlPath
          : newLoadedScreen;
      }
      return {
        ...state,
        loadingOperations: newLoadOperations,
        initialPageLoad: newInitialPageLoad,
        loadedScreen: newLoadedScreen,
      };
    default:
      return state;
  }
}

export default loadManagerReducer;
