import React from "react";
import "./SeventhSlideOrbiterYoghurt.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const SeventhSlideOrbiterYoghurt = ({}) => {
  return (
    <div className="orbiterStorySeventhSlide">
      <div className="orbiterStorySeventhSlide--floor"></div>
      <TextCaption additionalCssClassname="orbiterStorySeventhSlide--textCaption">
        <div className="orbiterStorySeventhSlide--text">
          {translate("ORBITER_SEVENTH_SLIDE_YOGHURT_TEXT")}
        </div>
      </TextCaption>
      <div className="orbiterStorySeventhSlide--robot"></div>
      <div className="orbiterStorySeventhSlide--table"></div>
      <div className="orbiterStorySeventhSlide--tree"></div>
      <div className="orbiterStorySeventhSlide--pipe"></div>
      <div className="orbiterStorySeventhSlide--arrow"></div>
    </div>
  );
};

export default SeventhSlideOrbiterYoghurt;
