import React from "react";
import "./StartingSlideLely.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import StoryNavigationHint from "../../common/components/StoryNavigationHint/StoryNavigationHint";

const StartingSlideLely = ({ name }) => {
  return (
    <div className="lelyStoryStartingSlide">
      <TextCaption additionalCssClassname="lelyStoryStartingSlide--textCaption">
        <div className="lelyStoryStartingSlide--title">
          {translate("LELY_STORY_TITLE")}
        </div>
        <div className="lelyStoryStartingSlide--name">{name}</div>
      </TextCaption>
      <div className="lelyStoryStartingSlide--building"></div>
      <div className="lelyStoryStartingSlide--trees"></div>
      <div className="lelyStoryStartingSlide--line"></div>
      <StoryNavigationHint />
    </div>
  );
};

export default StartingSlideLely;
