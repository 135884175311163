import React, { useEffect, useState } from "react";
import "./CreateAccount.scss";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, resetUserManagement } from "./actions.js";
import { Button, Link } from "@material-ui/core";
import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../../localization/TranslationUtil";
import ValidatedTextInput from "../../../common/components/ValidatedTextInput/ValidatedTextInput";
import {
  emailValidationFn,
  emptyValidationFn,
  sameValueValidationFn,
  strongPasswordValidationFn,
} from "../../../common/components/ValidatedTextInput/ValidationsUtil";
import { redirect } from "../../CustomRedirect/actions";
import { resendVerificationEmail } from "../ResendVerificationEmail/actions";
import CheckboxGroupSelection from "../../../common/components/CheckboxGroupSelection/CheckboxGroupSelection";
import TermsAndConditionsDialog from "../../../common/components/TermsAndConditionsDialog/TermsAndConditionsDialog";

/** CreateAccount component */
function CreateAccount() {
  const dispatch = useDispatch();
  const { isRegisterUserSuccess, isRegisterUserError } = useSelector(
    (state) => state.userManagementDetails,
  );

  useEffect(() => {
    return () => {
      dispatch(resetUserManagement());
    };
  }, []);

  //#region field state variables
  const [username, setUsername] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChangeMadeToRegistrationForm, setIsChangeMadeToRegistrationForm] =
    useState(false);
  const [areTermsAndConditionsApproved, setAreTermsAndConditionsApproved] =
    useState(false);
  //#endregion

  //#region validation logic
  const [isEmailAddressValid, setIsEmailAddressValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isUsernameValid, setIsUsernameValid] = useState(false);

  const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const isCreateAccountButtonEnabled = validationErrorMessage === "";
  const [isTermsAndConditionsDisplayed, setTermsAndConditionsDisplayed] =
    useState(false);
  const updateValidationErrorMessage = () => {
    // run a bunch of validations here specific to the individual fields in the form
    // set validation error message state here based on validation errors (if any; if there are no validation errors set this state to empty string)
    const ERROR_MESSAGES_SEPARATOR_CHARACTER = "\n";
    let validationErrorMessages = [];

    let areThereEmailErrors = false;
    let areTherePasswordErrors = false;
    let areThereUsernameErrors = false;

    if (!emailValidationFn(emailAddress)) {
      validationErrorMessages.push(
        translate(
          "USER_MANAGEMENT_CREATE_ACCOUNT_ERROR_MSG_INVALID_EMAIL_ADDRESS_FORMAT",
        ),
      );
      areThereEmailErrors = true;
    }
    if (!sameValueValidationFn(password, confirmPassword)) {
      validationErrorMessages.push(
        translate(
          "USER_MANAGEMENT_CREATE_ACCOUNT_ERROR_MSG_PASSWORD_NOT_MATCHING",
        ),
      );
      areTherePasswordErrors = true;
    }
    if (!strongPasswordValidationFn(password)) {
      validationErrorMessages.push(
        translate("USER_MANAGEMENT_CREATE_ACCOUNT_ERROR_MSG_WEAK_PASSWORD"),
      );
      areTherePasswordErrors = true;
    }
    if (!emptyValidationFn(username)) {
      areThereUsernameErrors = true;
    }
    if (!emptyValidationFn(password)) {
      areTherePasswordErrors = true;
    }
    if (!emptyValidationFn(confirmPassword)) {
      areTherePasswordErrors = true;
    }
    if (!emptyValidationFn(emailAddress)) {
      areThereEmailErrors = true;
    }
    const isSomeOfTheFieldsEmpty = !(
      emptyValidationFn(username) &&
      emptyValidationFn(password) &&
      emptyValidationFn(confirmPassword) &&
      emptyValidationFn(emailAddress)
    );
    if (isSomeOfTheFieldsEmpty) {
      validationErrorMessages.push(
        translate("USER_MANAGEMENT_CREATE_ACCOUNT_ERROR_MSG_EMPTY_FIELDS"),
      );
    }
    if (!areTermsAndConditionsApproved) {
      validationErrorMessages.push(
        translate(
          "USER_MANAGEMENT_CREATE_ACCOUNT_ERROR_TERMS_AND_CONDITIONS_MANDATORY",
        ),
      );
    }
    setValidationErrorMessage(
      validationErrorMessages.join(ERROR_MESSAGES_SEPARATOR_CHARACTER),
    );
    console.log(
      "areThereEmailErrors: ",
      areThereEmailErrors,
      "emailAddress:",
      emailAddress,
    );
    setIsEmailAddressValid(!areThereEmailErrors);
    setIsPasswordValid(!areTherePasswordErrors);
    setIsUsernameValid(!areThereUsernameErrors);
    return validationErrorMessage.length === 0;
  };

  useEffect(() => {
    console.log("@useeffect of update validation error message");
    updateValidationErrorMessage();
  }, [
    username,
    emailAddress,
    password,
    confirmPassword,
    areTermsAndConditionsApproved,
  ]);
  //#endregion

  function handleCreateAccountButtonClick() {
    dispatch(registerUser(username, password, emailAddress));
  }

  function handleResendVerificationEmailButtonClick() {
    dispatch(resendVerificationEmail(emailAddress));
  }

  return !isRegisterUserSuccess ? (
    <div className="create-account-container--creationPage">
      <h4>{translate("USER_MANAGEMENT_CREATE_ACCOUNT_HEADING_TEXT")}</h4>
      <h5>{translate("USER_MANAGEMENT_CREATE_ACCOUNT_SUBHEADING_TEXT")}</h5>
      <ValidatedTextInput
        initialValue={emailAddress}
        labelText={translate(
          "USER_MANAGEMENT_CREATE_ACCOUNT_EMAIL_ADDRESS_LABEL_TEXT",
        )}
        handleChangeFn={(emailAddressValue) => {
          setIsChangeMadeToRegistrationForm(true);
          setEmailAddress(emailAddressValue);
        }}
        additionalClassName="create-account--textField"
        dataTestID="txt-emailAddress"
        isValid={isEmailAddressValid}
        placeholderText={translate(
          "USER_MANAGEMENT_CREATE_ACCOUNT_EMAIL_ADDRESS_LABEL_TEXT",
        )}
      />
      <ValidatedTextInput
        initialValue={username}
        labelText={translate(
          "USER_MANAGEMENT_CREATE_ACCOUNT_LICENSE_LABEL_TEXT",
        )}
        handleChangeFn={(usernameValue) => {
          setIsChangeMadeToRegistrationForm(true);
          setUsername(usernameValue);
        }}
        additionalClassName="create-account--textField"
        dataTestID="txt-username"
        isValid={isUsernameValid}
        placeholderText={translate(
          "USER_MANAGEMENT_CREATE_ACCOUNT_LICENSE_LABEL_TEXT",
        )}
      />
      <ValidatedTextInput
        initialValue={password}
        labelText={translate("USER_MANAGEMENT_LOGIN_PASSWORD_TEXT")}
        handleChangeFn={(passwordValue) => {
          setIsChangeMadeToRegistrationForm(true);
          setPassword(passwordValue);
        }}
        additionalClassName="create-account--textField"
        inputType="password"
        dataTestID="txt-password"
        isValid={isPasswordValid}
        placeholderText={translate("USER_MANAGEMENT_LOGIN_PASSWORD_TEXT")}
      />
      <ValidatedTextInput
        initialValue={confirmPassword}
        labelText={translate(
          "USER_MANAGEMENT_CREATE_ACCOUNT_CONFIRM_PASSWORD_LABEL_TEXT",
        )}
        handleChangeFn={(confirmPasswordValue) => {
          setIsChangeMadeToRegistrationForm(true);
          setConfirmPassword(confirmPasswordValue);
        }}
        additionalClassName="create-account--textField"
        inputType="password"
        dataTestID="txt-confirmPassword"
        isValid={isPasswordValid}
        placeholderText={translate("USER_MANAGEMENT_LOGIN_PASSWORD_TEXT")}
      />

      <p className="create-account-container--error-msg">
        {isChangeMadeToRegistrationForm ? validationErrorMessage : ""}
      </p>
      {isRegisterUserError ? (
        <p className="create-account-container--error-msg">
          {translate("USER_MANAGEMENT_REGISTER_USER_ERROR")}
        </p>
      ) : null}
      <div className="create--account--checkbox">
        <CheckboxGroupSelection
          optionsArray={[
            {
              value: "termsAndConditionsSelected",
            },
          ]}
          selectedOptions={
            areTermsAndConditionsApproved ? ["termsAndConditionsSelected"] : []
          }
          onSelectionChange={(newSelectedValue, isChecked) => {
            console.log("@EZE onSelectionChange isChecked:", isChecked);
            setIsChangeMadeToRegistrationForm(true);
            setAreTermsAndConditionsApproved(isChecked);
          }}
        />
        <button
          onClick={() => {
            setTermsAndConditionsDisplayed(true);
          }}
          className="termsAndConditionsButton"
          type="button"
        >
          <i className="termsAndConditionsButton"></i>
          <span
            className="create--account--checkbox--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml(
                "TERMS_AND_CONDITIONS_CHECKBOX_TEXT",
              ),
            }}
          ></span>
        </button>
        {isTermsAndConditionsDisplayed && (
          <TermsAndConditionsDialog
            onCancelCallbackFn={() => {
              setTermsAndConditionsDisplayed(false);
            }}
          />
        )}
      </div>
      <Button
        disabled={!isCreateAccountButtonEnabled}
        data-testid="btn-create-account"
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className="btn-createAccount"
        onClick={handleCreateAccountButtonClick}
      >
        {translate("USER_MANAGEMENT_CREATE_ACCOUNT_BUTTON_TEXT")}
      </Button>
      <p className="create-account-container--alreadyCreatedAccountLabel">
        {translate(
          "USER_MANAGEMENT_CREATE_ACCOUNT_ALREADY_SIGNED_UP_LABEL_TEXT",
        )}
        <Link
          onClick={() => {
            dispatch(redirect("/admin/login"));
          }}
        >
          {translate("USER_MANAGEMENT_CREATE_ACCOUNT_LOGIN_BUTTON_TEXT")}
        </Link>
      </p>
    </div>
  ) : (
    <div className="create-account-container--verificationPage">
      <h4>
        {translate("USER_MANAGEMENT_CREATE_ACCOUNT_PLEASE_VERIFY_HEADING_TEXT")}
      </h4>
      <p className="create-account-container--emailVerificationParagraph">
        {translateWithValues(
          "USER_MANAGEMENT_CREATE_ACCOUNT_PLEASE_VERIFY_FIRST_PARAGRAPH_TEXT",
          {
            emailAddress: emailAddress,
          },
        )}
      </p>
      <p className="create-account-container--emailVerificationParagraph">
        {translate(
          "USER_MANAGEMENT_CREATE_ACCOUNT_PLEASE_VERIFY_SECOND_PARAGRAPH_TEXT",
        )}
      </p>
      <p className="create-account-container--emailVerificationParagraph">
        {translate(
          "USER_MANAGEMENT_CREATE_ACCOUNT_PLEASE_VERIFY_THIRD_PARAGRAPH_TEXT",
        )}
      </p>
      <Button
        data-testid="btn-resend-verification-email"
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className="btn-resendVerificationEmail"
        onClick={handleCreateAccountButtonClick}
      >
        {translate(
          "USER_MANAGEMENT_CREATE_ACCOUNT_PLEASE_VERIFY_RESEND_BUTTON_TEXT",
        )}
      </Button>
    </div>
  );
}

export default CreateAccount;
