export const REQUEST_FARMER_PAGE_INFORMATION =
  'REQUEST_FARMER_PAGE_INFORMATION';
export const FARMER_PAGE_INFORMATION_ERROR = 'FARMER_PAGE_INFORMATION_ERROR';
export const FARMER_PAGE_INFORMATION_SUCCESS =
  'FARMER_PAGE_INFORMATION_SUCCESS';
export const SAVE_CHANGES_TO_FARMER_PAGE = 'SAVE_CHANGES_TO_FARMER_PAGE';
export const SAVE_CHANGES_TO_FARMER_PAGE_SUCCESS =
  'SAVE_CHANGES_TO_FARMER_PAGE_SUCCESS';
export const SAVE_CHANGES_TO_FARMER_PAGE_ERROR =
  'SAVE_CHANGES_TO_FARMER_PAGE_ERROR';
