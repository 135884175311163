import {
  GET_UDDER_DETAILS_REQUEST,
  GET_UDDER_DETAILS_REQUEST_SUCCESS,
  GET_UDDER_DETAILS_REQUEST_ERROR,
  RESET_UDDER_DETAILS,
} from "../common/components/AstronautCowDropdownMenu/types";

/** Store states
 * @constant {Object} */
export const initialState = {
  udderDetails: {},
  isUdderDetailsError: false,
  errorMessage: "",
};

/** Udder Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function udderReducer(state = initialState, action) {
  switch (action.type) {
    case GET_UDDER_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        udderDetails: action.payload,
        isUdderDetailsError: false,
        errorMessage: "",
      };

    case GET_UDDER_DETAILS_REQUEST_ERROR:
      return {
        ...state,
        udderDetails: {},
        isUdderDetailsError: true,
        errorMessage: action.payload,
      };

    case RESET_UDDER_DETAILS:
      return {
        ...state,
        udderDetails: {},
        isUdderDetailsError: false,
        errorMessage: "",
      };

    default:
      return state;
  }
}

export default udderReducer;
