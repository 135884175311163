import _ from "lodash";
import React, { useEffect } from "react";
import "./CowIntroPage.scss";
import TextCaption from "../../../components/TextCaption/TextCaption";
import { useSelector } from "react-redux";
import {
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../../localization/TranslationUtil";
import { getFarmHouseStyleCssClassname } from "../../../common/farmUtil";
import StoryNavigationHint from "../../../common/components/StoryNavigationHint/StoryNavigationHint";

const CowIntroPage = React.memo(() => {
  const { selectedCowDetails } = useSelector(
    (state) => state.selectedCowDetails,
  );
  const { selectedLanguage } = useSelector(
    (state) => state.selectedLanguageDetails,
  );

  const { farmDetails } = useSelector((state) => state.virtualFarmDetails);
  const farmStyle = farmDetails && farmDetails.style.toLowerCase();

  useEffect(() => {}, [!_.isEmpty(selectedCowDetails)]);

  if (!_.isEmpty(selectedCowDetails)) {
    return (
      <div className="cowIntroPage">
        <TextCaption additionalCssClassname="textCaption--cowIntro">
          <p
            className="cow-intro--text"
            dangerouslySetInnerHTML={{
              __html: translateWithValuesAndHtml("COW_INTRO_TEXT"),
            }}
          ></p>
          <span className="cow-intro--name">
            {translateWithValues("COW_INTRO_NAME", {
              cowName: selectedCowDetails.name,
            })}
          </span>
        </TextCaption>

        <StoryNavigationHint />
        <div className="cow-intro-leftTree"></div>
        <div
          className={
            "cow-intro-house " + getFarmHouseStyleCssClassname(farmStyle)
          }
        ></div>
        <div className="cow-intro-smallCow"></div>
      </div>
    );
  } else {
    return <p>Empty cow details.</p>;
  }
});

export default CowIntroPage;
