import { useEffect, useRef, useState } from "react";

function useDynamicSVGImport(svgFullFilePath, themeName, options = {}) {
  const ImportedSVGRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { onCompleted, onError } = options;
  useEffect(() => {
    setLoading(true);
    const importSVGImage = async () => {
      try {
        // WORKS! : ImportedSVGRef.current = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!./${svgFullFilePath}`)).default;
        // WORKS AS WELL! ImportedSVGRef.current = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!./../../resources/assets/themes/${themeName}/16x9/actors/cow/body/${svgFullFilePath}`)).default;
        ImportedSVGRef.current = (
          await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!./../../resources/assets/themes/${themeName}/16x9/actors/${svgFullFilePath}`
          )
        ).default;
        if (onCompleted) {
          onCompleted(svgFullFilePath, ImportedSVGRef.current);
        }
      } catch (err) {
        if (onError) {
          onError(err);
        }
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    importSVGImage();
  }, [svgFullFilePath, onCompleted, onError]);

  return { error, loading, SvgElement: ImportedSVGRef.current };
}

export default useDynamicSVGImport;
