import _ from "lodash";
import React, { useEffect } from "react";
import {
  getSubBreedCssClassNameBySubBreed,
  OPTIONS_PER_COW_BREED,
} from "../../common/components/LabelledCowBreedSelector/optionsPerCowBreed";

export const getRandomXYTransformCoordinates = function (
  viewportX1,
  viewportX2,
  viewportY1,
  viewportY2,
) {
  let randomXCoordinate = getRandomNumberBetweenMinAndMax(
    viewportX1,
    viewportX2,
  );
  let randomYCoordinate = getRandomNumberBetweenMinAndMax(
    viewportY1,
    viewportY2,
  );
  return {
    randomXCoordinate,
    randomYCoordinate,
  };
};

export const getRandomNumberBetweenMinAndMax = function (minNumber, maxNumber) {
  return parseInt(Math.random() * (maxNumber - minNumber) + minNumber);
};

export const getRandomScaleX = function (scaleValue) {
  return getRandomNumberBetweenMinAndMax(0, 100) % 10 === 0
    ? scaleValue * -1
    : scaleValue;
};

export const decorateVirtualFarmDataWithPositionInformation = function (
  virtualFarmData,
) {
  let modifiedVirtualFarmData = _.cloneDeep(virtualFarmData);

  let randomFarmerCoordinates = getRandomXYTransformCoordinates(50, 60, 1, 10);
  let randomFarmerScale = getRandomScaleX(1);
  modifiedVirtualFarmData.farmer.x = randomFarmerCoordinates.randomXCoordinate;
  modifiedVirtualFarmData.farmer.y = randomFarmerCoordinates.randomYCoordinate;
  modifiedVirtualFarmData.farmer.scale = randomFarmerScale;
  modifiedVirtualFarmData.farmer.type = "farmer"; // TODO: backend

  // modifiedVirtualFarmData.pets.map((currentPet) => {
  //   let randomPetCoordinates = getRandomXYTransformCoordinates(50, 60, 1, 10);
  //   let randomPetScale = randomFarmerScale;
  //   currentPet.x = randomPetCoordinates.randomXCoordinate;
  //   currentPet.y = randomPetCoordinates.randomYCoordinate;
  //   currentPet.scale = randomPetScale;
  // });

  let batch = modifiedVirtualFarmData.milkBatch;
  let cowsWithFullInfo = batch
    ? batch.cows
    : modifiedVirtualFarmData.cowsWithFullInfo;
  cowsWithFullInfo.map((currentCow) => {
    let randomCowCoordinates = getRandomXYTransformCoordinates(20, 70, 20, 40);
    let randomCowScale = getRandomScaleX(1);
    currentCow.x = randomCowCoordinates.randomXCoordinate + 5;
    currentCow.y = randomCowCoordinates.randomYCoordinate + 5;
    currentCow.scale = randomCowScale;
    //currentCow.cowType = 'friesian'; TODO: BE should return this instead of us putting it here
  });

  return modifiedVirtualFarmData;
};

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
// const vh = window.innerHeight * 0.01;
// const vw = window.innerWidth * 0.01;
// Then we set the value in the --vh custom property to the root of the document
//document.documentElement.style.setProperty('--vh', `${vh}px`);
export const farmerPetCoordinates = [
  { x: `${20 / 2.16533}`, y: `${115 / 2.165333}` },
  { x: `${17 / 2.16533}`, y: `${105 / 2.16533}` },
  { x: `${30 / 2.16533}`, y: `${130 / 2.16533}` },
  { x: `${15 / 2.16533}`, y: `${90 / 2.16533}` },
  { x: `${7 / 2.16533}`, y: `${70 / 2.16533}` },
];

export const catCoordinates = [
  { x: `${38 / 2.16533}`, y: `${12 / 2.16533}` }, //
  { x: `${90 / 2.16533}`, y: `${14 / 2.16533}` },
  { x: `${93 / 2.16533}`, y: `${90 / 2.16533}` },
  { x: `${63 / 2.16533}`, y: `${90 / 2.16533}` }, //
  { x: `${50 / 2.16533}`, y: `${90 / 2.16533}` },
  { x: `${7 / 2.16533}`, y: `${90 / 2.16533}` }, //
  { x: `${7 / 2.16533}`, y: `${22 / 2.16533}` },
];
// lastCatPosition is passed so that we don't show the cat two times IN A ROW on the same spot
export const getRandomCatPosition = (lastCatPositionIndex) => {
  const minCatPositionIndex = 0;
  const maxCatPositionIndex = catCoordinates.length - 1;
  let randomCatPositionIndex = getRandomNumberBetweenMAndN(
    minCatPositionIndex,
    maxCatPositionIndex,
  );
  while (lastCatPositionIndex === randomCatPositionIndex) {
    randomCatPositionIndex = getRandomNumberBetweenMAndN(
      minCatPositionIndex,
      maxCatPositionIndex,
    );
  }
  return {
    ...catCoordinates[randomCatPositionIndex],
    preset: randomCatPositionIndex,
  };
};

export const getRandomNumberBetweenMAndN = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const otherAnimalCoordinates = [
  { x: `${97 / 2.16533}`, y: `${186 / 2.16533}` },
  { x: `${86 / 2.16533}`, y: `${181 / 2.16533}` },
  { x: `${95 / 2.16533}`, y: `${175 / 2.16533}` },
  { x: `${97 / 2.16533}`, y: `${196 / 2.16533}` },
  { x: `${88 / 2.16533}`, y: `${163 / 2.16533}` },
  { x: `${80 / 2.16533}`, y: `${196.5 / 2.16533}` },
  { x: `${75 / 2.16533}`, y: `${180 / 2.1653}` },
  { x: `${73 / 2.16533}`, y: `${188 / 2.16533}` },
  { x: `${82 / 2.16533}`, y: `${172 / 2.16533}` },
  { x: `${87 / 2.16533}`, y: `${192 / 2.16533}` },
];

export const toVh = (vhValue) => {
  return `calc(var(--vh, 1vh) * ${vhValue})`;
};

export const toVw = (vwValue) => {
  return `calc(var(--vw, 1vw) * ${vwValue})`;
};

export const measureResponsive = (leftSideIndex) => {
  let isInLandscapeMode = window.innerWidth > window.innerHeight;
  let aspectRatioIndex = isInLandscapeMode
    ? window.innerWidth / window.innerHeight
    : window.innerHeight / window.innerWidth;
  if (aspectRatioIndex > 2.6 && aspectRatioIndex < 3.0) {
    return toVh(leftSideIndex * (aspectRatioIndex - 1.45));
  } else if (aspectRatioIndex >= 3.0) {
    return toVh(leftSideIndex * (aspectRatioIndex - 0.85));
  } else if (aspectRatioIndex < 2.6 && aspectRatioIndex > 2.5) {
    return toVh(leftSideIndex * (aspectRatioIndex - 0.55));
  } else if (aspectRatioIndex <= 2.5 && aspectRatioIndex > 2.35) {
    return toVh(leftSideIndex * (aspectRatioIndex - 0.25));
  } else if (aspectRatioIndex <= 2.35 && aspectRatioIndex > 2.21) {
    return toVh(leftSideIndex * (aspectRatioIndex - 0.25));
  } else if (aspectRatioIndex <= 2.2 && aspectRatioIndex > 2.16) {
    return toVh(leftSideIndex * (aspectRatioIndex - 0.2));
  } else if (aspectRatioIndex <= 2.16 && aspectRatioIndex > 2.1) {
    return toVh(leftSideIndex * aspectRatioIndex);
  } else if (aspectRatioIndex <= 2.1 && aspectRatioIndex > 2.049) {
    return toVh(leftSideIndex * (aspectRatioIndex - 0.15));
  } else if (aspectRatioIndex <= 2.05 && aspectRatioIndex >= 1.95) {
    return toVh(leftSideIndex * aspectRatioIndex);
  } else if (aspectRatioIndex < 1.95) {
    return toVh(leftSideIndex * (aspectRatioIndex + 0.09));
  } else if (aspectRatioIndex < 1.89) {
    return toVh(leftSideIndex * (aspectRatioIndex + 0.22));
  } else if (aspectRatioIndex < 1.75) {
    return toVh(leftSideIndex * (aspectRatioIndex + 0.28));
  }
}; //

export const cowCoordinates = [
  { x: `${33 / 2.16533}`, y: `${7 / 2.16533}` },
  { x: `${89 / 2.16533}`, y: `${21 / 2.16533}` },
  { x: `${30 / 2.16533}`, y: `${54 / 2.16533}` },
  { x: `${20 / 2.16533}`, y: `${4 / 2.16533}` },
  { x: `${86 / 2.16533}`, y: `${32 / 2.16533}` },
  { x: `${43 / 2.16533}`, y: `${58 / 2.16533}` },
  { x: `${18 / 2.16533}`, y: `${70 / 2.16533}` },
  {
    x: `${133 / 2.1653}`,
    y: `${74 / 2.16533}`,
    isSpaceAware: true,
    hasExcrements: true,
  },
  { x: `${66 / 2.16533}`, y: `${53 / 2.16533}` },
  { x: `${35 / 2.16533}`, y: `${22 / 2.16533}` },
  { x: `${47 / 2.16533}`, y: `${14 / 2.16533}` },
  { x: `${54 / 2.16533}`, y: `${42 / 2.16533}` },
  { x: `${80 / 2.16533}`, y: `${44 / 2.16533}` },
  { x: `${58 / 2.16533}`, y: `${22.5 / 2.16533}` },
  {
    x: `${101 / 2.16533}`,
    y: `${57 / 2.16533} `,
    isSpaceAware: true,
    hasExcrements: false,
  },
  { x: `${78 / 2.16533} `, y: `${83 / 2.16533} ` },
  { x: `${83 / 2.16533} `, y: `${71 / 2.16533} ` },
  { x: `${20 / 2.16533} `, y: `${54 / 2.16533} ` },
  { x: `${21 / 2.16533} `, y: `${22 / 2.16533} ` },
  { x: `${18 / 2.16533} `, y: `${35 / 2.16533} ` },
  { x: `${66 / 2.16533} `, y: `${9 / 2.16533} ` },
  { x: `${79 / 2.16533} `, y: `${13 / 2.16533} ` },
  { x: `${70 / 2.16533} `, y: `${31 / 2.16533} ` },
  { x: `${82 / 2.16533} `, y: `${99 / 2.16533} ` },
  { x: `${36 / 2.16533} `, y: `${39 / 2.16533} ` },
];

//#region cow breed probability utilities
export const getBreedNamesArrayByCowsArrayAndBreedPercentageKeyDictionary =
  function (cowsArray, breedPercentageDictionary) {
    let totalNumberOfCows = cowsArray.length;
    let breedNamesArray = [];
    let latestBreedStartIndex = 0;
    Object.keys(breedPercentageDictionary).forEach(
      (currentBreedPercentageKey) => {
        let breedEndIndex = Math.floor(
          (currentBreedPercentageKey / 100) * totalNumberOfCows,
        ); // index from the array of cows at which this breed ENDS
        let currentBreed = breedPercentageDictionary[currentBreedPercentageKey];
        breedNamesArray = breedNamesArray.concat(
          getBreedItemsArray(
            latestBreedStartIndex,
            breedEndIndex,
            currentBreed,
          ),
        );
        latestBreedStartIndex = breedEndIndex;
      },
    );
    return breedNamesArray;
  };

export const getSubbreedPercentageByBreedColorsArray = function (
  breedColorsArray,
) {
  let selectedBreedColors = breedColorsArray.filter(
    (breedColor) => breedColor.isSelected,
  );
  return 1 / selectedBreedColors.length;
};

export const getBreedItemsArray = function (
  breedStartIndex,
  breedEndIndex,
  breedData,
) {
  let subBreedPercentageDictionary = getSubBreedPercentagesBySelectedColors(
    breedData.colors,
  );
  let totalNumberOfBreedItems = breedEndIndex - breedStartIndex;
  let breedItemsArray = [];

  let itemsPerSubBreed = Math.floor(
    getSubbreedPercentageByBreedColorsArray(breedData.colors) *
      totalNumberOfBreedItems,
  );
  Object.keys(subBreedPercentageDictionary).forEach(
    (currentSubBreedPercentageKey) => {
      let currentSubBreed =
        subBreedPercentageDictionary[currentSubBreedPercentageKey];
      breedItemsArray = breedItemsArray.concat(
        getArrayFromNumberToNumberWithValue(0, itemsPerSubBreed, {
          cowSubBreed: currentSubBreed,
          cowType: breedData.name,
        }),
      );
    },
  );

  return breedItemsArray;
};

export const getBreedPercentageDictionaryByBreedProportionConfig = function (
  breedProportionConfig,
) {
  let breedPercentageDictionary = {};
  let latestBreedPercentage = 0;
  breedProportionConfig
    .filter((currentBreed) => JSON.parse(currentBreed.isSelected))
    .forEach((currentBreed) => {
      let currentBreedPercentage =
        latestBreedPercentage + parseInt(currentBreed.probabilityPercentage);
      breedPercentageDictionary[currentBreedPercentage] = currentBreed;
      latestBreedPercentage = currentBreedPercentage;
    });
  return breedPercentageDictionary;
};

export const getSubBreedPercentageArrayBySelectedColorsArray = function (
  breedColorsArray,
) {
  let subBreedColorPercentageSize = (1 / breedColorsArray.length) * 100;
  let subBreedPercentageArray = [];
  for (
    let n = subBreedColorPercentageSize;
    Math.round(n) <= 100;
    n += subBreedColorPercentageSize
  ) {
    subBreedPercentageArray.push(n);
  }
  return subBreedPercentageArray;
};

export const getSubBreedPercentagesBySelectedColors = function (
  breedColorsArray,
) {
  let selectedBreedColors = breedColorsArray.filter((breedColor) =>
    JSON.parse(breedColor.isSelected),
  );
  let subBreedPercentageArray =
    getSubBreedPercentageArrayBySelectedColorsArray(selectedBreedColors);
  let subBreedPercentageDictionary = {};
  selectedBreedColors.forEach((currentBreedColor, currentBreedColorIndex) => {
    let colorName = currentBreedColor.name;
    subBreedPercentageDictionary[
      subBreedPercentageArray[currentBreedColorIndex]
    ] = colorName;
  });
  return subBreedPercentageDictionary;
};

export const getArrayFromNumberToNumberWithValue = function (
  startNumber,
  endNumber,
  value,
) {
  let newArray = [];
  for (var n = startNumber; n < endNumber; n++) {
    newArray.push(value);
  }
  return newArray;
};

export const decorateCowsWithBreed = function (
  cowsArray,
  breedProportionConfig,
) {
  let breedPercentageDictionary =
    getBreedPercentageDictionaryByBreedProportionConfig(breedProportionConfig);
  let breedNamesArray =
    getBreedNamesArrayByCowsArrayAndBreedPercentageKeyDictionary(
      cowsArray,
      breedPercentageDictionary,
    );

  let lastBreed = breedNamesArray[breedNamesArray.length - 1];

  cowsArray = cowsArray.map((currentCow, currentCowIndex) => {
    if (breedNamesArray.length > 0) {
      currentCow.cowType =
        currentCowIndex > breedNamesArray.length - 1
          ? lastBreed.cowType
          : breedNamesArray[currentCowIndex].cowType;
      currentCow.cowSubBreed =
        currentCowIndex > breedNamesArray.length - 1
          ? lastBreed.cowSubBreed
          : breedNamesArray[currentCowIndex].cowSubBreed;
      currentCow.subBreedCssClassName = getSubBreedCssClassNameBySubBreed(
        currentCow.cowSubBreed,
      );
    }

    return currentCow;
  });
};
//#endregion
