export const REQUEST_APPLICATION_NAVIGATION = "REQUEST_APPLICATION_NAVIGATION";
export const SAVE_CHANGES_AND_LEAVE = "SAVE_CHANGES_AND_LEAVE";
export const LEAVE_WITHOUT_SAVING_CHANGES = "LEAVE_WITHOUT_SAVING_CHANGES";
export const SAVE_CHANGES_POPUP_ACTION = "SAVE_CHANGES_POPUP_ACTION";
export const CLOSE_SAVE_CHANGES_POPUP = "CLOSE_SAVE_CHANGES_POPUP";
export const UPDATE_GLOBAL_SAVE_STATUS = "UPDATE_GLOBAL_SAVE_STATUS";
export const RESET_GLOBAL_SAVE_STATUS = "RESET_GLOBAL_SAVE_STATUS";
export const SHOW_CONFIRM_SAVING_CHANGES_POPUP =
  "SHOW_CONFIRM_SAVING_CHANGES_POPUP";
export const HIDE_CONFIRM_SAVING_CHANGES_POPUP =
  "HIDE_CONFIRM_SAVING_CHANGES_POPUP";
