import { getRandomNumberBetweenMAndN } from "../VirtualFarm/util";
import React, { useEffect, useState } from "react";
import "./BirdContainer.scss";
import Bird from "./Bird";
import { BirdType } from "./types/Bird.types";
import {
  BIRD_BOTTOM_TO_TOP_START_Y,
  BIRD_DIRECTION_LEFT_TO_RIGHT,
  BIRD_DIRECTION_RIGHT_TO_LEFT,
  BIRD_LEFT_TO_RIGHT_START_X,
  BIRD_MAX_FLIGHT_DURATION_IN_MILLISECONDS,
  BIRD_MIN_FLIGHT_DURATION_IN_MILLISECONDS,
  BIRD_RIGHT_TO_LEFT_START_X,
  BIRD_TOP_TO_BOTTOM_START_Y,
  BIRD_VERTICAL_DIRECTION_BOTTOM_TO_TOP,
  MAX_BIRD_SCALE,
  MAX_POSSIBLE_NUMBER_OF_BIRDS_TO_LAUNCH_SIMULTANEOUSLY,
  MAX_SECONDS_BETWEEN_LAUNCHING_BIRDS,
  MIN_BIRD_SCALE,
  MIN_SECONDS_BETWEEN_LAUNCHING_BIRDS,
  X_RANGE_MAX,
  X_RANGE_MIN,
  Y_RANGE_MAX,
  Y_RANGE_MIN,
} from "./config";

/** BirdContainer component */
const BirdContainer: React.FunctionComponent<any> = ({
  doDisableAnimation,
}: any) => {
  const [birds, setBirds] = useState<BirdType[]>([]);

  useEffect(() => {
    launchBirds();
  }, [birds]); //[birds]

  function getRandomDirection(): number {
    let randomNumberBetweenZeroAndFour = Math.round(Math.random() * 3);
    return randomNumberBetweenZeroAndFour;
  }

  function getRandomScaleBetweenMinScaleAndMaxScale(
    minScale,
    maxScale,
  ): number {
    let minMaxScaleDifference = maxScale - minScale;
    let randomScaleDistance = Math.random() * minMaxScaleDifference;
    return randomScaleDistance + minScale;
  }

  function shouldLaunchBird(birdsLaunchedSoFarDuringCurrentLaunch): boolean {
    return (
      birdsLaunchedSoFarDuringCurrentLaunch === 0 ||
      Math.round(Math.random()) === 1
    );
  }

  function getBirdFlightCoordinatesByBirdDirection(birdDirection: Number): any {
    let birdStartX;
    let birdStartY;
    let birdEndX;
    let birdEndY;
    let isHorizontalFlight =
      birdDirection === BIRD_DIRECTION_RIGHT_TO_LEFT ||
      birdDirection === BIRD_DIRECTION_LEFT_TO_RIGHT;
    if (isHorizontalFlight) {
      birdStartX =
        birdDirection === BIRD_DIRECTION_LEFT_TO_RIGHT
          ? BIRD_LEFT_TO_RIGHT_START_X
          : BIRD_RIGHT_TO_LEFT_START_X;
      birdEndX =
        birdDirection === BIRD_DIRECTION_LEFT_TO_RIGHT
          ? BIRD_RIGHT_TO_LEFT_START_X
          : BIRD_LEFT_TO_RIGHT_START_X;
      birdStartY = getRandomNumberBetweenMAndN(Y_RANGE_MIN, Y_RANGE_MAX);
      birdEndY = getRandomNumberBetweenMAndN(Y_RANGE_MIN, Y_RANGE_MAX);
    } else {
      birdStartX = getRandomNumberBetweenMAndN(X_RANGE_MIN, X_RANGE_MAX);
      birdEndX = getRandomNumberBetweenMAndN(X_RANGE_MIN, X_RANGE_MAX);
      birdStartY =
        birdDirection === BIRD_VERTICAL_DIRECTION_BOTTOM_TO_TOP
          ? BIRD_BOTTOM_TO_TOP_START_Y
          : BIRD_TOP_TO_BOTTOM_START_Y;
      birdEndY =
        birdDirection === BIRD_VERTICAL_DIRECTION_BOTTOM_TO_TOP
          ? BIRD_TOP_TO_BOTTOM_START_Y
          : BIRD_BOTTOM_TO_TOP_START_Y;
    }
    return {
      birdStartX,
      birdStartY,
      birdEndX,
      birdEndY,
    };
  }

  function getRandomBirdObject(birdIndex: number): BirdType {
    let birdDirection = getRandomDirection();

    let birdScale = getRandomScaleBetweenMinScaleAndMaxScale(
      MIN_BIRD_SCALE,
      MAX_BIRD_SCALE,
    );
    let birdFlightDurationInMilliseconds = getRandomNumberBetweenMAndN(
      BIRD_MIN_FLIGHT_DURATION_IN_MILLISECONDS,
      BIRD_MAX_FLIGHT_DURATION_IN_MILLISECONDS,
    );
    let { birdStartX, birdStartY, birdEndX, birdEndY } =
      getBirdFlightCoordinatesByBirdDirection(birdDirection);
    return {
      birdIndex: birdIndex,
      birdStartXCoordinate: birdStartX,
      birdEndXCoordinate: birdEndX,
      birdStartYCoordinate: birdStartY,
      birdEndYCoordinate: birdEndY,
      birdScale: birdScale,
      birdFlightDurationInMilliseconds: birdFlightDurationInMilliseconds,
    };
  }

  function launchBirds(): void {
    const LAUNCH_BIRDS_AFTER_SECONDS = getRandomNumberBetweenMAndN(
      MIN_SECONDS_BETWEEN_LAUNCHING_BIRDS,
      MAX_SECONDS_BETWEEN_LAUNCHING_BIRDS,
    );

    setTimeout(function () {
      let birdsArrayToBeLaunched: BirdType[] = [];
      let birdsLaunchedSoFar = 0;
      for (
        let b = 0;
        b < MAX_POSSIBLE_NUMBER_OF_BIRDS_TO_LAUNCH_SIMULTANEOUSLY;
        b++
      ) {
        if (shouldLaunchBird(birdsLaunchedSoFar)) {
          birdsLaunchedSoFar++;
          birdsArrayToBeLaunched.push(getRandomBirdObject(birdsLaunchedSoFar));
        }
      }
      setBirds(birdsArrayToBeLaunched);
    }, LAUNCH_BIRDS_AFTER_SECONDS * 1000);
  }

  let birdContainerCssClassname = doDisableAnimation
    ? "bird-container is-bird-animation-disabled"
    : "bird-container";
  return (
    <div className={birdContainerCssClassname}>
      {birds.map((currentBirdData, birdIndex) => (
        <Bird
          birdData={currentBirdData}
          birdCssClassname="bird"
          doDisableAnimation={doDisableAnimation}
        />
      ))}
    </div>
  );
};

export default BirdContainer;
