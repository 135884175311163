import { RESET_STORAGE, RESET_STORAGE_WITHOUT_USER_SESSION } from '../types';

/** Create action which calls resets the contents of the application local storage
 * @return {Object} Action object
 */
export function resetApplicationStore() {
  return {
    type: RESET_STORAGE,
    payload: {},
  };
}
/** Create action which calls resets the contents of the application local storage and remove everything without userSession data
 * @return {Object} Action object
 */
export function resetApplicationStoreWithoutUserSession() {
  return {
    type: RESET_STORAGE_WITHOUT_USER_SESSION,
    payload: {},
  };
}
