export const REQUEST_ABOUT_THE_FARM_PAGE_INFORMATION =
  'REQUEST_ABOUT_THE_FARM_PAGE_INFORMATION';
export const ABOUT_THE_FARM_PAGE_INFORMATION_ERROR =
  'ABOUT_THE_FARM_PAGE_INFORMATION_ERROR';
export const ABOUT_THE_FARM_PAGE_INFORMATION_SUCCESS =
  'ABOUT_THE_FARM_PAGE_INFORMATION_SUCCESS';
export const SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE =
  'SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE';
export const SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE_SUCCESS =
  'SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE_SUCCESS';
export const SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE_ERROR =
  'SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE_ERROR';
