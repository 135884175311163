import React from "react";
import "./SideMenuOption.scss";

type SideMenuOptionProps = {
  cssClassName?: string;
  label: string;
  translationKey: string;
  onClickFn: (translationKey: string) => void;
};

/** SideMenuOption component */
const SideMenuOption = (props: SideMenuOptionProps) => {
  let sideMenuOptionCssClassname = "sideMenuOption " + props.cssClassName;
  return (
    <a
      className={sideMenuOptionCssClassname}
      onClick={() => {
        props.onClickFn(props.translationKey);
      }}
    >
      {props.label}
    </a>
  );
};

export default SideMenuOption;
