import {
  REQUEST_ABOUT_THE_FARM_PAGE_INFORMATION,
  ABOUT_THE_FARM_PAGE_INFORMATION_SUCCESS,
  ABOUT_THE_FARM_PAGE_INFORMATION_ERROR,
  SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE,
  SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE_SUCCESS,
  SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE_ERROR,
} from './types';

/** Create action which will receive the text blocks for the selected farm
 * @param {string} accessToken - User access token
 * @return {Object} Returns action object
 */
export function requestAboutTheFarmPageInformation(accessToken) {
  return {
    type: REQUEST_ABOUT_THE_FARM_PAGE_INFORMATION,
    payload: {
      accessToken: accessToken,
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of the About the farm page in the Admin app
 * @param {Object} textBlocksDetails - an object containing the data to be used in order to initialize the About the farm page in the Admin app
 * @return {Object} Return type and farmDetails
 */
export function getAboutTheFarmInformationSuccess(textBlocksDetails) {
  return {
    type: ABOUT_THE_FARM_PAGE_INFORMATION_SUCCESS,
    payload: textBlocksDetails,
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getAboutTheFarmIformationError(errorMessage) {
  return {
    type: ABOUT_THE_FARM_PAGE_INFORMATION_ERROR,
    payload: errorMessage,
  };
}

/** Create action which to be used whenever the user clicks the Save changes button in the About the farm page in the Admin app
 * @param {string} accessToken - User access token
 * @param {Object} newAboutTheFarmDetails - The latest state of the about the farm details (some new text blocks might be added, the contents of existing ones might have been changed)
 * @param {Object} newLanguageDetails - The latest state of the language selection control which needs to be persisted to the languages services as soon as the other request has been carried (both requests can't happen at the same time as there is concurrency issue with the database access)
 * @return {Object} Returns action object
 */
export function saveChangesToAboutTheFarmPage(
  accessToken,
  newAboutTheFarmDetails,
  newLanguageDetails
) {
  return {
    type: SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE,
    payload: {
      accessToken: accessToken,
      newAboutTheFarmDetails,
      newLanguageDetails
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of the About The Farm page in the Admin app
 * @return {Object} Returns action object
 */
export function saveChangesToAboutTheFarmPageSuccess() {
  return {
    type: SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE_SUCCESS,
    payload: {},
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function saveChangesToAboutTheFarmPageSuccessError(errorMessage) {
  return {
    type: SAVE_CHANGES_TO_ABOUT_THE_FARM_INFORMATION_PAGE_ERROR,
    payload: errorMessage,
  };
}
