import _ from "lodash";
import { ADULT_THEME_CODE } from "../common/constants";

export const getCowSvgFilePath = function (farmTheme, cowType) {
  if(!cowType) {
    cowType = 'cowHolsteinFriesian--one'
  }
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  if (farmTheme == ADULT_THEME_CODE) {
    return `cow/virtualFarmAvatars/adultThemeCow.svg`;
  }
  return `cow/virtualFarmAvatars/${cowType}.svg`;
};

// export const getCowPopupSvgFilePath = function () {
//   return `cowlist/cow-list-popup-head-11.svg`;
// };

export const getCheeseFactorySvgFilePath = function (farmTheme) {
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  return `cheesefactory/cheese-factory.svg`;
};

export const getBackgroundLayerSvgFilePath = function (
  farmTheme,
  backgroundLayerId,
) {
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  return `backgroundLayers/${backgroundLayerId}.svg`;
};

export const getAstronautFactorySvgFilePath = function (farmTheme) {
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  return `astronautfactory/astronaut-landing-page.svg`;
};
export const getLunaFactorySvgFilePath = function (farmTheme) {
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  return `luna/lunaRobot.svg`;
};

export const getDiscoverySvgFilePath = function (farmTheme) {
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  return `discovery/discoveryRobot.svg`;
}; 
export const getDiscoverySwSvgFilePath = function (farmTheme) {
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  return `discovery/discoveryRobotSw.svg`;
};

export const getGrazewaySvgFilePath = function (farmTheme) {
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  return `grazeway/grazeway-feature.svg`;
};

export const getIceCreamFactorySvgFilePath = function (farmTheme) {
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  return `icecreamfactory/ice-cream-factory.svg`;
};

export const getDairyFactorySvgFilePath = function (farmTheme) {
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  return `dairyfactory/dairy-robot-factory.svg`;
};

export const getMilkFactorySvgFilePath = function (farmTheme) {
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  return `milkfactory/milk-factory-new.svg`;
};


export const getFarmerSvgFilePath = function (farmTheme, farmerGender) {
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  console.log('_.isEmpty(farmTheme)', _.isEmpty(farmTheme))
  return `farmer/farmer-${farmerGender}.svg`;
};
export const getCowPopupSvgFilePath = function (farmTheme, cowBreed) {
  farmTheme = _.isEmpty(farmTheme) ? "lely" : farmTheme;
  cowBreed = _.isEmpty(cowBreed) ? "holstein-white" : cowBreed;
  return `cow/popup/heads/cow-list-popup-head-${cowBreed}.svg`;
}; 

export const getMijnMelkPopupSvgFilePath = function(farmId, batchProductType){
  let mijnMelkPopupFarmId = ''
  if (farmId == "A4D1442E459E" && (batchProductType?.includes('Melk') || batchProductType?.includes('Milk'))) {
    mijnMelkPopupFarmId = "dobblehoeve--milk";
  }else if(farmId == "A4D1442E459E" && (batchProductType?.includes('Volle Yoghurt'))){
    mijnMelkPopupFarmId = "dobblehoeve--yogurt--volle"; 
  }else if(farmId == "A4D1442E459E" && (batchProductType?.includes('Yoghurt') || batchProductType?.includes('Yogurt'))){
    mijnMelkPopupFarmId = "dobblehoeve--yogurt"; 
  }else if((farmId == "A4D1442E459E")){
    mijnMelkPopupFarmId = "dobblehoeve--milk";
  }else if(farmId == "8D8F970C4BB1" && (batchProductType?.includes('Volle Yoghurt'))){
    mijnMelkPopupFarmId = "oostdam--yogurt--volle"; 
  }else if(farmId == "8D8F970C4BB1" && (batchProductType?.includes('Yoghurt') || batchProductType?.includes('Yogurt'))){
    mijnMelkPopupFarmId = "oostdam--yogurt"; 
  }else if(farmId == "8D8F970C4BB1" && (batchProductType?.includes('Milk') || batchProductType?.includes('Melk'))){
    mijnMelkPopupFarmId = "oostdam--milk"; 
  }else if(farmId == "8D8F970C4BB1"){
    mijnMelkPopupFarmId = "oostdam--milk"; 
  }else if(farmId == "9FCA2FEE4AB3"){
    mijnMelkPopupFarmId = "rotgans--milk"; 
  }else if(farmId == '92FCED8D4F0B' && (batchProductType?.includes('Volle Yoghurt'))){
    mijnMelkPopupFarmId = 'brosa--yoghurt--volle';
  }else if(farmId == '92FCED8D4F0B' && (batchProductType?.includes('Yoghurt') || batchProductType?.includes('Yogurt'))){
    mijnMelkPopupFarmId = 'brosa--yoghurt';
  }else if(farmId == '92FCED8D4F0B' && (batchProductType?.includes('Milk') || batchProductType?.includes('Melk'))){
    mijnMelkPopupFarmId = 'brosa--milk';
  }else if(farmId == '92FCED8D4F0B'){
    mijnMelkPopupFarmId = 'brosa--milk';
  }else if(farmId == "9643297842C4"){
    mijnMelkPopupFarmId = "schutte"
  }else {
    mijnMelkPopupFarmId = "dobblehoeve--milk"
  } 
  return mijnMelkPopupFarmId;
}
