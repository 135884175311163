import React from "react";
import "./Flame.scss";


function Flame() {
    return <svg className="flame" preserveAspectRatio='none' viewBox="0 0 155 223" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="flame-outer" xmlns="http://www.w3.org/2000/svg" d="M81.0316 17.7249C81.0316 21.9987 77.567 25.4634 73.2932 25.4634C69.0193 25.4634 65.5547 21.9987 65.5547 17.7249C65.5547 13.4511 73.2932 0.463379 73.2932 0.463379C73.2932 0.463379 81.0316 13.4511 81.0316 17.7249Z" fill="#FABE1F" />
        <path className="flame-middle" xmlns="http://www.w3.org/2000/svg" d="M78.624 20.4636C78.624 23.225 76.3854 25.4636 73.624 25.4636C70.8626 25.4636 68.624 23.225 68.624 20.4636C68.624 17.7022 73.624 9.31055 73.624 9.31055C73.624 9.31055 78.624 17.7022 78.624 20.4636Z" fill="#EA6300" />
        <path className="flame-inner" xmlns="http://www.w3.org/2000/svg" d="M76.0488 22.9104C76.0488 24.278 74.9401 25.3867 73.5725 25.3867C72.2049 25.3867 71.0962 24.278 71.0962 22.9104C71.0962 21.5428 73.5725 17.3867 73.5725 17.3867C73.5725 17.3867 76.0488 21.5428 76.0488 22.9104Z" fill="#C30A14" />
    </svg>;
}

export default Flame;
