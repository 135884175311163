import { RESEND_VERIFICATION_EMAIL_REQUEST } from "./types.js";
import {
  resendVerificationEmailSuccess,
  resendVerificationEmailError,
} from "./actions.js";
import { put, take, call } from "redux-saga/effects";
import { redirect } from "../../CustomRedirect/actions";
import { getServerResponseStatus } from "../../ErrorPopUp/actions";
import {
  startLoading,
  endLoading,
} from "../../../common/redux/loadManager/actions";
import { getLoadId } from "../../../common/redux/loadManager/methods";

let currentLoadId = 0;

export default function* resendVerificationEmailSaga(api) {
  while (true) {
    const resendVerificationEmailUserRequest = yield take(
      RESEND_VERIFICATION_EMAIL_REQUEST,
    );
    if (resendVerificationEmailUserRequest.payload) {
      /** Run loader */
      currentLoadId = getLoadId();
      yield put(startLoading(currentLoadId));
      const { emailAddress } = resendVerificationEmailUserRequest.payload;
      yield call(resendVerificationEmail, api, emailAddress);
    }
  }
}

/** Create request for resending verification e-mail to the user
 * @param {object} api - API providing access to the Lely backend
 * @param {string} emailAddress - E-mail address of the user where the verification e-mail needs to be re-sent
 */
function* resendVerificationEmail(api, emailAddress) {
  var response;
  try {
    response = yield call(api.resendVerificationEmail, emailAddress);
    yield put(resendVerificationEmailSuccess());
    yield put(getServerResponseStatus(""));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    yield put(resendVerificationEmailError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
    /** Listen for error state to show when server is disconected */
    yield put(getServerResponseStatus(error));
  }
}
