import {
  REQUEST_FARMER_PAGE_INFORMATION,
  FARMER_PAGE_INFORMATION_SUCCESS,
  FARMER_PAGE_INFORMATION_ERROR,
  SAVE_CHANGES_TO_FARMER_PAGE,
  SAVE_CHANGES_TO_FARMER_PAGE_SUCCESS,
  SAVE_CHANGES_TO_FARMER_PAGE_ERROR,
} from './types';

/** Create action which will receive the text blocks for the selected farmer
 * @param {string} accessToken - User access token
 * @return {Object} Returns action object
 */
export function requestFarmerPageInformation(accessToken) {
  return {
    type: REQUEST_FARMER_PAGE_INFORMATION,
    payload: {
      accessToken: accessToken,
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of the Farmer page in the Admin app
 * @param {Object} textBlocksDetails - an object containing the data to be used in order to initialize the Farmer page in the Admin app
 * @return {Object} Return type and farmerDetails
 */
export function getFarmerInformationSuccess(textBlocksDetails) {
  return {
    type: FARMER_PAGE_INFORMATION_SUCCESS,
    payload: textBlocksDetails,
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getFarmerInformationError(errorMessage) {
  return {
    type: FARMER_PAGE_INFORMATION_ERROR,
    payload: errorMessage,
  };
}

/** Create action which to be used whenever the user clicks the Save changes button in the Farmer page in the Admin app
 * @param {string} accessToken - User access token
 * @param {Object} newFarmerDetails - The latest state of farmer details page (with all the latest changes that the user has made)
 * @param {Object} newLanguageDetails - The latest state of the language selection control which needs to be persisted to the languages services as soon as the other request has been carried (both requests can't happen at the same time as there is concurrency issue with the database access)
 * @return {Object} Returns action object
 */
export function saveChangesToFarmerPage(accessToken, newFarmerDetails, newLanguageDetails) {
  return {
    type: SAVE_CHANGES_TO_FARMER_PAGE,
    payload: {
      accessToken: accessToken,
      newFarmerDetails: newFarmerDetails,
      newLanguageDetails: newLanguageDetails
    },
  };
}

/** Create action which to be intercepted by the reducer and store information about the initial state of the Farmer page in the Admin app
 * @return {Object} Returns action object
 */
export function saveChangesToFarmerPageSuccess() {
  return {
    type: SAVE_CHANGES_TO_FARMER_PAGE_SUCCESS,
    payload: {},
  };
}

/** Create action which receives as an argument errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function saveChangesToFarmerPageSuccessError(errorMessage) {
  return {
    type: SAVE_CHANGES_TO_FARMER_PAGE_ERROR,
    payload: errorMessage,
  };
}
