import Sky from "../../Sky/Sky";
import Sun from "../../Sun/Sun";
import Moon from "../../Moon/Moon";
import Weather from "../../Weather/Weather";
import MistOverlay from "../../Weather/MistOverlay";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import BackgroundLayer from "../../BackgroundLayer/BackgroundLayer";
import { getBackgroundLayerTranslateXInPx } from "../parallax_util";
import "./ParallaxCowDaily.scss";
import {
  WEATHER_TYPE_SNOW,
  WEATHER_TYPE_SNOWSTORM,
} from "../../../common/constants";
import Clouds from "../../Weather/Clouds";
import WindmillGenerator from "../../Windmills/WindmillGenerator";

export const NUMBER_OF_SLIDES_FOR_MILKING = 2;

function ParallaxCowDaily({
  numberOfSlides,
  slideWidthInPx,
  slideHeightInPx,
  translateX,
  startX,
  endX,
  cowDailyDetails,
  cowGrazingData,
  isAdultThemeApplied,
}) {
  const BACKGROUND_LAYER_MOUNTAIN_MOVEMENT_AMOUNT = 100;
  const BACKGROUND_LAYER_STARS_MOVEMENT_AMOUNT = 200;
  const BACKGROUND_LAYER_CLOUDS_MOVEMENT_AMOUNT = 200;
  const BACKGROUND_LAYER_HILLS_1_MOVEMENT_AMOUNT =
    (numberOfSlides * slideWidthInPx) / 4;
  const BACKGROUND_LAYER_HILLS_2_MOVEMENT_AMOUNT =
    (numberOfSlides * slideWidthInPx) / 4;
  const BACKGROUND_LAYER_WINDMILLS_MOVEMENT_AMOUNT = endX - startX;
  const BACKGROUND_LAYER_TREES_AND_HOUSES_MOVEMENT_AMOUNT =
    (numberOfSlides * slideWidthInPx) / 2.05;
  const BACKGROUND_LAYER_SLIDE_BACKGROUND_MOVEMENT_AMOUNT = endX - startX;
  const BACKGROUND_LAYER_GRASS_MOVEMENT_AMOUNT = endX - startX;

  useEffect(() => {}, [numberOfSlides]);

  if (translateX < startX || translateX > endX) {
    return null;
  }

  let cssClassName = "parallax-cow-daily";

  function getForegroundBackgroundLayerClassNameVarietiesByCowDailyDetails(
    cowDailyDetails,
  ) {
    let introClasses = [
      "background-pcd-grass6",
      // 'background-pcd-grass5',
      // 'background-pcd-grass3',
    ]; // intro
    const comparedDate = moment(
      new Date(cowDailyDetails && cowDailyDetails.lastUpdated),
    ).format("D MMM YYYY");
    const isBetweenDate = moment(comparedDate).isBetween(
      cowGrazingData.dateFrom,
      cowGrazingData.dateTo,
    );
    if (cowGrazingData.flag && isBetweenDate) {
      introClasses.push("background-pcd-grass5", "background-pcd-grass3");
    } else if (cowGrazingData.flag && !isBetweenDate) {
      introClasses.push("background-pcd-eating1", "background-pcd-eating3");
    }
    let eatingClasses = [
      // eating
      "background-pcd-eating1",
      // "background-pcd-eating2",
      "background-pcd-eating3",
    ];
    let milkingClasses = []; // milking
    cowDailyDetails &&
      cowDailyDetails.milkings &&
      cowDailyDetails.milkings.forEach((currentMilkingData) => {
        milkingClasses.push("background-pcd-milking1");
        milkingClasses.push("background-pcd-milking2");
      });
    let ruminationClasses = [];
    if (
      cowDailyDetails &&
      cowDailyDetails.ruminationMinutes &&
      cowDailyDetails.ruminationMinutes > 0
    ) {
      ruminationClasses.push("background-pcd-resting1");
      ruminationClasses.push("background-pcd-resting3");
    } // rumination
    let restingClasses = [];
    if (
      cowDailyDetails &&
      cowDailyDetails.restingMinutes &&
      cowDailyDetails.restingMinutes > 0
    ) {
      restingClasses.push("background-pcd-resting1");
      //restingClasses.push("background-pcd-resting2");
      restingClasses.push("background-pcd-resting3");
    } // resting
    let outroClasses = ["background-pcd-grass5"];

    const isThereMilking = milkingClasses.length > 0;
    const isThereSecondMilking =
      milkingClasses.length > NUMBER_OF_SLIDES_FOR_MILKING;
    const isThereThreeOrMoreMilkings =
      milkingClasses.length > NUMBER_OF_SLIDES_FOR_MILKING * 2;

    //#region composite the background layers for the Daily Cow story with all of their different background images
    let backgroundClasses = [...introClasses]; // intro
    if (isThereMilking) {
      // first milking
      backgroundClasses = [
        ...backgroundClasses,
        ...milkingClasses.slice(0, NUMBER_OF_SLIDES_FOR_MILKING),
      ];
    }
    let isThereEnoughInformationAboutEating =
      cowDailyDetails.eatingMinutes !== null &&
      cowDailyDetails.eatingMinutes > 0;
    if (isThereEnoughInformationAboutEating) {
      backgroundClasses = [...backgroundClasses, ...eatingClasses]; // eating
    }
    if (isThereSecondMilking) {
      // second milking
      backgroundClasses = [
        ...backgroundClasses,
        ...milkingClasses.slice(
          NUMBER_OF_SLIDES_FOR_MILKING,
          NUMBER_OF_SLIDES_FOR_MILKING * 2,
        ),
      ];
    }
    backgroundClasses = [...backgroundClasses, ...ruminationClasses]; // rumination
    if (isThereThreeOrMoreMilkings) {
      // third milking and beyond
      backgroundClasses = [
        ...backgroundClasses,
        ...milkingClasses.slice(NUMBER_OF_SLIDES_FOR_MILKING * 2),
      ];
    }
    backgroundClasses = [...backgroundClasses, ...restingClasses]; // resting
    backgroundClasses = [...backgroundClasses, ...outroClasses]; // outro
    //#endregion

    return backgroundClasses;
  }

  function getGrassBackgroundLayerClassNameVarietiesByCowDailyDetails(
    cowDailyDetails,
  ) {
    let backgroundVarieties = [
      "background-grass1",
      "background-pcd-grass3",
      "background-grass4",
    ];
    for (var i = 0; i < numberOfSlides - 3; i++) {
      backgroundVarieties.push("background-grass1");
    }
    return backgroundVarieties;
  }

  const HILLS_CLASSNAME_VARIETIES = [
    "background-hills11",
    "background-hills12",
    "background-hills13",
    "background-hills14",
    "background-hills15",
  ];
  const HILLS_CLASSNAME_VARIETIES_WEATHER_SNOW = [
    "background-hills11-snow",
    "background-hills12-snow",
    "background-hills13-snow",
    "background-hills14-snow",
    "background-hills15-snow",
  ];

  function getHills1BackgroundLayerClassNameVarietiesByCowDailyDetails(
    cowDailyDetails,
  ) {
    let foregroundClassNames =
      getForegroundBackgroundLayerClassNameVarietiesByCowDailyDetails(
        cowDailyDetails,
      );

    let hillsClassNames = foregroundClassNames.map(
      (currentClassname, currentClassnameIndex) => {
        return HILLS_CLASSNAME_VARIETIES[
          currentClassnameIndex % HILLS_CLASSNAME_VARIETIES.length
        ];
      },
    );
    let currentMilkingIndex = 0;
    foregroundClassNames.forEach((currentClassname, currentClassnameIndex) => {
      let isMilkingClassname = currentClassname.indexOf("milking") !== -1;
      if (isMilkingClassname) {
        let currentMilkingData =
          cowDailyDetails &&
          cowDailyDetails.milkings &&
          cowDailyDetails.milkings[currentMilkingIndex];
        let isSnowingDuringRespectiveMilking =
          currentMilkingData.weatherString === WEATHER_TYPE_SNOW ||
          currentMilkingData.weatherString === WEATHER_TYPE_SNOWSTORM;
        if (isSnowingDuringRespectiveMilking) {
          hillsClassNames[currentClassnameIndex] =
            HILLS_CLASSNAME_VARIETIES_WEATHER_SNOW[0];
        }
      }
    });
    return hillsClassNames;
  }
  let hills1BackgroundClassNameVarieties =
    getHills1BackgroundLayerClassNameVarietiesByCowDailyDetails(
      cowDailyDetails,
    );

  return (
    <div className={cssClassName}>
      <BackgroundLayer
        backgroundClassNameVarietiesArray={[
          "background-pcd-stars",
          "background-pcd-stars",
        ]}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_STARS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--stars"
      />
      <BackgroundLayer
        backgroundClassNameVarietiesArray={[
          //"background-pcd-sky1",
          //"background-pcd-sky2",
          "test1",
          "test2",
        ]}
        slideWidthInPx={slideWidthInPx}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_CLOUDS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--sky"
      >
        <Sky
          viewportWidthInPx={slideWidthInPx}
          viewportHeightInPx={slideHeightInPx}
        />
      </BackgroundLayer>
      <BackgroundLayer
        backgroundClassNameVarietiesArray={["test1", "test2"]}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_CLOUDS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--moon"
      >
        <Moon
          viewportWidthInPx={slideWidthInPx}
          viewportHeightInPx={slideHeightInPx}
        />
      </BackgroundLayer>
      <BackgroundLayer
        backgroundClassNameVarietiesArray={["test1", "test2"]}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_CLOUDS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--sun"
      >
        <Sun
          viewportWidthInPx={slideWidthInPx}
          viewportHeightInPx={slideHeightInPx}
        />
      </BackgroundLayer>

      <BackgroundLayer
        backgroundClassNameVarietiesArray={[
          "background-pcd-clouds1",
          "background-pcd-clouds2",
          "background-pcd-clouds3",
        ]}
        slideWidthInPx={slideWidthInPx}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_CLOUDS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--clouds"
      >
        <Clouds
          viewportWidthInPx={slideWidthInPx}
          viewportHeightInPx={slideHeightInPx}
        />
      </BackgroundLayer>
      <BackgroundLayer
        backgroundClassNameVarietiesArray={hills1BackgroundClassNameVarieties}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_HILLS_1_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--hills1"
      />
      <BackgroundLayer
        backgroundClassNameVarietiesArray={[]}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_WINDMILLS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--windmills"
      >
        <WindmillGenerator
          numberOfSlides={numberOfSlides}
          cowDailyDetails={cowDailyDetails}
          slideWidthInPx={slideWidthInPx}
        />
      </BackgroundLayer>
      <BackgroundLayer
        backgroundClassNameVarietiesArray={[
          "background-hills21",
          "background-hills22",
          "background-hills23",
          "background-hills24",
          "background-hills25",
        ]}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_HILLS_2_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--hills2"
      />
      <BackgroundLayer
        backgroundClassNameVarietiesArray={["test1", "test2"]}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_CLOUDS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--weather"
      >
        <Weather
          viewportWidthInPx={slideWidthInPx}
          viewportHeightInPx={slideHeightInPx}
        />
      </BackgroundLayer>
      <BackgroundLayer
        backgroundClassNameVarietiesArray={getGrassBackgroundLayerClassNameVarietiesByCowDailyDetails(
          cowDailyDetails,
        )}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_GRASS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--grass"
      />
      <BackgroundLayer
        backgroundClassNameVarietiesArray={getForegroundBackgroundLayerClassNameVarietiesByCowDailyDetails(
          cowDailyDetails,
        )}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_SLIDE_BACKGROUND_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--rumination"
      />
      <BackgroundLayer
        backgroundClassNameVarietiesArray={["test1", "test2"]}
        xPosition={`${getBackgroundLayerTranslateXInPx(
          translateX,
          BACKGROUND_LAYER_CLOUDS_MOVEMENT_AMOUNT,
          startX,
          endX,
        )}px`}
        slideWidthInPx={slideWidthInPx}
        slidesCount={numberOfSlides}
        customCssClassName="background-layer--mist"
      >
        <MistOverlay
          viewportWidthInPx={slideWidthInPx}
          viewportHeightInPx={slideHeightInPx}
        />
      </BackgroundLayer>
    </div>
  );
}

export default ParallaxCowDaily;
