import React from "react";
import "./SecondSlideLuna.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const SecondSlideLuna = ({}) => {
  return (
    <div className="lunaStorySecondSlide">
      <TextCaption additionalCssClassname="lunaStorySecondSlide--textCaption">
        <div className="lunaStorySecondSlide--title">
          {translate("LUNA_ROBOT_STORY_SECOND_SLIDE_TITLE")}
        </div>
        <div className="lunaStorySecondSlide--text">
          {translate("LUNA_ROBOT_STORY_SECOND_SLIDE_INFO")}
        </div>
      </TextCaption>
      <div className="discoveryStory--bottomColorOverlay"></div>
      <div className="lunaStorySecondSlide--imgCaption">
        <div className="lunaStorySecondSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default SecondSlideLuna;
