import _ from 'lodash';
import { SHOW_COW_EXCREMENT, HIDE_COW_EXCREMENT } from '../components/Cow/types.js';

/** Store states
 * @constant {Object} */
export const initialState = {
  cowExcrements: []
};

/** Excrement Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function excrementReducer(state = initialState, action) {
  let modifiedState = _.cloneDeep(state);
  switch (action.type) {
    case SHOW_COW_EXCREMENT:
      modifiedState.cowExcrements = [action.payload.cowId];
      return modifiedState;
    case HIDE_COW_EXCREMENT:
      modifiedState.cowExcrements = modifiedState.cowExcrements.filter(currentCowId => (currentCowId !== action.payload.cowId));
      return modifiedState;
    default:
      return state;
  }
}

export default excrementReducer;
