import React from 'react';
import { logUserActivity } from '../../activity';

export default function ActivityTracker({children}) {
  return (
    <div onClick={() => {
        // currently we are using mouse click events as an
        // indication of overall user activity in the application
        // all application components are contained within that div element
        // so we will not miss any click activity related to them by
        // having this click handler put here
        logUserActivity();
      }}
    >
      {children}
    </div>
  );
}
