import React, { useRef } from "react";
import {translate,translateNumber,translateWithValues,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import "./ThirdSlideSustainability.scss";
import ReferenceValueChart from "../../components/ReferenceValueChart/ReferenceValueChart";
import {
  SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MAX_COLOR,
  SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MID_COLOR,
  SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MIN_COLOR,
  SUSTAINABILITY_CHART_FOR_ADULTS_STROKE_WIDTH,
} from "../../common/constants";
import { translateDynamic } from "../../localization/TranslationUtil";
import useWindowDimensions from "../../hooks/useViewportDimensions";

const ThirdSlideSustainability = ({ farmDetails }) => {
  //#region initialize viewport width value in order to be able to calculate properly the size of the sustainability diagram
  let { width: VIEWPORT_WIDTH_IN_PX, height: VIEWPORT_HEIGHT_IN_PX } =
    useWindowDimensions();
  const viewportWidthInPxRef = useRef(VIEWPORT_WIDTH_IN_PX);
  const viewportHeightInPxRef = useRef(VIEWPORT_HEIGHT_IN_PX);
  let isAppOpenedInLandscapeMode = VIEWPORT_WIDTH_IN_PX > VIEWPORT_HEIGHT_IN_PX;
  VIEWPORT_WIDTH_IN_PX = isAppOpenedInLandscapeMode
    ? VIEWPORT_WIDTH_IN_PX
    : VIEWPORT_HEIGHT_IN_PX;
  VIEWPORT_HEIGHT_IN_PX = isAppOpenedInLandscapeMode
    ? VIEWPORT_HEIGHT_IN_PX
    : VIEWPORT_WIDTH_IN_PX;
  viewportWidthInPxRef.current = VIEWPORT_WIDTH_IN_PX;
  viewportHeightInPxRef.current = VIEWPORT_HEIGHT_IN_PX;
  const diagramContainerWidthInPixels = 0.2 * viewportWidthInPxRef.current;
  const diagramRadiusInPixels = (1 / 3) * diagramContainerWidthInPixels;
  const diagramStrokeSizeInPixels = 0.02 * diagramContainerWidthInPixels;
  //#endregion

  const farmerName = translateDynamic(farmDetails?.farmer?.name);
  const farmName = translateDynamic(farmDetails?.farmName);
  const concentratesStory = translateDynamic(
    farmDetails?.sustainabilityData?.concentratesStory,
  );

  return (
    <div className="sustainabilityStoryThirdSlide">
      <ReferenceValueChart
        currentValue={(farmDetails?.sustainabilityData?.gramsConcentratesPerLiterOfMilk).toFixed(2)}
        referenceValue={translateNumber(
          "SUSTAINABILITY_CONCENTRATE_PER_LITER_MILK_RANGE_REFERENCE",
        )}
        rangeMinValue={translateNumber(
          "SUSTAINABILITY_CONCENTRATE_PER_LITER_MILK_RANGE_MIN",
        )}
        rangeMaxValue={translateNumber(
          "SUSTAINABILITY_CONCENTRATE_PER_LITER_MILK_RANGE_MAX",
        )}
        rangeMinColor={SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MIN_COLOR}
        rangeMidColor={SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MID_COLOR}
        rangeMaxColor={SUSTAINABILITY_CHART_FOR_ADULTS_RANGE_MAX_COLOR}
        unitText={translate("SUSTAINABILITY_CONCENTRATE_PER_LITER_MILK_UNIT")}
        labelText={translate(
          "SUSTAINABILITY_CONCENTRATE_PER_LITER_MILK_DIAGRAM_LABEL",
        )}
        referenceLabelText={translate("SUSTAINABILITY_AVG_FARM_TEXT")}
        currentLabelText={farmName}
        diagramContainerWidthInPixels={diagramContainerWidthInPixels}
        diagramContainerHeightInPixels={diagramContainerWidthInPixels}
        diagramRadiusInPixels={diagramRadiusInPixels}
        gradientMeterStrokeWidthInPixels={diagramStrokeSizeInPixels}
        cssClassname={"gramsOfConcentratePerLiterMilkChart"}
      />
      <TextCaption additionalCssClassname="sustainabilityStoryThirdSlide--textCaption">
        <div className="sustainabilityStoryThirdSlide-title">
          {translate("SUSTAINABILITY_TITLE_THIRD_SLIDE")}
        </div>
        <div className="sustainabilityStoryThirdSlide--text">
          {translate("SUSTAINABILITY_TEXT_THIRD_SLIDE")}
        </div>
        {concentratesStory ? (
          <div>
            <div className="sustainabilityStoryThirdSlide-farmer-title">
              {translateWithValues("SUSTAINABILITY_FARMER_NAME_TITLE", {
                farmerName,
              })}
            </div>
            <div className="sustainabilityStoryThirdSlideFarmer--text">
              {concentratesStory}
            </div>
          </div>
        ) : null}
        <a
          className="sustainabilityStoryThirdSlide--link"
          href="https://www.melkvee.nl/artikel/407716-koeien-en-kansen-bedrijven-halen-gemiddeld-55-procent-eiwit-van-eigen-land/"
          target="_blank"
        >
          {translate("SUSTAINABILITY_THIRD_SLIDE_LINK")}
        </a>
      </TextCaption>
      <div className="sustainabilityStory-treeAndSmallBush"></div>
    </div>
  );
};

export default ThirdSlideSustainability;
