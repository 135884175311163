import {
  TELL_ME_MORE_PAGE_INFORMATION_ERROR,
  TELL_ME_MORE_PAGE_INFORMATION_SUCCESS,
  SAVE_CHANGES_TO_TELL_ME_MORE_PAGE_SUCCESS,
  SAVE_CHANGES_TO_TELL_ME_MORE_PAGE_ERROR,
} from '../containers/Admin/TellMoreAboutMe/types.js';

/** Store states
 * @constant {Object} */
export const initialState = {
  textBlocksData: '',
  errorMessage: '',
  isLatestSaveSuccessful: false,
  saveChangesErrorMessage: '',
};

/** Tell me more page reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function tellMeMoreReducer(state = initialState, action) {
  switch (action.type) {
    case TELL_ME_MORE_PAGE_INFORMATION_SUCCESS:
      return {
        ...state,
        textBlocksData: action.payload,
        errorMessage: '',
      };
    case TELL_ME_MORE_PAGE_INFORMATION_ERROR:
      return {
        ...state,
        textBlocksData: [],
        errorMessage: action.payload,
      };
    case SAVE_CHANGES_TO_TELL_ME_MORE_PAGE_SUCCESS:
      return {
        ...state,
        isLatestSaveSuccessful: true,
        saveChangesErrorMessage: '',
      };
    case SAVE_CHANGES_TO_TELL_ME_MORE_PAGE_ERROR:
      return {
        ...state,
        isLatestSaveSuccessful: false,
        saveChangesErrorMessage: action.payload,
      };

    default:
      return state;
  }
}

export default tellMeMoreReducer;
