import React from "react";
import "./LastSlideLelySlide.scss";
import { translate } from "../../localization/TranslationUtil";
import PoleComponent from "../../common/components/PoleComponentEndingSlides/PoleComponent";

const LastSlideLelySlide = ({ onNavigateToInitialSlideFn }) => {
  const goToLelySite = translate("REDIRECT_TO_LELY_WEB");

  return (
    <div className="lelyStoryLastSlide">
      <div className="lelyStoryLastSlide--redirectionLely">
        <div
          className="lelyStoryLastSlide--secondText"
          onClick={() => window.open("https://lely.com")}
        >
          {goToLelySite}
        </div>
      </div>
      <PoleComponent onNavigateToInitialSlideFn={onNavigateToInitialSlideFn} />
    </div>
  );
};

export default LastSlideLelySlide;
