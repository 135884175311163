import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';

// Styles
import './searchStyle.scss';

/** Search component.
 * @param {Object} obj - Object which contains all props.
 * @param {function} [obj.search] - Pass current value of input to parent.
 * @param {string} [obj.searchInputText] - Clear search
 * @param {string} [obj.tooltip] - Text for tooltip
 * @param {string} obj.placeholder - Search placeholder
 * @param {function} [obj.onUpdate] - Do something on press event
 * @param {string} [obj.errorMessage] - Error message
 */
function Search({
  search,
  searchInputText,
  tooltip,
  errorMessage,
  placeholder,
  onUpdate,
}) {
  /** Search input value */
  const [value, setValue] = useState(searchInputText || '');
  const [errorMsg, setErrorMsg] = useState(errorMessage || '');

  // Update error message
  useEffect(() => {
    setErrorMsg(errorMessage);
  }, [errorMessage]);

  /** If search input value is change update the state
   * @param {Object} event - Contains current value of input filed.
   */
  function handleChange(event) {
    const value = event.target.value;
    setValue(value);
    if (search) {
      search(value);
    }
  }
  /** On keyDown press update parent
   * @param {Object} e - Contains data which button was pressed
   */
  function onKeyPress(e) {
    if (e.key === 'Enter') {
      if (onUpdate) {
        onUpdate(value);
        setValue('');
      }
    }
    //resetErrorMessage
    setErrorMsg('');
  }
  /** On click update parent */
  function onButtonPress() {
    if (onUpdate) {
      onUpdate(value);
      setValue('');
    }
  }

  return (
    <Tooltip title={tooltip == null ? '' : tooltip} placement="top">
      <div className="search-root">
        <div className="search-container">
          <input
            type="search"
            data-testid="txt-search"
            className="items-searchTerm"
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            onKeyDown={onKeyPress}
          />
          <button
            onClick={onButtonPress}
            className="btn btn-primary btn-search"
            type="button"
          >
            <i className="lely-icon-search"></i>
          </button>
        </div>
        <p className="search-error-message">{errorMsg}</p>
      </div>
    </Tooltip>
  );
}

export default Search;
