import React from "react";
import "./FourthSlideLely.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const FourthSlideLely = ({}) => {
  return (
    <div className="lelyStoryFourthSlide">
      <TextCaption additionalCssClassname="lelyStoryFourthSlide--textCaption">
        <div className="lelyStoryFourthSlide--title">
          {translate("LELY_FOURTH_SLIDE_TITLE")}
        </div>
        <div className="lelyStoryFourthSlide--text">
          {translate("LELY_FOURTH_SLIDE_TEXT")}
        </div>
      </TextCaption>
      <div className="lelyStoryFourthSlide--imgCaption">
        <div className="lelyStoryFourthSlide--imgContainer"></div>
      </div>
    </div>
  );
};

export default FourthSlideLely;
