import React from "react";

// Styles && Components
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import "./Preloader.scss";
// Images
//import waitingCow from '../../resources/images/waitingCow.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "fixed",
    opacity: 0.4,
    backgroundColor: "white",
    zIndex: 99999,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
/** Show Preloader on page*/
function Preloader({ isPreloaderActive, preloaderText }) {
  const classes = useStyles();

  var preloader = null;
  if (isPreloaderActive) {
    preloader = (
      <div className={classes.root}>
        <p className="cow-loading">{preloaderText}</p>
      </div>
    );
  }
  return preloader;
}

export default Preloader;
