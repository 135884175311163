import { CHANGE_LANGUAGE } from "./types";

/** Create action which will be executed when the user changes the language from the side menu
 * @param {string} newLanguageKey - The key for the new language in which to load the UI
 * @return {Object} Returns action object
 */
export function changeLanguage(newLanguageKey) {
  return {
    type: CHANGE_LANGUAGE,
    payload: {
      selectedLanguage: newLanguageKey,
    },
  };
}
