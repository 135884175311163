import React, { useRef, useEffect } from "react";
import "./AstronautAmountOfSeconds.scss";
import {
  translate,
  translateWithValues,
  translateWithValuesAndHtml,
} from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";
import MatrixBackground from "./MatrixBackground";
import MatrixColumn from "./MatrixColumn"; 
import { useSelector } from "react-redux";


const AstronautAmountOfSeconds = ({}) => { 
  const { astronautDetails } = useSelector((state) => state.astronautDetails);

  return (
    <div className="astronautAmountOfSeconds">
      <div className="astronautAmountOfSeconds--arrow"></div>
      <div className="astronautAmountOfSeconds--arrowBetweenSeconds"></div>
      <TextCaption additionalCssClassname="astronautAmountOfSeconds--leftTextCaption">
        <div className="astronautAmountOfSeconds--text">
          {translateWithValues("ASTRONAUT_LIVE_DATA_LEFT_TEXT_BOX", {
            astronautName : astronautDetails?.name
          })}
        </div>
      </TextCaption>
      <TextCaption additionalCssClassname="astronautAmountOfSeconds--middleTextCaption">
        <div
          className="astronautAmountOfSeconds--text"
          dangerouslySetInnerHTML={{
            __html: translateWithValuesAndHtml(
              "ASTRONAUT_LIVE_DATA_MIDDLE_TEXT_BOX",
            ),
          }}
        ></div>
      </TextCaption>
      <TextCaption additionalCssClassname="astronautAmountOfSeconds--hourTextCaption">
        <div className="astronautAmountOfSeconds--hourText">
          {translate("ASTRONAUT_LIVE_DATA_HOUR_TEXT_BOX")}
        </div>
      </TextCaption>
      <MatrixColumn
        cssClassName="testMatrixColumn"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
      <MatrixColumn
        cssClassName="testMatrixColumn2"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
      <MatrixColumn
        cssClassName="testMatrixColumn3"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
      <MatrixColumn
        cssClassName="testMatrixColumn4"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
      <MatrixColumn
        cssClassName="testMatrixColumn5"
        columnWidthInPixels={Math.ceil(0.02 * document.body.offsetWidth)}
        timeout={50}
      />
    </div>
  );
};

export default AstronautAmountOfSeconds;
