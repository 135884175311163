import {
  REQUEST_SUSTAINABILITY_PAGE_INFORMATION,
  SAVE_CHANGES_TO_SUSTAINABILITY_PAGE,
} from "./types.js";
import {
  getSustainabilityPageSuccess,
  getSustainabilityPageError,
  saveChangesToSustainabilityPageSuccess,
  saveChangesToSustainabilityPageError,
} from "./actions.js";
import { put, take, call } from "redux-saga/effects";
import {
  startLoading,
  endLoading,
} from "../../../common/redux/loadManager/actions";
import { getLoadId } from "../../../common/redux/loadManager/methods";
import { saveChangesToSelectedFarmLanguages } from "../../../common/components/LanguageSelection/actions.js";

let currentLoadId = 0;

export function* initializeSustainabilitySaga(api) {
  while (true) {
    const sustainabilityInformationRequestAction = yield take(
      REQUEST_SUSTAINABILITY_PAGE_INFORMATION,
    );
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { accessToken } = sustainabilityInformationRequestAction.payload;
    yield call(getSustainabilityDetails, api, accessToken);
  }
}

/** Create request to get sustainability page latest state of data from the backend
 * @param {string} accessToken - User access token
 */
function* getSustainabilityDetails(api, accessToken) {
  try {
    const sustainabilityDetails = yield call(
      api.getSustainabilityDetails,
      accessToken,
    );
    yield put(getSustainabilityPageSuccess(sustainabilityDetails.data));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) {
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(getSustainabilityPageError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}

export function* sustainabilitySaveChangesSaga(api) {
  while (true) {
    const saveChangesSustainabilityRequestAction = yield take(
      SAVE_CHANGES_TO_SUSTAINABILITY_PAGE,
    );
    /** Run loader */
    currentLoadId = getLoadId();
    yield put(startLoading(currentLoadId));
    const { accessToken, newSustainabilityDetails, newLanguageDetails } =
      saveChangesSustainabilityRequestAction.payload;
    yield call(
      saveChangesToSustainabilityPage,
      api,
      accessToken,
      newSustainabilityDetails,
      newLanguageDetails,
    );
  }
}

/** Create request to get farmer page latest state of data from the backend
 * @param {string} accessToken - User access token
 * @param {Object} newSustainabilityDetails - The latest state of the farm sustainability page (with the latest changes)
 * @param {Object} newLanguageDetails - The latest state of the selected farm languages that need to be persisted to the backend as soon as the first request is over
 */
function* saveChangesToSustainabilityPage(
  api,
  accessToken,
  newSustainabilityDetails,
  newLanguageDetails,
) { 
  try {
    const updateSustainabilityDataResponse = yield call(
      api.setSustainabilityDetails,
      accessToken,
      newSustainabilityDetails,
    );
    yield put(
      saveChangesToSustainabilityPageSuccess(
        updateSustainabilityDataResponse.data,
      ),
    );
    yield put(
      saveChangesToSelectedFarmLanguages(accessToken, newLanguageDetails),
    );
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  } catch (error) { 
    /** Error code parsed to string   */
    const errorCode = error && error.toString().substr(-3);
    yield put(saveChangesToSustainabilityPageError(error));
    /** Stop loader */
    yield put(endLoading(currentLoadId));
  }
}
