//#region saga imports
import { all } from "redux-saga/effects";
import loginSaga from "../components/UserManagement/LoginForm/saga.js";
import accessTokenSaga from "../components/AccessToken/saga.js";
import logoutSaga from "../components/LogoutButton/saga.js";
import errorCodeSaga from "../common/redux/errorCodeHandler/saga.js";
import googleAnalyticTrackingSaga from "../common/redux/googleAnalyticTracking/saga";
import applicationCacheSaga from "../common/components/CacheBust/saga";
import virtualFarmDetailsSaga from "../components/VirtualFarm/saga.js";
import cowNamePollingSaga from "../components/CowNameVoting/saga.js";
import cowDailyDetailsSaga from "../components/CowPopup/saga";
import astronautDetailsSaga from "../components/AstronautPopup/saga";
import udderDetailsSaga from "../common/components/AstronautCowDropdownMenu/saga";
//#endregion

//#region API imports
import { api as virtualFarmApi } from "../api/lelyBackend/virtualFarmApi";
//#endregion

//#region Mock API imports
import { api as mockLoginApi } from "../api/mockApi/loginApi";
import { api as mockLogoutApi } from "../api/mockApi/logoutApi";
import { api as mockVirtualFarmApi } from "../api/mockApi/virtualFarmApi";
//#endregion

//#region User Management imports
import activateUserSaga from "../components/UserManagement/AccountActivated/saga.js";
import registerUserSaga from "../components/UserManagement/CreateAccount/saga.js";
import recoverUserSaga from "../components/UserManagement/RecoverAccount/saga.js";
import resendVerificationEmailSaga from "../components/UserManagement/ResendVerificationEmail/saga.js";
import setNewPasswordSaga from "../components/UserManagement/SetNewPassword/saga.js";
//#endregion

//#region AdminApp imports
import globalSaveChangesSaga from "../common/components/SaveChangesManager/saga.js";

import {
  initalizeTellMeMorePageSaga,
  tellMeMoreSaveChangesSaga,
} from "../containers/Admin/TellMoreAboutMe/saga";

import {
  initalizeFarmerPageSaga,
  farmerSaveChangesSaga,
} from "../containers/Admin/AboutYouPage/saga";

import {
  initalizeAboutTheFarmPageSaga,
  aboutTheFarmSaveChangesSaga,
} from "../containers/Admin/AboutTheFarmPage/saga";

import {
  initalizeFarmFeaturesPageSaga,
  farmFeaturesSaveChangesSaga,
} from "../containers/Admin/FarmFeaturesPage/saga";
//#endregion

import {
  initalizeCowsPageSaga,
  cowsSaveChangesSaga,
} from "../containers/Admin/CowsPage/saga";

import {
  initializeAnimalsPageSaga,
  animalsSaveChangesSaga,
} from "../containers/Admin/AnimalsPage/saga";

import {
  farmLanguagesSaga,
  farmLanguagesSaveChangesSaga,
} from "../common/components/LanguageSelection/saga";

import {
  initializeSustainabilitySaga,
  sustainabilitySaveChangesSaga,
} from "../containers/Admin/SustainabilityPage/saga.js";
//#endregion

//#region AdminApp API imports
import { api as loginApi } from "../api/lelyBackend/loginApi";
import { api as logoutApi } from "../api/lelyBackend/logoutApi";
import { api as tellMeMoreApi } from "../api/lelyBackend/farmerApi";
import { api as farmerInfoApi } from "../api/lelyBackend/farmerInfoApi";
import { api as aboutTheFarmApi } from "../api/lelyBackend/aboutTheFarmApi";
import { api as cowsPageApi } from "../api/lelyBackend/cowsPageApi";
import { api as animalsPageApi } from "../api/lelyBackend/animalsApi";
import { api as farmFeaturesApi } from "../api/lelyBackend/farmFeaturesApi";
import { api as cowDailyDetailsApi } from "../api/lelyBackend/cowDailyDetailsApi";
import { api as farmLanguagesApi } from "../api/lelyBackend/languagesApi";
import { api as namePollsApi } from "../api/lelyBackend/namePollsApi";
import { api as astronautApi } from "../api/lelyBackend/astronautApi";
import { api as sustainabilityApi } from "../api/lelyBackend/sustainabilityApi";
import { api as usersApi } from "../api/lelyBackend/usersApi.js";
//#endregion

//#endregion

/** Root saga.
 * @return {Object} - return store
 */
function* rootSaga() {
  const envVariablesPassed = process.env;
  const doUseMocks = envVariablesPassed.REACT_APP_USE_MOCKS === "true";
  if (doUseMocks) {
    yield all([loginSaga(mockLoginApi), logoutSaga(mockLogoutApi)]);
  } else {
    yield all([
      loginSaga(loginApi),
      accessTokenSaga(loginApi),
      logoutSaga(logoutApi),
      errorCodeSaga(),
      googleAnalyticTrackingSaga(),
      applicationCacheSaga(),
      virtualFarmDetailsSaga(virtualFarmApi),
      initalizeTellMeMorePageSaga(tellMeMoreApi),
      tellMeMoreSaveChangesSaga(tellMeMoreApi),
      initalizeFarmerPageSaga(farmerInfoApi),
      farmerSaveChangesSaga(farmerInfoApi),
      initalizeAboutTheFarmPageSaga(aboutTheFarmApi),
      aboutTheFarmSaveChangesSaga(aboutTheFarmApi),
      initalizeFarmFeaturesPageSaga(farmFeaturesApi),
      farmFeaturesSaveChangesSaga(farmFeaturesApi),
      initalizeCowsPageSaga(cowsPageApi),
      cowsSaveChangesSaga(cowsPageApi),
      initializeAnimalsPageSaga(animalsPageApi),
      animalsSaveChangesSaga(animalsPageApi),
      cowDailyDetailsSaga(cowDailyDetailsApi),
      farmLanguagesSaga(farmLanguagesApi),
      farmLanguagesSaveChangesSaga(farmLanguagesApi),
      cowNamePollingSaga(namePollsApi),
      astronautDetailsSaga(astronautApi),
      udderDetailsSaga(astronautApi),
      initializeSustainabilitySaga(sustainabilityApi),
      sustainabilitySaveChangesSaga(sustainabilityApi),
      globalSaveChangesSaga(),
      activateUserSaga(usersApi),
      registerUserSaga(usersApi),
      recoverUserSaga(usersApi),
      resendVerificationEmailSaga(usersApi),
      setNewPasswordSaga(usersApi),
    ]);
  }
}

export default rootSaga;
