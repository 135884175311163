import React from "react";
import "./SixthSlideOrbiterYoghurt.scss";
import { translate } from "../../localization/TranslationUtil";
import TextCaption from "../../components/TextCaption/TextCaption";

const SixthSlideOrbiterYoghurt = ({}) => {
  return (
    <div className="orbiterStorySixthSlideYoghurt">
      <div className="orbiterStorySixthSlideYoghurt--floor"></div>
      <TextCaption additionalCssClassname="orbiterStorySixthSlideYoghurt--textCaption">
        <div className="orbiterStorySixthSlideYoghurt--text">
          {translate("ORBITER_SIXTH_SLIDE_YOGHURT_RIGHT_TEXT")}
        </div>
      </TextCaption>
      <TextCaption additionalCssClassname="orbiterStorySixthSlideYoghurt--secondTextCaption">
        <div className="orbiterStorySixthSlideYoghurt--text">
          {translate("ORBITER_SIXTH_SLIDE_YOGHURT_LEFT_TEXT")}
        </div>
      </TextCaption>
      <div className="orbiterStorySixthSlideYoghurt--table"></div>
      <div className="orbiterStorySixthSlideYoghurt--tableRightPart"></div>
      <div className="orbiterStorySixthSlideYoghurt--milkBottles"></div> 
      <div className="orbiterStorySixthSlideYoghurt--temperature"></div>
      <div className="orbiterStorySixthSlideYoghurt--pipe"></div>
      <div className="orbiterStorySixthSlideYoghurt--arrow"></div> 
    </div>
  );
};

export default SixthSlideOrbiterYoghurt;
