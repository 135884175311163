import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import useWindowDimensions from "../../hooks/useViewportDimensions";
import { useLocation } from "react-router";
import { translate } from "../../localization/TranslationUtil";
import "./MijnMelkOnboardingAdultContainer.scss";
import { ReactComponent as GrassSurface } from "../../resources/assets/themes/mijnmelk/16x9/onboarding/adult/landSurface.svg";
import moment from "moment";
import _ from "lodash";
import {
  getLatestSwipeDeltaX,
  setLatestSwipeDeltaX,
} from "../../common/globals";
import Graphic from "../../components/Graphic/Graphic";
import MijnMelkBottle from "./MijnMelkBottle";
import MijnMelkAdultOnboardingSlide1 from "./MijnMelkAdultOnboardingSlide1";
import MijnMelkAdultOnboardingSlide2 from "./MijnMelkAdultOnboardingSlide2";
import MijnMelkAdultOnboardingSlide3 from "./MijnMelkAdultOnboardingSlide3";
import MijnMelkAdultOnboardingSlide4 from "./MijnMelkAdultOnboardingSlide4";
import MilkBatchWeatherIcon from "./MilkBatchWeatherIcon";
import MilkBatchWeatherText from "./MilkBatchWeatherText";
import MilkBatchTemperatureIcon from "./MilkBatchTemperatureIcon";
import StoryNavigationHint from "../../common/components/StoryNavigationHint/StoryNavigationHint";

function MijnMelkOnboardingAdultContainer({
  farmName,
  farmerName,
  farmLocation,
  farmerStyle,
  farmStyle,
  onNavigateToInitialSlideFn,
  milkBatch,
  farmerImage,
  fatPercentage,
  proteinPercentage,
  lactosePercentage,
  totalNumberOfCowsInMilkBatch,
  numberOfMilkingsInPast24Hours,
  milkBatchStartDate,
  milkBatchEndDate,
  weatherData,
  currentFarmTime,
  milkGoingToBottleHour,
  otherDevices,
  batchProductType,
}) {
  const [currentSlideInfo, setCurrentSlideInfo] = useState({
    previous: -1,
    current: 0,
  });
  const MIJN_MELK_ADULT_ONBOARDING_SLIDES_CONFIG =
    batchProductType?.includes("Yogurt") ||
    batchProductType?.includes("Yoghurt")
      ? [
          {
            headerText: translate(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE1_HEADER_TEXT_YOGHURT",
            ),
          },
          {
            headerText: translate(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE2_HEADER_TEXT_YOGHURT",
            ),
          },
          {
            headerText: translate(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE3_HEADER_TEXT_YOGHURT",
            ),
          },
          {
            headerText: translate(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE4_HEADER_TEXT",
            ),
          },
        ]
      : [
          {
            headerText: translate(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE1_HEADER_TEXT",
            ),
          },
          {
            headerText: translate(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE2_HEADER_TEXT",
            ),
          },
          {
            headerText: translate(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE3_HEADER_TEXT",
            ),
          },
          {
            headerText: translate(
              "MIJN_MELK_ADULT_ONBOARDING_SLIDE4_HEADER_TEXT",
            ),
          },
        ];

  let { width: VIEWPORT_WIDTH_IN_PX, height: VIEWPORT_HEIGHT_IN_PX } =
    useWindowDimensions();
  let isAppOpenedInLandscapeMode = VIEWPORT_WIDTH_IN_PX > VIEWPORT_HEIGHT_IN_PX;
  VIEWPORT_WIDTH_IN_PX = isAppOpenedInLandscapeMode
    ? VIEWPORT_WIDTH_IN_PX
    : VIEWPORT_HEIGHT_IN_PX;
  VIEWPORT_HEIGHT_IN_PX = isAppOpenedInLandscapeMode
    ? VIEWPORT_HEIGHT_IN_PX
    : VIEWPORT_WIDTH_IN_PX;
  //#region MOUSE WHEEL FUNCTIONALITY
  function handleWheel(mouseEvent) {
    mouseEvent.stopPropagation();
    const deltaY = _.clone(mouseEvent.deltaY);
    createDebouncedWheelHandler(deltaY, 100);
  }
  function createDebouncedWheelHandler(deltaY, debounceIntervalInMs) {
    const mouseWheelHandler = _.debounce(() => {
      // _.debounce will combine multiple/a burst of mouse events into one event
      const isMoveRight = deltaY < 0;
      handleSlideChange(isMoveRight);
    }, debounceIntervalInMs);
    mouseWheelHandler(deltaY);
  }
  //#endregion

  //#region useful farm data extraction
  const search = useLocation().search;
  const farmId = new URLSearchParams(search).get("f");
  //#endregion

  //#region SWIPE FUNCTIONALITY
  const debouncedSwipeHandler = _.throttle(swipeHandler, 10);

  function swipeHandler(deltaX) {
    let latestSwipeDeltaX = getLatestSwipeDeltaX();
    const isMoveRight = latestSwipeDeltaX < 0;
    handleSlideChange(isMoveRight);
  }

  const swipeEventHandlers = useSwipeable({
    onSwiped: (eventData) => {
      let eventDeltaValue;

      if (isAppOpenedInLandscapeMode) {
        eventDeltaValue = _.cloneDeep(eventData.deltaX);
      } else {
        eventDeltaValue = _.cloneDeep(eventData.deltaY);
      }
      debouncedSwipeHandler(eventDeltaValue);
    },
    onSwipeStart: (eventData) => {
      let eventDeltaValue;
      if (isAppOpenedInLandscapeMode) {
        eventDeltaValue = _.cloneDeep(eventData.deltaX);
      } else {
        eventDeltaValue = _.cloneDeep(eventData.deltaY);
      }
      setLatestSwipeDeltaX(eventDeltaValue);
    },
    trackMouse: false,
  });
  //#endregion

  //#region SLIDE CHANGE LOGIC
  function handleSlideChange(isMoveRight) {
    if (isMoveRight) {
      moveToNextSlide();
    } else {
      moveToPreviousSlide();
    }
  }

  function moveToNextSlide() {
    const totalNumberOfSlidesInMijnMelkAdultOnboarding =
      MIJN_MELK_ADULT_ONBOARDING_SLIDES_CONFIG.length;
    setCurrentSlideInfo((previousCurrentSlideInfo) => {
      const isOnLastOnboardingSlide =
        previousCurrentSlideInfo.current ===
        totalNumberOfSlidesInMijnMelkAdultOnboarding - 1;
      if (!isOnLastOnboardingSlide) {
        return {
          ...previousCurrentSlideInfo,
          current: previousCurrentSlideInfo.current + 1,
          previous: previousCurrentSlideInfo.current,
        };
      } else {
        return { ...previousCurrentSlideInfo };
      }
    });
  }

  function moveToPreviousSlide() {
    setCurrentSlideInfo((previousCurrentSlideInfo) => {
      const isOnFirstOnboardingSlide = previousCurrentSlideInfo.current === 0;
      if (!isOnFirstOnboardingSlide) {
        return {
          ...previousCurrentSlideInfo,
          current: previousCurrentSlideInfo.current - 1,
          previous: previousCurrentSlideInfo.current,
        };
      } else {
        return { ...previousCurrentSlideInfo };
      }
    });
  }
  //#endregion

  let grassLayerCssClassname =
    "mijnMelkOnboardingAdult--layer mijnMelkOnboardingAdult--layer--grass";
  let isCurrentSlideEven = currentSlideInfo.current % 2 === 1;
  let isCurrentSlideLastSlide =
    currentSlideInfo.current ===
    MIJN_MELK_ADULT_ONBOARDING_SLIDES_CONFIG.length - 1;
  grassLayerCssClassname = isCurrentSlideEven
    ? grassLayerCssClassname + " is-tilted-left"
    : grassLayerCssClassname + " is-tilted-right";

  return (
    <div
      className="mijnMelkOnboardingAdult-container"
      onWheel={handleWheel}
      {...swipeEventHandlers}
    >
      <div className={grassLayerCssClassname}>
        <GrassSurface />
      </div>
      <div className="mijnMelkOnboardingAdult--layer mijnMelkOnboardingAdult--layer--slides">
        {MIJN_MELK_ADULT_ONBOARDING_SLIDES_CONFIG.map(
          (currentSlideConfig, currentSlideIndex) => {
            let currentSlideCssClassname =
              "mijnMelkOnboardingAdult--slide mijnMelkOnboardingAdult--slide" +
              (currentSlideIndex + 1);
            const isCurrentSlideTheActiveSlide =
              currentSlideInfo.current === currentSlideIndex;
            const isCurrentSlideThePreviousSlide =
              currentSlideInfo.previous === currentSlideIndex;
            currentSlideCssClassname = isCurrentSlideTheActiveSlide
              ? currentSlideCssClassname + " is-faded-in"
              : currentSlideCssClassname;
            currentSlideCssClassname = isCurrentSlideThePreviousSlide
              ? currentSlideCssClassname + " is-faded-out"
              : currentSlideCssClassname;
            let currentSlideComponent = null;
            if (currentSlideIndex === 0) {
              currentSlideComponent = (
                <MijnMelkAdultOnboardingSlide1
                  farmName={farmName}
                  milkBatchDate={milkBatchEndDate}
                  batchId={milkBatch}
                  milkBatchStartDate={milkBatchStartDate}
                  milkBatchEndDate={milkBatchEndDate}
                  milkGoingToBottleHour={milkGoingToBottleHour}
                  batchProductType={batchProductType}
                />
              );
            } else if (currentSlideIndex === 1) {
              currentSlideComponent = (
                <MijnMelkAdultOnboardingSlide2
                  batchProductType={batchProductType}
                />
              );
            } else if (currentSlideIndex === 2) {
              currentSlideComponent = (
                <MijnMelkAdultOnboardingSlide3
                  fatPercentage={fatPercentage}
                  proteinPercentage={proteinPercentage}
                  lactosePercentage={lactosePercentage}
                  batchProductType={batchProductType}
                />
              );
            } else {
              currentSlideComponent = (
                <MijnMelkAdultOnboardingSlide4
                  farmerName={farmerName}
                  farmName={farmName}
                  farmLocation={farmLocation}
                  farmerStyle={farmerStyle}
                  farmStyle={farmStyle}
                  totalNumberOfCowsInMilkBatch={totalNumberOfCowsInMilkBatch}
                  numberOfMilkingsInPast24Hours={numberOfMilkingsInPast24Hours}
                  onNavigateToInitialSlideFn={onNavigateToInitialSlideFn}
                  otherDevices={otherDevices}
                  batchProductType={batchProductType}
                />
              );
            }
            return (
              <div className={currentSlideCssClassname}>
                <div className="graphicElements"></div>
                {currentSlideComponent}
              </div>
            );
          },
        )}
      </div>
      <div className="mijnMelkOnboardingAdult--layer mijnMelkOnboardingAdult--layer--slideHeaders">
        <h3 className="mijnMelkOnboardingAdult-container--header">
          {
            MIJN_MELK_ADULT_ONBOARDING_SLIDES_CONFIG[currentSlideInfo.current]
              .headerText
          }
        </h3>
      </div>
      {currentSlideInfo.current < 3 ? (
        <div className="mijnMelkOnboardingAdult--layer mijnMelkOnboardingAdult--layer--swipeHand">
          <StoryNavigationHint />
        </div>
      ) : null}

      <div className="mijnMelkOnboardingAdult--layer mijnMelkOnboardingAdult--layer--milkBottle">
        <MijnMelkBottle
          farmName={farmName}
          farmerName={farmerName}
          isShiftedLeft={isCurrentSlideEven}
          isFullLeft={isCurrentSlideLastSlide}
          farmId={farmId}
          batchProductType={batchProductType}
        />
      </div>
      <div className="mijnMelkOnboardingAdult--layer mijnMelkOnboardingAdult--layer--weatherIcon">
        <MilkBatchWeatherIcon
          weatherData={weatherData}
          currentTimeInFarm={currentFarmTime}
        />
        {currentSlideInfo.current >= 1 ? null : (
          <>
            <MilkBatchTemperatureIcon weatherData={weatherData} />
            <MilkBatchWeatherText
              weatherData={weatherData}
              currentTimeInFarm={currentFarmTime}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default MijnMelkOnboardingAdultContainer;
