import React from "react";
import "./BirthdayCake.scss";
import Flame from "./Flame";


function BirthdayCake() {
    return <div className="birthdayCake">
        <span className="birthdayCake--cake"></span>
        <span className="birthdayCake--number">1</span>
        <Flame />
    </div>;
}

export default BirthdayCake;
