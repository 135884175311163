import {
  FARM_FEATURES_PAGE_INFORMATION_ERROR,
  FARM_FEATURES_PAGE_INFORMATION_SUCCESS,
  SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE_SUCCESS,
  SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE_ERROR,
} from '../containers/Admin/FarmFeaturesPage/types.js';

/** Store states
 * @constant {Object} */
export const initialState = {
  farmFeaturesPageDetails: {},
  errorMessage: '',
  isLatestSaveSuccessful: false,
  saveChangesErrorMessage: '',
};

/** Tell me more page reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function farmFeaturesReducer(state = initialState, action) {
  switch (action.type) {
    case FARM_FEATURES_PAGE_INFORMATION_SUCCESS:
      return {
        ...state,
        farmFeaturesPageDetails: action.payload,
        errorMessage: '',
      };
    case FARM_FEATURES_PAGE_INFORMATION_ERROR:
      return {
        ...state,
        farmFeaturesPageDetails: [],
        errorMessage: action.payload,
      };
    case SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE_SUCCESS:
      return {
        ...state,
        farmFeaturesPageDetails: {...action.payload},
        isLatestSaveSuccessful: true,
        saveChangesErrorMessage: '',
      };
    case SAVE_CHANGES_TO_FARM_FEATURES_INFORMATION_PAGE_ERROR:
      return {
        ...state,
        isLatestSaveSuccessful: false,
        saveChangesErrorMessage: action.payload,
      };

    default:
      return state;
  }
}

export default farmFeaturesReducer;
